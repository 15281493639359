export const PRINT_STATUS_TEXT = {
  PRINT_SUCESS: '已打印成功',
  PRINT_FAIL: '已打印失败',
  PRINT_UNKNOWN: '无法识别打印机状态，请自行检查是否打印成功',
  PRINT_TIMEOUT: '未获取到打印机状态，请自行检查是否打印成功',
  PRINT_NO_STATUS: '无法获取到打印机状态，请自行检查是否打印成功',
};

export const COLOR_MAP = {
  [PRINT_STATUS_TEXT.PRINT_SUCESS]: '#80BE1C',
  [PRINT_STATUS_TEXT.PRINT_FAIL]: '#DE2539',
};
