/**
 * zhujunyan
 * 短信模版 tips
 */
import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { Tooltip, ButtonIcon } from 'components';
import actions from 'actions';
import './index.scss';

function msgTip(parm, callback) {
  const _parm = parm;
  const _emel = _parm.split(','); // 发短信的接收方
  actions.orderServer.msgModel(_emel).then(res => {
    if (res.type === 'API_SUCCESS' && res.data.errno === 0) {
      const _data = res.data.res.data.templateList;
      const _html = [];
      for (const val of _data) {
        _html.push(
          <p key={Math.random()}>
            <span>【{val.receiver}短信内容】</span>
            <span>{val.template}</span>
          </p>,
        );
      }
      callback && callback(_html, _data); // 返回短信模板数据，短信校验需要取数据中的id
    }
  });
}

export default class MsgTips extends PureComponent {
  static propTypes = {
    msgTypeS: PropTypes.string, // 短信模版的接受人
    onChange: PropTypes.func,
    display: PropTypes.bool,
  };
  static defaultProps = {
    msgTypeS: 'BUS_TRACK_SEND',
    display: true,
  };
  constructor(props) {
    super(props);
    this.state = {
      msgTip: '',
    };
  }
  UNSAFE_componentWillMount = () => {
    const parm = this.props.msgTypeS;
    msgTip(parm, (_html, _data) => {
      console.log(_html);
      this.setState({
        msgTip: _html,
      });
      this.props.onChange(_data);
    });
  };

  render() {
    const msgTipHtml = this.state.msgTip;
    if (!this.props.display) {
      return null;
    }
    if (!msgTipHtml) return false;
    return (
      <Tooltip
        content={
          <div className="homepage_tip" style={{ fontSize: '12px', color: '', minWidth: '350px' }}>
            {msgTipHtml}
          </div>
        }
      >
        <ButtonIcon iconType="icon-message-tmp" classname="message-tmp" ref={r => (this.msgTipButton = r)} />
      </Tooltip>
    );
  }
}
