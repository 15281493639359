/*
 * @Author: hanshuai
 * @Date: 2024-03-06 11:31:50
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ModalDialog, PopUp, Button } from 'components';
import moment from 'moment';
import { showInfo, axiosIns } from 'utils';
import { WARN } from 'constants';
import { Form, Input, InputNumber, Checkbox, Link, Select, Grid } from '@gui/web-react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';

const FormItem = Form.Item;
const { Option } = Select;
const quickOperateList = [
  { label: '20万', value: 200000 },
  { label: '50万', value: 500000 },
  { label: '100万', value: 1000000 },
];
let pdfModalDialog = null;

class FreightGuaranteeModal extends PureComponent {
  static propTypes = {
    close: PropTypes.func,
    title: PropTypes.string,
    onSaveSuccess: PropTypes.func,
    waybillInfo: PropTypes.object,
    goodsTypeList: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        amountMax: PropTypes.number.isRequired,
        sort: PropTypes.number.isRequired,
        advicePdf: PropTypes.object.isRequired,
        confirmPdf: PropTypes.object.isRequired,
        guaranteePdf: PropTypes.object.isRequired,
      }),
    ),
  };
  static defaultProps = {
    title: '货物安全管理保障服务',
  };

  constructor(prop) {
    super(prop);
    this.state = {
      initialValues: { goodsType: '1' },
      amountMax: 0,
      computeLoading: false,
    };
  }

  UNSAFE_componentWillMount() {
    const { goodsType } = this.state.initialValues;
    this.handleGoodsChange(goodsType);
  }

  // 货物类型
  handleGoodsChange = val => {
    const { goodsTypeList } = this.props;
    this.formRef && this.formRef.setFieldsValue({ guaranteeAmount: undefined, guaranteeCost: undefined });
    const amountMax = goodsTypeList.find(e => e.code === val)?.amountMax;
    this.setState({ amountMax });
  };

  // form表单更改
  handleValuesChange = changedValues => {
    // 检查变化的值
    if ('guaranteeAmount' in changedValues) {
      // 处理日期变化的逻辑
      this.formRef && this.formRef.setFieldsValue({ guaranteeCost: undefined });
    }
  };

  // 保额快捷操作
  handleSetAmount = val => {
    this.formRef.setFieldsValue({ guaranteeAmount: val });
  };

  handleCompute = () => {
    const goodsType = this.formRef.getFieldValue('goodsType');
    const guaranteeAmount = this.formRef.getFieldValue('guaranteeAmount');
    if (!guaranteeAmount) {
      showInfo(WARN, '请输入保额!');
      return;
    }
    this.setState({ computeLoading: true });
    const { goods, truckId, createPoint } = this.props.waybillInfo || { goods: {}, truckId: {} };
    const params = {
      customerId: window.group_id,
      goodsName: goods?.[0]?.name,
      goodsType,
      guaranteeAmount,
      plateNumber: truckId?.tr_num,
      platformCode: 'cyt',
      customerBranchId: createPoint?.id,
      //   goodsCount: '',
      //   guaranteeCostType: '',
    };
    axiosIns
      .postRequest({
        url: '/insurance/freight/assurance/services/premium',
        data: params,
      })
      .then(res => {
        const { success, data } = res;
        if (success) {
          this.formRef.setFieldsValue({ guaranteeCost: data?.totalActualScore });
        }
      })
      .finally(() => {
        this.setState({ computeLoading: false });
      });
  };

  handleReset = () => {
    this.props.handleReset && this.props.handleReset(this);
  };

  handleSubmit = () => {
    const { goodsTypeList } = this.props;
    this.formRef.validate().then(val => {
      Reflect.deleteProperty(val, 'readme');
      this.props.onSaveSuccess && this.props.onSaveSuccess({ ...val, goodsTypeList });
      this.handleCancel();
    });
  };

  handleCancel = () => {
    this.vModalDialog.handleHide();
    this.props.handleCancel && this.props.handleCancel(this);
  };

  // 获取pdf
  getPdf = (pdfUrl, pdfPage) => {
    const { goodsTypeList } = this.props;
    const goodsType = this.formRef.getFieldValue('goodsType');
    const file = goodsTypeList.find(e => e.code === goodsType)?.[pdfUrl];
    const page = goodsTypeList.find(e => e.code === goodsType)?.[pdfPage];
    return { file, page };
  };

  // 已阅读
  handleRead = (pdfUrl, pdfPage) => {
    const { file, page } = this.getPdf(pdfUrl, pdfPage);
    pdfModalDialog = new PopUp(ModalDialog, {
      ref: r => (this.buyFreightDialog = r),
      closable: false,
      content: (
        <div style={{ height: '600px', overflowY: 'auto' }}>
          <Document file={file}>
            {new Array(page).fill().map((_, index) => (
              <Page width={600} key={`page_${index + 1}`} scale={1.5} pageNumber={index + 1} height={600} />
            ))}
          </Document>
        </div>
      ),
      bottom: [
        <Button
          type="primary"
          key={1}
          onClick={() => {
            pdfModalDialog.close();
          }}
        >
          我已阅读并同意
        </Button>,
      ],
      bottomStyle: { textAlign: 'center' },
      isShow: true,
      style: { height: 600 },
    });
    pdfModalDialog.show();
  };

  submitBtnRefGetter = r => (this.submitBtnRef = r);

  cancelBtnRefGetter = r => (this.cancelBtnRef = r);

  renderBottom = () => (
    <div className="bottom-div">
      <Button ref={this.submitBtnRefGetter} type="primary" onClick={this.handleSubmit}>
        确定
      </Button>
      <Button ref={this.cancelBtnRefGetter} onClick={this.handleCancel}>
        取消
      </Button>
    </div>
  );
  refTypeSet = r => (this.typeSet = r);

  render() {
    const { title, autoDestroy, isShow = true, goodsTypeList, ...rest } = this.props;
    const { initialValues, computeLoading, amountMax } = this.state;
    const bottom = this.renderBottom();
    const typesetWrap = (
      <Form
        initialValues={initialValues}
        ref={r => (this.formRef = r)}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
        onValuesChange={this.handleValuesChange}
      >
        <FormItem label="货物类型" field="goodsType" rules={[{ required: true, message: '货物类型不能为空' }]}>
          <Select placeholder="请选择货物类型" onChange={this.handleGoodsChange}>
            {goodsTypeList.map(option => (
              <Option key={option.code} value={option.code}>
                {option.label}
              </Option>
            ))}
          </Select>
        </FormItem>
        <FormItem label="保额" required>
          <Grid.Row gutter={8}>
            <Grid.Col span={12}>
              <Form.Item field="guaranteeAmount" rules={[{ required: true, message: '保额不能为空' }]}>
                <InputNumber placeholder="请选择保额" hideControl min={0} max={amountMax} />
              </Form.Item>
            </Grid.Col>
            <Grid.Col span={12} style={{ fontSize: '14px', marginTop: '6px' }}>
              元
            </Grid.Col>
          </Grid.Row>
          {quickOperateList.map(e => {
            return (
              <Button type="text" onClick={() => this.handleSetAmount(e.value)}>
                {e.label}
              </Button>
            );
          })}
        </FormItem>
        <FormItem label="积分" required>
          <Grid.Row gutter={8}>
            <Grid.Col span={12}>
              <Form.Item field="guaranteeCost" rules={[{ required: true, message: '积分不能为空' }]}>
                <Input style={{ width: 90 }} disabled placeholder="积分" />
              </Form.Item>
            </Grid.Col>
            <Grid.Col span={12}>
              <Button loading={computeLoading} type="primary" onClick={this.handleCompute}>
                计算积分
              </Button>
            </Grid.Col>
          </Grid.Row>
        </FormItem>
        <FormItem wrapperCol={{ offset: 5 }} required>
          <Grid.Row gutter={8}>
            <Grid.Col span={6}>
              <Form.Item
                field="readme"
                rules={[{ type: 'boolean', true: true, required: true, message: '请勾选已阅读' }]}
              >
                <Checkbox />
              </Form.Item>
            </Grid.Col>
            <Grid.Col span={18} style={{ marginLeft: '-90px', marginTop: '4px' }}>
              我已阅读
              <Link
                onClick={() => {
                  this.handleRead('advicePdf', 'advicePage');
                }}
              >
                《投保告知书》
              </Link>
              及
              <Link
                onClick={() => {
                  this.handleRead('guaranteePdf', 'guaranteePage');
                }}
              >
                《保障范围》
              </Link>
              并同意购买
            </Grid.Col>
          </Grid.Row>
        </FormItem>
      </Form>
    );
    return (
      <ModalDialog
        isShow={isShow}
        title={title}
        autoDestroy={autoDestroy}
        content={typesetWrap}
        ref={r => (this.vModalDialog = r)}
        bottom={bottom}
        style={{ width: 500 }}
        {...rest}
      />
    );
  }
}

export default FreightGuaranteeModal;

// 购买货运险校验
export const freightGuaranteeValidate = state => {
  const {
    goodsName,
    startPlace,
    toPlace,
    plateNumber,
    planTruckT,
    truckT,
    planArrT,
    completeTime,
    returnTask,
    backJourney,
    emptyTask,
  } = state;
  // returnTask 往返任务
  // backJourney 返程配货
  // emptyTask 空驶任务
  if (returnTask) {
    showInfo(WARN, `往返任务，创建运单时不可购买货物安全管理保障服务，可在创建后运单列表中进行购买`);
    return true;
  } else if (backJourney === 1) {
    showInfo(WARN, `返程配货，创建运单时不可购买货物安全管理保障服务，可在创建后运单列表中进行购买`);
    return true;
  } else if (emptyTask === 1) {
    showInfo(WARN, `空驶任务，创建运单时不可购买货物安全管理保障服务，可在创建后运单列表中进行购买`);
    return true;
  }
  // 计算 truckT 之后的 24 小时后的时间
  const truckTPlus24Hours = moment(truckT || planTruckT)
    .clone()
    .add(24, 'hours');
  // 计算 a 和 b 之间的时间差（以秒为单位）
  const differenceInSeconds = moment(completeTime || planArrT).diff(moment(truckT || planTruckT), 'seconds');
  // 定义一周的秒数
  const oneWeekInSeconds = 7 * 24 * 60 * 60;
  if (!goodsName || !startPlace || !toPlace || !plateNumber || (!truckT && !planTruckT)) {
    showInfo(WARN, `货物名称、出发地、目的地、车牌号、实际发车时间或计划发车时间不能为空！`);
    return true;
  } else if (moment().isAfter(truckTPlus24Hours)) {
    showInfo(WARN, `该运单已发车超过24小时，不可购买货物安全管理保障服务！`);
    return true;
  } else if ((completeTime || planArrT) && moment(completeTime || planArrT).isBefore(truckT || planTruckT)) {
    showInfo(WARN, `到达时间不能早于发车时间`);
    return true;
  } else if (differenceInSeconds > oneWeekInSeconds) {
    showInfo(WARN, `货物安全管理保障服务最长保障7天！`);
    return true;
  } else {
    return false;
  }
};
