import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class Start extends PureComponent {
  constructor(prop) {
    super(prop);
    this.state = {
      data: this.formatData(prop.data),
      // data: prop.data,
      listDriver: ['defalut', 'defalut', 'defalut', 'defalut', 'defalut'],
      // eslint-disable-next-line react/no-unused-state
      newData: [],
    };
  }
  static propTypes = {
    data: PropTypes.array,
  };
  formatData = data =>
    data.map(item => {
      const obj = { ...item };
      switch (true) {
        case item.grade === 1:
          obj.text = '非常差';
          break;
        case item.grade === 2:
          obj.text = '差';
          break;
        case item.grade === 3:
          obj.text = '一般';
          break;
        case item.grade === 4:
          obj.text = '好';
          break;
        case item.grade === 5:
          obj.text = '非常好';
          break;
        default:
          break;
      }
      return obj;
    });

  getData = () => this.state.data;

  startDefault = (data, ind) => {
    const { listDriver } = this.state;
    // eslint-disable-next-line no-nested-ternary
    const _start = listDriver.map((x, index) => (index + 1 > +data ? 'defalut' : +data <= 2 ? 'bad' : 'hover'));
    return _start.map((x, indx) => <li className={x} onClick={() => this.startMove(ind, indx)} />);
  };
  startMove = (ind, index) => {
    const { data } = this.state;
    const _data = [...data];
    _data[ind].grade = index + 1;
    switch (true) {
      case index + 1 === 1:
        _data[ind].text = '非常差';
        break;
      case index + 1 === 2:
        _data[ind].text = '差';
        break;
      case index + 1 === 3:
        _data[ind].text = '一般';
        break;
      case index + 1 === 4:
        _data[ind].text = '好';
        break;
      case index + 1 === 5:
        _data[ind].text = '非常好';
        break;
      default:
        break;
    }
    this.setState({ data: _data });
  };
  render() {
    return (
      <React.Fragment>
        {this.state.data.map((el, ind) => (
          <div className="list-start">
            <span>{el.item}</span>
            <ul>
              {this.startDefault(el.grade, ind, el.text)}
              <span className="commentText">{el.text}</span>
            </ul>
          </div>
        ))}
      </React.Fragment>
    );
  }
}
