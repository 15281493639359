import emitter from 'utils/emitter';
import { showInfo, fetchJAVA, fetchApi } from 'utils';
import { ShowList } from 'components';
import React, { Fragment } from 'react';
import localforage from 'localforage';
import { WARN, CHECK, ERROR } from 'constants';
import { COLLECTION_PAGE_CONFIG } from './config';
import { POPUP_CONFIRM_WINDOW } from '@/utils/i18n/constants.js';
import { i18n } from '@/utils/i18n/index.js';
import _ from 'lodash';

import overloadOp from 'operation/listOperation/Finance/overloadOp/index.js';

// 使用 createInstance 创建独立的localforage 实例
const collectionListStore = localforage.createInstance({
  driver: localforage.INDEXEDDB, // 选择存储引擎，如IndexedDB
  name: 'cyt_frontend', // 数据库名称
  version: 1, // 数据库版本号
  storeName: 'collection_list', // 存储对象的名称
});

// 创建存储数据的 key 值
export const createSaveKey = ({ billType, dataType }) => {
  const { group_id, user_id } = window;
  const USER_KEY = `${group_id}-${user_id}`;
  return `${billType}-${dataType}-${USER_KEY}`;
};

// 判断订单对账数据是否取于待对账列表
export const isUseCollectionData = btnKey => {
  const keys = ['allOrderToCollection', 'createOrderFromCollection'];
  return keys.includes(btnKey);
};

/**
 * 获取挑单数据
 * dataType: 数据类 [add, edit， view], add: 创建对账单, edit: 编辑对账单, view: 查看挑单夹数据
 * billType: 单据类型
 */
export const getCollectionData = (option = {}) => {
  const { billType, dataType } = option;
  return new Promise((reslove, reject) => {
    const saveKey = createSaveKey({ billType, dataType });
    collectionListStore
      .getItem(saveKey)
      .then(function (value) {
        reslove(value ?? new Map());
      })
      .catch(function (err) {
        reject(err);
      });
  });
};

// 存储挑单数据
export const setCollectionData = (option = {}) => {
  const { val, billType, dataType } = option;
  return new Promise((reslove, reject) => {
    const saveKey = createSaveKey({ billType, dataType });
    collectionListStore
      .setItem(saveKey, val)
      .then(function (data) {
        reslove(data);
        // 添加 emitter 事件，通知更新数据条数
        const Emitter = emitter.getSpance('picker_order');
        Emitter.emit('setCollection');
      })
      .catch(function (err) {
        reject(err);
      });
  });
};

// 清除存储的数据
export const clearCollectionData = (option = {}) => {
  const { billType, dataTypeList = [] } = option;
  dataTypeList.forEach(dataType => {
    setCollectionData({
      val: new Map(),
      billType,
      dataType,
    });
  });
};

// 加入挑单夹
export const addOrderToCollection = async (option = {}) => {
  const { dataType, billType, newData = [], prefixTips } = option;
  const data = [...newData];
  if (!data.length) return showInfo(WARN, '请选择要操作的数据！');
  const { saveUniqueKey, saveDataKeys } = COLLECTION_PAGE_CONFIG[billType];
  const list = data.map(item => {
    const saveObj = _.pick(item, saveDataKeys);
    // com_id 特殊处理
    saveObj.com_id = item.com_id ?? window.company_id;
    return saveObj;
  });
  // 获取存储的数据
  const collectionData = await getCollectionData({ billType, dataType });
  // 已经存储的条数
  const oldSize = collectionData.size;
  // 更新数据，直接添加，有重复的会更新
  list.forEach(item => {
    collectionData.set(item[saveUniqueKey], item);
  });
  // 新数据条数
  const newSize = collectionData.size;
  const { maxPickOrder } = COLLECTION_PAGE_CONFIG[billType];
  if (newSize > maxPickOrder) {
    return showInfo(ERROR, `待对账列表最多${maxPickOrder}条订单，无法添加`);
  }
  const successLen = newSize - oldSize;
  const failLen = list.length - successLen;
  const successTip = `成功添加${successLen}条，重复/失败${failLen}条`;
  const tips = prefixTips ? `${prefixTips}, ${successTip}` : successTip;
  let successType = failLen === 0 ? CHECK : WARN;
  successType = successLen === 0 ? ERROR : successType;
  setCollectionData({ val: collectionData, billType, dataType })
    .then(() => {
      showInfo(successType, tips);
    })
    .catch(() => {
      showInfo(ERROR, '添加失败');
    });
};

// 移除已选中订单
export const removeOrderFromCollection = async (option = {}) => {
  const { that, dataType, billType } = option;
  const { saveUniqueKey } = COLLECTION_PAGE_CONFIG[billType];
  const selectIds = that.getSelectesKey(saveUniqueKey);
  if (!selectIds.length) return showInfo(WARN, '请选择要操作的数据！');
  const collectionData = await getCollectionData({ billType, dataType });
  selectIds.forEach(key => {
    collectionData.delete(key);
  });

  setCollectionData({ val: collectionData, billType, dataType })
    .then(() => {
      showInfo(CHECK, '移除成功');
    })
    .catch(() => {
      showInfo(ERROR, '移除失败');
    });
};

// 校验查询并新增后，是否要弹窗提示
export const checkQueryAndAddNum = async ({
  tableList, // pickTable 接口返回数据
  uniqueKey, // 取数的key
  selectList, //
  selectPackBatchList,
  isPack, // 是否为打包单
  buttonKey, // 业务btnkey
  orderNum, // 查询的 orderNum 数据
  category, // 属于哪个类目
  tab, // 哪个tab
  cusOpTips, // 自定义提示文案
  showInfoHandle,
  showInfoOtherProps = {},
}) => {
  const transportKeyArr = ['driver_ca_ba_apply', 'car_ca_ba_apply', 'carrier_ca_ba_apply'];
  const clientKeyArr = ['client_ca_ba_apply', 'project_ca_ba_apply', 'create_new_account', 'receiptApply'];
  // 车辆对账 / 司机对账 / 承运商对账 /客户运单对账 项目运单对账 客户订单对账 创建对账单时错误弹框还需要暂时另外的错误信息
  const isTransport = transportKeyArr.indexOf(buttonKey); // 是下游对账
  const isClient = clientKeyArr.indexOf(buttonKey); // 是上游对账
  const showMoreErrorTip = isTransport > -1 || isClient > -1;

  const i18nDict = await i18n(POPUP_CONFIRM_WINDOW);
  const orderDict = i18nDict.get('order', '运单'); // 运单改为订单
  const batchDict = i18nDict.get('batch', '任务'); // 任务改为运单
  const batchDictp = i18nDict.get('batch', '批次'); // 批次改为运单

  const rightList = (tableList.right || {}).data || [];
  let list = orderNum || [];

  const sucOrderNum = [];
  rightList.forEach(item => sucOrderNum.push(item[uniqueKey]));
  list = list.filter(x => sucOrderNum.indexOf(x) === -1);

  const PackList = orderNum ?? [];
  if (isPack && PackList) {
    const PackIds = [];
    const notSelectPack = [];
    rightList.forEach(i => {
      PackIds.push(i.b_link_id);
    });
    selectList.forEach((i, index) => {
      if (!PackIds.includes(i)) {
        notSelectPack.push(selectPackBatchList[index]);
      }
    });
    list = notSelectPack;
  }

  const rightQueryLen = PackList.length;

  if (rightList.length !== rightQueryLen) {
    const isReservation = category === 'Reservation';
    const titleText = isPack ? '打包单' : '数据';
    // eslint-disable-next-line no-nested-ternary
    const pText = isPack ? '打包单' : category === 'Batch' ? batchDictp : isReservation ? '订单' : orderDict;
    const taskList = ['payment_batch_task', 'settle_batch_task', 'receipt_batch_task', 'project_batch_detail'];
    const batchNotice = [
      'team_batch_detail',
      'driver_batch_detail',
      'car_batch_detail',
      'dispatch_batch_detail',
      ...taskList,
    ];

    const content = (
      <div className="dialog_detail">
        <p className="notice">{`以下${titleText}无法${cusOpTips || '操作'}:`}</p>
        <ShowList list={list} style={{ marginTop: '8px' }} />
      </div>
    );
    const textNode = (
      <div style={{ postion: 'absolute' }} className="detail-content">
        <p className="notice_list">
          <em>●</em>该{pText}存在待审核或者审核中的修改记录，不允许进行当前操作！
        </p>
      </div>
    );
    const textNode2 = showMoreErrorTip ? (
      <>
        {pText === '订单' ? (
          ''
        ) : (
          <div style={{ postion: 'absolute' }} className="detail-content">
            <p className="notice_list">
              <em>●</em>该{pText}
              {isTransport > -1 ? '运力' : '客户'}考核费待审核/审核中，不允许进行当前操作！
            </p>
          </div>
        )}
        <div style={{ postion: 'absolute' }} className="detail-content">
          <p className="notice_list">
            <em>●</em>该{pText}存在未审核/审核异常/审核错误的审核记录，不允许进行当前操作！
          </p>
        </div>
        {/* 客户订单对账（收款按钮）对账单不展示未到达 */}
        {buttonKey === 'receiptApply' ? (
          ''
        ) : (
          <div style={{ postion: 'absolute' }} className="detail-content">
            <p className="notice_list">
              <em>●</em>该{pText}未到达，不允许进行当前操作！
            </p>
          </div>
        )}
      </>
    ) : (
      ''
    );
    // 结算功能 开启了禁止创建结算单规则时展示
    const textNode3 =
      buttonKey === 'settle_new' ? (
        <>
          <div style={{ postion: 'absolute' }} className="detail-content">
            <p className="notice_list">
              <em>●</em>该{pText}存在客户/运力考核费待审核/审核中的记录，不可结算
            </p>
          </div>
          <div style={{ postion: 'absolute' }} className="detail-content">
            <p className="notice_list">
              <em>●</em>该{pText}存在审核异常/错误的记录，不可结算
            </p>
          </div>
        </>
      ) : (
        ''
      );
    const bText = taskList.includes(tab) ? batchDict : batchDictp;
    const noticeList = batchNotice.includes(tab) ? (
      <Fragment>
        <div style={{ postion: 'absolute' }} className="detail-content">
          <p className="notice_list">
            <em>●</em>未结算金额为0的数据
          </p>
        </div>
        <div style={{ postion: 'absolute' }} className="detail-content">
          <p className="notice_list">
            <em>●</em>该{bText}已删除，不允许进行当前操作
          </p>
        </div>
        {textNode}
        {textNode2}
        {textNode3}
      </Fragment>
    ) : (
      <Fragment>
        <div style={{ postion: 'absolute' }} className="detail-content">
          <p className="notice_list">
            <em>●</em>该{pText}已不在当前被操作网点的库存中
          </p>
        </div>
        <div style={{ postion: 'absolute' }} className="detail-content">
          <p className="notice_list">
            <em>●</em>该{pText}当前状态不符合系统设置的要求
          </p>
        </div>
        <div style={{ postion: 'absolute' }} className="detail-content">
          <p className="notice_list">
            <em>●</em>该{pText}已作废或已删除，不允许进行当前操作
          </p>
        </div>
        {textNode}
        {textNode2}
        {textNode3}
      </Fragment>
    );

    const contentNotice = (
      <div className="dialog_detail checkinfo-detail">
        <p className="notice_title">可能的原因:</p>
        {noticeList}
      </div>
    );
    showInfo(ERROR, content, true, showInfoHandle, false, '操作提醒', contentNotice, {}, showInfoOtherProps);
  }
};

// 读取挑单夹数据，生成对账数据，并判断对账数据是否超1000条
export const checkAndApplyInit = async ({ acount, req, billType, okConfirm = true }) => {
  const { checkAndInitUrl, checkAndInitFetchType } = COLLECTION_PAGE_CONFIG[billType]?.pickOrderConfig || {};
  const _fetch = checkAndInitFetchType === 'php' ? fetchApi : fetchJAVA;
  const request = ({ params } = {}) =>
    _fetch(checkAndInitUrl, {
      method: 'POST',
      body: { req: { ...req, ...params } },
    });

  const checkedRes = await overloadOp({
    request,
    okConfirm,
    total: acount,
    title: acount ? (
      <Fragment>
        <div>
          总数 <span style={{ color: '#3aa965' }}> {acount ?? ''} </span>
        </div>
      </Fragment>
    ) : null,
    content: (
      <Fragment>
        <div>{acount > 1000 ? '所选数据超过1000条' : `所选数据${acount}条`} </div>
        <div>请等待系统校验，一般不超过30秒</div>
      </Fragment>
    ),
  });

  return checkedRes;
};

// 根据所有 operate_data 数据，获取对应运单数
export const getCollectionUniqData = (data = []) => {
  // 存在调账数据，调账数据没有id，需要过滤
  const uniqData = _.uniqBy(data, 'id').filter(item => item.id);
  return uniqData;
};

// 将数组对象转换成以对象key的 Map 结构
export const arrayToMap = (list = [], key) => {
  const dataMap = new Map();
  list.forEach(item => {
    // 将key转成字符串
    dataMap.set(`${item[key]}`, item);
  });
  return dataMap;
};

// 获取map 结构的值转化成数组
export const mapToArray = (mapData = new Map()) => {
  return [...mapData.values()];
};

// 新增、编辑挑单页数据后，更新超1000条信息
export const updateOverloadInfo = async ({ dataType, that }) => {
  const pickOrderInfo = dataType === 'add' ? that.props.pickOrderInfo.toJS() ?? {} : that.props.pickOrderInfo;
  const billType = pickOrderInfo?.reqInfo?.type;
  const saveData = await getCollectionData({
    billType,
    dataType,
  });
  const od_link_id = [...saveData.keys()];
  const req = {
    od_link_id,
  };
  if (dataType === 'edit') {
    req.bill_id = that.props.reqParam?.bill_id;
  }
  const checkedRes = await checkAndApplyInit({
    acount: saveData.size,
    req,
    billType,
    okConfirm: false,
  });

  if (!checkedRes) {
    return showInfo(ERROR, '无可对账数据，请确认！');
  }
  const { company_id_list, failed_detail, fee_relation_and_fee, success_id, total_amount } = checkedRes.res;
  const overload = {
    success_id: [
      {
        uuid: null,
        id: null,
        expense_id: null,
        number: '',
        expense: null,
        direction: 0,
        amount: null,
        company_id: null,
        fee_direct: null,
        fee_type: null,
        fee_id: null,
      },
    ],
    operate_data: success_id,
    failed_detail,
    fee_info: fee_relation_and_fee,
    company_info: company_id_list,
    account_book: [],
    total_amount,
  };
  that._feeRelationAndFee = overload.fee_info;
  that.amountDetail = null;
  that.setState(
    {
      isOverload: true,
      isNewOverload: true,
      showBiTable: false,
      overloadInfo: { ...overload },
    },
    async () => {
      const pickOrderContent = that.context?.getPick?.();
      pickOrderContent?.setLoadingStatus?.(true);
      await pickOrderContent?.getFeeRelation?.();
      pickOrderContent?.handleChangeAmount?.();
      pickOrderContent?.setLoadingStatus?.(false);
    },
  );
};
