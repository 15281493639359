import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { prefixCls } from './index.scss';
import OrderButtons from 'components/commoncomponents/OrderButtons';
import { cloneDeep } from 'lodash';

export default class BiSummary extends PureComponent {
  constructor(prop) {
    super(prop);
    this.state = {
      summary: cloneDeep(prop.summary),
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.summary !== nextProps.summary) {
      this.setSummary(nextProps.summary);
    }
  }
  setSummary = summary => {
    this.setState({
      summary: cloneDeep(summary),
    });
  };

  render() {
    const tab = this.props.tab?.();
    const getCategory = this.props.category?.();

    return this.state.summary?.length || Object.keys(this.props.rightButtons ?? {}).length ? (
      <div
        className={classnames({ [`${prefixCls}`]: true, 'is-simple': this.props.simple, 'is-stack': this.props.stack })}
        style={this.props.style}
      >
        <div className={classnames(`${prefixCls}__summary`)}>
          {this.state.summary?.map?.(item =>
            !item.hide ? (
              <div className={classnames(`${prefixCls}__summary-item`)}>
                <span className={classnames(`${prefixCls}__summary-title`)}>{item.title}</span>
                <span className={classnames(`${prefixCls}__summary-data`)} style={{ color: item.color, ...item.style }}>
                  {item.value}
                </span>
              </div>
            ) : null,
          )}
        </div>
        <div className={classnames(`${prefixCls}__right-btn`)}>
          <OrderButtons
            data={{ ...this.props.rightButtons }}
            handleClick={(...args) => this.props.btnClickCallback?.('right', ...args)}
            tab={tab}
            flat={false}
            category={getCategory}
          />
        </div>
      </div>
    ) : (
      <span />
    );
  }
}
