import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Icon } from 'components';

class Card extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    canToggle: PropTypes.bool,
    children: PropTypes.element,
    titleExt: PropTypes.any,
    className: PropTypes.string,
    open: PropTypes.bool,
  };
  static defaultProps = {
    canToggle: true,
    open: true,
  };
  state = {
    open: this.props.open,
  };

  toggle = () => {
    if (!this.props.canToggle) {
      return;
    }
    this.setState({
      open: !this.state.open,
    });
  };
  open = () =>
    new Promise(resolve => {
      this.setState(
        {
          open: true,
        },
        resolve,
      );
    });
  render() {
    const { open } = this.state;
    const { children, title, canToggle, titleExt, className } = this.props;
    return (
      <Flex dir="column" className={`form-card ${className}`} align="stretch">
        <Flex align="center" className={`card-header ${canToggle && 'toggle'}`} onClick={this.toggle}>
          {canToggle && (
            <Icon iconType={`icon-carry-${open ? 'out' : 'in'}`} style={{ fontSize: open ? '16px' : '14px' }} />
          )}
          <Flex className="title">{title}</Flex>
          {titleExt}
        </Flex>
        <Flex wrap className={`form-content ${!open && 'hide'}`}>
          {children}
        </Flex>
      </Flex>
    );
  }
}
export default Card;
