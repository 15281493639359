exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".batch_g7_confirm,.batch_g7_confirm .form-item{display:flex;flex-direction:column;width:100%;align-items:stretch}.batch_g7_confirm .form-item:nth-child(n+2){margin-top:18px}.batch_g7_confirm .form-item>label{width:100%;margin-bottom:8px;color:#333;font-size:14px}.batch_g7_confirm .form-item .ant-calendar-picker,.batch_g7_confirm .form-item .fn-input-pure-wrap{width:330px!important}.batch_g7_confirm .form-item .ant-calendar-picker .ant-calendar-picker-input,.batch_g7_confirm .form-item .fn-input-pure-wrap .ant-calendar-picker-input{width:100%}.batch_g7_confirm .form-item .fn-icon-help{margin-left:6px}.g7-batch-confrim .dialog_detail{padding-right:0}.g7-batch-confrim .detail-content{margin-left:0}", ""]);

// exports
exports.locals = {
	"prefixCls": "batch_g7_confirm",
	"batch_g7_confirm": "batch_g7_confirm",
	"form-item": "form-item",
	"fn-input-pure-wrap": "fn-input-pure-wrap",
	"ant-calendar-picker": "ant-calendar-picker",
	"ant-calendar-picker-input": "ant-calendar-picker-input",
	"fn-icon-help": "fn-icon-help",
	"g7-batch-confrim": "g7-batch-confrim",
	"dialog_detail": "dialog_detail",
	"detail-content": "detail-content"
};