import React from 'react';
import { ModalDialog, Flex, Icon, Button } from 'components';
import delayApply from 'utils/business/account/delayApply';
import { prefixCls } from './index.scss';

export default class ExpireTip extends React.Component {
  toDelayApply = () => {
    delayApply();
    this.handleHide();
  };
  toOnlineBuy = () => {
    const urlData = {
      controller: 'Account',
      action: 'onlineBuy',
      query: {
        tabName: '在线购买',
      },
    };
    window.$app.direct(urlData, 'data');
  };
  handleRenew = () => {
    const urlData = {
      controller: 'Account',
      action: 'onlineBuy',
      query: {
        tabName: '在线续费',
        type: 'to_pay',
      },
    };
    window.$app.direct(urlData, 'data');
  };
  renderContent = () => {
    if (+this.props.errno === -60003) {
      return (
        <Flex className={prefixCls} dir="column" align="center">
          <Flex className="title" justify="center">
            <Icon iconType="icon-check-o" />
          </Flex>
          <p className="msg">{this.props.errmsg || ''}感谢您的支持，工作人员会尽快审核您的申请！</p>
          <p className="msg">如您比较着急，可以拨打400-611-5656客服电话为您服务。</p>
          <Button type="positive" classname="btn" onClick={this.handleHide}>
            我知道了
          </Button>
        </Flex>
      );
    }
    if (+this.props.errno === -60004) {
      return (
        <Flex className={prefixCls} dir="column" align="center">
          <Flex className="title" justify="center">
            <Icon iconType="icon-warn-o" />
          </Flex>
          <p className="msg">您的系统使用时间已到期，贵司账号及数据，我们将为您保留三个月。</p>
          <p className="msg">为避免影响使用，您可以联系客服：400-611-5656 咨询续费事宜。</p>
          <p className="msg">感谢您的支持，期待继续合作！</p>
          <Button type="positive" classname="btn" onClick={this.handleRenew}>
            立即续费
          </Button>
        </Flex>
      );
    }
    return (
      <Flex className={prefixCls} dir="column" align="center">
        <Flex className="title" justify="center">
          <Icon iconType="icon-error-o" />
        </Flex>
        <p className="msg">感谢您使用本产品！您的当前服务已经到期。</p>
        <p className="msg">为避免影响正常使用，请立即购买或申请延期！</p>
        <Flex className="btn" justify="between">
          {+this.props.errno === -60002 && (
            <Button type="positive" onClick={this.toOnlineBuy}>
              立即购买
            </Button>
          )}
          {+this.props.errno === -60002 && (
            <Button type="positive-r" onClick={this.toDelayApply}>
              申请延期
            </Button>
          )}
        </Flex>
      </Flex>
    );
  };
  handleHide = () => {
    this.modal && this.modal.handleHide();
  };
  render() {
    return (
      <ModalDialog
        content={this.renderContent()}
        ref={ref => (this.modal = ref)}
        closable
        maskClosable={false}
        style={{ width: 478 }}
        isShow
        {...this.props}
      />
    );
  }
}
