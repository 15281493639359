import { CHECK, ERROR } from 'constants';
import { PopUp, PopTip } from 'components';
import { fetchApi, fetchJAVA, showInfo, typeIs } from 'utils';
import { appleOperation } from './constant';
import { rebutConfirm } from './tool';
import { assignedApprover } from 'components/commoncomponents/ApproverSelection/index';

const getReq = page => {
  const {
    fieldSet = {},
    allField = {},
    valHub = {},
    listData = [],
    filesList,
    imgList,
    billType,
    expenseType,
    oriBillNo,
    billId,
  } = page.state;
  const req = {
    type: billType,
    ori_bill_no: oriBillNo,
    group_id: window.group_id,
  };
  if (billId) req.bill_id = billId;
  if (imgList) req.bill_img = imgList;
  if (filesList) req.files = filesList;

  const errList = [];
  Object.keys(valHub).forEach(x => {
    switch (x) {
      case 'com_id':
        if (fieldSet[x].required && (!valHub[x] || !valHub[x].id)) errList.push(x);
        if (valHub[x]) req[x] = valHub[x].id;
        break;
      case 'route':
        if (fieldSet[x].required && (!valHub[x] || !valHub[x].id)) errList.push(x);
        if (valHub[x]) req[x] = valHub[x].id;
        break;
      case 'department':
        if (fieldSet[x].required && (!valHub[x] || !valHub[x].department_name)) errList.push(x);
        if (valHub[x]) req[x] = valHub[x].department_name;
        break;
      case 'operator':
        if (fieldSet[x].required && (!valHub[x] || !valHub[x].id)) errList.push(x);
        if (valHub[x]) req[x] = valHub[x].id;
        break;
      default:
        if (fieldSet[x].required && !valHub[x]) errList.push(x);
        if (valHub[x]) req[x] = valHub[x];
    }
  });
  if (errList.length) {
    return showInfo(ERROR, `${allField[errList[0]]}必填！`);
  }

  const reInfo = page.feeBreakdown.getData();
  if (reInfo.err.length) {
    return showInfo(ERROR, reInfo.err[0]);
  }

  const fee = page.capitalFlowPaymode ? page.capitalFlowPaymode.getData() : {};
  console.log('fee =', fee);
  const feeData = fee.data || [];
  // if (!feeData.length || fee.err.length) {
  // return showInfo(ERROR, '收支方式不能为空！')
  // }
  const _feeTotal = ['loan', 'income'].includes(expenseType) ? -fee.total : fee.total;
  if (
    (feeData.length > 1 || fee.total) &&
    reInfo.total &&
    _feeTotal &&
    reInfo.total.toFixed(2) !== _feeTotal.toFixed(2)
  ) {
    return showInfo(ERROR, '总金额与支付金额不相等！');
  }

  req.re_info = reInfo.data;
  if (feeData.length) req.pay_mode = feeData;
  return req;
};

export const opSave = async (page, type) => {
  const { billType, completeCallBack } = page.props;
  const { fieldSet } = page.state;
  const reqParam = getReq(page);
  if (!reqParam) return;

  let _fetch;
  let reqUrl;
  let req = {
    ori_bill_no: reqParam.ori_bill_no,
    operation: appleOperation[billType],
  };
  if (reqParam.bill_id) req.apply_id = reqParam.bill_id;

  // 切换 loading 状态
  page.switchLoadState();

  // 显示单号 走审批流   不显示单号就直接调用java接口
  if (!fieldSet.bill_no.show) {
    _fetch = fetchJAVA;
    reqUrl = '/cmm-finance/japi/finance/repay/create';
    req = { ...req, ...reqParam };
  } else {
    _fetch = fetchApi;
    reqUrl = '/Basic/Approval/apply';
    req.apply_data = reqParam;
    const appoint_user = await assignedApprover(req).catch(() => page.switchLoadState());
    if (!appoint_user) return;
    req.appoint_user = appoint_user?.id;
  }

  const fetchRes = await _fetch(reqUrl, { method: 'POST', body: { req } });
  if (fetchRes.errno !== 0) {
    page.switchLoadState();
    return showInfo(ERROR, fetchRes.errmsg);
  }

  new PopUp(PopTip, {
    type: CHECK,
    content: fieldSet.bill_no.show ? '操作成功！' : '还款成功，本次还款不会生成还款单据！',
    onClose: () => {
      page.closeCapitalFlow();
      if (completeCallBack && typeIs(completeCallBack, 'function')) completeCallBack();
    },
  }).show();
};

export const opAudit = async (page, type) => {
  const { billType, completeCallBack, rebut_direct } = page.props;
  const reqParam = getReq(page);
  if (!reqParam) return;
  reqParam.audit_type = type;

  let check_rebut_direct = false;
  const onChangeRebutDirect = checked => {
    check_rebut_direct = checked;
  };

  // 财务借支操作驳回时需要确认弹窗
  if (
    type === 'rebut' &&
    ['loan_car', 'repay_car', 'loan_driver', 'repay_driver', 'loan_employee', 'repay_employee'].includes(billType)
  ) {
    // eslint-disable-next-line no-shadow
    const result = await rebutConfirm({ onChangeRebutDirect, rebut_direct });
    if (!result) return;
  }

  const req = {
    // 通过=1，拒绝=3 驳回=4
    audit: type === 'pass' ? 1 : (type === 'rebut' && 4) || 3,
    apply_data: reqParam,
    opinion: reqParam.audit_remark,
    check_rebut_direct,
    data: [
      {
        number: reqParam.ori_bill_no,
        bill_no: reqParam.ori_bill_no,
        apply_id: reqParam.bill_id,
        operation: appleOperation[billType],
        company_id: reqParam.com_id,
        create_by: reqParam.create_by,
        table: 'bill',
      },
    ],
  };

  // 切换 loading 状态
  page.switchLoadState();

  // const reqUrl = '/cmm-finance/japi/finance/repay/audit'
  const reqUrl = '/Basic/Approval/audit';
  const fetchRes = await fetchApi(reqUrl, { method: 'POST', body: { req } });
  if (fetchRes.errno !== 0) {
    page.switchLoadState();
    const errInfo =
      fetchRes.res && fetchRes.res.failed_detail && fetchRes.res.failed_detail.length
        ? fetchRes.res.failed_detail[0].msg
        : fetchRes.errmsg;
    return showInfo(ERROR, errInfo);
  }

  new PopUp(PopTip, {
    type: CHECK,
    content: '操作成功！',
    onClose: () => {
      page.closeCapitalFlow();
      if (completeCallBack && typeIs(completeCallBack, 'function')) completeCallBack();
    },
  }).show();
};
