exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "#has_susp_dupes,.similar-fee{height:400px;padding-bottom:20px}#has_susp_dupes .fn-dialog .fn-dialog__content,.similar-fee .fn-dialog .fn-dialog__content{border:none}#has_susp_dupes .fn-dialog .fn-dialog__content .fn-dialog__title,.similar-fee .fn-dialog .fn-dialog__content .fn-dialog__title{background-color:#fff;box-shadow:none;display:flex;align-items:center;height:50px;line-height:50px;margin-bottom:0}#has_susp_dupes .fn-dialog .fn-dialog__content .fn-dialog__title .fn-icon-warn-o,.similar-fee .fn-dialog .fn-dialog__content .fn-dialog__title .fn-icon-warn-o{color:#0176f5;font-size:15px;margin-right:10px}#has_susp_dupes .fn-dialog .fn-dialog__content .fn-dialog__center,.similar-fee .fn-dialog .fn-dialog__content .fn-dialog__center{padding:0 10px 30px;border:none;max-height:500px;min-height:200px;overflow:auto}#has_susp_dupes .fn-waybill-buttons,.similar-fee .fn-waybill-buttons{margin-top:0}#has_susp_dupes .list_page_table_wraper,.similar-fee .list_page_table_wraper{margin-bottom:10px}", ""]);

// exports
exports.locals = {
	"prefixCls": "similar-fee",
	"similar-fee": "similar-fee",
	"has_susp_dupes": "has_susp_dupes",
	"fn-dialog": "fn-dialog",
	"fn-dialog__content": "fn-dialog__content",
	"fn-dialog__title": "fn-dialog__title",
	"fn-icon-warn-o": "fn-icon-warn-o",
	"fn-dialog__center": "fn-dialog__center",
	"fn-waybill-buttons": "fn-waybill-buttons",
	"list_page_table_wraper": "list_page_table_wraper"
};