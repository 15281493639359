// 订单对账
const receipt = {
  category: 'Order',
  tab: 'receipt_payment_collection_v1',
  url: 'Table/Search/orderList',
  title: '客户订单待对账数据',
  maxPickOrder: 5000, // 挑单夹最大数量
  overloadNum: 1000, // 超多少条使用全部对账
  queryIdKey: 'od_link_id', // 查询时的key值
  // 查询并新增配置
  searchConfig: {
    searchType: 'receipt',
    searchTab: 'receipt',
    searchName: 'OrderList',
    searchMaxNum: 500, // 一次性对账查询多少条数据
    searchtUrl: 'Table/Search/financePickTable',
    searchLabel: '订单号',
    searchCategory: 'Order',
    checkTab: 'receipt',
    checkNumUniqueKey: 'order_num',
    checkButtonKey: 'receiptApply', // 查询并新增校验，使用创建订单对账账时的 buttonKey
  },
  // 创建对账单时，跳转方法配置
  handleToPickerConfig: {
    pickerReqCategory: 'Order',
    pickerReqType: 'receipt',
    pickerReqTab: 'receipt',
  },
  // 挑单页相关配置
  pickOrderConfig: {
    pickOrderCollectionLabel: '对账订单数：',
    checkAndInitUrl: '/cmm-finance/japi/finance/order/apply/init', // 创建、查看订单对账获取费用信息等
    checkAndInitFetchType: 'java',
    backActiveTab: 'receipt',
    tabName: '创建客户订单对账单',
    buildUrl: 'Finance/PaymentOption/onPaymentOptionBuilding',
    buildFetchType: 'php',
    createBillUrl: 'Basic/Approval/apply', // 创建对账单
    createBillFetchType: 'php',
    approvalType: 'order_receipt_apply',
  },

  // 存储数据时，Map 的key值
  saveUniqueKey: 'od_link_id',
  // 存储数据时，需要存哪些值
  saveDataKeys: ['od_link_id', 'order_num', 'com_id'],

  // 按钮显示隐藏配置，根据挑单页打开类型来判断
  buttonsHideKey: {
    view: ['save_btn', 'cancel_btn'],
    add: ['create_order_from_collection'],
    edit: ['create_order_from_collection'],
  },
  // 使用 emitListBtnOp 方法触发事件的 btnkey
  useBtnOpButtonKeys: ['create_order_from_collection'],
  /**
   * 按钮配置
   * 固定（通用）按钮「remove_order，save_btn，cancel_btn、remove_all_order 」，业务逻辑在 PaymentCollection 内部；
   * 创建对账单：create_order_from_collection，业务配置按钮，逻辑实现通过 emitListBtnOp 将事件抛出，通过op 监听实现；
   * 根据业务需求，可以自定义添加按钮，如需要固定按钮，则保留
   */
  buttons: {
    remove_all_order: {
      type: 'ButtonMenu',
      eventNames: ['onClick'],
      children: '移除全部订单',
      iconType: 'icon-del',
      dynamic: 'false',
      showKey: 'title',
      uniqueKey: 'key',
      display: true,
      postion: 'left',
      sublist: [],
      btnType: 'default',
    },
    remove_order: {
      type: 'ButtonMenu',
      eventNames: ['onClick'],
      children: '移除已选订单',
      iconType: 'icon-del',
      dynamic: 'false',
      showKey: 'title',
      uniqueKey: 'key',
      display: true,
      postion: 'left',
      sublist: [],
      btnType: 'default',
    },
    create_order_from_collection: {
      type: 'ButtonMenu',
      eventNames: ['onClick'],
      children: '创建对账单',
      iconType: 'icon-file-add',
      dynamic: 'false',
      showKey: 'title',
      uniqueKey: 'key',
      display: true,
      postion: 'left',
      sublist: [],
      btnType: 'default',
      useParentPage: true,
    },
    save_btn: {
      type: 'ButtonMenu',
      eventNames: ['onClick'],
      children: '保存',
      iconType: '',
      dynamic: 'false',
      showKey: 'title',
      uniqueKey: 'key',
      display: true,
      postion: 'left',
      sublist: [],
      btnType: 'primary',
    },
    cancel_btn: {
      type: 'ButtonMenu',
      eventNames: ['onClick'],
      children: '取消',
      iconType: '',
      dynamic: 'false',
      showKey: 'title',
      uniqueKey: 'key',
      display: true,
      postion: 'left',
      sublist: [],
      btnType: 'default',
    },
  },
};

/**
 * 挑单页配置，根据单据类型作为 key 值，获取相应配置
 */
export const COLLECTION_PAGE_CONFIG = {
  receipt: {
    ...receipt,
  },
};
