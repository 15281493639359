import React, { useState, createContext, useEffect, useContext, useReducer, useRef } from 'react';
import { Spin, Message } from '@gui/web-react';
import { useUnmount, useWebSocket } from '@gui/hooks-react';
import { getVegaRequestConfig } from 'utils/vega';
import { sentryReporter } from 'utils/sentryHelper';
import { renderTips } from './utils';
import _ from 'lodash';

const messagesContext = createContext(null);
const messagesDispatchContext = createContext(null);

function dealHistory(history, dispatch) {
  const { isFinished, chat_record_list = [] } = history;

  dispatch({
    type: 'DISABLE_SEND',
    // 0: 未结束；1：已结束
    isDisableSend: isFinished === 0,
  });
  dispatch({
    type: 'SCROLL_TO',
    lastHistoryLength: chat_record_list?.length ?? 0,
  });

  const historyList = chat_record_list.map(el => {
    const { business_type, business_data, feed_back, chat_record_id: chatId } = el ?? {};
    const { is_finished, total_group_count, total_waybill_count, identified_data } = business_data ?? {};
    let message = {
      isHistory: true,
      chatId,
    };

    switch (business_type) {
      // 用户原始消息
      case 'llm_waybill_identify_user':
        message = {
          ...message,
          messageType: 'text',
          role: 'user',
          textList: [business_data ?? ''],
        };
        break;
      // AI 通用识别消息
      case 'llm_common_identify':
        message = {
          ...message,
          messageType: 'text',
          role: 'AI',
          textList: [business_data ?? ''],
        };
        break;
      // AI 运单识别消息
      case 'llm_waybill_identify':
        message = {
          ...message,
          messageType: 'card',
          role: 'AI',
          textList: ['识别结果如下：'],
          cardList: identified_data ?? [],
          historyFeedback: Number(feed_back),
          isFinished: is_finished,
        };
        break;
      // 建单汇总消息
      case 'llm_waybill_create_summary_info':
        message = {
          ...message,
          messageType: 'text',
          role: 'AI',
          textList: [
            '好的，财财收到！',
            `本次确定建单，共计${total_group_count}个运单组，包含${total_waybill_count}单运单。`,
            '正在建单中...',
          ],
          cardList: business_data,
        };
        break;
      // 进度消息
      case 'llm_waybill_create_progress_info':
        message = {
          ...message,
          messageType: 'progress',
          role: 'AI',
          ...business_data,
        };
        break;
      // 建单结束消息
      case 'llm_waybill_create_waybill_group_finished':
        message = {
          ...message,
          messageType: 'done',
          role: 'AI',
          ...business_data,
        };
        break;
      // 代号识别消息
      case 'llm_professional_terms_identify':
        message = {
          ...message,
          messageType: 'text',
          role: 'AI',
          ...business_data,
        };
        break;
      default:
        break;
    }

    return message;
  });

  dispatch({
    type: 'HISTORY_ADD',
    historyList,
  });
}

function dealMessage(message, dispatch) {
  const { data_type, data } = message ?? {};
  const {
    guide_info,
    max_chat_record_id,
    identified_chat_record_id,
    waybill_submit_id,
    total_group_count,
    total_waybill_count,
    total_count,
    handled_count,
    waybill_group_info,
    identified_data,
  } = data ?? {};

  switch (data_type) {
    case 'llm_guide':
      dispatch({
        type: 'AI_ADD_GUIDE',
        messageType: 'text',
        role: 'AI',
        textList: guide_info,
        maxChatRecordId: max_chat_record_id,
      });
      break;
    case 'llm_identify_start':
      dispatch({
        type: 'AI_IDENTIFY_START',
        messageType: 'text',
        role: 'AI',
        cardList: [],
        textList: ['好的，正在识别中....', <Spin dot loading />],
      });
      break;
    case 'llm_common_identify':
      dispatch({
        type: 'AI_COMMON_TEXT',
        messageType: 'text',
        role: 'AI',
        chatId: identified_chat_record_id,
        textList: [],
        streamText: identified_data ?? '',
      });
      break;
    case 'llm_waybill_identify':
      dispatch({
        type: 'AI_CARD_ADD',
        messageType: 'card',
        chatId: identified_chat_record_id,
        isFinished: 0,
        card: identified_data ?? {},
        textList: ['识别结果如下：'],
        role: 'AI',
      });
      break;
    case 'llm_identify_finished':
      dispatch({
        type: 'AI_IDENTIFY_END',
        isFinished: 1,
        chatId: identified_chat_record_id,
        role: 'AI',
      });
      dispatch({
        type: 'DISABLE_SEND',
        isDisableSend: false,
      });
      break;
    case 'llm_waybill_create_summary_info':
      dispatch({
        type: 'CREATE_START',
        messageType: 'text',
        role: 'AI',
        textList: [
          '好的，财财收到！',
          `本次确定建单，共计${total_group_count}个运单组，包含${total_waybill_count}单运单。`,
          ' 正在建单中...',
        ],
      });

      dispatch({
        type: 'CREATE_PROGRESS_ADD',
        messageType: 'progress',
        role: 'AI',
        waybill_submit_id,
        total_count: total_waybill_count,
        handled_count: 0,
      });
      break;
    case 'llm_waybill_create_progress_info':
      dispatch({
        type: 'CREATE_PROGRESS_UPDATE',
        messageType: 'progress',
        role: 'AI',
        waybill_submit_id,
        total_count,
        handled_count,
      });
      break;
    case 'llm_waybill_create_waybill_group_finished':
      // 发送建单完成消息
      dispatch({
        type: 'CREATE_DONE',
        messageType: 'done',
        role: 'AI',
        ...waybill_group_info,
      });

      // 更新卡片展示
      dispatch({
        type: 'UPDATE_DONE_INFO',
        messageType: 'card',
        role: 'AI',
        chatId: Number(waybill_group_info.waybill_origin_chat_record_id),
        waybill_group_info,
      });
      break;
    case 'llm_chat_recovery':
      dealHistory(data, dispatch);
      break;
    case 'llm_professional_terms_identify':
      if (identified_data) {
        dispatch({
          type: 'UPDATE_IDENTIFIED_DATA',
          identifiedData: identified_data,
        });
      }
      break;
    default:
      break;
  }
}

function handelMessage(event, dispatch) {
  if (event.data === 'pong') {
    return;
  }

  const errMsg = '系统异常，请联系客服同学反馈！';
  let response;
  try {
    response = JSON.parse(event.data);
    const { code, data_type, msg = errMsg } = response ?? {};

    if (code !== 0 || !data_type) {
      dispatch({
        type: 'AI_IDENTIFY_ERROR',
        messageType: 'text',
        isFinished: 1,
        textList: [msg],
        role: 'AI',
      });
    } else {
      dealMessage(response, dispatch);
    }
  } catch (err) {
    dispatch({
      type: 'AI_IDENTIFY_ERROR',
      messageType: 'text',
      isFinished: 1,
      textList: [errMsg],
      role: 'AI',
    });
  }
}

const getSocketUrl = token => {
  const config = getVegaRequestConfig(`https://${process.env.VEGA_ORDER_CENTER_WEBSOCKET}`, {
    method: 'GET',
  });
  const wssUrl = `wss://${process.env.VEGA_ORDER_CENTER_WEBSOCKET}?accessid=${
    config.params.accessid
  }&sign=${encodeURIComponent(config.params.sign)}&g7timestamp=${config.params.g7timestamp}&access_token=${token}`;

  return wssUrl;
};
// web socket 最大重连数
const EXCEPTION_CONNET_COUNT_MAX = 10;
export function MessagesProvider({ children, tokenInfo, setNewWss }) {
  const [wssUrl, setWssUrl] = useState(getSocketUrl(tokenInfo?.token));
  const exceptionConnetCount = useRef(0);
  const exceptionConnetInterval = useRef();

  const [messages, dispatch] = useReducer(messagesReducer, {
    editInfo: {
      show: false,
      errMsg: '',
      chatId: 0,
      cardIndex: 0,
    },
    messages: [],
    reconnectTip: '',
    send: null,
    lastSendMessage: null,
    isDisableSend: false,
    maxChatRecordId: 0,
    scrollOption: {
      // 是否立即滚动
      immediate: false,
      // 滚动目标 list footer
      isToBottom: true,
      isHistoryMode: false,
      lastHistoryLength: 10,
    },
    codeTranslationList: [],
  });

  // 重新获取sshUrl进行替换,延迟2s设置
  const handleReplaceWssUrl = () => {
    if (exceptionConnetInterval.current) {
      clearTimeout(() => exceptionConnetInterval.current);
    }
    exceptionConnetInterval.current = setTimeout(() => setWssUrl(getSocketUrl(tokenInfo?.token)), 2000);
  };

  const { readyState, sendMessage, disconnect } = useWebSocket(wssUrl, {
    manual: false,
    onMessage: event => handelMessage(event, dispatch),
    onOpen: () => {
      if (exceptionConnetCount.current > 0) {
        dispatch({
          type: 'RECONNECT_TIP',
          reconnectTip: '网络连接已恢复，服务已恢复！',
        });
      }
      exceptionConnetCount.current = 0;
      console.info('websocket was opened:', new Date());
    },
    onClose: event => {
      if (exceptionConnetCount.current < EXCEPTION_CONNET_COUNT_MAX) {
        handleReplaceWssUrl();
      }
      exceptionConnetCount.current += 1;
      console.info('websocket was closed:', event, new Date());

      const chatIdList = messages.messages.map(el => el.chatId);
      sentryReporter
        .setContext('Websocket onclose', {
          chatIdList,
          isDisableSend: messages.isDisableSend,
          lastSendMessage: JSON.stringify(messages.lastSendMessage),
          wssUrl,
          exceptionConnetCount,
          exceptionConnetInterval,
          closeCode: event?.code,
        })
        .rdcReport({
          module: '大模型接单-websocket 连接',
          description: 'websocket 关闭',
        });
    },
    onError: event => {
      if (exceptionConnetCount.current < EXCEPTION_CONNET_COUNT_MAX) {
        handleReplaceWssUrl();
      }
      exceptionConnetCount.current += 1;
      console.info('websocket has error:', event, new Date());

      const chatIdList = messages.messages.map(el => el.chatId);
      sentryReporter
        .setContext('Websocket onError', {
          chatIdList,
          isDisableSend: messages.isDisableSend,
          lastSendMessage: JSON.stringify(messages.lastSendMessage),
          wssUrl,
          exceptionConnetCount,
          exceptionConnetInterval,
          closeCode: event?.code,
        })
        .rdcReport({
          module: '大模型接单-websocket 连接',
          description: 'websocket 出错',
        });
    },
    heartbeat: {
      message: 'ping',
      timeout: 30 * 1000, // 30s, 如果没有收到任何消息，则关闭连接触发重连
      interval: 20 * 1000, // 每 20 s
    },
    reconnectInterval: 2000,
    reconnectLimit: 0, // 因为socket URl每次都会发生变化，所以关闭内部重连机制
  });
  // 组件卸载后断掉socket连接
  useUnmount(() => {
    disconnect();
  });
  const send = (data, isResend = false) => {
    // 重发消息
    if (isResend && readyState === 1) {
      sendMessage(JSON.stringify(data));

      return;
    }

    const {
      dataType,
      scene,
      originData,
      size,
      parse_content,
      waybill_origin_chat_record_id,
      max_chat_record_id,
      waybill_group_info,
      termsName,
      termsType,
    } = data ?? {};
    const { tenantId, token } = tokenInfo;
    const { group_id, company_id, user_id } = window;
    const willSendMessage = {
      data_type: dataType,
      access_token: token,
      data: {
        domain_code: 'waybill',
        tenant_id: tenantId,
        waybill_origin_chat_record_id,
        max_chat_record_id,
        platform_group_id: group_id,
        platform_company_id: company_id,
        platform_user_id: user_id,
        channel: 4,
        llm_scene: scene,
        origin_data: originData,
        parse_content,
        waybill_group_info,
        size,
        terms_name: termsName,
        terms_type: termsType,
      },
    };

    if (readyState === 1) {
      sendMessage(JSON.stringify(willSendMessage));
    }

    // 保存用户最新发送的消息
    if (dataType === 'llm_waybill_identify') {
      dispatch({
        type: 'LAST_SEND_MESSAGE',
        lastSendMessage: willSendMessage,
      });
    }
  };
  console.info('readyState: ', readyState);

  useEffect(() => {
    // ws 打开状态，且未发过消息时发送引导、初始化 send
    if (readyState === 1 && messages.messages.length === 0) {
      dispatch({
        type: 'SEND',
        send,
      });
      send({
        dataType: 'llm_guide',
      });
    } else if (readyState === 1 && messages.isDisableSend) {
      // ws 重新连接且发送中，则重发用户发送的最新消息
      if (messages.lastSendMessage) {
        send(
          {
            ...messages.lastSendMessage,
            access_token: tokenInfo.token,
          },
          true,
        );
        // 重发后清空缓存消息，避免重复
        dispatch({
          type: 'LAST_SEND_MESSAGE',
          lastSendMessage: null,
        });
      }

      const chatIdList = messages.messages.map(el => el.chatId);
      sentryReporter
        .setContext('重连成功后重发消息数据', {
          chatIdList,
          isDisableSend: messages.isDisableSend,
          message: JSON.stringify(messages.lastSendMessage),
          wssUrl,
          exceptionConnetCount,
          exceptionConnetInterval,
        })
        .rdcReport({
          module: '大模型接单-消息重发',
          description: '重连后消息重发',
        });
    }
  }, [readyState]);

  return (
    <messagesContext.Provider value={messages}>
      <messagesDispatchContext.Provider value={dispatch}>
        {messages.send ? children : null}
      </messagesDispatchContext.Provider>
    </messagesContext.Provider>
  );
}

export function useMessages() {
  return useContext(messagesContext);
}

export function useMessagesDispatch() {
  return useContext(messagesDispatchContext);
}

function messagesReducer(state, action) {
  const { messages, scrollOption } = state;
  const {
    type,
    token,
    reconnectTip,
    send,
    lastSendMessage,
    isDisableSend,
    tenantId,
    chatId,
    waybill_submit_id,
    waybill_group_info,
    maxChatRecordId,
    historyList,
    cardList,
    card,
    cardIndex,
    imageIndex,
    errMsg,
    text,
    streamText,
    codeTranslationList,
    identifiedData = {},
  } = action ?? {};

  switch (type) {
    case 'TOKEN':
      return {
        ...state,
        token,
        tenantId,
      };
    case 'SEND':
      return {
        ...state,
        send,
      };
    case 'RECONNECT_TIP':
      return {
        ...state,
        reconnectTip,
      };
    case 'LAST_SEND_MESSAGE':
      return {
        ...state,
        lastSendMessage,
      };
    case 'DISABLE_SEND':
      return {
        ...state,
        isDisableSend,
      };
    case 'SCROLL_TO':
      return {
        ...state,
        scrollOption: {
          ...scrollOption,
          ...action,
        },
      };
    case 'USER_ADD': {
      return {
        ...state,
        messages: [
          ...messages,
          {
            ...action,
            textList: [text],
          },
        ],
      };
    }
    case 'AI_COMMON_TEXT': {
      return {
        ...state,
        messages: messages.map(el => {
          // 第一次识别回来之前没有 chatId, 则找到识别中的卡片
          if (el.chatId === chatId || el.type === 'AI_IDENTIFY_START') {
            return {
              ...el,
              ...action,
              streamText: `${el.streamText ?? ''}${streamText}`,
            };
          }
          return el;
        }),
      };
    }
    case 'AI_ADD_GUIDE': {
      return {
        ...state,
        maxChatRecordId,
        messages: [
          ...messages,
          {
            ...action,
          },
        ],
      };
    }
    case 'AI_GUIDE_IMAGE': {
      return {
        ...state,
        messages: [
          ...messages,
          {
            role: 'AI',
            messageType: 'image',
            imgUrl: [
              `https://static.g7cdn.com/fe-cdn/cyt/cmtx-tms-pro-frontend-web/images/chat/s${imageIndex}-guide.png`,
            ],
          },
        ],
      };
    }
    case 'AI_IDENTIFY_START': {
      // 是否存在识别中的消息，只能存在一个
      const hasRecognizing = messages.some(el => el.type === 'AI_IDENTIFY_START');

      // 多次收到开始识别消息，只展示一个识别中，否则 chatId 重复导致列表渲染出错
      if (hasRecognizing) {
        return state;
      } else {
        return {
          ...state,
          messages: [
            ...messages,
            {
              ...action,
            },
          ],
        };
      }
    }
    case 'AI_IDENTIFY_END': {
      const identifiedIndex = messages.findIndex(el => el.chatId === chatId);

      if (identifiedIndex > -1) {
        return {
          ...state,
          messages: messages.map(el => {
            if (el.chatId === chatId) {
              return {
                ...el,
                ...action,
              };
            }
            return el;
          }),
        };
      } else {
        // 没有任何识别内容的异常场景
        return {
          ...state,
          messages: messages.map(el => {
            if (el.type === 'AI_IDENTIFY_START') {
              return {
                ...el,
                ...action,
                textList: ['识别异常中断，请稍后重试。'],
              };
            }
            return el;
          }),
        };
      }
    }
    case 'AI_IDENTIFY_ERROR': {
      const endAllMessage = messages.map(el => {
        return {
          ...el,
          isFinished: 1,
        };
      });

      return {
        ...state,
        // 异常后结束禁用
        isDisableSend: false,
        messages: [...endAllMessage, { ...action }],
      };
    }
    case 'AI_CARD_ADD': {
      return {
        ...state,
        messages: messages.map(el => {
          const prevCardList = el.cardList ?? [];

          // 第一次识别回来之前没有 chatId, 则找到识别中的卡片
          if (el.chatId === chatId || el.type === 'AI_IDENTIFY_START') {
            return {
              ...el,
              ...action,
              cardList: [...prevCardList, card],
            };
          }
          return el;
        }),
      };
    }
    case 'AI_CARD_LIST_UPDATE': {
      return {
        ...state,
        messages: messages.map(el => {
          if (el.chatId === chatId) {
            return {
              ...el,
              cardList,
            };
          }
          return el;
        }),
      };
    }
    case 'AI_CARD_EDIT_OPEN': {
      return {
        ...state,
        editInfo: {
          show: true,
          chatId,
          cardIndex,
          errMsg,
        },
      };
    }
    case 'AI_CARD_EDIT_CLOSE': {
      return {
        ...state,
        editInfo: {
          show: false,
          chatId: 0,
          cardIndex: 0,
          errMsg: '',
        },
      };
    }
    case 'CREATE_START': {
      return {
        ...state,
        messages: [...messages, action],
      };
    }
    case 'CREATE_PROGRESS_ADD': {
      return {
        ...state,
        messages: [...messages, action],
      };
    }
    case 'CREATE_PROGRESS_UPDATE': {
      return {
        ...state,
        messages: messages.map(el => {
          if (el.waybill_submit_id === waybill_submit_id && el.messageType === 'progress') {
            return action;
          }
          return el;
        }),
      };
    }
    case 'CREATE_DONE': {
      return {
        ...state,
        messages: [...messages, action],
      };
    }
    case 'UPDATE_DONE_INFO': {
      return {
        ...state,
        messages: messages.map(el => {
          if (el.chatId === chatId) {
            return {
              ...el,
              cardList: el.cardList.map(item => {
                if (item.waybill_group_no === waybill_group_info.waybill_group_no) {
                  return {
                    ...item,
                    waybill_group_info,
                  };
                }
                return item;
              }),
            };
          }
          return el;
        }),
      };
    }
    case 'HISTORY_ADD': {
      return {
        ...state,
        messages: [...historyList, ...messages],
      };
    }
    case 'SET_CODE_LIST': {
      return {
        ...state,
        codeTranslationList,
      };
    }
    case 'UPDATE_IDENTIFIED_DATA': {
      const { codeTranslationList: codeList } = state;
      delete identifiedData?.id;
      if (identifiedData?.identified_terms_data) {
        _.set(identifiedData, 'tips', renderTips(identifiedData?.identified_terms_data));
        _.set(identifiedData, 'status', 'success');
        Message.normal({
          content: renderTips(identifiedData?.identified_terms_data),
        });
      } else {
        _.set(identifiedData, 'tips', renderTips());
        _.set(identifiedData, 'status', 'error');
        Message.error({
          content: renderTips(),
        });
      }

      codeList.forEach((item, index) => {
        if (
          item?.terms_name === identifiedData?.terms_name &&
          item?.terms_type === identifiedData?.terms_type &&
          item?.origin_terms_data === identifiedData?.origin_terms_data
        ) {
          codeList[index] = {
            ...codeList[index],
            ...identifiedData,
          };
        }
      });
      return {
        ...state,
        codeTranslationList: [...codeList],
      };
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}
