/**
 * 常量
 * Author：liubingyuan
 */
import React from 'react';
// 内容  通过 category 和 tab的驼峰形式 拼接的形式所匹配
export const contentConf = {
  // 线路货量统计表
  Report_lineGoodsStatistics: [
    <p>1、时间：按“实际发车时间”统计</p>,
    <p>2、货量：取自运单重量合计</p>,
    <p>3、环比：(本期数据/上期数据 -1)*100%</p>,
    <p>4、同比：(本期数据/上年同期数据 -1)*100%</p>,
  ],
  // 线路经营分析表
  Report_routeOperationAnalysis: [
    <p>1、同比：(本期数据/上年同期数据 -1)*100%</p>,
    <p>2、环比：(本期数据/上期数据 -1)*100%</p>,
    <p>3、标准对比：本期数据-本期标准值</p>,
    <p>4、标准值：只有以下项目有标准值</p>,
    <div className="statement-description-detail">
      <p>● 收货重泡比标准值：3:1</p>
      <p>● 发车数标准值：30车/月</p>
      <p>● 平均单车吨位数标准值：30吨/月</p>
      <p>● 平均单车方位数标准值：110方/月</p>
      <p>● 营业收入标准值：当期运单标杆价合计</p>
      <p>● 运力费用标准值：当期营业收入的50%</p>
      <p>● 业务费用标准值：20元/吨*当月运单合计吨位</p>
      <p>● 操作费用标准值：25元/吨*当月运单合计吨位</p>
      <p>● 分流费用标准值：30元/吨*当月运单合计吨位</p>
      <p>● 综合费用标准值：25元/吨*当月运单合计吨位</p>
      <p>● 费用合计标准值：业务费用标准值+操作费用标准值+分流费用标准值+运力费用标准值+综合费用标准值</p>
    </div>,
  ],
  Report_routeProfitsStatistic: [
    <p>1、利润：取自利润损益表的“本年利润”</p>,
    <p>2、环比：(本期数据/上期数据 -1)*100%</p>,
    <p>3、同比：(本期数据/上年同期数据 -1)*100%</p>,
  ],
  Report_corFlowProvinceR: [<p>在线中转的运单取运单的“中转时间”进行统计</p>],
};
