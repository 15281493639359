export const CIRCLE = 'circle'; // 圆
export const POLYGON = 'polygon'; // 多边形
export const MARKER = 'marker'; // 标记

export const FENCE = 'fence'; // 围栏
export const EXTRA = 'extra'; // 外延
export const REFER_SHAPE = 'refer_shape'; // 参照

export const FROMINPUT = 'inputBlur'; // 画圆形(多边形)的来源

export const polygonStyle = {
  draggable: true,
  bubble: true,
  fillColor: '#CE0000',
  fillOpacity: 0.3,
  strokeWeight: 2,
  strokeColor: '#A60000',
  strokeOpacity: 0.7,
  isPolyEditor: true,
  zIndex: 30,
};
export const circleStyle = {
  bubble: true,
  strokeOpacity: 0.7,
  strokeColor: '#A60000',
  strokeWeight: 2,
  strokeStyle: 'solid',
  fillColor: '#CE0000',
  fillOpacity: 0.3,
  draggable: true,
  zIndex: 30,
};
