import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ListPageConf from 'pages/ListPage';
import { Button } from 'components';
import { showInfo, postJAVA, opTips, createTip } from 'utils';
import { WARN, ERROR, CHECK } from 'constants';
import { prefixCls } from './index.scss';

const getListPageConfig = ({ url, category, tab }) => {
  return {
    pageConfig: {
      listPage: true,
      url,
      tableInfo: {
        category,
        tab,
      },
    },
  };
};
const BtnListPage = ListPageConf(
  getListPageConfig({
    url: '/Table/Search/batchList',
    category: 'Batch',
    tab: 'on_way_relation_batch',
  }),
);
const InlineListPage = ListPageConf(
  getListPageConfig({
    url: '/Table/Search/batchList',
    category: 'Batch',
    tab: 'on_way_relation_batch_in_line',
  }),
);
class ETCCheck extends PureComponent {
  static propTypes = {
    ids: PropTypes.array,
    tr_num: PropTypes.string,
    close: PropTypes.func,
    btnKey: PropTypes.string,
    entry: PropTypes.string,
    uuid: PropTypes.array,
    includeETCRecord: PropTypes.bool,
  };
  static defaultProps = {
    type: 'empty',
  };
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      query: {
        b_tr_num: props.tr_num || undefined,
        uuid: props.uuid || undefined,
      },
    };
  }
  save = async () => {
    const { ids, includeETCRecord } = this.props;
    const rows = this.list.getStateSelecteRows();
    if (rows.length === 0) {
      return showInfo(WARN, '请选择要操作的数据');
    }
    if (rows.length > 1) {
      return showInfo(WARN, '只能关联一条运单数据');
    }
    const op = async successIds => {
      const req = {
        apply_ids: successIds,
        cur_company_id: rows[0].company_id,
        b_basic_id: rows[0].b_basic_id,
        b_link_id: rows[0].b_link_id,
      };
      const btnKey = this.props.btnKey;
      if (btnKey === 'relation_fee_batch') {
        req.trade_ids = successIds;
        delete req.apply_ids;
      }
      this.setState({ loading: true });
      const saveMap = {
        relation_fee_batch: '/cmm-basic/trade/associatedTransitExpense',
        default: '/cmm-batch/batch/transitFee/associatedTransitExpense',
      };
      const res = await postJAVA(saveMap[btnKey] || saveMap.default, req);
      this.setState({ loading: false });
      if (+res.errno !== 0) {
        return showInfo(ERROR, res.errmsg);
      }
      const tipTextMap = {
        relation_fee_batch: '交易记录',
        default: '费用',
      };
      opTips({
        resData: res,
        orderType: tipTextMap[btnKey] || tipTextMap.default,
        opType: '关联',
        showSucTips: false,
        sucCallback: () => {
          const successTipMap = {
            relation_fee_batch: '关联成功的运单号会同步展示在【在途费用】记录中，请知悉~',
            default: includeETCRecord ? '匹配成功，运单号会同步展示在【ETC交易记录】中，请知悉~' : '关联成功',
          };
          createTip(successTipMap[btnKey] || successTipMap.default, CHECK, () => {}, 3000, 'tops', true).show();
          this.props.close();
        },
        continueCallback: (sucId, cb) => {
          op(sucId);
          cb();
        },
      });
    };
    op(ids);
  };
  render = () => {
    const { loading } = this.state;
    const { entry } = this.props;
    const ListPage = entry === 'match_result' ? InlineListPage : BtnListPage;
    return (
      <div className={prefixCls}>
        {entry === 'match_result' && (
          <div className="match_tip">
            说明：ETC交易记录列表所展示匹配到运单的数量与下方列表的运单数量可能存在差异，差异原因：运单被删除、运单的发车/到达时间出现调整等。可关联的运单以下方列表展示的运单为准，请知悉。
          </div>
        )}
        <ListPage isRedirect designateFilter={this.state.query} ref={ref => (this.list = ref)} />
        <footer>
          <Button type="primary" loading={loading} onClick={this.save}>
            关联
          </Button>
        </footer>
      </div>
    );
  };
}

export default ETCCheck;
