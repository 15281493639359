exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".upgrade-alert__wrap{width:100%;height:42px;background-color:#fff5e8;display:flex;align-items:center;padding:0 8px}.upgrade-alert__icon{margin-right:5px}.upgrade-alert__slogan{font-size:12px;color:#4e5969;margin-right:5px}.upgrade-alert__link{color:#252dd2;text-decoration:underline;cursor:pointer;font-weight:700}", ""]);

// exports
exports.locals = {
	"prefixCls": "upgrade-alert",
	"upgrade-alert__wrap": "upgrade-alert__wrap",
	"upgrade-alert__icon": "upgrade-alert__icon",
	"upgrade-alert__slogan": "upgrade-alert__slogan",
	"upgrade-alert__link": "upgrade-alert__link"
};