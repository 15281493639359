exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".normal-img{display:inline-table}.normal-tip{font-weight:500;color:#fa7c20;margin-left:5px}.normal-btn{text-decoration:underline;cursor:pointer;margin-left:3px}", ""]);

// exports
exports.locals = {
	"normal-img": "normal-img",
	"normal-tip": "normal-tip",
	"normal-btn": "normal-btn"
};