import React, { useEffect, useState } from 'react';
import { Switch } from '@gui/web-react';
import { IconCheck, IconClose } from '@gui/web-react/icon';
import { handleAPIResponse, postJAVA, bem } from 'utils';
import { prefixCls } from './index.scss';

const DefaultStdLineSwitch = ({ lineId, stdRouteId, disabled, initialSwitchState = false, onDefaultStdLinechange }) => {
  const cls = bem(prefixCls);
  const [switchValue, setSwitchValue] = useState(initialSwitchState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    switchValue !== initialSwitchState && setSwitchValue(initialSwitchState);
  }, [initialSwitchState]);

  const handleSwitchChange = () => {
    setLoading(true);
    handleAPIResponse(
      postJAVA('/cmm-basic/line/std_route/set_default_std_route', { line_id: lineId, std_route_id: stdRouteId }),
    )
      .then(res => {
        if (res) {
          // 触发外部更新
          if (typeof onDefaultStdLinechange === 'function') {
            onDefaultStdLinechange(stdRouteId);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className={cls('wrap')}>
      <Switch
        disabled={disabled}
        checkedIcon={<IconCheck />}
        uncheckedIcon={<IconClose />}
        loading={loading}
        checked={switchValue}
        onChange={handleSwitchChange}
      />
      <span className={cls('wrap__label')}>设为默认</span>
    </div>
  );
};
export default DefaultStdLineSwitch;
