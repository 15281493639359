import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { fetchApi, postJAVA, handleAPIResponse, showInfo, downloadFile, openExportCentre } from 'utils';
import { ERROR } from 'constants';
import { Checkbox } from '@gui/web-react';
import Load from '../../utilcomponents/loading/Load';
import { ModalDialog, Button } from 'components';
import { prefixCls } from './index.scss';

const CheckboxGroup = Checkbox.Group;
const TRUCK_ALL_LICENSE_OPTIONS = [
  {
    label: '行驶证照片',
    value: 'license_img',
    key: 'license_img',
    show: false,
  },
  {
    label: '道路运输证照片',
    value: 'r_tp_img',
    key: 'r_tp_img',
    show: false,
  },
  {
    label: '道路经营许可证照片',
    value: 'oper_img',
    key: 'oper_img',
    show: false,
  },
  {
    label: '车辆照片',
    value: 'tr_img',
    key: 'tr_img',
    show: false,
  },
  {
    label: '人车合影照片',
    value: 'p_tr_img',
    key: 'p_tr_img',
    show: false,
  },
  {
    label: '保险卡照片',
    value: 'insure_img',
    key: 'insure_img',
    show: false,
  },
  {
    label: '附件信息',
    value: 'imgs',
    key: 'imgs',
    show: true,
  },
];
const DRIVER_ALL_LICENSE_OPTIONS = [
  {
    label: '身份证正面照片',
    value: 'id_front_img',
    key: 'id_front_img',
    show: false,
  },
  {
    label: '身份证反面照片',
    value: 'id_back_img',
    key: 'id_back_img',
    show: false,
  },
  {
    label: '人证合照照片',
    value: 'p_l_img',
    key: 'p_l_img',
    show: false,
  },
  {
    label: '驾驶证照片',
    value: 'd_l_img',
    key: 'd_l_img',
    show: false,
  },
  {
    label: '从业资格证照片',
    value: 'qc_imgs',
    key: 'qc_imgs',
    show: false,
  },
  {
    label: '附件信息',
    value: 'imgs',
    key: 'imgs',
    show: true,
  },
];

export default class TruckDownloadDialog extends PureComponent {
  static defaultProps = {
    title: '证照附件下载',
  };
  static propTypes = {
    truckId: PropTypes.string,
    close: PropTypes.func,
    title: PropTypes.string,
  };
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      truckLicenseOptions: [],
      driverLicenseOptions: [],
      downloadData: {
        truck: {
          name: '车辆证照：',
          options: [],
          indeterminate: false,
          allChecked: false,
          values: [],
        },
      },
    };
  }
  componentDidMount() {
    const comSet = window.company_setting || {};
    const truckMustField = comSet.truck_must_show?.truck_must_field || {};
    const truckAllLicenseOptions = TRUCK_ALL_LICENSE_OPTIONS.map(item => {
      if (truckMustField[item.key]?.show) {
        return {
          ...item,
          show: true,
        };
      }

      return { ...item };
    });
    const truckLicenseOptions = truckAllLicenseOptions.filter(item => item.show);
    const driverMustField = comSet.driver_must_show?.driver_must_field || {};
    const driverAllLicenseOptions = DRIVER_ALL_LICENSE_OPTIONS.map(item => {
      if (driverMustField[item.key]?.show) {
        return {
          ...item,
          show: true,
        };
      }

      return { ...item };
    });
    const driverLicenseOptions = driverAllLicenseOptions.filter(item => item.show);
    const { downloadData } = this.state;

    downloadData.truck.options = truckLicenseOptions;
    this.setState({
      truckLicenseOptions,
      driverLicenseOptions,
      downloadData: JSON.parse(JSON.stringify(downloadData)),
    });
    this.fetchInfo();
  }
  componentWillUnmount() {}
  async fetchInfo() {
    this.setState({ loading: true });

    try {
      const res = await handleAPIResponse(
        fetchApi('/Basic/Truck/truckInfo', {
          method: 'POST',
          body: {
            req: {
              tab: 'truck',
              truck_id: this.props.truckId,
              truck_info: { truck_id: this.props.truckId },
              op_type: 'update',
            },
          },
        }),
      );
      const truckInfo = res?.truck_info || {};
      const { downloadData, truckLicenseOptions, driverLicenseOptions } = this.state;

      // 关联了挂车
      if (truckInfo.tr_num_ext) {
        downloadData.truck_ext = {
          name: '关联挂车证照：',
          options: truckLicenseOptions,
          indeterminate: false,
          allChecked: false,
          values: [],
        };
      }

      // 关联了主驾司机
      if (truckInfo.dr_name) {
        downloadData.main_driver = {
          name: '关联主驾证照：',
          options: driverLicenseOptions,
          indeterminate: false,
          allChecked: false,
          values: [],
        };
      }

      // 关联了副驾司机
      if (truckInfo.co_pliot_name) {
        downloadData.co_pliot_driver = {
          name: '关联副驾证照：',
          options: driverLicenseOptions,
          indeterminate: false,
          allChecked: false,
          values: [],
        };
      }

      // 关联了押运员
      if (truckInfo.escort_staff) {
        downloadData.escort_driver = {
          name: '关联押运员证照：',
          options: driverLicenseOptions,
          indeterminate: false,
          allChecked: false,
          values: [],
        };
      }

      this.setState({ downloadData: JSON.parse(JSON.stringify(downloadData)) });
      this.getHistoryChecked();
    } finally {
      this.setState({ loading: false });
    }
  }
  handleChangeAll(checked, key) {
    const { downloadData } = this.state;
    const downloadDataItem = downloadData[key];

    if (checked) {
      downloadDataItem.indeterminate = false;
      downloadDataItem.allChecked = true;
      downloadDataItem.values = downloadDataItem.options.map(item => item.value);
    } else {
      downloadDataItem.indeterminate = false;
      downloadDataItem.allChecked = false;
      downloadDataItem.values = [];
    }

    this.setState({ downloadData: JSON.parse(JSON.stringify(downloadData)) });
  }
  handleChange(checkedList, key) {
    const { downloadData } = this.state;
    const downloadDataItem = downloadData[key];

    downloadDataItem.indeterminate = !!(checkedList.length && checkedList.length !== downloadDataItem.options.length);
    downloadDataItem.allChecked = !!(checkedList.length === downloadDataItem.options.length);
    downloadDataItem.values = checkedList;

    this.setState({ downloadData: JSON.parse(JSON.stringify(downloadData)) });
  }
  async handleDownLoad() {
    this.setState({ loading: true });

    try {
      const { downloadData } = this.state;
      const downloadDataKeys = Object.keys(downloadData);
      const downloadCheckedData = {};
      let valid = false;

      downloadDataKeys.forEach(key => {
        downloadCheckedData[key] = downloadData[key].values;

        if (downloadData[key].values.length) {
          valid = true;
        }
      });

      if (valid) {
        const param = {
          category: 'Truck',
          format: 'zip',
          ids: [this.props.truckId],
          download_filed: downloadCheckedData,
        };
        const res = await handleAPIResponse(
          fetchApi('/Table/Search/checkedTruckDownLoad', {
            method: 'POST',
            body: {
              req: param,
            },
          }),
        );

        // 走导出中心
        if (res?.use_export_center === 1) {
          await handleAPIResponse(postJAVA('/ge-truck-export/front/export/truckInfoFiles', param));
          openExportCentre();
        } else {
          downloadFile(`/api/Table/Search/truckDownLoad?req=${JSON.stringify(param)}`);
        }

        localStorage.setItem('truckDownloadHistoryCheckedData', JSON.stringify(downloadCheckedData));
        this.truckDownloadDialog.handleHide();
      } else {
        showInfo(ERROR, '请至少选择一项');
      }
    } finally {
      this.setState({ loading: false });
    }
  }
  getHistoryChecked() {
    const { downloadData } = this.state;
    const downloadDataKeys = Object.keys(downloadData);
    const historyCheckedData = JSON.parse(localStorage.getItem('truckDownloadHistoryCheckedData') || '{}');

    downloadDataKeys.forEach(key => {
      const allValues = downloadData[key].options.map(item => item.value);

      if (historyCheckedData[key]) {
        const historyCheckedList = [];

        historyCheckedData[key].forEach(value => {
          if (allValues.includes(value)) {
            historyCheckedList.push(value);
          }
        });
        this.handleChange(historyCheckedList, key);
      } else {
        this.handleChange(allValues, key);
      }
    });
  }
  render() {
    const { title } = this.props;
    const { loading, downloadData } = this.state;
    const downloadDataKeys = Object.keys(downloadData);
    const content = (
      <Load spinning={loading}>
        <div className={`${prefixCls}-content`}>
          {downloadDataKeys.map(key => (
            <div className={`${prefixCls}-content-item`} key={key}>
              <div className={`${prefixCls}-content-title`}>
                <Checkbox
                  checked={downloadData[key].allChecked}
                  indeterminate={downloadData[key].indeterminate}
                  onChange={checked => this.handleChangeAll(checked, key)}
                >
                  {downloadData[key].name}
                </Checkbox>
              </div>
              <div className={`${prefixCls}-content-group`}>
                <CheckboxGroup
                  direction="vertical"
                  options={downloadData[key].options}
                  value={downloadData[key].values}
                  onChange={checkedList => this.handleChange(checkedList, key)}
                />
              </div>
            </div>
          ))}
        </div>
        <div className={`${prefixCls}-tip`}>
          注：勾选上方需要下载的证照后，点击”下载“按钮，系统会将勾选的证照生成一个压缩包并进行下载。
        </div>
      </Load>
    );
    const bottom = [
      <Button key="cancel" onClick={() => this.truckDownloadDialog.handleHide()}>
        取消
      </Button>,
      <Button key="download" onClick={() => this.handleDownLoad()} type="primary">
        下载
      </Button>,
    ];
    return (
      <ModalDialog
        title={title}
        content={content}
        bottom={bottom}
        isShow
        classname={prefixCls}
        closable
        close={this.props.close}
        maskClosable={false}
        ref={r => (this.truckDownloadDialog = r)}
      />
    );
  }
}
