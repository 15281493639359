import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, PureInput } from 'components';
import { PRICE_TYPE_ENUM } from '../../constant';

const BaseInfo = props => {
  const { setRuleFilterData, initialData = {} } = props;
  const { name: defaultName = '', type: defaultType } = initialData;
  const [name, setName] = useState(defaultName);
  const [type, setType] = useState(defaultType || 'order');

  // Effect监听传递数据变更
  useEffect(() => {
    setRuleFilterData({ name, type });
  }, [name, type]);

  return (
    <div className="fn-card__form">
      <div className="fn-card__form__card-item">
        <label className="fn-label">规则名称：</label>
        <PureInput value={name} required onChange={e => setName(e?.target?.value)} />
      </div>
      <div className="fn-card__form__card-item">
        <label className="fn-label">取数表单：</label>
        <Select
          value={type}
          required
          data={PRICE_TYPE_ENUM}
          map={false}
          compare="type"
          onChange={val => setType(val?.type)}
        />
      </div>
    </div>
  );
};

BaseInfo.propTypes = {
  setRuleFilterData: PropTypes.func,
  initialData: PropTypes.object,
};

export default BaseInfo;
