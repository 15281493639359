module.exports = {
  wv_radio: {
    tr_up: {
      weightKey: 'b_tr_load_w',
      volumeKey: 'b_tr_load_v',
    },
    delivery_batch: {
      weightKey: 'b_delivery_load_w',
      volumeKey: 'b_delivery_load_v',
    },
    shuttle_up: {
      weightKey: 'b_shuttle_load_w',
      volumeKey: 'b_shuttle_load_v',
    },
    pickup_batch: {
      weightKey: 'b_pickup_load_w',
      volumeKey: 'b_pickup_load_v',
    },
  },
  point_batch_settle_detail: {
    // 网点批次对帐，发车批次
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
    printUrl: '/Table/Search/trLoadingList',
  },
  tr_up: {
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
    printUrl: '/Table/Search/trLoadingList',
    need_calc_price: window.company_setting.truck_calc_price && window.company_setting.truck_calc_price.checked,
  },
  pre_tr_up: {
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/TruckPre/onLoading',
  },
  tr_pre_loading: {
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/TruckPre/onLoading',
  },
  tr_up_arrival: {
    // 已到达
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
  },
  tr_up_depart: {
    // 在途中
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
  },
  tr_up_loading: {
    // 配载中
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
  },
  tr_down: {
    type: 'show',
    hasTab: true,
    printTab: 'tr_loading_down_list_print',
    printType: 2,
    pageUrl: '/Truck/Truck/onLoading',
  },
  pre_tr_down: {
    type: 'show',
    hasTab: true,
    printTab: 'tr_loading_down_list_print',
    printType: 2,
    pageUrl: '/Truck/TruckPre/onLoading',
  },
  tr_down_depart: {
    // 在途中
    type: 'show',
    hasTab: true,
    printTab: 'tr_loading_down_list_print',
    printType: 2,
    pageUrl: '/Truck/Truck/onLoading',
  },
  tr_down_arrival: {
    // 已到达
    type: 'show',
    hasTab: true,
    printTab: 'tr_loading_down_list_print',
    printType: 2,
    pageUrl: '/Truck/Truck/onLoading',
  },
  shuttle_up: {
    type: 'edit',
    hasTab: false,
    pageUrl: '/Truck/Shuttle/onLoading',
  },
  shuttle_down: {
    type: 'show',
    hasTab: false,
    pageUrl: '/Truck/Shuttle/onLoading',
  },
  delivery_batch: {
    type: 'edit',
    hasTab: false,
    pageUrl: '/Truck/Delivery/onLoading',
    need_calc_price: window.company_setting.delivery_calc_price && window.company_setting.delivery_calc_price.checked,
  },
  pickup_batch: {
    type: 'edit',
    hasTab: false,
    pageUrl: '/Truck/Pickup/onLoading',
    priceKeyList: ['pickup_f'],
    priceType: 'pickup_pt',
    need_calc_price: window.company_setting.pickup_calc_price && window.company_setting.pickup_calc_price.checked,
  },
  trans_inner_to_trans: {
    pageUrl: '/Order/Trans/trans',
  },
  trans_inner_accepted: {
    pageUrl: '/Order/Trans/trans',
  },
  trans_inner_to_deal: {
    pageUrl: '/Order/Trans/trans',
  },
  trans_to_trans: {
    pageUrl: '/Order/Trans/trans',
  },
  trans_to_deal: {
    pageUrl: '/Order/Trans/trans',
  },
  trans_inner_transed: {
    pageUrl: '/Order/Trans/trans',
  },
  b_inner_trans_out: {
    // 内部中转->转出
    pageUrl: '/Order/Trans/trans',
    printUrl: '/Table/Search/orderList',
    printTab: 'inner_trans_batch_detail_right_print',
    printTitle: '内部中转-转出打印预览',
  },
  b_inner_trans_in: {
    // 内部中转->转入
    pageUrl: '/Order/Trans/trans',
    printUrl: '/Table/Search/orderList',
    printTab: 'inner_trans_in_batch_detail_right_print',
    printTitle: '内部中转-转入打印预览',
  },
  b_trans_out: {
    // 外部中转->转出
    pageUrl: '/Order/Trans/trans',
    printUrl: '/Table/Search/orderList',
    printTab: 'trans_batch_detail_right_print',
    printTitle: '外部中转-转出打印预览',
  },
  b_trans_in: {
    // 外部中转->转入
    pageUrl: '/Order/Trans/trans',
    printUrl: '/Table/Search/orderList',
    printTab: 'trans_in_batch_detail_right_print',
    printTitle: '外部中转-转入打印预览',
  },
  b_point_trans_in: {
    pageUrl: '/Order/Trans/trans',
  },
  settle_batch_shuttle: {
    pageUrl: '/Truck/Shuttle/onLoading',
  },
  settle_batch_shuttle_delivery: {
    pageUrl: '/Truck/Delivery/onLoading',
  },
  settle_batch_shuttle_pickup: {
    pageUrl: '/Truck/Pickup/onLoading',
  },
  payment_batch_shuttle: {
    pageUrl: '/Truck/Shuttle/onLoading',
  },
  payment_batch_delivery: {
    pageUrl: '/Truck/Delivery/onLoading',
  },
  payment_batch_pickup: {
    pageUrl: '/Truck/Pickup/onLoading',
  },
  confirm_batch_delivery: {
    pageUrl: '/Truck/Delivery/onLoading',
  },
  cash_batch_delivery: {
    pageUrl: '/Truck/Delivery/onLoading',
  },
  confirm_batch_shuttle: {
    pageUrl: '/Truck/Shuttle/onLoading',
  },
  ledger_batch_shuttle: {
    pageUrl: '/Truck/Shuttle/onLoading',
  },
  ledger_batch_delivery: {
    pageUrl: '/Truck/Delivery/onLoading',
  },
  settle_batch_start: {
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
  },
  settle_batch_to: {
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
  },
  settle_batch_billing: {
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
  },
  settle_batch_fuel_card: {
    // 现付油卡费结算
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
  },
  settle_batch_rcp: {
    // 回付运输费结算
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
  },
  settle_batch_insur: {
    // 整车保险费结算
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
  },
  settle_batch_arr: {
    // 到付运输费结算
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
  },
  settle_batch_loc_loading: {
    // 发站装车费结算
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
  },
  settle_batch_loc_misc: {
    // 发站其他费结算
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
  },
  settle_batch_rmt_unload: {
    // 到站卸车费结算
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
  },
  settle_batch_rmt_misc: {
    // 到站其他费结算
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
  },
  settle_batch_info: {
    // 整车信息费结算
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
  },
  cash_batch_start: {
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
  },
  cash_batch_to: {
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
  },
  payment_batch_start: {
    // 收付款-发车汇总
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
  },
  payment_batch_to: {
    // 收付款-到车汇总
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
  },
  payment_batch_billing: {
    // 收付款-现付运输费
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
  },
  payment_batch_fuel_card: {
    // 收付款-现付油卡费
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
  },
  payment_batch_rcp: {
    // 收付款-回付运输费
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
  },
  payment_batch_insur: {
    // 收付款-整车保险费
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
  },
  payment_batch_loc_misc: {
    // 收付款-发站其他费
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
  },
  payment_batch_arr: {
    // 收付款-到付运输费
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
  },
  payment_batch_loc_loading: {
    // 收付款-发站装车费
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
  },
  payment_batch_rmt_unload: {
    // 收付款-到站卸车费
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
  },
  payment_batch_rmt_misc: {
    // 收付款-到站其他费
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
  },
  receipt_batch_info_f: {
    // 收付款-整车信息费
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
  },
  ledger_batch_start: {
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
  },
  confirm_batch_to: {
    type: 'edit',
    hasTab: true,
    printTab: 'tr_loading_up_list_print',
    printType: 1,
    pageUrl: '/Truck/Truck/onLoading',
  },
  doc_delivery_detail: {
    pageUrl: '/Truck/Delivery/onLoading',
  },
  pickup_reservation_batch: {
    pageUrl: '/Reservation/Pickup/onLoading',
  },
  pickup_reservation: {
    pageUrl: '/Reservation/Pickup/onLoading',
  },
  pickup_order: {
    pageUrl: '/Truck/Pickup/onLoading',
  },
  pack_default: {
    pageUrl: '/Truck/Pack/onLoading',
    printUrl: '/Table/Search/orderList',
    printTab: 'pack_list_print',
    printTitle: '打包清单-打印预览',
  },
  tr_up_loading_list: {
    pageUrl: '/Truck/Pack/onLoading',
  },
  tr_down_loading_list: {
    pageUrl: '/Truck/Pack/onLoading',
  },
  delivery_list: {
    pageUrl: '/Truck/Pack/onLoading',
  },
  platform_task: {
    // 平台任务
    printTab: 'platform_task_print',
    printType: 1,
    pageUrl: 'Truck/PlatformTask/onLoading',
  },
};
