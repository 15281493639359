import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { prefixCls } from './index.scss';
import cytUpgradeGuider from 'utils/cytUpgradeGuider';
import { CDN_PATH } from 'utils/cytUpgradeGuider/tool';

export default class UpgradeAlert extends PureComponent {
  static propTypes = {
    text: PropTypes.string,
    // 仅需传 name/place/guideImage
    guideOptions: PropTypes.object,
  };

  handleClick = () => {
    cytUpgradeGuider({
      ...this.props.guideOptions,
      type: 'popup',
      show: true,
    });
  };

  render() {
    const { text } = this.props;
    return (
      <section className={`${prefixCls}__wrap`}>
        <img className={`${prefixCls}__icon`} src={`${CDN_PATH}/icon_upgrade_notify.png`} alt="" />
        <span className={`${prefixCls}__slogan`}>{text}</span>
        <span className={`${prefixCls}__link`} onClick={this.handleClick}>
          立即体验
        </span>
      </section>
    );
  }
}
