/**
 * 工具函数
 */

/**
 * 高德的经纬度转成数组
 */
export const lngLatToArr = lngLat => [lngLat.getLng(), lngLat.getLat()];

/**
 * 获取屏幕DPI的算法
 */
const getDPI = () => {
  let dpi;
  if (window.screen.deviceXDPI !== undefined) {
    dpi = window.screen.deviceXDPI;
    // arrDPI[1] = window.screen.deviceYDPI
  } else {
    const tmpNode = document.createElement('DIV');
    tmpNode.style.cssText = 'width:1mm;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden';
    document.body.appendChild(tmpNode);
    dpi = parseInt(tmpNode.offsetWidth, 0);
    // arrDPI[1] = parseInt(tmpNode.offsetHeight, 0)
    tmpNode.parentNode.removeChild(tmpNode);
  }
  return dpi;
};

/**
 * 计算多边形的外延
 * 算法详情可移步：https://blog.csdn.net/sun_and_breeze/article/details/107517088 去看
 * @param {*} map 高德地图map对象
 * @param {*} zoom 地图缩放比例
 * @param {*} scale 地图比例尺. 高德地图可以通过map.getScale()来获取
 * @param {*} paths 多边形顶点数组
 * @param {*} extra 外延大小。为正: 向外扩; 为负: 向内缩
 * @return 扩展或缩小后的多边形顶点数组
 */
export const calcPolygonExtra = (map, zoom, scale, mapPaths, extra) => {
  if (!zoom) return;
  const norm = (x, y) => Math.sqrt(x * x + y * y);

  let paths = mapPaths;
  let clockwise = false; // 是否是顺时针画的
  const len = paths.length;
  // 判断是顺时针画的还是逆时针画的
  const P1 = map.lnglatToPixel(paths[0], zoom);
  const P2 = map.lnglatToPixel(paths[1], zoom);
  const P3 = map.lnglatToPixel(paths[2], zoom);
  const V12x = P2.x - P1.x; // 向量P12 横坐标
  const V12y = P2.y - P1.y; // 向量P12 纵坐标
  const V32x = P2.x - P3.x; // 向量P32 横坐标
  const V32y = P2.y - P3.y; // 向量P32 纵坐标
  if (V12x * V32y - V12y * V32x < 0) {
    // 如果是顺时针就倒过来
    paths = paths.reverse();
    clockwise = true;
  }
  // 获取实际1m对应像素是多少
  const extraPixel = (extra / scale) * getDPI() * 1000;
  let polygon = [];
  for (let i = 0; i < len; i++) {
    const point = map.lnglatToPixel(paths[i], zoom); // P 点
    const point1 = map.lnglatToPixel(paths[i === 0 ? len - 1 : i - 1], zoom); // P1 点
    const point2 = map.lnglatToPixel(paths[i === len - 1 ? 0 : i + 1], zoom); // P2 点

    // 向量PP1
    const vectorX1 = point1.x - point.x; // 向量PP1 横坐标
    const vectorY1 = point1.y - point.y; // 向量PP1 纵坐标
    const n1 = norm(vectorX1, vectorY1); // 向量的平方根 为了对向量PP1做单位化
    let vectorUnitX1 = vectorX1 / n1 || 0; // 向量单位化 横坐标
    let vectorUnitY1 = vectorY1 / n1 || 0; // 向量单位化 纵坐标

    // 向量PP2
    const vectorX2 = point2.x - point.x; // 向量PP2 横坐标
    const vectorY2 = point2.y - point.y; // 向量PP2 纵坐标
    const n2 = norm(vectorX2, vectorY2); // 向量的平方根 为了对向量PP1做单位化
    let vectorUnitX2 = vectorX2 / n2 || 0; // 向量单位化 横坐标
    let vectorUnitY2 = vectorY2 / n2 || 0; // 向量单位化 纵坐标

    // PQ距离
    const vectorLen = -extraPixel / Math.sqrt((1 - (vectorUnitX1 * vectorUnitX2 + vectorUnitY1 * vectorUnitY2)) / 2);

    // 根据向量的叉乘积来判断角是凹角还是凸角
    if (vectorX1 * vectorY2 + -1 * vectorY1 * vectorX2 < 0) {
      vectorUnitX2 *= -1;
      vectorUnitY2 *= -1;
      vectorUnitX1 *= -1;
      vectorUnitY1 *= -1;
    }
    // PQ的方向
    const vectorX = vectorUnitX1 + vectorUnitX2;
    const vectorY = vectorUnitY1 + vectorUnitY2;
    const n = vectorLen / norm(vectorX, vectorY);
    const vectorUnitX = vectorX * n;
    const vectorUnitY = vectorY * n;

    const polygonX = vectorUnitX + point.x;
    const polygonY = vectorUnitY + point.y;
    const polygonLngLat = map.pixelToLngLat(new window.AMap.Pixel(polygonX, polygonY), zoom);

    polygon[i] = [polygonLngLat.getLng(), polygonLngLat.getLat()];
  }

  if (clockwise) polygon = polygon.reverse();

  return polygon;
};

/**
 * 地址 -> 坐标
 * @param {*} map 地图
 * @param {*} lnglat 经纬度 [lng, lat]
 */
export const regeocoder = (map, lnglat) =>
  new Promise(resolve => {
    window.AMap.plugin('AMap.Geocoder', () => {
      const geocoder = new window.AMap.Geocoder({
        radius: 1000,
        city: '全国',
        extensions: 'all',
      });
      geocoder.getAddress(lnglat, (status, r) => {
        if (status === 'complete' && r.info === 'OK') {
          if (!map) resolve({});
          const { addressComponent } = r.regeocode;
          const city = !addressComponent.city.length ? addressComponent.province : addressComponent.city;
          const poiData = {
            province: addressComponent.province,
            city,
            district: addressComponent.district,
            adcode: addressComponent.adcode,
            poi: lnglat.join(','),
            street: addressComponent.township,
            show_val: r.regeocode.formattedAddress,
          };
          resolve(poiData);
        }
        resolve(false);
      });
    });
  });
