import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';
import classnames from 'classnames';
import _, { isArray } from 'lodash';
import { postJAVA } from 'utils';
import Load from '../../utilcomponents/loading/Load';
import Tabs from '../../utilcomponents/Tabs';
import { prefixCls } from './index.scss';

const { TabPane } = Tabs;

export default class PriceSnapshot extends PureComponent {
  static propTypes = {
    data: PropTypes.object, // 快照数据
    fee: PropTypes.string, // 费用key 如 co_freight
    bizID: PropTypes.string, // 运单 od_basic_id 批次,任务 batch_id
    type: PropTypes.string, // 运单：yd 批次：pc 任务：rw,
    onComponentUpdate: PropTypes.func,
    expect: PropTypes.string, // 期望计算结果，如果请求结果和期望不等，不会显示
    isHidden: PropTypes.string, // 是否是隐藏字段
  };

  state = {};

  componentDidUpdate() {
    this.props.onComponentUpdate && this.props.onComponentUpdate();
  }

  UNSAFE_componentWillMount() {
    const setting = window.company_setting;
    this.settingParams = {
      transport_mode_list: setting.transport_mode_list,
      goods_cat: setting.goods_cat,
      goods_name: setting.goods_name,
      goods_special: setting.goods_special,
      pkg_name: setting.pkg_name,
    };
    this.props.data ? this.convertData() : this.getData();
  }

  getData() {
    const { fee, bizID, type, expect } = this.props;
    this.setState({ loading: true });
    const timer = setTimeout(() => this.setState({ showLoading: true }), 100);
    const req = { fee_code: fee, biz_id: bizID, type, settings: this.settingParams };
    postJAVA('/cmm-pricesys/Basic/PriceSystemV2/getProcessSnapshot', req).then(res => {
      const isExpected = expect ? +_.get(res.res, '0.result_price') === +expect : true;
      const data = isExpected ? res.res : {};
      this.setState({ loading: false, data });
      clearTimeout(timer);
    });
  }

  convertData() {
    const { data } = this.props;
    this.setState({ loading: true });
    const timer = setTimeout(() => this.setState({ showLoading: true }), 100);
    const req = { snapshot: data, settings: this.settingParams };
    postJAVA('/cmm-pricesys/japi/Basic/PriceSystemV2/convertProcessSnapshot', req).then(res => {
      this.setState({ loading: false, data: res.res });
      clearTimeout(timer);
    });
  }

  renderItem(data) {
    const isFormula = data.price_cal && ['公式', '公式系数'].includes(data.price_cal.price_mode);

    // 费用属性 抹平为数组
    const fee_attr = data?.price_cal?.fee_attr ?? {};
    const finalFeeAttrArr = isArray(fee_attr) ? fee_attr : [fee_attr];

    return (
      <div className="table-wrap">
        <table>
          <tr>
            <td width="90">报价名称</td>
            <td colSpan="2" width="417" className="price-name">
              {data.price_name} <span>【{data.update_time}修订】</span>
            </td>
            <td width="120">实际数据</td>
            <td width="90">计算结果</td>
          </tr>
          {data.price_attr &&
            data.price_attr.map((item, i) => (
              <tr>
                {i === 0 && <td rowSpan={data.price_attr.length}>报价属性</td>}
                <td>{item.price_attr_name}</td>
                <td>
                  {item.price_attr === 'order_arr_a_pt'
                    ? item.setting_values.map(addrItem => addrItem.show_val).join('；')
                    : item.setting_values.join('；')}
                </td>
                <td>{item.real_values.join('；')}</td>
                <td>&nbsp;</td>
              </tr>
            ))}
          {data.preprocess_data &&
            data.preprocess_data.map((item, i) => (
              <Fragment>
                <tr>
                  {i === 0 && <td rowSpan={data.preprocess_data.length * 2}>数据预处理</td>}
                  <td>预处理条件{i + 1}</td>
                  <td>
                    {item.preprocess_condition.map(c => (
                      <div>
                        {c.price_attr_name}:
                        {c.price_attr === 'order_arr_a_pt'
                          ? c.setting_values.map(addrItem => addrItem.show_val).join('，')
                          : c.setting_values.join('，')}
                      </div>
                    ))}
                  </td>
                  <td>
                    {item.preprocess_condition.map(c => (
                      <div>
                        {c.price_attr_name}:{c.real_values.join('，')}
                      </div>
                    ))}
                  </td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>预处理规则{i + 1}</td>
                  <td>{item.preprocess_rule.setting_value && item.preprocess_rule.setting_value.formula}</td>
                  <td>
                    {item.preprocess_rule.real_values.price_attr_name}：
                    <span className="text-red">{item.preprocess_rule.real_values.value}</span>
                    {item.preprocess_rule.real_values.unit}
                  </td>
                  <td className="ar">
                    <span className="text-red">{item.preprocess_rule.cal_result.value}</span>
                    {item.preprocess_rule.cal_result.unit}
                  </td>
                </tr>
              </Fragment>
            ))}
          <tr>
            <td
              rowSpan={
                2 +
                (data.price_cal
                  ? finalFeeAttrArr.length +
                    !!data.price_cal.price_value +
                    ((data.price_cal.range_price_detail && data.price_cal.range_price_detail.length) || 0) +
                    !!data.price_cal.adjust +
                    !!data.price_cal.lowest +
                    !!data.price_cal.highest
                  : 0)
              }
            >
              计价规则
            </td>
            <td width="90" className="text-gray">
              计价方式
            </td>
            <td colSpan={3}>{data.price_cal && data.price_cal.price_mode}</td>
          </tr>
          <tr>
            <td className="text-gray">计价规则</td>
            <td colSpan={isFormula ? undefined : 3}>{data.price_cal && data.price_cal.price_rule}</td>
            {isFormula && data.price_cal && (
              <td>
                {data.price_cal.price_rule_values &&
                  data.price_cal.price_rule_values.map(r => (
                    <span>
                      {r.price_attr_name}：<span className="text-red">{r.value}</span>
                      {r.unit}
                    </span>
                  ))}
              </td>
            )}
            {isFormula && data.price_cal && <td className="text-red ar">&yen;{data.price_cal.cal_result.value}</td>}
          </tr>
          {data.price_cal && data.price_cal.price_value && (
            <tr>
              <td className="text-gray">取值规则</td>
              <td colSpan={3}>
                <span>重量：{data.price_cal.price_value.weight_value}</span>
                <span> 体积：{data.price_cal.price_value.volume_value}</span>
                <span> 价格：{data.price_cal.price_value.fee_value}</span>
              </td>
            </tr>
          )}
          {finalFeeAttrArr.map((item, index) => (
            <tr key={index}>
              <td className="text-gray">{item?.fee_attr_name}</td>
              <td>{item?.setting_value}</td>
              <td>{item?.real_value}</td>
              <td> &nbsp;</td>
            </tr>
          ))}
          {data.price_cal &&
            data.price_cal.range_price_detail &&
            data.price_cal.range_price_detail.map((item, index) => (
              <tr key={index}>
                <td className="text-gray">{item.range_price_name}</td>
                <td>{item.setting_value}</td>
                <td>
                  {item.real_values &&
                    item.real_values.map(r => (
                      <div className="nowrap">
                        {r.price_attr_name}：<span className="text-red">{r.value}</span>
                        {r.unit}
                      </div>
                    ))}
                </td>
                <td className="text-red ar">&yen;{item.cal_result.value}</td>
              </tr>
            ))}
          {data.price_cal && data.price_cal.adjust && (
            <tr>
              <td className="text-gray">调价</td>
              <td>{data.price_cal.adjust.setting_value}</td>
              <td>&nbsp;</td>
              <td className="text-red ar">
                {data.price_cal.adjust.cal_result && <span>&yen;{data.price_cal.adjust.cal_result.value}</span>}
              </td>
            </tr>
          )}
          {data.price_cal && data.price_cal.lowest && (
            <tr>
              <td className="text-gray">最低价</td>
              <td>{data.price_cal.lowest.setting_value}</td>
              <td>&nbsp;</td>
              <td className="text-red ar">
                {data.price_cal.lowest.cal_result && <span>&yen;{data.price_cal.lowest.cal_result.value}</span>}
              </td>
            </tr>
          )}
          {data.price_cal && data.price_cal.highest && (
            <tr>
              <td className="text-gray">最高价</td>
              <td>{data.price_cal.highest.setting_value}</td>
              <td>&nbsp;</td>
              <td className="text-red ar">
                {data.price_cal.highest.cal_result && <span>&yen;{data.price_cal.highest.cal_result.value}</span>}
              </td>
            </tr>
          )}
          <tr>
            <td rowSpan={2}>折扣/上浮</td>
            <td className="text-gray">规则名称</td>
            <td>{data.discount_info && data.discount_info.name}</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td className="text-gray">折扣系数</td>
            <td>{data.discount_info && data.discount_info.radio}</td>
            <td>&nbsp;</td>
            <td className="text-red final ar">
              {data.discount_info && data.discount_info.cal_result && (
                <span>&yen;{data.discount_info.cal_result.value}</span>
              )}
            </td>
          </tr>
        </table>
      </div>
    );
  }

  render() {
    console.log(this.props, 'isHiddenisHiddenisHiddenisHidden');
    const { showLoading, loading, data } = this.state;
    const { isHidden } = this.props;
    const noData = data && !data[0]?.price_cal;
    const calcType = (data && data[0] && +data[0].calc_type) || 1;
    if (loading && !showLoading) return null;
    if (isHidden)
      return (
        <div className={classnames({ [prefixCls]: true, 'no-data': true })}>
          提示： <br />
          您无权查看
        </div>
      );
    if (noData)
      return (
        <div className={classnames({ [prefixCls]: true, 'no-data': noData })}>
          提示： <br />
          {isHidden === null || isHidden === '***' ? '您无权查看' : '不是系统计算的价格'}
        </div>
      );
    return (
      <Load spinning={showLoading && loading} className={prefixCls}>
        {data && calcType === 1 && (
          <Tabs animated={false} onChange={this.onTabChange}>
            {data.map((item, i) => (
              <TabPane
                tab={
                  <span>
                    货物{i + 1}
                    <span className="text-red">(¥{item.result_price})</span>
                  </span>
                }
                key={i}
              >
                {this.renderItem(item)}
              </TabPane>
            ))}
          </Tabs>
        )}
        {data && calcType !== 1 && this.renderItem(data[0])}
      </Load>
    );
  }
}
