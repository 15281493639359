import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import actions from 'actions';

import { ModalDialog, PopUp } from 'components';
import { getCookies, fetchApi as fetch } from 'utils';
import g7AdvImg from 'images/g7_guide/g7_adv.png';
import g7PropagadanNew from 'images/g7_guide/g7_propagadan.png';
import RegisterInfo from '../registerInfo';

import { prefixCls } from './index.scss';

class SettlePropaganda extends PureComponent {
  static propTypes = {
    controller: PropTypes.string,
    action: PropTypes.string,
    popType: PropTypes.string,
    close: PropTypes.func,
  };

  static defaultProps = {
    id: 0,
  };

  constructor(props) {
    super(props);

    this.state = {
      // eslint-disable-next-line react/no-unused-state
      isLoading: false,
    };
  }

  goForm = () => {
    console.log('goForm');
    new PopUp(RegisterInfo, { popName: 'RegisterInfo' }).show();
  };

  doOpen = () => {
    const { controller, action } = this.props;
    if (controller === 'Carry' && action === 'guide') {
      this.closableCallback();
    } else {
      actions.api.direct({ controller: 'Carry', action: 'guide', query: { isRefresh: true, noPageDone: true } });
    }
  };

  getExpires = days => {
    const curDate = new Date();

    // 当前时间戳
    const curTamp = curDate.getTime();

    curDate.setTime(curTamp + days * 24 * 60 * 60 * 1000);
    // curDate.setTime(curTamp + (days * 60 * 1000))

    return curDate;
  };

  cookie3Op = () => {
    const cookiesAll = getCookies() || {};
    const exist = +cookiesAll.CMMG7in3dCount1910 || 0;

    const countExpires = this.getExpires(365 * 100);
    const newCount = exist + 1;
    document.cookie = `${encodeURIComponent('CMMG7in3dCount1910')}=${encodeURIComponent(
      newCount,
    )}; expires=${countExpires.toString()}; path=/`;

    const in3TimeExpires = this.getExpires(3);
    const in3Time = new Date().getTime();
    document.cookie = `${encodeURIComponent('CMMG7in3dTime1910')}=${encodeURIComponent(
      in3Time,
    )}; expires=${in3TimeExpires.toString()}; path=/`;

    this.closableCallback();
  };

  noTipForever = () => {
    const foreverExpires = this.getExpires(365 * 100);
    document.cookie = `${encodeURIComponent('CMMG7Forever1910')}=${encodeURIComponent(
      'true',
    )}; expires=${foreverExpires.toString()}; path=/`;
    this.closableCallback();
  };

  goH5 = () => {
    window.open('https://wj.qq.com/s2/4396207/4755/', '_blank');

    const userInfo = window.user_info || {};

    const reqData = {
      user_id: userInfo.id || '', // 登录系统的的用户id。
      tms_version: '2', // tms版本。1 C11; 2 哥伦布
      user_name: userInfo.name || '', // 联系人。
      telephone: userInfo.telephone || '', // 联系方式。
      source: 'PC点击', // 来源（也是最新来源）。
      product_name: '结算服务', // 类型。
    };

    const conf = { method: 'POST', body: { req: reqData } };
    fetch('/Loan/Loan/pclientApply', conf);
  };

  closableCallback = () => {
    this.modal.handleHide();
    this.props.close && this.props.close();
  };

  renderContent = () => (
    <div className="cnt-wrap">
      <img src={g7PropagadanNew} width="800px" alt="" />
      <div className="do-open" onClick={this.doOpen}>
        我要开通
      </div>
      <div className="learn-info" onClick={this.goH5}>
        了解详情
      </div>
      {/* <Button classname="do-open" onClick={this.doOpen} >我要开通</Button>
    <Button classname="learn-info" onClick={this.goForm} >了解详情</Button> */}
    </div>
  );

  renderG7Adv = () => {
    const cookiesAll = getCookies() || {};
    const noForever = +cookiesAll.CMMG7in3dCount1910 === 2;
    return (
      <div className="cnt-wrap">
        <img src={g7AdvImg} width="760px" alt="" />
        <i className="fn-icon-il fn-icon-error-o" onClick={this.closableCallback} />
        {/* 春节活动先注释掉 <div className="learn-info-adv" onClick={this.goH5} > {'立刻了解活动 >'} </div> */}
        {/* {!noForever && (<div className="forever-3-in" onClick={this.cookie3Op} > 3 天内不再提示 </div>)} */}
        {/* {noForever && (<div className="forever-3-in" onClick={this.noTipForever} > 不再提示 </div>)} */}
      </div>
    );
  };

  render() {
    const { close, popType } = this.props;
    const { title } = this.state;
    return (
      <ModalDialog
        content={popType === 'g7_adv' ? this.renderG7Adv() : this.renderContent()}
        ref={ref => (this.modal = ref)}
        closable
        classname={prefixCls}
        // maskClosable={false}
        // style={{ width: 478 }}
        // handleHideCallback={this.handleHide}
        isShow
        autoDestroy
        closableCallback={this.closableCallback}
      />
    );
  }
}

export default SettlePropaganda;
