exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".route-card-switch__wrap .gui-switch{height:20px;line-height:20px}.route-card-switch__wrap .gui-switch .gui-switch-dot{width:14px;height:14px;top:3px;left:2px}.route-card-switch__wrap .gui-switch-checked .gui-switch-dot{left:calc(100% - 16px - 2px)}.route-card-switch__wrap__label{padding-left:8px;color:#1e3151;font-size:13px}", ""]);

// exports
exports.locals = {
	"prefixCls": "route-card-switch",
	"route-card-switch__wrap": "route-card-switch__wrap",
	"gui-switch": "gui-switch",
	"gui-switch-dot": "gui-switch-dot",
	"gui-switch-checked": "gui-switch-checked",
	"route-card-switch__wrap__label": "route-card-switch__wrap__label"
};