import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { SelectDrop, PureInput, Icon, OrgSug } from 'components';
import { FLOAT_P } from 'constants';
import _ from 'lodash';
import Card from '../card';
import { data as config } from './config';
import { prefixCls } from './index.scss';

const carrierHeader = {
  carrier_name: '承运商名称',
  telephone: '承运商电话',
  carrier_type: '承运商类型',
  company_name: '所属组织',
  use_corp_type: '使用组织',
};
const driverHeader = {
  name: '司机姓名',
  tel: '联系电话',
  type: '司机类型',
  company_name: '所属组织',
  use_corp_type: '使用组织',
};
const moneyHeader = {
  display: '名称',
  company_name: '所属组织',
  use_corp_type: '使用组织',
};
const payTypeJson = { cor: 1, cee: 3, carrier: 5, driver: 7 };
const moneyfetchApi = num => ({
  key: 'search',
  para: { search: '', type: payTypeJson[num] },
  url: 'Basic/Sug/entitySug',
});
class payInfo extends React.Component {
  static propTypes = {
    set: PropTypes.func,
    data: PropTypes.array,
    enums: PropTypes.object,
    lineSettle: PropTypes.bool,
    allowMulPay: PropTypes.bool,
    isCardForm: PropTypes.bool,
  };
  static defaultProps = {
    isCardForm: true,
  };
  constructor(props) {
    super(props);
    this.header = this.initConfig(props);
    this.onChangeCache = {};
  }
  UNSAFE_componentWillReceiveProps(np) {
    if (np.lineSettle !== this.props.lineSettle) {
      this.header = this.initConfig(np);
    }
  }
  initConfig = props => {
    let header = config;
    if (!props.lineSettle) {
      header = header.filter(item => item.key !== 'payee_downstream' && item.key !== 'duty_downstream');
    }
    return header;
  };
  onChange = (index, key) => {
    if (!this.onChangeCache[`${index}-${key}`]) {
      this.onChangeCache[`${index}-${key}`] = val => this.props.set('payInfo', [index, key], val);
    }
    return this.onChangeCache[`${index}-${key}`];
  };
  renderDutyPayee = (item, key, index) => {
    const type = item[`${key}_type`];
    const typeVal = item[`${key}_${type}`];
    const { enums, disabled, abnormType, hiddenOrderInfo, categoryNum } = this.props;
    const newDisable = hiddenOrderInfo ? categoryNum === '1' : abnormType === '1';
    let el;
    // 未选择付款方类型时不允许编辑付款方
    if (!type) {
      el = <PureInput disabled handleSelected={this.onChange(index, `${key}_${type}`)} />;
    } else if (type === 'cor' || type === 'cee') {
      el = (
        <SelectDrop
          defaultSelected={typeVal}
          data={enums[type]}
          disabled={newDisable}
          uniqueKey="id"
          showKey="name"
          selectOnly
          handleSelected={this.onChange(index, `${key}_${type}`)}
          fetchApi={moneyfetchApi(type)}
          tableHeader={moneyHeader}
        />
      );
    } else if (type === 'point') {
      el = (
        <OrgSug
          defaultSelected={typeVal}
          data={enums.company}
          disabled={disabled}
          scope="group"
          uniqueKey="id"
          showKey="short_name"
          handleSelected={this.onChange(index, `${key}_${type}`)}
        />
      );
    } else if (type === 'carrier') {
      el = (
        <SelectDrop
          defaultSelected={typeVal}
          data={enums[type]}
          uniqueKey="id"
          showKey="carrier_name"
          disabled={newDisable}
          selectOnly
          tableHeader={carrierHeader}
          fetchApi={moneyfetchApi('carrier')}
          handleSelected={this.onChange(index, `${key}_${type}`)}
        />
      );
    } else if (type === 'driver') {
      el = (
        <SelectDrop
          defaultSelected={typeVal}
          data={enums[type]}
          uniqueKey="name"
          showKey="name"
          selectOnly
          disabled={!newDisable}
          tableHeader={driverHeader}
          fetchApi={moneyfetchApi('driver')}
          handleSelected={this.onChange(index, `${key}_${type}`)}
        />
      );
    } else {
      el = <PureInput value={typeVal} disabled={disabled} onChange={this.onChange(index, `${key}_${type}`)} />;
    }
    return el;
  };
  onDelete = index => {
    this.onChange(index, 'delete')();
  };
  renderTr = (item, index) => {
    const { enums, disabled, allowMulPay } = this.props;
    const hasOperate = allowMulPay && !disabled;
    return (
      <tr key={index}>
        {hasOperate && (
          <Fragment>
            <td key="order" className="order">
              {index + 1}
            </td>
            <td key="operate" className="operate">
              <span onClick={() => this.onDelete(index)}>[删除]</span>
            </td>
          </Fragment>
        )}

        {this.header.map(({ key, props = {} }) => {
          console.log(key, props, '_------------------------');
          let el;
          // 只有
          let disable = disabled;
          const fetchApi = _.cloneDeep(props.fetchApi);
          let theEnum = enums[key];
          let { required } = props;
          if (['duty_downstream'].includes(key)) {
            fetchApi.para.company_id = _.get(item.duty_point, 'id', item.duty_point);
            theEnum = enums.downstream;
            const isCenter = +item.duty_point_type === 5;
            disable = disable || item.duty_type !== 'point' || !isCenter;
            // 分拨中心且有金额时线路必填
            required = !disable && isCenter && +item.dp_price;
          }
          if (['payee_downstream'].includes(key)) {
            fetchApi.para.company_id = _.get(item.payee_point, 'id', item.payee_point);
            theEnum = enums.downstream;
            const isCenter = +item.payee_point_type === 5;
            disable = disable || item.payee_type !== 'point' || !isCenter;
            required = !disable && isCenter && +item.dp_price;
          }
          if (key === 'payee_user_id') {
            const pointId = typeof item.payee_point === 'object' ? item.payee_point.id : item.payee_point;
            disable = disable || item.payee_type !== 'point' || !pointId;
            fetchApi.para.company_id = pointId || undefined;
            theEnum = enums.user || [];
          }
          if (key === 'duty_user_id') {
            const pointId = typeof item.duty_point === 'object' ? item.duty_point.id : item.duty_point;
            disable = disable || item.duty_type !== 'point' || !pointId;
            fetchApi.para.company_id = pointId || undefined;
            theEnum = enums.user || [];
          }
          if (key === 'dp_price') {
            el = (
              <PureInput
                value={item[key]}
                maxLength={9}
                pattern={FLOAT_P}
                disabled={disabled}
                onChange={this.onChange(index, key)}
              />
            );
          } else if (key === 'duty' || key === 'payee') {
            el = this.renderDutyPayee(item, key, index);
          } else {
            el = (
              <SelectDrop
                defaultSelected={item[key]}
                data={theEnum}
                uniqueKey="id"
                showKey="name"
                {...props}
                selectOnly
                disabled={disable}
                handleSelected={this.onChange(index, key)}
                fetchApi={fetchApi}
                required={required}
              />
            );
          }
          return (
            <td key={key} path={`${index}-${key}`}>
              {el}
            </td>
          );
        })}
      </tr>
    );
  };
  isColRequired = () => {
    const { data = [] } = this.props;
    let hasDpPrice;
    let hasPayeeDownStream;
    let hasDutyDownStream;
    for (const item of data) {
      hasDpPrice = hasDpPrice || +item.dp_price;
      hasPayeeDownStream = hasPayeeDownStream || (+item.payee_point_type === 5 && +item.dp_price);
      hasDutyDownStream = hasDutyDownStream || (+item.duty_point_type === 5 && +item.dp_price);
    }
    return {
      hasDpPrice,
      hasPayeeDownStream,
      hasDutyDownStream,
    };
  };
  isRequired = ({ hasDpPrice, hasPayeeDownStream, hasDutyDownStream }, key) => {
    const normalRequired = ['duty_type', 'duty', 'payee_type', 'payee', 'dp_price'].includes(key) && hasDpPrice;
    const payeeDwonstream = key === 'payee_downstream' && hasPayeeDownStream;
    const dutyDwonstream = key === 'duty_downstream' && hasDutyDownStream;
    return normalRequired || payeeDwonstream || dutyDwonstream;
  };
  render() {
    const { data = [], disabled, allowMulPay, isCardForm } = this.props;
    const total = data.reduce((a, b) => +a + +(+b.dp_price || 0).toFixed(2), 0) || 0;
    const hasOperate = allowMulPay && !disabled;
    const requiredCheckRes = this.isColRequired();

    const content = (
      <table>
        <thead>
          <tr>
            {hasOperate && (
              <Fragment>
                <th key="order" className="order">
                  序号
                </th>
                <th key="operate" className="operate">
                  操作
                </th>
              </Fragment>
            )}
            {this.header.map(({ key, name, tips }) => (
              <th key={key} className={`${this.isRequired(requiredCheckRes, key) ? 'required' : ''}`}>
                {name}
                {tips ? <Icon iconType="icon-help" tips={tips} /> : null}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{data.map(this.renderTr)}</tbody>
      </table>
    );
    return isCardForm ? (
      <Card
        title="收付款方信息"
        className={prefixCls}
        titleExt={<span className="total-price">异常费用合计：￥{total.toFixed(2)}</span>}
      >
        {content}
      </Card>
    ) : (
      content
    );
  }
}
export default payInfo;
