import moment from 'moment';

import taskQueue from './PrintTaskQueue';
import { PRINT_STATUS_TEXT } from './printConst';

export const PRINT_TYPE = {
  ATTACHMENT: 'ATTACHMENT', // 附件
  TABLE_BILL: 'TABLE_BILL', // table票据
};

/**
 * proxy字段说明
 * 如果是模板入口点击打印，需要根据每个业务线不同展示不同字段，展示列表名称或者票据单号
 * 从列表入口如果点击打印 有点特殊，只包含title字段，直接展示打印列表名称
 */
const printTaskMap = new Map();

export function printStatusCommand(LODOP, proxy, printFileType) {
  LODOP.SET_PRINT_MODE('CATCH_PRINT_STATUS', true);
  if (LODOP.CVERSION) {
    LODOP.On_Return = function (TaskID, Value) {
      printLog(`打印任务ID：${Value}`);

      addTask(TaskID, Value);
      // if (!taskQueue.isRunning) {
      //   taskQueue.runTask(() => getStatusValue(LODOP, Value, proxy, printFileType));
      // } else {
      //  addTask(TaskID, Value);
      // }
    };
    const taskId = LODOP.PRINT();
    printTaskMap.set(taskId, {
      getStatusValueFn: id => {
        return () => {
          return getStatusValue(LODOP, id, proxy, printFileType);
        };
      },
    });
  }
}

function addTask(TaskID, Value) {
  if (printTaskMap.has(TaskID)) {
    const printTaskInfo = printTaskMap.get(TaskID);
    printTaskInfo.id = Value;
    taskQueue.addTask(printTaskInfo);
    printLog('新增打印任务,id:', Value, TaskID);
    return true;
  }

  return false;
}

function getStatusValue(LODOP, P_ID, proxy, printFileType) {
  return new Promise((resolve, reject) => {
    let PRINT_STATUS_ADDTIME_TASKID = '';
    let PRINT_STATUS_DOCNAME_TASKID = '';
    let PRINT_STATUS_ID_TASKID = '';
    let PRINT_STATUS_EXIST = '';

    if (LODOP.CVERSION) {
      LODOP.On_Return_Remain = true;
      const values = {
        PRINT_STATUS_ID_TEXT: '',
        PRINT_STATUS_ID_VALUE: '',
        PRINT_STATUS_DOCNAME_VALUE: '',
        PRINT_STATUS_ADDTIME_VALUE: '',
        PRINT_STATUS_TEXT_CONTENT: '',
        FINISH_PRINT: false,
      };
      LODOP.On_Return = function (TaskID, Value) {
        let remainder;
        let dateTime;
        let printTitle = '';

        printLog(`轮训获取${P_ID}打印状态：`, TaskID, Value);

        // 其他打印任务 推入队列
        if (addTask(TaskID, Value)) return;

        switch (TaskID) {
          case PRINT_STATUS_ADDTIME_TASKID:
            values.PRINT_STATUS_ADDTIME_VALUE = Value;
            break;
          case PRINT_STATUS_DOCNAME_TASKID:
            values.PRINT_STATUS_DOCNAME_VALUE = Value;
            break;
          // 不在队列中 1-在 0-不在 不在队列中表示已完成
          case PRINT_STATUS_EXIST:
            if (Value / 1 === 0) {
              values.FINISH_PRINT = true;
            }
            break;
          case PRINT_STATUS_ID_TASKID:
            values.PRINT_STATUS_ID_TEXT = getStatuMessage(Value);
            values.PRINT_STATUS_ID_VALUE = Value;
            remainder = +Value % 2048;
            if (+values.PRINT_STATUS_ID_TEXT.value === 128 || +remainder === 20 || values.FINISH_PRINT) {
              values.PRINT_STATUS_TEXT_CONTENT = PRINT_STATUS_TEXT.PRINT_SUCESS;
              printLog(`打印成功！`);
            } else if (+values.PRINT_STATUS_ID_TEXT.value === 2) {
              values.PRINT_STATUS_TEXT_CONTENT = PRINT_STATUS_TEXT.PRINT_FAIL;
            } else if (values.PRINT_STATUS_ID_TEXT.text) {
              values.PRINT_STATUS_TEXT_CONTENT = values.PRINT_STATUS_ID_TEXT.text;
            } else if (Value === undefined) {
              values.PRINT_STATUS_TEXT_CONTENT = PRINT_STATUS_TEXT.PRINT_NO_STATUS;
            } else {
              values.PRINT_STATUS_TEXT_CONTENT = PRINT_STATUS_TEXT.PRINT_UNKNOWN;
            }

            dateTime = moment(values.PRINT_STATUS_ADDTIME_VALUE || new Date()).format('HH:mm:ss');
            // printFileType 这里是代表来源，是附件打印还是票据打印，展示消息内容不一样
            if (printFileType === PRINT_TYPE.TABLE_BILL) {
              const order_title =
                proxy?.data?.batch?.bill_no ||
                proxy?.data?.batch?.balance_no ||
                proxy?.data?.car_batch ||
                proxy?.title ||
                proxy?.order_num ||
                proxy?.data?.fee_approval?.[0]?.bill_no ||
                '--';

              printTitle = `${order_title}(${dateTime})`;
            } else if (printFileType === PRINT_TYPE.ATTACHMENT) {
              printTitle = `${values.PRINT_STATUS_DOCNAME_VALUE}(${dateTime})`;
            }

            resolve({
              P_ID,
              ...values,
              proxy,
              printFileType,
              printTitle,
              time: Date.now(),
            });
            break;
        }
      };
    }

    printLog('开始获取打印机状态');

    PRINT_STATUS_ADDTIME_TASKID = LODOP.GET_VALUE('PRINT_STATUS_ADDTIME', P_ID);
    PRINT_STATUS_DOCNAME_TASKID = LODOP.GET_VALUE('PRINT_STATUS_DOCNAME', P_ID);
    PRINT_STATUS_EXIST = LODOP.GET_VALUE('PRINT_STATUS_EXIST', P_ID);
    PRINT_STATUS_ID_TASKID = LODOP.GET_VALUE('PRINT_STATUS_ID', P_ID);

    if (!LODOP.CVERSION) {
      resolve({
        PRINT_STATUS_ID_TEXT: '',
        PRINT_STATUS_ID_VALUE: '',
        PRINT_STATUS_DOCNAME_VALUE: '',
        PRINT_STATUS_ADDTIME_VALUE: '',
        time: Date.now(),
      });
    }
  });
}

function getStatuMessage(statusID) {
  const statuses = {
    1: '已暂停',
    2: '错误',
    4: '正删除',
    8: '进入队列',
    16: '正在打印中',
    32: '脱机',
    64: '缺纸',
    128: '打印结束',
    256: '已删除',
    512: '堵了',
    1024: '用户介入',
    2048: '正在重新启动',
  };

  let messages = '';
  let value = null;

  for (const [key, status] of Object.entries(statuses)) {
    if (statusID & parseInt(key)) {
      messages += status + ' - ';
      value = parseInt(key);
    }
  }
  return {
    text: messages ? messages.slice(0, -3) : messages,
    value,
  };
}

export function printLog() {
  console.log('printLog:', ...arguments);
}
