import React from 'react';
import { Select, PureInput as Input, DateTimePicker } from 'components';
import { bem } from 'utils';

import { prefixCls } from './index.scss';

import { comIdOrgSug } from 'utils/sug/org';
import userSug from 'utils/sug/user';
import { routeSug } from 'utils/sug/route';

const cls = bem(prefixCls);

const attrSet = (key, val, page) => {
  const { valHub = {} } = page.state;
  switch (key) {
    case 'operator':
      valHub[key] = { id: val.id, name: val.name };
      valHub.department = { department_id: val.department_id, department_name: val.department_name };
      break;
    default:
      valHub[key] = val;
  }
  console.log('attrSet =', key, val, valHub[key]);
  // const fee = page.capitalFlowPaymode.getData()
  // page.setState({ valHub: { ...valHub }, feeInfo: fee.data })
  page.setState({ valHub: { ...valHub } });
};

const renderEl = page => {
  const { state } = page;
  const { allField = {}, attrField = [], valHub = {} } = state;

  // 发生网点id
  const _comId = '15973';

  const sugComId =
    () =>
    (search, { fetch }) =>
      comIdOrgSug({ fetch, search, company_id: +window.company_id });
  const sugOperator =
    () =>
    (keyword, { fetch }) =>
      userSug({ fetch, keyword });
  const sugRoute =
    () =>
    (keyword, { fetch }) =>
      routeSug({ fetch, keyword });

  return attrField.map((k, t) => {
    let _el = null;
    const _key = k.key;
    switch (_key) {
      case 'bill_no':
        _el = (
          <div className={cls('form-item')} key={t}>
            <label className={cls('fn-label', k.required ? 'required' : '')}>{allField[_key]}</label>
            <Input
              value={valHub[_key] || ''}
              required={k.required || false}
              disabled={k.disables || false}
              onChange={e => attrSet(_key, e.target.value, page)}
            />
          </div>
        );
        break;
      case 'build_time':
        _el = (
          <div className={cls('form-item')} key={t}>
            <label className={cls('fn-label', k.required ? 'required' : '')}>{allField[_key]}</label>
            <DateTimePicker
              showTime
              classname="date-input"
              required={k.required || false}
              disabled={k.disables || false}
              defaultValue={valHub[_key] || ''}
              onChange={val => attrSet(_key, val, page)}
            />
          </div>
        );
        break;
      case 'route':
        _el = (
          <div className={cls('form-item')} key={t}>
            <label className={cls('fn-label', k.required ? 'required' : '')}>{allField[_key]}</label>
            <Select
              value={valHub[_key]}
              disabled={k.disables || false}
              required={k.required || false}
              onChange={val => attrSet(_key, val, page)}
              filter={sugRoute()}
              header={['segment_name']}
              format="segment_name"
              map={x => ({ id: x.id, segment_name: x.segment_name })}
            />
          </div>
        );
        break;
      case 'com_id':
        _el = (
          <div className={cls('form-item')} key={t}>
            <label className={cls('fn-label', k.required ? 'required' : '')}>{allField[_key]}</label>
            <Select
              value={valHub[_key] || ''}
              required={k.required || false}
              disabled={k.disables || false}
              onChange={val => attrSet(_key, val, page)}
              filter={sugComId()}
              header={['short_name']}
              format="short_name"
              map={x => ({ id: x.id, short_name: x.name })}
            />
          </div>
        );
        break;
      case 'operator':
        _el = (
          <div className={cls('form-item')} key={t}>
            <label className={cls('fn-label', k.required ? 'required' : '')}>{allField[_key]}</label>
            <Select
              value={valHub[_key] || ''}
              required={k.required || false}
              disabled={k.disables || false}
              onChange={val => attrSet(_key, val, page)}
              filter={sugOperator()}
              header={[
                { key: 'name', title: '姓名' },
                { key: 'telephone', title: '联系电话' },
              ]}
              format="name"
              map={x => ({
                id: x.id,
                name: x.name,
                department_id: x.department_id,
                department_name: x.department_name,
              })}
            />
          </div>
        );
        break;
      case 'department':
        _el = (
          <div className={cls('form-item')} key={t}>
            <label className={cls('fn-label', k.required ? 'required' : '')}>{allField[_key]}</label>
            <Select
              value={valHub[_key] || ''}
              required={k.required || false}
              disabled={k.disables || false}
              onChange={val => attrSet(_key, val, page)}
              filter={sugOperator()}
              header={['department_name']}
              format="department_name"
              map={x => ({ department_id: x.department_id, department_name: x.department_name })}
            />
          </div>
        );
        break;
      default:
        _el = null;
    }
    return _el;
  });
};

const renderAttr = page => <div className={cls()}>{renderEl(page)}</div>;

export default renderAttr;
