import { axiosIns, showInfo } from 'utils';
import { uploadOss, getFile, getProxyUrl } from 'utils/insurance';
import { WARN, INFO } from 'constants';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Load from 'components/utilcomponents/loading/Load';
import { ModalDialog, Button } from 'components';
import html2canvas from 'html2canvas';
import Jspdf from 'jspdf';
import { prefixCls } from './index.scss';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';

// 保障服务0=未生效，1=已生效，2=已失效
const SERVICE_ACTION_STATUS_NO_INVALID = 0;
const SERVICE_ACTION_STATUS_EFFECT = 1;
const SERVICE_ACTION_STATUS_INVALID = 2;

// 服务单状态：0=待支付，1=支付中，2=支付成功，3=支付失败，4=退款中，5=退款成功，6=退款失败
const SERVICE_STATUS_SUCCESS = 2;
const SERVICE_STATUS_REFUND_FAIL = 6;
// pdf渲染dom的id
const PDF_DOM_ID = `${prefixCls}-pdf`;
const PDF_CONTAINER_HEIGHT = 300;

const PDF_DOWNLOAD_NAME = '货物安全管理保障凭证';

export default class Recharge extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    insuranceParam: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.dynamicHTMLRef = React.createRef();
    this.state = {
      loading: false,
      dialogType: 'info',
      dialogTitle: '',
      insuranceInfo: {},
      guaranteePolicy: {}, // 保障凭证信息
      downloadLoading: false,
    };
  }
  componentDidMount() {
    this.setState({ dialogTitle: this.props.title || '货物安全管理保障服务详情' });
    this.fetchInsuranceInfo();
  }
  componentDidUpdate(prevProps, prevState) {
    console.log(prevProps);
    if (prevState.dialogType !== this.state.dialogType && this.state.dialogType === 'pdf') {
      const { detail } = this.state.guaranteePolicy;
      const dynamicHTMLRef = this.dynamicHTMLRef.current;
      if (dynamicHTMLRef) {
        const guaranteeFields = [
          {
            id: '#guarantee-id',
            field: 'serviceId',
          },
          {
            id: '#company-name',
            field: 'insurant',
          },
          {
            id: '#company-code',
            field: 'insurantCreditCode',
          },
          {
            id: '#plate-number',
            field: 'plateNumber',
          },
          {
            id: '#goods-name',
            field: 'goodsName',
          },
          {
            id: '#start-time',
            field: 'departTime',
          },
          {
            id: '#start-place',
            field: 'startPlace',
          },
          {
            id: '#end-place',
            field: 'toPlace',
          },
          {
            id: '#waybill',
            field: 'businessNo',
          },
          {
            id: '#goods-type',
            field: 'goodsTypeDesc',
          },
          {
            id: '#buy-time',
            field: 'createTime',
          },
          {
            id: '#service-fee',
            field: 'guaranteeService',
          },
          {
            id: '#guarantee-fee',
            field: 'guaranteeAmount',
          },
          {
            id: '#points',
            field: 'guaranteeCost',
          },
        ];
        guaranteeFields.forEach(item => {
          const element = dynamicHTMLRef && dynamicHTMLRef.querySelector(item.id);
          if (element) {
            element.textContent = detail[item?.field];
          }
        });
      }
    }
  }

  async fetchInsuranceInfo() {
    this.setState({ loading: true });

    try {
      const { success, data } = await axiosIns.postRequest({
        url: '/insurance/freight/assurance/services/detail',
        data: this.props.insuranceParam,
      });

      success && this.setState({ insuranceInfo: data || {} });
    } finally {
      this.setState({ loading: false });
    }
  }

  handleServeCancel() {
    showInfo(WARN, '取消后，该运单将不享受货物安全管理保障服务，确认取消？', true, {
      confirm: async () => {
        this.setState({ loading: true });

        try {
          const { success } = await axiosIns.postRequest({
            url: '/insurance/freight/assurance/services/cancel',
            data: {
              ...this.props.insuranceParam,
              serviceId: this.state.insuranceInfo.serviceId,
              userId: window.user_id,
              userName: window?.user_info?.name,
            },
          });

          if (success) {
            showInfo(INFO, '取消成功');
            this.fetchInsuranceInfo();
          }
        } finally {
          this.setState({ loading: false });
        }
      },
    });
  }

  async handlePdfInfo() {
    try {
      const { success, data } = await axiosIns.postRequest({
        url: '/insurance/freight/assurance/services/policy',
        data: this.props.insuranceParam,
      });
      if (success) {
        const { signatureUrlList, policyUrl } = data;
        if (policyUrl) {
          this.setState({
            guaranteePolicy: { ...data },
            dialogType: 'hasUploadPdf',
            dialogTitle: '保障凭证',
            loading: true,
          });
        } else {
          const imgsPromises = signatureUrlList.map(async e => {
            const fetchUrl = getProxyUrl(e);
            const { blob } = await getFile(fetchUrl);
            return URL.createObjectURL(blob);
          });
          // 使用 Promise.all() 等待所有异步操作完成
          Promise.all(imgsPromises)
            .then(imgs => {
              this.setState({
                guaranteePolicy: { ...data, signatureUrlList: imgs },
                dialogType: 'pdf',
                dialogTitle: '保障凭证',
                loading: true,
              });
            })
            .catch(error => {
              console.error('Error occurred while fetching images:', error);
            });
        }
      }
    } finally {
      this.setState({ loading: false });
    }
  }

  generatePDF() {
    this.setState({ downloadLoading: true });
    // 计算每页的高度 600是按照目前给到后端的html生成pdf正好不被截取的尺寸，如果生成尺寸调整，那么意味着html模板也要调整
    const container = document.getElementById(PDF_DOM_ID); // 获取包含整个HTML内容的容器
    const pageHeight = 600;
    const totalHeight = container.scrollHeight;
    const numPages = Math.ceil(totalHeight / pageHeight);
    const pdfInstance = new Jspdf();
    // 循环生成每一页的Canvas并添加到PDF中
    let promise = Promise.resolve();
    for (let i = 0; i < numPages; i++) {
      promise = promise.then(() => {
        return html2canvas(container, {
          y: i * pageHeight,
          //   windowHeight: pageHeight,
          width: container.offsetWidth,
          height: pageHeight,
          useCORS: true,
          backgroundColor: '#fff',
          scale: 3, // 提升画面质量，但是会增加文件大小,
        }).then(canvas => {
          const imgData = canvas.toDataURL('image/jpeg', 1);
          if (i > 0) {
            pdfInstance.addPage();
          }
          pdfInstance.addImage(imgData, 'JPEG', 7, 20, 200, 265);
        });
      });
    }
    // 保存PDF
    promise
      .then(() => {
        pdfInstance.save(`${PDF_DOWNLOAD_NAME}.pdf`);
        // 将PDF作为Blob对象返回
        const blob = pdfInstance.output('blob');
        const file = new File([blob], `${PDF_DOWNLOAD_NAME}.pdf`); // 创建文件对象
        this.handleUploadOss(file);
      })
      .finally(() => {
        this.setState({ downloadLoading: false });
      });
  }

  handlePdfPreview() {
    this.setState({ downloadLoading: true });
    const { policyUrl: url } = this.state.guaranteePolicy;
    const link = document.createElement('a');
    link.href = url;
    // link.target = '_blank'; // Open in a new tab
    link.download = `${PDF_DOWNLOAD_NAME}.pdf`; // Set the default filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    this.setState({ downloadLoading: false });
  }

  renderInfoContent() {
    const { insuranceInfo } = this.state;
    return (
      <div>
        <div className={`${prefixCls}-title`}>本详情不是保障凭证，请在保障服务生效后查看凭证</div>
        <div className={`${prefixCls}-item`}>
          <div className={`${prefixCls}-label`}>货物名称：</div>
          <div className={`${prefixCls}-value`}>{insuranceInfo.goodsName}</div>
        </div>
        <div className={`${prefixCls}-item`}>
          <div className={`${prefixCls}-label`}>保障额度：</div>
          <div className={`${prefixCls}-value`}>
            {insuranceInfo.guaranteeAmount ? `${insuranceInfo.guaranteeAmount}元` : ''}
          </div>
        </div>
        <div className={`${prefixCls}-item`}>
          <div className={`${prefixCls}-label`}>购买时间：</div>
          <div className={`${prefixCls}-value`}>{insuranceInfo.createTime}</div>
        </div>
        <div className={`${prefixCls}-item`}>
          <div className={`${prefixCls}-label`}>消耗积分：</div>
          <div className={`${prefixCls}-value`}>{insuranceInfo.guaranteeCost}</div>
        </div>
        <div className={`${prefixCls}-item`}>
          <div className={`${prefixCls}-label`}>发车时间：</div>
          <div className={`${prefixCls}-value`}>{insuranceInfo.departTime}</div>
        </div>
        <div className={`${prefixCls}-item`}>
          <div className={`${prefixCls}-label`}>始发地：</div>
          <div className={`${prefixCls}-value`}>{insuranceInfo.startPlace}</div>
        </div>
        <div className={`${prefixCls}-item`}>
          <div className={`${prefixCls}-label`}>目的地：</div>
          <div className={`${prefixCls}-value`}>{insuranceInfo.toPlace}</div>
        </div>
        <div className={`${prefixCls}-item`}>
          <div className={`${prefixCls}-label`}>车牌号：</div>
          <div className={`${prefixCls}-value`}>{insuranceInfo.plateNumber}</div>
        </div>
        <div className={`${prefixCls}-item`}>
          <div className={`${prefixCls}-label`}>货物类型：</div>
          <div className={`${prefixCls}-value`}>{insuranceInfo.goodsTypeDesc}</div>
        </div>
        <div className={`${prefixCls}-item`}>
          <div className={`${prefixCls}-label`}>保障凭证：</div>
          <div className={`${prefixCls}-value`}>
            {/* 显示原则：支付成功并且已生效或已失效 */}
            {insuranceInfo.serviceStatus === SERVICE_STATUS_SUCCESS &&
            (insuranceInfo.serviceActiveStatus === SERVICE_ACTION_STATUS_EFFECT ||
              insuranceInfo.serviceActiveStatus === SERVICE_ACTION_STATUS_INVALID) ? (
              <a onClick={() => this.handlePdfInfo()}>查看保障凭证</a>
            ) : (
              <span style={{ fontWeight: 'bold' }}>保障服务生效后可查看</span>
            )}
          </div>
        </div>
        {/* 显示原则：支付成功或退款失败并且未生效 */}
        {(insuranceInfo.serviceStatus === SERVICE_STATUS_SUCCESS ||
          insuranceInfo.serviceStatus === SERVICE_STATUS_REFUND_FAIL) &&
          insuranceInfo.serviceActiveStatus === SERVICE_ACTION_STATUS_NO_INVALID && (
            <div className={`${prefixCls}-item`}>
              <div className={`${prefixCls}-label`}> </div>
              <div className={`${prefixCls}-value`}>
                <Button key={2} onClick={() => this.handleServeCancel()} type="primary">
                  取消保障服务
                </Button>
              </div>
            </div>
          )}
      </div>
    );
  }

  renderPdfContent() {
    const { signatureUrlList, contentHtml } = this.state.guaranteePolicy;
    return (
      <div className={PDF_DOM_ID} style={{ height: `${PDF_CONTAINER_HEIGHT}px` }}>
        <div id={PDF_DOM_ID}>
          <div ref={this.dynamicHTMLRef} dangerouslySetInnerHTML={{ __html: contentHtml }} />
          {signatureUrlList?.length && (
            <div className={`${prefixCls}-chapter`}>
              {signatureUrlList.map(e => {
                return <img className={`${prefixCls}-chapter-img`} src={e} />;
              })}
            </div>
          )}
        </div>
      </div>
    );
  }

  // upload oss
  async handleUploadOss(file) {
    const url = await uploadOss(file);
    if (url) {
      const { businessId, businessNo } = this.state.insuranceInfo;
      const { customerBranchId } = this.props.insuranceParam || {};
      const params = { businessId, businessNo, customerId: window.group_id, customerBranchId, platformCode: 'cyt' };
      this.handleUploadPolicy({ ...params, path: url });
    }
  }

  // 上传保障凭证
  async handleUploadPolicy(params) {
    await axiosIns.postRequest({
      url: '/insurance/freight/assurance/services/uploadPolicy',
      data: params,
    });
  }

  render() {
    const { loading, dialogType, dialogTitle, downloadLoading, guaranteePolicy } = this.state;
    let content = (
      <div className={`${prefixCls}-main`}>
        <Load spinning={loading}>{this.renderInfoContent()}</Load>
      </div>
    );
    let bottom = [
      <Button key={2} onClick={() => this.insuranceServeInfoDialog.handleHide()} type="primary">
        关闭
      </Button>,
    ];

    // 展示和下载保障凭证pdf
    if (dialogType === 'pdf') {
      content = (
        <div className={`${prefixCls}-main`}>
          <Load spinning={loading}>{this.renderPdfContent()}</Load>
        </div>
      );
      bottom = [
        <Button loading={downloadLoading} key={2} onClick={() => this.generatePDF()} type="primary">
          下载
        </Button>,
      ];
    } else if (dialogType === 'hasUploadPdf') {
      // 已经上传过pdf
      content = (
        <div style={{ height: `${PDF_CONTAINER_HEIGHT}px`, overflowY: 'auto' }}>
          <Document file={getProxyUrl(guaranteePolicy?.policyUrl)}>
            {new Array(8).fill().map((_, index) => (
              <Page key={`page_${index + 1}`} scale={1.5} pageNumber={index + 1} height={PDF_CONTAINER_HEIGHT} />
            ))}
          </Document>
        </div>
      );
      bottom = [
        <Button loading={downloadLoading} key={2} onClick={() => this.handlePdfPreview()} type="primary">
          下载
        </Button>,
      ];
    }

    return (
      <ModalDialog
        title={dialogTitle}
        style={{ width: 480 }}
        content={content}
        bottom={bottom}
        isShow
        classname={prefixCls}
        closable
        close={this.props.close}
        maskClosable={false}
        ref={r => (this.insuranceServeInfoDialog = r)}
      />
    );
  }
}
