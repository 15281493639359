import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { IconPlus } from '@gui/web-react/icon';
import { prefixCls } from './index.scss';
import cytUpgradeGuider from 'utils/cytUpgradeGuider';

export default class UpgradeAlert extends PureComponent {
  static propTypes = {
    text: PropTypes.string,
    // 仅需传 name/place/guideImage
    guideOptions: PropTypes.object,
  };

  handleClick = () => {
    cytUpgradeGuider({
      ...this.props.guideOptions,
      type: 'popup',
      show: true,
    });
  };

  render() {
    const { text } = this.props;
    return (
      <div className={`${prefixCls}__wrap`} onClick={this.handleClick}>
        <span className={`${prefixCls}__icon`}>
          <IconPlus style={{ color: '#9675F0' }} />
        </span>
        <span>{text}</span>
      </div>
    );
  }
}
