import { CHECK, ERROR } from 'constants';
import { PopUp, PopTip } from 'components';
import { fetchJAVA, showInfo } from 'utils';

const getReq = page => {
  const { attrField, listTotal, settledAmount = 0, imgList, filesList } = page.state;
  const { selectInfo } = page.props;
  const req = {
    is_check: 2,
    bill_id: selectInfo.bill_id,
  };
  Object.keys(attrField).forEach(x => {
    const val = attrField[x].value;
    if (val) req[x] = val;
  });

  const listData = page.feeBreakdownTable.getData();
  const _data = listData.data.map(x => ({ uuid_mark: x.uuid_mark, current_settled_amount: x.amount || 0 }));
  req.re_info = _data;

  const findZero = _data.some(item => !item.current_settled_amount || !Number(item.current_settled_amount));
  if (findZero) {
    return showInfo(ERROR, '金额不可为0！');
  }

  const feeInfo = page.feeSettlePaymode.getData();
  if (!feeInfo.data.length || feeInfo.err.length) {
    return showInfo(ERROR, '收支方式不能为空！');
  }

  /*
  11-24修复BUG：当已经部分结算的单子（如：总金额 111, 部分结算了 11 ），再次结算时，输入了与原始单子相同的金额（输入：111），
  此时 is_section 会为 false，导致后端 会当做完整结算处理。修复方式如下：
  当存在 settledAmount 即已经 部分结算，is_section 始终为 true
  否则，判断 输入的 listData.total 和 当前单子总金额 listTotal，是否相等，判断是否为部分结算
  */
  req.is_section = settledAmount > 0 || listTotal !== listData.total;
  req.pay_mode = feeInfo.data;
  req.settle_voucher = {
    bill_file: filesList,
    bill_img: imgList,
  };
  return req;
};

export const opSave = async page => {
  const req = getReq(page);
  if (!req) return;

  const reqUrl = '/cmm-finance/japi/finance/repay/settle';
  const fetchRes = await fetchJAVA(reqUrl, { method: 'POST', body: { req } });
  if (fetchRes.errno !== 0) {
    return showInfo(ERROR, fetchRes.errmsg);
  }

  new PopUp(PopTip, {
    type: CHECK,
    content: '操作成功！',
    onClose: () => {
      page.close();
      page.props.completeCallBack && page.props.completeCallBack();
    },
  }).show();
};
