import React, { useState, useEffect } from 'react';
import { Card, Checkbox, InputNumber, Descriptions, Space, Button, Spin, Tooltip } from '@gui/web-react';
import { useDeepCompareEffect } from '@gui/hooks-react';
import { IconUniDirectionRightOutline, IconUniFillThumbupFill, IconUniFillThumbdownFill } from '@gui/icon-react';
import vega from 'utils/vega';
import { dataAnalyticTrack } from '@/utils/dataAnalytic';
import { useMessages, useMessagesDispatch } from '../messagesContext';
import { prefixCls } from './index.scss';

const useCheckbox = Checkbox.useCheckbox;
const { Meta } = Card;
const FOLD_NUM = 8;
// -1 未开始，0 - 全部成功，1 - 全部失败，2 - 部分失败
const CARD_STATUS = {
  notStart: -1,
  allCreated: 0,
  allFailed: 1,
  partFailed: 2,
};

export default function MessageCard({ list, isHistory, chatId, historyFeedback, isFinished }) {
  const { send, tenantId } = useMessages();
  const dispatch = useMessagesDispatch();
  const [submit, setSubmit] = useState({ text: '确定建单', disable: true });
  const [isAllBuilding, setIsAllBuilding] = useState(false);
  const [isAllCreated, setIsAllCreated] = useState(false);
  const [feedbackValue, setHasFeedbackValue] = useState(historyFeedback ?? 0);
  const [tipVisible, setTipVisible] = useState({
    up: false,
    down: false,
  });
  const getCardStatusList = () =>
    list.map(card => {
      const { field_data, waybill_group_no } = card ?? {};
      let viewMore = false;
      let isFold = false;

      if (field_data?.length > FOLD_NUM) {
        viewMore = true;
        isFold = true;
      } else {
        viewMore = false;
        isFold = false;
      }

      return {
        waybill_group_no,
        text: '',
        status: CARD_STATUS.notStart,
        isDisable: false,
        isLoading: false,
        viewMore,
        isFold,
      };
    });
  const [cardList, setCardList] = useState(getCardStatusList());
  const { selected, setSelected, setValueSelected, isAllSelected, selectAll } = useCheckbox(
    list.map(el => el.waybill_group_no),
  );

  useEffect(() => {
    const cards = getCardStatusList();

    setCardList(cards);
  }, [list.length]);

  useEffect(() => {
    // 运单组识别完成才全选
    if (isFinished) {
      selectAll();
    }
  }, [isFinished]);

  useEffect(() => {
    // 历史数据不勾选建单成功和部分成功的
    if (isHistory) {
      const unSelectNo = cardList.filter(el => el.isDisable).map(item => item.waybill_group_no);

      if (unSelectNo.length > 0) {
        setValueSelected(unSelectNo, false);
      }
    }
  }, [cardList]);

  useEffect(() => {
    if (selected.length < 1 && !isAllCreated) {
      setSubmit({
        text: '确定建单',
        disable: true,
      });
    } else if (isAllCreated) {
      setSubmit({
        text: '建单完成',
        disable: true,
      });
    } else if (!isAllBuilding) {
      // 排除全选建单某组成功后的勾选数量变化的情况
      setSubmit({
        text: `确定建单(已选${selected.length})`,
        disable: false,
      });
    }
  }, [selected, isAllCreated]);

  // 所有运单组状态在一个组件管理较复杂，后期迭代拆分优化，组件拆成 CardGroupWaybill, CardWaybill, FeedBack, CreateWaybill 各自管理状态
  useDeepCompareEffect(() => {
    const allCreated = list.every(el => {
      const { waybill_group_info } = el;
      const { success_count } = waybill_group_info ?? {};

      if (success_count > 0) {
        return true;
      }

      return false;
    });

    if (allCreated) {
      setIsAllCreated(true);
    }
    // 卡片状态数组更新完成或者历史模式，设置卡片建单结果状态
    if (cardList.length === list.length || isHistory) {
      setDoneInfo();
    }
  }, [list]);

  const renderCardExtra = (item, index) => {
    const { waybill_count } = item;
    const setCardCount = value => {
      if (value > 0) {
        dispatch({
          type: 'AI_CARD_LIST_UPDATE',
          chatId,
          cardList: list.map((el, j) => {
            if (index === j) {
              return {
                ...el,
                waybill_count: value,
              };
            }
            return el;
          }),
        });
      }
    };

    return (
      <div className={`${prefixCls}-cardExtra`}>
        建单倍数 X
        <InputNumber
          className={`${prefixCls}-cardExtra__input`}
          size="small"
          hideControl
          defaultValue={waybill_count}
          disabled={disableAction(index)}
          min={1}
          max={100}
          step={1}
          onChange={value => setCardCount(value)}
          onClick={e => e.stopPropagation()}
        />{' '}
        倍
      </div>
    );
  };

  const handelToggle = (e, i) => {
    e.preventDefault();
    e.stopPropagation();
    setCardList(
      cardList.map((el, j) => {
        if (i === j) {
          el.isFold = !el.isFold;

          if (el.isFold) {
            dataAnalyticTrack(['大模型智能助手', '智能录单助手', '显示更多']);
          }
        }
        return el;
      }),
    );
  };

  const handelEdit = (e, cardIndex) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch({
      type: 'AI_CARD_EDIT_OPEN',
      chatId,
      cardIndex,
    });
    dataAnalyticTrack(['大模型智能助手', '智能录单助手', '运单组卡片-编辑']);
  };

  const handelCreate = () => {
    if (selected.length < 1) {
      return;
    }

    send({
      dataType: 'llm_waybill_create',
      scene: '1',
      waybill_origin_chat_record_id: chatId,
      waybill_group_info: list.filter(el => selected.includes(el.waybill_group_no)),
    });

    if (isAllSelected()) {
      setIsAllBuilding(true);
      setSubmit({
        text: '正在建单中...',
        disable: true,
      });
    } else {
      // 剩余可选：非禁用且未在已选中
      const canSelectedNo = cardList
        .filter(el => !el.isDisable && !selected.includes(el.waybill_group_no))
        .map(item => item.waybill_group_no);

      // 反选剩余的可选的
      setSelected(canSelectedNo, true);
    }

    setCardList(
      cardList.map(el => {
        if (selected.includes(el.waybill_group_no)) {
          return {
            ...el,
            isLoading: true,
            isDisable: true,
          };
        }

        return el;
      }),
    );

    dataAnalyticTrack(['大模型智能助手', '智能录单助手', '确定建单']);
  };

  const disableAction = i => {
    return cardList?.[i]?.isDisable ?? false;
  };

  const loadingAction = i => {
    return cardList?.[i]?.isLoading ?? false;
  };

  const setDoneInfo = () => {
    setCardList(
      cardList.map((card, i) => {
        const { waybill_count, fail_count, success_count, waybill_group_no } = list[i]?.waybill_group_info ?? {};

        if (success_count > 0 && success_count === waybill_count) {
          setValueSelected(waybill_group_no, false);

          return {
            ...card,
            text: '建单成功',
            status: CARD_STATUS.allCreated,
            isDisable: true,
            isLoading: false,
          };
        } else if (fail_count > 0 && fail_count === waybill_count) {
          // 重新选中已失败的（需要判断是否已在选中，否则会重复）
          if (!selected.includes(waybill_group_no)) {
            setSelected([...selected, waybill_group_no]);
          }
          setSubmit({
            text: `确定建单(已选${selected.length})`,
            disable: false,
          });
          // 有建单失败的，还原全部建单 isAllBuilding 为 false
          setIsAllBuilding(false);

          return {
            ...card,
            text: '建单失败',
            status: CARD_STATUS.allFailed,
            isDisable: false,
            isLoading: false,
          };
        } else if (fail_count > 0 && fail_count !== waybill_count) {
          setValueSelected(waybill_group_no, false);

          return {
            ...card,
            text: `失败${fail_count}单`,
            status: CARD_STATUS.partFailed,
            isDisable: true,
            isLoading: false,
          };
        }

        return card;
      }),
    );
  };

  const feedback = async option => {
    if (feedbackValue > 0) {
      return;
    }

    const { group_id, company_id, user_id } = window;
    const params = {
      feed_back: option,
      tenant_id: tenantId,
      identified_chat_record_id: chatId,
      platform_group_id: group_id,
      platform_company_id: company_id,
      platform_user_id: user_id,
    };

    const res = await vega.orderCenter.feedback(params);
    const { feed_back } = res ?? {};

    if (feed_back > 0) {
      setHasFeedbackValue(feed_back);
      setTipVisible({
        up: feed_back === 1,
        down: feed_back === 2,
      });
      setTimeout(
        () =>
          setTipVisible({
            up: false,
            down: false,
          }),
        2000,
      );
    }

    if (option === 1) {
      dataAnalyticTrack(['大模型智能助手', '智能录单助手', '结果反馈-赞']);
    } else if (option === 2) {
      dataAnalyticTrack(['大模型智能助手', '智能录单助手', '结果反馈-踩']);
    }
  };

  const handleCardClick = i => {
    if (selected.includes(list[i]?.waybill_group_no)) {
      dataAnalyticTrack(['大模型智能助手', '智能录单助手', '取消勾选']);
    } else {
      dataAnalyticTrack(['大模型智能助手', '智能录单助手', '手动勾选']);
    }
  };

  return (
    <div className={prefixCls}>
      <Checkbox.Group value={selected} onChange={setSelected} className={`${prefixCls}-group`}>
        {list.map((item, i) => {
          return (
            <Checkbox
              disabled={disableAction(i)}
              key={item.waybill_group_no}
              value={item.waybill_group_no}
              className={`${prefixCls}-checkbox`}
            >
              {({ checked }) => {
                return (
                  <Spin
                    dot
                    onClick={() => handleCardClick(i)}
                    className={`${prefixCls}-spin`}
                    loading={loadingAction(i)}
                  >
                    <div className={`gui-checkbox-mask ${disableAction(i) ? 'hidden' : ''}`}>
                      <svg
                        className="gui-checkbox-mask-icon"
                        aria-hidden="true"
                        focusable="false"
                        viewBox="0 0 1024 1024"
                        width="200"
                        height="200"
                        fill="currentColor"
                      >
                        <path
                          d="M877.44815445 206.10060629a64.72691371 64.72691371 0 0 0-95.14856334 4.01306852L380.73381888 685.46812814 235.22771741 533.48933518a64.72691371 64.72691371 0 0 0-92.43003222-1.03563036l-45.82665557 45.82665443a64.72691371 64.72691371 0 0 0-0.90617629 90.61767965l239.61903446 250.10479331a64.72691371 64.72691371 0 0 0 71.19960405 15.14609778 64.33855261 64.33855261 0 0 0 35.08198741-21.23042702l36.24707186-42.71976334 40.5190474-40.77795556-3.36579926-3.49525333 411.40426297-486.74638962a64.72691371 64.72691371 0 0 0-3.88361443-87.64024149l-45.3088404-45.43829334z"
                          p-id="840"
                        />
                      </svg>
                    </div>
                    <Card
                      title={`运单组${i + 1}`}
                      extra={renderCardExtra(item, i)}
                      actions={
                        !disableAction(i) && [
                          <span onClick={e => handelEdit(e, i)} className="icon-edit">
                            编辑
                            <IconUniDirectionRightOutline />
                          </span>,
                        ]
                      }
                      className={`${prefixCls}-card ${checked ? 'checked' : ''}`}
                    >
                      <div className={`${prefixCls}-mainInfo`}>
                        {item?.capacity_info?.length > 0 && (
                          <div className={`${prefixCls}-mainInfo__line`}>{item.capacity_info[0].value}</div>
                        )}
                        {item?.group_field_data?.length > 0 && (
                          <Descriptions
                            border={false}
                            layout="inline-horizontal"
                            column={2}
                            data={item.group_field_data}
                          />
                        )}
                      </div>
                      <div className={`${prefixCls}-subInfo`}>
                        {cardList[i]?.viewMore && cardList[i]?.isFold ? (
                          <Descriptions
                            border={false}
                            layout="inline-horizontal"
                            column={2}
                            data={item.field_data.slice(0, FOLD_NUM)}
                          />
                        ) : (
                          <Descriptions
                            border={false}
                            layout="inline-horizontal"
                            column={2}
                            data={item.field_data ?? []}
                          />
                        )}
                      </div>
                      {item?.waybill_group_info && (
                        <div className={`${prefixCls}-mark-area`}>
                          <span className={`mark-ico status-${cardList[i]?.status}`}>{cardList[i]?.text}</span>
                        </div>
                      )}
                      <Meta
                        avatar={
                          cardList[i]?.viewMore ? (
                            <span onClick={e => handelToggle(e, i)} className="action-fold">
                              {cardList[i]?.isFold ? '显示更多' : '收起'}
                            </span>
                          ) : null
                        }
                      />
                    </Card>
                  </Spin>
                );
              }}
            </Checkbox>
          );
        })}
      </Checkbox.Group>
      {isFinished === 1 && (
        <div className={`${prefixCls}-action`}>
          <div className={`${prefixCls}-feedback ${feedbackValue > 0 ? 'done' : ''}`}>
            <Tooltip position="top" content="谢谢夸奖，财财收到喽！" popupVisible={tipVisible.up}>
              <Tooltip position="top" content="识别正确，准确率高">
                <Space onClick={() => feedback(1)} className={`icon-wrap ${feedbackValue === 1 ? 'selected' : ''}`}>
                  <IconUniFillThumbupFill className={`icon ${feedbackValue === 1 ? 'selected' : ''}`} />
                </Space>
              </Tooltip>
            </Tooltip>
            <Tooltip
              position="top"
              content="感谢反馈，财财抓紧联系研发小哥哥优化此功能！"
              popupVisible={tipVisible.down}
            >
              <Tooltip position="top" content="识别错误、准确率低，反馈给后台持续优化">
                <Space onClick={() => feedback(2)} className={`icon-wrap ${feedbackValue === 2 ? 'selected' : ''}`}>
                  <IconUniFillThumbdownFill className={`icon ${feedbackValue === 2 ? 'selected' : ''}`} />
                </Space>
              </Tooltip>
            </Tooltip>
          </div>
          <Button
            onClick={() => handelCreate()}
            disabled={submit.disable}
            type="primary"
            className={`${prefixCls}-create`}
          >
            {submit.text}
          </Button>
        </div>
      )}
    </div>
  );
}
