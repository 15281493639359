import React, { PureComponent } from 'react';
import { ModalDialog, Button, SelectDrop } from 'components';
import { PropTypes } from 'prop-types';
import { prefixCls } from './index.scss';
import _ from 'lodash';
import { createTip } from 'utils/checkInfo';
import { WARN } from 'constants';

import { IconInfoCircle } from '@gui/web-react/icon';
import { Timeline } from '@gui/web-react';
const TimelineItem = Timeline.Item;

const approverHeader = {
  name: '姓名',
  company_name: '所属组织',
  department_name: '部门',
  position_id: '职位',
};

export default class ApproverSelectionModal extends PureComponent {
  static displayName = 'ApproverSelectionModal';
  static propTypes = {
    opType: PropTypes.oneOf(['Add', 'Edit']),
    onSuccess: PropTypes.func,
    onClose: PropTypes.func,
    modalTitle: PropTypes.object,
    data: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentSelect: null,
      currentInfo: null,
    };
  }

  save = () => {
    if (!this.state.currentSelect) {
      createTip('审批人是必填项', WARN).show();
      return;
    }
    this.props.onSuccess(this.state.currentInfo);
    this.props.close();
  };

  cancel = () => {
    this.props.onCancel();
    this.props.close();
  };

  convertApproveData = data => {
    const { appoint_data, audit_or, title, user_list, is_appoint_user } = data;
    const list = [];
    for (let i = 0; i < title.length; i++) {
      list.push({
        title: title[i],
        is_appoint_user: is_appoint_user[i],
        user_list: user_list[i],
        audit_or: audit_or[i],
        key: `${title}-${i}`,
      });
    }
    return {
      appoint_data,
      list,
    };
  };

  onSelect = val => {
    this.setState({
      currentSelect: val?.id ?? null,
      currentInfo: val,
    });
  };

  renderBottom = prop => {
    return (
      <div>
        <Button key={1} type="primary" onClick={() => this.save()}>
          确定
        </Button>
        <Button key={2} onClick={() => this.cancel()}>
          取消
        </Button>
      </div>
    );
  };

  renderApproverName = (audit_or, prop) => {
    if (Array.isArray(prop) && !prop.length) {
      return;
    }
    const user_list = _.values(prop);
    return (
      <div>
        {!audit_or ? (
          user_list.length && user_list.map(item => <div key={item.id}>{item.name}</div>)
        ) : (
          <div>{user_list.map(item => item.name).join('/')}</div>
        )}
      </div>
    );
  };

  // renderTimeline = (prop, appoint_data) => {
  //   return (
  //     <Timeline>
  //       {prop.length &&
  //         prop.map(item => {
  //           return (
  //             <TimelineItem key={item.key}>
  //               <div className="approver-selection-line-item">
  //                 <div className="transform-triangle" />
  //                 <div className="approver-selection-item-title">{item.title}</div>
  //                 <div className="approver-selection-item-value">
  //                   {item.is_appoint_user ? (
  //                     <div className="select-value">
  //                       <div className="select-value-label">请选择审批人</div>
  //                       <div>
  //                         <SelectDrop
  //                           disabled={false}
  //                           data={appoint_data}
  //                           defaultSelected={this.state.currentSelect}
  //                           handleSelected={this.onSelect}
  //                           uniqueKey="id"
  //                           selectOnly
  //                           showKey="name"
  //                           isMultiple={false}
  //                           required
  //                           placeholder="请选择审批人"
  //                           tableHeader={approverHeader}
  //                         />
  //                         <div style={{ color: 'red' }}>(仅能选择本审批环节设置的审批人)</div>
  //                       </div>
  //                     </div>
  //                   ) : (
  //                     this.renderApproverName(item.audit_or, item.user_list)
  //                   )}
  //                 </div>
  //               </div>
  //             </TimelineItem>
  //           );
  //         })}
  //     </Timeline>
  //   );
  // };

  renderSelectDrop = (prop, appoint_data) => {
    const item = prop?.[0] ?? {};
    return (
      item?.is_appoint_user && (
        <div className="select-value">
          <div className="select-value-label">请选择审批人</div>
          <div>
            <SelectDrop
              disabled={false}
              data={appoint_data}
              defaultSelected={this.state.currentSelect}
              handleSelected={this.onSelect}
              uniqueKey="id"
              selectOnly
              showKey="name"
              isMultiple={false}
              required
              placeholder="请选择审批人"
              tableHeader={approverHeader}
            />
            <div style={{ color: 'red' }}>(仅能选择本审批环节设置的审批人)</div>
          </div>
        </div>
      )
    );
  };

  renderContent = data => {
    const { list, appoint_data } = this.convertApproveData(data);
    return (
      <div className={prefixCls}>
        <div className="approver-selection-waring">
          <IconInfoCircle
            style={{
              fontSize: '48px',
              color: 'orange',
            }}
          />
        </div>
        <div className="approver-selection-content">
          <div className="content-title">审批人需要您确认，请确认后保存</div>
          <div className="content-list">{this.renderSelectDrop(list, appoint_data)}</div>
        </div>
      </div>
    );
  };

  render() {
    const { modalTitle, data } = this.props;
    const { title } = modalTitle;
    return (
      <ModalDialog
        title={title}
        content={this.renderContent(data)}
        bottom={this.renderBottom()}
        isShow
        closable
        autoDestroy
        close={() => this.cancel()}
        maskClosable={false}
        style={{ width: 642 }}
        ref={r => (this.selectionModal = r)}
      />
    );
  }
}
