import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import emitter from 'utils/emitter';
import { getCollectionData } from './utils';
import { Tooltip } from 'components';
import { showInfo } from 'utils';
import { WARN } from 'constants';

const CollectionButtonArea = ({
  onBtnClick,
  label = '待对账列表：',
  countVal,
  tooltipContent = '点击查询待对账数据',
  billType = 'receipt',
  dataType = 'add',
}) => {
  const [count, setCount] = useState(countVal ?? 0);
  const Emitter = emitter.getSpance('picker_order');

  const getData = async () => {
    const data = await getCollectionData({ billType, dataType });
    const setVal = countVal ?? (data?.size || 0);
    setCount(setVal);
  };

  const toViewList = () => {
    if (!count) {
      return showInfo(WARN, '暂无对账数据，请加入后查看');
    }
    onBtnClick?.();
  };

  useEffect(() => {
    Emitter.on('setCollection', async () => {
      const data = await getCollectionData({ billType, dataType });
      // 如果传入了数量，则使用传入的
      const setVal = countVal ?? (data?.size || 0);
      setCount(setVal);
    });
    getData();
  });

  return (
    <div className="receipt-payment-collection">
      <Tooltip content={tooltipContent}>
        <div onClick={toViewList}>
          {label}
          <span className="count-val">{count} 条</span>
        </div>
      </Tooltip>
    </div>
  );
};

CollectionButtonArea.propTypes = {
  onBtnClick: PropTypes.func,
  label: PropTypes.string,
  tooltipContent: PropTypes.string,
  billType: PropTypes.string,
  dataType: PropTypes.string,
  countVal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CollectionButtonArea;
