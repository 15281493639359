import React, { useState, useRef, useEffect, useContext, useCallback, forwardRef, useImperativeHandle } from 'react';
import { Tooltip, Table, Input, Form, Message, Spin } from '@gui/web-react';
import {
  IconUniTipsQuestioncircleOutline,
  IconUniOperateSearchOutline,
  IconUniTipsCheckcircleOutline,
  IconUniTipsExclamationcircleOutline,
  IconUniOperateMoreOutline,
} from '@gui/icon-react';
import vega from 'utils/vega';
import { useMessages, useMessagesDispatch } from '../messagesContext';
import { renderTips } from '../utils';
import { prefixCls } from './index.scss';

const FormItem = Form.Item;

const EditableContext = React.createContext({});

function EditableRow(props) {
  const { children, className, index, ...rest } = props;
  const refForm = useRef(null);
  const getForm = () => refForm.current;

  return (
    <EditableContext.Provider value={{ getForm }}>
      <Form
        style={{ display: 'table-row' }}
        ref={refForm}
        // eslint-disable-next-line react/no-children-prop
        children={children}
        wrapper="tr"
        wrapperProps={rest}
        className={`${className} editable-row table-row-${index}`}
      />
    </EditableContext.Provider>
  );
}

function EditableCell(props) {
  const { children, className, rowData, column, onHandleSave } = props;

  const refEdit = useRef(null);
  const refInput = useRef(null);
  const { send } = useMessages();
  const { getForm } = useContext(EditableContext);
  const [editing, setEditing] = useState(false);

  const handleClick = useCallback(
    e => {
      if (editing && column.editable && !refEdit?.current?.contains(e.target)) {
        cellValueChangeHandler(rowData[column.dataIndex]);
      }
    },
    [editing, rowData, column],
  );

  useEffect(() => {
    editing && refInput?.current?.focus();
  }, [editing]);

  useEffect(() => {
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [handleClick]);

  const cellValueChangeHandler = value => {
    const form = getForm();
    form.validate([column.dataIndex], (errors, values) => {
      if (!errors || !errors[column.dataIndex]) {
        setEditing(!editing);
        if (column.dataIndex === 'origin_terms_data') {
          if (values.origin_terms_data && value !== values.origin_terms_data) {
            onHandleSave?.({ ...rowData, ...values, status: 'loading', tips: null });
            send({
              dataType: 'llm_professional_terms_identify',
              scene: 2,
              termsName: rowData.terms_name,
              termsType: rowData.terms_type,
              originData: values.origin_terms_data,
            });
          }
          if (!values.origin_terms_data) {
            onHandleSave?.({ ...rowData, ...values, status: null, tips: null });
          }
          if (value === values.origin_terms_data) {
            return null;
          }
        } else {
          onHandleSave?.({ ...rowData, ...values });
        }
      }
    });
  };

  if (rowData?.index === 0) {
    return children;
  }

  if (editing) {
    const rules = {
      terms_name: [
        {
          maxLength: 50,
        },
      ],
      origin_terms_data: [
        {
          maxLength: 100,
        },
      ],
    };
    return (
      <div ref={refEdit}>
        <FormItem
          style={{ marginBottom: 0 }}
          labelCol={{
            span: 0,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValue={rowData[column.dataIndex]}
          field={column.dataIndex}
          required
          disabled={rowData.status === 'loading'}
          rules={rules[column.dataIndex]}
        >
          <Input
            ref={refInput}
            onPressEnter={cellValueChangeHandler}
            normalizeTrigger={['onBlur']}
            normalize={v => (v ? v.trim() : v)}
          />
        </FormItem>
      </div>
    );
  }

  return (
    <div
      className={column.editable ? `editable-cell ${className}` : className}
      onClick={() => column.editable && setEditing(!editing)}
    >
      {children}
    </div>
  );
}

const EditableTable = forwardRef((props, ref) => {
  const { onClose } = props;
  const dispatch = useMessagesDispatch();
  const { codeTranslationList, tenantId } = useMessages();
  const [loading, setLoading] = useState(false);
  const [keyword1, setKeyord1] = useState('');
  const [keyword2, setKeyord2] = useState('');
  const [showAddBtn, setShowAddBtn] = useState(true);

  useImperativeHandle(ref, () => ({
    submit,
  }));

  const getData = async () => {
    try {
      setLoading(true);
      const data = await vega.orderCenter.termQuery({
        platform_code: 'cyt',
        platform_user_code: window.company_id,
        channel: 4,
        platform_group_id: window.group_id,
        platform_company_id: window.company_id,
        domain_code: 'waybill',
        tenant_id: tenantId,
      });
      setLoading(false);
      const adaptorData = [{}, ...data]?.map((item, index) => ({
        ...item,
        index,
        tips: renderTips(item?.identified_terms_data),
        status: item?.identified_terms_data ? 'success' : 'error',
      }));
      dispatch({
        type: 'SET_CODE_LIST',
        codeTranslationList: adaptorData,
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (keyword1 || keyword2) {
      setShowAddBtn(false);
    } else {
      setShowAddBtn(true);
    }
  }, [keyword1, keyword2]);

  const filter = (item, index) => {
    return (
      index === 0 ||
      ((!keyword1 || item.terms_name?.includes(keyword1)) && (!keyword2 || item.origin_terms_data?.includes(keyword2)))
    );
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      const tableBody = document.getElementsByClassName('gui-table-body')[0];
      tableBody.scrollTop = tableBody.scrollHeight;
    }, 0);
  };

  const validate = () => {
    let repeatList = [];
    const emptyList = codeTranslationList
      ?.filter((item, index) => index > 0)
      ?.filter(i => !i.terms_name || !i.origin_terms_data);
    if (emptyList?.length) {
      Message.error('输入信息不完整,请核对是否代号与翻译/解释都有值后再提交。');
      return false;
    }
    const codeList = codeTranslationList?.filter((item, index) => index > 0)?.map(i => i.terms_name);
    repeatList = codeList?.filter((item, index) => codeList?.indexOf(item) !== index && item !== '');
    if (repeatList?.length) {
      Message.error(`同一使用场景中代号不可重复仅能有一条记录,请检查重复项「${repeatList.join('、')}」后重新提交!`);
      return false;
    }
    if (codeTranslationList?.filter((item, index) => index > 0)?.filter(i => i.status !== 'success').length) {
      Message.error('您有待修改或校验中的数据行,请检查后再提交。');
      return false;
    }
    return true;
  };

  const submit = async () => {
    if (!(await validate())) return null;
    try {
      setLoading(true);
      const data = await vega.orderCenter.termSave({
        platform_group_id: window.group_id,
        platform_company_id: window.company_id,
        platform_user_id: window.user_id,
        tenant_id: tenantId,
        llm_professional_terms: codeTranslationList.filter((item, index) => index > 0),
      });
      if (data) {
        Message.success('保存成功');
        onClose?.();
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const columns = [
    {
      title: '操作',
      dataIndex: 'op',
      width: 60,
      align: 'center',
      className: 'cell-small',
      render: (col, record, index) =>
        index === 0 ? (
          '筛选'
        ) : (
          <div className="btn-wrapper" onClick={() => removeRow(index)}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.8665 8.82406H4.40137V7.49072H11.8665V8.82406Z"
                fill="#FA071C"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.4681 2.49007V13.8234H13.8014V2.49007H2.4681ZM2.13477 1.15674C1.58248 1.15674 1.13477 1.60445 1.13477 2.15674V14.1567C1.13477 14.709 1.58248 15.1567 2.13477 15.1567H14.1348C14.687 15.1567 15.1348 14.709 15.1348 14.1567V2.15674C15.1348 1.60445 14.687 1.15674 14.1348 1.15674H2.13477Z"
                fill="#FA071C"
              />
            </svg>
          </div>
        ),
    },
    {
      title: '序号',
      dataIndex: 'orderNumber',
      width: 60,
      align: 'center',
      className: 'cell-small',
      render: (col, record, index) => (index === 0 ? '' : <div>{index}</div>),
    },
    {
      title: (
        <div className="flex">
          <span>代号</span>
          <Tooltip content="在实际业务中的代号或名词缩写。如：北京白钢">
            <IconUniTipsQuestioncircleOutline className="icon-question-circle" />
          </Tooltip>
        </div>
      ),
      bodyCellStyle: { color: '#532fe6' },
      dataIndex: 'terms_name',
      editable: true,
      width: 200,
      render: (col, record, index) =>
        index === 0 ? (
          <Input
            className="search-filter"
            value={keyword1}
            height={40}
            placeholder="请输入关键词筛选"
            maxLength={20}
            onChange={val => setKeyord1(val)}
          />
        ) : (
          record.terms_name
        ),
    },
    {
      title: (
        <div className="flex">
          <span>翻译/解释</span>
          <Tooltip content="对于代号的说明、注释、解读。例如「北京白钢」翻译/解释：车线名称是北京海淀区西三旗街道->四川省成都市兴隆湖街道，客户名称是北京G7，货物名称是白钢，货物单价是7.6。">
            <IconUniTipsQuestioncircleOutline className="icon-question-circle" />
          </Tooltip>
        </div>
      ),
      dataIndex: 'origin_terms_data',
      editable: true,
      filterIcon: <IconUniOperateSearchOutline />,
      className: 'td-origin_terms_data',
      render: (col, record, index) => {
        const statusMap = {
          success: <IconUniTipsCheckcircleOutline />,
          error: <IconUniTipsExclamationcircleOutline />,
          loading: <IconUniOperateMoreOutline />,
        };
        return index === 0 ? (
          <Input
            className="search-filter"
            value={keyword2}
            height={40}
            placeholder="请输入关键词筛选"
            maxLength={20}
            onChange={val => setKeyord2(val)}
          />
        ) : (
          <Tooltip content={record.tips} position="top">
            <div className={`origin_terms_data-wrapper ${record.status}`}>
              <div>{record.origin_terms_data}</div>
              {statusMap[record.status] ?? ''}
            </div>
          </Tooltip>
        );
      },
    },
  ];

  function handleSave(row) {
    const newData = [...codeTranslationList];
    const index = newData?.findIndex(item => row.index === item.index);
    newData?.splice(index, 1, {
      ...newData[index],
      ...row,
    });
    dispatch({
      type: 'SET_CODE_LIST',
      codeTranslationList: newData,
    });
  }

  function removeRow(index) {
    dispatch({
      type: 'SET_CODE_LIST',
      codeTranslationList: codeTranslationList?.filter((item, i) => index !== i),
    });
  }

  const addRow = () => {
    dispatch({
      type: 'SET_CODE_LIST',
      codeTranslationList: codeTranslationList?.concat({
        index: codeTranslationList.length,
        terms_type: 0,
        terms_name: '',
        origin_terms_data: '',
      }),
    });
    scrollToBottom();
  };

  return (
    <div className={prefixCls}>
      <Spin loading={loading}>
        <Table
          data={codeTranslationList.filter(filter)}
          pagination={false}
          borderCell
          scroll={{ y: 400 }}
          components={{
            body: {
              row: EditableRow,
              cell: EditableCell,
            },
          }}
          columns={columns.map(column =>
            column.editable
              ? {
                  ...column,
                  onCell: () => ({
                    onHandleSave: handleSave,
                  }),
                }
              : column,
          )}
          className="table-editable-cell"
        />
        {showAddBtn && (
          <div className={`${prefixCls}-bottom`}>
            <div className="btn-wrapper" onClick={addRow}>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" className="svg-add">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.8901 8.82406H4.37793V7.49072H11.8901V8.82406Z"
                  fill="#532FE6"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.46777 11.9131L7.46777 4.40088H8.80111L8.80111 11.9131L7.46777 11.9131Z"
                  fill="#532FE6"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.4681 2.49007V13.8234H13.8014V2.49007H2.4681ZM2.13477 1.15674C1.58248 1.15674 1.13477 1.60445 1.13477 2.15674V14.1567C1.13477 14.709 1.58248 15.1567 2.13477 15.1567H14.1348C14.687 15.1567 15.1348 14.709 15.1348 14.1567V2.15674C15.1348 1.60445 14.687 1.15674 14.1348 1.15674H2.13477Z"
                  fill="#532FE6"
                />
              </svg>
              <div>添加</div>
            </div>
          </div>
        )}
      </Spin>
    </div>
  );
});

export default EditableTable;
