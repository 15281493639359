exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".route-card__wrap{min-height:178px}.route-card__wrap .gui-tabs-card-gutter>.gui-tabs-content,.route-card__wrap .gui-tabs-card>.gui-tabs-content{border:0 none}.route-card__wrap .gui-tabs-content{padding-top:0}.route-card__wrap .gui-tabs-header-nav-card-gutter .gui-tabs-header-title,.route-card__wrap .gui-tabs-header-nav-card .gui-tabs-header-title{padding:4px 20px}.route-card__wrap .gui-tabs-header-title-text{color:#142035;font-weight:500}.route-card__wrap .gui-tabs-header-title-active .gui-tabs-header-title-text{color:#165dff}.route-card__wrap .gui-tabs-header-nav-card .gui-tabs-header-title{background-color:#f4f5f6}.route-card__wrap .gui-tabs-header-nav-card .gui-tabs-header-title-active,.route-card__wrap .gui-tabs-header-nav-card .gui-tabs-header-title-active:hover{border-bottom-color:#e5e6eb;background-color:transparent}.route-card__add{display:flex;justify-content:center;align-items:center;height:178px}.route-card__add .gui-btn{font-size:12px}.route-card__add .gui-btn .fn-icon{margin-right:6px}.route-card__view{display:flex}.route-card__info{flex:1;flex-shrink:0;margin-right:20px;overflow:hidden}.route-card__item{display:flex;padding:0 0 0 8px;height:36px;border:1px solid #ddd;border-width:0 1px 1px;font-size:14px;line-height:36px}.route-card__label{width:105px;color:#4e5869;text-align:right}.route-card__value{display:flex;flex:1;padding-right:8px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;font-weight:500;color:#2c2b31}.route-card__path{max-width:100%;overflow:hidden}.route-card__map{position:relative;flex:1;flex-shrink:0}.route-card__map__btn{position:absolute;left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%);bottom:10px}.route-card__btn{display:flex;align-items:center;height:44px;font-size:14px;color:#4e5969}.route-card__btn__left{flex:1}.route-card__btn__right__btn{margin-left:10px}", ""]);

// exports
exports.locals = {
	"prefixCls": "route-card",
	"route-card__wrap": "route-card__wrap",
	"gui-tabs-card": "gui-tabs-card",
	"gui-tabs-content": "gui-tabs-content",
	"gui-tabs-card-gutter": "gui-tabs-card-gutter",
	"gui-tabs-header-nav-card": "gui-tabs-header-nav-card",
	"gui-tabs-header-title": "gui-tabs-header-title",
	"gui-tabs-header-nav-card-gutter": "gui-tabs-header-nav-card-gutter",
	"gui-tabs-header-title-text": "gui-tabs-header-title-text",
	"gui-tabs-header-title-active": "gui-tabs-header-title-active",
	"route-card__add": "route-card__add",
	"gui-btn": "gui-btn",
	"fn-icon": "fn-icon",
	"route-card__view": "route-card__view",
	"route-card__info": "route-card__info",
	"route-card__item": "route-card__item",
	"route-card__label": "route-card__label",
	"route-card__value": "route-card__value",
	"route-card__path": "route-card__path",
	"route-card__map": "route-card__map",
	"route-card__map__btn": "route-card__map__btn",
	"route-card__btn": "route-card__btn",
	"route-card__btn__left": "route-card__btn__left",
	"route-card__btn__right__btn": "route-card__btn__right__btn"
};