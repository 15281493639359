exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".check-set{--border-radius-medium:0px;--color-fill-1:#f6f7f8;--color-neutral-3:#d3d3d3;--gray-10:#1d2129;--color-neutral-2:#f7f8fa;--gui-table-border-top-color:#378cbe}.check-set table,.check-set td,.check-set th,.check-set tr{border:0}.check-set .gui-table-header::-webkit-scrollbar-track{-webkit-box-shadow:none}.check-set.gui-table-border .gui-table-container{border-top:1px solid var(--gui-table-border-top-color)}.check-set .gui-table-td{border-bottom:1px solid var(--color-neutral-3)}.check-set thead>.gui-table-tr{height:30px}.check-set .gui-table-td,.check-set .gui-table-th{font-size:12px}.check-set thead>.gui-table-tr>.gui-table-th{color:#333;font-weight:700}.check-set .gui-table-td,.check-set .gui-table-th-item{padding:0}.check-set .gui-table-empty-row .gui-table-td{padding:9px 16px}.check-set.gui-table-has-fixed-col-left.gui-table-border .gui-table-expand-fixed-row,.check-set.gui-table-has-fixed-col-left.gui-table-border .gui-table-td:first-child,.check-set.gui-table-has-fixed-col-left.gui-table-border .gui-table-th:first-child{border-left:0}.check-set.gui-table-has-fixed-col-left.gui-table-border .gui-table-body{height:190px;border-left:1px solid var(--color-neutral-3)}.check-set.gui-table-has-fixed-col-left.gui-table-border .gui-table-header{border-left:1px solid var(--color-neutral-3)}.check-set .fn-input-pure-wrap{width:calc(100% - 4px)}.check-set_item-input{display:flex;align-items:center;justify-content:space-around}.check-set_item-input .fn-input-pure-wrap{width:calc(100% - 22px)}.check-set .fn-input,.check-set .fn-input-pure,.check-set .fn-input-pure:hover,.check-set .fn-input:hover{border:0}.check-set .fn-checkDrop{z-index:99999}.check-set .gui-typography{margin-bottom:0;height:30px;line-height:30px}.check-set .img-list-wrap{padding:0 3px}.check-set .img-list-wrap .upload{margin-right:3px;line-height:24px}.check-set .img-list-wrap .pic-list-item{margin-right:0}", ""]);

// exports
exports.locals = {
	"prefixCls": "check-set",
	"check-set": "check-set",
	"gui-table-header": "gui-table-header",
	"gui-table-border": "gui-table-border",
	"gui-table-container": "gui-table-container",
	"gui-table-td": "gui-table-td",
	"gui-table-tr": "gui-table-tr",
	"gui-table-th": "gui-table-th",
	"gui-table-th-item": "gui-table-th-item",
	"gui-table-empty-row": "gui-table-empty-row",
	"gui-table-has-fixed-col-left": "gui-table-has-fixed-col-left",
	"gui-table-expand-fixed-row": "gui-table-expand-fixed-row",
	"gui-table-body": "gui-table-body",
	"fn-input-pure-wrap": "fn-input-pure-wrap",
	"check-set_item-input": "check-set_item-input",
	"fn-input-pure": "fn-input-pure",
	"fn-input": "fn-input",
	"fn-checkDrop": "fn-checkDrop",
	"gui-typography": "gui-typography",
	"img-list-wrap": "img-list-wrap",
	"upload": "upload",
	"pic-list-item": "pic-list-item"
};