export default i18n => {
  const batchDict = i18n.get('batch', '批次');
  const taskhDict = i18n.get('batch', '任务');
  const orderDict = i18n.get('order', '运单');
  return {
    返程: {
      key: 'return_flag',
      text: 'return_flag',
      tips: '返程',
    },
    [`作废${orderDict}`]: {
      key: 'deserted',
      text: '废',
      tips: `作废${orderDict}`,
      color: '#7D9CAC',
      bgColor: '#F1F7FA',
    },
    [`${taskhDict}作废`]: {
      key: 'deserted',
      text: '废',
      tips: `${taskhDict}作废`,
      color: '#7D9CAC',
      bgColor: '#F1F7FA',
    },
    [`退货${orderDict}`]: {
      key: 'back_flag',
      text: '退',
      tips: `退货${orderDict}`,
      color: '#FFB20C',
      bgColor: '#FFFAEF',
    },
    [`异常${orderDict}`]: {
      key: 'abnormal_flag',
      text: '异',
      tips: `异常${orderDict}`,
      color: '#F12222',
      bgColor: '#FFEFEF',
    },
    [`异常${batchDict}`]: {
      key: 'abnormal_flag',
      text: '异',
      tips: `异常${batchDict}`,
      color: '#F12222',
      bgColor: '#FFEFEF',
    },
    [`异常${taskhDict}`]: {
      key: 'abnormal_flag',
      text: '异',
      tips: `异常${taskhDict}`,
      color: '#F12222',
      bgColor: '#FFEFEF',
    },
    等通知放货: {
      key: 'notice_delivery',
      text: '控',
      tips: '等通知放货',
      color: '#DA6FCC',
      bgColor: '#FFF1FD',
    },
    [`${orderDict}已投保`]: {
      key: 'is_insured',
      text: '保',
      tips: `${orderDict}已投保`,
      color: '#13C450',
      bgColor: '#EFFFF5',
    },
    [`${batchDict}已投保`]: {
      key: 'is_insured',
      text: '保',
      tips: `${batchDict}已投保`,
      color: '#13C450',
      bgColor: '#EFFFF5',
    },
    已发送短信: {
      key: 'msg_flag',
      text: '信',
      tips: '已发送短信',
      color: '#007BE7',
      bgColor: '#F7FBFF',
    },
    在线支付: {
      key: 'pay_online',
      text: '付',
      tips: '在线支付',
      color: '#487EFF',
      bgColor: '#EEF3FF',
    },
    拆单: {
      key: 'split_flag',
      text: '拆',
      tips: '拆单',
      color: '#FF8800',
      bgColor: '#FFF6EC',
    },
    订单已拆单: {
      key: 'split',
      text: '拆',
      tips: '订单已拆单',
      color: '#533ce4',
      bgColor: '#eeebff',
    },
    订单部分拆单: {
      key: 'splitting',
      text: '拆',
      tips: '订单部分拆单',
      color: '#41a1ec',
      bgColor: '#eff8ff',
    },
    移库: {
      key: 'yi_ku',
      text: '移',
      tips: '移库',
      color: '#FF5200',
      bgColor: '#FFF1EA',
    },
    [`${orderDict}已移库`]: {
      key: 'yi_ku',
      text: '移',
      tips: '移库',
      color: '#FF5200',
      bgColor: '#FFF1EA',
    },
    [`平台${orderDict}`]: {
      key: 'ooooo5',
      text: '',
      tips: `平台${orderDict}`,
    },
    [`${orderDict}已修改`]: {
      key: 'passed_om',
      text: '改',
      tips: `${orderDict}已修改`,
      color: '#41A1EC',
      bgColor: '#EFF8FF',
    },
    [`${taskhDict}已修改`]: {
      key: 'passed_om',
      text: '改',
      tips: `${taskhDict}已修改`,
      color: '#41A1EC',
      bgColor: '#EFF8FF',
    },
    [`计划转${taskhDict}`]: {
      key: 'batch_plan',
      text: '计',
      tips: `计划转${taskhDict}`,
      color: '#FF8551',
      bgColor: '#fff1ea',
    },
    已绑定设备: {
      key: 'device_flag',
      text: '绑',
      tips: '已绑定设备',
      color: '#41A1EC',
      bgColor: '#EFF8FF',
    },
    司机扫码: {
      key: 'driver_scan_order',
      text: '扫',
      tips: '司机扫码',
      color: '#6F1AC6',
      bgColor: '#E2E2FF',
    },
    [`${orderDict}已审核`]: {
      key: 'is_check',
      text: '审',
      tips: `${orderDict}已审核`,
      color: '#FF6800',
      bgColor: '#FFF8F4',
    },
    扫码卸车: {
      key: 'pda_scan_unload',
      text: '扫',
      tips: '已操作扫码卸车',
      color: '#FE5493',
      bgColor: '#FFF0F5',
    },
    扫码装车: {
      key: 'pda_scan_load',
      text: '扫',
      tips: '已操作扫码装车',
      color: '#07CD9B',
      bgColor: '#F2FFFD',
    },
    扫码入库: {
      key: 'pda_scan_inwh',
      text: '扫',
      tips: '已操作扫描入库',
      color: '#533CE4',
      bgColor: '#EEEBFF',
    },
    发票已开: {
      key: 'is_invoiced',
      text: '票',
      tips: '发票已开',
      color: '#477DB2',
      bgColor: '#F0F5FF',
    },
    [`${orderDict}有异动`]: {
      key: 'is_move',
      text: '动',
      tips: `${orderDict}有异动`,
      color: '#F56D6D',
      bgColor: '#FFF5F5',
    },
    [`${orderDict}已挂失`]: {
      key: 'loss_flag',
      text: '失',
      tips: `${orderDict}已挂失，代收货款已冻结`,
      color: '#FF5BDB',
      bgColor: '#FFF5FD',
    },
    其他上传照片: {
      key: 'upload_image_flag',
      text: 'upload_image',
      tips: '其他上传照片',
    },
    已上传签收照片: {
      key: 'sign_image_flag',
      text: 'sign_image',
      tips: '已上传签收照片',
    },
    已上传回单照片: {
      key: 'receipt_image_flag',
      text: 'receipt_image',
      tips: '已上传回单照片',
    },
    急: {
      key: 'urgent',
      text: '急',
      tips: `紧急${orderDict}`,
      color: '#F48F4E',
      bgColor: '#FFF8F4',
    },
    加急: {
      key: 'much_urgent',
      text: '急',
      tips: `加急${orderDict}`,
      color: '#F48F4E',
      bgColor: '#FFF8F4',
    },
    特急: {
      key: 'extremely_urgent',
      text: '急',
      tips: `特急${orderDict}`,
      color: '#F48F4E',
      bgColor: '#FFF8F4',
    },
    [`来自${orderDict}导入`]: {
      key: 'co_from_import',
      text: 'import',
      tips: `来自${orderDict}导入`,
    },
    来自安卓开单: {
      key: 'co_from_android',
      text: 'android',
      tips: '来自安卓开单',
    },
    来自IOS开单: {
      key: 'co_from_ios',
      text: 'ios',
      tips: '来自IOS开单',
    },
    微信支付: {
      key: 'wechat_pay',
      text: 'wechat_pay',
      cls: 'pay-by-weixin',
      tips: '微信支付',
    },
    支付宝支付: {
      key: 'alipay',
      text: 'alipay',
      cls: 'pay-by-ali',
      tips: '支付宝支付',
    },
    银联卡支付: {
      key: 'unionpay',
      text: 'unionpay',
      cls: 'pay-by-union',
      tips: '银联卡支付',
    },
    货主确认运费无误: {
      key: 'mb_confirm',
      text: 'mb_confirm',
      cls: 'mb-confrim',
      tips: '货主确认运费无误',
    },
    货主确认运费有误: {
      key: 'mb_confirm_failed',
      text: 'mb_confirm_failed',
      cls: 'mb-confrim-failed',
      tips: '货主确认运费有误',
    },
    货主已支付: {
      key: 'shipper_pay',
      text: 'shipper_pay',
      tips: '货主已支付',
    },
    满帮已结算: {
      key: 'mb_pay',
      text: 'mb_pay',
      tips: '满帮账户收款',
    },
    扫描开单: {
      key: 'scan',
      text: 'scan',
      tips: '扫描开单',
    },
    到货部内转三次: {
      key: 'intrans_3_flag',
      text: 'intrans_3_flag',
      tips: '到货部内转三次',
    },
    到货部内转二次: {
      key: 'intrans_2_flag',
      text: 'intrans_2_flag',
      tips: '到货部内转二次',
    },
    到货部内转一次: {
      key: 'intrans_1_flag',
      text: 'intrans_1_flag',
      tips: '到货部内转一次',
    },
    到货部内转四次: {
      key: 'intrans_4_flag',
      text: 'intrans_4_flag',
      tips: '到货部内转四次',
    },
    到货部内转五次: {
      key: 'intrans_5_flag',
      text: 'intrans_5_flag',
      tips: '到货部内转五次',
    },
    货主已线下支付: {
      key: 'offline_pay',
      text: 'offline_pay',
      tips: '货主已线下支付',
    },
    可提货指派: {
      key: 'need_pickup_flag',
      text: 'need_pickup_flag',
      tips: '可提货指派',
    },
    可送货指派: {
      key: 'need_delivery_flag',
      text: 'need_delivery_flag',
      tips: '可送货指派',
    },
    已打印: {
      key: 'order_printed',
      text: 'order_printed',
      tips: '已打印',
    },
    应付现付加急: {
      key: 'b_billing_urgent',
      text: 'b_billing_urgent',
      tips: '应付现付加急',
    },
    应付到付加急: {
      key: 'b_arr_urgent',
      text: 'b_arr_urgent',
      tips: '应付到付加急',
    },
    应付回付加急: {
      key: 'b_receipt_urgent',
      text: 'b_receipt_urgent',
      tips: '应付回付加急',
    },
    [`空驶${taskhDict}`]: {
      key: 'empty_task',
      text: '空',
      tips: `空驶${taskhDict}`,
      color: '#FF6800',
      bgColor: '#FFF8F4',
    },
    发车位置已上报: {
      key: 'depart_position_report',
      text: 'depart_position_report',
      tips: '发车位置已上报',
    },
    到达位置已上报: {
      key: 'arrive_poi_upload',
      text: 'arrive_poi_upload',
      tips: '到达位置已上报',
    },
    [`${taskhDict}晚点`]: {
      key: 'arr_later',
      text: '晚',
      tips: `${taskhDict}晚点`,
      color: '#A39791',
      bgColor: '#FFFCFA',
    },
    [`${batchDict}晚点`]: {
      key: 'arr_later',
      text: '晚',
      tips: `${batchDict}晚点`,
      color: '#A39791',
      bgColor: '#FFFCFA',
    },
    高: {
      key: 'balance_flag_max',
      text: 'balance_flag_max',
      tips: '金额标识高',
    },
    低: {
      key: 'balance_flag_min',
      text: 'balance_flag_min',
      tips: '金额标识低',
    },
    司机已评价: {
      key: 'appraised_driver',
      text: 'appraised_driver',
      tips: '司机已评价',
    },
    部分到达: {
      key: 'arrival_partly',
      text: 'arrival_partly',
      tips: '部分到达',
    },
    车队已评价: {
      key: 'carrier_appraised',
      text: 'carrier_appraised',
      tips: '车队已评价',
      [`${taskhDict}晚点`]: {
        key: 'arr_later',
        text: '晚',
        tips: `${taskhDict}晚点`,
        color: '#A39791',
        bgColor: '#FFFCFA',
      },
      [`${batchDict}晚点`]: {
        key: 'arr_later',
        text: '晚',
        tips: `${batchDict}晚点`,
        color: '#A39791',
        bgColor: '#FFFCFA',
      },
    },
    司机已确认收款人: {
      key: 'driver_confirm_payee',
      text: 'driver_confirm_payee',
      tips: '司机已确认收款人',
    },
    自动结算: {
      key: 'auto_pay',
      text: 'auto_pay',
      tips: '自动结算（生效中）',
    },
    取消自动结算: {
      key: 'cancel_auto_pay',
      text: 'cancel_auto_pay',
      tips: '自动结算已取消',
    },
    员工操作发车或到达: {
      key: 'dispatcher_depart_arrival',
      text: '员',
      tips: '员工操作发车或到达',
      color: '#FF7D00',
      bgColor: '#FFF7E8',
    },
    司机操作发车或到达: {
      key: 'driver_depart_arrival',
      text: '司',
      tips: '司机操作发车或到达',
      color: '#165DFF',
      bgColor: '#E8F3FF',
    },
    司机已导航: {
      key: 'driver_navigated',
      text: '导',
      tips: '司机已导航',
      color: '#532FE6',
      bgColor: '#F0E8FF',
    },
    电子围栏触发: {
      key: 'fence_depart_arrival',
      text: '电',
      tips: '电子围栏触发',
      color: '#3AA965',
      bgColor: '#E8FFED',
    },
    超标调车单: {
      key: 'over_budget',
      text: '超',
      tips: '超标调车单',
      color: '#F54A45',
      bgColor: '#FFFCFA',
    },
    已购买货物安全管理保障服务: {
      key: 'icsg_mark',
      text: '险',
      tips: '已购买货物安全管理保障服务',
      color: '#F54A45',
      bgColor: '#FFFCFA',
    },
    已导出图片: {
      key: 'image_unexport',
      text: '导',
      tips: '已导出图片',
      color: '#532FE6',
      bgColor: '#F0E8FF',
    },
    未导出图片: {
      key: 'image_unexport',
      text: '导',
      tips: '未导出图片',
      color: '#A39791',
      bgColor: '#FFFCFA',
      hide: true,
    },
    普通货物: {
      key: 'is_normal_goods',
      text: 'is_normal_goods',
      tips: '普通货物',
    },
    危险货物: {
      key: 'is_dangers_goods',
      text: 'is_dangers_goods',
      tips: '危险货物',
    },
    危化车辆: {
      key: 'is_dangerous_car',
      text: 'is_dangerous_car',
      tips: '危化车辆',
    },
    结束发运: {
      key: 'end_despatch_flag',
      text: '结',
      tips: '结束发运',
      color: '#FF7D00',
      bgColor: '#EFF8FF',
    },
  };
};
