import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { ModalDialog, Button, PureInput as Input, Icon } from 'components';

import { showInfo, fetchApi as fetch } from 'utils';
import { ERROR, INFO } from 'constants';

import { prefixCls } from './index.scss';

class RegisterInfo extends PureComponent {
  static propTypes = {
    close: PropTypes.func,
  };

  static defaultProps = {
    id: 0,
  };

  constructor(props) {
    super(props);

    const userInfo = window.user_info || {};

    this.state = {
      isLoading: false,
      user_name: userInfo.name || '',
      telephone: userInfo.telephone || '',
    };
  }

  doFetch = async () => {
    const userInfo = window.user_info || {};
    const { user_name, telephone } = this.state;

    const reqData = {
      user_id: userInfo.id || '', // 登录系统的的用户id。
      tms_version: '2', // tms版本。1 C11; 2 哥伦布
      user_name, // 联系人。
      telephone, // 联系方式。
      source: 'PC申请', // 来源（也是最新来源）。
      product_name: '结算服务', // 类型。
    };
    this.setState({ isLoading: true });

    const conf = { method: 'POST', body: { req: reqData } };
    const saveRes = await fetch('/Loan/Loan/pclientApply', conf);
    this.setState({ isLoading: false });
    if (+saveRes.errno !== 0) {
      showInfo(ERROR, saveRes.errmsg);
      return false;
    }
    showInfo(INFO, saveRes.errmsg);
    this.closableCallback();
  };

  closableCallback = () => {
    this.modal.handleHide();
    this.props.close && this.props.close();
  };

  renderContent = () => {
    const { user_name, telephone, isLoading } = this.state;
    return (
      <div className="form-wrap">
        <div className="form-title">请登记您的联系方式，我们的小伙伴会尽快联系您</div>
        <Input value={user_name} required onChange={e => this.setState({ user_name: e.target.value })}>
          <Icon iconType="icon-avtor" />
        </Input>
        <Input value={telephone} required onChange={e => this.setState({ telephone: e.target.value })}>
          <Icon iconType="icon-iphone" />
        </Input>
        <Button classname="form-confirm" loading={isLoading} onClick={this.doFetch}>
          确定
        </Button>
      </div>
    );
  };

  render() {
    const { close } = this.props;
    const { title } = this.state;
    return (
      <ModalDialog
        content={this.renderContent()}
        ref={ref => (this.modal = ref)}
        closable
        classname={prefixCls}
        // maskClosable={false}
        style={{ width: 438 }}
        // handleHideCallback={this.handleHide}
        isShow
        autoDestroy
        closableCallback={this.closableCallback}
      />
    );
  }
}

export default RegisterInfo;
