exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".pick-order-amount-detail{--border-radius-medium:0px;--color-neutral-3:#b9ccd7;--gray-10:#1d2129;--color-neutral-2:#f7f8fa}.pick-order-amount-detail table,.pick-order-amount-detail td,.pick-order-amount-detail th,.pick-order-amount-detail tr{border:0}.pick-order-amount-detail td{border-bottom:1px solid var(--color-neutral-3)}.pick-order-amount-detail .expense-column .gui-table-cell{display:flex}.pick-order-amount-detail .expense-column .gui-table-cell-wrap-value{margin-left:40%}.pick-order-amount-detail .gui-table-header::-webkit-scrollbar-track{-webkit-box-shadow:none}.pick-order-amount-detail .gui-table-td,.pick-order-amount-detail .gui-table-th{font-size:12px}.pick-order-amount-detail .gui-table-td,.pick-order-amount-detail .gui-table-th-item{padding:6px 12px}.pick-order-amount-detail .icon-help{padding-left:8px}.pick-order-amount-detail .fn-icon-help{font-size:14px;width:14px;height:14px;line-height:14px;color:#0097cf}", ""]);

// exports
exports.locals = {
	"prefixCls": "pick-order-amount-detail",
	"pick-order-amount-detail": "pick-order-amount-detail",
	"expense-column": "expense-column",
	"gui-table-cell": "gui-table-cell",
	"gui-table-cell-wrap-value": "gui-table-cell-wrap-value",
	"gui-table-header": "gui-table-header",
	"gui-table-th": "gui-table-th",
	"gui-table-td": "gui-table-td",
	"gui-table-th-item": "gui-table-th-item",
	"icon-help": "icon-help",
	"fn-icon-help": "fn-icon-help"
};