/**
 * Created by wangnaihe on 2017/9/21.
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import { Select, PureInput as Input } from 'components';
import { shallowCompareIgnoreFunc, buildTipMixin, addressFullName, genCustomAddress } from 'utils';
import GISSug, { GISHeader } from 'utils/sug/gis';
import districtSug, { districtHeader } from 'utils/sug/district';
import { prefixCls } from './index.scss';

const header = [
  { title: '区域类型', key: 'typeDisplay' },
  { title: '区域名称', key: 'fullName' },
];

const CUSTOM = 'custom';

const DISTRICT = 'district';

const GIS = 'gis';

const recieptAddress = 'rp_ad_0'; // 收货地址

const convertValueToData = value =>
  value.map(val => {
    const type = val.type || (val.adcode ? DISTRICT : CUSTOM);
    const isGIS = type === GIS;
    // eslint-disable-next-line no-nested-ternary
    const typeDisplay = isGIS ? 'GIS区域' : type === DISTRICT ? '行政区划' : '自定义地址';
    return {
      val,
      name: isGIS ? val.zone_name : val.show_val,
      fullName: isGIS ? val.zone_name : addressFullName(val),
      key: `${type}_${val.id || val.show_val}`,
      type,
      typeDisplay,
    };
  });

export default class Area extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.any,
    className: PropTypes.string,
    selectOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    defaultMode: PropTypes.string,
  };

  state = { mode: this.props.defaultMode, data: convertValueToData(this.props.value || []) };

  shouldComponentUpdate = shallowCompareIgnoreFunc;

  setAddress = e => this.setState({ address: e.target.value });

  customAddressTip = buildTipMixin('点击切换为字符输入，输入后回车添加字符');

  districtTip = buildTipMixin('行政区划');

  GISTip = buildTipMixin('GIS区域');

  addCustomAddress = e => {
    if (e.keyCode !== 13) return;
    const { value } = e.target;
    if (value) {
      this.setState({ address: '' });
      this.onSelect(genCustomAddress(value));
    }
  };

  onSelect = val => {
    const { data } = this.state;
    const { value = [] } = this.props;
    const newData = [...data];
    // eslint-disable-next-line no-nested-ternary
    const arr = Array.isArray(val) ? val : val ? [val] : [];
    const filteredVal = arr.filter(item =>
      value.every(v => v.id !== item.id || v.show_val !== item.show_val || v.type !== item.type),
    );
    if (filteredVal.length) {
      this.props.onChange && this.props.onChange([...value, ...filteredVal]);
    }
    arr.forEach(item => {
      const type = item.type || (item.adcode ? DISTRICT : CUSTOM);
      const id = `${type}_${item.id || item.show_val}`;
      if (data.every(d => d.key !== id)) {
        newData.push(convertValueToData([item])[0]);
      }
    });
    this.setState({ data: newData });
  };

  filterGIS = async (search, { fetch }) => {
    const res = await GISSug({ search, fetch });
    const GISData = res.map(item => ({
      id: item.id,
      zone_name: item.zone_name,
      zone_no: item.zone_no,
      area: item.area,
      type: 'gis',
    }));
    this.setState({ GISData });
  };

  // eslint-disable-next-line no-nested-ternary
  getIcon = item => (item.type === DISTRICT ? 'qizi' : item.type === CUSTOM ? 'bianji2' : 'ditu');

  format = () => '';

  render() {
    const { value = [], onChange, className, disabled, selectOnly, hiddenGis = true } = this.props;
    const { mode, GISData, data, address } = this.state;
    const classes = classnames({ [prefixCls]: true, [className]: className });
    const selectValue = convertValueToData(value);
    const gisFlag = window.company_setting.link_net_gis ? window.company_setting.link_net_gis.value : '';
    return (
      <div className={classes}>
        {!disabled && (
          <div className="area-input">
            {mode === CUSTOM && <Input value={address} onChange={this.setAddress} onKeyDown={this.addCustomAddress} />}
            {mode === DISTRICT && (
              <Select
                map={false}
                header={districtHeader}
                multiple
                compare="id"
                format="show_val"
                filter={(search, { fetch }) => districtSug({ search, fetch, num: 200 })}
                onChange={this.onSelect}
              />
            )}
            {mode === GIS && (
              <Select
                map={false}
                showIcon={false}
                format="zone_name"
                header={GISHeader}
                filter={this.filterGIS}
                onChange={this.onSelect}
                data={GISData}
              />
            )}
            <span className="area-mode">
              {!selectOnly && (
                <i
                  {...this.customAddressTip}
                  className={classnames({ 'fn-icon-il fn-icon-bianji2': true, active: mode === CUSTOM })}
                  onClick={() => this.setState({ mode: CUSTOM })}
                />
              )}
              <i
                {...this.districtTip}
                className={classnames({ 'fn-icon-il fn-icon-qizi': true, active: mode === DISTRICT })}
                onClick={() => this.setState({ mode: DISTRICT })}
              />
              {gisFlag === recieptAddress && hiddenGis && (
                <i
                  {...this.GISTip}
                  className={classnames({ 'fn-icon-il fn-icon-ditu': true, active: mode === GIS })}
                  onClick={() => this.setState({ mode: GIS })}
                />
              )}
            </span>
          </div>
        )}
        <Select
          className="area-select"
          map="val"
          filter={false}
          collapseAll={false}
          value={selectValue}
          multiple
          header={header}
          format={this.format}
          onChange={onChange}
          data={data}
          disabled={disabled}
        >
          <span className="area-name">
            {selectValue.map((item, i) => (
              <span key={i}>
                <i className={`fn-icon-il fn-icon-${this.getIcon(item)}`} /> {item.name} ；
              </span>
            ))}
          </span>
        </Select>
      </div>
    );
  }
}
