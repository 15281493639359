exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".capital-flow__header{font-size:22px;text-align:center}.capital-flow .fee-breakdown{margin:0 10px}.capital-flow__paymode{width:100%;margin-top:10px;padding:0 10px}.capital-flow__upload-img{padding:0 10px}.capital-flow__upload-img h3{margin:6px 0;font-size:14px}.capital-flow__upload-img h3 span{margin-left:4px;color:red}.capital-flow__files-wrap{padding:0 10px;margin-top:10px}", ""]);

// exports
exports.locals = {
	"prefixCls": "capital-flow",
	"capital-flow__header": "capital-flow__header",
	"capital-flow": "capital-flow",
	"fee-breakdown": "fee-breakdown",
	"capital-flow__paymode": "capital-flow__paymode",
	"capital-flow__upload-img": "capital-flow__upload-img",
	"capital-flow__files-wrap": "capital-flow__files-wrap"
};