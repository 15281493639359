const deliveryHeader = {
  car_batch: { title: '批次号', display: 'show', dispay: 'show' },
  route_text: { title: '车线名称', display: 'show', dispay: 'show' },
  com_name: { title: '网点', display: 'show', dispay: 'show' },
  b_tr_num: { title: '车牌号', display: 'show', dispay: 'show' },
};
const _config = {
  task_loading: {
    apiUrl: '/cmm-batch/batch/load',
    reasonKey: '',
    isReason: '',
    opType: '装车',
    noCheck: true,
    header: deliveryHeader,
    idKey: 'b_basic_id',
  },
};
export default _config;
