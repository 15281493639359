exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-area{display:inline-block;width:360px;vertical-align:top}.fn-area .area-input{position:relative;margin-bottom:6px}.fn-area .fn-input-pure-wrap{width:100%}.fn-area .area-mode{position:absolute;font-size:14px;right:5px;top:0;line-height:24px}.fn-area .area-mode .fn-icon-il{color:#bbb}.fn-area .area-mode .fn-icon-bianji2{padding-right:8px;margin-right:8px;border-right:1px solid #bbb}.fn-area .area-mode .fn-icon-bianji2.active{color:#0097cf}.fn-area .area-mode .fn-icon-qizi.active{color:#1dbb73}.fn-area .area-mode .fn-icon-ditu{padding-left:8px;margin-left:8px;border-left:1px solid #bbb}.fn-area .area-mode .fn-icon-ditu.active{color:#ff9c22}.fn-area .area-select.active{padding-left:0}.fn-area .area-select .fn-input-pure{opacity:0;position:absolute;z-index:1}.fn-area .area-select .area-name{position:absolute;width:100%;z-index:0;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;padding-right:20px}.fn-area .area-select .area-name>span{margin-left:4px;color:#000}.fn-area .area-select .fn-icon-bianji2{color:#0097cf}.fn-area .area-select .fn-icon-qizi{color:#1dbb73}.fn-area .area-select .fn-icon-ditu{color:#ff9c22}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-area",
	"fn-area": "fn-area",
	"area-input": "area-input",
	"fn-input-pure-wrap": "fn-input-pure-wrap",
	"area-mode": "area-mode",
	"fn-icon-il": "fn-icon-il",
	"fn-icon-bianji2": "fn-icon-bianji2",
	"active": "active",
	"fn-icon-qizi": "fn-icon-qizi",
	"fn-icon-ditu": "fn-icon-ditu",
	"area-select": "area-select",
	"fn-input-pure": "fn-input-pure",
	"area-name": "area-name"
};