exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".upgrade-more-button__wrap{display:inline-block;font-size:12px;color:#4e5969;cursor:pointer}.upgrade-more-button__icon{display:inline-flex;width:22px;height:22px;border:1px solid #9675f0;border-radius:3px;margin:0 5px;justify-content:center;align-items:center}", ""]);

// exports
exports.locals = {
	"prefixCls": "upgrade-more-button",
	"upgrade-more-button__wrap": "upgrade-more-button__wrap",
	"upgrade-more-button__icon": "upgrade-more-button__icon"
};