import React from 'react';
import { TextArea } from 'components';
import { bem } from 'utils';

import { prefixCls } from './index.scss';

const cls = bem(prefixCls);

const auditSet = (key, val, page) => {
  const { valHub = {} } = page.state;
  valHub[key] = val;
  page.setState({ valHub: { ...valHub } });
};

const renderAudit = page => {
  const { valHub = {} } = page.state;
  return (
    <div className={cls()}>
      <label className={cls('fn-label')}>审批意见：</label>
      <TextArea
        name="audit_remark"
        maxLength="512"
        classname="remark"
        placeholder=""
        defaultValue={valHub.audit_remark || ''}
        onChange={e => auditSet('audit_remark', e.target.value, page)}
      />
    </div>
  );
};

export default renderAudit;
