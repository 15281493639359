exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".show-dialog{width:440px}.show-dialog .show-info-content .text{font-size:14px;line-height:14px;color:#000;font-family:Microsoft YaHei}.show-dialog .fn-show_info__text_con_notice{padding:0 10px 0 18px}.show-dialog .fn-show_info__text_con_notice .show-info-notice{margin-top:16px;line-height:17px;font-size:12px;font-family:Microsoft YaHei;color:#888}", ""]);

// exports
exports.locals = {
	"dialogClass": "show-dialog",
	"show-dialog": "show-dialog",
	"show-info-content": "show-info-content",
	"text": "text",
	"fn-show_info__text_con_notice": "fn-show_info__text_con_notice",
	"show-info-notice": "show-info-notice"
};