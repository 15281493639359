import { typeIs, isset } from 'utils';

const eleChangeConfig = require('./eleChangeConfig');

export function handleBiTableChange(rowIndex, col, value, resData = {}, context) {
  // input change
  // console.log(value, resData)
  const config = eleChangeConfig.typeCheckBox;
  if (config[col]) {
    const ele = config[col];
    const pickObj = context.getPick();
    if (pickObj === null) return;
    const { header } = pickObj.biPickOrder.props.rightList;
    const changedRightList = resData.list
      ? resData.list
      : (pickObj.biPickOrder ? pickObj.biPickOrder.getTableData('right') : []) || [];
    const listElem = changedRightList[rowIndex][ele.elem]; // checkbox元素
    const { display } = header[config[col].elem];
    let checked = true;
    const disabledList = (changedRightList[rowIndex].otherProps || {}).disable || [];
    if (disabledList.indexOf(config[col].elem) !== -1) {
      checked = false;
    }
    if (Number(value) && typeIs(listElem, 'object') && display === 'show') {
      // eslint-disable-next-line no-unused-expressions
      listElem.text ? '' : (listElem.text = ele.text);
    } else if (Number(value) && !typeIs(listElem, 'object') && display === 'show') {
      const evaluation = {
        text: ele.text,
        checked,
      };
      changedRightList[rowIndex][ele.elem] = evaluation;
    } else {
      changedRightList[rowIndex][ele.elem] = 0;
    }
    context.getPick().setRightTableData(changedRightList);
  }
}

export function handleLeftToRight(tab, changedList, header) {
  // install
  const newListTable = [];
  const config = eleChangeConfig.tabToTypeCheckBox[tab];
  if (typeIs(config, 'object')) {
    const configKeys = Object.keys(config);
    changedList.map(key => {
      const item = key;
      let checked = true;
      const disabledList = (key.otherProps || {}).disable || [];
      configKeys.forEach(configKey => {
        const configItem = config[configKey];
        if (disabledList.indexOf(configItem.elem) !== -1) {
          checked = false;
        }
        if (Number(key[configKey]) === 0 || isset(header, `${configItem.elem}.display`) !== 'show') {
          item[configItem.elem] = 0;
        }
        if (Number(key[configKey]) && !typeIs(key[configItem.elem], 'object') && key.finance_disp) {
          let disabled = false;
          let tips;
          const elec = key.finance_disp[configKey];
          // cash_status 收银
          // em_ac_ss 交帐
          // if (elec && (elec.cash_status > 10 || elec.em_ac_ss > 10)) {
          if (elec && elec.em_ac_ss > 10) {
            disabled = true;
            // elec.cash_status > 10 ? (tips = '已收银') : (tips = '已交帐')
            tips = '已交账';
          }
          item[configItem.elem] = Number(key[configItem.elem])
            ? { text: configItem.text, checked, disabled, tips }
            : { text: configItem.text, checked: false, disabled, tips };
          // eslint-disable-next-line no-unused-expressions
          disabled ? item.otherProps.disable.push(configItem.elem) : '';
        }
      });
      newListTable.push(item);
      return newListTable;
    });
    return newListTable;
  }
  return changedList;
}

// 签收人
export function signNameFn(ext) {
  // 有签收人
  const { item, cardForm } = ext;
  const newItem = Object.assign({}, item);
  if (cardForm.sign_name || !cardForm.cee_flag) {
    // 签收人同步
    newItem.sign_name = cardForm.sign_name;
    const newDisable =
      item.otherProps.disable && item.otherProps.disable.filter(disableItem => disableItem !== 'sign_name');
    newItem.otherProps.disable = [...[], ...newDisable];
    newItem.cee_flag = false;
  }
  if (cardForm.sign_id_num && !cardForm.cee_flag) {
    // 身份证同步
    newItem.sign_id_num = cardForm.sign_id_num;
    const newDisable =
      item.otherProps.disable && item.otherProps.disable.filter(disableItem => disableItem !== 'sign_id_num');
    newItem.otherProps.disable = [...[], ...newDisable];
    newItem.cee_flag = false;
  }
  if (cardForm.cee_flag) {
    newItem.sign_name = item.cee_name;
    newItem.sign_id_num = item.cee_id_num;
    newItem.cee_flag = 1;
    // newItem.cee_flag = cardForm.cee_flag
    newItem.otherProps = newItem.otherProps || {};
    const disable = newItem.otherProps.disable || [];
    disable.push('sign_name');
    newItem.otherProps.disable = [...[], ...disable];
  }
  return newItem;
}

// 身份证
export function signIdNumFn(ext) {
  const { item, cardForm } = ext;
  const newItem = Object.assign({}, item);
  if (cardForm.sign_id_num && !item.sign_id_num) {
    newItem.sign_id_num = cardForm.sign_id_num;
  }
  return newItem;
}

// 签收操作
// 默认右屏-同步顶部签收人&&同收货人&&身份证号
// 左侧进入右侧运单-同步顶部签收人&&同收货人&&身份证号
export function signDadaDeal(tab, ext) {
  if (tab === 'Sign') {
    // 签收
    const newItem = signNameFn(ext); // 如果有签收人，同步签收人信息
    // newItem = signIdNumFn({ ...ext, ...{ item: newItem } })  // 如果有身份证， 同步身份证， 如果用户自己输入，则不再同步
    return newItem;
  }
  return ext.item;
}
