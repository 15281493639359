export const allField = {
  bill_no: '单据号',
  build_time: '发生时间',
  com_id: '发生网点', // {id: '62371' short_name: 'cd1'}
  operator: '经办人',
  department: '部门',

  receipt_bill_no: '借款单号',
  batch_num: '批次号',
  project_id: '项目名称',
  customer_id: '客户名称',
  line_id: '车线名称',
  order_num: '运单号',
  truck_num: '车牌号',
  car_remain_amount: '车辆余额',
  real_pay_amount: '实付金额',
  pre_pay_amount: '预付金额',
  tip_amount: '手续费',
  pre_pay_fuel: '预付油卡',
  pay_total_amount: '应付运输费合计',
  driver_name: '司机姓名',
  driver_remain_amount: '司机余额',
  receipt_person: '收款人',
  receipt_person_bank: '收款人开户行',
  receipt_person_banknum: '收款人银行卡号',
  refund_amount: '还款金额',
  refund_status: '还款状态',
  employee_id: '员工姓名',
  employee_remain_amount: '员工余额',
  predict_refund_time: '预计还款日期',
  amount: '金额',
  loan_type: '借支类型',

  abstract: '摘要',
  handle_pay_amount: '应付金额',
  balance_flag: '金额标识',
  expense: '费用类别',
  belong_new: '费用归属',
  route: '线路',

  create_by: '制单人',
  remark: '备注',
  imgs: '图片',
  files: '附件',
  oil_card: '油卡号',
};

export const extraAttr = ['create_by', 'remark'];

// 车辆借款 Attr
export const attrHub = {
  loan_car: ['bill_no', 'build_time', 'com_id', 'operator', 'department'],
  repay_car: ['bill_no', 'build_time', 'com_id', 'operator', 'department'],
  loan_driver: ['bill_no', 'build_time', 'com_id', 'operator', 'department'],
  repay_driver: ['bill_no', 'build_time', 'com_id', 'operator', 'department'],
  loan_employee: ['bill_no', 'build_time', 'com_id', 'operator', 'department'],
  repay_employee: ['bill_no', 'build_time', 'com_id', 'operator', 'department'],
  income_pay: ['bill_no', 'build_time', 'route', 'com_id', 'operator', 'department'],
};
export const headerHub = {
  // 车辆借款
  loan_car: [
    'loan_type',
    'abstract',
    'oil_card',
    'project_id',
    'batch_num',
    'truck_num',
    'car_remain_amount',
    'car_total_balance',
    'real_pay_amount',
    'pre_pay_amount',
    'tip_amount',
    'pre_pay_fuel',
    'pay_total_amount',
    'driver_name',
    'receipt_person',
    'receipt_person_bank',
    'receipt_person_banknum',
    'refund_amount',
    'refund_status',
    'amount',
  ],
  // 车辆还款
  repay_car: [
    'loan_type',
    'abstract',
    'receipt_bill_no',
    'oil_card',
    'project_id',
    'truck_num',
    'car_remain_amount',
    'car_total_balance',
    'driver_name',
    'batch_num',
    'amount',
  ],
  // 司机借款
  loan_driver: [
    'loan_type',
    'abstract',
    'oil_card',
    'project_id',
    'batch_num',
    'truck_num',
    'real_pay_amount',
    'pre_pay_amount',
    'tip_amount',
    'pre_pay_fuel',
    'driver_name',
    'driver_remain_amount',
    'driver_total_balance',
    'pay_total_amount',
    'receipt_person',
    'receipt_person_bank',
    'receipt_person_banknum',
    'refund_amount',
    'refund_status',
    'amount',
  ],
  // 司机还款
  repay_driver: [
    'loan_type',
    'abstract',
    'receipt_bill_no',
    'oil_card',
    'project_id',
    'truck_num',
    'driver_name',
    'driver_remain_amount',
    'driver_total_balance',
    'batch_num',
    'amount',
  ],
  // 员工借款
  loan_employee: [
    'abstract',
    'employee_id',
    'predict_refund_time',
    'employee_remain_amount',
    'employee_total_balance',
    'real_pay_amount',
    'pre_pay_amount',
    'tip_amount',
    'pre_pay_fuel',
    'receipt_person',
    'receipt_person_bank',
    'receipt_person_banknum',
    'refund_amount',
    'refund_status',
    'amount',
  ],
  // 员工还款
  repay_employee: [
    'abstract',
    'receipt_bill_no',
    'employee_id',
    'employee_remain_amount',
    'employee_total_balance',
    'amount',
  ],

  // 创建付款单
  income_pay: [
    'abstract',
    'order_num',
    'batch_num',
    'project_id',
    'customer_id',
    'line_id',
    'truck_num',
    'driver_name',
    'handle_pay_amount',
    'pre_pay_amount',
    'expense',
    'belong_new',
    'balance_flag',
    'receipt_person',
    'receipt_person_bank',
    'receipt_person_banknum',
    'amount',
  ],
};

export const appleOperation = {
  loan_car: 'loan_car_apply',
  repay_car: 'repayment_car_apply',
  loan_driver: 'loan_driver_apply',
  repay_driver: 'repayment_driver_apply',
  loan_employee: 'loan_user_apply',
  repay_employee: 'repayment_user_apply',
  income_pay: 'create_payment',
};

// 使用借支类型的单据
export const useloanTypeBillTypes = ['loan_car', 'repay_car', 'loan_driver', 'repay_driver'];

//  车辆、司机还款单
export const isCarAndDriverRepay = ['repay_car', 'repay_driver'];
