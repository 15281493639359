export const formatCell = ({
  columnKey,
  type,
  item,
  value,
  enumerations,
  headerCol = {},
  otherProps,
  classMap,
} = {}) => {
  const { refEnum } = headerCol;
  if (type === 'Text') {
    // TODO: 枚举的key-value 支持自定义键名
    const option = refEnum ? enumerations?.[refEnum]?.find(element => element.key === value) : null;
    const realData = option ? option.value : value;
    const classname = option?.classname ?? classMap?.[columnKey]?.[realData];
    return { value: realData, classname };
  }
  return value;
};
