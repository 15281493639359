const tips = {
  is_zzd:
    '1、母单的实际路由会按照“主子单”的实际路由来生成。2、当拆单分摊方式设置为“指定运单全部分摊”时，会分摊到“主子单”上。',
};

const splitConfigV2 = prop => {
  const { orderDict } = prop;
  const config = {
    // 拆单表格的属性
    splitTableAttr: {
      isShowTotalRow: false,
      isShowFilterRow: false,
      isShowFilterRowIcon: false,
    },
    maxSplitTime: 20,
    maxSplitTimeCh: '二十',
    // 分摊方式
    aveType: [
      { key: '1', name: '按件数分摊', mapKey: 'num' },
      { key: '2', name: '按重量分摊', mapKey: 'weight' },
      { key: '3', name: '按体积分摊', mapKey: 'volume' },
      { key: '4', name: '按分摊合计运费', mapKey: 'actual_price' },
    ],
    // 默认分摊方式
    aveDefault: '1',
    // 拆单方式
    splitType: [
      { key: 'g_num', value: 1, label: '按件数' },
      { key: 'g_weight', value: 2, label: '按重量' },
      { key: 'g_volume', value: 3, label: '按体积' },
      { key: 'customer', value: 4, label: '自定义' },
    ],
    mapCol: {
      1: ['num'],
      2: ['weight'],
      3: ['volume'],
      4: ['num', 'weight', 'volume', 'actual_price'],
    },
    // 默认拆单方式
    splitDefault: 1,
    // 顶部展示信息
    orderInfo: {
      g_name: '货物名称',
      g_num: '件数',
      g_weight: '重量',
      g_volume: '体积',
      g_actual_price: '运费',
    },
    splitHeader: {
      is_zzd: { title: '主子单', display: 'show', type: 'CheckBox', width: 60, tips: tips.is_zzd },
      order_num_sub: { title: '子单号', display: 'show', type: 'Text', flexGrow: 1 },
      num: {
        title: '件数',
        display: 'show',
        type: 'Text',
        split_type: 1,
        width: 60,
        validate: ['number'],
        format: { zero2null: false },
      },
      weight: {
        title: '重量',
        display: 'show',
        type: 'Text',
        split_type: 2,
        width: 60,
        validate: ['positiveFloatThree'],
        format: { zero2null: false },
      },
      volume: {
        title: '体积',
        display: 'show',
        type: 'Text',
        split_type: 3,
        width: 60,
        validate: ['handlePositiveFloatFour'],
        format: { zero2null: false },
      },
      actual_price: {
        title: '分摊合计运费',
        display: 'show',
        type: 'Text',
        split_type: 4,
        width: 90,
        validate: ['float'],
        format: { zero2null: false },
      },
    },
    splitHeaderUnSign: {
      car_batch: { title: `${orderDict}号`, display: 'show', flexGrow: 2 },
      batch_st: { title: `${orderDict}状态`, display: 'show', width: 60 },
    },
    splitHeaderPercent: {
      // percentType: { title: '分摊到此单', display: 'show', type: 'CheckBox', flexGrow: 1 }
    },
  };
  return config;
};
export default splitConfigV2;
