exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".capital-flow-footer .fn-btn,.capital-flow-footer .fn-btn-icon{margin:0 4px}", ""]);

// exports
exports.locals = {
	"prefixCls": "capital-flow-footer",
	"capital-flow-footer": "capital-flow-footer",
	"fn-btn": "fn-btn",
	"fn-btn-icon": "fn-btn-icon"
};