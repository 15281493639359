import React from 'react';
import PropTypes from 'prop-types';
import { ModalDialog, Flex, Button, PopUp, PureInput, TextArea, Icon } from 'components';
import { fetchApi } from 'utils';
import { handleErr } from 'utils/business/account/delayApply';
import { regulars } from 'constants';
import { prefixCls } from './index.scss';
import successTip from '../successTip';

/**
 * 延期申请弹窗
 */
export default class DelayApply extends React.Component {
  static propTypes = {
    telephone: PropTypes.string,
    name: PropTypes.string,
  };
  constructor(props) {
    super(props);
    this.state = {
      codeBtn: -1,
      loading: false,
      remark: '请销售人员联系我司，协商延期和续费有关事宜。',
      phone: props.telephone,
      err: {
        code: '',
      },
    };
    const tel = props.telephone.toString();
    this.tel = `${tel.substr(0, 4)}****${tel.substr(8, 4)}`;
  }
  onChange = key => e => {
    const val = e && e.target ? e.target.value : e;
    this.setState({
      [key]: val,
      err: {},
    });
  };
  getCode = async () => {
    console.log('获取验证码');
    // 获取验证码
    const codeRes = await fetchApi('Basic/Org/getDelayVerifyCode', {
      method: 'POST',
      body: {
        req: { user_id: window.user_id, telephone: this.props.telephone },
      },
    });
    if (!handleErr(codeRes)) {
      return;
    }
    if (this.state.codeBtn === -1) {
      this.setState({
        codeBtn: 60,
      });
      this.codeTimer = setInterval(() => {
        let { codeBtn } = this.state;
        if (codeBtn > -1) {
          codeBtn -= 1;
        } else {
          clearInterval(this.codeTimer);
        }
        this.setState({
          codeBtn,
        });
      }, 1000);
    }
  };
  handleHide = () => {
    this.modal && this.modal.handleHide();
  };
  onApply = async () => {
    this.setState({
      loading: true,
    });
    const { remark, phone, code } = this.state;
    const applyRes = await fetchApi('Basic/Org/submitApply', {
      method: 'POST',
      body: {
        req: {
          user_id: window.user_id,
          telephone: phone,
          verifyCode: code,
          apply_content: remark,
          user_name: window.user_info.user_name,
        },
      },
    });
    this.setState({
      loading: false,
    });
    if (applyRes.errno === -131) {
      this.setState({
        err: {
          code: '请输入正确的验证码',
        },
      });
      return;
    }
    if (!handleErr(applyRes)) {
      return;
    }
    new PopUp(successTip).show();
    this.handleHide();
  };
  renderContent = () => (
    <Flex className={prefixCls} dir="column" align="stretch">
      <Flex className="title" justify="center">
        申请延期
      </Flex>
      <p className="desc">
        延期申请审核通过后，可延期试用<span className="delay-day">15天</span>。试用期内，只能使用系统部分功能！
        如需体验更稳定、更高效、更便捷、更强大的系统服务， 请您购买正式账号，详询当地市场人员或致电
        <span className="phone">400-611-5656</span>。
      </p>
      <Flex className="form" dir="column" align="stretch">
        <p className="tip-1">
          点击[发送验证码]，会将验证码发送到公司负责人{this.props.name}的手机号上
          <span className="phone">{this.tel}</span>
          ，请在5分钟内联系负责人获取验证码，完成申请
        </p>
        <Flex className="form-item code" align="center">
          <Icon iconType="icon-safe" />
          <PureInput placeholder="请输入验证码" value={this.state.code} onChange={this.onChange('code')} />
          <Button type="positive" disabled={this.state.codeBtn > 0} onClick={this.getCode}>
            {this.state.codeBtn > 0 ? `${this.state.codeBtn}s` : '发送验证码'}
          </Button>
        </Flex>
        {this.state.err.code && <span className="err">{this.state.err.code}</span>}
        <p className="tip-2">
          如负责人已更换手机号或因其他原因无法获取验证码，可联系当地市场人员或致电官方客服400-611-5656，咨询延期事宜
        </p>
        <Flex className="form-item tel" align="center">
          <Icon iconType="icon-iphone" />
          <label>联系电话：</label>
          <PureInput value={this.state.phone} onChange={this.onChange('phone')} pattren={regulars.MOBILE} />
        </Flex>
        <Flex className="form-item remark" align="stretch">
          <Icon iconType="icon-company" />
          <label>申请留言：</label>
          <TextArea maxLength="200" value={this.state.remark} onChange={this.onChange('remark')} />
        </Flex>
        <Flex className="bottom" align="stretch" justify="between">
          <Button
            classname="btn"
            type="positive"
            loading={this.state.loading}
            disabled={!this.state.code || !this.state.phone}
            onClick={this.onApply}
          >
            确定
          </Button>
          <Button classname="cancel" type="positive-r" onClick={this.handleHide}>
            取消
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );

  render() {
    return (
      <ModalDialog
        content={this.renderContent()}
        ref={ref => (this.modal = ref)}
        closable
        maskClosable={false}
        style={{ width: 478 }}
        handleHideCallback={this.handleHide}
        isShow
        {...this.props}
      />
    );
  }
}
