import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Table, ButtonIcon, ResizeItemWidth, PopUp, PopTip } from 'components';
import { CHECK, ERROR, WIN_MIN_WIDTH } from 'constants';
import { bem, hasPermission, EventListener, fetchApi, fetchJAVA, viewImage, createTip } from 'utils';
import { uploadReceiptImg } from 'operation/listOperation/batchReceiptOp';
import { ImageTag } from 'components/commoncomponents/listOperation/truckOp/batchTaskAdd';
import { prefixCls } from './index.scss';

const cls = bem(prefixCls);
// 上传电子回单权限
const UploadReceiptImg = 'batchUploadReceiptImg';
@ResizeItemWidth
export default class ReceiptLog extends PureComponent {
  static propTypes = {
    from: PropTypes.string,
    comId: PropTypes.string,
    basicId: PropTypes.string,
    bLinkId: PropTypes.string,
    transtaskFlag: PropTypes.string,
    classname: PropTypes.string,
  };
  static defaultProps = {
    classname: 'receipt-log',
  };
  static contextTypes = {
    hanldeResizeTableWidth: PropTypes.func,
  };

  constructor(prop) {
    super(prop);
    this.state = {
      receiptInfo: {},
    };
    this.handleRefreshData = this.getData;
  }

  /**
   * 组件接受新的props的时候调用
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setTableWidthHeight();
  }

  componentWillUnmount() {
    if (this.eventListenerResize) {
      this.eventListenerResize.remove();
    }
  }

  componentDidMount() {
    this.setTableWidthHeight();
    this.eventListenerResize = EventListener.listen(window, 'resize', () => {
      this.setTableWidthHeight();
    });
  }

  UNSAFE_componentWillMount() {
    this.getData();
  }

  getData = async () => {
    const { basicId } = this.props;
    /*
    const req = {
      od_link_id: '86097576',
      od_basic_id: 86096324,
      type: 'receipt_log'
    }
    */
    const req = {
      tab: 'batch_receipt_log',
      category: 'Log',
      query: {
        b_basic_id: [basicId],
      },
    };
    const res = await fetchApi('/Table/Search/logList', { method: 'POST', body: { req } });
    if (res.errno !== 0) {
      createTip(res.errmsg, ERROR).show();
      return false;
    }
    const _res = res.res || {};
    const info = {};
    if (_res.table) {
      info.header = _res.table.header;
      info.dataList = _res.table.data;
      info.enumerations = _res.table.enumerations;
    }
    this.setState({ receiptInfo: { ...info } });
  };

  cellContentGetter = (props, cellProps, cellOtherProps) => {
    // props 包含后面三个参数 ||  getOriObjectAt == getObjectAt == oriRowObjGetter
    const dataItem = cellOtherProps.data.getObjectAt(cellProps.rowIndex);
    const { receipt_send_img, trace_info } = dataItem;
    if (!receipt_send_img) {
      return <div className={cls('operate-txt')}> {trace_info} </div>;
    }

    const imgListElem = receipt_send_img.map((item, index) => (
      <a className={cls('pic-list-item')} key={item.name}>
        <span onClick={() => this.viewImageFunc(dataItem, item, receipt_send_img)}>[{index + 1}]</span>
      </a>
    ));

    return (
      <div className={cls('operate-trace-info')}>
        <div className={cls('operate-txt')}> {trace_info} </div>
        {imgListElem}
      </div>
    );
  };

  cellCustomizeEvent = (...rest) => {
    console.log(' ==== cellCustomizeEvent === ', rest);
  };

  hanldeResizeTableWidth = header => {
    const tableWidth = this.tableWrap ? this.tableWrap.offsetWidth : WIN_MIN_WIDTH;
    return this.context.hanldeResizeTableWidth(tableWidth, header);
  };
  setTableWidthHeight = () => {
    const tableWidth = this.tableWrap ? this.tableWrap.offsetWidth : WIN_MIN_WIDTH;
    let tableHeigh = this.tableWrap ? this.tableWrap.offsetHeight : 100;
    if (this.tableBtn) tableHeigh -= this.tableBtn.offsetHeight;
    if (this.state.tableHeigh !== tableHeigh || this.state.tableWidth !== tableWidth) {
      this.setState({ tableWidth, tableHeigh });
    }
  };
  syncFilter = ({ filter }) => (this._filter = filter);
  handleTable = () => {
    const { receiptInfo = {} } = this.state;
    const { header = {}, opation = {}, dataList = [], enumerations = {} } = receiptInfo;

    if (header.operation) {
      header.operation.type = 'Customize';
    }

    return (
      <Table
        canSaveTableStyle={false}
        canFreeClumn={false}
        refGetter={r => (this.receiptLogTable = r)}
        header={this.hanldeResizeTableWidth(header)}
        // header={header}
        data={dataList || []}
        opation={opation} // 操作
        enumerations={enumerations}
        isShowTotalRow={false}
        rowHeight={33}
        headerHeight={35}
        footerHeight={26}
        handleLinkClick={this.handleLinkClick}
        isShowFilterRow
        isRowSelect={false}
        sortIner
        isShowSort
        filterIner
        isPopCopyOn
        syncFilter={this.syncFilter}
        // adaptiveCols={['operation']}
        cellContentGetter={this.cellContentGetter}
        handleCustomizeEvent={this.cellCustomizeEvent}
      />
    );
  };
  delPic = async imgInfo => {
    const { log_id, name, path, type = 'receipt' } = imgInfo;
    const req = {
      log_id,
      receipt_imgs: [
        {
          name,
          path,
          type,
        },
      ],
    };

    const res = await fetchJAVA('/cmm-batch/batch/delReceiptImg', { method: 'POST', body: { req } });
    if (res.errno !== 0) {
      res.errmsg && createTip(res.errmsg, ERROR, null, 2000, null, true).show();
      return;
    }
    new PopUp(PopTip, {
      type: CHECK,
      content: '回单删除成功！',
      isModal: true,
    }).show();
    return true;
  };

  delCusPic = async (req, _d) => {
    const res = await fetchJAVA('/cmm-batch/log/imgs/remove', { method: 'POST', body: { req } });
    if (res.errno !== 0) {
      res.errmsg && createTip(res.errmsg, ERROR).show();
      return;
    }
    const _self = this;
    new PopUp(PopTip, {
      type: CHECK,
      content: '回单删除成功！',
      onClose: () => {
        _d.modal.handleHide();
        _self.getData();
      },
    }).show();
  };

  viewImageFunc = (rowData, imgInfo, images) => {
    const reqParam = {
      log_id: rowData.id,
      images: [imgInfo],
    };
    const props = {
      type: 'receipt_send',
      path: imgInfo.path,
      name: imgInfo.name,
      deleteReq: reqParam,
      showDeleteButton: true,
      extraDel: (param, _d) => this.delCusPic(param, _d),
    };
    // TODO: add del btn
    viewImage(props /* images */);
  };

  handleLinkClick = (newRowIndex, column, data, item) => {
    if (item.link_status === 'text') return;
    const imgTagMap = {
      1: '装货回单',
      2: '卸货回单',
    };
    let divRef = null;
    const options = {
      withDelete: hasPermission(UploadReceiptImg),
      customDeleteFun: this.delPic,
      onDeleteSucceed: () => this.getData?.(),
      galleryProps: {
        onSlide: (i, list) => {
          const { receipt_type } = list?.[i] || imgList?.[i] || {};
          const tag = imgTagMap[receipt_type];
          divRef?.handleChangeValue(tag);
        },
      },
      renderTopSlot: () => <ImageTag tag={imgTagMap[item?.receipt_type]} ref={ref => (divRef = ref)} />,
    };
    const imgList = (data.state_follow_img || [])
      .filter(img => img.link_status !== 'text')
      .map(img => ({ ...img, log_id: data.id }));
    viewImage(item, imgList, options);
  };

  popLoad = () => {
    console.log('popLoad', this.props);
    const { from, comId, basicId, bLinkId, transtaskFlag, receiptAuditSt } = this.props;
    const info =
      from === 'task'
        ? {
            b_basic_id: basicId,
            company_id: comId,
            transtask_flag: transtaskFlag,
            receipt_audit_st: receiptAuditSt,
          }
        : { b_link_id: bLinkId, transtask_flag: transtaskFlag, receipt_audit_st: receiptAuditSt };
    uploadReceiptImg('detail', [info], this);
  };

  render() {
    return (
      <div className={cls()} ref={r => (this.tableWrap = r)}>
        <div className={cls('list-wrap')}>{this.handleTable()}</div>
        <div className={cls('footer')}>
          <ButtonIcon iconType="icon-upload" onClick={this.popLoad}>
            上传电子回单
          </ButtonIcon>
        </div>
      </div>
    );
  }
}
