import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import { SelectDrop, Tree, Icon } from 'components';
import { isEmptyObj, emptyFunction, typeIs } from 'utils';
import { prefixCls } from './index.scss';

export default class LeftSearchTree extends Component {
  constructor(props) {
    super(props);
    // 构造器 初始化state 初始化绑定事件
    const { treeData } = props;
    const { uniqueKey } = treeData;
    const { showKey } = treeData;
    const rootKey = treeData.data ? Object.keys(treeData.data)[0] : null;
    const expandedKeysRoot =
      rootKey && treeData.data[rootKey][uniqueKey] ? [treeData.data[rootKey][uniqueKey].toString()] : [];
    const expandedKeys = 'expandedKeys' in props ? props.expandedKeys : expandedKeysRoot;
    // const expandedKeys = (treeData.data.length > 0 && treeData.data[0][uniqueKey]) ? [treeData.data[0][uniqueKey]] : []
    const SugData = this.getSearchSugData(treeData); // 树节点数组全集
    const treeDataArr = SugData.data || [];
    const sugTableHeader = SugData.sugTableHeader || [];
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      value: '', // this.props.defaultSearch,
      expandedKeys,
      selectedKeys: this.props.selectedKeys || expandedKeys,
      checkedKeys: props.checkedKeys || [],
      autoExpandParent: typeIs(props.autoExpandParent, 'undefinded') ? true : props.autoExpandParent,
      treeDataArr,
      sugTableHeader,
      uniqueKey,
      // eslint-disable-next-line react/no-unused-state
      showKey,
    };
  }

  static propTypes = {
    treeData: PropTypes.object, // 树形数据
    showTreeIcon: PropTypes.bool, // 是否显示树节点图标
    searchIcon: PropTypes.string, // 搜索框图标 默认
    classname: PropTypes.string, // 样式
    toggleIconHandle: PropTypes.func, // 收起图标动作
    onNodeSelected: PropTypes.func, // 树节点点击事件
    getSearchSugData: PropTypes.func, // 搜索sug生成函数 参数 treeData
    nodeNameFormat: PropTypes.func,
    disableParent: PropTypes.bool, // 父节点禁用（点击、选中等）
    checkable: PropTypes.bool, // 是否可勾选
    onCheck: PropTypes.func, // 勾选事件
    selectable: PropTypes.bool,
    checkedKeys: PropTypes.array,
    selectedKeys: PropTypes.array,
    defaultExpandAll: PropTypes.bool,
    autoExpandParent: PropTypes.bool,
    multiple: PropTypes.bool,
    autoExpandNode: PropTypes.bool,
    menuClassName: PropTypes.string,
  };
  static defaultProps = {
    toggleIconHandle: emptyFunction,
    treeData: {
      showKey: 'title',
      uniqueKey: 'id',
      data: [],
    },
    searchIcon: 'icon-search',
    classname: '',
    checkable: false,
    selectable: true,
    defaultExpandAll: true,
    multiple: false,
    autoExpandNode: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { treeData } = nextProps;
    if (treeData !== this.props.treeData) {
      this.setState({ checkedKeys: nextProps.checkedKeys });
      if (Array.isArray(nextProps.selectedKeys) && nextProps.selectedKeys.length) {
        this.setState({ selectedKeys: nextProps.selectedKeys });
      }
      const SugData = this.getSearchSugData(treeData); // 树节点数组全集
      const treeDataArr = SugData.data || [];
      const { uniqueKey } = treeData;
      const rootKey = treeData.data ? Object.keys(treeData.data)[0] : null;
      let expandedKeys = [];
      if (this.props.autoExpandNode) {
        expandedKeys.push(
          rootKey && treeData.data[rootKey][uniqueKey] ? [treeData.data[rootKey][uniqueKey].toString()] : [],
        );
      } else {
        expandedKeys = [...(this.state.expandedKeys || [])];
      }
      if (nextProps.expandedKeys !== this.props.expandedKeys && typeIs(nextProps.expandedKeys, 'array')) {
        expandedKeys = expandedKeys.concat(nextProps.expandedKeys);
      }
      expandedKeys = [...new Set(expandedKeys)];
      this.setState({ treeDataArr, expandedKeys });
    }
  }

  getTreeDataList = () => this.state.treeDataArr;
  clearCheckedKeys = () => this.setState({ checkedKeys: [] });

  render() {
    const { classname, searchIcon, treeData = {}, showTreeIcon, checkable } = this.props;
    const classes = classnames(`${prefixCls}`, classname);
    const { uniqueKey, showKey } = treeData;
    const sugTableHeader = this.state.sugTableHeader || {};
    return isEmptyObj(treeData) ? null : (
      <div className={classes}>
        <div className={`${prefixCls}__search`}>
          <SelectDrop
            isKeyboard
            isIconTrans={false}
            handleSelected={this.onSearchDropSelected}
            inputIconType={searchIcon}
            data={this.state.treeDataArr}
            showKey={showKey}
            uniqueKey={uniqueKey}
            tableHeader={sugTableHeader}
            shouldFilter
            autoColWidth
            isMultiple={false}
            placeholder="搜索"
            showHeader={!!Object.keys(sugTableHeader).length}
            menuClassName={this.props.menuClassName}
          />
          <Icon iconType="icon-extend-left" onClick={this.toggleIconHandle} />
        </div>
        <div className={`${prefixCls}__tree`}>
          <Tree
            defaultExpandAll={this.props.defaultExpandAll}
            data={treeData}
            showIcon={showTreeIcon}
            showLine
            checkable={checkable}
            ref={t => (this._Tree = t)}
            addNodeProps={item => this.getAddNodeProps(item)}
            onExpand={this.onExpand}
            expandedKeys={this.state.expandedKeys}
            autoExpandParent={this.state.autoExpandParent}
            onSelect={this.onTreddNodeSelect}
            selectedKeys={this.state.selectedKeys}
            onCheck={this.onCheck}
            checkedKeys={this.state.checkedKeys}
            nodeNameFormat={this.props.nodeNameFormat}
          />
        </div>
      </div>
    );
  }

  getAddNodeProps = node => {
    if (node.children && Object.entries(node.children).length > 0) {
      return this.props.disableParent ? { disabled: true } : {};
    }
    return {};
  };
  // 左右拖拽 左侧收起按钮
  toggleIconHandle = () => {
    this.props.toggleIconHandle();
  };
  // 搜索框 下拉选中
  onSearchDropSelected = item => {
    if (!item) return;
    const expandedKeys = `${item[this.state.uniqueKey]}`;
    const selectedKeys = [`${item[this.state.uniqueKey]}`];
    const treeData = this.state.treeDataArr;
    const selectData = treeData.find(x => `${x[this.state.uniqueKey]}` === expandedKeys) || {};
    const expandedParentKeys = selectData.parentKeys || [];
    this.setState({ expandedKeys: [expandedKeys, ...expandedParentKeys], selectedKeys, autoExpandParent: true });
    this.onTreddNodeSelect(selectedKeys, this._Tree.getCurrSelectedNode());
  };
  // tree - 点选节点事件
  onTreddNodeSelect = (selectedKeys, info) => {
    if (!this.props.multiple && Array.isArray(selectedKeys) && !selectedKeys.length) return;
    this.setState({ selectedKeys });
    const node = document.getElementById(selectedKeys[0]);
    node && node.scrollIntoView(false);
    this.props.onNodeSelected && this.props.onNodeSelected(selectedKeys, info);
  };
  onExpand = expandedKeys => {
    //  autoExpandParent为false时，才能展开对应的节点
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };
  onCheck = (checkedKeys, ...rest) => {
    this.setState({
      checkedKeys,
    });
    this.props.onCheck && this.props.onCheck(checkedKeys, ...rest);
  };
  // 搜索框sug header list 数据
  getSearchSugData = treeData => {
    let res = [];
    if (this.props.getSearchSugData) {
      res = this.props.getSearchSugData(treeData);
    } else {
      // 默认值
      res = this.getLeftSearchSugData(treeData);
    }
    return res;
  };
  getLeftSearchSugData = treeData => {
    const data = [];
    const tree = treeData.data;
    const { showKey } = treeData;
    const { uniqueKey } = treeData;
    const sugTableHeader = {
      title: '名称',
      uper: '所属',
    };
    if (tree) {
      const loopData = (idata, arr, iuper, ishowKey, iuniqueKey, parentKeys = []) => {
        for (const [, item] of Object.entries(idata)) {
          const currentParentKeys = [...parentKeys, `${item[uniqueKey]}`];
          if (item.children && Object.entries(item.children).length > 0) {
            loopData(item.children, arr, item[showKey], ishowKey, iuniqueKey, currentParentKeys);
          }
          const uper = iuper || item[ishowKey];
          arr.push({ [showKey]: item[showKey], [uniqueKey]: item[uniqueKey], uper, parentKeys });
        }
      };
      loopData(tree, data, null, showKey, uniqueKey);
    }
    return { data, sugTableHeader };
  };
}
