import React, { Component } from 'react';
import { SlideDrager, Flex, Button, Load } from 'components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { fetchApi, showInfo, createTip, typeIs, validateFieldsOf, hasApproval, confirmWithReasonSync } from 'utils';
import { ERROR, WARN, CHECK } from 'constants';
import { approvalProcess } from 'components/commoncomponents/listOperation/approvalOp';
import moment from 'moment';
import OrderInfo from './orderInfo';
import BaseInfo from './baseInfo';
import PayInfo from './payInfo';
import { typeListJosn, addType } from './payInfo/config';
import HandleInfo from './handleInfo';
import Log from './log';
import { prefixCls } from './index.scss';
import { withPopUpI18n } from '@/utils/i18n/context';

class Abnormal extends Component {
  static propTypes = {
    // 异常id
    id: PropTypes.string,
    // 操作类型
    type: PropTypes.oneOf(['add', 'update', 'view', 'audit', 'viewLog', 'viewAuditLog']),
    // 运单号
    orderNum: PropTypes.string,
    odLinkId: PropTypes.string,
    i18n: PropTypes.object,
  };
  constructor(props) {
    super(props);
    const defaultAbnType = window.localStorage.getItem('abnormType');
    this.state = {
      orderInfo: {},
      baseInfo: {},
      auditInfos: [],
      enums: {},
      required: {},
      logInfo: {},
      tips: {
        abn_expense: '费用类别在“公司管理-费用管理”里设置',
      },
      disabled: {},
      // 审核、处理、回复 时表单数据
      handleInfo: {},
      count: 0,
      loading: true,
      saving: false,
      abnormType: defaultAbnType || '1',
    };
    this.handleInfoEnum = {
      company_id: [{ ...window.company_info }],
      user_id: [{ ...window.window.user_info }],
    };
    this.isHandle = ['audit', 'reply', 'deal'].includes(props.type);
  }
  UNSAFE_componentWillMount() {
    this.getData(this.props);
  }
  componentDidMount() {
    const { selectIds = {}, categoryNum } = this.props;
    if (selectIds.length === 1 && categoryNum === '1') {
      this.getOrderInfo(selectIds[0]);
    }
  }
  shouldComponentUpdate() {
    return true;
  }
  approvalLogFormater = data => {
    const { next_title: title = [], next_user_list: user = {}, audit_or: auditOr } = data;
    return title.map((item, index) => ({
      id: item,
      operation: `待审批：${Object.values(user[index] || {})
        .map(i => i.name)
        .join(auditOr ? '/' : '，')}`,
      type_show: item,
    }));
  };
  getData = props => {
    const param = { op_type: props.type, category: this.props.categoryNum || '1' };
    props.type !== 'add' && (param.abnormal_id = props.id);
    fetchApi('Order/Abnormal/abnormalInfo', {
      method: 'POST',
      body: {
        req: param,
      },
    }).then(async res => {
      if (+res.errno === 0) {
        const response = res.res || {};
        let { order_info: orderInfo = {}, abn_log: logInfo = [] } = response;

        const {
          abnormal_info: baseInfo = {},
          deal_infos: dealInfos = [],
          audit_infos: auditInfos = [],
          enum: enumss = {},
          appoint,
          line_settle: lineSettle,
          bms_check: pointIsOpenApproval,
          is_auditor: isAuditor,
          setting,
        } = response;
        let isOpenApproval = props.isApproval; // 有的数据可能otherProps.operate不存在audit_log，判断为非审批流，凡是接口又返回bms_check：true,互相矛盾，现改为根据audit_log判断
        if (typeof isOpenApproval === 'undefined' || props.type === 'add' || props.type === 'view') {
          // 取接口返回的
          isOpenApproval = pointIsOpenApproval;
        }
        const { type } = this.props;
        if (baseInfo.category !== '1' && !this.props.hiddenOrderInfo) {
          orderInfo = response.batch_info || {};
        }
        const abnormalTypeEnums = enumss.abn_type || {}; // 异常类型原始枚举
        const enums = {};
        const allTypes = enumss.duty_pay || {};
        Object.entries(enumss).forEach(([key, value]) => {
          if (typeIs(value, 'array')) {
            enums[key] = value;
          } else if (key === 'abn_expense') {
            enums[key] = Object.entries(value).map(([k, v]) => ({
              fee_name: v,
            }));
          } else if (key === 'assign_permission') {
            enums[key] = Object.entries(value).map(([k, v]) => ({
              role_id: k,
              ...v,
            }));
          } else {
            enums[key] = Object.entries(value).map(([k, v]) => ({
              key: k,
              name: v.display,
              id: k,
              display: v.display,
              short_name: v.display,
              category: v.category,
            }));
          }
        });
        const orderDict = this.props.i18n.get('order', '运单');
        const waybillDict = this.props.i18n.get('batch', '任务');
        const batchDict = this.props.i18n.get('batch');
        const addTypeList = [{ type: `${orderDict}号` }];
        if (enums.can_add_abn) {
          enums.can_add_abn.forEach(i => {
            i.display === '批次异常' && addTypeList.push({ type: `${batchDict}批次号` });
            i.display === '任务异常' && addTypeList.push({ type: `${waybillDict}号` });
          });
        }
        const allowMulPay = setting.allow_mul_pay && setting.allow_mul_pay.checked;
        const dutyTypes = setting.duty_types ? setting.duty_types.select : [];
        const payeeTypes = setting.payee_types ? setting.payee_types.select : [];
        enums.duty_type = dutyTypes.map(item => ({ id: item, name: allTypes[item] && allTypes[item].display }));
        enums.payee_type = payeeTypes.map(item => ({ id: item, name: allTypes[item] && allTypes[item].display }));
        const required = {
          type: true,
          duty_company_id: +baseInfo.abnormal_price,
          payee_company_id: +baseInfo.abnormal_price,
          assign_company_id: true,
          assign_permission: true,
          rmk: true,
        };
        const { disabled } = this.state;
        disabled.add_imgs = type.includes('view');
        let payInfo = baseInfo.duty_pay_info || [];
        // todo 司机、承运商
        if (payInfo.length) {
          const { duty_company_id: dce, payee_company_id: pce } = enums;
          const getCom = (id, e) => e.find(item => +item.id === +id) || {};

          payInfo = payInfo.map(item => {
            const { duty_type: dt, payee_type: pt, duty, payee } = item;
            const dutyCom = item.duty_type === 'point' ? getCom(duty, dce) : {};
            const payeeCom = item.payee_type === 'point' ? getCom(payee, pce) : {};
            const iitem = {
              ...item,
              duty_cor: orderInfo.cor_id,
              duty_cee: orderInfo.cee_id,
              payee_cor: orderInfo.cor_id,
              payee_cee: orderInfo.cee_id,
              duty_other: dt === 'other' ? item.duty : '',
              payee_other: pt === 'other' ? item.payee : '',
              payee_carrier: pt === 'carrier' ? item.payee : '',
              duty_carrier: dt === 'carrier' ? item.duty : '',
              payee_driver: pt === 'driver' ? item.payee : '',
              duty_driver: dt === 'driver' ? item.duty : '',
              duty_point: dutyCom,
              payee_point: payeeCom,
              duty_point_type: dutyCom.type,
              payee_point_type: payeeCom.type,
            };
            return iitem;
          });
        }
        delete baseInfo.duty_pay_info;
        const logCurIndex = logInfo.findIndex(i => i.approval_next_log) - 1;
        logInfo = {
          curIndex: logCurIndex < 0 ? logInfo.length - 1 : logCurIndex,
          info: logInfo,
        };
        // 11390 -暂时注释
        // if (baseInfo.add_imgs && baseInfo.add_imgs.length) {
        //   const imgs = {};
        //   baseInfo.add_imgs.forEach(item => {
        //     if (item && item.name) {
        //       imgs[item.name] = item;
        //     }
        //   });
        //   baseInfo.add_imgs = imgs;
        // }
        let state = {
          ...this.state,
          disabled,
          orderInfo,
          baseInfo,
          payInfo,
          dealInfos,
          auditInfos,
          logInfo,
          enums,
          abnormalTypeEnums,
          addTypeList,
          required,
          appoint, // 是否可以指定处理网点
          lineSettle, // 是否开启了线路扣款
          isOpenApproval, // 是否开启审批流
          baseInfoDisabled: !['add', 'update'].includes(type), // 开通审批流之后
          payInfoDisabled: !(
            (type === 'audit' && isOpenApproval && isAuditor) ||
            ['add', 'update', 'deal'].includes(type)
          ),
          handleInfo: this.isHandle && {
            time: moment().format('YYYY-MM-DD HH:mm:ss'),
            company_id: window.company_id,
            user_id: window.user_id,
            rmk: '',
          },
          initedData: true,
          loading: false,
          allowMulPay,
          abnormType: baseInfo.category || this.props.categoryNum,
        };
        state = this.orderInfoChange('', state, true);
        this.setState(state);
        if (this.props.odLinkId) {
          await this.getOrderInfo(this.props.odLinkId);
        }
      } else {
        showInfo(ERROR, res.errmsg || '网络错误');
        setTimeout(() => {
          this._slideDrager.handleHide();
        }, 0);
        return;
      }
      if (this.isHandle) {
        setTimeout(() => {
          const rmkRef = this.handleRefInstance && this.handleRefInstance._textarea;
          rmkRef && rmkRef.scrollIntoView();
          rmkRef && rmkRef.focus();
        }, 200);
      }
      if (this.props.type === 'viewLog' || this.props.type === 'viewAuditLog') {
        setTimeout(() => {
          const logRef = this.logRectInstance;
          logRef && logRef.scrollIntoView(true);
        });
      }
    });
  };
  getDefaultDutyPayee = (enums, type) => {
    const theEnum = enums[type] || [];
    if (theEnum.length !== 1) {
      return '';
    }
    let theVal;
    switch (type) {
      case 'cor':
      case 'cee':
        theVal = theEnum[0].id;
        break;
      case 'driver':
        theVal = theEnum[0].name;
        break;
      case 'carrier':
        theVal = theEnum[0].id;
        break;
      default:
        break;
    }
    return theVal;
  };
  getDefaultPayInfo = (state = this.state) => {
    const { orderInfo, enums } = state;
    return {
      duty_type: 'point',
      duty_cor: this.getDefaultDutyPayee(enums, 'cor'),
      duty_cee: this.getDefaultDutyPayee(enums, 'cee'),
      duty_driver: this.getDefaultDutyPayee(enums, 'driver'),
      duty_carrier: this.getDefaultDutyPayee(enums, 'carrier'),
      payee_type: 'point',
      payee_cor: this.getDefaultDutyPayee(enums, 'cor'),
      payee_cee: this.getDefaultDutyPayee(enums, 'cee'),
      payee_driver: this.getDefaultDutyPayee(enums, 'driver'),
      payee_carrier: this.getDefaultDutyPayee(enums, 'carrier'),
      // 应app要求，需要默认穿个空字符串，否则app 会报错:(
      payee_downstream: '',
      duty_downstream: '',
    };
  };
  async getOrderInfo(odLinkId, batch = false) {
    this.setState({
      loading: true,
    });
    const reqKey = batch ? 'b_link_id' : 'od_link_id';
    const url = batch ? 'Order/Abnormal/getAbnormalBatch' : 'Order/Abnormal/getAbnormalOrder';
    const res = await fetchApi(url, {
      method: 'POST',
      body: {
        req: {
          [reqKey]: odLinkId,
        },
      },
    });
    if (res.errno !== 0) {
      showInfo(WARN, '未找到符合条件的运单').show();
      return;
    }
    this.setState({
      loading: false,
    });
    const orderInfo = res.res || {};
    this.set('orderInfo', 'od_link_id', orderInfo, 'getOrderInfo');
  }
  checkAbnType = type => {
    const { od_basic_id: id } = this.state.orderInfo;
    if (!this.checkTyping && id) {
      //   // alert(this.checkTyping)
      this.checkTyping = true;
      fetchApi('/Order/Abnormal/checkAbnType', {
        method: 'POST',
        body: { req: { od_basic_id: id, type } },
      }).then(res => {
        if (res.errno !== 0) {
          showInfo(WARN, '该运单已登记过相同类型的异常，您确定要再次登记吗？', true, {
            confirm: () => {
              this.baseInfoRef && this.baseInfoRef.quantityRef.focus();
            },
            cancel: () => {},
          });
        }
        this.checkTyping = false;
      });
    }
  };
  baseInfoChange = (key, val, state) => {
    const _state = state;
    _state.baseInfo[key] = val;
    switch (key) {
      case 'type':
        if (val && val.key) {
          this.checkAbnType(val.key);
        }
        break;
      default:
        break;
    }
    if (key === 'assign_company_id') {
      _state.enums.assign_permission = [];
      _state.baseInfo.assign_permission = [];
    }
    return _state;
  };
  set = (c, k, val, from) => {
    const v = val && val.target ? val.target.value : val;
    let state = { ...this.state };
    const typeJson = { order_num: '1', car_batch: '3' };
    state[c] = _.cloneDeep(state[c]);
    switch (c) {
      case 'orderInfo':
        state.orderInfo = v;
        state = this.orderInfoChange(k, state);
        break;
      case 'baseInfo':
        state = this.baseInfoChange(k, v, state);
        break;
      case 'payInfo':
        state = this.payInfoChange(k, v, state);
        break;
      default:
        _.set(state[c], k, v);
        break;
    }
    if (this.props.type !== 'add' && c === 'payInfo') {
      this.edited = true;
    }
    this.setState(state);
  };
  payTypeChange = (k, val, state) => {
    const _state = state;
    const { orderInfo } = _state;
    const key = k[1];
    const pre = key.includes('payee') ? 'payee' : 'duty';
    const theRow = _state.payInfo[k[0]];
    const isSelectType = ['cor', 'cee', 'carrier', 'driver'].includes(val);
    const orderDisable = ['cor', 'cee'];
    const { oneData } = this.props;
    if (this.props.categoryNum !== '1' && val === 'driver') {
      const b_dr_name = orderInfo.b_dr_name || (oneData && oneData[0].b_dr_name);
      _state.enums.driver = [{ name: b_dr_name }];
      // this.setState({ state: Object.assign(this.state, _state) })
    }
    if (this.props.categoryNum === '1') {
      if (orderDisable.includes(val)) {
        const cName = orderInfo[`${val}_name`] || (oneData && oneData[0][`${val}_name`]);
        const cId = orderInfo[`${val}_id`] || (oneData && oneData[0][`${val}_id`]);
        _state.enums[val] = [{ name: cName, id: cId }];
      }
    }
    if (isSelectType) {
      theRow[`${pre}_${val}`] = this.getDefaultDutyPayee(_state.enums, val);
      // if (!theEnum.length) {
      //   const preTip = pre === 'payee' ? '收' : '付'
      //   const tip = payInfoTips[val] && payInfoTips[val](preTip)
      //   if (tip) {
      //     if (tip.tips) {
      //       confirmWithReasonSync(tip)
      //     } else {
      //       showInfo(WARN, tip, true, {}, false)
      //     }
      //     _.set(_state.payInfo, k, [])
      //   }
      // }
    }
    if (val !== 'point') {
      theRow[`${pre}_user_id`] = '';
    }
    return _state;
  };
  orderInfoChange = (key, state, isInit) => {
    const { type } = this.props;
    const _state = state;
    const { orderInfo, allowMulPay } = state;
    if (!orderInfo) return;
    _state.enums.cor = [{ id: orderInfo.cor_id, name: orderInfo.cor_name }];
    _state.enums.cee = [{ id: orderInfo.cee_id, name: orderInfo.cee_name }];
    _state.enums.carrier = orderInfo.carrier_info || [];
    _state.enums.driver = orderInfo.driver_info || [];
    const isEdited = isInit && type === 'update';
    if (!_state.payInfo.length || (isEdited && allowMulPay)) {
      _state.payInfo.push(this.getDefaultPayInfo(_state));
    }
    if (key === 'order_num' || key === 'car_batch') {
      _state.payInfo = [this.getDefaultPayInfo(_state)];
    }
    if (key === 'order_num' || key === 'receipt_num') {
      this.getOrderInfo(orderInfo.od_link_id);
    }
    return _state;
  };
  payInfoChange = (k, val, state) => {
    let _state = state;
    // 删除行
    if (k[1] === 'delete') {
      _state.payInfo.splice(k[0], 1);
      if (_state.payInfo.length === 0) {
        _state.payInfo = [this.getDefaultPayInfo(_state)];
      }
      return _state;
    }
    _.set(_state.payInfo, k, val);

    const { orderInfo, appoint, payInfo, enums, allowMulPay } = _state;
    const key = k[1];
    const theRow = _state.payInfo[k[0]];
    // 编辑一行之后自动新增行
    if (k[0] === payInfo.length - 1 && allowMulPay) {
      _state.payInfo.push(this.getDefaultPayInfo(_state));
    }
    switch (key) {
      // 付款网点
      case 'duty_point':
        theRow.duty_point_type = _.get(val, 'type', '');
        theRow.duty_downstream = '';
        theRow.duty_user_id = '';
        break;
      case 'payee_point':
        theRow.payee_downstream = '';
        theRow.payee_user_id = '';
        theRow.payee_point_type = _.get(val, 'type', '');
        break;
      case 'payee_driver':
      case 'duty_driver':
        theRow[key] = _.get(val, 'name', val);
        break;
      case 'duty_user_id':
        if (_.get(val, 'id') && theRow.duty_type === 'point') {
          const curVal = val;
          const comId = curVal.company_id;
          const comKey = 'duty_company_id';
          let comVal = enums[comKey].find(item => +item.id === +comId);
          comVal = comVal || '';
          return this.payInfoChange([k[0], comKey], comVal, state);
        }
        break;
      case 'payee_user_id':
        if (_.get(val, 'id') && theRow.payee_type === 'point') {
          const curVal = val;
          const comId = curVal.company_id;
          const comKey = 'payee_company_id';
          let comVal = enums[comKey].find(item => +item.id === +comId);
          comVal = comVal || '';
          return this.payInfoChange([k[0], comKey], comVal, state);
        }
        break;
      case 'payee_type':
      case 'duty_type':
        _state = this.payTypeChange(k, val, _state);
        break;
      case 'dp_price':
        _state.required.abn_expense = _state.payInfo.some(i => i.dp_price > 0);
        break;
      default:
        break;
    }
    return _state;
  };
  imgRefGetter = ref => {
    this.imgInstance = ref;
  };
  handleImgRefGetter = ref => {
    this.handleImgInstance = ref;
  };
  handleRmkRefGetter = ref => {
    this.handleRefInstance = ref;
  };
  logRefGetter = ref => {
    this.logRectInstance = ref;
  };
  checkPermission = (type = this.props.type) => {
    let thePermission;
    switch (type) {
      case 'add':
        thePermission = 'addAbnormal';
        break;
      case 'update':
        thePermission = 'updateAbnormal';
        break;
      case 'deal':
        thePermission = 'dealAbnormal';
        break;
      default:
        thePermission = type;
    }
    return thePermission && !hasApproval('abnormalApproval');
  };
  // 校验异常登记信息的必填项(补充时间组件DateTimePicker的required缺陷)
  validate = () => {
    const { baseInfo, abnormalTypeEnums } = this.state;
    const data = baseInfo;
    const abnormalExtendFields = window.company_setting.abn_fields || {}; // 获取异常一级分类的扩展字段
    const clieckedExtendFields = []; // 当前选中异常类型所在一级分类的扩展字段
    // data.type中没有id等值为改查操作，直接用data.type去匹配对应的一级分类code
    const updateExtendType =
      data.type && data.type.id
        ? abnormalTypeEnums?.[data.type.id]?.type_code?.[0].key
        : abnormalTypeEnums?.[data.type]?.type_code?.[0]?.key;
    const foundItem = abnormalExtendFields?.values?.find(item => item.code === updateExtendType);
    const foundItemRequire = foundItem?.child?.filter(item => item.require); // 用当前异常类型匹配到的一级分类code，找到一级分类对应的必填的对象
    if (foundItemRequire?.length > 0) {
      clieckedExtendFields.push(...foundItemRequire); // 将一级分类对象中的扩展字段child数组加入到dom渲染数组clieckedExtendFields中
    }
    if (clieckedExtendFields?.length > 0) {
      const requireData = clieckedExtendFields.filter(
        ({ code }) =>
          !(code in baseInfo) || baseInfo[code] === '' || baseInfo[code] === null || baseInfo[code] === undefined,
      );
      if (requireData.length > 0) {
        return false;
      }
    }
    return true;
  };

  save = async (customType, state) => {
    // 工单管理
    if (!(await validateFieldsOf(this.wrap))) {
      return;
    }
    console.log(customType, state, 'oooooooooo');
    const _state = state || this.state;
    let { payInfo } = _state;
    const { orderInfo, baseInfo, allowMulPay, addTypeList } = _state;

    const { od_link_id: odLinkId, od_basic_id: odBasicId, b_link_id: bLinId } = orderInfo;
    const _baseInfo = {};
    Object.entries(baseInfo).forEach(([key, value]) => {
      _baseInfo[key] = value && value.id ? value.id : value;
    });
    if (!this.validate(baseInfo)) {
      showInfo(ERROR, '请完善异常信息必填项！');
      return;
    }
    if (!baseInfo.rmk || !baseInfo.rmk.trim?.()) {
      showInfo(ERROR, '请输入异常描述');
      return false;
    }
    const _payInfo = [];
    let abnormalPrice = 0;
    const notCompleteRows = [];
    const requireds = {};
    const tipStr = [];
    for (let i = 0; i < payInfo.length; i++) {
      const item = payInfo[i];
      const dutyType = !_.isEqual(item.duty_type, []) ? item.duty_type : '';
      const payeeType = !_.isEqual(item.payee_type, []) ? item.payee_type : '';
      let duty = item[`duty_${dutyType}`];
      let payee = item[`payee_${payeeType}`];
      const dpPrice = +(+item.dp_price || 0).toFixed(2);
      const dutyUserId = item.duty_user_id ? item.duty_user_id.id || item.duty_user_id : '';
      const payeeUserId = item.payee_user_id ? item.payee_user_id.id || item.payee_user_id : '';
      const dutyPointTYpe = item.duty_point_type;
      const payeePointTYpe = item.payee_point_type;
      duty = _.isEmpty(`${duty}`) ? '' : duty;
      payee = _.isEmpty(`${payee}`) ? '' : payee;
      let dutyNoCheck = false;
      let payeeNoCheck = false;
      const dutyTypeArr = ['cor', 'cee', 'carrier'];
      if (
        (this.props.categoryNum === '1' && dutyTypeArr.includes(item.duty_type)) ||
        (this.props.categoryNum !== '1' && item.duty_type === 'driver')
      ) {
        dutyNoCheck = true;
      }
      if (
        (this.props.categoryNum === '1' && dutyTypeArr.includes(item.payee_type)) ||
        (this.props.categoryNum !== '1' && item.payee_type === 'driver')
      ) {
        payeeNoCheck = true;
      }
      if ((dpPrice && duty && payee) || dutyNoCheck || payeeNoCheck) {
        duty = _.get(duty, 'id', duty);
        payee = _.get(payee, 'id', payee);
        if (dutyType === payeeType && duty === payee) {
          showInfo(ERROR, '付款方与收款方不可以相同！');
          return;
        }
        abnormalPrice += dpPrice;
        _payInfo.push({
          duty_type: dutyType,
          payee_type: payeeType,
          dp_price: dpPrice,
          duty_user_id: dutyUserId,
          payee_user_id: payeeUserId,
          duty,
          payee,
          payee_downstream: item.payee_downstream,
          duty_downstream: item.duty_downstream,
        });
        if (!dutyNoCheck && !duty) {
          requireds.付款方 = requireds.付款方 || `${i}-duty`;
          tipStr.push(typeListJosn[dutyType]);
        }
        if (!payeeNoCheck && !payee) {
          requireds.收款方 = requireds.收款方 || `${i}-payee`;
          tipStr.push(typeListJosn[payeeType]);
        }
        if (((dutyNoCheck && payee) || (payeeNoCheck && duty)) && !dpPrice) {
          showInfo(WARN, '请填写异常金额');
          return;
        }
      } else if (
        !dpPrice &&
        (duty || payee || item.payee_downstream || item.duty_downstream || dutyUserId || payeeUserId)
      ) {
        notCompleteRows.push(i + 1);
      } else if (dpPrice) {
        if (!dutyType) {
          requireds.付款方类型 = requireds.付款方类型 || `${i}-duty_type`;
        }
        if (!duty) {
          requireds.付款方 = requireds.付款方 || `${i}-duty`;
          tipStr.push(typeListJosn[dutyType]);
        }
        if (dutyType === 'point' && +dutyPointTYpe === 5 && !item.duty_downstream) {
          requireds.付款线路 = requireds.付款线路 || `${i}-duty_downstream`;
        }
        if (!payeeType) {
          requireds.收款方类型 = requireds.收款方类型 || `${i}-payee_type`;
        }
        if (!payee && !payeeNoCheck) {
          requireds.收款方 = requireds.收款方 || `${i}-payee`;
          tipStr.push(typeListJosn[payeeType]);
        }
        if (payeeType === 'point' && +payeePointTYpe === 5 && !item.payee_downstream) {
          requireds.收款线路 = requireds.收款线路 || `${i}-payee_downstream`;
        }
      }
    }
    if (!customType && notCompleteRows.length) {
      const isSure = await confirmWithReasonSync({
        tips: `收付款方信息的第${notCompleteRows.join('、')}行填写不完整，是否继续保存？`, // 顶部提示语
        noticeTitle: '',
        reason: '点击“继续”，会跳过填写不完整行，只保留填写完整的收付款信息。', // 具体原因
        btnText: { confirm: '继续' },
      });
      if (isSure) {
        payInfo = payInfo.filter((v, i) => !notCompleteRows.includes(i + 1));
        const nextState = {
          ..._state,
          payInfo,
        };
        this.save(customType, nextState);
      }
      return;
    }
    const idType = this.state.abnormType === '1' ? odLinkId : bLinId;
    const odLinkIdArr = this.props.hiddenOrderInfo ? this.props.selectIds : [idType];
    const requiredCols = Object.keys(requireds);
    if (requiredCols.length) {
      const firstRequired = requireds[requiredCols[0]];
      showInfo(
        WARN,
        `${requiredCols.map(i => `[${i}]`).join('、')}是必填项！请修改${
          addType[this.props.categoryNum]
        }补充${tipStr.join('、')}信息`,
        true,
        {
          confirm: () => {
            const el = document.body.querySelector(`[path='${firstRequired}'] input`);
            if (el) {
              el.focus();
            }
          },
        },
      );
      return;
    }
    const type = customType || this.props.type;
    const formData = new FormData();
    const bodyData = { operation: 'add_abnormal', uploadImgs: {} };
    let applyId;
    // 11390 -暂时注释
    // const addImgState = this.imgInstance && this.imgInstance.state;
    // const addImgs = addImgState.photoProps;
    // const addNames = addImgState.photoNum;

    const categoryNum = this.props.categoryNum || '1';
    const questKey = this.props.hiddenOrderInfo ? categoryNum : this.state.abnormType;
    const linkIdsType = questKey === '1' ? 'od_link_ids' : 'b_link_ids';
    const basicIdsType = questKey === '1' ? 'od_basic_ids' : 'b_batch_ids';
    const params = {
      [linkIdsType]: odLinkIdArr,
      op_type: type,
      category: questKey === '4' ? '3' : questKey,
      num_title: this.props.num_title,
      [basicIdsType]: this.props.od_basic_ids,
      company_name: this.state.baseInfo.add_company_name,
    };
    // 11390 -暂时注释
    // bodyData.uploadImgs['add_imgs[]'] = addImgs;
    if (type === 'update') {
      params.abnormal_id = this.props.id;
      applyId = this.props.id;
    }
    // 11390 -暂时注释
    // Object.keys(addImgs).forEach(name => formData.append('add_imgs[]', addImgs[name].formdata));
    // _baseInfo.image_names = { add_imgs: addNames };
    const batch_info = { b_tr_num: '', b_dr_name: '', b_dr_phone: '' };
    Object.keys(orderInfo).forEach(key => {
      if (['b_tr_num', 'b_dr_name', 'b_dr_phone'].includes(key)) {
        batch_info[key] = orderInfo[key];
      }
    });
    // fileList赋值给_baseInfo.add_imgs，最终保存的文件数据
    _baseInfo.add_imgs = this.imgInstance.state.fileList;
    _baseInfo.add_upload_files = true;
    params.abnormal_info = {
      ..._baseInfo,
      abnormal_price: abnormalPrice.toFixed(2),
      duty_pay_info: _payInfo,
      batch_info,
    };
    this.setState({ saving: true });
    formData.append('req', JSON.stringify(params));
    bodyData.data = params;
    const that = this.props;
    let res;
    try {
      if (this.checkPermission(type)) {
        // 如果有权限走原来的方法
        res = await fetchApi('/Order/Abnormal/opAbnormal', {
          method: 'POST',
          body: formData,
          credentials: 'include',
        });
      } else {
        // 否则走审核接口
        if (type === 'add' || type === 'update') {
          bodyData.data.od_basic_ids = odBasicId ? [odBasicId] : this.props.od_basic_ids;
        }
        res = await approvalProcess({
          applyId,
          isApply: true,
          hiddenOrderInfo: this.props.hiddenOrderInfo,
          questKey,
          that,
        })(bodyData);
      }
      if (customType) {
        if (res.errno !== 0) {
          showInfo(ERROR, res.errmsg);
        }
        return res.errno === 0 ? true : res.errmsg;
      }
      if (res.errno !== 0) {
        showInfo(ERROR, res.errmsg);
      } else {
        const typeText = { add: '登记', update: '修改', deal: '处理' }[type];
        showInfo(CHECK, `异常${typeText}成功`);
        this._slideDrager.handleHide();
        this.props.refreshTab && this.props.refreshTab();
      }
      this.setState({ saving: false });
    } catch (error) {
      // 错误
      showInfo(ERROR, '网络错误');
      this.setState({ saving: false });
      if (customType) {
        return '网络错误';
      }
    }
  };
  setAbnormalTypeFn = val => {
    if (!val) return;
    const orderDict = this.props.i18n.get('order', '运单');
    const waybillDict = this.props.i18n.get('batch', '任务');
    const batchDict = this.props.i18n.get('batch');
    /* 
      此处原map为{ 运单号: '1', 批次号: '3', 任务号: '4' }; 
      如果词条把批次和任务都改为运单则会有问题，所以批次号在1.0改为运单批次号
    */
    const type = { [`${orderDict}号`]: '1', [`${batchDict}批次号`]: '3', [`${waybillDict}号`]: '4' };
    const payInfo = [this.getDefaultPayInfo(this.state)];
    const baseInfo = Object.assign(this.state.baseInfo, { type: {} });
    this.setState({ abnormType: type[val.type], orderInfo: {}, payInfo, baseInfo });
    if (!this.props.hiddenOrderInfo) {
      window.localStorage.setItem('abnormType', type[val.type]);
    }
  };
  renderContent() {
    const {
      required,
      loading,
      enums,
      abnormalTypeEnums,
      orderInfo,
      baseInfo,
      payInfo,
      logInfo,
      tips,
      lineSettle,
      handleInfo,
      initedData,
      baseInfoDisabled,
      payInfoDisabled,
      allowMulPay,
      isOpenApproval,
      appoint,
      abnormType,
      addTypeList,
    } = this.state;
    const { type, id, hiddenOrderInfo = false, i18n } = this.props;
    return (
      <Load spinning={loading}>
        <Flex dir="column" align="stretch" className={prefixCls} getRef={ref => (this.wrap = ref)}>
          {!hiddenOrderInfo && (
            <OrderInfo
              set={this.set}
              data={orderInfo}
              enums={enums}
              i18nDict={i18n}
              required={required}
              addTypeList={addTypeList}
              disabled={type !== 'add'}
              setAbnormalTypeFn={this.setAbnormalTypeFn}
              abnormType={abnormType}
            />
          )}
          <BaseInfo
            ref={ref => (this.baseInfoRef = ref)}
            set={this.set}
            data={baseInfo}
            enums={enums}
            abnormalTypeEnums={abnormalTypeEnums}
            required={required}
            disabled={baseInfoDisabled}
            abnormType={abnormType}
            tips={tips}
            type={type}
            showPoint={!!((isOpenApproval && appoint) || !isOpenApproval)}
            showPermission={!!(isOpenApproval && appoint)}
            initedData={initedData}
            imgRefGetter={this.imgRefGetter}
            hiddenOrderInfo={hiddenOrderInfo}
          />
          <PayInfo
            set={this.set}
            enums={enums}
            type={type}
            allowMulPay={allowMulPay}
            data={payInfo}
            disabled={payInfoDisabled}
            lineSettle={lineSettle}
            abnormType={abnormType}
            categoryNum={this.props.categoryNum}
            hiddenOrderInfo={hiddenOrderInfo}
          />
          {logInfo.info && logInfo.info.length > 0 ? <Log logInfo={logInfo} logRefGetter={this.logRefGetter} /> : null}
          {this.isHandle && (
            <HandleInfo
              key="handleInfo"
              data={handleInfo}
              type={type}
              enums={this.handleInfoEnum}
              imgRefGetter={this.handleImgRefGetter}
              rmkRefGetter={this.handleRmkRefGetter}
              set={this.set}
            />
          )}
        </Flex>
      </Load>
    );
  }
  audit = async result => {
    if (!(await validateFieldsOf(this.wrap))) {
      return;
    }
    if (this.edited && !(await this.save('update'))) {
      return;
    }
    const { id } = this.props;
    const { baseInfo, isOpenApproval, handleInfo } = this.state;
    const { number } = baseInfo;
    const { rmk } = handleInfo;
    const addImgState = this.handleImgInstance && this.handleImgInstance.state;
    const addImgs = addImgState.photoProps;
    let res;
    this.setState({ saving: true });
    const imgKey = isOpenApproval ? 'add_imgs[]' : 'deal_imgs[]';
    if (!isOpenApproval) {
      const params = {
        op_type: 'audit',
        abnormal_id: id,
        category: this.props.categoryNum,
        audit_info: { audit_state: result, audit_rmk: rmk },
      };
      const formData = new FormData();
      Object.keys(addImgs).forEach(name => formData.append(imgKey, addImgs[name].formdata));
      formData.append('req', JSON.stringify(params));
      res = await fetchApi('Order/Abnormal/opAbnormal', { method: 'POST', body: formData, credentials: 'include' });
    } else {
      const data = [
        {
          operation: 'add_abnormal',
          apply_id: id,
          number,
        },
      ];
      const uploadImgs = {};
      uploadImgs[imgKey] = addImgs;
      res = await approvalProcess({
        applyId: id,
        isAudit: true,
      })({ opinion: rmk, audit: result, operation: 'add_abnormal', data, uploadImgs });
    }
    this.setState({ saving: false });
    if (res.errno === 0 && (!res.res.success || res.res.success.length)) {
      createTip('异常审核成功', CHECK).show();
      this._slideDrager.handleHide();
      this.props.refreshTab && this.props.refreshTab();
    } else {
      const errmsg = res.res && res.res.failed_detail ? res.res.failed_detail[0].msg : res.errmsg;
      createTip(errmsg, ERROR).show();
    }
  };
  deal = async () => {
    if (!(await validateFieldsOf(this.wrap))) {
      return;
    }
    if (this.edited && !(await this.save('update'))) {
      return;
    }
    const { id } = this.props;
    const { handleInfo } = this.state;
    const addImgState = this.handleImgInstance && this.handleImgInstance.state;
    const addImgs = addImgState.photoProps;
    const formData = new FormData();
    const params = {
      abnormal_id: id,
      op_type: 'deal',
      category: this.props.categoryNum,
      deal_info: {
        deal_time: handleInfo.time,
        deal_company_id: handleInfo.company_id,
        deal_user_id: handleInfo.user_id,
        deal_rmk: handleInfo.rmk,
      },
    };
    this.setState({ saving: true });
    Object.keys(addImgs).forEach(name => formData.append('deal_imgs[]', addImgs[name].formdata));
    formData.append('req', JSON.stringify(params));
    const res = await fetchApi('Order/Abnormal/opAbnormal', {
      method: 'POST',
      body: formData,
      credentials: 'include',
    });
    this.setState({
      saving: false,
    });
    if (res.errno !== 0) {
      createTip(res.errmsg, ERROR).show();
    } else {
      createTip('异常处理成功', CHECK).show();
      this._slideDrager.handleHide();
      this.props.refreshTab && this.props.refreshTab();
    }
  };
  reply = async () => {
    if (!(await validateFieldsOf(this.wrap))) {
      return;
    }
    if (this.edited && !(await this.save('update'))) {
      return;
    }
    const { id } = this.props;
    const { handleInfo } = this.state;
    const addImgState = this.handleImgInstance && this.handleImgInstance.state;
    const addImgs = addImgState.photoProps;
    const formData = new FormData();
    const params = {
      abnormal_id: id,
      company_id: handleInfo.company_id,
      content: handleInfo.rmk,
    };
    this.setState({ saving: true });
    Object.keys(addImgs).forEach(name => formData.append('imgs[]', addImgs[name].formdata));
    formData.append('req', JSON.stringify(params));
    const res = await fetchApi('Order/Abnormal/replyAbnormal', {
      method: 'POST',
      body: formData,
      credentials: 'include',
    });
    this.setState({ saving: false });
    if (res.errno !== 0) {
      createTip(res.errmsg, ERROR).show();
    } else {
      createTip('异常回复成功', CHECK).show();
      this._slideDrager.handleHide();
      this.props.refreshTab && this.props.refreshTab();
    }
  };
  renderFooter = () => {
    const { saving: loading } = this.state;
    const { type, auditType } = this.props;
    const buttons = [];
    switch (type) {
      case 'add':
      case 'update':
        buttons.push(
          <Button onClick={() => this.save()} type="primary" loading={loading}>
            保存
          </Button>,
        );
        buttons.push(
          <Button onClick={this.props.close} loading={loading}>
            取消
          </Button>,
        );
        break;
      case 'audit':
        buttons.push(
          <Button onClick={() => this.audit(1)} type="primary" loading={loading}>
            通过
          </Button>,
        );
        if (auditType !== 'confirm') {
          buttons.push(
            <Button onClick={() => this.audit(0)} loading={loading}>
              拒绝
            </Button>,
          );
        } else {
          buttons.push(
            <Button onClick={this.props.close} loading={loading}>
              取消
            </Button>,
          );
        }
        break;
      case 'deal':
        buttons.push(
          <Button onClick={this.deal} type="primary" loading={loading}>
            保存
          </Button>,
        );
        buttons.push(
          <Button onClick={this.props.close} loading={loading}>
            取消
          </Button>,
        );
        break;
      case 'reply':
        buttons.push(
          <Button onClick={this.reply} type="primary" loading={loading}>
            保存
          </Button>,
        );
        buttons.push(
          <Button onClick={this.props.close} loading={loading}>
            取消
          </Button>,
        );
        break;
      default:
        break;
    }
    return <div className={SlideDrager.footerClass}>{buttons}</div>;
  };
  render() {
    return (
      <SlideDrager
        isShow
        slideWidth={1000}
        HeaderTitle={this.props.title}
        contentDiv={this.renderContent()}
        footerDiv={this.renderFooter()}
        ref={r => (this._slideDrager = r)}
        dragerContainer={this.props.popContainer}
        close={this.props.close}
      />
    );
  }
}
export default withPopUpI18n(Abnormal);
