exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".capital-flow-extra{margin-top:10px}.capital-flow-extra__form-item,.capital-flow-extra__form-remark{display:inline-flex;align-items:center;position:relative;width:20%;height:34px;padding:4px 10px;margin-bottom:1px}.capital-flow-extra__form-item .fn-input-pure-wrap,.capital-flow-extra__form-item .fn-textarea_wrap,.capital-flow-extra__form-remark .fn-input-pure-wrap,.capital-flow-extra__form-remark .fn-textarea_wrap{height:28px}.capital-flow-extra__form-item .ant-input,.capital-flow-extra__form-item .fn-input-pure,.capital-flow-extra__form-remark .ant-input,.capital-flow-extra__form-remark .fn-input-pure{font-size:14px;width:100%;padding:1px 1px 1px 4px;border:0;box-shadow:inset 0 -1px 0 0 #e5e5e5;border-radius:0}.capital-flow-extra__form-item .fn-input-pure[disabled],.capital-flow-extra__form-item .fn-input[disabled],.capital-flow-extra__form-item .fn-textarea--disabled,.capital-flow-extra__form-remark .fn-input-pure[disabled],.capital-flow-extra__form-remark .fn-input[disabled],.capital-flow-extra__form-remark .fn-textarea--disabled{background-color:#fff}.capital-flow-extra__fn-label{position:relative;flex:0 0 72px;text-align:justify;-moz-text-align-last:justify;text-align-last:justify;padding-right:8px;white-space:nowrap;text-overflow:ellipsis;font-size:14px;line-height:1}.capital-flow-extra__fn-label--required{color:red}.capital-flow-extra__fn-label:after{position:absolute;padding:0 2px 0 4px;content:\":\"}.capital-flow-extra__form-remark{width:80%}.capital-flow-extra__form-remark .fn-textarea_wrap{width:100%}.capital-flow-extra__form-remark .fn-textarea{font-size:14px;width:100%;height:100%;padding:1px 1px 1px 4px;border:0;border-radius:0;box-shadow:inset 0 -1px 0 0 #e5e5e5}", ""]);

// exports
exports.locals = {
	"prefixCls": "capital-flow-extra",
	"capital-flow-extra": "capital-flow-extra",
	"capital-flow-extra__form-item": "capital-flow-extra__form-item",
	"capital-flow-extra__form-remark": "capital-flow-extra__form-remark",
	"fn-textarea_wrap": "fn-textarea_wrap",
	"fn-input-pure-wrap": "fn-input-pure-wrap",
	"ant-input": "ant-input",
	"fn-input-pure": "fn-input-pure",
	"fn-input": "fn-input",
	"fn-textarea--disabled": "fn-textarea--disabled",
	"capital-flow-extra__fn-label": "capital-flow-extra__fn-label",
	"capital-flow-extra__fn-label--required": "capital-flow-extra__fn-label--required",
	"fn-textarea": "fn-textarea"
};