/*
 * @Author: hanshuai
 * @Date: 2024-03-04 11:01:13
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import { dataAnalyticTrack } from '@/utils/dataAnalytic';
import { showInfo } from 'utils';
import { ERROR } from 'constants';
import { MessagesProvider } from './messagesContext';
import MessageDrawer from './MessageDrawer';
import { prefixCls } from './index.scss';

const PATH_LIST = ['/Schedule/taskList'];

export default function ChatEntry({ tokenInfo, setNewWss, layoutTabs }) {
  const [visible, setVisible] = useState(false);
  // 是否开启socket连接
  const [socketEnabled, setSocketEnabled] = useState(false);
  const [recoverDrawerShow, setRecoverDrawerShow] = useState(false);
  const [entryVisible, setEntryVisible] = useState(PATH_LIST.includes(window.location.pathname));

  const boundOffset = 20;
  let clickable = true;
  let screenX;
  let screenY;

  const handleDragStart = e => {
    screenX = e.screenX;
    screenY = e.screenY;
  };

  const handleDrag = e => {
    if (Math.abs(e.screenX - screenX) > boundOffset || Math.abs(e.screenY - screenY) > boundOffset) {
      clickable = false;
    }
  };

  const handleDragEnd = () => {
    if (clickable) {
      if (visible) {
        setVisible(false);
        setSocketEnabled(false);
        setRecoverDrawerShow(false);
      } else if (!visible && tokenInfo.hasPermission) {
        setVisible(true);
        setSocketEnabled(true);
      } else if (!visible && !tokenInfo.hasPermission) {
        showInfo(ERROR, tokenInfo.tokenErrorMessage);
      }
      dataAnalyticTrack(['大模型智能助手', '智能录单助手', '对话框入口']);
    }
    clickable = true;
  };

  const handelCloseDrawer = () => {
    setVisible(false);
    setRecoverDrawerShow(false);
    setSocketEnabled(false);
  };

  useEffect(() => {
    if (PATH_LIST.includes(window.location.pathname)) {
      setEntryVisible(true);
      if (recoverDrawerShow) {
        setVisible(true);
        setSocketEnabled(true);
      }
    } else {
      setEntryVisible(false);
      // 只有本来展示聊天窗口，因为切换路由关闭的切回后才恢复展示
      if (visible) {
        setRecoverDrawerShow(true);
      }
      setVisible(false);
    }
  }, [window.location.pathname]);

  // 判定展示录单助手的路由是否在财运通被打开的tab标签页中
  useEffect(() => {
    const socketPathExist = PATH_LIST.every(path => layoutTabs.some(item => item.path === path));
    if (!socketPathExist) {
      setSocketEnabled(false);
    }
  }, [layoutTabs.length]);

  return (
    <>
      <Draggable bounds="parent" onStart={handleDragStart} onDrag={handleDrag} onStop={handleDragEnd}>
        <a className={`${prefixCls} ${entryVisible ? '' : 'hidden'}`}>
          <div className="inner">
            <div className="cs-ico" />
            <div className="cs-font">你好，我是您的录单助手，有什么可以帮您？</div>
          </div>
        </a>
      </Draggable>
      {tokenInfo.hasPermission && socketEnabled && (
        <MessagesProvider tokenInfo={tokenInfo} setNewWss={setNewWss}>
          <MessageDrawer visible={visible} closeDrawer={() => handelCloseDrawer()} />
        </MessagesProvider>
      )}
    </>
  );
}

ChatEntry.propTypes = {
  layoutTabs: PropTypes.array, // 当前打开的tab标签页
  setNewWss: PropTypes.func, // 更新wss
  tokenInfo: PropTypes.object, // token信息
};
