import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { PopUp } from 'components';
import Carmaintenance from 'pages/CarMaintenanceController/Entity';
import { prefixCls } from './index.scss';

const TableType = {
  wx: '送修',
  by: '送保',
};

const TitleMap = {
  wx: '维修',
  by: '保养',
};
export default class MaintainRecordTable extends PureComponent {
  static propTypes = {
    type: PropTypes.oneOf(['wx', 'by']),
    resData: PropTypes.object,
  };

  constructor(props) {
    super();
  }
  // 打开维修记录详情
  viewCarmaintenance = id => {
    const popUpParam = {
      opType: 'view',
      type: this.props.type,
      id,
    };
    const dialog = new PopUp(Carmaintenance, popUpParam);
    dialog.show();
  };
  // 渲染维修记录表格
  renderTable = () => {
    const { resData, type } = this.props;
    const headers = resData?.header || [];
    const bodydata = resData?.data || [];
    return (
      <table className="table-wrap">
        <colgroup>
          <col width="120" />
          {headers.length && headers.map(() => <col width="auto" />)}
        </colgroup>
        <thead>
          <tr>
            <th className="first-col">
              <div className="tr-date">{`${TableType[type]}日期`}</div>
              <div className="tr-project">项目名称</div>
              <div className="tr-line" />
            </th>
            {headers.length && headers.map(item => <th className="op-col">{item}</th>)}
          </tr>
        </thead>
        <tbody>
          {bodydata.map(item => {
            return (
              <tr key={item.date}>
                <td className="td-project">{item.project}</td>
                {headers.map(key => {
                  const colData = item[key];
                  if (!colData?.length) return <td className="op-col">-</td>;
                  return (
                    <td className="op-col">
                      {colData.map(keyData => {
                        const { total_amount, maintenance_no, maintenance_id, send_maintain_user } = keyData;
                        const infoList = [];
                        send_maintain_user && infoList.push(send_maintain_user);
                        (total_amount || total_amount === 0) && infoList.push(`${total_amount}元`);
                        return (
                          <div>
                            <span className="link" onClick={() => this.viewCarmaintenance(maintenance_id)}>
                              {maintenance_no}
                            </span>
                            {infoList.length && <span>，{infoList.join('，')}</span>}
                          </div>
                        );
                      })}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };
  render() {
    const { type } = this.props;
    const title = `同项目”${TableType[type]}日期“前90天记录，请关注车辆历史${TitleMap[type]}记录，做好车辆日常管理`;
    return (
      <div className={prefixCls}>
        <div className="title">{title}</div>
        <div className="table-content">{this.renderTable()}</div>
      </div>
    );
  }
}
