const data = [
  {
    key: 'duty_type',
    name: '付款方类型',
    props: {
      selectedOnlyKey: true,
    },
  },
  {
    key: 'duty',
    name: '付款方',
  },
  {
    key: 'duty_user_id',
    name: '付款员工',
    tips: '如果启用了员工账户扣款，罚款审核通过后，将从员工个人账户中扣款到收款网点账户。',
    props: {
      fetchApi: {
        url: 'Basic/Org/userSug',
        para: {
          // is_table_sug: 1,
          search: '',
        },
        key: 'search',
      },
      tableHeader: {
        name: '姓名',
        telephone: '手机',
        company_name: '所属组织',
        department_name: '职位',
      },
    },
  },
  {
    key: 'duty_downstream',
    name: '付款线路',
    tips: '付款网点是分拨中心时，才能选择付款线路！',
    props: {
      uniqueKey: 'downstream',
      showKey: 'segment_name',
      fetchApi: {
        url: 'Order/Abnormal/segmentSug',
        para: {
          company_id: '',
        },
        key: 'search',
      },
      selectedOnlyKey: true,
    },
  },
  {
    key: 'payee_type',
    name: '收款方类型',
    props: {
      selectedOnlyKey: true,
    },
  },
  {
    key: 'payee',
    name: '收款方',
  },
  {
    key: 'payee_user_id',
    name: '收款员工',
    tips: '如果启用了员工账户扣款，罚款审核通过后，将从收款网点账户中扣款到员工个人账户。',
    props: {
      fetchApi: {
        url: 'Basic/Org/userSug',
        para: {
          // is_table_sug: 1,
          search: '',
        },
        key: 'search',
      },
      tableHeader: {
        name: '姓名',
        telephone: '手机',
        company_name: '所属组织',
        department_name: '职位',
      },
    },
  },
  {
    key: 'payee_downstream',
    name: '收款线路',
    tips: '收款网点是分拨中心时，才能选择收款线路！',
    props: {
      uniqueKey: 'downstream',
      showKey: 'segment_name',
      fetchApi: {
        url: 'Order/Abnormal/segmentSug',
        para: {
          company_id: '',
        },
        key: 'search',
      },
      selectedOnlyKey: true,
    },
  },
  {
    key: 'dp_price',
    name: '异常金额',
  },
];
const tips = {
  cor: t => ({
    tips: `该运单的发货人姓名为空，${t}款方不能选择发货人！请重新选择${t}款方类型！`,
    noticeTitle: '操作提示',
    reason: '如确实需要选择发货人，请选改单填写发货人姓名，再登记异常！',
  }),
  cee: t => ({
    tips: `该运单的收货人姓名为空，${t}款方不能选择收货人！请重新选择${t}款方类型！`,
    noticeTitle: '操作提示',
    reason: '如确实需要选择收货人，请选改单填写收货人姓名，再登记异常！',
  }),
  carrier: t => `该运单未操作过外部中转，${t}款方不能选择承运商！请重新选择${t}款方类型`,
  driver: t => `该运单未操作过装车/提货/送货/短驳/派单，${t}款方不能选择司机！请重新选择${t}款方类型`,
};
const typeListJosn = {
  cor: '发货人',
  cee: '收货人',
  point: '网点',
  carrier: '承运商',
  driver: '司机',
};
const addType = { 1: '运单', 3: '批次', 4: '任务' };
export { data, tips, typeListJosn, addType };
