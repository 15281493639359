exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".remark_dialog{width:280px;margin-left:20px}.remark_dialog .remark_text{font-size:13px;width:100%;margin-bottom:10px;padding:0 12px 0 0}.remark_dialog textarea{width:360px;height:80px;border-radius:2px;display:block;background:#fff;border:1px solid #c7c7c7}", ""]);

// exports
exports.locals = {
	"remark_dialog": "remark_dialog",
	"remark_text": "remark_text"
};