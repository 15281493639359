/* eslint-disable */
let createdOKLodop7766 = null;
const needCLodop = true;
let head = document.head || document.getElementsByTagName('head')[0] || document.documentElement;
let currNormalPort = window.localStorage.normalPrintPort || 8000,
  currHttpsPort = window.localStorage.httpsPrintPort || 8443;

let httpsPortList = [currHttpsPort];
let portList = document.location.protocol === 'https:' ? httpsPortList : [currNormalPort, 18000];
portList.forEach(function (value) {
  let oScript = document.createElement('script');
  oScript.src = `//localhost.lodop.net:${value}/CLodopfuncs.js?priority=1`;
  head.insertBefore(oScript, head.firstChild);
  let oScript_old = document.createElement('script');
  oScript_old.src = `//localhost:${value}/CLodopfuncs.js?priority=1`;
  head.insertBefore(oScript_old, head.firstChild);
});
window.oriPrintPort = portList[0];

function getLodop(oOBJECT, oEMBED, isPreview) {
  const origin = window.location.origin;
  let strHtmInstall = `<br><font color="#FF00FF">打印控件未安装!点击这里<a href="${origin}/storage/Lodop/Lodop_Extend.zip" target="_self">下载打印插件</a>,安装后请刷新页面或重新进入。</font>`;
  let strHtmUpdate = `<br><font color="#FF00FF">打印控件需要升级!点击这里<a href="${origin}/storage/Lodop/Lodop_Extend.zip" target="_self">下载打印插件</a>,升级后请重新进入。</font>`;
  let strHtm64Install = `<br><font color="#FF00FF">打印控件未安装!点击这里<a href="${origin}/storage/Lodop/Lodop_Extend.zip" target="_self">下载打印插件</a>,安装后请刷新页面或重新进入。</font>`;
  let strHtm64Update = `<br><font color="#FF00FF">打印控件需要升级!点击这里<a href="${origin}/storage/Lodop/Lodop_Extend.zip" target="_self">下载打印插件</a>,升级后请重新进入。</font>`;
  let strHtmFireFox =
    '<br><br><font color="#FF00FF">（注意：如曾安装过Lodop旧版附件npActiveXPLugin,请在【工具】->【附加组件】->【扩展】中先卸它）</font>';
  let strHtmChrome =
    '<br><br><font color="#FF00FF">(如果此前正常，仅因浏览器升级或重安装而出问题，需重新执行以上安装）</font>';
  let strCLodopInstall = `<br><font color="#FF00FF">CLodop云打印服务(localhost本地)未安装启动!点击这里<a href="${origin}/storage/Lodop/Lodop_Extend.zip" target="_self">下载打印插件</a>,安装后请刷新页面。</font>`;
  let strCLodopUpdate = `<br><font color="#FF00FF">CLodop云打印服务需升级!点击这里<a href="${origin}/storage/Lodop/Lodop_Extend.zip" target="_self">下载打印插件</a>,升级后请刷新页面。</font>`;
  let isIE = navigator.userAgent.indexOf('MSIE') >= 0 || navigator.userAgent.indexOf('Trident') >= 0;
  let is64IE = isIE && navigator.userAgent.indexOf('x64') >= 0;
  let LODOP;
  try {
    if (CLODOP !== null && typeof CLODOP !== 'undefined' && CLODOP.CVERSION >= '2.0.6.2' && isPreview !== 1) {
      try {
        LODOP = getCLodop();
      } catch (err) {
        console.log(err);
      }
      if (!LODOP && document.readyState !== 'complete') {
        alert('C-Lodop未安装，请安装后再试！');
        return;
      }
      if (!LODOP) {
        if (isIE) {
          document.write(strCLodopInstall);
        } else {
          document.documentElement.innerHTML = strCLodopInstall + document.documentElement.innerHTML;
        }
        return;
      }
      if (CLODOP.CVERSION < '2.0.5.3') {
        if (isIE) {
          document.write(strCLodopUpdate);
        } else {
          document.documentElement.innerHTML = strCLodopUpdate + document.documentElement.innerHTML;
        }
      }
      if (oEMBED && oEMBED.parentNode) {
        oEMBED.parentNode.removeChild(oEMBED);
      }
      if (oOBJECT && oOBJECT.parentNode) {
        oOBJECT.parentNode.removeChild(oOBJECT);
      }
    } else {
      if (oOBJECT !== undefined || oEMBED !== undefined) {
        if (isIE) {
          LODOP = oOBJECT;
        } else {
          LODOP = oEMBED;
        }
      } else if (createdOKLodop7766 === null) {
        LODOP = document.createElement('object');
        LODOP.setAttribute('width', 0);
        LODOP.setAttribute('height', 0);
        LODOP.setAttribute('style', 'position:absolute;left:0px;top:-100px;width:0px;height:0px;');
        if (isIE) {
          LODOP.setAttribute('classid', 'clsid:2105C259-1E0C-4534-8141-A753534CB4CA');
        } else {
          LODOP.setAttribute('type', 'application/x-print-lodop');
        }
        document.documentElement.appendChild(LODOP);
        createdOKLodop7766 = LODOP;
      } else {
        LODOP = createdOKLodop7766;
      }
      if (LODOP === null || typeof LODOP.VERSION === 'undefined') {
        if (navigator.userAgent.indexOf('Chrome') >= 0) {
          document.documentElement.innerHTML = strHtmChrome + document.documentElement.innerHTML;
        }
        if (navigator.userAgent.indexOf('Firefox') >= 0) {
          document.documentElement.innerHTML = strHtmFireFox + document.documentElement.innerHTML;
        }
        if (is64IE) {
          document.write(strHtm64Install);
        } else if (isIE) {
          document.write(strHtmInstall);
        } else {
          document.documentElement.innerHTML = strHtmInstall + document.documentElement.innerHTML;
        }
        return LODOP;
      }
    }
    if (LODOP.VERSION < '6.1.9.0') {
      if (needCLodop()) {
        document.documentElement.innerHTML = strCLodopUpdate + document.documentElement.innerHTML;
      } else if (is64IE) {
        document.write(strHtm64Update);
      } else if (isIE) {
        document.write(strHtmUpdate);
      } else {
        document.documentElement.innerHTML = strHtmUpdate + document.documentElement.innerHTML;
      }
      return LODOP;
    }
    LODOP.SET_LICENSES('', '7A516AF9101AD27684BD2E22542F4957635', '', '');
    return LODOP;
  } catch (err) {
    alert('getLodop出错:'.concat(err));
  }
}

// 如果使用CLODOP获取系统信息，系统信息存放在oResultOB里面，如果是LODOP则使用返回值
function getSystemInfo(LODOP, strInfoType, oResultOB) {
  // 如果定义了CLODOP， 则使用CLODOP获取系统信息， 否则使用lodop获取系统信息
  if (LODOP.CVERSION) {
    CLODOP.On_Return = (taskID, value) => {
      // eslint-disable-line no-undef
      if (oResultOB) {
        oResultOB.value = value; // eslint-disable-line no-param-reassign
      }
    };
  }
  let strResult = LODOP.GET_SYSTEM_INFO(strInfoType);
  if (!LODOP.CVERSION) {
    return strResult;
  }
  return '';
}

function getAllPrinters(LODOP) {
  let printerCount = LODOP.GET_PRINTER_COUNT();
  let printerArr = [];
  for (let i = 0; i < printerCount; ++i) {
    let printerName = LODOP.GET_PRINTER_NAME(i);
    printerArr.push({
      id: printerName,
      name: printerName,
    });
  }
  return printerArr;
}

const LodopFunc = {
  getLodop,
  getSystemInfo,
  getAllPrinters,
};

export default LodopFunc;
