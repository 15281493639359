exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".carousel-modal-content{width:800px;height:557px}", ""]);

// exports
exports.locals = {
	"prefixCls": "carousel-modal",
	"carousel-modal-content": "carousel-modal-content"
};