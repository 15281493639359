import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ListPageConf from 'pages/ListPage';
import { getUuid } from 'utils';
import { prefixCls } from './index.scss';

class SimilarFee extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
  };
  constructor(props) {
    super(props);
    this.state = {};
  }
  render = () => {
    const { id = '' } = this.props;
    const ListPage = ListPageConf({
      pageConfig: {
        listPage: true,
        tableInfo: {
          category: 'BatchFeeApproval',
          tab: 'b_fee_list_susp_dupes_popup_v1',
          body: {
            fetch_mode: 'all',
          },
        },
        tabs: {},
        url: 'Table/Search/batchFeeApprovalList',
      },
      key: getUuid(),
    });

    return (
      <div className={prefixCls}>
        <ListPage isRedirect designateParam={{ id }} />
      </div>
    );
  };
}

export default SimilarFee;
