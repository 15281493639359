// export const _permission = {
//   state_follow: 'addStateFollowImg',
//   Batch_pickup_batch: 'aPickupStateFollowImg',
//   Batch_pickup_reservation_batch: 'aPickupReservationStateFollowImg',
//   Batch_shuttle_up: 'aShuttleStateFollowImg',
//   Batch_shuttle_down: 'aShuttleAccStateFollowImg',
//   Batch_tr_up: 'addTruckUpTraceImg',
//   Batch_tr_pre_loading: 'addTruckUpTraceImg',
//   Batch_tr_up_loading: 'addTruckUpTraceImg',
//   Batch_tr_up_depart: 'addTruckUpTraceImg',
//   Batch_tr_up_arrival: 'addTruckUpTraceImg',
//   Batch_delivery_batch: 'addDeliveryTraceImg',
// }
// export const permission = {
//   SfStateFollow: 'addStateFollowImg',
//   trace: 'addTruckUpTraceImg',
//   sign: ['signPickupOrder', 'signDeliveryOrder', 'signTransOrder'], // 自提签收/送货签收/中转签收
//   abnormal: ['updateAbnormal', 'dealAbnormal', 'auditAbnormal'], // 修改异常/处理异常/审核异常
// }
// // 是否存在一个权限即可 数组
// export const permissionExitOne = ['sign', 'abnormal']
export const permission = {
  155: 'addStateFollowImg', // 日志类型: (运单跟踪)上传图片; 权限: (运单列表)上传照片
  // 批次上传照片的权限 -- start
  Batch_pickup_batch: 'aPickupStateFollowImg',
  Batch_pickup_reservation_batch: 'aPickupReservationStateFollowImg',
  Batch_shuttle_up: 'aShuttleStateFollowImg',
  Batch_shuttle_down: 'aShuttleAccStateFollowImg',
  Batch_tr_up: 'addTruckUpTraceImg',
  Batch_tr_pre_loading: 'addTruckUpTraceImg',
  Batch_tr_up_loading: 'addTruckUpTraceImg',
  Batch_tr_up_depart: 'addTruckUpTraceImg',
  Batch_tr_up_arrival: 'addTruckUpTraceImg',
  Batch_delivery_batch: 'addDeliveryTraceImg',
  Batch_tr_down: 'addTruckDnTraceImg',
  Batch_tr_down_depart: 'addTruckDnTraceImg',
  Batch_tr_down_arrival: 'addTruckDnTraceImg',
  // 批次上传照片的权限 -- end
  // 1006: 'addTruckUpTraceImg', // 日志类型: (配载日志)上传图片; 权限: (批次)上传照片
  380: ['signPickupOrder', 'signDeliveryOrder', 'signTransOrder'], // 日志类型: 签收; 权限: 自提签收/送货签收/中转签收
  4016: ['signPickupOrder', 'signDeliveryOrder', 'signTransOrder'], // 日志类型: APP签收; 权限: 自提签收/送货签收/中转签收
  // 800: ['updateAbnormal', 'dealAbnormal', 'auditAbnormal'], // 日志类型: 登记异常; 权限: 修改异常/处理异常/审核异常
};
// 是否存在一个权限即可 数组
export const permissionExitOne = ['380', '4016'];

export const audioType = ['aac'];
