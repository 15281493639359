exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".settle-propaganda{padding:0;border:none;box-shadow:none;background-color:initial}.settle-propaganda .fn-icon-close{top:6px;right:5px;width:24px;height:24px;opacity:0;display:none;z-index:1;align-items:center;justify-content:center}.settle-propaganda .fn-icon-error-o{opacity:.7;font-size:24px;color:#fff;position:absolute;right:10px;top:10px;line-height:1;z-index:2}.settle-propaganda .fn-icon-error-o:hover{opacity:1}.settle-propaganda .fn-dialog__center{padding:0}.settle-propaganda .cnt-wrap{position:relative}.settle-propaganda .fn-btn{left:100px;bottom:30px;box-shadow:none;position:absolute}.settle-propaganda .do-open,.settle-propaganda .learn-info{color:#fff;bottom:52px;height:57px;width:287px;display:flex;cursor:pointer;font-size:18px;font-weight:700;position:absolute;align-items:center;letter-spacing:10px;justify-content:center}.settle-propaganda .learn-info{right:101px}.settle-propaganda .do-open{left:96px}.settle-propaganda .forever-3-in,.settle-propaganda .learn-info-adv{left:256px;width:286px;height:56px;display:flex;cursor:pointer;position:absolute;align-items:center;letter-spacing:4px;justify-content:center}.settle-propaganda .learn-info-adv{color:#fff;bottom:53px;font-size:18px;font-weight:700}.settle-propaganda .forever-3-in{color:#fff;bottom:10px;opacity:.8;height:40px;font-size:17px}", ""]);

// exports
exports.locals = {
	"prefixCls": "settle-propaganda",
	"settle-propaganda": "settle-propaganda",
	"fn-icon-close": "fn-icon-close",
	"fn-icon-error-o": "fn-icon-error-o",
	"fn-dialog__center": "fn-dialog__center",
	"cnt-wrap": "cnt-wrap",
	"fn-btn": "fn-btn",
	"do-open": "do-open",
	"learn-info": "learn-info",
	"learn-info-adv": "learn-info-adv",
	"forever-3-in": "forever-3-in"
};