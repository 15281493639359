import { exportUrlConf } from 'constants';
import { dangerGoodsFields } from 'constants/setting/rosSetting';
/* eslint-disable */
module.exports = {
  // 批次详情导出URL
  exportUrlConf,
  feeInfo: {
    allKey: [
      'b_billing_f',
      'b_fuel_card_f',
      'b_arr_f',
      'b_arr_f_val',
      'b_receipt_f',
      'b_insur_f',
      'b_loc_tr_f',
      'b_loc_misc_f',
      'b_rmt_unload_f',
      'b_rmt_misc_f',
      'plan_arr_t',
      'b_info_f',
      'b_rmt_landing_f',
      'b_billing_f_paid',
      'b_fuel_card_f_paid',
      'b_insur_f_paid',
      'b_info_f_paid',
      'b_loc_tr_f_paid',
      'b_loc_misc_f_paid',
      'b_rmt_misc_f_paid',
      'b_rmt_unload_f_paid',
      'b_dr_co_f',
      'b_billing_f_move',
      'b_fuel_card_f_move',
      'b_receipt_f_move',
    ],
    otherProps: {
      // 车辆配载信息中哪些费用不可修改
      disable: [
        'b_billing_f',
        'b_fuel_card_f',
        'b_receipt_f',
        'b_info_f',
        'b_insur_f',
        'b_loc_tr_f',
        'b_loc_misc_f',
        'b_rmt_unload_f',
        'b_rmt_misc_f',
        'b_rmt_landing_f',
        'b_billing_f_paid',
        'b_fuel_card_f_paid',
        'b_insur_f_paid',
        'b_info_f_paid',
        'b_loc_tr_f_paid',
        'b_loc_misc_f_paid',
        'b_rmt_misc_f_paid',
        'b_rmt_unload_f_paid',
        'b_dr_co_f',
        'b_tr_tail_f',
        'b_billing_f_move',
        'b_fuel_card_f_move',
        'b_receipt_f_move',
        'b_yf_trans_f',
        'b_spot_fee',
        'b_upstairs_f',
        'b_handling_f',
        'b_trans_tax',
      ],
    },
    arrDisable: ['b_arr_f', 'b_loc_misc_f', 'b_rmt_misc_f', 'b_rmt_landing_f'],
  },
  feeInfoText: {
    loadArriveSetting: ['b_rmt_unload_f', 'b_rmt_misc_f'], // 新增配载时，费用信息的到站默认勾选，读取系统设置
    allKey: ['b_arr_f_val', 'b_receipt_f', 'plan_arr_t', 'b_rmt_landing_f'],
    otherProps: {
      // 车辆配载信息中哪些费用不可修改
      disable: [
        'b_billing_f',
        'b_fuel_card_f',
        'b_receipt_f',
        'b_info_f',
        'b_insur_f',
        'b_loc_tr_f',
        'b_loc_misc_f',
        'b_rmt_unload_f',
        'b_rmt_misc_f',
        'b_rmt_landing_f',
        'b_billing_f_paid',
        'b_fuel_card_f_paid',
        'b_insur_f_paid',
        'b_info_f_paid',
        'b_loc_tr_f_paid',
        'b_loc_misc_f_paid',
        'b_rmt_misc_f_paid',
        'b_rmt_unload_f_paid',
        'b_tr_tail_f',
      ],
    },
    arrDisable: ['b_arr_f', 'b_loc_misc_f', 'b_rmt_misc_f', 'b_rmt_landing_f'],
  },
  sign: {
    completeUrl: '/Order/Sign/sign',
    redirectUrl: '/Operate/deliverReceive',
    tipsContent: '签收',
    checkKey: 'od_link_id',
    listKey: 'od_link_detail',
    closeTab: true,
    saveMapKey: {
      num: 'sign_n',
      volume: 'sign_v',
      weight: 'sign_w',
      actual_price: 'sign_actual_price',
    },
    submitData: {
      tableColumnList: [
        'od_link_id',
        'sp_type',
        'sub_sp_type',
        'num',
        'weight',
        'volume',
        'g_info',
        'percent',
        'is_update',
        'sign_name',
        'sign_id_num',
        'cee_flag',
        'actual_price',
        'sign_code',
        'is_zzd',
        'delivery_link_id',
      ],
    },
  },
  trans_1: {
    completeUrl: 'Order/Trans/transHandle',
    redirectUrl: '/Transit/wtransitRollOut',
    tipsContent: '中转',
    checkKey: 'od_link_id',
    listKey: 'orders',
    submitData: {
      noNeedCheck: [
        'od_link_id',
        'b_trans_batch_id',
        // 中转费付款方式
        'trans_pay_mode',
        // 中转备注
        'trans_remark',
        // 结算金额
        'trans_settle_money',
        // 中转单号
        'trans_order_num',
        // 中转备注
        'order_trans_remark',
        'trans_arr_stn',
        'trans_f',
        'order_num',
        // 中转体积
        'trans_v_detail',
        // 中转重量
        'trans_w_detail',
        'trans_t',
        'delivery_mode',
        // 中转经办人
        'trans_up_mgr_id',
        'trans_modify_remark',
      ],
      tableColumnList: [
        'od_link_id',
        'b_trans_batch_id',
        // 中转时间
        'trans_t',
        // 中转运费
        'trans_f',
        // 中转费合计已付
        'trans_f_paid',
        'trans_freight_f',
        // 中转提货费
        'trans_pickup_f',
        // 中转送货费
        'trans_delivery_f',
        // 中转上楼费
        'trans_upstairs_f',
        // 中转进仓费
        'trans_in_wh_f',
        // 中转包装费
        'trans_pkg_f',
        // 中转保管费
        'trans_storage_f',
        // 中转装卸费
        'trans_handling_f',
        // 中转保价费
        'trans_insurance',
        // 中转其他费
        'trans_misc_f',
        // 中转费付款方式
        'trans_pay_mode',
        // 现付中转费
        'trans_pay_billing',
        // 到付中转费
        'trans_pay_arrival',
        // 月结中转费
        'trans_pay_monthly',
        // 回付中转费
        'trans_pay_receipt',
        // 中转装卸费
        'trans_handling_f',
        // 中转垫付费
        'trans_pay_adv',
        // 欠付中转费
        'trans_pay_owed',
        // 货款扣中转费
        'trans_pay_co_delivery',
        // 货到打款中转费
        'trans_pay_credit',
        // 中转备注
        'order_trans_remark',
        // 结算金额
        'trans_settle_money',
        // 中转单号
        'trans_order_num',
        // 实际运费
        'actual_price',
        // 中转返款已付
        'trans_settle_money_paid',
        // 中转到站
        'trans_arr_stn',
        'trans_cor_pick_dist',
        'trans_cee_pick_dist_pre',
        'trans_unit_price',
        'order_num',
        // 中转件数
        'trans_n',
        // 中转体积
        'trans_v_detail',
        // 中转重量
        'trans_w_detail',
        'delivery_mode',
        'trans_billing_method',
        // 中转经办人
        'trans_up_mgr_id',
        'trans_modify_remark',
        // 外部价格计算原始值
        'ext_trans_pt',
        // 中转件数单价
        'trans_num_unit_p',
        // 中转重量单价
        'trans_weight_unit_p',
        // 中转体积单价
        'trans_volume_unit_p',
      ],
    },
  },
  trans_2: {
    completeUrl: 'Order/Trans/transHandle',
    redirectUrl: '/Transit/ntransitRollOut',
    tipsContent: '中转',
    listKey: 'orders',
    checkKey: 'od_link_id',
    submitData: {
      noNeedCheck: [
        'od_link_id',
        // 结算金额
        'inner_trans_pay_mode',
        // 员工中转备注
        'inner_trans_remark',
        // 结算金额
        'inner_trans_settle_money',
        'inner_trans_t',
      ],
      tableColumnList: [
        'od_link_id',
        // 中转时间
        'inner_trans_t',
        'inner_trans_f',
        // 员工中转提货费
        'inner_trans_freight_f',
        // 员工中转提货费
        'inner_trans_pickup_f',
        // 员工中转送货费
        'inner_trans_delivery_f',
        // 员工中转上楼费
        'inner_trans_upstairs_f',
        // 员工中转进仓费
        'inner_trans_in_wh_f',
        // 员工中转包装费
        'inner_trans_pkg_f',
        // 员工中转保管费
        'inner_trans_storage_f',
        // 员工中转装卸费
        'inner_trans_handling_f',
        // 员工中转保价费
        'inner_trans_insurance',
        // 员工中转垫付费
        'inner_trans_pay_adv',
        // 员工中转其他费
        'inner_trans_misc_f',
        // 员工中转费付款方式
        'inner_trans_pay_mode',
        // 员工现付中转费
        'inner_trans_pay_billing',
        // 员工到付中转费
        'inner_trans_pay_arrival',
        // 员工月结中转费
        'inner_trans_pay_monthly',
        // 员工回付中转费
        'inner_trans_pay_receipt',
        // 员工欠付中转费
        'inner_trans_pay_owed',
        // 员工货款扣中转费
        'inner_trans_pay_co_delivery',
        // 员工货到打款中转费
        'inner_trans_pay_credit',
        // 员工中转备注
        'inner_trans_remark',
        // 结算金额
        'inner_trans_settle_money',
        // 实际运费
        'actual_price',
        'inner_trans_settle_money_paid',
      ],
    },
  },
  trans_3: {
    completeUrl: 'Order/Trans/transHandle',
    redirectUrl: '/Transit/ntransitRollOut',
    tipsContent: '中转',
    tipsEmptyMessage: '请选择要中转的运单！',
    listKey: 'orders',
    checkKey: 'od_link_id',
    submitData: {
      noNeedCheck: [
        'od_link_id',
        // 网点中转费付款方式
        'point_trans_pay_mode',
        // 网点中转备注
        'point_trans_remark',
        'point_trans_t',
        'inner_trans_start',
        'inner_trans_arr',
        'point_trans_pickup_addr',
        'point_trans_pickup_addr_remark',
        'point_trans_settle_money',
        // 网点中转件数
        'point_trans_n',
        // 网点中转费合计
        'point_trans_f',
        // 网点中转体积
        'point_trans_v_detail',
        // 网点中转重量
        'point_trans_w_detail',
        // 承运路由
        'point_trans_route',
      ],
      tableColumnList: [
        'od_link_id',
        // 中转时间
        'point_trans_t',
        // 网点中转费合计
        'point_trans_f',
        // 网点中转提货费
        'point_trans_freight_f',
        // 网点中转提货费
        'point_trans_pickup_f',
        // 网点中转送货费
        'point_trans_delivery_f',
        // 网点中转装卸费
        'point_trans_handling_f',
        // 网点中转上楼费
        'point_trans_upstairs_f',
        // 网点中转进仓费
        'point_trans_in_wh_f',
        // 网点中转包装费
        'point_trans_pkg_f',
        // 网点中转保管费
        'point_trans_storage_f',
        // 网点中转保价费
        'point_trans_insurance',
        // 网点中转其他费
        'point_trans_misc_f',
        // 网点中转费付款方式
        'point_trans_pay_mode',
        // 网点现付中转费
        'point_trans_pay_billing',
        // 网点到付中转费
        'point_trans_pay_arrival',
        // 网点月结中转费
        'point_trans_pay_monthly',
        // 网点回付中转费
        'point_trans_pay_receipt',
        // 网点欠付中转费
        'point_trans_pay_owed',
        // 网点货款扣中转费
        'point_trans_pay_co_delivery',
        // 网点货到打款中转费
        'point_trans_pay_credit',
        // 网点中转备注
        'point_trans_remark',
        // 网点中转欠款
        'point_trans_settle_money',
        // 网点中转欠款已付
        'point_trans_settle_money_paid',
        // 网点中转提货地址
        'point_trans_pickup_addr',
        // 网点中转提货地址备注
        'point_trans_pickup_addr_remark',
        'inner_trans_start',
        'inner_trans_arr',
        // 网点中转件数
        'point_trans_n',
        // 网点中转体积
        'point_trans_v_detail',
        // 网点中转重量
        'point_trans_w_detail',
        // 实际运费
        'actual_price',
        // 网点中转费已付
        'point_trans_f_paid',
        'goods',
        // 标准网点中转费
        'point_trans_std_freight_f',
        // 标准网点中转送货费
        'point_trans_std_delivery_f',
        // 标准网点中转提货费
        'point_trans_std_pickup_f',
        // 标准网点中转装卸费
        'point_trans_std_handling_f',
        // 承运路由
        'point_trans_route',
        // 送提货里程
        'trans_cor_pick_dist',
        'trans_cee_pick_dist_pre',
        // 中转件数单价
        'point_trans_num_unit_p',
        // 中转重量单价
        'point_trans_weight_unit_p',
        // 中转体积单价
        'point_trans_volume_unit_p',
      ],
      feeColumnList: [
        'company_id',
        'b_arr_f',
        'b_billing_f',
        'b_fuel_card_f',
        'b_info_f',
        'b_insur_f',
        'b_loc_misc_f',
        'b_loc_tr_f',
        'b_receipt_f',
        'b_rmt_landing_f',
        'b_rmt_misc_f',
        'b_rmt_unload_f',
        'plan_arr_t',
      ],
    },
  },
  pre_load: {
    listKey: 'load_od_link_detail',
    completeUrl: '/Truck/TruckPre/onComplete',
  },
  // const isPreLoad = _.get(window, 'company_setting.task_auto_loading') === 'yes' // 是自动装车
  truck: {
    completeUrl: '/Truck/Truck/onComplete',
    redirectUrl: '/Operate/carStowage',
    tipsContent: '装车',
    listKey: 'load_od_link_detail',
    checkKey: 'od_link_id',
    saveMapKey: {
      num: 'b_tr_load_n',
      volume: 'b_tr_load_v',
      weight: 'b_tr_load_w',
      suit: 'b_tr_load_s',
      actual_price: 'b_tr_load_actual_price',
    },
    submitData: {
      tableColumnList: [
        'dispatch_f',
        'od_link_id',
        'sp_type',
        'sub_sp_type',
        'num',
        'weight',
        'volume',
        'suit',
        'actual_price',
        'g_info',
        'percent',
        'is_update',
        'od_loc_tr_f',
        'od_rmt_unload_f',
        'od_loc_misc_f',
        'od_rmt_msc_f',
        'is_zzd',
        'order_num',
      ],
      feeColumnList: [
        'unique_id',
        'company_id',
        'b_arr_f',
        'b_billing_f',
        'b_fuel_card_f',
        'b_info_f',
        'b_insur_f',
        'b_loc_misc_f',
        'b_loc_tr_f',
        'b_receipt_f',
        'b_rmt_landing_f',
        'b_rmt_misc_f',
        'b_rmt_unload_f',
        'plan_arr_t',
        'b_billing_f_paid',
        'b_fuel_card_f_paid',
        'b_insur_f_paid',
        'b_info_f_paid',
        'b_loc_tr_f_paid',
        'b_loc_misc_f_paid',
        'b_rmt_unload_f_paid',
        'b_rmt_misc_f_paid',
        'b_dr_co_f',
        'b_oil_f',
        'b_bridge_f',
        'b_kilometers',
        'b_tr_tail_f',
        'b_billing_f_move',
        'b_fuel_card_f_move',
        'b_receipt_f_move',
        'b_arr_f_move',
        'b_yf_trans_f',
        'b_spot_fee',
        'b_upstairs_f',
        'b_handling_f',
        'b_trans_tax',
        'actual_mile',
        'section_time',
        'section_hour',
        'node_remark',
      ],
      boolToNum: [
        'b_billing_f_paid',
        'b_fuel_card_f_paid',
        'b_insur_f_paid',
        'b_info_f_paid',
        'b_loc_tr_f_paid',
        'b_loc_misc_f_paid',
        'b_rmt_unload_f_paid',
        'b_rmt_misc_f_paid',
      ],
    },
  },
  shuttle: {
    completeUrl: '/Truck/Shuttle/onComplete',
    redirectUrl: '/Operate/shortDepart',
    tipsContent: '短驳',
    checkKey: 'od_link_id',
    listKey: 'load_od_link_detail',
    saveMapKey: {
      num: 'b_shuttle_load_n',
      volume: 'b_shuttle_load_v',
      weight: 'b_shuttle_load_w',
      actual_price: 'b_shuttle_load_actual_price',
    },
    submitData: {
      tableColumnList: [
        'dispatch_f',
        'od_link_id',
        'sp_type',
        'sub_sp_type',
        'num',
        'weight',
        'volume',
        'g_info',
        'goods',
        'percent',
        'is_update',
        'actual_price',
        'is_zzd',
      ],
    },
  },
  pickup: {
    completeUrl: '/Truck/Pickup/onComplete',
    redirectUrl: 'Operate/pickupBatch',
    tipsContent: '提货',
    checkKey: 'od_link_id',
    listKey: 'load_od_link_detail',
    saveMapKey: {
      num: 'b_pickup_load_n',
      volume: 'b_pickup_load_v',
      weight: 'b_pickup_load_w',
      suit: 'b_pickup_load_s',
      actual_price: 'b_pickup_load_actual_price',
    },
    submitData: {
      checkColumn: {
        pickup_f: ['float'],
        b_pickup_f: ['float'],
      },
      tableColumnList: [
        'dispatch_f',
        'od_link_id',
        'sp_type',
        'sub_sp_type',
        'pickup_f',
        'b_pickup_f',
        'num',
        'weight',
        'volume',
        'suit',
        'pickup_f_paid',
        // 'g_num', // 订单提货 件数 重量 体积
        // 'g_weight',
        // 'g_volume',
        'goods',
        'g_info',
        'is_update',
        'percent',
        'actual_price',
        'pickup_pt',
        'is_zzd',
        'id',
        'pay_monthly',
        'b_pickup_spot_fee',
        'b_pickup_trans_tax',
        'b_pickup_upstairs_f',
        'b_pickup_yf_trans_f',
        'b_pickup_handling_f',
        'b_pickup_info_f',
      ],
    },
  },
  delivery: {
    completeUrl: '/Truck/Delivery/onComplete',
    redirectUrl: 'Operate/deliverBatch',
    tipsContent: '送货',
    checkKey: 'od_link_id',
    listKey: 'load_od_link_detail',
    saveMapKey: {
      num: 'b_delivery_load_n',
      volume: 'b_delivery_load_v',
      weight: 'b_delivery_load_w',
      suit: 'b_delivery_load_s',
      actual_price: 'b_delivery_load_actual_price',
    },
    submitData: {
      checkColumn: {
        od_delivery_f: ['float'],
        b_delivery_f: ['float'],
      },
      tableColumnList: [
        'dispatch_f',
        'od_link_id',
        'sp_type',
        'sub_sp_type',
        'num',
        'weight',
        'volume',
        'suit',
        'od_delivery_f',
        'od_delivery_f_paid',
        'goods',
        'g_info',
        'percent',
        'is_update',
        'actual_price',
        'b_delivery_f',
        'delivery_pt',
        'is_zzd',
        'id',
        'pay_monthly',
        'b_delivery_spot_fee',
        'b_delivery_trans_tax',
        'b_delivery_upstairs_f',
        'b_delivery_yf_trans_f',
        'b_delivery_handling_f',
        'b_delivery_info_f',
      ],
    },
  },
  deliveryBatchDispatch: {
    completeUrl: '/Truck/Delivery/saveBatchDispatch',
    tipsContent: '送货派单',
    checkKey: 'b_link_id',
    listKey: 'dispatch_infos',
    submitData: {
      checkColumn: {
        b_delivery_f: ['float'],
      },
      tableColumnList: [
        'b_tr_num',
        'b_remark',
        'b_dr_phone',
        'b_dr_name',
        'b_link_id',
        'b_delivery_f',
        'b_tr_id',
        'b_dr_id',
        'delivery_batch',
      ],
    },
  },
  pickupBatchDispatch: {
    completeUrl: '/Truck/Pickup/saveBatchDispatch',
    tipsContent: '送货派单',
    checkKey: 'b_link_id',
    listKey: 'dispatch_infos',
    submitData: {
      checkColumn: {
        b_delivery_f: ['float'],
      },
      tableColumnList: [
        'b_tr_num',
        'b_remark',
        'b_dr_phone',
        'b_dr_name',
        'b_link_id',
        'b_pickup_f',
        'b_tr_id',
        'b_dr_id',
        'pickup_batch',
      ],
    },
  },
  batchDispatchDriver: {
    completeUrl: '/Truck/Pickup/saveBatchDispatch',
    tipsContent: '指派司机',
    checkKey: 'b_link_id',
    listKey: 'dispatch_infos',
    submitData: {
      checkColumn: {
        b_delivery_f: ['float'],
      },
      tableColumnList: [
        'b_tr_num',
        'b_remark',
        'b_dr_phone',
        'b_dr_name',
        'b_dr_assistant_name',
        'b_dr_assistant_phone',
        'b_link_id',
        'b_pickup_f',
        'b_tr_id',
        'b_dr_id',
        'pickup_batch',
      ],
    },
  },
  dispatch: {
    completeUrl: '/Order/Dispatch/dispatchHandle',
    redirectUrl: 'Dispatch/dispatchMgr',
    tipsContent: '派单',
    checkKey: 'od_link_id',
    listKey: 'dispatch_infos',
    saveMapKey: {},
    submitData: {
      checkColumn: {
        dispatch_f: ['float'],
      },
      tableColumnList: [
        'dispatch_dr_name',
        'dispatch_dr_phone',
        'dispatch_remark',
        'inner_remark',
        'dispatch_f',
        'od_link_id',
        'od_basic_id',
        'order_num',
        'dispatch_reserved_t',
      ],
    },
  },
  manual_schedule: {
    tipsContent: '人工调度',
    submitData: {
      tableColumnList: ['od_link_id', 'pickup_tr_team_id'],
    },
  },
  bind_device: {
    completeUrl: '/Order/Order/bindDevice',
    redirectUrl: 'Order/orderList',
    tipsContent: '绑定设备',
    checkKey: 'od_link_id',
    listKey: 'order_list',
    saveMapKey: {},
    submitData: {
      checkColumn: {
        // device_id: ['require'],
      },
      tableColumnList: ['od_link_id', 'od_basic_id', 'order_num', 'device_id'],
    },
  },
  pack: {
    completeUrl: '/Truck/Pack/onComplete',
    redirectUrl: 'Dispatch/dispatchMgr',
    tipsContent: '打包',
    listKey: 'load_od_link_detail',
    saveMapKey: {},
    submitData: {
      checkColumn: {
        dispatch_f: ['float'],
      },
      tableColumnList: ['od_link_id', 'remark'],
    },
  },
  // 移库，mockData
  moveStock: {
    completeUrl: '/Truck/WhOperate/onDirectInWh',
    redirectUrl: 'Operate/stockManager',
    tipsContent: '移库',
    checkKey: 'od_link_id',
    listKey: 'od_link_ids',
    saveMapKey: {},
    submitData: {
      checkColumn: {},
      tableColumnList: ['od_link_id', 'od_basic_id', 'order_num'],
    },
  },
  assignReservation: {
    completeUrl: 'Reservation/Reservation/assignReservation',
    redirectUrl: 'Order/reservationList',
    tipsContent: '指派',
    checkKey: 'id',
    listKey: 'assign_infos',
    saveMapKey: {},
    submitData: {
      checkColumn: {},
      tableColumnList: ['reservation_num', 'carrier_id', 'id'],
    },
  },
  outWMS: {
    completeUrl: '/Warehouse/WmsBill/outHandle',
    redirectUrl: '/WMS/out',
    tipsContent: '出库',
    checkKey: 'id',
    listKey: 'wms_out',
    saveMapKey: {
      num: 'b_delivery_load_n',
    },
    submitData: {
      checkColumn: {
        // count: ['float'],
      },
      tableColumnList: ['id', 'count'],
    },
  },
  // 移库
  moveWMS: {
    completeUrl: '/Warehouse/WmsMove/moveHandle',
    redirectUrl: '/WMS/move',
    tipsContent: '移库',
    checkKey: 'id',
    listKey: 'wms_out',
    saveMapKey: {
      num: 'b_delivery_load_n',
    },
    submitData: {
      checkColumn: {
        // count: ['float'],
      },
      tableColumnList: [
        'id',
        'remain_count', // 库存现有量
        'wh_location_id', // 转出货位
        're_location_id', // 转入货位
        'move_wh_count', // 转移数量
        'move_wh_reason', // 移库原因
        'remark', // 备注
      ],
    },
  },
  // card from 中的 传递unique值的列表，默认传递showKey
  cardFormList: {
    uniqueKeyList: [
      'sign_mgr_id',
      'fee_ave_type',
      'up_mgr_id',
      'mgr_id',
      'station',
      'dn_mgr_id',
      'trans_pick_type',
      'billing_method',
      'source',
      'stock_point',
      'device_id',
      'pm_id',
    ],
    allList: ['arrival', 'carrier_id', 'route'],
    wmsBothKey: {
      cor_name: 'cor_id',
    },
  },
  priceUrl: '/Basic/PriceSystemV2/getOrderOuterPrice',
  // 外部中转价格
  extTransPt: {
    priceDecodeList: [
      'goods',
      'start_info',
      'arr_info',
      'cee_addr_info',
      'cor_addr_info',
      'trans_start_info',
      'trans_arr_info',
    ],
    // 请求价格数据，向后台传递的参数
    priceParam: {
      // 运单号
      order_num: undefined,
      // 运单id
      od_link_id: undefined,
      cor_id: undefined,
      // 发货人
      cor_name: undefined,
      // 发货人单位
      cor_com: undefined,
      cee_id: undefined,
      // 收货人
      cee_name: undefined,
      // 收货人单位
      cee_com: undefined,
      // 送货方式
      delivery_mode: undefined,
      // 运输方式
      trsp_mode: undefined,
      // 货物信息
      goods: 'goods_str',
      // 发站
      start_info: 'start_info_str',
      // 到站
      arr_info: 'arr_info_str',
      // 送货地址
      cee_addr_info: 'cee_addr_info_str',
      // 提货地址
      cor_addr_info: 'cor_addr_info_str',
      // 提货备注
      cor_addr_remark: undefined,
      // 送货备注
      cee_addr_remark: undefined,
      // 出发网点
      start_point_id: undefined,
      start_point: 'start_point_id',
      // 目的网点
      arr_point: undefined,
      // 送货里程
      cee_pick_dist: undefined,
      // 提货里程
      cor_pick_dist: undefined,
      // 中转送货里程
      trans_cee_pick_dist: undefined,
      // 中转提货里程
      trans_cor_pick_dist: undefined,
      // 承运商
      carrier_id: undefined,
      // 中转发站
      trans_start_info: 'company_addr_str',
      // 中转到站
      trans_arr_info: 'trans_arr_info_str',
      // 单票总重量
      weight: undefined,
      // 单票总体积
      volume: undefined,
      // 单票总件数
      num: undefined,
      // 结算重量
      settle_weight: undefined,
      // 转出方地址
      // 'trans_up_addr_info': 'com_addr_info',
      // 中转到站
      // 'carrier_arr_info': undefined,
      // 中转货物信息
      trans_goods: undefined,
      // 产品类型id
      product_key: undefined,
      // 服务类型
      service_type: undefined,
      // 车长
      truck_length: undefined,
      // 车型
      truck_type: undefined,
    },
  },
  // 网点中转价格
  pointTransPt: {
    priceDecodeList: ['goods', 'cee_addr_info', 'point_trans_start', 'point_trans_arr'],
    // 请求价格数据，向后台传递的参数
    priceParam: {
      // 运单号
      order_num: undefined,
      // 运单id
      od_link_id: undefined,
      // 送货地址
      cee_addr_info: 'cee_addr_info_str',
      // 提货地址
      cor_addr_info: 'point_trans_pickup_addr',
      // 送货方式
      delivery_mode: undefined,
      // 运输方式
      trsp_mode: undefined,
      // 货物信息
      goods: 'goods_str',
      // 网点中转分摊方式
      point_trans_fee_ave_type: undefined,
      // 网点中转送货里程
      point_trans_cee_pick_dist: 'trans_cee_pick_dist',
      // 网点中转提货里程
      point_trans_cor_pick_dist: undefined,
      // 网点中转转出方
      point_trans_up_com_id: 'com_id',
      // 网点中转承运商
      point_trans_dn_com_id: undefined,
      // 网点中转发站
      point_trans_start: 'company_addr_str',
      // 网点中转到站
      point_trans_arr: 'arr_info_str',
      // 单票总重量
      weight: 'point_trans_w_detail',
      // 单票总体积
      volume: 'point_trans_v_detail',
      // 单票总件数
      num: 'point_trans_n',
      // 产品类型id
      product_key: undefined,
      // 服务类型
      service_type: undefined,
      // 车长
      truck_length: undefined,
      // 车型
      truck_type: undefined,
    },
  },
  deliveryLoadCheck: ['pickup_f_paid', 'od_delivery_f_paid'],
  spTypeMap: { 1: 'num', 2: 'weight', 3: 'volume', 4: 'actual_price', 5: 'suit' },
  //splitValuMap: { num: 'wh_n', weight: 'wh_w', volume: 'wh_v' },
  // valMap: {
  //   pickup: { num: 'b_pickup_load_n', weight: 'b_pickup_load_w', volume: 'b_pickup_load_v' },
  //   sign: { num: 'sign_n', weight: 'sign_w', volume: 'sign_v' }
  // }
  // 识别相同货物的key
  uniqueGoodsKey: [
    'bo_num', // 商流订单号
    'code', //货物代码
    'name', //货物名称
    'special', //特殊货物,
    'pkg_service', //包装服务,
    'price_unit', //单价单位
    'weight_per_num', //单件重量
    'count_per_num', //单件数量
    'volume_per_num', //单件体积
    'num_per_tray', //单托件数
    'length', //长
    'width', //宽
    'high', //高
    'cat', //货物类型
    'pkg', //包装，
    'suit_unit_price', //元/套
    'unit_price_disp', //单价
    'unit', //单位,
    'spec', //规格
    'model', //型号
    ...Object.keys(dangerGoodsFields),
  ],
};
