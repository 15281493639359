const rightIcon = [
  {
    iconType: 'icon-previous',
    clickFunc: 'handleBatchChange',
    tips: '所有行进左屏',
  },
  {
    iconType: 'icon-arrow-l',
    clickFunc: 'handleSingleChange',
    tips: '选中行进左屏',
  },
];
const leftIcon = [
  {
    iconType: 'icon-arrow-r',
    clickFunc: 'handleSingleChange',
    tips: '选中行进右屏',
  },
  {
    iconType: 'icon-next',
    clickFunc: 'handleBatchChange',
    tips: '所有行进右屏',
  },
];
const checkIsSplitMap = {
  delivery_pick: {
    num: 'b_delivery_load_n',
    volume: 'b_delivery_load_v',
    weight: 'b_delivery_load_w',
    suit: 'b_delivery_load_s',
  },
  delivery_pick_v1: {
    num: 'b_delivery_load_n',
    volume: 'b_delivery_load_v',
    weight: 'b_delivery_load_w',
    suit: 'b_delivery_load_s',
  },
  pickup_pick: {
    num: 'b_pickup_load_n',
    volume: 'b_pickup_load_v',
    weight: 'b_pickup_load_w',
    suit: 'b_pickup_load_s',
  },
  pickup_pick_v1: {
    num: 'b_pickup_load_n',
    volume: 'b_pickup_load_v',
    weight: 'b_pickup_load_w',
    suit: 'b_pickup_load_s',
  },
};
const splitDisableKeyMap = {
  delivery_pick: {
    key: 'od_delivery_f_paid',
    tips: '当前运单为拆单送货，不能勾选已付',
  },
  delivery_pick_v1: {
    key: 'od_delivery_f_paid',
    tips: '当前运单为拆单送货，不能勾选已付',
  },
  pickup_pick: {
    key: 'pickup_f_paid',
    tips: '当前运单为拆单提货，不能勾选已付',
  },
  pickup_pick_v1: {
    key: 'pickup_f_paid',
    tips: '当前运单为拆单提货，不能勾选已付',
  },
};
// 拆单中需要判断
const resetPayList = ['delivery_pick', 'pickup_pick', 'pickup_pick_v1'];
const reduceKey = ['order_num', 'batch_num', 'car_batch', 'delivery_batch', 'shuttle_batch', 'pickup_batch'];
module.exports = {
  rightIconList: [
    {
      iconType: 'icon-extend-left',
      clickFunc: 'handleCloseLeft',
      tips: '右屏铺满',
    },
    ...rightIcon,
  ],
  rightHideIconList: [
    {
      iconType: 'icon-extend-right',
      clickFunc: 'resume',
      tips: '恢复左右屏',
    },
    ...rightIcon,
  ],
  leftHideIconList: [
    ...leftIcon,
    {
      iconType: 'icon-extend-left',
      clickFunc: 'resume',
      tips: '恢复左右屏',
    },
  ],
  leftIconList: [
    ...leftIcon,
    {
      iconType: 'icon-extend-right',
      clickFunc: 'handleCloseRight',
      tips: '左屏铺满',
    },
  ],
  subBtnList: {
    children: '设置',
    eventNames: ['onClick'],
    display: true,
    showKey: 'title',
    iconType: 'icon-setting',
    type: 'ButtonMenu',
    uniqueKey: 'key',
    sublist: [
      {
        key: 'set_header',
        title: '列表设置',
      },
      {
        key: 'set_sort',
        title: '排序设置',
      },
    ],
  },
  showWVRate: ['truck_pick', 'delivery_pick', 'shuttle_pick', 'pickup_pick', 'manual_schedule'],
  // 开通拆单的tab
  showSplitTab: {
    Order: {
      truck_pick: {
        path: '/Truck/Truck',
        tipText: '库存',
        batchKey: 'b_tr_basic_id',
        permission: 'trSplit',
        resetKeyDict: {
          b_tr_load_v: 'volume',
          b_tr_load_w: 'weight',
          b_tr_load_n: 'num',
        },
      },
      delivery_pick: {
        path: '/Truck/Delivery',
        tipText: '库存',
        permission: 'deliverySplit',
        batchKey: 'b_delivery_basic_id',
        resetKeyDict: {
          b_delivery_load_v: 'volume',
          b_delivery_load_w: 'weight',
          b_delivery_load_n: 'num',
        },
      },
      // 1.0干线配载拆单
      truck_pick_v1: {
        path: '/Truck/Truck',
        tipText: '库存',
        batchKey: 'b_tr_basic_id',
        permission: 'trSplitV1',
        resetKeyDict: {
          b_tr_load_v: 'volume',
          b_tr_load_w: 'weight',
          b_tr_load_n: 'num',
          b_tr_load_s: 'suit',
        },
      },
      // 1.0送货配载拆单
      delivery_pick_v1: {
        path: '/Truck/Delivery',
        tipText: '库存',
        permission: 'deliverySplitV1',
        batchKey: 'b_delivery_basic_id',
        resetKeyDict: {
          b_delivery_load_v: 'volume',
          b_delivery_load_w: 'weight',
          b_delivery_load_n: 'num',
          b_delivery_load_s: 'suit',
        },
      },
      // 1.0提货配载拆单
      pickup_pick_v1: {
        path: '/Truck/Pickup',
        permission: 'pickupSplitV1',
        tipText: '可提货',
        batchKey: 'b_pickup_basic_id',
        resetKeyDict: {
          b_pickup_load_v: 'volume',
          b_pickup_load_w: 'weight',
          b_pickup_load_n: 'num',
          b_pickup_load_s: 'suit',
        },
      },
      shuttle_pick: {
        path: '/Truck/Shuttle',
        tipText: '库存',
        permission: 'shuttleSplit',
        batchKey: 'b_shuttle_basic_id',
        resetKeyDict: {
          b_shuttle_load_v: 'volume',
          b_shuttle_load_w: 'weight',
          b_shuttle_load_n: 'num',
        },
      },
      sign_pick: {
        path: '/Truck/Sign',
        permission: 'signSplit',
        tipText: '可签收',
        isFromSign: true,
        resetKeyDict: {},
      },
      delivery_sign_pick: {
        path: '/Truck/Sign',
        permission: 'signSplit',
        tipText: '可签收',
        isFromSign: true,
        resetKeyDict: {},
      },
      trans_sign_pick: {
        path: '/Truck/Sign',
        permission: 'signSplit',
        tipText: '可签收',
        isFromSign: true,
        resetKeyDict: {},
      },
      pickup_sign_pick: {
        path: '/Truck/Sign',
        permission: 'signSplit',
        tipText: '可签收',
        isFromSign: true,
        resetKeyDict: {},
      },
      pickup_pick: {
        path: '/Truck/Pickup',
        permission: 'pickupSplit',
        tipText: '可提货',
        batchKey: 'b_pickup_basic_id',
        resetKeyDict: {
          b_pickup_load_v: 'volume',
          b_pickup_load_w: 'weight',
          b_pickup_load_n: 'num',
        },
      },
      dispatch_delivery_sign_pick: {
        path: '/Truck/Sign',
        permission: 'signSplit',
        tipText: '可签收',
        isFromSign: true,
        resetKeyDict: {},
      },
    },
    Reservation: {
      pickup_pick: {
        path: '/Reservation/Reservation',
        permission: 'reservationPickupSplit',
        od_key: 'reservation_num',
        orderNumKey: 'reservation_num',
        orderTips: '订单号',
        tipText: '可提货',
        disableActualPrice: true,
        batchKey: 'b_pickup_basic_id',
        resetKeyDict: {
          b_pickup_load_v: 'volume',
          b_pickup_load_w: 'weight',
          b_pickup_load_n: 'num',
        },
      },
    },
  },
  // 拆单中需要判断是否是拆单的map条件
  checkIsSplitMap,
  splitDisableKeyMap,
  resetPayList,
  // 减速key
  reduceKey,
};
