import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Icon, Tips, Flex, PopUp, PopTip } from 'components';
import {
  hasPermission,
  fetchApi,
  viewImage,
  genImageURL,
  getFileUrl,
  downloadUploadedFile,
  createTip,
  post,
} from 'utils';
import actions from 'actions';
import _ from 'lodash';
import taskCarSpotCheck from 'pages/ScheduleController/task/components/taskCarSpotCheck';
import { permission, permissionExitOne, audioType } from './constants';
import { prefixCls } from './index.scss';
import { CHECK, ERROR } from 'constants';

export default class Log extends PureComponent {
  /*
   * 日志信息: 用户展示转交日志、车辆配载日志、运单跟踪
   */
  static propTypes = {
    extData: PropTypes.any,
    logInfo: PropTypes.any,
    onDel: PropTypes.any,
    onEdit: PropTypes.any,
    unEdit: PropTypes.any,
    indexKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // 标志点击了那行的修改按钮
    tableWidth: PropTypes.any,
    opType: PropTypes.string,
    onShowCallback: PropTypes.func,
    // 是否正序
    orderByAsc: PropTypes.bool,
    curIndex: PropTypes.number,
    hideDetailDesc: PropTypes.bool,
    hidebriefDdesc: PropTypes.bool,
  };
  static defaultProps = {
    orderByAsc: false,
  };

  constructor(prop) {
    super(prop);
    const { logInfo = [] } = prop;

    this.state = {
      indexKey: -1,
      logInfo,
      expend: {},
      tipsProps: {
        dir: 'down',
        className: 'log-tips',
        type: 'warn',
      },
    };
    this.comInfo = {};
    this.userInfo = {};
  }
  componentDidMount() {
    this.initRow2State();
  }
  UNSAFE_componentWillReceiveProps = props => {
    if (Number(props.indexKey) !== this.state.indexKey) {
      this.setState({
        indexKey: Number(props.indexKey),
      });
    }
    if (props.logInfo !== this.state.logInfo) {
      this.setState(
        {
          logInfo: props.logInfo,
        },
        this.initRow2State,
      );
    }
  };
  initRow2State = () => {
    const r2s = [...this.wrap.querySelectorAll('.row-2')];
    const { expend } = this.state;
    r2s.forEach(item => {
      const { id } = item.dataset;
      if (item.offsetHeight > 60) {
        expend[id] = expend[id] || 0;
      }
    });
    this.setState({
      expend: {
        ...expend,
      },
    });
  };
  toggleExtend = id => {
    const { expend } = this.state;
    expend[id] = expend[id] ? 0 : 1;
    this.setState({
      expend: {
        ...expend,
      },
    });
  };
  getLogClass = (data, index, orderByAsc) => {
    const curIndexProps = this.props.curIndex;
    let curIndex;
    let curLineIndex;
    if (curIndexProps !== undefined) {
      curIndex = curIndexProps;
      curLineIndex = orderByAsc ? curIndex - 1 : 0;
    } else {
      curIndex = orderByAsc ? data.length - 1 : 0;
      curLineIndex = orderByAsc ? data.length - 2 : 0;
    }
    let className;
    if (orderByAsc) {
      // eslint-disable-next-line no-nested-ternary
      className = index < curIndex ? 'done' : index > curIndex ? 'todo' : 'doing';
    } else {
      // eslint-disable-next-line no-nested-ternary
      className = index > curIndex ? 'done' : index < curIndex ? 'todo' : 'doing';
    }
    if (index === curLineIndex) {
      className = `${className} doing-line`;
    }
    return className;
  };
  unEdit = index => {
    this.setState({
      indexKey: -1,
    });
    this.props.unEdit(index);
  };
  onEdit = index => {
    this.setState({
      indexKey: index,
    });
    this.props.onEdit(index);
  };
  deleteImage = async (imgInfo, extraParams) => {
    try {
      const { file_type, name, path, type } = imgInfo;
      const req = {
        ...extraParams,
        images: { file_type, name, path, type },
      };
      const res = await post('Order/Order/deletePicture', req);
      if (res.errno !== 0) {
        res.errmsg && createTip(res.errmsg, ERROR, null, 2000, null, true).show();
        return;
      }
      new PopUp(PopTip, {
        type: CHECK,
        content: '照片删除成功',
        isModal: true,
      }).show();
      return true;
    } catch (error) {
      createTip('服务器异常', ERROR, null, 2000, null, true).show();
    }
  };
  onDel = index => {
    this.setState({
      indexKey: -1,
    });
    this.props.onDel(index);
  };
  onShow = (delPicParam, imgArr) => {
    const { images, id } = delPicParam;
    if (images.link_status === 'text') return;
    const showDeleteButton = delPicParam.canDel;
    const extraParams = {
      id,
    };
    const imgs = imgArr.filter(item => item.link_status !== 'link_status');
    const options = {
      deleteImageName: '点击后将删除当前图片',
      withDelete: showDeleteButton,
      customDeleteFun: imgInfo => this.deleteImage(imgInfo, extraParams),
      onDeleteSucceed: () => setTimeout(this.props.onShowCallback, 1500),
    };
    viewImage(images, imgs, options);
  };
  getCanDeleteImg = item => {
    const canDelete = _.get(item, 'content.delete_image', true);
    const isUploadCom = +item.op_com_id === +window.company_id;
    let hasDelPerm = false;
    let permissionKey;
    if (item.type) {
      let deletePicType = item.type;
      if (+item.type === 1006) {
        // 批次的日志
        deletePicType = this.props.opType;
      }
      let checkAll = true;
      if (permissionExitOne.includes(deletePicType)) {
        checkAll = false;
      }
      hasDelPerm = hasPermission(permission[deletePicType], checkAll);
    }
    // eslint-disable-next-line no-nested-ternary
    return canDelete && hasDelPerm ? (this.props.opType === 'Batch_tr_down' ? isUploadCom : true) : false;
  };
  rederUserTips = () => (
    <Tips className="log-tips" type="warn" title="手动添加的状态">
      <span className="fn-icon fn-icon-editinfo fn-icon-custom table-cell" />
    </Tips>
  );
  renderUserVisible = () => (
    <Tips className="log-tips" type="warn" title="用户可见">
      <span className="fn-icon fn-icon-ps-show fn-icon-custom table-cell" />
    </Tips>
  );
  renderUserNotVisible = () => (
    <Tips className="log-tips" type="warn" title="用户不可见">
      <span className="fn-icon fn-icon-invisible fn-icon-custom table-cell" />
    </Tips>
  );
  renderComTips = info => (
    <Flex dir="column" width="220" style={{ padding: '0 5px', color: '#666' }}>
      <Flex style={{ fontSize: '14px', color: '#000' }}>【{info.company_name}】</Flex>
      <Flex>负责人：{info.name}</Flex>
      <Flex>负责人电话：{info.telephone}</Flex>
      <Flex>客服电话：{info.service_phone}</Flex>
      <Flex wrap>
        地址：{info.address ? info.address.show_val : ''}
        {info.address_remark || ''}
      </Flex>
    </Flex>
  );
  renderUserTips = info => (
    <Flex dir="column" width="220" style={{ padding: '0 5px', color: '#666' }}>
      {/* <Flex style={{ fontSize: '14px', color: '#000' }}>【{info.name}】</Flex>
    <Flex >姓名：{info.name}</Flex> */}
      <Flex>手机号：{info.telephone}</Flex>
      <Flex>部门：{info.depName}</Flex>
      <Flex wrap>所属组织：{info.company_name}</Flex>
    </Flex>
  );
  getComInfo = async id => {
    if (!this.comInfo[id]) {
      const res = await fetchApi('Basic/Org/orgInfo', {
        method: 'POST',
        body: {
          req: {
            company_id: id,
            type: 'Company',
            readonly: true,
            front_is_admin: 0,
            set_single_login: 0,
            op_company_id: id,
            online_trans: true,
          },
          raw: 1,
        },
      });
      if (res.errno === 0) {
        const info = res.res.org_info;
        this.comInfo[id] = info;
        return this.renderComTips(this.comInfo[id]);
      }
    } else {
      return this.renderComTips(this.comInfo[id]);
    }
  };
  getUserInfo = async id => {
    const param = {
      raw: 1,
      req: {
        type: 'User',
        user_id: id,
      },
    };
    if (!this.userInfo[id]) {
      const res = await actions.companyServer.getOrgInfo(param);
      if (res.data && res.data.errno === 0) {
        const resData = res.data && res.data.res;
        const info = resData && resData.org_info;
        this.userInfo[id] = info;
        const depId = info.department_id; // 拿到部门id，去enum_info寻找部门信息
        const depData = resData.enum_info && resData.enum_info.department_id;
        let depName = '';
        depName = depData && depData[depId] && depData[depId].department_name;
        this.userInfo[id].depName = depName;
        return this.renderUserTips(this.userInfo[id]);
      }
    } else {
      console.log('已有数据', this.userInfo[id]);
      return this.renderUserTips(this.userInfo[id]);
    }
  };
  getVisible = item => {
    let isVisible;
    const notVisible = {
      修改派单: 1,
      派单: 1,
      取消派单: 1,
    };
    const noVisiIcon = {
      上传照片: 1,
      创建运单: 1,
    };
    if (
      notVisible[item.type_show] ||
      (item.is_visible && !Number(item.is_visible) && item.is_visible !== '') ||
      item.is_visible === 0
    ) {
      isVisible = this.renderUserNotVisible();
    } else {
      Number(item.is_visible) && (isVisible = this.renderUserVisible());
    }
    if (noVisiIcon[item.type_show]) {
      isVisible = null;
    }
    return isVisible;
  };
  viewSpotDetail = (title, id) => {
    new PopUp(taskCarSpotCheck, {
      id,
      title,
      opType: 'view',
    }).show();
  };
  renderComInfoTips = (name, id) => (
    <Tips className="log-tips" type="warn" asyncContent={() => this.getComInfo(id)}>
      <span id="icon-tip-map" className="fn-icon fn-icon-yingyewangdian fn-icon-custom table-cell" />
      操作网点：{name}
    </Tips>
  );
  renderUserInfoTips = (name, id) => (
    <Tips className="log-tips" type="warn" asyncContent={() => this.getUserInfo(id)}>
      <span id="icon-tip-user" className="fn-icon fn-icon-user-add fn-icon-custom table-cell" />
      操作人：{name}
    </Tips>
  );
  renderIcon = parm => (
    <Icon iconType={parm.iconType} mode="icon" onClick={parm.callBack} tipsProps={parm.tipsProps} tips={parm.tips} />
  );
  renderImgHtml = parm => {
    const images = parm.delPicParam.images || {};
    const { imgArr = [] } = parm;
    const isDelete = !images.path || !images.name;
    return (
      <>
        {(images.file_type === 'image' || !images.file_type) && (
          <div className="img-wrap">
            <img src={parm.src} onClick={() => !isDelete && this.onShow(parm.delPicParam, imgArr)} />
          </div>
        )}
        {images.file_type && images.file_type !== 'image' && (
          <div className="img-wrap-fild">
            <Icon classname="item-download" iconType="icon-download" onClick={() => downloadUploadedFile(images)} />
            <p>{images.name}</p>
          </div>
        )}
      </>
    );
    // return (<span className="imgA" onClick={() => this.onShow(parm.src, parm.delPicParam)}>[{parm.index + 1}]</span>)
  };
  renderAudio = parm => {
    return <audio className="audio-field" controls src={parm.src} />;
  };
  renderLogList = data => {
    if (data === undefined) return;
    const { indexKey, tipsProps, expend } = this.state;
    const { orderByAsc, extData, hideDetailDesc, hidebriefDdesc } = this.props;
    return (
      <ul>
        {data.map((item, index) => {
          let ediBtn = this.renderIcon({
            iconType: 'icon-modify',
            callBack: () => this.onEdit(index),
            tipsProps,
            tips: '修改',
          });
          let delBtn = this.renderIcon({
            iconType: 'icon-del',
            callBack: () => this.onDel(index),
            tipsProps,
            tips: '删除',
          });
          let isUser = null;
          const cur = '';
          let htmlLi = [];
          let basic = '';
          const isVisible = this.getVisible(item);
          const imgArr = item.state_follow_img;
          const img = [];
          imgArr &&
            imgArr.length > 0 &&
            imgArr.forEach((imgItem, imgIndex) => {
              basic = audioType.includes(imgItem.file_type) ? getFileUrl(imgItem) : genImageURL(imgItem);
              const delPicParam = {
                id: item.id,
                images: imgItem,
                canDel: this.getCanDeleteImg(item),
              };

              audioType.includes(imgItem.file_type)
                ? img.push(
                    this.renderAudio({
                      src: basic,
                    }),
                  )
                : img.push(
                    this.renderImgHtml({
                      src: basic,
                      log_type: item.type,
                      index: imgIndex,
                      delPicParam,
                      imgArr,
                    }),
                  );
            });
          indexKey === index &&
            (ediBtn = this.renderIcon({
              iconType: 'icon-task-cancel',
              callBack: () => this.unEdit(index),
              tipsProps,
              tips: '取消修改',
            }));
          Number(item.user_add) && (isUser = this.rederUserTips());
          !item.del_trace && (delBtn = '');
          !item.modify_trace && (ediBtn = '');
          const className = this.getLogClass(data, index, orderByAsc);
          const expendCls = expend[item.id] !== undefined ? `expend-${expend[item.id]}` : '';
          const iconName = expend[item.id] ? 'icon-arrow-dt' : 'icon-arrow-db';
          const briefDesc = !hidebriefDdesc && item.brief_operation ? item.brief_operation : '';
          console.log(item.type, item, 'oooooo');
          htmlLi = (
            <li className={`log-item ${className}`} key={item.id}>
              <div className="type-show">{item.type_show}</div>
              <Flex dir="column" className="log-item-detail" align="stretch">
                {briefDesc ? (
                  <div className="brief-desc">
                    <span className="fn-icon fn-icon-jianbao" />
                    {briefDesc}
                  </div>
                ) : null}
                <Flex className="row-1" align="center">
                  <span className="fn-icon fn-icon-time fn-icon-custom table-cell" />
                  操作时间：{item.trace_time}
                  <div className="com-name">{this.renderComInfoTips(item.op_com_name, item.op_com_id)}</div>
                  <div className="com-name">{this.renderUserInfoTips(item.op_user_name, item.op_user_id)}</div>
                  {/* <div className="sort-td op-user">
                      <div className="icon-user-wrap">
                        {isUser}
                      </div>
                      <span>{item.op_user_name}</span></div> */}
                  {ediBtn}
                  {delBtn}
                </Flex>
                <div className={`row-2 ${expendCls}`} data-id={item.id}>
                  {!hideDetailDesc && item.trace_info ? (
                    <div className="trace-info">
                      {isVisible}
                      <span className="fn-icon fn-icon-neirong" />
                      {item.trace_info}
                      {item.content && !!item.content.spotCheckId && (
                        <div
                          className="view-spot-detail"
                          onClick={() => this.viewSpotDetail(item.type_show, item.content.spotCheckId)}
                        >
                          <Icon iconType="icon-preview" />
                          查看点检详情
                        </div>
                      )}
                    </div>
                  ) : (
                    isVisible
                  )}
                  {!!expendCls && <Icon iconType={iconName} onClick={() => this.toggleExtend(item.id)} />}
                </div>
                <Flex className="row-3" wrap>
                  {img}
                </Flex>
                {item.log_type === 'workOrder' ? <Flex>{item.op_detail}</Flex> : null}
              </Flex>
            </li>
          );
          return htmlLi;
        })}
      </ul>
    );
  };
  render() {
    const { logInfo = [] } = this.state;
    return (
      <div className={`${prefixCls}`} ref={ref => (this.wrap = ref)}>
        <div className={`${prefixCls}__main qwer`}>{this.renderLogList(logInfo)}</div>
      </div>
    );
  }
}
