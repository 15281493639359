import React from 'react';
import { showInfo, confirmWithReason, postJAVA } from 'utils';
import PropTypes from 'prop-types';
import { ERROR, WARN } from 'constants';
import { Load, PopUp } from 'components';
import { getLocation } from 'pages/ListPage/customize/Batch/waybillTrace';
import RechargeDialog from 'components/commoncomponents/AccountRechargeDialog';
import { confirmClass, mapLoading } from './index.scss';
import { trackCharge } from '@/hooks/subscribeCharge';

class index extends React.Component {
  static propTypes = {
    truckInfo: PropTypes.array,
    batchID: PropTypes.string,
    comID: PropTypes.string,
    gId: PropTypes.string,
    num: PropTypes.string,
    sys: PropTypes.string, // 1:tms, 2: oms
    opFrom: PropTypes.string, // 操作类型，订单轨迹：reservation;运单轨迹：order;批次轨迹：batch
    number: PropTypes.string, // 订单取reservation_num,运单取order_num，批次取car_batch
    isFromNtocc: PropTypes.bool, // 是否来自网货的运单轨迹
  };
  constructor(props) {
    super(props);
    console.log('props', props);
    this.param = props.truckInfo
      ? {
          truck_info: props.truckInfo,
          batchID: props.batchID,
          op_from: props.opFrom,
          number: props.number,
          gid: props.gId,
          query_source: 'tms',
        }
      : {
          batchID: props.batchID,
          gid: props.gId,
          reservation_num: props.num,
          sys_version: props.sys,
          op_from: props.opFrom,
          number: props.number,
          query_source: 'tms',
        };
    this.state = {
      loading: false,
    };
  }
  componentDidMount() {
    const { batchID, comID } = this.props;

    this.track_log.contentWindow.trackCharge = params => trackCharge({ ...params });
    this.track_log.contentWindow.getLocation = () => getLocation(batchID, false, false); // 通过轨迹反向查询定位，不需要预检和报错
    this.track_log.contentWindow.handleErr = this.handleErr;
    this.track_log.contentWindow.handleWarn = this.handleWarn;
    this.track_log.contentWindow.showLoading = val => this.showLoading(val);
    this.track_log.contentWindow.bd_gps_wallet_open = window.sas_env_group
      ? window.sas_env_group.bd_gps_wallet_open
      : true;
    this.track_log.contentWindow.batch_id = batchID;
    this.track_log.contentWindow.openRechargeDialog = () => this.openRechargeDialog();
    // 财运通框架的请求方法
    this.track_log.contentWindow.postJAVA = postJAVA;
    // 环境
    this.track_log.contentWindow.QIANKUN_ENV = process.env.QIANKUN_ENV;
    // 公司id
    this.track_log.contentWindow.company_id = comID;
  }
  openRechargeDialog = () => {
    return new PopUp(RechargeDialog, {
      title: '充值',
    }).show();
  };
  handleErr = (err, detail, isShowInfo = false) => {
    if (isShowInfo) {
      showInfo(ERROR, err);
    } else {
      confirmWithReason({
        title: ' 操作提醒',
        iconType: WARN, // icon
        tips: err, // 顶部提示语
        className: confirmClass,
        noticeTitle: '',
        reason: detail || '', // 具体原因
        btns: { confirm: 1 },
      });
    }
  };
  showLoading = loading => {
    this.setState({
      loading,
    });
  };
  handleWarn = () => {};
  render() {
    const { isFromNtocc } = this.props;
    return (
      // <iframe
      //   ref={ref => (this.track_log = ref)}
      //   width="100%"
      //   height="100%"
      //   src={`/api/Basic/Loc/getWaybillRelatedTruckInfo?req=${JSON.stringify(this.param)}`}
      //   title="轨迹跟踪"
      // />
      <Load spinning={this.state.loading} tip="Loading..." autoStop={false} className={mapLoading}>
        {isFromNtocc ? (
          <iframe
            ref={ref => (this.track_log = ref)}
            width="100%"
            height="100%"
            frameBorder="0"
            src={`/static/track/trace.html?req=${JSON.stringify(this.param)}`}
            title="运单轨迹"
          />
        ) : (
          <iframe
            ref={ref => (this.track_log = ref)}
            width="100%"
            height="100%"
            frameBorder="0"
            src={`/static/track/index.html?req=${JSON.stringify(this.param)}`}
            title="轨迹跟踪"
          />
        )}
      </Load>
    );
  }
}
export default index;
