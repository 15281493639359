exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".date-time-validity-picker{display:inline-block;position:relative}.date-time-validity-picker.invalid .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled){border-color:#ff4040}.date-time-validity-picker.invalid .ant-input{background:#fff5f5;border:1px solid #ff4040;pointer-events:none}.date-time-validity-picker.invalid .fn-icon:not(.input-tip--invalid){display:none}.date-time-validity-picker-extra{display:inline-flex;height:100%;align-items:center;position:absolute;left:5px;font-size:12px;color:#333}", ""]);

// exports
exports.locals = {
	"prefixCls": "date-time-validity-picker",
	"date-time-validity-picker": "date-time-validity-picker",
	"invalid": "invalid",
	"ant-calendar-picker": "ant-calendar-picker",
	"ant-calendar-picker-input": "ant-calendar-picker-input",
	"ant-input-disabled": "ant-input-disabled",
	"ant-input": "ant-input",
	"fn-icon": "fn-icon",
	"input-tip--invalid": "input-tip--invalid",
	"date-time-validity-picker-extra": "date-time-validity-picker-extra"
};