import React from 'react';
import { PureInput as Input, DateTimePicker } from 'components';
import { bem } from 'utils';

import { prefixCls } from './index.scss';

const cls = bem(prefixCls);

const renderAttr = page => {
  const { attrField } = page.props;
  const attrEl = Object.keys(attrField).map(k => {
    let _el = null;
    const _item = attrField[k];
    switch (k) {
      case 'settle_time':
        _el = (
          <div key={k} className={cls('form-item')}>
            <label className={cls('fn-label')}>{_item.label}</label>
            <DateTimePicker
              showTime
              classname="date-input"
              disabled={!_item.editable || false}
              required={_item.required || false}
              defaultValue={_item.value}
              onChange={val => page.attrSet(k, val)}
            />
          </div>
        );
        break;
      case 'remark':
        _el = (
          <div key={k} className={cls('form-item')}>
            <label className={cls('fn-label')}>{_item.label}</label>
            <Input
              value={_item.value}
              disabled={!_item.editable || false}
              required={_item.required || false}
              onChange={e => page.attrSet(k, e.target.value)}
            />
          </div>
        );
        break;
      default:
        _el = null;
    }
    return _el;
  });
  return <div className={cls()}>{attrEl}</div>;
};

export default renderAttr;
