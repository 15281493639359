exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".batch-buy__tip{display:flex;font-size:14px;width:100%;height:24px;line-height:24px;margin-bottom:20px;vertical-align:middle}.batch-buy__tip>div{display:inline-block}.batch-buy__tip .batch-tip-num-txt{display:flex;align-items:center}.batch-buy__tip .spe-font{font-size:22px;font-weight:700;margin:0 2px}.batch-buy__tip .fn-icon{width:16px;font-size:18px;margin-right:8px;margin-bottom:2px}.batch-buy-batch-table table{border:0}", ""]);

// exports
exports.locals = {
	"prefixCls": "batch-buy",
	"batch-buy__tip": "batch-buy__tip",
	"batch-tip-num-txt": "batch-tip-num-txt",
	"spe-font": "spe-font",
	"fn-icon": "fn-icon",
	"batch-buy-batch-table": "batch-buy-batch-table"
};