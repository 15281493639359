import React from 'react';
import { Icon } from 'components';

function infoItem({ label, children, required, show, className, tips }) {
  if (show === false) {
    return null;
  }
  return (
    <div className={`info-item ${required ? 'required' : ''} info-item_${className}`}>
      <label>{label}</label>
      {children}
      {tips ? <Icon iconType="icon-help" tipsCls="info-item-icon" tips={tips} /> : null}
    </div>
  );
}

export default infoItem;
