import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'components';

const CalcRule = props => {
  const { setRuleFilterData, initialData = {}, enums = {} } = props;
  const { group_field = [], sum_field = [] } = initialData;
  const [group, setGroup] = useState(group_field);
  const [sum, setSum] = useState(sum_field);

  useEffect(() => {
    setRuleFilterData({ group, sum });
  }, [group, sum]);
  return (
    <div className="fn-card__form">
      <div className="fn-card__form__card-item">
        <label className="fn-label">分组字段：</label>
        <Select
          value={group}
          multiple
          data={enums.group_key}
          map={false}
          compare="key"
          onChange={val => setGroup(val?.map(({ key }) => key))}
        />
      </div>
      <div className="fn-card__form__card-item">
        <label className="fn-label">加和的字段：</label>
        <Select
          value={sum}
          multiple
          data={enums?.sum_key}
          map={false}
          compare="key"
          onChange={val => setSum(val?.map(({ key }) => key))}
        />
      </div>
    </div>
  );
};

CalcRule.propTypes = {
  setRuleFilterData: PropTypes.func,
  initialData: PropTypes.object,
};

export default CalcRule;
