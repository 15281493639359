import { setComVal, getComVal } from 'utils';
import addTruck from 'utils/business/truck/add';
import addDriver from 'utils/business/driver/add';
import BasicPickOrder from 'components/commoncomponents/pickorder/BasicPickOrder';
import { shouldWvFunc } from 'components/commoncomponents/pickorder/BiPickOrderContent/biUtils';
// import payee from 'pages/TruckController/components/payee'
export const setWeightToVolume = inst => {
  const _this = inst;
  const useRate = (window.company_setting.use_weight_to_volume || {}).value === 'true' ? 1 : 0;
  const wRate = Number((window.company_setting.weight_to_volume || {}).weight) || 0;
  const vRate = Number((window.company_setting.weight_to_volume || {}).volume) || 0;
  _this.wRate = useRate ? wRate : 0;
  _this.vRate = useRate ? vRate : 0;
};
// 添加司机
export const drNameIconClick = (inst, key, form, data) => {
  const newData = data;
  const _this = inst;
  addDriver({ company_id: _this.props.comId }).then(driverInfo => {
    if (driverInfo) {
      newData.dr_phone = setComVal(data.dr_phone, [driverInfo]).toJS();
      newData.dr_name = setComVal(data.dr_name, [driverInfo]).toJS();
      _this.context.getPick().setCardFormData(newData);
    }
  });
};
// 添加副驾司机
export const drAssistantNameIconClick = (inst, key, form, data) => {
  const newData = data;
  const _this = inst;
  addDriver({ company_id: _this.props.comId }).then(driverInfo => {
    if (driverInfo) {
      newData.dr_assistant_phone = setComVal(data.dr_assistant_phone, [driverInfo]).toJS();
      newData.dr_assistant_name = setComVal(data.dr_assistant_name, [driverInfo]).toJS();
      _this.context.getPick().setCardFormData(newData);
    }
  });
};
// 添加车头
export const trNumIconClick = (inst, key, form, data) => {
  const newData = data;
  const _this = inst;
  addTruck({ is_tr_header: 1, company_id: _this.props.comId }).then(trInfo => {
    if (trInfo && +trInfo.is_tr_header) {
      const { id } = trInfo; // eslint-disable-line
      newData.tr_num = setComVal(data.tr_num, [trInfo] || []);
      if (trInfo.dr_name && data.dr_name) {
        const drInfo = {
          id: trInfo.dr_com_id,
          name: trInfo.dr_name,
          tel: trInfo.dr_tel,
        };
        newData.dr_name = setComVal(data.dr_name, [drInfo]);
        if (data.dr_phone) {
          newData.dr_phone = setComVal(data.dr_phone, [drInfo]);
        }
      }
      _this.props.initPayee(newData, id, 'tr_num', [trInfo]);
      const pickOrder = _this.context.getPick();
      if (+trInfo.weight !== 0 || +trInfo.volume !== 0) {
        const rightTable = (pickOrder.biPickOrder ? pickOrder.biPickOrder.getTableData('right') : []) || [];
        const res = _this.getWeightVolume(rightTable || []);
        _this.resetRateValue(res, 0, 1, { trNum: [trInfo] });
      }
    }
  });
};
// 添加挂车
export const trNumExtIconClick = (inst, key, form, data) => {
  const newData = data;
  const _this = inst;
  addTruck({ is_tr_header: 0, company_id: _this.props.comId }).then(trInfo => {
    // 只有添加的是挂车的情况下，才可以将值赋给挂车
    if (trInfo && +trInfo.is_tr_header === 0) {
      newData.tr_num_ext = setComVal(data.tr_num_ext, [trInfo] || []);
      if (data.tr_ext_id) {
        newData.tr_ext_id = setComVal(data.tr_ext_id, [trInfo] || []);
      }
      const pickOrder = _this.context.getPick();
      pickOrder.setCardFormData(newData);
      if (+trInfo.weight !== 0 || +trInfo.volume !== 0) {
        const rightTable = (pickOrder.biPickOrder ? pickOrder.biPickOrder.getTableData('right') : []) || [];
        const res = _this.getWeightVolume(rightTable || []);
        _this.resetRateValue(res, 0, 1, { trNumExt: [trInfo] });
      }
    }
  });
};
// 车辆改变操作
export const trNumSelectChange = (inst, value, key, data, rightTable) => {
  const _this = inst;
  const res = _this.getWeightVolume(rightTable || []);
  _this.resetRateValue(res, 0, 1, { trNum: value });
  _this.props.initPayee && _this.props.initPayee(data, undefined, key, value);

  if (_this.cardExtData) {
    const val = (value && value[0]) || {};
    _this.cardExtData.tr_type = val.tr_type || '';
    _this.cardExtData.tr_length = val.tr_length || '';
    _this.cardExtData.tr_num = val.tr_num;
    _this.calcBatchPrice();
  }
};

// 挂车数据改变
export const trNumExtSelectChange = (inst, value, key, data, rightTable) => {
  const _this = inst;
  const res = _this.getWeightVolume(rightTable || []);
  _this.resetRateValue(res, 0, 1, { trNumExt: value });
  if (_this.cardExtData) {
    const val = (value && value[0]) || {};
    _this.cardExtData.tr_num_ext = val.tr_num;
    _this.calcBatchPrice();
  }
};

export const shouldWeightInputChange = (inst, value, ...rest) => {
  const _this = inst;
  const res = _this.getWeightVolume(rest[1].tableInfo.list || []);
  _this.resetRateValue(res, 0, 1, { should_weight: value });
};
export const shouldVolumeInputChange = (inst, value, ...rest) => {
  const _this = inst;
  const res = _this.getWeightVolume(rest[1].tableInfo.list || []);
  _this.resetRateValue(res, 0, 1, { should_volume: value });
};
/* 从左侧表格 移动到  右侧表格时，更改车辆配载率, 跟新司机代收 */
export const handleLeftToRightCallback = (inst, changedRightList, newLeftList, newRightList) => {
  const _this = inst;
  const res = _this.getWeightVolume(changedRightList);
  _this.resetRateValue(res, 1, 1);
};
export const handleRightToLeftCallback = (inst, changedLeftList, newLeftList, newRightList) => {
  const _this = inst;
  const res = _this.getWeightVolume(changedLeftList);
  _this.resetRateValue(res, 1, -1);
};
// 计算右屏中运单的转换后的重量和体积值
export const getWeightVolume = (inst, tableList) => {
  let totalWeight = 0;
  let totalVolume = 0;
  const _this = inst;
  const pickOrder = _this.context.getPick();
  if (!pickOrder) return { weight: totalWeight, volume: totalVolume };
  if (tableList.length === 0) return { weight: totalWeight, volume: totalVolume };
  const { left } = pickOrder.state.tableList;
  const extData = left.ext_data || {};
  const weightUnit = extData.weight_unit || 'KG';
  tableList.forEach(item => {
    let changedVolume = 0;
    let changedWeight = 0;
    let weight = +item[_this.state.weightKey] || 0;
    const volume = +item[_this.state.volumeKey] || 0;
    if (weightUnit === 'KG') {
      weight /= 1000;
    }
    changedVolume = _this.vRate !== 0 ? (volume * _this.wRate) / _this.vRate : 0;
    changedWeight = _this.wRate !== 0 ? (weight * _this.vRate) / _this.wRate : 0;
    const newWeigthValue = weight > changedVolume ? weight : changedVolume;
    const newVolumeValue = volume > changedWeight ? volume : changedWeight;
    totalWeight += parseFloat(newWeigthValue);
    totalVolume += parseFloat(newVolumeValue);
  });
  return { weight: totalWeight, volume: totalVolume };
};
// 配载率计算： 获取车辆的配载重量信息
export const getCarInfo = (inst, extCardData = {}) => {
  const _this = inst;
  const res = { weight: 0, volume: 0 };
  const pickOrder = _this.context.getPick();
  if (!pickOrder) return res;
  const cardInfo = pickOrder.cardForm ? pickOrder.cardForm.getStateData() : {};
  let trNum = getComVal(cardInfo.tr_num);
  let trNumExt = getComVal(cardInfo.tr_num_ext);
  const shouldWeight = getComVal(cardInfo.should_weight);
  const shouldVolume = getComVal(cardInfo.should_volume);
  // ext中的数据优先级高
  if (extCardData.trNum !== undefined) trNum = extCardData.trNum;
  if (extCardData.trNumExt !== undefined) trNumExt = extCardData.trNumExt;

  // 体积先取挂车的, 重量先取车头的
  res.weight =
    // eslint-disable-next-line no-nested-ternary
    trNum !== undefined && trNum !== '' && trNum.length && +trNum[0].weight
      ? trNum[0].weight
      : trNumExt !== undefined && trNumExt.length
      ? trNumExt[0].weight
      : 0;
  res.volume =
    // eslint-disable-next-line no-nested-ternary
    trNumExt !== undefined && trNumExt !== '' && trNumExt.length && +trNumExt[0].volume
      ? trNumExt[0].volume
      : trNum !== undefined && trNum.length
      ? trNum[0].volume
      : 0;

  // 取页面已计算的应装重体, 为空或为0则体积先取挂车的, 重量先取车头的
  const shouldObj = shouldWvFunc([{}], cardInfo, { tr_num: trNum, tr_num_ext: trNumExt });
  // 如果用户输入, 应装重体取输入值, 如果用户选择,  应装重体按照车牌和挂车计算
  res.should_weight =
    // eslint-disable-next-line no-nested-ternary
    +(extCardData.should_weight !== undefined
      ? extCardData.should_weight
      : extCardData.trNum || extCardData.trNumExt
      ? shouldObj[0].should_weight
      : shouldWeight) / 1000 || res.weight;
  res.should_volume =
    // eslint-disable-next-line no-nested-ternary
    +(extCardData.should_volume !== undefined
      ? extCardData.should_volume
      : extCardData.trNum || extCardData.trNumExt
      ? shouldObj[0].should_volume
      : shouldVolume) || res.volume;
  return res;
};
// 设置配载率, 获取车辆的载重信息, newValue为右屏合计重量和体积信息
export const resetRateValue = (inst, newValue, paraClear, paraSet, extCardData) => {
  const _this = inst;
  const carInfo = _this.getCarInfo(extCardData);
  const goodsInfo = newValue;
  let newWRate = 0;
  let newVRate = 0;
  const pickContent = _this.context.getPick();
  if (!pickContent) return;
  if (
    (Number(carInfo.should_weight) === 0 || carInfo.should_weight === undefined) &&
    Number(carInfo.should_volume) === 0 &&
    carInfo.should_volume === undefined
  ) {
    pickContent.biPickOrder &&
      pickContent.biPickOrder.setRateVal({
        wRateValue: 0,
        vRateValue: 0,
      });
    return;
  }
  if (Number(carInfo.should_weight) !== 0) {
    newWRate = (goodsInfo.weight / carInfo.should_weight) * 100 || 0;
  }
  if (Number(carInfo.should_volume) !== 0) {
    newVRate = (goodsInfo.volume / carInfo.should_volume) * 100 || 0;
  }
  const oldWRate = pickContent.biPickOrder ? pickContent.biPickOrder.state.wRateValue : 0;
  const oldVRate = pickContent.biPickOrder ? pickContent.biPickOrder.state.vRateValue : 0;
  const wRateValue = parseFloat(Number(oldWRate) * paraClear + paraSet * newWRate || 0).toFixed(2) || 0;
  const vRateValue = parseFloat(Number(oldVRate) * paraClear + paraSet * newVRate || 0).toFixed(2) || 0;
  pickContent.biPickOrder &&
    pickContent.biPickOrder.setRateVal(
      Object.assign(
        {},
        {
          wRateValue: +wRateValue <= 0 ? 0 : wRateValue,
          vRateValue: +vRateValue <= 0 ? 0 : vRateValue,
        },
      ),
    );
};
export default class TruckPickOrder extends BasicPickOrder {
  constructor(prop) {
    super(prop);
    this.state = { wRate: 1, vRate: 1 };
    this.setWeightToVolume();
  }
  static defaultProps = {
    wRateValue: 0,
    vRateValue: 0,
  };
  // 设置配载率
  setWeightToVolume = () => setWeightToVolume(this);
  drNameIconClick = (key, form, data) => drNameIconClick(this, key, form, data);
  drAssistantNameIconClick = (key, form, data) => drAssistantNameIconClick(this, key, form, data);
  trNumIconClick = (key, form, data) => trNumIconClick(this, key, form, data);
  trNumExtIconClick = (key, form, data) => trNumExtIconClick(this, key, form, data);
  trNumSelectChange = (value, key, data, rightTable) => trNumSelectChange(this, value, key, data, rightTable);
  shouldWeightInputChange = (value, ...rest) => shouldWeightInputChange(this, value, ...rest);
  shouldVolumeInputChange = (value, ...rest) => shouldVolumeInputChange(this, value, ...rest);
  trNumExtSelectChange = (value, key, data, rightTable) => trNumExtSelectChange(this, value, key, data, rightTable);
  handleLeftToRightCallback = (changedRightList, newLeftList, newRightList) =>
    handleLeftToRightCallback(this, changedRightList, newLeftList, newRightList);
  handleRightToLeftCallback = (changedLeftList, newLeftList, newRightList) =>
    handleRightToLeftCallback(this, changedLeftList, newLeftList, newRightList);
  getWeightVolume = tableList => getWeightVolume(this, tableList);
  getCarInfo = (extCardData = {}) => getCarInfo(this, extCardData);
  resetRateValue = (newValue, paraClear, paraSet, extCardData) =>
    resetRateValue(this, newValue, paraClear, paraSet, extCardData);
}
