module.exports = {
  typeCheckBox: {
    pickup_f: {
      type: 'text',
      text: '已付',
      elem: 'pickup_f_paid',
    },
    od_delivery_f: {
      type: 'text',
      text: '已付',
      elem: 'od_delivery_f_paid',
    },
  },
  tabToTypeCheckBox: {
    // 左右挑单的时候  按tab设置checkbox
    pickup_batch: {
      pickup_f: {
        elem: 'pickup_f_paid',
        text: '已付',
        valueType: 'text',
      },
    },
    pickup_reservation_batch: {
      pickup_f: {
        elem: 'pickup_f_paid',
        text: '已付',
        valueType: 'text',
      },
    },
    pickup_reservation: {
      pickup_f: {
        elem: 'pickup_f_paid',
        text: '已付',
        valueType: 'text',
      },
    },
    delivery_batch: {
      od_delivery_f: {
        elem: 'od_delivery_f_paid',
        text: '已付',
        valueType: 'text',
      },
    },
    default: {
      // 订单管理
      pickup_f: {
        elem: 'pickup_f_paid',
        text: '已付',
        valueType: 'text',
      },
    },
  },
};
