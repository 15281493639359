import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ot, showInfo } from 'utils';
import { ModalDialog, Button, Table } from 'components';
import { FLOAT_P, NUMBER, ERROR } from 'constants';
import initShortcuts from 'utils/business/order/initShortcuts';
import { prefixCls } from './index.scss';

export default class VolumeEditModal extends PureComponent {
  static propTypes = {
    data: PropTypes.array,
    close: PropTypes.func,
    isView: PropTypes.bool,
    onConfirm: PropTypes.func,
    handleHideCallback: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const inputHeader = {
      type: props.isView ? 'Text' : 'PureInput',
      display: 'show',
      align: 'right',
      width: 90,
      isResizable: false,
      summable: true,
      pattern: FLOAT_P,
      required: true,
    };
    this.header = {
      l: { ...inputHeader, title: ot('长(cm)') },
      w: { ...inputHeader, title: ot('宽(cm)') },
      h: { ...inputHeader, title: ot('高(cm)') },
      n: { ...inputHeader, title: ot('件数'), pattern: NUMBER },
      v: {
        ...inputHeader,
        title: ot('体积(方)'),
        type: 'Text',
        format: { round: 2, zero2null: true, trim_zero: true },
      },
    };
    this.state = { data: props.data && props.data.length ? props.data : [{}] };
  }

  componentDidMount() {
    setTimeout(() => {
      this.focus(0, 'l', 0);
      this.table._updateTotalRow();
    }, 100);
    !this.props.isView && (this.unbindShortcuts = initShortcuts({ save_volume_detail: 'f4' }, this.footer));
  }

  componentWillUnmount() {
    this.unbindShortcuts && this.unbindShortcuts();
  }

  onChange = (index, key, value) => {
    const data = [...this.state.data];
    const row = { ...data[index] };
    row[key] = value;
    row.v = +((row.l * row.w * row.h * row.n) / 1000000 || 0).toFixed(2);
    data[index] = row;
    this.setState({ data }, () => this.table._updateTotalRow());
  };

  onKeyDown = (index, key, e) => {
    if (e.keyCode === 13) {
      if (!e.target.value || Number.isNaN(+e.target.value)) {
        const el = e.target;
        el.blur();
        setTimeout(() => el.focus(), 0);
      } else if (key === 'n') {
        if (index === this.state.data.length - 1) {
          e.target.blur();
          setTimeout(this.handleTodoAdd, 100);
        } else {
          this.table.scrollToRow(index + 1, () => this.focus(index + 1, 'l', 0));
        }
      } else {
        const colIndex = Object.keys(this.header).indexOf(key) + 1;
        this.focus(index, Object.keys(this.header)[colIndex], colIndex);
      }
    }
  };

  focus = (index, key, colIndex) => {
    const el = this.wrap.querySelector(`[data-path="${key}_${index}_${colIndex + 2}"]`);
    el && el.focus();
  };

  handleTodoAdd = () => {
    const data = [...this.state.data];
    if (data.length >= 100) {
      showInfo(ERROR, '最多只能输入100行！');
      return;
    }
    data.push({});
    this.setState({ data }, () => {
      this.table.scrollToRow(data.length - 1, () => this.focus(data.length - 1, 'l', 0));
    });
  };

  handleTodoSubtract = index => {
    const data = [...this.state.data];
    data.splice(index, 1);
    this.setState({ data }, () => this.table._updateTotalRow());
  };

  confirm = () => {
    const keys = Object.keys(this.header);
    this.modal.handleHide();
    const data = this.state.data.filter(item => keys.some(key => item[key]));
    this.props.onConfirm(
      data.map(item => {
        const newRow = {};
        keys.forEach(key => (newRow[key] = item[key]));
        return newRow;
      }),
    );
  };

  render() {
    const { data } = this.state;
    const { isView, close, handleHideCallback } = this.props;
    const content = (
      <div className={prefixCls} ref={r => (this.wrap = r)}>
        <div>
          <div className="tip">
            提示：1、输入后，可点击回车键直接跳转到下一个输入框 或 新增一行。 <br />
            <span style={{ paddingLeft: '39px' }} /> 2、体积最多保留两位小数，小于0.01方的按0处理。
          </div>
          <div className={`table-wrap${data.length === 1 ? ' one-row' : ''}`}>
            <Table
              refGetter={r => (this.table = r)}
              rowHeight={27}
              isShowTotalRow
              isShowFilterRow
              isShowSubstract={!isView}
              isOrderNumberColumn
              ifShowFilterRowIcon
              handleTodoAdd={this.handleTodoAdd}
              handleTodoSubtract={this.handleTodoSubtract}
              data={data}
              header={this.header}
              handleChange={this.onChange}
              handleKeyDown={this.onKeyDown}
              onlyViewportColumn={false}
            />
          </div>
        </div>
      </div>
    );

    const bottom = (
      <div ref={r => (this.footer = r)}>
        {!isView && (
          <Button type="primary" data-path="save_volume_detail" onClick={this.confirm}>
            确定(F4)
          </Button>
        )}
        <Button onClick={() => this.modal.handleHide()}>取消(ESC)</Button>
      </div>
    );

    return (
      <ModalDialog
        ref={r => (this.modal = r)}
        content={content}
        bottom={bottom}
        isShow
        isModal
        close={close}
        handleHideCallback={handleHideCallback}
        title="体积计算器"
        contentStyle={{ width: isView ? '545px' : '587px', padding: '20px' }}
      />
    );
  }
}
