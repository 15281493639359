/**
 * Create by lby
 * Time on 2020-07-17
 * Describe: 电子围栏
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { SlideDrager, Icon, Button, ButtonGroup, PureInput as Input, Amap } from 'components';
import { ERROR, WARN, NUMBER_NO_ZERO } from 'constants';
import Popover from 'components/utilcomponents/tips/Tooltip/popover';
import {
  showInfo,
  hideTip,
  setMarker,
  drawCircle,
  drawPolygon,
  confirm,
  validateFieldsOf,
  formatFenceData,
} from 'utils';

import { MARKER, CIRCLE, POLYGON, FENCE, EXTRA, REFER_SHAPE, FROMINPUT, polygonStyle, circleStyle } from './contants';
import { calcPolygonExtra, regeocoder, lngLatToArr } from './tools';
import { prefixCls } from './index.scss';

export default class Fence extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    slideTitle: PropTypes.string,
    markerDraggable: PropTypes.bool, // 标记点是否可拖动
    markerPoi: PropTypes.string, // 标记点的经纬度
    data: PropTypes.array, // 地址
    close: PropTypes.func,
    togglePopWidth: PropTypes.func,
    popContainer: PropTypes.object,
    submitCallback: PropTypes.func, // 确定的回调函数
    noVerifyMakerPoi: PropTypes.bool, // 不校验中心点是否在图形内
  };

  static defaultProps = {
    slideTitle: '设置电子围栏',
    markerDraggable: true,
    noVerifyMakerPoi: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      shape: CIRCLE, // 围栏形状
      extra: '', // 外延长度
      address_poi: props.markerPoi, // 地址坐标
      // 圆
      circle_center: props.markerPoi, // 圆心
      radius: '', // 围栏半径
      // 多边形
      zoom: '', // 绘制多边形时的地图缩放比例值
      scale: '', // 绘制多边形时的地图的比例尺
      // eslint-disable-next-line react/no-unused-state
      area: '', // 区域
      paths: [], // 路径
      // eslint-disable-next-line react/no-unused-state
      extraArea: '', // 外延区域
      extraPaths: [], // 外延路径
    };
    this.oldRadius = '';
    this.oldExtra = '';
    this.marker = null; // 地址标记点
    this.fence = null; // 小围栏
    this.extra = null; // 外延围栏
    this.polygon = null; // 多边形参数
    this.polyEditor = null; // 多边形可编辑状态下
  }

  UNSAFE_componentWillMount() {
    this.initData();
  }

  componentDidMount() {
    // if (this.radiusInput) {
    //   this.radiusInput.focus();
    // }
  }

  initData = () => {
    const { data } = this.props;
    const obj = formatFenceData(data);
    this.oldRadius = obj.radius || '';
    this.oldExtra = obj.extra || '';
    Object.keys(obj).length && this.setState({ ...obj });
  };

  handleHide = () => this.fenceRef.handleHide();

  handleSubmit = async () => {
    if (!(await validateFieldsOf(this.wrap))) return false;

    // 圆形
    const { circle_center, radius } = this.state;
    // 多边形
    const { paths, extraPaths } = this.state;
    // 其他参数
    const { shape, extra, address_poi } = this.state;

    const { data, markerPoi } = this.props;

    let params = {};
    // 标记点
    if (address_poi !== markerPoi) {
      const address = await regeocoder(this.map, address_poi.split(','));
      params = { ...params, poi: address_poi, address };
    } else {
      params = { ...params, poi: address_poi };
    }

    // ========================= 围栏 =========================
    const propsFenceList = data.filter(item => +item.fence_type === 1);
    const propsFenceObj = propsFenceList.length ? propsFenceList[0] : {};
    // ========================= 外延 =========================
    const propsExtraList = data.filter(item => +item.fence_type === 2);
    const propsExtraObj = propsExtraList.length ? propsExtraList[0] : {};

    /**
     * @param {*} fence_type 类型 number  1 -> 围栏; 2 -> 外延;
     * @param {*} graphtype 区域类型 number 1 -> 圆形; 2 -> 多边形;
     * @param {*} radius 圆形半径 string
     * @param {*} latlngs 路径 string (多边形：端点和起点首尾相接; 圆形: 圆心坐标)
     * @param {*} center_point 中心点坐标 string (圆形: 圆心坐标; 多边形: 无)
     * @param {*} extra_length 外延长度 string
     */
    let fenceObj = {};
    let extraObj = {};
    // =================== 圆形 ===================
    if (shape === CIRCLE) {
      if (radius) {
        // 围栏
        fenceObj = {
          graphtype: 1,
          fence_type: 1,
          latlngs: circle_center,
          radius,
          center_point: circle_center,
          extra_length: extra || '0',
        };
      }
      if (extra && +extra) {
        // 外延
        extraObj = {
          graphtype: 1,
          fence_type: 2,
          latlngs: circle_center,
          radius: `${+radius + +extra}`,
          center_point: circle_center,
          extra_length: '0',
        };
      }
    } else if (shape === POLYGON) {
      // =================== 多边形 ===================
      const polygonLngLats = polygonPath => {
        const newPath = [...polygonPath, polygonPath[0]];
        const lngLat = newPath.map(item => item.join(','));
        return lngLat.join(';');
      };
      if (paths && paths.length) {
        // 围栏
        fenceObj = {
          graphtype: 2,
          fence_type: 1,
          latlngs: polygonLngLats(paths),
          radius: '0',
          center_point: '',
          extra_length: extra || '0',
        };
      }
      if (extra && +extra && extraPaths && extraPaths.length) {
        // 外延
        extraObj = {
          graphtype: 2,
          fence_type: 2,
          latlngs: polygonLngLats(extraPaths),
          radius: '0',
          center_point: '',
          extra_length: '0',
        };
      }
    }

    let fence = [];
    if (Object.keys(fenceObj).length) fence = [...fence, { ...propsFenceObj, ...fenceObj }];
    if (Object.keys(extraObj).length) fence = [...fence, { ...propsExtraObj, ...extraObj }];
    params = { ...params, fence };
    this.props.submitCallback && this.props.submitCallback(params);
    this.handleHide();
  };

  showTip = (left1, top1) => {
    const { left, top } = this.wrap.getBoundingClientRect();
    Popover.show({
      targetRect: { left: left + left1, top: top + top1, width: 0, height: 0 },
      content: this.state.drawTip,
      dir: 'right',
    });
  };

  hideTip = () => hideTip();

  removeFence = () => {
    // 清除围栏
    if (this.fence) {
      this.map.remove(this.fence);
      delete this.fence;
    }
  };
  removeExtra = () => {
    // 清除外延
    if (this.extra) {
      this.map.remove(this.extra);
      delete this.extra;
    }
  };
  removeRolyEditor = () => {
    // 清除多边形的编辑功能
    if (this.polyEditor) {
      this.polyEditor.close();
      this.map.remove(this.polyEditor);
      delete this.polyEditor;
    }
  };

  onMapLoaded = map => {
    this.map = map;

    // 添加比例尺
    const plugins = ['AMap.Scale'];
    map.plugin(plugins, () => {
      map.addControl(new window.AMap.Scale());
    });

    const { data } = this.props;
    // 其他参数
    const { shape, extra, address_poi } = this.state;
    // 圆形
    const { radius, referShapeRadius } = this.state;
    // 多边形
    const { paths, extraPaths, referShapePaths } = this.state;
    this.setMarker(address_poi);

    referShapeRadius && this.setCircle(referShapeRadius, REFER_SHAPE);
    referShapePaths?.length && this.setPolygon(referShapePaths, REFER_SHAPE);

    if (data && data.length) {
      // 修改
      if (shape === CIRCLE) {
        // 圆
        if (+radius) {
          // 围栏
          this.setCircle(radius);
        }
        if (+extra) {
          // 外延
          this.setCircle(extra, EXTRA);
        }
      } else if (shape === POLYGON) {
        // 多边形
        if (paths && paths.length) {
          // 围栏
          this.setPolygon(paths);
        }
        if (+extra && extraPaths && extraPaths.length) {
          // 外延
          this.setPolygon(extraPaths, EXTRA);
        }
      }
    }
  };

  // 设地址Marker
  setMarker = poi => {
    const { map } = this;
    if (this.marker) map.remove(this.marker);

    const { markerDraggable } = this.props;

    const position = poi.split(',');
    const params = {
      draggable: markerDraggable,
      icon: new window.AMap.Icon({
        image: require('images/map/marker/addressMarker.png'),
        size: new window.AMap.Size(19, 27),
        imageSize: new window.AMap.Size(19, 27),
      }),
      offset: new window.AMap.Pixel(-10, -25),
      zIndex: 50,
    };

    this.marker = setMarker(this.map, position, params);

    this.marker.on('mouseup', e => {
      const movePoi = `${e.lnglat.getLng()},${e.lnglat.getLat()}`;
      this.validateInFence({ poi: movePoi });
    });
  };

  // 校验坐标点是否在围栏区域内
  // param: 参数 => poi: 经纬度('lng,lat'); center: 圆心坐标; polygon: 多边形参数; from: 从哪里触发的弹框
  // type: 操作的类型  marker: 标记点; circle: 圆; polygon: 多边形;
  validateInFence = async (param, type = MARKER) => {
    const { poi, center, area: polygonArea, paths: polygonPath, zoom: polygonZoom, scale: polygonScale, from } = param;
    const { address_poi, radius, extra, paths, zoom, scale } = this.state;
    // 未设置围栏
    if (!this.fence) {
      if (type === MARKER) {
        this.setState({ address_poi: poi, circle_center: poi });
      }
    } else {
      const pois = (poi || address_poi).split(',');
      const lngLat = new window.AMap.LngLat(pois[0], pois[1]);
      if (!this.fence.contains(lngLat)) {
        if (
          this.props.noVerifyMakerPoi ||
          (await confirm(WARN, '当前围栏区域已偏离地址坐标，请确认是否继续', {}, '操作确认'))
        ) {
          // 点击确定
          switch (type) {
            case MARKER: // 标记点
              this.setState({ address_poi: poi });
              break;
            case CIRCLE: // 圆
              this.setState({ circle_center: center });
              if (extra && +extra) {
                this.setCircle(extra, EXTRA, { circle_center: center });
              } else {
                this.removeExtra();
              }
              break;
            case POLYGON: // 多边形
              // eslint-disable-next-line react/no-unused-state
              this.setState({ area: polygonArea, paths: polygonPath, zoom: polygonZoom, scale: polygonScale });
              if (extra && +extra) {
                const calcPaths = calcPolygonExtra(this.map, polygonZoom, polygonScale, polygonPath, +extra);
                this.setPolygon(calcPaths, EXTRA);
              } else {
                this.removeExtra();
              }
              break;
            default:
              break;
          }
        } else {
          // 点击取消     需要回退到上次操作
          switch (type) {
            case MARKER: // 标记点
              this.setMarker(address_poi);
              break;
            case CIRCLE:
              // eslint-disable-next-line no-case-declarations
              let circleRadius = radius;
              if (from === FROMINPUT) {
                circleRadius = this.oldRadius;
                this.setState({ radius: this.oldRadius, extra: this.oldExtra });
              }
              this.setCircle(circleRadius);
              if (extra && +extra) {
                this.setCircle(extra, EXTRA);
              } else {
                this.removeExtra();
              }
              break;
            case POLYGON: // 多边形
              this.removeRolyEditor();
              if (from === FROMINPUT) {
                this.polygonClick();
              } else {
                this.setPolygon(paths);
                if (extra && +extra) {
                  const calcPaths = calcPolygonExtra(
                    this.map,
                    zoom || polygonZoom,
                    scale || polygonScale,
                    paths,
                    +extra,
                  );
                  this.setPolygon(calcPaths, EXTRA);
                } else {
                  this.removeExtra();
                }
              }
              break;
            default:
              break;
          }
        }
      } else {
        // 允许的区域内
        switch (type) {
          case MARKER: // 标记点
            this.setState({ address_poi: poi });
            break;
          case CIRCLE: // 圆
            this.setState({ circle_center: center });
            if (extra && +extra) {
              this.setCircle(extra, EXTRA, { circleCenter: center });
            } else {
              this.removeExtra();
            }
            break;
          case POLYGON: // 多边形
            // eslint-disable-next-line react/no-unused-state
            this.setState({ area: polygonArea, paths: polygonPath, zoom: polygonZoom, scale: polygonScale });
            if (extra && +extra) {
              const calcPaths = calcPolygonExtra(this.map, polygonZoom, polygonScale, polygonPath, +extra);
              this.setPolygon(calcPaths, EXTRA);
            } else {
              this.removeExtra();
            }
            break;
          default:
            break;
        }
      }
    }
  };

  // 添加圆形区域 点击
  circleClick = () => {
    this.removeFence();
    this.removeExtra();
    this.removeRolyEditor();
    this.polygonRightClick();
    this.setState({ shape: CIRCLE, radius: '', extra: '' });
  };

  // 添加多边形区域 点击 -> (开始绘制多边形)
  polygonClick = () => {
    const { map } = this;
    const plugins = ['AMap.MouseTool'];
    map.plugin(plugins, () => {
      this.mouseTool = new window.AMap.MouseTool(this.map);
      window.AMap.event.addListener(this.mouseTool, 'draw', this.onDraw);
    });
    this.removeFence();
    this.removeExtra();
    this.removeRolyEditor();
    this.setState({ shape: POLYGON, radius: '', extra: '' });
    map.setDefaultCursor('crosshair');
    // 绘制的多边形的参数
    this.mouseTool && this.mouseTool.close(true);
    if (this.mouseTool && this.mouseTool[POLYGON]) {
      this.mouseTool[POLYGON](polygonStyle);
    }
    this.point = 0;
    map.setFitView();

    // 在地图上绘制(多边形)的tips提示文案
    // const polygonTip1 = '点击开始绘制,单击鼠标右键取消绘制。'
    const polygonTip1 = '请在地图上通过鼠标点击画定一个区域。';
    const polygonTip2 = '点击继续绘制,单击鼠标右键取消绘制。';
    const polygonTip3 = '单击继续绘制，双击完成绘制,单击鼠标右键取消绘制。';

    map.on('mousemove', e => {
      if (this.state.shape === POLYGON && Number.isFinite(this.point)) {
        if (this.point === 0 && this.state.drawTip !== polygonTip1) {
          this.setState({ drawTip: polygonTip1 });
        }
        if (this.point === 1 && this.state.drawTip !== polygonTip2) {
          this.setState({ drawTip: polygonTip2 });
        }
        if (this.point > 1 && this.state.drawTip !== polygonTip3) {
          this.setState({ drawTip: polygonTip3 });
        }
        this.showTip(e.pixel.getX() + 10, e.pixel.getY());
      }
    });
    map.on('mouseup', e => {
      if (this.state.shape === POLYGON && Number.isFinite(this.point)) {
        this.point++;
      }
    });
    map.on('mouseout', this.hideTip);
    map.on('rightclick', () => {
      this.cancelDraw = true;
      this.polygonRightClick();
    });
  };

  // 多边形的右键点击
  polygonRightClick = () => {
    if (Number.isFinite(this.point) && this.mouseTool) this.mouseTool.close(true);
    this.map.setDefaultCursor('pointer');
    this.hideTip();
    this.point = null;
  };

  // 绘制多边形围栏 完成(取消) 处理
  onDraw = e => {
    this.mouseTool.close(true);
    this.map.setDefaultCursor('pointer');
    this.hideTip();
    this.fence = e.obj;
    const onClose = () => this.removeFence();

    const path = e.obj.getPath();
    this.polygon = {
      area: +e.obj.getArea().toFixed(3),
      paths: path.map(lngLatToArr),
    };

    setTimeout(() => {
      this.point = null;
      if (this.cancelDraw) {
        // 取消绘制
        this.cancelDraw = false;
        onClose();
      } else {
        onClose();
        this.setPolygon(this.polygon.paths, FENCE, FROMINPUT);
      }
    }, 50);
  };

  // 绘制圆 (围栏 + 外延)
  // type: fence -> 小围栏; extra -> 外延
  // circleCenter: 圆心坐标
  // from: inputBlur -> 围栏半径输入框修改;
  setCircle = (value, type = FENCE, ext = {}) => {
    const { circleCenter, from } = ext;
    const { radius, circle_center } = this.state;
    const center = (circleCenter || circle_center).split(',');
    let params = {
      radius: value,
      ...circleStyle,
      ...this.props.circleStyle,
    };
    if (type === FENCE) {
      // 围栏半径
      this.removeFence();
      this.removeExtra();
    } else if (type === EXTRA) {
      // 外延长度
      params = {
        ...params,
        radius: +radius + +value,
        strokeColor: '#D60D0D',
        fillColor: '#F12222',
        fillOpacity: 0.1,
        strokeWeight: 1.44,
        draggable: false,
        zIndex: 25,
      };
      this.removeExtra();
    } else if (type === REFER_SHAPE) {
      // 外延长度
      params = {
        ...params,
        radius: +value,
        strokeColor: '#D60D0D',
        fillColor: '#F12222',
        fillOpacity: 0.1,
        strokeWeight: 1.44,
        draggable: false,
        zIndex: 25,
      };
    }

    const circle = drawCircle(this.map, center, params);
    this[type] = circle;

    if (type === FENCE) {
      // 画的围栏
      if (from === FROMINPUT) this.validateInFence({ center: center.join(','), from }, CIRCLE);
      // 平移圆形围栏 事件
      this.fence.on('mouseup', mouseup => {
        const moveCircle = mouseup.target;
        const moveCenter = moveCircle.getCenter();
        this.validateInFence({ center: lngLatToArr(moveCenter).join(',') }, CIRCLE);
      });
    }
  };

  // 绘制多边形 (围栏 + 外延)
  // type: fence -> 小围栏; extra -> 外延
  // from: inputBlur -> 围栏半径输入框修改;
  setPolygon = (paths, type = FENCE, from = '') => {
    if (!paths || !paths.length) return null;
    let params = { ...polygonStyle };
    if (type === FENCE) {
      // 围栏半径
      this.removeFence();
      this.removeExtra();
    } else if (type === EXTRA) {
      // 外延长度
      params = {
        ...params,
        isPolyEditor: false,
        strokeColor: '#D60D0D',
        fillColor: '#F12222',
        fillOpacity: 0.1,
        strokeWeight: 1.44,
        draggable: false,
        zIndex: 25,
      };
      this.removeExtra();
    } else if (type === REFER_SHAPE) {
      params = {
        ...params,
        isPolyEditor: false,
        strokeColor: '#D60D0D',
        fillColor: '#F12222',
        fillOpacity: 0.1,
        strokeWeight: 1.44,
        draggable: false,
        zIndex: 25,
      };
    }

    const { polygon, polyEditor } = drawPolygon(this.map, paths, params);
    this[type] = polygon;
    if (polyEditor) this.polyEditor = polyEditor;

    const polygonArea = +polygon.getArea().toFixed(3);
    const polygonPaths = polygon.getPath().map(lngLatToArr);

    // 缩放地图到合适的视野级别
    this.map.setFitView();

    if (type === FENCE) {
      if (from === FROMINPUT) {
        const zoom = this.map.getZoom();
        const scale = this.map.getScale(); // 获取当前地图比例尺。表示当前屏幕距离一米代表实际距离多少米
        // this.setState({ zoom, scale })
        this.validateInFence({ area: polygonArea, paths: polygonPaths, zoom, scale, from }, POLYGON);
      } else {
        // eslint-disable-next-line react/no-unused-state
        this.setState({ area: polygonArea, paths: polygonPaths });
      }
      const polygonEvent = event => {
        const zoom = this.map.getZoom();
        const scale = this.map.getScale(); // 获取当前地图比例尺。表示当前屏幕距离一米代表实际距离多少米
        const polygonObj = event.target;
        const polygonPath = polygonObj.getPath();
        const obj = {
          area: +polygonObj.getArea().toFixed(3),
          paths: polygonPath.map(lngLatToArr),
          zoom: this.state.zoom || zoom,
          scale: this.state.scale || scale,
        };
        this.polygon = obj;
        this.validateInFence({ ...obj }, POLYGON);
        // const calcPaths = calcPolygonExtra(this.map, zoom, scale, obj.paths, +extra)
        // this.setPolygon(calcPaths, EXTRA)
      };
      // 调整 多边形某个顶点位置的事件
      // 添加 多边形上增加一个顶点的事件
      polyEditor.on('adjust', adjust => {
        polygonEvent(adjust);
      });

      // polyEditor.on('addnode', (addnode) => {
      //   polygonEvent(addnode)
      // })
      // 平移多边形围栏 事件
      this.fence.on('mouseup', mouseup => {
        const zoom = this.map.getZoom();
        const scale = this.map.getScale(); // 获取当前地图比例尺。表示当前屏幕距离一米代表实际距离多少米
        const movePolygon = mouseup.target;
        const polygonPath = movePolygon.getPath();
        const obj = {
          area: +movePolygon.getArea().toFixed(3),
          paths: polygonPath.map(lngLatToArr),
          zoom: this.state.zoom || zoom,
          scale: this.state.scale || scale,
        };
        this.polygon = obj;
        this.validateInFence({ ...obj }, POLYGON);
      });
    } else if (type === EXTRA) {
      // eslint-disable-next-line react/no-unused-state
      this.setState({ extraArea: polygonArea, extraPaths: polygonPaths });
    }
  };

  // 围栏半径焦点移开事件
  radiusBlur = e => {
    const { extra, referShapeRadius } = this.state;
    const { value } = e.target;
    if (!value) {
      showInfo(ERROR, '请输入围栏半径，地图将自动显示围栏！');
      return false;
    }

    if (typeof this.props.radiusValidate === 'function' && !this.props.radiusValidate(value)) return;

    if (+value && Number.isFinite(+value)) {
      this.setCircle(+value, FENCE, { from: FROMINPUT });
      // 如果有外延，重新绘制圆形的外延
      if (extra && +extra) {
        this.setCircle(+extra, EXTRA, { from: FROMINPUT });
      }
    }
  };

  // 外延长度焦点移开事件
  extraBlur = e => {
    const { shape, paths, zoom, scale } = this.state;
    const { value } = e.target;
    if (Number.isFinite(+value)) {
      if (shape === CIRCLE) {
        // 圆
        this.setCircle(+value, EXTRA, { from: FROMINPUT });
      } else if (shape === POLYGON) {
        // 多边形
        if (this.fence) {
          const calcPaths = calcPolygonExtra(this.map, zoom, scale, paths, +value);
          this.setPolygon(calcPaths, EXTRA, FROMINPUT);
        }
      }
    }
  };

  radiusValidity = val => (!+val ? '请输入围栏半径，地图将自动显示围栏' : '');

  renderContent = () => {
    const { className } = this.props;
    const { radius, shape, extra } = this.state;

    const classes = classnames({
      [className]: className,
      [prefixCls]: true,
    });

    return (
      <div className={classes} ref={this.refWrap}>
        <Amap.MapShow width="100%" height="100%" onMapLoaded={this.onMapLoaded} />
        <div className="tool-bar">
          <div className="tool-bar-item">
            <label className="fn-label">围栏形状</label>
            {/* ================== 圆形 ================== */}
            <div className={`fence-shape${shape === CIRCLE ? ' checked' : ''}`} onClick={() => this.circleClick()}>
              <Icon iconType="icon-round" classname="fence-shape-icon" />
              <label className="fence-shape-text">添加圆形区域</label>
            </div>
            {/* ================== 多边形 ================== */}
            <div className={`fence-shape${shape === POLYGON ? ' checked' : ''}`} onClick={() => this.polygonClick()}>
              <Icon iconType="icon-polygon" classname="fence-shape-icon" />
              <label className="fence-shape-text">添加多边形区域</label>
            </div>
          </div>
          {shape === CIRCLE && (
            <div className="tool-bar-item">
              <label className="fn-label red">围栏半径</label>
              <Input
                ref={r => (this.radiusInput = r)}
                // required
                value={radius}
                customValidity={this.radiusValidity}
                onFocus={e => (this.oldRadius = e.target.value)}
                onChange={e => this.setState({ radius: e.target.value })}
                onBlur={this.radiusBlur}
                pattern={NUMBER_NO_ZERO}
              >
                <label className="unit">m</label>
              </Input>
            </div>
          )}
          {/* <div className="tool-bar-item">
            <label className="fn-label">外延长度</label>
            <Input
              // ref={r => this.extraInput = r}
              value={extra}
              onFocus={e => (this.oldExtra = e.target.value)}
              onChange={e => this.setState({ extra: e.target.value })}
              onBlur={this.extraBlur}
              pattern={NUMBER}
            >
              <label className="unit">m</label>
            </Input>
            {shape === POLYGON && (
              <Icon iconType="icon-help" tipsCls="set-help" tips="外延长度，是指从多边形各点垂直向外延展的长度" />
            )}
          </div> */}
        </div>
      </div>
    );
  };

  renderFooter = () => {
    console.log('fence-footer');
    return (
      <ButtonGroup>
        <Button onClick={this.handleSubmit} type="primary">
          确定
        </Button>
        <Button onClick={this.handleHide}>取消</Button>
      </ButtonGroup>
    );
  };

  refWrap = r => (this.wrap = r);
  sliderWrap = ref => (this.fenceRef = ref);

  render() {
    const { slideTitle, close, popContainer, togglePopWidth } = this.props;
    return (
      <SlideDrager
        className="set-fence-slide"
        isShow
        isBiggest
        isHasBiggest={false}
        close={close}
        ref={this.sliderWrap}
        HeaderTitle={slideTitle}
        contentDiv={this.renderContent()}
        footerDiv={this.renderFooter()}
        dragerContainer={popContainer}
        togglePopWidth={togglePopWidth}
      />
    );
  }
}
