/**
 * Created by wangnaihe on 2018/4/18.
 */

import { asyncComponent } from 'utils';

const dynamicImportSettlePropaganda = () => import(/* webpackChunkName: "Carry-settlePropaganda" */ './index');

export default dynamicImportSettlePropaganda;

export const AsyncSettlePropaganda = asyncComponent(dynamicImportSettlePropaganda);
