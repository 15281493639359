import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { showTip, hideTip } from 'utils/buildTip';
import Popover from '../../utilcomponents/tips/Tooltip/popover';
import PriceSnapshot from '../PriceSnapshot';
import { prefixCls } from './index.scss';

export default class PriceSnapshotIcon extends PureComponent {
  static propTypes = {
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]), // 快照数据
    className: PropTypes.string,
    fee: PropTypes.string, // 费用key 如 co_freight
    bizID: PropTypes.string, // 运单 od_basic_id 批次,任务 batch_id
    type: PropTypes.string, // 运单：yd 批次：pc 任务：rw
  };

  showSnapshot = e => {
    if (this.showingSnapshot) {
      hideTip();
      return;
    }
    const { className, ...rest } = this.props;
    const content = <PriceSnapshot {...rest} onComponentUpdate={Popover.update} />;
    const onClose = () => (this.showingSnapshot = false);
    showTip(e.target, { content, type: 'info', className: 'popover-price-snapshot', triggerType: 'click', onClose });
    this.showingSnapshot = true;
  };

  showTip = e => !this.showingSnapshot && showTip(e.target, { content: '点击查看计价规则' });

  hideTip = () => !this.showingSnapshot && hideTip();

  render() {
    const { className, data, bizID } = this.props;
    if (!data && !bizID) return null;
    const classes = classnames('fn-icon', 'fn-icon-ziyuan', { [className]: className }, prefixCls);
    return (
      <i className={classes} onMouseEnter={this.showTip} onMouseLeave={this.hideTip} onClick={this.showSnapshot} />
    );
  }
}
