import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import { Select, Button, ModalDialog, PureInput as Input, TextArea, Label } from 'components';
import { fetchApi, postOption, showInfo, fetchJAVA, validateFieldsOf } from 'utils';
import { Switch } from '@arco-design/web-react';
import { FLOAT_I_9_P_2_NO_0, ERROR, CHECK } from 'constants';
import './index.scss';

// 付款网点数据kye
const toCompanyListKey = 'toCompanyList';
// 收款网点数据key
const fromCompanyListKey = 'fromCompanyList';
const { company_id } = window;
// 收款网点默认网点key
const toDefulatCompanyKey = `transfer-to-${company_id}`;
// 付款网点默认网点key
const fromDefulatCompanyKey = `transfer-from-${company_id}`;

// 存默认网点key
const transferDefulatCompanyKey = 'transferDefulatCompanyKey';

const MaxAmount = 999999999.99;

// 查询收支方式
const financeInExpModeRemainderSug = ({ cid = window.company_id, search }) => {
  const url = 'Basic/InExpMode/financeInExpModeRemainderSug';
  return fetchApi(url, postOption({ company_id: cid, search: search.split('|')[0].trim() })).then(res =>
    (res.res || []).map(item => ({
      ...item,
      key: item.pay_mode,
      name: item.pay_mode,
      show_name: `${item.pay_mode} | ￥${item.settle_remainder}`,
    })),
  );
};

export default class PayModeTransferAccounts extends PureComponent {
  static propTypes = {
    selectPaymode: PropTypes.string,
    onSaveSuccess: PropTypes.func,
  };
  constructor(prop) {
    super(prop);
    let defulatCompany = {};
    try {
      defulatCompany = JSON.parse(window.localStorage.getItem(transferDefulatCompanyKey));
      // eslint-disable-next-line no-empty
    } catch (error) {}
    this.state = {
      form: {
        from_company_id: null, // 转出网点id
        from_pay_mode: {
          // 转出收支方式
          pay_mode: null, // 收支方式名称
        },
        to_company_id: null, // 转出网点id
        to_pay_mode: {
          // 转出收支方式
          pay_mode: prop.selectPaymode,
        },
        amount: null, // 金额
        remark: null,
      },
      toCompanyList: [],
      fromCompanyList: [],
      fromPayModeList: [],
      toPayModeList: [],
      saveLoading: false,
      defulatCompany: defulatCompany ?? {},
    };
    // 转账时勾选了收支方式，则请求收款网点
    prop.selectPaymode && this.paymodeOrgSug('', toCompanyListKey);
  }
  // 校验表单
  validateForm = () => {
    const { amount } = this.state.form;
    const errMsgs = [];
    if (!+amount || amount === '-') errMsgs.push('请输入转账金额且不能为0');
    if (errMsgs.length) {
      showInfo(ERROR, errMsgs[0]);
      return false;
    }
    return true;
  };
  // 提交接口
  handleSave = async () => {
    let formValidated = true;
    const formPromise = validateFieldsOf(this.wrap);
    try {
      formValidated = await formPromise;
    } catch (e) {
      formValidated = false;
    }
    if (!formValidated) return false;
    const validate = this.validateForm();
    if (!validate) return;
    this.setState({ saveLoading: true });
    fetchJAVA('/cmm-finance/japi/finance/payModeTransfer', {
      method: 'POST',
      body: {
        req: { ...this.state.form },
      },
    })
      .then(res => {
        if (+res.errno === 0) {
          const { onSaveSuccess } = this.props;
          this.modal.handleHide();
          showInfo(CHECK, '转账成功');
          onSaveSuccess?.();
        } else {
          showInfo(ERROR, res.errmsg);
        }
      })
      .finally(() => {
        this.setState({ saveLoading: false });
      });
  };
  onMouseDown = e => {
    e.stopPropagation();
    e.preventDefault();
  };
  // 设置默认网点
  setDefualtCompay = (id, type, val) => {
    const key = type === toCompanyListKey ? toDefulatCompanyKey : fromDefulatCompanyKey;
    // 勾选默认则设置值
    const setVal = val ? id : '';
    const { defulatCompany } = this.state;
    defulatCompany[key] = setVal;
    window.localStorage.setItem(transferDefulatCompanyKey, JSON.stringify(defulatCompany));
    this.setState({
      defulatCompany,
    });
  };
  switchChange = ({ val, data, type = toCompanyListKey }) => {
    this.setDefualtCompay(data.key, type, val);
    const { toCompanyList, fromCompanyList } = this.state;
    const dataList = type === toCompanyListKey ? [...toCompanyList] : [...fromCompanyList];
    const newList = dataList.map(item => {
      return {
        ...item,
        isDefualt: item.key === data.key ? val : false,
      };
    });
    this.setState({
      [type]: newList,
    });
  };
  // 获取收支方式网点
  paymodeOrgSug = (search, type = toCompanyListKey) => {
    const toDefulatCompanyVal = this.state.defulatCompany[toDefulatCompanyKey];
    const fromDefulatCompanyVal = this.state.defulatCompany[fromDefulatCompanyKey];
    // 设置默认网点id
    let defaultId = type === toCompanyListKey ? toDefulatCompanyVal : fromDefulatCompanyVal;
    if (defaultId) {
      defaultId = +defaultId;
    }
    // 对应是收款收支方式还是付款收支方式
    const paymodeKey = type === toCompanyListKey ? 'to_pay_mode' : 'from_pay_mode';
    const url = 'Basic/InExpMode/financeInExpModeCompanySug';
    const { pay_mode } = this.state.form?.[paymodeKey] || {};
    // 如果收支方式没有值，将对应网点数据置空
    if (!pay_mode) {
      this.setState({
        [type]: [],
      });
      return;
    }
    const conf = {
      method: 'POST',
      body: {
        req: {
          pay_mode,
          company_id,
          search: search.split('|')[0].trim(),
        },
      },
    };
    fetchApi(url, conf).then(res => {
      const list = (res.res || []).map(item => ({
        ...item,
        show_name: `${item.company_name} | ￥${item.settle_remainder}`,
        key: item.company_id,
        name: item.company_name,
        isDefualt: item.company_id === defaultId,
      }));

      const findDefualt = list.find(item => item.company_id === defaultId);
      // 更新默认网点
      if (findDefualt) {
        const { form } = this.state;
        const companyKey = toCompanyListKey === type ? 'to_company_id' : 'from_company_id';
        form[companyKey] = defaultId;
        this.setState({
          form: {
            ...form,
          },
        });
      }
      // 更新网点下拉数据
      this.setState({
        [type]: list,
      });
    });
  };
  // 收支方式、网点变更
  onChange = (value, type) => {
    const { form } = this.state;
    let isPaymodeChange = false;
    let listKey = fromCompanyListKey;
    if (type === 'to_pay_mode' || type === 'from_pay_mode') {
      isPaymodeChange = true;
      form[type].pay_mode = value;
      // 收支方式变更，需要重新获取对应网点
      if (type === 'to_pay_mode') {
        form.to_company_id = null;
        listKey = toCompanyListKey;
      } else {
        form.from_company_id = null;
      }
    } else {
      form[type] = value;
    }
    this.setState(
      {
        form: { ...form },
      },
      () => {
        // 收支方式变更
        isPaymodeChange && this.paymodeOrgSug('', listKey);
      },
    );
  };
  // 金额和备注改变
  changeParam = (value, key) => {
    if (key === 'amount') {
      const reg = /^-?[0-9]+\.{0,1}[0-9]{0,2}$/;
      if (value && !reg.test(value) && value !== '-') return;
      if (+value > MaxAmount) value = MaxAmount;
      if (+value < -MaxAmount) value = -MaxAmount;
    }
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        [key]: value,
      },
    });
  };
  renderSwitch = (item, type) => {
    return (
      <div className="switch-content" onMouseDown={this.onMouseDown}>
        <Switch
          checkedText="默认"
          uncheckedText="默认"
          className="item-switch-wrap"
          onChange={val => this.switchChange({ data: item, type, val })}
          checked={item.isDefualt}
        />
      </div>
    );
  };
  customRenderMenu = ({ onSelect = () => {}, type = toCompanyListKey }) => {
    const { form, toCompanyList, fromCompanyList } = this.state;
    let bindThis = this.paymentCompany;
    let formType = 'from_company_id';
    let realData = fromCompanyList;
    if (type === toCompanyListKey) {
      bindThis = this.receiptCompany;
      formType = 'to_company_id';
      realData = toCompanyList;
    }
    return (
      <div className="custom-select-content">
        {realData.map(item => (
          <div className="paymode-transfer-accounts-custom-select" onMouseDown={e => onSelect.call(bindThis, item, e)}>
            <div className={`info-content ${item.key === form[formType] && 'info-content-active'}`}>{item.name}</div>
            {this.renderSwitch(item, type)}
          </div>
        ))}
      </div>
    );
  };
  refWrap = ref => {
    this.wrap = ref;
  };
  renderContent = () => {
    const { form, fromPayModeList, toPayModeList, toCompanyList, fromCompanyList } = this.state;
    return (
      <div ref={this.refWrap}>
        <div className="select-content">
          <div className="select-item">
            <div className="select-title">收款收支方式</div>
            <Select
              ref={r => (this.receiptPaymode = r)}
              value={form.to_pay_mode.pay_mode}
              filter={search => financeInExpModeRemainderSug({ search })}
              filterOnInit
              menuClassName="transfer-pay-mode-menu"
              data={toPayModeList || []}
              required
              format="show_name"
              onChange={val => this.onChange(val, 'to_pay_mode')}
            />
          </div>
          <div className="select-item">
            <div className="select-title">收款网点</div>
            <Select
              ref={r => (this.receiptCompany = r)}
              value={form.to_company_id}
              filter={search => this.paymodeOrgSug(search, toCompanyListKey)}
              data={toCompanyList || []}
              required
              disabled={!form.to_pay_mode.pay_mode}
              customRenderMenu={({ onSelect }) => this.customRenderMenu({ onSelect, type: toCompanyListKey })}
              onChange={val => this.onChange(val, 'to_company_id')}
              format="show_name"
            />
          </div>
        </div>
        <div className="amount-content">
          <div className="amount-content-label">
            <span>转账金额：</span>
            <span className="currency">￥</span>
          </div>
          <Input
            className="input-amount"
            required
            value={form.amount}
            pattern={FLOAT_I_9_P_2_NO_0}
            placeholder="请输入转账金额"
            onChange={e => this.changeParam(e.target.value, 'amount')}
          />
        </div>
        <div className="select-content select-content-payment">
          <div className="select-item">
            <div className="select-title">付款收支方式</div>
            <Select
              ref={r => (this.paymentPaymode = r)}
              value={form.from_pay_mode.pay_mode}
              filter={search => financeInExpModeRemainderSug({ search })}
              menuClassName="transfer-pay-mode-menu"
              data={fromPayModeList || []}
              required
              onChange={val => this.onChange(val, 'from_pay_mode')}
              format="show_name"
            />
          </div>
          <div className="select-item">
            <div className="select-title">付款网点</div>
            <Select
              ref={r => (this.paymentCompany = r)}
              value={form.from_company_id}
              data={fromCompanyList || []}
              disabled={!form.from_pay_mode.pay_mode}
              filter={search => this.paymodeOrgSug(search, fromCompanyListKey)}
              customRenderMenu={({ onSelect }) => this.customRenderMenu({ onSelect, type: fromCompanyListKey })}
              format="show_name"
              required
              onChange={val => this.onChange(val, 'from_company_id')}
            />
          </div>
        </div>
        <div className="remark-content">
          <Label>备注</Label>
          <TextArea
            maxLength="200"
            defaultValue={form.remark}
            placeholder="请填写备注信息，最多支持输入200个字符"
            onChange={e => this.changeParam(e.target.value, 'remark')}
          />
        </div>
      </div>
    );
  };
  render() {
    const bottom = (
      <Fragment>
        <Button onClick={() => this.modal.handleHide()}>取消</Button>
        <Button type="primary" loading={this.state.saveLoading} onClick={this.handleSave}>
          确定
        </Button>
      </Fragment>
    );

    return (
      <ModalDialog
        ref={r => (this.modal = r)}
        content={this.renderContent()}
        classname="paymode-transfer-accounts"
        bottom={bottom}
        isShow
        isModal
        close={this.props.close}
        title="转账"
      />
    );
  }
}
