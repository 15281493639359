import React, { Fragment, PureComponent } from 'react';
import { postJAVA } from 'utils';
import { Button, ModalDialog, Icon } from 'components';
import { prefixCls } from './index.scss';

export default class TaskArrivalModal extends PureComponent {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {
      succData: [],
      succCount: 0,
      failCount: 0,
    };
  }

  componentDidMount() {
    this.planTransformTask();
  }

  closeModal = () => {
    this.modal.handleHide();
    this.props.closeModal && this.props.closeModal();
  };

  planTransformTask = () => {
    const { data } = this.props;
    for (const i in data) {
      const params = {
        ids: [data[i].b_basic_id],
      };

      postJAVA('/cmm-batch/batchPlan/planTransferTask', params).then(({ res = {}, errno, errmsg }) => {
        const succData = [...this.state.succData];
        let { succCount, failCount } = this.state;
        if (errno !== 0) {
          data[i].status = {
            type: 0,
            msg: errmsg,
          };
          failCount++;
          succData.push({ ...data[i], ...res });
          this.setState({
            succData,
            failCount,
          });
          return;
        }

        data[i].status = {
          type: 1,
          msg: '',
        };
        succCount++;
        succData.push({ ...data[i], ...res });
        this.setState({
          succData,
          succCount,
        });
      });
    }
  };

  isAllTranslate = () => {
    const { succCount, failCount } = this.state;
    return succCount + failCount === this.props.data.length;
  };

  render() {
    const { succCount, failCount } = this.state;
    const content = (
      <div ref={r => (this.wrap = r)} className={prefixCls}>
        <div className="top">
          {this.isAllTranslate() && (
            <Fragment>
              <span className="iconBox">
                <Icon iconType="icon-check-o" />
              </span>
              <span>转任务已完成，</span>
            </Fragment>
          )}

          {!this.isAllTranslate() && (
            <Fragment>
              <span className="iconBox">
                <Icon iconType="icon-info-o" />
              </span>
              <span>
                批量操作进度（第<span className="current">{succCount + failCount}</span>个 / 共{this.props.data.length}
                个），
              </span>
              <span>操作进行中，请勿关闭！</span>
            </Fragment>
          )}
          <span className="succ-count">{succCount}</span>
          <span> 个操作成功，</span>
          <span className="fail-count">{failCount}</span>
          <span> 个操作失败！</span>
        </div>

        <div className="table-box">
          <table border={1}>
            <thead>
              <th>序号</th>
              <th>批次号</th>
              <th>车线名称</th>
              <th>主驾司机</th>
              <th>主驾电话</th>
              <th>车牌号</th>
              <th>操作状态</th>
            </thead>

            <tbody>
              {this.state.succData.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item.car_batch}</td>
                  <td>{item.route_line}</td>
                  <td>{item.dr_name}</td>
                  <td>{item.dr_phone}</td>
                  <td>{item.tr_num}</td>
                  <td>
                    {item.status.type === 0 && <Icon iconType="icon-error-o" />}
                    {item.status.type === 1 && <Icon iconType="icon-check-o" />}
                    {item.status.type === 0 ? '失败' : '成功'}
                    {item.status.msg}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );

    const bottom = (
      <Fragment>
        <Button onClick={this.closeModal}>关闭</Button>
      </Fragment>
    );

    return (
      <ModalDialog
        ref={r => (this.modal = r)}
        content={content}
        bottom={bottom}
        isShow
        isModal
        style={{ minWidth: 500 }}
        close={this.props.close}
        title="计划转任务"
      />
    );
  }
}
