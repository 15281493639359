exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".price-snapshot-icon{color:#0097cf;font-size:14px}.popover-price-snapshot .popover-content{padding:0}", ""]);

// exports
exports.locals = {
	"prefixCls": "price-snapshot-icon",
	"price-snapshot-icon": "price-snapshot-icon",
	"popover-price-snapshot": "popover-price-snapshot",
	"popover-content": "popover-content"
};