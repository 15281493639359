export const isAudit = buttonKey =>
  [
    // 上游运单对账单、下游对账单 审核
    'audit_Settle_customer_task',
    'audit_Settle_customer_audit_t_task',
    // 上游订单对账
    'audit_Settle_receipt_payment',
    'audit_Settle_receipt_payment_audit_t',
    // 单据中心 审核
    'settleAudit',
  ].includes(buttonKey);

const getSummary = (pageType, buttonKey) => {
  const summaryType = buttonKey && isAudit(buttonKey) ? 'audit' : 'settle'; // 默认取Settle
  if (summaryType === 'settle') return _getSettleSummary(pageType);
  if (summaryType === 'audit') return _getAuditSummary(pageType);
};

const _getPageModule = pageType => {
  // 是否上游对账
  const isUpStream = ['customer_batch_project', 'customer_batch_client', 'receipt'].includes(pageType);
  // 是否下游对账
  const isDownStream = ['customer_batch_team', 'customer_batch_driver', 'customer_batch_car'].includes(pageType);
  const isOtherStream = ['payment_batch_onway'].includes(pageType);
  const isReceipt = pageType === 'receipt';
  return {
    isUpStream,
    isDownStream,
    isOtherStream,
    isReceipt,
  };
};

const _getSettleSummary = pageType => {
  if (!pageType) return [];
  const { isUpStream, isDownStream, isOtherStream, isReceipt } = _getPageModule(pageType);
  if (!isUpStream && !isDownStream && !isOtherStream) return;
  const summary = [
    {
      title: '待结算金额',
      key: 'unsettleTotal',
      value: '',
    },
    {
      title: '账本抵扣金额合计',
      key: 'bookTotal',
      value: '',
      hide: isUpStream && isReceipt,
      color: '#36B084',
    },
    {
      title: isDownStream || (isUpStream && !isReceipt) ? '其他收支方式金额合计' : '收支方式金额合计',
      key: 'otherTotal',
      value: '',
      color: '#36B084',
    },
    {
      title: '剩余',
      key: 'remainTotal',
      value: '',
      color: '#D42F2F',
    },
  ];
  return summary;
};

const _getAuditSummary = pageType => {
  if (!pageType) return [];
  const { isUpStream, isDownStream, isOtherStream, isReceipt } = _getPageModule(pageType);
  if (!isUpStream && !isDownStream && !isOtherStream) return;

  return [
    {
      title: '单据金额合计',
      key: 'billTotal',
      value: '',
    },
    {
      title: '账本抵扣金额合计',
      key: 'bookTotal',
      value: '',
      hide: isUpStream && isReceipt,
      color: '#36B084',
    },
    {
      title: isDownStream || (isUpStream && !isReceipt) ? '其他收支方式金额合计' : '收支方式金额合计',
      key: 'otherTotal',
      value: '',
      color: '#36B084',
    },
    {
      title: '剩余',
      key: 'remainTotal',
      value: '',
      color: '#D42F2F',
    },
  ];
};

export default getSummary;
