exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".wage_title{display:flex;margin:10px 0}.wage_title>div{margin-right:15px}.wage_title>div>label{margin-right:5px}.wage_p,.wage_wrap{height:calc(100% - 24px)}", ""]);

// exports
exports.locals = {
	"wage_title": "wage_title",
	"wage_wrap": "wage_wrap",
	"wage_p": "wage_p"
};