exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".volume-edit .tip{font-size:13px;color:#f37100}.volume-edit .table-wrap{height:260px;margin-top:10px}.volume-edit .table-wrap .fixedDataTableCellLayout_main .table-filter-column-input{padding-left:1px;padding-right:1px}.volume-edit .table-wrap .fixedDataTableCellLayout_main .table-filter-column-input .fn-input{left:0}.volume-edit .table-wrap .fn-input-pure{text-align:right}.volume-edit .table-wrap .fn-icon-add-rad,.volume-edit .table-wrap.one-row .fn-icon-minus-rad{display:none}.volume-edit .table-wrap .public_fixedDataTable_footer .public_fixedDataTableCell_main:last-of-type{color:#f12222;font-size:16px;font-weight:700}.volume-edit .table-wrap .t_header_substract .table-filter-column-input{font-size:0}", ""]);

// exports
exports.locals = {
	"prefixCls": "volume-edit",
	"volume-edit": "volume-edit",
	"tip": "tip",
	"table-wrap": "table-wrap",
	"fixedDataTableCellLayout_main": "fixedDataTableCellLayout_main",
	"table-filter-column-input": "table-filter-column-input",
	"fn-input": "fn-input",
	"fn-input-pure": "fn-input-pure",
	"fn-icon-add-rad": "fn-icon-add-rad",
	"one-row": "one-row",
	"fn-icon-minus-rad": "fn-icon-minus-rad",
	"public_fixedDataTable_footer": "public_fixedDataTable_footer",
	"public_fixedDataTableCell_main": "public_fixedDataTableCell_main",
	"t_header_substract": "t_header_substract"
};