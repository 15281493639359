exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".transfer-accounts .fn-card__form{border:0 none}.transfer-accounts .fn-card__form .pay_account_name .fn-label:nth-child(2){width:auto;min-width:80px;text-align:left}.transfer-accounts .fn-card__form .fn-label{width:100px}.transfer-accounts .fn-card__form .fn-input,.transfer-accounts .fn-card__form .fn-select{width:200px}.transfer-accounts .fn-card__form .account_name .fn-label:first-child,.transfer-accounts .fn-card__form .account_no .fn-label:first-child,.transfer-accounts .fn-card__form .amount .fn-label:first-child,.transfer-accounts .fn-card__form .line_id_in .fn-label:first-child,.transfer-accounts .fn-card__form .line_id_out .fn-label:first-child,.transfer-accounts .fn-card__form .pay_pwd .fn-label:first-child,.transfer-accounts .fn-card__form .remark .fn-label:first-child{padding-right:10px;vertical-align:top}.transfer-accounts .fn-card__form .remark div div{width:200px}.transfer-accounts .fn-card__form .remark div div .fn-textarea{width:200px;height:58px}", ""]);

// exports
exports.locals = {
	"transfer-accounts": "transfer-accounts",
	"fn-card__form": "fn-card__form",
	"pay_account_name": "pay_account_name",
	"fn-label": "fn-label",
	"fn-input": "fn-input",
	"fn-select": "fn-select",
	"line_id_in": "line_id_in",
	"line_id_out": "line_id_out",
	"account_name": "account_name",
	"account_no": "account_no",
	"amount": "amount",
	"pay_pwd": "pay_pwd",
	"remark": "remark",
	"fn-textarea": "fn-textarea"
};