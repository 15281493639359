import React, { useState, useRef } from 'react';
import { Button, Tooltip, Input, Modal } from '@gui/web-react';
import { useDebounceFn } from '@gui/hooks-react';
import {
  IconUniTipsQuestioncircleOutline,
  IconUniOperateStarOutline,
  IconUniOperateCopyOutline,
  IconUniGeneralSendOutline,
} from '@gui/icon-react';
import { dataAnalyticTrack } from '@/utils/dataAnalytic';
import { useMessages, useMessagesDispatch } from '../messagesContext';
import CodeTranslation from '../CodeTranslation';
import { prefixCls } from './index.scss';

const TextArea = Input.TextArea;

export default function MessageInput() {
  const [text, setText] = useState('');
  const { send, isDisableSend } = useMessages();
  const [visible, setVisible] = useState(false);
  const dispatch = useMessagesDispatch();
  const modalRef = useRef(null);
  const inputRef = useRef(null);
  const { run } = useDebounceFn(() => handleGetGuide(), {
    wait: 500,
  });

  const handleOpen = () => {
    setVisible(true);

    dataAnalyticTrack(['大模型智能助手', '智能录单助手', '代号翻译/解释']);
  };
  const handleClose = () => {
    setVisible(false);

    dataAnalyticTrack(['大模型智能助手', '智能录单助手', '代号翻译/解释-取消']);
  };

  const handleModalSubmit = () => {
    modalRef?.current?.submit();
  };

  const paste = () => {
    navigator.clipboard
      .readText()
      .then(pasteData => {
        if (pasteData) {
          setText(`${text}${pasteData}`);
        }
      })
      .catch(err => {
        console.error('Failed to read clipboard contents: ', err);
      });
  };

  const handelFocus = () => {
    dispatch({
      type: 'SCROLL_TO',
      isToBottom: true,
      immediate: true,
    });
  };

  const handleGetGuide = () => {
    send({
      dataType: 'llm_guide',
    });

    dataAnalyticTrack(['大模型智能助手', '智能录单助手', '使用示例']);
  };

  const handleSend = () => {
    const trimText = text.trim();

    if (trimText.length > 0) {
      setText('');

      // 显示用户数据到消息列表
      dispatch({
        type: 'USER_ADD',
        messageType: 'text',
        role: 'user',
        text: trimText,
      });
      // 识别完成前不能再发送
      dispatch({
        type: 'DISABLE_SEND',
        isDisableSend: true,
      });
      // 发送消息到服务端
      send({
        dataType: 'llm_waybill_identify',
        scene: 1,
        originData: trimText,
      });

      dataAnalyticTrack(['大模型智能助手', '智能录单助手', '消息发送']);
    }
  };

  const showCodeTranslation = () => {
    return window.permissionObj?.codeNameTranslationV1 === 1;
  };

  const checkSendDisable = () => {
    return text.trim().length === 0;
  };

  const handleKeyDown = event => {
    if (isDisableSend) {
      return;
    }

    // 正在中文输入法，回车不发送
    if (event.key === 'Enter' && !event.shiftKey && !event.nativeEvent?.isComposing) {
      // 按回车，且没按 shift 时阻止默认换行，且发送
      event.preventDefault();
      handleSend();
    }
  };

  return (
    <div className={prefixCls}>
      <div className={`${prefixCls}-help`}>
        {showCodeTranslation() && (
          <Tooltip content="用于维护包含代号和翻译/解释的专业知识给财财，让财财更好地理解您的需求，给出更准确的回答。例如：公司内部的业务代称和代表的运单信息，就是一组代号和翻译/解释。">
            <Button
              className={`${prefixCls}-help__btn`}
              icon={<IconUniTipsQuestioncircleOutline />}
              onClick={handleOpen}
            >
              代号翻译/解释
            </Button>
          </Tooltip>
        )}
        <Button className={`${prefixCls}-help__btn`} icon={<IconUniOperateStarOutline />} onClick={run}>
          使用示例
        </Button>
      </div>
      <TextArea
        className={`${prefixCls}-textarea`}
        ref={inputRef}
        value={text}
        placeholder="在这里输入运单描述信息，shift + 回车 = 换行"
        onKeyDown={handleKeyDown}
        onFocus={() => handelFocus()}
        onChange={e => setText(e)}
      />
      <div className={`${prefixCls}-btn`}>
        <Button
          type="secondary"
          onClick={() => paste()}
          icon={<IconUniOperateCopyOutline />}
          className={`${prefixCls}-btn__paste`}
        >
          粘贴
        </Button>
        <Button
          type="primary"
          disabled={checkSendDisable()}
          loading={isDisableSend}
          onClick={() => handleSend()}
          icon={<IconUniGeneralSendOutline />}
          className={`${prefixCls}-btn__send`}
        >
          发送
        </Button>
      </div>
      <Modal
        title="代号翻译/解释"
        className="modal-code-translation"
        visible={visible}
        onOk={handleModalSubmit}
        onCancel={() => setVisible(false)}
        autoFocus={false}
        maskClosable={false}
        focusLock
        unmountOnExit
      >
        <CodeTranslation ref={modalRef} onClose={handleClose} />
      </Modal>
    </div>
  );
}
