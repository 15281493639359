exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".account-expire-modal .title{font-size:20px;color:#131e27;text-align:center;line-height:24px;font-weight:500;margin-bottom:40px}.account-expire-modal .title .fn-icon{font-size:56px;color:#ff6f6b;margin-right:44px;margin-top:36px}.account-expire-modal .title .fn-icon-check-o{color:#53df9c}.account-expire-modal .msg{width:100%;text-align:center;align-self:flex-start;font-size:14px;color:#131e27;line-height:26px}.account-expire-modal .btn{margin-top:30px;width:378px;height:48px}.account-expire-modal .btn .fn-btn{height:48px;width:184px}", ""]);

// exports
exports.locals = {
	"prefixCls": "account-expire-modal",
	"account-expire-modal": "account-expire-modal",
	"title": "title",
	"fn-icon": "fn-icon",
	"fn-icon-check-o": "fn-icon-check-o",
	"msg": "msg",
	"btn": "btn",
	"fn-btn": "fn-btn"
};