// 发起付款申请表格信息header
export const paymentRequestHeader = {
  dr_name: {
    title: '主驾司机',
    width: 100,
    display: 'show',
  },
  dr_phone: {
    title: '主驾电话',
    width: 100,
    display: 'show',
  },
  total_amount: {
    title: '支付金额',
    width: 100,
    display: 'show',
    type: 'Ccombiner',
    data: {
      total_amount: {
        type: 'Label',
      },
      amount_icon: {
        type: 'Icon',
        defaultValue: 'icon-help',
      },
    },
  },
  tr_payee_name: {
    display: 'show',
    title: '收款人',
    width: 100,
    tips: '收款人会根据司机绑定的收款人自动带入，如未带入请检查司机是否绑定了收款人，也可更换或录入收款人，收款人会从收款人管理中获取数据。',
    type: 'SelectDrop',
    fetchApi: { url: 'Basic/Truck/truckPayeeSug', key: 'query' },
    tableHeader: {
      tr_payee_name: '收款人',
      tr_payee_open_bank: '收款人开户行',
      tr_payee_bank_card_num: '收款人银行卡号',
    },
    showHeader: true,
    filterType: 'SelectDrop',
    showKey: 'tr_payee_name',
    uniqueKey: 'tr_payee_name',
  },
  tr_payee_open_bank: {
    display: 'show',
    title: '开户行',
    width: 150,
    type: 'SelectDrop',
    fetchApi: { url: 'Basic/Truck/truckPayeeSug', key: 'query' },
    tableHeader: {
      tr_payee_name: '收款人',
      tr_payee_open_bank: '收款人开户行',
      tr_payee_bank_card_num: '收款人银行卡号',
    },
    showKey: 'tr_payee_open_bank',
    showHeader: true,
    filterType: 'SelectDrop',
    uniqueKey: 'tr_payee_open_bank',
  },
  tr_payee_bank_card_num: {
    title: '银行卡号',
    width: 180,
    display: 'show',
    type: 'SelectDrop',
    fetchApi: { url: 'Basic/Truck/truckPayeeSug', key: 'query' },
    tableHeader: {
      tr_payee_name: '收款人',
      tr_payee_open_bank: '收款人开户行',
      tr_payee_bank_card_num: '收款人银行卡号',
    },
    showKey: 'tr_payee_bank_card_num',
    showHeader: true,
    filterType: 'SelectDrop',
    uniqueKey: 'tr_payee_bank_card_num',
  },
  remark: {
    title: '备注',
    width: 202,
    type: 'PureInput',
    display: 'show',
  },
};

// 发起付款申请进度条header信息
export const batchOpHeader = {
  company_name: { title: '网点', display: 'show' },
  dr_name: { title: '主驾司机', display: 'show' },
  tr_payee_name: { title: '收款人', display: 'show' },
  total_amount: { title: '金额', display: 'show' },
};

// 发起付款申请字段与设置的字段map
export const payBillMap = {
  remark: 'remark',
  account_holder: 'tr_payee_name',
  account_bank: 'tr_payee_open_bank',
  bank_num: 'tr_payee_bank_card_num',
};
