exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".task-arrival-modal .tip{display:flex;align-items:center}.task-arrival-modal .tip .fn-icon-il{flex:0 0;color:#fbba7f;font-size:32px;width:auto;height:auto}.task-arrival-modal .tip p{flex:1 1;margin-left:10px;line-height:22px;font-size:14px}.task-arrival-modal .fields{margin-top:20px}.task-arrival-modal .fields .uploadImg{margin-left:34px;display:block}.task-arrival-modal .fields .uploadImg .transform-fileupload{margin-left:17px}.task-arrival-modal .fields .uploadImg .upload-wrap-tilte{padding-bottom:5px}.task-arrival-modal .fields .uploadImg .upload-file-img__item--upload{min-width:100px!important}.task-arrival-modal .fields>div{display:flex;align-items:center;margin-bottom:10px}.task-arrival-modal .fields>div>label{flex:0 0 100px;text-align:right;padding-right:5px;color:#333}.task-arrival-modal .fields>div>label.r{color:red}.task-arrival-modal .fields>div .ant-calendar-picker,.task-arrival-modal .fields>div .fn-input-pure-wrap{flex:0 0 200px;height:28px}.task-arrival-modal .fields>div .ant-input{width:200px;height:28px}.task-arrival-modal .fields>div .fn-checkbox-pure{margin-left:100px;margin-right:4px}.task-arrival-modal .fields>div .fn-checkbox-pure+label{flex:0 0 auto}", ""]);

// exports
exports.locals = {
	"prefixCls": "task-arrival-modal",
	"task-arrival-modal": "task-arrival-modal",
	"tip": "tip",
	"fn-icon-il": "fn-icon-il",
	"fields": "fields",
	"uploadImg": "uploadImg",
	"transform-fileupload": "transform-fileupload",
	"upload-wrap-tilte": "upload-wrap-tilte",
	"upload-file-img__item--upload": "upload-file-img__item--upload",
	"r": "r",
	"fn-input-pure-wrap": "fn-input-pure-wrap",
	"ant-calendar-picker": "ant-calendar-picker",
	"ant-input": "ant-input",
	"fn-checkbox-pure": "fn-checkbox-pure"
};