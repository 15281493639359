import _ from 'lodash';
import { ERROR } from 'constants';
import { typeIs, showInfo, arraySum } from 'utils';
import { isAccountCheckPayMode } from 'pages/FinanceController/utils/accountBook';
import { accountTypeSugKeyArr, accountTypeFontArr } from './constant';

export const getPayModeArr = (list = []) => list.filter(x => x.pay_mode).map(x => x.pay_mode[0].key);

export const formatHeader = (header, data = [], page, isTaskAmount) => {
  const { isView, isAddOilCard } = page.props;
  const _hd = _.cloneDeep(header);
  if (isView) delete _hd.substract;
  if (!isView && isAddOilCard && header.oil_card) {
    _hd.oil_card.addIcon = 'fn-icon-add-rad';
    _hd.oil_card.headerAddIcon = () => page.addOilCard();
  }
  if (header.amount && isTaskAmount) {
    // 只针对任务对账单-承运商的单子-结算弹窗
    _hd.amount.tips = '负数表示支出项，正数表示收入项';
    _hd.amount.title = '实付金额';
  }
  return _hd;
};
export const formatData = (data = [], header, isView, { canEditAmount = false, auditType, needGray = false } = {}) => {
  const list = [];
  data.forEach(r => {
    const _row = {};
    Object.keys(header).forEach(k => {
      switch (k) {
        // case 'truck_num':
        // if (r[x]) _row[x] = typeIs(r[x], 'array') ? r[x] : [r[x]]
        // break
        case 'oil_card':
          if (r[k]) _row[k] = [{ id: r.oil_card_id, card_num: r.oil_card, is_relation: r.is_relation }];
          break;
        case 'account_book_card':
          // eslint-disable-next-line no-case-declarations
          const dataObj = {};
          accountTypeSugKeyArr.forEach(keyItem => {
            dataObj[keyItem] = r[keyItem];
          });
          if (r[k]) _row[k] = [dataObj];
          if (!_.isNil(r.is_group_account)) _row.is_group_account = r.is_group_account; // 回显时需要附带 建团账本标识
          break;
        default:
          if (r[k]) _row[k] = r[k];
      }
    });

    _row.otherProps = { ...r.otherProps };
    _row.otherProps.disable = [];
    if (isView) {
      // 查看模式都禁用
      _row.otherProps.disable = _row.otherProps.disable.concat(Object.keys(header));
    }
    if (auditType === 'audit') {
      // 审核模式只有金额可以修改 isView false
      const arr = Object.keys(header);
      _.remove(arr, function (x) {
        return x === 'amount';
      });
      _row.otherProps.disable = _row.otherProps.disable.concat(arr);
    }
    // 新增、修改
    if (!isView && auditType !== 'audit') {
      if (accountTypeFontArr.includes(_row.pay_mode)) {
        let arr = [
          'bank_name',
          'bank_num',
          'account_holder',
          'cheque_no',
          'draft_no',
          'wechat_no',
          'alipay_no',
          'oil_card',
        ];
        if (needGray) {
          arr = arr.concat(['amount', 'pay_mode', 'substract']);
        }
        _row.otherProps.disable = _row.otherProps.disable.concat(arr);
      } else {
        _row.otherProps.disable.push('account_book_card');
      }
    }
    // 部分情况下允许编辑金额
    if (canEditAmount && !isAccountCheckPayMode(_row.pay_mode) && _row.otherProps.disable) {
      _row.otherProps.disable = _row.otherProps.disable?.filter?.(key => key !== 'amount');
    }
    list.push(_row);
  });
  return list;
};

export const buildFeeList = (list = []) => {
  let total = 0;
  const errFee = [];
  const feeRow = [];
  list.forEach(r => {
    const amount = r.amount ? +r.amount : 0;
    total = arraySum([total, amount]);
    const _row = {};
    if (!r.pay_mode || !r.pay_mode.length) errFee.push(r);
    Object.keys(r).forEach(x => {
      switch (x) {
        case 'pay_mode':
          if (r[x]) _row.pay_mode = typeIs(r[x], 'array') ? r[x][0].display : r[x];
          break;
        case 'oil_card':
          if (r[x]) {
            _row.oil_card_id = r[x][0].id;
            _row.oil_card = r[x][0].card_num;
            _row.is_relation = r[x][0].is_relation;
          }
          break;
        case 'account_book_card':
          if (r[x]) {
            Object.assign(_row, r[x][0]);
          }
          break;
        default:
          if (r[x] && x !== 'otherProps') _row[x] = r[x];
      }
    });
    feeRow.push(_row);
  });
  return { err: errFee, data: feeRow, total };
};

export const doOilAmount = (amount, oilAmount, isRelation) => {
  let mark = true;
  // 负数金额才校验 并且是支付方式关联的油卡
  if (Number(isRelation) > 0 && Number(amount) < 0 && amount && oilAmount) {
    if (-Number(amount) > Number(oilAmount)) {
      mark = false;
      showInfo(ERROR, `输入金额不能超过所选油卡余额`);
    }
  }
  return mark;
};

// 同一个司机、车辆、员工账本的账本账户只能选择一条
export const doOnlyAccountBook = (data, payMode, cardId) => {
  let mark = true;
  if (cardId && payMode && accountTypeFontArr.includes(payMode)) {
    const checkArr = data.filter(item => {
      const itemPayMode = _.get(item, 'pay_mode[0].key') || _.get(item, 'pay_mode');
      return itemPayMode === payMode;
    });
    if (checkArr && checkArr.length > 1) {
      const sameCardArr = checkArr.filter(item => {
        const itemPayModeId = _.get(item.account_book_card, '[0].account_book_uuid');
        return itemPayModeId === cardId;
      });

      if (sameCardArr && sameCardArr.length > 1) {
        showInfo(ERROR, '该账本账户已选择，请勿重复选择');
        mark = false;
      }
    }
  }
  return mark;
};
