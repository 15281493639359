exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".chat-take-order{display:inline-block;position:absolute;left:24px;bottom:14px;z-index:2;cursor:pointer}.chat-take-order.hidden{visibility:hidden}.chat-take-order:hover .cs-font{visibility:visible}.chat-take-order:active{cursor:move}.chat-take-order:active .cs-font{visibility:hidden}.chat-take-order .inner{position:relative}.chat-take-order .cs-ico{width:84px;height:102px;background:url(https://static.g7cdn.com/fe-cdn/cyt/cmtx-tms-pro-frontend-web/images/chat/customer-service.png) no-repeat 50%;background-size:100% 100%;display:block}.chat-take-order .cs-font{position:absolute;left:80px;top:10px;border-radius:6px 6px 6px 0;width:143px;padding:8px 10px;background:#381dc0;color:#fff;font-size:12px;visibility:hidden}", ""]);

// exports
exports.locals = {
	"prefixCls": "chat-take-order",
	"chat-take-order": "chat-take-order",
	"hidden": "hidden",
	"cs-font": "cs-font",
	"inner": "inner",
	"cs-ico": "cs-ico"
};