import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { PureInput as Input } from 'components';
import { ERROR } from 'constants';
import { bem, range, showInfo } from 'utils';
import {
  initListData,
  formatData,
  getTotal,
  formSet,
  buildData,
  getFeeInfo,
  updateDriverRemainAmount,
  updateCarRemainAmount,
  updatePayTotalAmount,
} from './tool';
import { renderTbodyTd } from './renderTbodyTd';
import { useloanTypeBillTypes } from 'components/commoncomponents/capitalFlow/constant.js';
import { getLoanType } from './action';
import Nzh from 'nzh';

import { prefixCls } from './index.scss';

const nzhcn = Nzh.cn;
const monyUnit = ['亿', '千', '百', '十', '万', '千', '百', '十', '元', '角', '分'];
const cls = bem(prefixCls);

export default class FeeBreakdown extends PureComponent {
  static propTypes = {
    type: PropTypes.string, // 用在何处：loan: 借款  repay: 还款  income: 付款
    billType: PropTypes.string,
    usedFor: PropTypes.string,
    settings: PropTypes.object, // 配置项
    preParam: PropTypes.object, // 父组件参数
    handleToDoAdd: PropTypes.func, // 增加行回调
    handleToDoSub: PropTypes.func, // 删除行回调
    isAddSub: PropTypes.bool, // 是否显示加减号
  };

  static defaultProps = {
    isAddSub: true,
  };

  constructor(props) {
    super(props);

    const _props = this.props;
    const { oil_card, ...header } = _props.header || {};
    const data = _props.data && _props.data.length ? _props.data : initListData(_props.header);
    this.state = {
      data,
      header,
      // eslint-disable-next-line react/no-unused-state
      preParam: _props.preParam,
      moneyState: _props.data.map(x => !x),
    };
    // 用于记住要计算的输入框是否手动改过
    this.inputMemory = {};
    this.initHeader = { ..._props.header };
    this.loadTypeChange(data);
    this.setLoanTypeList();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const preProps = this.props;
    const { oil_card, ...header } = nextProps.header;
    this.initHeader = { ...nextProps.header };
    if (preProps.preParam !== nextProps.preParam) {
      this.setState({ preParam: { ...nextProps.preParam } }, () => {
        preProps.preParam?.com_id?.id !== nextProps.preParam?.com_id?.id && this.updateRemainAmoun();
      });
    }
    if (preProps.header !== nextProps.header) this.setState({ header: { ...header } });
    if (preProps.data !== nextProps.data) this.updateData(nextProps.data, nextProps.header);
  }

  // 设置借支类型数据
  setLoanTypeList = async () => {
    const loan_type_list = await getLoanType();
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      loan_type_list, // 在renderTbodyTd 中使用
    });
  };

  updateData = async (data, header) => {
    const _data = data.length ? data : initListData(header);
    const nextData = await getFeeInfo(_data, header);
    const formatDataList = formatData(nextData, header);
    this.setState({ data: formatDataList }, () => this.asyncUpdateData());
    this.loadTypeChange(formatDataList);
  };

  // 记录手动输入
  setInputMemory = (key, val) => {
    this.inputMemory[key] = val;
  };

  getData = () => buildData(this);

  addRow = () => {
    const { data = [], header } = this.state;
    const initRow = initListData(header);
    //
    if (useloanTypeBillTypes.includes(this.props.billType)) {
      initRow[0].loan_type = 'cash';
    }
    const _data = [...data, ...initRow];
    this.setState({ data: _data });
  };

  subRow = index => {
    const { data = [] } = this.state;
    if (data.length === 1) {
      return showInfo(ERROR, '最少要有一行单据');
    }
    const _list = [...data];
    _list.splice(index, 1);
    this.setState({ data: [..._list] });
  };

  switchMonyState = (index, flag) => {
    const { moneyState, header } = this.state;
    if (header.amount && header.amount.disables) return;
    moneyState[index] = flag;
    this.setState({ moneyState: [...moneyState] });
  };

  renderAmountTd = (total, flag, index) => {
    const totalStr = (+total || 0).toFixed(2);
    const numberArray = totalStr.replace('.', '').split('');
    const defaultArray = range(11 - numberArray.length).map(() => '');
    const moneyArray = defaultArray.concat(numberArray);
    return moneyArray.map((name, idx) => {
      const key = `${idx}_${name}`;
      if (flag === 'footer') {
        return (
          <td className={cls(`col_${idx}`)} key={key}>
            {name}
          </td>
        );
      }
      return (
        <td className={cls(`col_${idx}`)} key={key} onClick={() => this.switchMonyState(index, 1)}>
          {name}
        </td>
      );
    });
  };

  renderAmount = (index, amount = 0) => {
    const { moneyState, header } = this.state;
    if (header.amount && !header.amount.disables && moneyState[index]) {
      return (
        <td colSpan="11">
          <Input
            value={amount}
            type="number"
            min={0}
            max={999999999}
            step={0.01}
            onBlur={e => this.switchMonyState(index, 0)}
            onChange={e => formSet(index, 'amount', e.target.value, this)}
            ref={input => {
              if (input) {
                input.focus();
              }
            }}
          />
        </td>
      );
    }

    // 非修改状态
    return this.renderAmountTd(amount, 'body', index);
  };

  // 借支方式选择发生变更
  loadTypeChange(data) {
    // 如果借支方式包含油款，并且设置油款显示，则展示油卡
    const findGas = data.some(item => item.loan_type === 'gas');
    if (findGas && this.props.settings?.oil_card?.show) {
      this.setState({ header: { ...this.initHeader } });
    } else {
      const { oil_card, ...header } = this.state.header;
      this.setState({
        header,
      });
    }
  }

  // 异步更新
  asyncUpdateData = () => {
    const { data, header, preParam } = this.state;
    const len = data?.length ?? 0;
    if (len === 0) return;
    const updateData = async index => {
      if (index >= len) return;
      const { batch_num } = data[index];
      // 更新应付运输费合计
      const hasBatchNum = ['batch_num'].some(k => header[k]);
      const shouldUpdatePayTotalAmount = header.pay_total_amount && hasBatchNum && (batch_num?.car_batch ?? batch_num);
      if (shouldUpdatePayTotalAmount) {
        const _comId = preParam?.com_id.id;
        updatePayTotalAmount(
          { index, page: this, query: { car_batch: batch_num?.car_batch ?? batch_num, company_id: _comId } },
          this,
        );
      }
      updateData(index + 1);
    };

    updateData(0);
  };

  // 网点发生变更——更新司机、车辆余额
  updateRemainAmoun = () => {
    const { data, header, preParam } = this.state;
    const len = data.length;
    if (len === 0) return;
    // 司机余额和车辆余额只会存在一种
    const updateData = async index => {
      if (index >= len) return;
      const { driver_name, loan_type, truck_num } = data[index];
      // 更新司机余额
      const hasDriverAmount = ['driver_remain_amount', 'driver_total_balance'].some(k => header[k]);
      if (driver_name?.id && loan_type && hasDriverAmount && header.driver_name) {
        updateDriverRemainAmount({ index, page: this, query: { dr_id: data[index].driver_name.id, loan_type } }, this);
      }
      // 更新车辆余额
      const hasCarAmount = ['car_remain_amount', 'car_total_balance'].some(k => header[k]);
      if (header.car_remain_amount && hasCarAmount && truck_num?.id && loan_type) {
        const _comId = preParam?.com_id.id;
        updateCarRemainAmount(
          { index, page: this, query: { tr_id: truck_num.id, loan_type, company_id: _comId } },
          this,
        );
      }
      updateData(index + 1);
    };

    updateData(0);
  };

  renderTbody = data => {
    const { isAddSub } = this.props;
    return data.map((row, index) => (
      <tr key={index}>
        {isAddSub && (
          <td className="op-col">
            <i className="fn-icon fn-icon-minus-rad" onClick={() => this.subRow(index)} />
          </td>
        )}
        {renderTbodyTd(this, row, index)}
      </tr>
    ));
  };

  /*
  renderAddSub = data => {
    const { isAddSub } = this.props
    if (!isAddSub) return null
    const addEl = (<span key="addRow" className="add"><i className="fn-icon fn-icon-add-rad" onClick={() => this.addRow()} /></span>)
    const subEl = data.map((row, index) => (<span key={index} className="sub"><i className="fn-icon fn-icon-minus-rad" onClick={() => this.subRow(index)} /></span>))
    return (<div className={cls('list__icon-wrap')}>{[addEl, ...subEl]}</div>)
  }
  */

  renderTotalBody = data => data.map((row, index) => <tr key={index}>{this.renderAmount(index, row.amount)}</tr>);

  render() {
    const { header = {}, data = [] } = this.state;
    if (!Object.keys(header).length) return null;

    const { isAddSub } = this.props;
    const total = getTotal(data);
    const cnTotal = nzhcn.toMoney(total, { complete: true }).replace('人民币', '');
    const theadUnit = monyUnit;

    return (
      <div className={cls()}>
        <div className={cls('list')}>
          <table>
            <thead>
              <tr>
                {isAddSub && (
                  <th className="op-col">
                    <i className="fn-icon fn-icon-add-rad" onClick={this.addRow} />
                  </th>
                )}
                {Object.keys(header).map((k, index) =>
                  k === 'amount' ? null : <th key={index}>{header[k].label}</th>,
                )}
              </tr>
            </thead>
            <tbody>{this.renderTbody(data)}</tbody>
          </table>
          <div className={cls('total-wrap')}>
            <span>合计：</span>
            <strong>{cnTotal}</strong>
          </div>
        </div>
        <div className={cls('amount')}>
          <table>
            <thead>
              <tr>
                <th colSpan="11">{header.amount ? header.amount.label : '金额'}</th>
              </tr>
              <tr>
                {theadUnit.map((item, index) => (
                  <th className={cls(`col_${index}`)} key={index}>
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{this.renderTotalBody(data)}</tbody>
            <tfoot>
              <tr>{this.renderAmountTd(total, 'footer')}</tr>
            </tfoot>
          </table>
        </div>
      </div>
    );
  }
}
