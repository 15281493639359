exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".plan-transform-task-modal .fn-icon-info-o{color:#69b1f1}.plan-transform-task-modal .fn-icon-check-o{color:#31c481}.plan-transform-task-modal .fn-icon-error-o{color:#ff6f6b}.plan-transform-task-modal .fn-icon-warn-o{color:#ffa553}.plan-transform-task-modal .iconBox{margin-right:10px}.plan-transform-task-modal .iconBox .fn-icon{font-size:18px}.plan-transform-task-modal .current{color:#ffa553;font-size:18px}.plan-transform-task-modal .top{font-size:14px}.plan-transform-task-modal .top .succ-count{font-size:18px;color:#31c481}.plan-transform-task-modal .top .fail-count{font-size:18px;color:#ff6f6b}.plan-transform-task-modal .table-box{width:100%;margin-top:10px;max-height:600px;overflow:scroll}.plan-transform-task-modal .table-box table{width:100%}.plan-transform-task-modal .table-box table thead th{background:#f2f2f2;height:30px;text-align:center}.plan-transform-task-modal .table-box table td{text-align:center;height:30px;min-width:80px;padding:0 5px}", ""]);

// exports
exports.locals = {
	"prefixCls": "plan-transform-task-modal",
	"plan-transform-task-modal": "plan-transform-task-modal",
	"fn-icon-info-o": "fn-icon-info-o",
	"fn-icon-check-o": "fn-icon-check-o",
	"fn-icon-error-o": "fn-icon-error-o",
	"fn-icon-warn-o": "fn-icon-warn-o",
	"iconBox": "iconBox",
	"fn-icon": "fn-icon",
	"current": "current",
	"top": "top",
	"succ-count": "succ-count",
	"fail-count": "fail-count",
	"table-box": "table-box"
};