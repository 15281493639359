import React, { Fragment } from 'react';
import { SelectDrop, PureInput, PureTextarea as TextArea, UploadFile, DateTimePicker } from 'components';
import Card from '../card';
import InfoItem from '../infoItem';

const feeSug = {
  url: '/Basic/Fee/feeSug',
  para: {
    type: 2,
  },
  key: 'search',
};
const roleSug = {
  url: 'Basic/Permission/roleSug',
  para: {
    company_id: '',
    search: '',
  },
};
class baseInfo extends React.Component {
  onChange = key => {
    if (!this.onChangeCache) {
      this.onChangeCache = {};
    }
    if (!this.onChangeCache[key]) {
      this.onChangeCache[key] = e => {
        const val = e && e.target ? e.target.value : e;
        this.props.set('baseInfo', key, val);
      };
    }
    return this.onChangeCache[key];
  };
  // 异常一级分类
  renderExtendFields = (clieckedExtendFields, data, disabled, onChange) => {
    return (
      <>
        <div className="cut-line"> </div>
        {clieckedExtendFields?.map((item, index) => (
          <InfoItem label={item.name} show={item.show} required={item.require} key={`filds${index}`}>
            {item.code !== 'start_time' && item.code !== 'finish_time' && item.code !== 'goods_prod_time' && (
              <PureInput
                value={data[item.code]}
                onChange={onChange(item.code)}
                disabled={disabled}
                required={item.require}
              />
            )}
            {(item.code === 'start_time' || item.code === 'finish_time' || item.code === 'goods_prod_time') && (
              <DateTimePicker
                defaultValue={data[item.code]}
                showTime
                required={item.require}
                onChange={onChange(item.code)}
                allowClear={this.props.g7_unload_t}
                disabled={disabled}
              />
            )}
          </InfoItem>
        ))}
      </>
    );
  };

  render() {
    const {
      set,
      data,
      enums,
      abnormalTypeEnums,
      required,
      disabled,
      showPoint,
      showPermission,
      tips,
      imgRefGetter,
      initedData,
      hiddenOrderInfo,
      type,
      abnormType,
    } = this.props;
    const enumsType = enums.abn_type || [];
    const abnormalExtendFields = window.company_setting.abn_fields || {}; // 获取异常一级分类的扩展字段
    const clieckedExtendFields = []; // 当前选中异常类型所在一级分类的扩展字段
    // data.type中没有id等值为改查操作，直接用data.type去匹配对应的一级分类code
    const updateExtendType =
      data.type && data.type.id
        ? abnormalTypeEnums?.[data.type.id]?.type_code?.[0].key
        : abnormalTypeEnums?.[data.type]?.type_code?.[0]?.key;
    const foundItem = abnormalExtendFields?.values?.find(item => item.code === updateExtendType); // 用当前异常类型匹配到的一级分类code，找到一级分类对应的对象
    if (foundItem && foundItem.child && foundItem.child.length > 0) {
      clieckedExtendFields.push(...foundItem.child); // 将一级分类对象中的扩展字段child数组加入到dom渲染数组clieckedExtendFields中
    }

    const newenumsType = enumsType.filter(i => {
      console.log(i.category, 555, abnormType);
      const ab_type = abnormType === '4' ? '3' : abnormType;
      return i.category ? i.category.includes(ab_type) : true;
    });
    const enumPermission = type === 'add' ? [] : enums.assign_permission;
    roleSug.para.company_id = (data.assign_company_id && data.assign_company_id.key) || data.assign_company_id;
    const { onChange } = this;
    const EL = this.props.isFragment ? Fragment : Card;
    return (
      <EL title="异常登记信息" className="base-info">
        {!hiddenOrderInfo && (
          <Fragment>
            <InfoItem label="异常编号">
              <PureInput value={data.number} placeholder="保存后自动生成" disabled />
            </InfoItem>
            <InfoItem label="登记时间">
              <PureInput value={data.add_time} disabled />
            </InfoItem>
            <InfoItem label="登记网点">
              <SelectDrop defaultSelected={data.add_company_id} data={enums.add_company_id} disabled />
            </InfoItem>
            <InfoItem label="登记人">
              <SelectDrop defaultSelected={data.add_user_id} data={enums.add_user_id} disabled />
            </InfoItem>
          </Fragment>
        )}
        <InfoItem label="异常类型" required>
          <SelectDrop
            defaultSelected={data.type}
            data={newenumsType}
            handleSelected={onChange('type')}
            disabled={disabled}
            required
            selectOnly
          />
        </InfoItem>
        <InfoItem label="异常件数">
          <PureInput
            type="quantity"
            value={data.quantity}
            onChange={onChange('quantity')}
            disabled={disabled}
            ref={ref => (this.quantityRef = ref)}
          />
        </InfoItem>
        <InfoItem label="处理网点" show={showPoint} required={required.assign_company_id} tips={tips.assign_company_id}>
          <SelectDrop
            uniquekey="key"
            filKey="name"
            data={enums.assign_company_id}
            defaultSelected={data.assign_company_id}
            selectOnly
            required={required.assign_company_id}
            disabled={disabled}
            handleSelected={onChange('assign_company_id')}
          />
        </InfoItem>
        <InfoItem
          label="处理权限"
          show={showPermission}
          required={required.assign_permission}
          tips={tips.assign_permission}
        >
          <SelectDrop
            showKey="display"
            uniqueKey="role_id"
            data={enumPermission}
            defaultSelected={data.assign_permission}
            handleSelected={onChange('assign_permission')}
            disabled={disabled}
            fetchApi={roleSug}
            selectedOnlyKey
            selectOnly
            isMultiple
            required={required.assign_permission}
          />
        </InfoItem>
        <InfoItem label="费用类别" required={required.abn_expense} tips={tips.abn_expense}>
          <SelectDrop
            defaultSelected={data.abn_expense}
            showKey="fee_name"
            uniqueKey="fee_name"
            selectOnly
            selectedOnlyKey
            fetchApi={feeSug}
            disabled={disabled}
            handleSelected={onChange('abn_expense')}
            required={required.abn_expense}
          />
        </InfoItem>
        {/* 异常类型对应一级分类的扩展字段 cliecked */}
        {this.renderExtendFields(clieckedExtendFields, data, disabled, onChange)}
        <InfoItem key="rmk" className="rmk" required label="异常描述">
          <TextArea
            value={data.rmk}
            maxLength="500"
            placeholder="问题描述最多输入500个汉字"
            onChange={onChange('rmk')}
            disabled={disabled}
            required
          />
        </InfoItem>
        {/* CYTRD-11390 上传图片扩展为上传文件 暂时注释掉 */}
        {/* {initedData && (
          <UploadImgText
            cameraShow
            fileType="abnormal"
            ref={imgRefGetter}
            photoProps={data.add_imgs}
            disabled={disabled}
            maxNum={10}
            tips="最多上传10个图片，每个图片不能大于3M"
          />
        )} */}
        <InfoItem key="descr" className="descr" label="">
          <p>
            文件上传:
            <span className="spanRmk">支持图片，PDF、Word、Excel、.eml、AAC格式，最多上传10个，每个不能大于3M</span>
          </p>
        </InfoItem>
        <InfoItem key="desc" className="desc" label="上传文件">
          {initedData && (
            <UploadFile
              cameraShow
              fileList={data.add_imgs}
              ref={imgRefGetter}
              type="abnormal"
              disabled={disabled}
              isOss
              maxNum={10}
              sizeLimit={3}
            />
          )}
        </InfoItem>
      </EL>
    );
  }
}
export default baseInfo;
