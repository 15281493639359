export const hasOverCompanyAuth = (name, tab = '') => {
  let btnKey = name;
  // 满足以下菜单来的对账单才进行权限判定
  const overCompanyBtns = [
    'client_ca_ba_apply', // 客户运单对账
    'order_receipt_apply', // 客户订单对账
    'project_ca_ba_apply', // 项目运单对账
    'create_new_account', // 2.0 项目对账
    'driver_ca_ba_apply', // 下游对账
    'car_ca_ba_apply',
    'carrier_ca_ba_apply',
    'receiptApply', // 客户订单对账收款按钮打开的挑单页
    'receiptPaymentModify receipt_payment_v1', // 收款单— 订单对账单 修改按钮
    'customerAccountModify', // 收款单— 运单对账单 修改按钮
  ];
  // 满足条件的列表详情入口进去的编辑按钮
  const editBtnKeys = ['settlePaReNo', 'settleAcNo'];
  const editBtnFromPageTab = [
    // 订单对账单
    'receipt_payment_v1', // 全部
    'receipt_payment_self_create_v1', // 我创建的
    'receipt_payment_audit_t_v1', //  待审核
    // 运单对账单
    'customer_task_v1_0', // 全部
    'customer_self_create_task_v1_0', // 我创建的
    'customer_audit_t_task_v1_0', // 待审核
    // 付款单
    'customer_task_v1', // 全部
    'customer_self_create_task_v1', // 我创建的
    'customer_audit_t_task_v1', // 待审核
    // 任务对账单列表
    'customer_task', // 全部
    'customer_self_create_task', // 我创建的
    'customer_audit_t_task', // 待审核
  ];
  // 区分 收款单里的订单对账单、运单收款单的列表上的编辑按钮
  if (name === 'receiptPaymentModify') {
    btnKey = `${btnKey} ${tab}`;
  }
  // 详情页里面的编辑模式
  if (btnKey && editBtnKeys.indexOf(btnKey) > -1 && editBtnFromPageTab.indexOf(tab) > -1) {
    return true;
  }
  // 收款、创建对账单、列表编辑按钮
  if (btnKey && overCompanyBtns.indexOf(btnKey) > -1) {
    return true;
  }
  return false;
};
