import React from 'react';
import _ from 'lodash';
import Card from '../card';
import Log from 'components/commoncomponents/log';
import { prefixCls } from './index.scss';

const format = data =>
  data.map(item => {
    const iitem = item;
    const imgs = Object.values(iitem.images || {});
    iitem.state_follow_img = _.flatten(imgs);
    iitem.trace_info = iitem.operation;
    return {
      ...iitem,
    };
  });
class AbnormalLog extends React.Component {
  shouldComponentUpdate() {
    return !this.props.logInfo.info;
  }
  render() {
    const { logInfo, logRefGetter } = this.props;
    return (
      <Card title="异常跟踪">
        <div ref={logRefGetter} className={prefixCls}>
          <Log logInfo={format(logInfo.info)} orderByAsc curIndex={logInfo.curIndex} />
        </div>
      </Card>
    );
  }
}
export default AbnormalLog;
