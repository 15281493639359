exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".certification-header{margin-bottom:20px}.certification-header .header-title{font-size:18px;font-weight:500}.certification-header .header-desc{margin-top:20px;font-size:14px;font-weight:500;color:#f18f25}.certification-footer{margin-top:20px;display:flex;justify-content:flex-end}.certification-footer .fn-btn{margin-left:15px}.certification-content{background-color:#fff}.certification-content .certification-content-title{font-size:14px;font-weight:500}.certification-content .certification-content-input{margin-top:15px;width:100%;height:40px}.certification-content .fn-input-pure{font-size:14px}.certification-content .certification-content-desc{margin-top:10px;font-size:12px;color:#aaa}.certification-content input::-webkit-input-placeholder{color:#aaa}", ""]);

// exports
exports.locals = {
	"prefixCls": "certification",
	"certification-header": "certification-header",
	"header-title": "header-title",
	"header-desc": "header-desc",
	"certification-footer": "certification-footer",
	"fn-btn": "fn-btn",
	"certification-content": "certification-content",
	"certification-content-title": "certification-content-title",
	"certification-content-input": "certification-content-input",
	"fn-input-pure": "fn-input-pure",
	"certification-content-desc": "certification-content-desc"
};