import React from 'react';
import { ModalDialog, Flex, Icon, Button } from 'components';
import { prefixCls } from './index.scss';

class SuccessTip extends React.Component {
  renderContent = () => (
    <Flex className={prefixCls} dir="column" align="center">
      <Flex className="title" justify="center">
        <Icon iconType="icon-check-o" />
      </Flex>
      <p className="msg">{this.props.type || ''}申请已提交！感谢您的支持，工作人员会尽快审核您的申请！</p>
      <p className="msg">如您比较着急，可以拨打400-611-5656客服电话为您服务。</p>
      <Button classname="btn" type="positive" onClick={this.handleHide}>
        我知道了
      </Button>
    </Flex>
  );
  handleHide = () => {
    this.modal && this.modal.handleHide();
    this.props.hideCallBack && this.props.hideCallBack();
  };
  render() {
    return (
      <ModalDialog
        content={this.renderContent()}
        ref={ref => (this.modal = ref)}
        closable
        maskClosable={false}
        style={{ width: 478 }}
        handleHideCallback={this.handleHide}
        isShow
        {...this.props}
      />
    );
  }
}
export default SuccessTip;
