import splitConfigV1 from './v1';
import splitConfigV2 from './v2';

const suitForTabV1Key = ['truck_pick_v1', 'delivery_pick_v1', 'pickup_pick_v1'];

const splitConfig = prop => {
  const { i18nDict, tab } = prop;
  const orderDict = i18nDict.get('batch', '批次');
  // 为部分tab开放套数功能
  const config = suitForTabV1Key.includes(tab) ? splitConfigV1 : splitConfigV2;
  return config({ orderDict });
};
export default splitConfig;
