import React from 'react';
import { Button, ButtonIcon } from 'components';
import { bem } from 'utils';

import { prefixCls } from './index.scss';

const cls = bem(prefixCls);

const renderFooter = page => {
  const { usedFor } = page.props;
  /*
        <ButtonIcon
          iconType="icon-file-add"
          disabled={false}
          onClick={() => page.handleBtnClick('saveAndCreate')}
        >保存并新增</ButtonIcon>
        */

  if (usedFor === 'add') {
    return (
      <footer className={cls()}>
        <ButtonIcon iconType="icon-task-cancel" onClick={() => page.handleBtnClick()}>
          取消
        </ButtonIcon>
        <ButtonIcon type="primary" iconType="icon-save" disabled={false} onClick={() => page.handleBtnClick('save')}>
          保存
        </ButtonIcon>
      </footer>
    );
  }

  if (usedFor === 'update') {
    return (
      <footer className={cls()}>
        <ButtonIcon onClick={() => page.handleBtnClick()}>取消</ButtonIcon>
        <ButtonIcon type="primary" iconType="icon-save" disabled={false} onClick={() => page.handleBtnClick('update')}>
          保存
        </ButtonIcon>
      </footer>
    );
  }

  if (usedFor === 'audit') {
    const approval_config = (window.systemConfig && window.systemConfig.approval_config) || {};
    const button =
      (approval_config[page.props.auditOperation] && approval_config[page.props.auditOperation].button) || [];
    if (button.length) {
      return (
        <footer className={cls()}>
          {button.map(item => (
            <Button onClick={() => page.handleBtnClick(item.applet_audit)} type={item.type}>
              {item.title}
            </Button>
          ))}
        </footer>
      );
    }

    return (
      <footer className={cls()}>
        <Button onClick={() => page.handleBtnClick('pass')}>通过</Button>
        <Button onClick={() => page.handleBtnClick('reject')}>拒绝</Button>
      </footer>
    );
  }
};

export default renderFooter;
