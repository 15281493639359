exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".capital-flow-attr{margin-top:6px}.capital-flow-attr__form-item{display:inline-flex;align-items:center;position:relative;width:20%;height:34px;padding:4px 10px;margin-bottom:1px}.capital-flow-attr__form-item .fn-input-pure-wrap{height:28px}.capital-flow-attr__form-item .ant-input,.capital-flow-attr__form-item .fn-input-pure{font-size:14px;width:100%;padding:1px 1px 1px 4px;border:0;box-shadow:inset 0 -1px 0 0 #e5e5e5;border-radius:0}.capital-flow-attr__form-item .fn-select .fn-input-pure{padding-right:10px}.capital-flow-attr__form-item .ant-calendar-picker{width:100%!important}.capital-flow-attr__form-item .ant-calendar-picker input.ant-input{width:100%;border:0;height:28px}.capital-flow-attr__form-item .fn-icon-date{display:none}.capital-flow-attr__form-item .ant-input-disabled,.capital-flow-attr__form-item .fn-input-pure[disabled],.capital-flow-attr__form-item .fn-input[disabled]{background-color:#fff!important}.capital-flow-attr__fn-label{position:relative;flex:0 0 72px;text-align:justify;-moz-text-align-last:justify;text-align-last:justify;padding-right:8px;white-space:nowrap;text-overflow:ellipsis;font-size:14px;line-height:1}.capital-flow-attr__fn-label--required{color:red}.capital-flow-attr__fn-label:after{position:absolute;padding:0 2px 0 4px;content:\":\"}", ""]);

// exports
exports.locals = {
	"prefixCls": "capital-flow-attr",
	"capital-flow-attr": "capital-flow-attr",
	"capital-flow-attr__form-item": "capital-flow-attr__form-item",
	"fn-input-pure-wrap": "fn-input-pure-wrap",
	"ant-input": "ant-input",
	"fn-input-pure": "fn-input-pure",
	"fn-select": "fn-select",
	"ant-calendar-picker": "ant-calendar-picker",
	"fn-icon-date": "fn-icon-date",
	"fn-input": "fn-input",
	"ant-input-disabled": "ant-input-disabled",
	"capital-flow-attr__fn-label": "capital-flow-attr__fn-label",
	"capital-flow-attr__fn-label--required": "capital-flow-attr__fn-label--required"
};