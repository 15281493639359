import React from 'react';
import PropTypes from 'prop-types';
import { AddrSug, Icon, DateTimePicker } from 'components';
import { ERROR } from 'constants';
import { buildShowTip, validateFieldsOf, showInfo, typeIs } from 'utils';
import Popover from 'components/utilcomponents/tips/Tooltip/popover';

import { prefixCls } from './index.scss';

class BatchConfirmForm extends React.Component {
  static propTypes = {
    onConfirm: PropTypes.func,
    data: PropTypes.object,
    // // 装货地点
    // g7_load_add: PropTypes.string,
    // // 卸货地点
    // g7_unload_add: PropTypes.string,
    // // 地点是否可编辑
    // g7_add_edit: PropTypes.bool,
    // // 卸货时间
    // g7_unload_t: PropTypes.string,
    // // 卸货时间是否可编辑
    // g7_unload_t_edit: PropTypes.bool,
  };
  constructor(props) {
    super(props);
    const { data } = props;
    this.state = {
      g7_load_add: data.g7_load_add,
      g7_unload_add: data.g7_unload_add,
      g7_unload_t: data.g7_unload_t || '',
    };
    this.g7_unload_t_show = data.g7_unload_t !== undefined;
  }
  componentDidMount() {
    this.initTIp();
  }

  getResData = () =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async resolve => {
      if (!(await validateFieldsOf(this.wrapRef))) {
        resolve(false);
        return false;
      }
      resolve({
        g7_load_add_new: this.state.g7_load_add,
        g7_unload_add_new: this.state.g7_unload_add,
        g7_unload_t: this.state.g7_unload_t,
      });
    });

  initTIp = () => {
    const { g7_add_edit: AddEditable, g7_unload_t_edit: tEditable } = this.props.data;

    if (!AddEditable) {
      const addrTip = buildShowTip({ content: '批次已认证不可修改装、卸货地点' });

      const target1 = this.load_addr.querySelector('span');
      target1.onmouseover = addrTip;
      target1.onmouseout = Popover.hide;

      const target2 = this.unload_addr.querySelector('span');
      target2.onmouseover = e => {
        addrTip(e);
        console.log(e);
      };
      target2.onmouseout = Popover.hide;
    }
    if (!tEditable) {
      const addrTip = buildShowTip({ content: '批次认证中、支付中或已支付完成不可修改到达时间' });
      const target = this.addr_t.querySelector('span');
      target.onmouseover = addrTip;
      target.onmouseout = Popover.hide;
    }
  };

  onChange = key => e => {
    let val = e.target ? e.target.value : e;
    if (val.show_val && val.province) {
      if (val.province && val.province !== val.city) {
        val = `${val.province}${val.show_val}`;
      }
    }
    val = val.show_val || val;
    this.setState({
      [key]: val,
    });
  };

  addrBlurCheck = (key, name, val, event) => {
    const { data = {} } = this.props;
    const oriVal = data[key];
    if (oriVal === val.show_val) {
      return true;
    }
    if (!val.poi) {
      showInfo(ERROR, '请从下拉中选择数据');
      event && event.target && event.target.focus();
      return false;
    }
    const setVal = val; // && val.show_val !== undefined ? addressFullName(val) : val
    this.setState({
      [key]: setVal,
    });
  };

  render() {
    const { g7_load_add: load, g7_unload_add: unload, g7_unload_t: t } = this.state;
    const { data = {} } = this.props;
    const { g7_add_edit: AddEditable, g7_unload_t_edit: tEditable } = data;

    const loadAddr = typeIs(load, 'object') ? load : { show_val: load, province: 'xxx' };
    const unloadAddr = typeIs(unload, 'object') ? unload : { show_val: unload, province: 'xxx' };
    return (
      <div className={prefixCls} ref={r => (this.wrapRef = r)}>
        <div className="form-item">
          <label>结算服务装货地点：</label>
          <div className="form-item-content" ref={ref => (this.load_addr = ref)}>
            <AddrSug
              defaultValue={loadAddr}
              // handleSelected={this.onChange('g7_load_add')}
              // onChange={this.onChange('g7_load_add')}
              onBlur={(val, name, event) => this.addrBlurCheck('g7_load_add', '结算服务装货地点', val, event)}
              mapIcon={false}
              fullName
              required={!!data.g7_load_add}
              placeholder=""
              disabled={!AddEditable}
            />
            <Icon iconType="icon-help" tips="初始取值为批次中某运单的发货地址或本网点组织架构中的联系地址" />
          </div>
        </div>
        <div className="form-item">
          <label>结算服务卸货地点：</label>
          <div className="form-item-content" ref={ref => (this.unload_addr = ref)}>
            <AddrSug
              defaultValue={unloadAddr}
              // handleSelected={this.onChange('g7_unload_add')}
              // onChange={this.onChange('g7_unload_add')}
              onBlur={(val, name, event) => this.addrBlurCheck('g7_unload_add', '结算服务卸货地点', val, event)}
              mapIcon={false}
              fullName
              required={!!data.g7_unload_add}
              placeholder=""
              disabled={!AddEditable}
            />
            <Icon iconType="icon-help" tips="初始取值为某运单的收货地址或线路末端网点组织架构中的联系地址" />
          </div>
        </div>
        {this.g7_unload_t_show && (
          <div className="form-item">
            <label>结算服务卸货时间：</label>
            <div className="form-item-content" ref={ref => (this.addr_t = ref)}>
              <DateTimePicker
                defaultValue={t}
                showTime
                required={!!data.g7_unload_t}
                onChange={this.onChange('g7_unload_t')}
                allowClear={this.props.g7_unload_t}
                disabled={!tEditable}
              />
              <Icon iconType="icon-help" tips="取值为终点到达时间，修改后将更新终点到达时间" />
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default BatchConfirmForm;
