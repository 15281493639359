import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import vega from 'utils/vega';
import ChatEntry from './chatEntry';

// 迁移到messageContext中，避免重连导致父节点渲染
// const getSocketUrl = token => {
//   const config = getVegaRequestConfig(`https://${process.env.VEGA_ORDER_CENTER_WEBSOCKET}`, {
//     method: 'GET',
//   });
//   const wssUrl = `wss://${process.env.VEGA_ORDER_CENTER_WEBSOCKET}?accessid=${
//     config.params.accessid
//   }&sign=${encodeURIComponent(config.params.sign)}&g7timestamp=${config.params.g7timestamp}&access_token=${token}`;

//   return wssUrl;
// };

const getToken = async lastTokenInfo => {
  const { group_id, company_id, user_id } = window;
  const defaultMsg = '系统异常，请联系客服同学反馈！';
  let res;
  let tokenInfo = {};

  try {
    const params = {
      platform_code: 'cyt',
      channel: 4,
      platform_group_id: group_id,
      platform_company_id: company_id,
      platform_user_code: company_id,
      platform_user_id: user_id,
    };

    res = await vega.orderCenter.getAccessToken(params, { notHandleError: true });

    const { code, sub_code, data, sub_msg } = res ?? {};

    if (code === 0 && sub_code === 0) {
      const { access_token: token, tenant_id: tenantId } = data ?? {};

      tokenInfo = {
        token,
        tenantId,
        hasPermission: true,
        // wssUrl: getSocketUrl(token),
      };
    } else {
      tokenInfo = {
        hasPermission: false,
        tokenErrorMessage: sub_msg ?? defaultMsg,
      };
    }
  } catch (error) {
    // 确保再次获取 token, 即使请求失败不丢失 token 信息
    if (lastTokenInfo?.token) {
      tokenInfo = lastTokenInfo;
    }
  }

  return tokenInfo;
};

export default function Chat(props) {
  const [tokenInfo, setTokenInfo] = useState(null);

  const getTokenInfo = lastTokenInfo => {
    getToken(lastTokenInfo).then(res => {
      setTokenInfo(res);
    });
  };

  const setNewWss = () => {
    if (tokenInfo?.token) {
      // 重连时重新获取 vega 配置，生成新的地址，否则有可能连不上
      setTokenInfo({
        ...tokenInfo,
        // wssUrl: getSocketUrl(tokenInfo.token),
      });
    }
  };

  useEffect(() => {
    getTokenInfo();
  }, []);

  return <>{tokenInfo && <ChatEntry {...props} setNewWss={setNewWss} tokenInfo={tokenInfo} />}</>;
}
Chat.propTypes = {
  layoutTabs: PropTypes.array, // 当前打开的tab标签页
};
