import { formatCamelCase } from 'utils';
import _ from 'lodash';

/**
 * 每个卡片折叠展开的状态处理
 * @param cardState 卡片的状态
 * @param cardName 卡片名称
 * @returns  class string
 */
export const getCardHeaderClass = (cardState, cardName) => {
  const stateClass = `card-head ${cardState ? 'turn' : ''}`;
  const nameClass = `${cardName || ''}`;
  return `${stateClass} ${nameClass}`;
};

/**
 * 每个卡片折叠展开的状态处理
 * @param cardState 卡片的状态
 * @param cardName 卡片名称
 * @returns  class string
 */
export const getCardBodyClass = (cardState, cardName) =>
  `card-body ${cardState ? 'card-body-fold' : ''} card-body-${cardName}`;

// 生成customer-item的class
export const getItemClass = itemName => `customer-item item-${itemName}`;

const getShowRequiredFunc = setting => {
  const show = {};
  const required = {};
  const className = {};
  let showField = []; // 显示的字段枚举
  Object.keys(setting).forEach(key => {
    const item = setting[key];
    show[formatCamelCase(`${key}_show`)] = item.show;
    required[formatCamelCase(`${key}_required`)] = item.required;
    className[formatCamelCase(`${key}_class`)] = item.required ? `${key} red` : key;
    if (item.show) showField = [...showField, key];
  });

  return { show, required, className, showField };
};

// 字段显示必填处理逻辑 - 客户管理(客户tab)
export const handleFieldSet = () => {
  const customerFieldSet = _.get(window.company_setting, 'customer_proj_add_field.customer_proj_add_field', {});
  const formatSet = getShowRequiredFunc(customerFieldSet);

  return formatSet;
};
