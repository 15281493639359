/**
 * Created by wangnaihe on 2018/4/18.
 */

import { asyncComponent } from 'utils';

const dynamicImportBatchDetail = () => import(/* webpackChunkName: "Batch-Deatil" */ './index');

export default dynamicImportBatchDetail;

export const AsyncBatchDetail = asyncComponent(dynamicImportBatchDetail);
