exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".message-tmp .fn-btn--default{background:none;border:none}.message-tmp .fn-btn--default:focus .fn-icon,.message-tmp .fn-btn--default:hover .fn-icon-message-tmp{color:#0097cf}.message-tmp .fn-btn--default:active{box-shadow:none}", ""]);

// exports
exports.locals = {
	"message-tmp": "message-tmp",
	"fn-btn--default": "fn-btn--default",
	"fn-icon-message-tmp": "fn-icon-message-tmp",
	"fn-icon": "fn-icon"
};