import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import browserHistory from 'routes/history';
import classnames from 'classnames';
import {
  BiFormateData,
  SlideDrager,
  PopTip,
  PopUp,
  Tabs,
  LodopFunc,
  PrintManager,
  ShowInfo,
  Button,
  ShowList,
} from 'components';
import {
  filterListColumnByKey,
  fromJS,
  getIndexOfList,
  getPageKey,
  filterListColumnByKeyList,
  isEmptyObj,
  fetchApi,
  showInfo,
  confirm,
  alert,
  throttle,
  getComVal,
  formateTableCheckTips,
  setComVal,
  getCcombinderValue,
  getSplitRes,
  isset,
  remDub,
  createTip,
  typeIs,
  buttonOpDialog,
  proxyListPage,
  emitListBtnOp,
  groupIdxSum,
  opTips as NewOpTips,
  g7Done,
  getCookie,
  setCookie,
  getPrintComId,
  validateFieldsOf,
} from 'utils';
import actions from 'actions';
import { handleApiResPrint } from 'components/commoncomponents/listOperation/commonOp';
import { getPrintLocalStorage, setPrintLocalStorage } from 'operation/listOperation/printStorage';
import { COMPANY_ID, ERROR, WARN, CHECK, INFO, validateTips, FLOAT } from 'constants';
import _ from 'lodash';
import TrackLog from 'components/commoncomponents/trackLog';
import BindAction from 'components/commoncomponents/pickorder/BindAction';
import BatchLog from 'pages/TruckController/components/BatchLog';
import Contract from 'pages/TruckController/components/Contract';
import FinanceLog from 'pages/TruckController/components/financeLog';
import { AbnormalLog } from 'pages/OrderController/log';
import CarTrack from 'pages/TruckController/components/carTrack';
import CarLog from 'pages/TruckController/components/carLog';
import {
  cancelLoad,
  acceptLoad,
  arrivalOperate,
  directNewPageInnerFunc,
  odUnloadFunc,
  cancelAcceptLoad,
  cargo,
} from 'components/commoncomponents/listOperation/truckOp.js';
import {
  transFunc,
  signFunc,
  cancelTrans,
  cancelTransAccept,
} from 'components/commoncomponents/listOperation/orderOp.js';
import printSlnDialog from 'components/commoncomponents/printSlnDialog';
import { batchCheckLine, cancelBatchCheckLine } from 'components/commoncomponents/listOperation/orderCheckOp.js';
import { initEmt } from 'components/commoncomponents/listOperation/financialOp.js';
import { handlePrintTableList } from 'components/commoncomponents/listOperation/commonOp.js';
import { checkSend, checkMsgHistory } from 'utils/checkMsgSend';
import { dispatchCarrierHeader } from 'utils/sug/dispatchCarrier';
import payee from 'pages/TruckController/components/payee';
import { checkLODOP } from 'utils/business/print/checkLODOP';
import batchModifyOrder from 'pages/TruckController/components/batchModifyOrder';
import { dealCardPriceSnapshot, dealFeeTablePriceSnapshot } from 'utils/business/batchPickOrder/dealPriceSnapshot';
import {
  setWeightToVolume,
  drNameIconClick,
  trNumIconClick,
  trNumExtIconClick,
  trNumSelectChange,
  drAssistantNameIconClick,
  trNumExtSelectChange,
  getWeightVolume,
  getCarInfo,
  resetRateValue,
  shouldWeightInputChange,
  shouldVolumeInputChange,
} from 'components/commoncomponents/pickorder/TruckPickOrder';
import { commonOpCare } from 'operation/listOperation/batchOp';
import Abnormal from 'components/commoncomponents/Abnormal/Abnormal';
import PriceSnapshotIcon from 'components/commoncomponents/PriceSnapshotIcon';
import ReceiptLog from 'components/commoncomponents/receiptLog';
import { handleBiTableChange, handleLeftToRight } from './elemChangeToTableChange';

import { formulaConf } from '../../../pages/SettingController/set/rosSet/constant';

import { prefixCls } from './index.scss';

const mockData = require('./mockData');
const batchPrintConf = require('./batchPrintConf');
// @connect((state) => {
//   const printerHostId = state.getHostIdRdu.hostId || ','
//   return { printerHostId }
// })
const getPrintType = realTab => {
  const type = {};
  if (!realTab) {
    return type;
  }
  if (realTab.includes('delivery')) {
    type.name = '送货';
    type.key = 'delivery_loading_list';
  } else if (realTab.includes('shuttle')) {
    type.name = '短驳';
    type.key = 'shuttle_loading_list';
  } else if (realTab.includes('pickup_reservation')) {
    type.name = '订单提货';
    type.key = 'pickup_reservation_loading_list';
  } else if (realTab.includes('pickup')) {
    type.name = '提货';
    type.key = 'pickup_loading_list';
  }
  return type;
};

const getSettingItemList = () => {
  const { itemList = [] } =
    window.company_setting.b_f_total_formula && window.company_setting.b_f_total_formula.formulaDt[0];
  if (!itemList.length) return [];
  const value = [...itemList];
  const b_f_total_formula = Object.keys(formulaConf.b_f_total_formula);
  const reIndex = key => {
    let index = 0;
    b_f_total_formula.forEach((item, i) => {
      if (key === item) {
        index = i;
      }
    });
    return index;
  };
  value.sort((a, b) => reIndex(a) - reIndex(b));
  return value;
};
class BatchDetail extends PureComponent {
  static defaultProps = {
    pageType: 'show',
    autoDestroy: true,
    pickOrderInfo: fromJS({ batchTableInfo: { showFooter: false } }),
    pageUrl: '',
  };
  static contextTypes = {
    renderBiPickOrder: PropTypes.func,
    truckLoad: PropTypes.func,
    shuttleLoad: PropTypes.func,
    deliveryLoad: PropTypes.func,
    getPick: PropTypes.func,
    completePack: PropTypes.func,
  };
  /*
   * batchNo: 当前批次号
   * pickOrderInfo： 挑单信息
   */
  static propTypes = {
    batchId: PropTypes.string,
    batchNo: PropTypes.string,
    comId: PropTypes.string,
    pickOrderInfo: PropTypes.any,
    dragerContainer: PropTypes.any,
    close: PropTypes.func,
    autoDestroy: PropTypes.bool,
    type: PropTypes.string,
    trDownPrintType: PropTypes.bool,
    togglePopWidth: PropTypes.func,
    popContainer: PropTypes.object,
    hasTab: PropTypes.bool,
    listPage: PropTypes.any,
    basicId: PropTypes.string,
    goodsInfo: PropTypes.object,
    //  转交类型， 只有在中转批次详情中使用 ？暂时没用
    trans_type: PropTypes.any,
    closeCallback: PropTypes.func,
    // printerHostId: PropTypes.string,
    pickOrderInfo1: PropTypes.any,
    batchLogType: PropTypes.string,
    tableInfo: PropTypes.object,
    realTab: PropTypes.string,
    pageUrl: PropTypes.string,
  };

  constructor(prop) {
    super(prop);
    const setting = window.company_setting;
    this.printType = getPrintType(this.props.realTab);
    this.printOrderKey = this.printType.key;
    this.cardExtData = {}; // sug带的其他信息
    this.state = this.resetState(prop);
    if (window.CLODOP) {
      this.LodopObj = LodopFunc.getLodop();
    }
    if (
      this.LodopObj &&
      this.LodopObj !== 'undefined' &&
      this.LodopObj.VERSION &&
      this.LodopObj.VERSION !== 'undefined'
    ) {
      actions.printActions.getHostId().then(printerHostId => (this.printerHostId = printerHostId));
    } else {
      this.printerHostId = window.globalPrintHostId;
    }
    this.dataMap = {
      b_tr_load_n: 'wh_n',
      b_tr_load_w: 'wh_w',
      b_tr_load_v: 'wh_v',
      b_tr_load_actual_price: 'wh_actual_price',
      b_shuttle_load_actual_price: 'wh_actual_price',
      b_delivery_load_actual_price: 'wh_actual_price',
      b_pickup_load_actual_price: 'wh_actual_price',
      b_shuttle_load_n: 'wh_n',
      b_shuttle_load_w: 'wh_w',
      b_shuttle_load_v: 'wh_v',
      b_delivery_load_n: 'wh_n',
      b_delivery_load_w: 'wh_w',
      b_delivery_load_v: 'wh_v',
    };
    this.resetMap = {
      wh_n: 'num',
      wh_w: 'weight',
      wh_v: 'volume',
      wh_actual_price: 'actual_price_total',
    };
    // 折叠的时候需要显示在header中的字段， cardform非折叠状态下，显示在body下
    this.headerHideKey = ['tr_num', 'dr_name', 'carrier_id', 'arrival', 'dn_mgr_id'];
    // 修改的时候需要在header中显示的字段, 非修改的情况下，不显示，修改状态下显示
    // this.headerEditKey = ['car_batch', 'delivery_batch', 'shuttle_batch', 'pickup_batch', 'trans_batch']
    this.headerEditKey = [];
    // 修改的情况下，显示在body中，非修改的情况下，显示在header中的字段
    this.bodyEditKey = ['route', 'station'];
    // 原来在右侧的数据不进行处理
    this.trLoadingDefaultPrintArray = {
      tr_loading_list1: 'tr_loading_up_list1_print',
      tr_loading_list2: 'tr_loading_up_list2_print',
      tr_loading_list3: 'tr_loading_up_list3_print',
      tr_loading_list4: 'tr_loading_up_list4_print',
    };
    // 入口是否是支付管理
    this.is_from_pay_mgr = prop.pageTab && prop.pageTab.includes('pay_mgr');
    this.priceSnapshot = {}; // 保存的批次价格快照
    this.priceSnapshotDelete = {}; // 编辑过，要删除快照的字段
    this.disabledPickupF = setting.calced_price_disabled && setting.calced_price_disabled.value.includes('pickup_f');
    this.disabledOdDeliveryF =
      setting.calced_price_disabled && setting.calced_price_disabled.value.includes('od_delivery_f');
    this.disabledBDeliveryF =
      setting.calced_price_disabled && setting.calced_price_disabled.value.includes('b_delivery_f');
    this.disabledBPickupF = setting.calced_price_disabled && setting.calced_price_disabled.value.includes('b_pickup_f');
  }

  resetState = prop => {
    this.fromPage = prop.fromPage || false;
    !prop.fromPage && prop.getIsEdited(this);
    this.pageType = prop.pageType;
    let pickOrderInfo = prop.pickOrderInfo.toJS ? prop.pickOrderInfo.toJS() : prop.pickOrderInfo; // props所有数据
    pickOrderInfo = this.dealFeeInfoDisable(prop, pickOrderInfo); // 费用数据处理成disable状态
    pickOrderInfo = dealCardPriceSnapshot(pickOrderInfo); // 显示价格快照
    pickOrderInfo = dealFeeTablePriceSnapshot(pickOrderInfo); // 显示价格快照
    const batchInfo = pickOrderInfo.batch_info;
    // 车辆合计运费初始化
    // if ((this.props.realTab === 'tr_up' || this.props.realTab === 'tr_down') && pickOrderInfo.feeInfo) {
    //   const data = pickOrderInfo.feeInfo.data || []
    //   let totalData = 0
    //   data.forEach((item) => {
    //     totalData += (+item.b_billing_f || 0) + (+item.b_fuel_card_f || 0) + (+item.b_receipt_f || 0) + (+item.b_arr_f || 0)
    //   })
    //   pickOrderInfo.batchTitleInfo.cardFormHeader.truck_total = setComVal(pickOrderInfo.batchTitleInfo.cardFormHeader.truck_total, totalData).toJS()
    // }

    // 结算服务1.0.8 配载后自动认证，且认证成功后才可操作发车 start
    const { batch_info = {} } = pickOrderInfo;
    const batchType = [1, 3, 5, 7].includes(+batch_info.type);
    const pmsn = window.permission.includes('payMgr');
    const departed = +batch_info.blk_flag >= 50 && +batchInfo.batch_id === +batchInfo.head_b_link_id;

    let opPageType = 'add'; // 新增配载 add |  修改批次 update  |  批次详情 view

    const { batchTitleInfo = {} } = pickOrderInfo;
    const showHideCardInfo = batchTitleInfo.cardInfo || {};

    if (prop.pageType === 'show') {
      opPageType = 'view';
    } else if (+batch_info.batch_id) {
      opPageType = 'update';
    }
    this.cardExtData = {
      route_id: pickOrderInfo.batch_info.route_id,
      line_distance: pickOrderInfo.batch_info.line_distance,
      tr_num: pickOrderInfo.batch_info.b_tr_num,
      tr_num_ext: pickOrderInfo.batch_info.b_tr_num_ext,
      dr_id: pickOrderInfo.batch_info.b_dr_id || pickOrderInfo.batch_info.dr_id,
      tr_type: pickOrderInfo.batch_info.tr_type,
      tr_length: pickOrderInfo.batch_info.tr_length,
      carrier_name: pickOrderInfo.batch_info.b_tr_team_name,
      carrier_id: pickOrderInfo.batch_info.b_tr_team_id,
    };

    console.log(' === headTruckT ==== ');
    if (g7Done() && batchType && pmsn) {
      const isAutoImportChecked = getCookie(`isAutoImportChecked_${window.user_id}`) === 'true' || false;
      const isAutoImportDisabled = prop.pageType === 'show' || +batchInfo.b_tr_g7_import_st === 2;

      showHideCardInfo.is_auto_import.display = true;
      showHideCardInfo.is_auto_import_tip.display = true;
      opPageType === 'add' && (showHideCardInfo.is_auto_import.otherProps.checked = isAutoImportChecked);
      showHideCardInfo.is_auto_import.otherProps.disabled = isAutoImportDisabled;
    } else {
      delete showHideCardInfo.is_auto_import_tip;
      delete showHideCardInfo.is_auto_import;
    }

    pickOrderInfo.batchTitleInfo && (pickOrderInfo.batchTitleInfo.cardInfo = showHideCardInfo);
    // 结算服务1.0.8 配载后自动认证，且认证成功后才可操作发车 end

    let newState = {
      pickOrderInfo: this.dealheader(this.handleDefaultPrint(pickOrderInfo)),
      selectTab: '1',
      type: prop.pageType || 'show',
      feeDefaultData: (pickOrderInfo.feeInfo && pickOrderInfo.feeInfo.data) || [], // 默认费用信息
      wRate: 1,
      vRate: 1,
    };
    if (this.props.realTab && mockData.wv_radio[this.props.realTab]) {
      newState = { ...mockData.wv_radio[this.props.realTab], ...newState };
    }
    this.isEdit = false;
    this.isBiggest = false; // 修改的状况下是否点击过最大化和最小化
    // 设置配载率
    this.setWeightToVolume();
    if (window.CLODOP) {
      this.LodopObj = LodopFunc.getLodop();
    }
    // 折叠的时候需要显示在header中的字段， cardform非折叠状态下，显示在body下
    this.formateLeft = true;
    // 原来在右侧的数据不进行处理
    this.defaultDataFormate = false;
    this.resetDefaultDataFlag = false;
    if (prop.type === 'add') {
      this.defaultDataFormate = true;
      this.resetDefaultDataFlag = false;
    }
    // prop.type !== 'show' ? this.defaultDataFormate = true : ''
    this.gcurrPageInfo = null;
    this.checkFeeChange = {};
    this.feeChange = {};
    this.truckTeamSource = (batchInfo || {}).truck_team_source;
    return newState;
  };

  UNSAFE_componentWillMount = () => {
    const { batchId, batchNo, comId } = this.props;
    const tab = this.props.realTab;
    // 此处type 只影响后台渲染不同的模板, 批次详情第一次都是show的状态
    // let type = this.props.type,
    const config = mockData[tab] || {};
    const pageUrl = config.pageUrl || '/Truck/Truck/onLoading';
    // 此处有上下游的情况
    const printUrl = config.printUrl || '/Table/Search/trLoadingList';
    const printTab = config.printTab || '';
    const printTitle = config.printTitle || '';
    const printType = config.printType || 1;
    this.setState({
      // type: type === 'add' ? type : 'show',
      pageUrl,
      comId,
      batchId,
      batchNo,
      printTab, // 打印预览的 tab
      printTitle,
      printType, // 打印信息的上下游
      printUrl,
    });
    this.priceKeyList = config.priceKeyList;
    this.priceType = config.priceType;
    this.need_calc_price = config.need_calc_price;
    this.useJAVAPrice = window.company_setting.use_java_price && window.company_setting.use_java_price.checked;
    this.defaultDataFormate = (this.useJAVAPrice && this.need_calc_price) || pageUrl === '/Truck/Delivery/onLoading';
    if (window.CLODOP) {
      this.LodopObj = LodopFunc.getLodop();
    }
  };

  componentDidMount() {
    if (!this.state.pickOrderInfo.batch_info) {
      createTip('批次信息获取失败，请重新打开', WARN).show();
      setTimeout(() => {
        this.props.closeTab && this.props.closeTab();
      }, 100);
      return false;
    }
    this.gcurrPageInfo = this.gcurrPageInfo || getPageKey();
    if (this.gcurrPageInfo.pageConfig) {
      const { key } = this.gcurrPageInfo.pageConfig;
      // 清理缓存中的table数据
      actions.api.apiClear([key, 'tableInfo']);
    }
    this.getTruckTeamSource();
  }

  setWeightToVolume = () => setWeightToVolume(this);
  drNameIconClick = (key, form, data) => drNameIconClick(this, key, form, data);
  drAssistantNameIconClick = (key, form, data) => drAssistantNameIconClick(this, key, form, data);
  trNumIconClick = (key, form, data) => trNumIconClick(this, key, form, data);
  trNumExtIconClick = (key, form, data) => trNumExtIconClick(this, key, form, data);
  trNumSelectChange = (value, key, data, rightTable) => trNumSelectChange(this, value, key, data, rightTable);
  trNumExtSelectChange = (value, key, data, rightTable) => trNumExtSelectChange(this, value, key, data, rightTable);
  shouldWeightInputChange = (value, ...rest) => shouldWeightInputChange(this, value, ...rest);
  shouldVolumeInputChange = (value, ...rest) => shouldVolumeInputChange(this, value, ...rest);
  getWeightVolume = tableList => getWeightVolume(this, tableList);
  getCarInfo = (extCardData = {}) => getCarInfo(this, extCardData);
  resetRateValue = (newValue, paraClear, paraSet, extCardData) =>
    resetRateValue(this, newValue, paraClear, paraSet, extCardData);

  getTruckTeamSource = () => {
    const batchInfo = this.state.pickOrderInfo.batch_info;
    const truckTeamSource = (batchInfo || {}).truck_team_source;
    const tTSClassName = document.getElementsByClassName('f2-car-team')[1];
    if (!tTSClassName) return;
    const { className } = tTSClassName;
    if (truckTeamSource && !className.includes('active')) {
      tTSClassName.className = `${className} active`;
    } else if (!truckTeamSource && className.includes('active')) {
      tTSClassName.className = className.replace('active', '');
    }
  };

  closeContent = redirectUrl => {
    if (this.fromPage) {
      if (redirectUrl) {
        this.props.closeTab();
        this.props.redirectRouter({ pathname: redirectUrl }, false, true);
      } else {
        const pickOrder = this.context.getPick();
        pickOrder.requestPickTable();
      }
    } else {
      this.props.close();
      if (typeof this.props.closeCallback === 'function') {
        this.props.closeCallback();
      } else {
        const pickOrder = this.context.getPick();
        pickOrder.requestPickTable();
      }
    }
  };
  sendReq = (url, req) => {
    const param = { method: 'POST', body: { req } };
    return fetchApi(url, param);
  };
  handleDefaultPrint = pickOrderInfo => {
    const pickInfo = pickOrderInfo;
    if (this.printOrderKey && !this.handleDetaultPrintOrder && pickOrderInfo && pickOrderInfo.batchTableInfo) {
      this.handleDetaultPrintOrder = true;
      const sublist = _.get(pickInfo, 'batchTableInfo.right.otherForm.printOrder.otherProps.data');
      let printLocalStorage = getPrintLocalStorage(this.printOrderKey, sublist);
      if (printLocalStorage && printLocalStorage[0]) {
        printLocalStorage = printLocalStorage[0];
        _.set(pickInfo, 'batchTableInfo.right.otherForm.printOrder.otherProps.value', printLocalStorage);
      }
    }
    return pickInfo;
  };

  chagenFeeInfoDisable = (feeData, flag) => {
    const newFeeData = feeData;
    const index = this.getCompanyIndex(feeData);
    if (index === -1) return feeData;
    let currPidInfo = feeData[index];
    const defaultDiable = this.state.feeDefaultData[index].otherProps.disable;
    currPidInfo = fromJS(currPidInfo).setIn(['otherProps', 'disable'], defaultDiable).toJS();
    newFeeData[index] = currPidInfo;
    return newFeeData;
  };
  dealFeeInfoDisable = (prop, pickOrderInfo) => {
    if (!pickOrderInfo.feeInfo) return pickOrderInfo;
    const { pageTab } = prop;
    const { pageType } = prop;
    if (prop.realTab && prop.realTab === 'tr_down' && prop.type === 'view') {
      let feeInfo = pickOrderInfo.feeInfo.data; // 费用数据
      const index = this.getCompanyIndex(feeInfo);
      if (index === -1) return pickOrderInfo;
      // 已经审核的情况下， 不能修改
      if (!this.checkIsSet('batch_check', undefined, pickOrderInfo)) return pickOrderInfo;
      feeInfo = this.chagenFeeInfoDisable(feeInfo, false);
      return fromJS(pickOrderInfo).setIn(['feeInfo', 'data'], feeInfo).toJS();
    }
    return pickOrderInfo;
  };

  // 统一添加保存icon操作
  dealheader = pickOrderInfo => {
    const titleInfo = pickOrderInfo.batchTitleInfo || {};

    if (titleInfo.cardInfo && titleInfo.cardInfo.b_tr_team_id) {
      titleInfo.cardInfo.b_tr_team_id.otherProps.tableHeader = dispatchCarrierHeader;
    }
    if (titleInfo.cardInfo && titleInfo.cardInfo.b_tr_team_name) {
      titleInfo.cardInfo.b_tr_team_name.otherProps.tableHeader = dispatchCarrierHeader;
    }
    if (titleInfo.cardInfo && titleInfo.cardInfo.one_way_of_miles) {
      titleInfo.cardInfo.one_way_of_miles.otherProps.children = <span className="input-icon unit">km</span>;
    }
    if (titleInfo.cardInfo && titleInfo.cardInfo.total_time) {
      titleInfo.cardInfo.total_time.otherProps.children = <span className="input-icon unit">h</span>;
    }
    if (titleInfo.cardInfo && titleInfo.cardInfo.line_time) {
      titleInfo.cardInfo.line_time.otherProps.children = <span className="input-icon unit">h</span>;
    }
    if (titleInfo.cardInfo && titleInfo.cardInfo.standard_oil) {
      titleInfo.cardInfo.standard_oil.otherProps.children = <span className="input-icon unit">升/百公里</span>;
    }
    if (titleInfo.cardInfo && titleInfo.cardInfo.theory_oil) {
      titleInfo.cardInfo.theory_oil.otherProps.children = <span className="input-icon unit">升</span>;
    }
    return pickOrderInfo;
  };
  // dealFeeInfoDisable = (prop, pickOrderInfo) => {
  //   if (!pickOrderInfo.feeInfo) return pickOrderInfo
  //   const pageTab = prop.pageTab
  //   const type = prop.pageType
  //   if ((pageTab && pageTab.indexOf('tr_down') > -1) && type === 'view') { // 到车管理
  //     const feeInfo = pickOrderInfo.feeInfo.data  // 费用数据
  //     const disableArr = ['b_rmt_unload_f', 'b_rmt_misc_f', 'b_rmt_unload_f_paid', 'b_rmt_misc_f_paid', 'b_arr_f', 'b_rmt_landing_f', 'plan_arr_t']
  //     const newFeeInfo = []
  //     feeInfo.forEach((item, index) => {
  //       if (index !== 0) {
  //         const disable = item.otherProps.disable
  //         const newDisable = [...disable, ...disableArr]
  //         const newItem = fromJS(item).setIn(['otherProps', 'disable'], newDisable).toJS()
  //         newFeeInfo.push(newItem)
  //       } else {
  //         newFeeInfo.push(item)
  //       }
  //     })
  //     return fromJS(pickOrderInfo).setIn(['feeInfo', 'data'], newFeeInfo).toJS()
  //   }
  //   return pickOrderInfo
  // }
  // 此侧拉中的内容是否发生修改
  isEdited = () => {
    const biPickOrder = this.context.getPick();
    const isEdit = biPickOrder ? biPickOrder.isEdit : this.isEdit;
    return isEdit || false;
  };
  /*
   * 检验list 中某个属性值，是否符合要求
   * 例如：运单信息中的next_com_id  如果此项为0 ， 不允许进行送货/短驳
   * list = 运单列表
   * variable = 属性名
   * title = 提示的type文字 例如 送货／短驳
   * callback = 继续的回调函数
   */
  checkOneVariable = (list, variable, title, callback) => {
    console.log(list, variable, title);
    const canNotList = [];
    const canList = [];
    const tips = '';
    const _comId = [];
    const _canComId = [];
    const { company_id, bsc_company_id } = this.state.pickOrderInfo.batch_info;
    list.map(key => {
      const _id = key.od_tail_od_link_id || key.od_link_id;
      if (key.b_link_id) {
        return null;
      }
      const orderNum = key.order_num;
      _comId.push(key.next_com_id);
      if (company_id === bsc_company_id) {
        canList.push(key.od_link_id);
        _canComId.push(key.next_com_id);
      } else if (Number(key[variable]) === 0 || key[variable] === 'undefined') {
        canNotList.push(orderNum);
      } else {
        canList.push(_id);
        _canComId.push(key.next_com_id);
      }
      return canNotList;
    });
    // if (canList.length < 1) {
    //   createTip(`不允许进行${title} `, ERROR).show()
    //   return false
    // }
    const canComId = remDub(_canComId);
    // if (canComId.length > 1) {
    //   createTip(`不能多网点${title}`, ERROR).show()
    //   return false
    // }
    // if (canNotList.length > 0) {
    //   tips = (
    //     <div>
    //       <p>以下运单不允许{title}</p>
    //       <p>{canNotList.join(',')}</p>
    //       <p>是否继续？</p>
    //     </div>
    //   )
    //   new PopUp(ShowInfo, {
    //     type: INFO,
    //     content: tips,
    //     title,
    //     simpleMode: {
    //       confirm: (thisDialog) => {
    //         callback && callback(canList, canComId)
    //         thisDialog.handleHide()
    //       },
    //       cancel: (thisDialog) => thisDialog.handleHide(),
    //     },
    //   }).show()
    //   return false
    // }
    callback && callback(canList, canComId);
  };
  changeTotalFeeInfo = (data, col) => {
    let totalData = 0;
    let totalData1 = 0;
    const itemList = getSettingItemList();
    data.forEach(item => {
      totalData +=
        (+item.b_billing_f || 0) + (+item.b_fuel_card_f || 0) + (+item.b_receipt_f || 0) + (+item.b_arr_f || 0);
      itemList.forEach(j => {
        totalData1 += +item[j] || 0;
      });
    });
    const pickOrder = this.context.getPick();
    let value = +totalData.toFixed(2);
    if (itemList.includes(col)) {
      value = +totalData1.toFixed(2);
    }
    this.truckTotalInputChangeRelateFeeData(
      value,
      () => {
        pickOrder && pickOrder.setCardFormItemValue('truck_total', value);
      },
      col,
    );
  };
  // 配载费用
  handleFeeTableBlur = (feeInfo, rowIndex, col, value) => {
    if (this.props.realTab === 'tr_up' || this.props.realTab === 'tr_down') {
      const disableTruckTotal =
        window.company_setting.calced_price_disabled &&
        window.company_setting.calced_price_disabled.value.includes('b_tr_f_total');
      if (!disableTruckTotal) {
        this.changeTotalFeeInfo((feeInfo || {}).data, col);
      }
    }
  };
  // 获取feeList中是否含有当前网点
  getCompanyIndex = feeData => {
    const pid = this.props.comId || window[COMPANY_ID]; // 当前登录的公司
    let index = -1;
    index = getIndexOfList(feeData, 'company_id', pid.toString());
    return index;
  };
  splitFee = async ({ total, list, feeKey, feeKeyDesc, splitWay, opType }) => {
    if (!total) {
      return;
    }
    // disable 不能分摊的原因. 不能分摊的单号 + 能分摊的单号
    const orderType =
      isset(this.state, 'pickOrderInfo.batchTableInfo.tableFilter.category', '') === 'Reservation'
        ? 'reservation_num'
        : 'order_num';
    const disNums = [];
    const editNums = [];
    list.forEach(item => {
      if (_.get(item, 'disable', []).includes(feeKey) || _.get(item, 'otherProps.disable', []).includes(feeKey)) {
        disNums.push(item[orderType]);
      } else {
        editNums.push(item[orderType]);
      }
    });
    const disCfm = (
      <div className="split-pickup-dis">
        以下运单的{feeKeyDesc}不能被修改，是否继续对其他运单进行分摊？选择“继续”，{feeKeyDesc}合计将减去不能修改的
        {feeKeyDesc}的金额之后，分摊到其他运单上。
      </div>
    );
    if (
      disNums.length &&
      !(await confirm(WARN, disCfm, {}, '操作提醒', <p className="pickup-num-list"> {disNums.join(',')} </p>))
    ) {
      return;
    }
    return getSplitRes(feeKey, orderType, splitWay, +total, list, disNums, editNums, opType);
  };
  handleTableFormClick = (key, val, form, data) => {
    if (key === 'printOrder' && !data.printOrder.otherProps.data.length) {
      const type = this.printType.name;
      if (!type) {
        return;
      }
      const mockRes = {
        errno: 1,
        errmsg: '',
        dbg: [],
        res: {
          message: `您还未设置${type}清单打印模板，无法打印！<br> 请前往打印设置页面进行设置！`,
          detail: {
            title: '原因说明',
            content: `需前往公司管理->打印设置页面点击"添加模板"按钮设置模板类型=${type}清单的自定义模板后才能打印`,
          },
          url: { button_name: '前往设置', url: '/Print/printSetting' },
        },
        app_info: null,
      };
      buttonOpDialog(mockRes.res);
    }
  };
  handlePrint = (ids, _comId, tplKey, cb) => {
    if (!ids.length || !ids[0] || !tplKey || !this.printOrderKey) {
      return cb && cb();
    }
    // let prinCopies = pickOrderInfo.ext.print_copies
    const selected = {
      tpl_key: tplKey,
      tpl_type: this.printOrderKey,
    };
    const printComId = getPrintComId(_comId);
    actions.orderServer
      .getPrintInfo({
        cid: window.globalPrintHostId,
        tpl_id: tplKey,
        fields_type: selected.tpl_type,
        ids,
        company_id: printComId,
      })
      .then(rest => {
        handleApiResPrint(rest.data, selected, ids, { company_id: printComId, group_id: window.group_id });
      });
    cb && cb();
  };
  handleOnClick = async (key, form, data, value) => {
    console.log(value);
    const pickCom = this.context.getPick();
    const cardCom = pickCom.cardForm;
    let idata = fromJS(data);
    let idata2;
    switch (key) {
      case 'change_type':
        {
          const routeLine = Object.assign({}, data.route_line); // 替换的空数据
          const routeCustom = Object.assign({}, data.route_custom); // 默认数据
          const changeTypeTips = data.change_type.otherProps.tips; // 路线切换按钮的tips
          const defaultRoute = getComVal(data.route_custom) || [];
          if (changeTypeTips === '切换为选择车辆线路') {
            // 此时为自定义
            idata = idata.setIn(['change_type', 'otherProps', 'tips'], '切换为自定义车辆线路');
            idata = idata.setIn(['change_type', 'otherProps', 'tips'], '切换为自定义车辆线路');
            idata2 = fromJS({ ...idata.toJS(), route: routeLine });
            idata2 = idata2.setIn(['route', 'otherProps', 'defaultSelected'], '');
          } else {
            // 此时为车辆线路
            idata = idata.setIn(['change_type', 'otherProps', 'tips'], '切换为选择车辆线路');
            idata2 = fromJS({ ...idata.toJS(), route: routeCustom });
            idata2 = idata2.setIn(['route', 'otherProps', 'value'], [defaultRoute[0]]);
          }
          idata2 = idata2
            .setIn(['route', 'classname'], 'feeItem')
            .setIn(['route', 'labelProps', 'isRequired'], true)
            .setIn(['route', 'validate'], ['require']);
          cardCom.setData(idata2.toJS());

          pickCom.handleRouteChange([defaultRoute[0]], form, idata2.toJS());
        }
        break;
      case 'truck_team_source': // 使用平台车队
        {
          const type = this.pageType;
          if (type === 'view') return;
          let headerData = fromJS(cardCom.getStateHeader());
          const className = headerData.getIn([key, 'classname']);
          // const truckTeamSource = document.getElementsByClassName('f2-car-team')[1]
          // const className = truckTeamSource.className
          if (className.includes('active')) {
            this.truckTeamSource = 0;
            // truckTeamSource.className = className.split(' ')[0]
            headerData = headerData.setIn([key, 'classname'], className.replace(' active', ''));
          } else {
            this.truckTeamSource = 1;
            // truckTeamSource.className = `${className} active`
            headerData = headerData.setIn([key, 'classname'], `${className} active`);
          }
          cardCom.setHeaderData(headerData.toJS());
        }
        break;
      case 'is_auto_import':
        setCookie(`isAutoImportChecked_${window.user_id}`, form[key]);
        break;
      case 'fee_ave_type':
        {
          const splitWayObj = data.fee_ave_type ? getCcombinderValue(data.fee_ave_type.otherProps.data) : {};
          const splitPickupObj = data.pickup_f_total ? getCcombinderValue(data.pickup_f_total.otherProps.data) : {};
          const splitWay = splitWayObj ? splitWayObj.fee_ave_type : 'order';
          const splitPickup = splitPickupObj ? splitPickupObj.pickup_f_total : '';
          const splitBatchPickup = data.b_pickup_f ? data.b_pickup_f.otherProps.inputProps.defaultValue : '';
          const pickCntRef = this.context.getPick() || {};
          const biPickRef = pickCntRef.biPickOrder;
          let splitList = biPickRef.getTableData('right') || [];
          let newRight;
          if (!splitList.length || (!+splitPickup && !splitBatchPickup)) {
            return;
          }
          const hasF = splitList.some(item => (!this.disabledPickupF && +item.pickup_f) || +item.b_pickup_f);
          if (hasF) {
            const splitCfm = (
              <div className="split-pickup">
                <p>是否重新分摊{!this.disabledPickupF ? '单票提货费和' : ''}提货费？</p>
                <p>重新分摊之后将覆盖运单上的{!this.disabledPickupF ? '单票提货费和' : ''}提货费。</p>
              </div>
            );
            if (hasF && !(await confirm(WARN, splitCfm, {}, '操作确认'))) {
              return;
            }
          }
          let splitPickFRes;
          if (!this.disabledPickupF) {
            splitPickFRes = await this.splitFee({
              total: splitPickup,
              list: splitList,
              feeKey: 'pickup_f',
              feeKeyDesc: '单票提货费',
              splitWay,
              opType: '提货',
            });
            if (splitPickFRes === false) {
              return;
            }
            if (splitPickFRes) {
              splitList = splitPickFRes;
              newRight = splitPickFRes;
            }
          }
          splitPickFRes = await this.splitFee({
            total: splitBatchPickup,
            list: splitList,
            feeKey: 'b_pickup_f',
            feeKeyDesc: '提货费',
            splitWay,
            opType: '提货',
          });
          if (splitPickFRes) {
            newRight = splitPickFRes;
          }
          if (!newRight) {
            return;
          }
          const cardPickupObj = data.pickup_f_total ? getCcombinderValue(data.pickup_f_total.otherProps.data) : {};
          const cardPickup = cardPickupObj ? cardPickupObj.pickup_f_total : '';
          const calcPickupFee = this.getCalcPickUp('', data);
          const listSum = newRight.reduce(
            (all, item) => (+item.pickup_f ? +(all + +item.pickup_f).toFixed(2) : all),
            0,
          );

          biPickRef.resetTableData('right', newRight);
          setTimeout(
            () => this.setNewCard(data, +cardPickup !== +calcPickupFee || (+listSum && +cardPickup !== +listSum)),
            100,
          );
        }
        break;
      case 'delivery_f_ave_button': {
        let splitWay = isset(data.fee_ave_type, 'otherProps.defaultSelected.0.key', '');

        const splitBatchDelivery = data.b_delivery_f ? data.b_delivery_f.otherProps.inputProps.defaultValue : '';
        const sliptDelivery = data.delivery_f_total ? data.delivery_f_total.otherProps.defaultValue : '';
        const pickCntRef = this.context.getPick() || {};
        const biPickRef = pickCntRef.biPickOrder;
        let splitList = biPickRef.getTableData('right') || [];
        let newRight;
        if (!splitList.length || (!+splitBatchDelivery && !+sliptDelivery)) {
          return;
        }
        if (splitWay === 'custom') {
          splitWay = 'b_delivery_custom_load';
        }
        const hasF = splitList.some(item => (!this.disabledOdDeliveryF && +item.od_delivery_f) || +item.b_delivery_f);
        const splitCfm = (
          <div className="split-pickup">
            <p>是否重新分摊{!this.disabledOdDelivery ? '单票送货费和' : ''}送货费？</p>
            <p>重新分摊之后将覆盖运单上的{!this.disabledOdDelivery ? '单票送货费和' : ''}送货费。</p>
          </div>
        );
        if (hasF && !(await confirm(WARN, splitCfm, {}, '操作确认'))) {
          return;
        }
        let splitPickFRes;
        if (!this.disabledOdDeliveryF) {
          splitPickFRes = await this.splitFee({
            total: sliptDelivery,
            list: splitList,
            feeKey: 'od_delivery_f',
            feeKeyDesc: '单票送货费',
            splitWay,
            opType: '送货',
          });
          if (splitPickFRes === false) {
            return;
          }
          if (splitPickFRes) {
            splitList = splitPickFRes;
            newRight = splitPickFRes;
          }
        }
        splitPickFRes = await this.splitFee({
          total: splitBatchDelivery,
          list: splitList,
          feeKey: 'b_delivery_f',
          feeKeyDesc: '送货费',
          splitWay,
          opType: '送货',
        });
        if (splitPickFRes) {
          newRight = splitPickFRes;
          newRight = this.calcDeliveryPayMonthly(newRight);
        }
        biPickRef.resetTableData('right', newRight);
        break;
      }
      default:
        break;
    }
  };
  calcDeliveryPayMonthly = data => {
    const pick = this.context.getPick();
    const cardFormData = pick.getCardFormData();
    const radio = _.get(cardFormData, 'delivery_pay_monthly_ratio.display')
      ? _.get(cardFormData, 'delivery_pay_monthly_ratio.otherProps.defaultValue', 0)
      : 0;
    let _data = data;

    const showPayMonthly = _.get(pick.state, 'tableList.right.header.pay_monthly.display') === 'show';
    const showbPickupF = _.get(pick.state, 'tableList.right.header.b_delivery_f.display') === 'show';
    if (+radio && showPayMonthly && showbPickupF) {
      _data = data.map(item => {
        const iitem = item;
        const payMonthlyEditable = !_.get(item.otherProps, 'disable', []).includes('pay_monthly');
        if (payMonthlyEditable) {
          iitem.pay_monthly = ((+item.b_delivery_f || 0) * +radio || '').toFixed(2);
        }
        return iitem;
      });
    }
    return _data;
  };
  calcPickupPayMonthly = data => {
    const pick = this.context.getPick();
    const cardFormData = pick.getCardFormData();
    const radio = _.get(cardFormData, 'pickup_pay_monthly_ratio.display')
      ? _.get(cardFormData, 'pickup_pay_monthly_ratio.otherProps.defaultValue', 0)
      : 0;
    let _data = data;

    const showPayMonthly = _.get(pick.state, 'tableList.right.header.pay_monthly.display') === 'show';
    if (+radio && showPayMonthly) {
      _data = data.map(item => {
        const iitem = item;
        const payMonthlyEditable = !_.get(item.otherProps, 'disable', []).includes('pay_monthly');
        if (payMonthlyEditable) {
          iitem.pay_monthly = ((+item.b_pickup_f || 0) * +radio || '').toFixed(2);
        }
        return iitem;
      });
    }
    return _data;
  };
  // 从左屏到右屏挑单时，运单的默认值处理
  resetDefaultData = (item, header, enumerations, index, refreshDataMap) => {
    // console.log(enumerations)
    const { tab } = this.props.listPage.tableInfo;
    const newItem = handleLeftToRight(tab, [item], header);
    const res = newItem[0];
    if (refreshDataMap) {
      res.b_pickup_load_n_bak = +res.b_pickup_load_n || 0;
      res.b_pickup_load_v_bak = +res.b_pickup_load_v || 0;
      res.b_pickup_load_w_bak = +res.b_pickup_load_w || 0;
      // 不再库存运单可提货设置勾选
      if (window.company_setting.not_stock_pickup && window.company_setting.not_stock_pickup.checked) {
        res.b_pickup_load_n = (+res.num || 0) - (+res.b_pickup_load_n || 0);
        res.b_pickup_load_v = (+res.volume || 0) - (+res.b_pickup_load_v || 0);
        res.b_pickup_load_w = (+res.weight || 0) - (+res.b_pickup_load_w || 0);
      } else {
        res.b_pickup_load_n = (+res.wh_n || 0) - (+res.b_pickup_load_n || 0);
        res.b_pickup_load_v = (+res.wh_v || 0) - (+res.b_pickup_load_v || 0);
        res.b_pickup_load_w = (+res.wh_w || 0) - (+res.b_pickup_load_w || 0);
      }
      res.resetPickupValue = true;
    }
    const pick = this.context.getPick();
    // 提货送货费 能否编辑和cardform保持一致
    if (tab === 'delivery_batch') {
      const bDelieveryFDisable = pick.state.cardFormData.b_delivery_f.otherProps.readOnly;
      if (bDelieveryFDisable) {
        res.otherProps.disable.push('b_delivery_f');
      }
      if (this.disabledOdDeliveryF && header.od_delivery_f) {
        res.otherProps.disable = Array.from(new Set([...res.otherProps.disable, 'od_delivery_f']));
        res.otherProps.od_delivery_f = res.otherProps.od_delivery_f || {};
        res.otherProps.od_delivery_f.tips = '已启用价格计算，不允许编辑。';
      }
    }
    if (tab === 'pickup_batch' || tab === 'pickup_reservation_batch') {
      const bPickupFDisable = pick.state.cardFormData.b_pickup_f.otherProps.readOnly;
      if (bPickupFDisable) {
        res.otherProps.disable.push('b_pickup_f');
      }
      if (this.disabledPickupF && header.pickup_f) {
        res.otherProps.disable = Array.from(new Set([...res.otherProps.disable, 'pickup_f']));
        res.otherProps.pickup_f = res.otherProps.pickup_f || {};
        res.otherProps.pickup_f.tips = '已启用价格计算，不允许编辑。';
      }
    }
    const deliveryNoFeeTypes = _.get(window.company_setting.delivery_no_fee_types, 'value', []);
    if (deliveryNoFeeTypes.includes(item.delivery_mode)) {
      res.otherProps = res.otherProps || {};
      res.otherProps.disable = res.otherProps.disable || [];
      res.otherProps.disable.push('od_delivery_f');
      res.otherProps.od_delivery_f = res.otherProps.b_delivery_f || {};
      res.otherProps.od_delivery_f.tips = '该运单的送货方式不允许填写单票送货费！';
      res.otherProps.disable.push('b_delivery_f');
      res.otherProps.b_delivery_f = res.otherProps.b_delivery_f || {};
      res.otherProps.b_delivery_f.tips = '该运单的送货方式不允许填写或分摊送货费！';
    }
    return res;
  };
  rightDataFormate = item => {
    const newItem = item;
    if (this.state.type === 'add') {
      Object.keys(this.dataMap).forEach(key => {
        const whKey = this.dataMap[key];
        if (item[key] && +item[key] > 0) {
          // 拆单的情况， 将装车或者送货的货物恢复到库存
          if (+item[whKey] + +item[key] < item[this.resetMap[whKey]]) {
            newItem[whKey] = +item[whKey] + +item[key];
          } else {
            newItem[whKey] = item[this.resetMap[whKey]];
          }
        }
      });
      newItem.b_pickup_load_n = +newItem.b_pickup_load_n_bak || 0;
      newItem.b_pickup_load_v = +newItem.b_pickup_load_v_bak || 0;
      newItem.b_pickup_load_w = +newItem.b_pickup_load_w_bak || 0;
    }
    return newItem;
  };
  UNSAFE_componentWillReceiveProps = nextProps => {
    if (window.CLODOP && !this.LodopObj) {
      // 刷地址栏时，获取不到lodop  17/03/26
      this.LodopObj = LodopFunc.getLodop();
    }
    // if (nextProps.printerHostId) {
    //   this.printerHostId = nextProps.printerHostId || ''
    // }
  };
  // 批次详情操作 check
  filterCheck = (text, value) => {
    const list = value.tableInfo.list || [];
    const select = value.tableInfo.select || [];
    const newList = [];
    select.forEach(item => {
      newList.push(list[item]);
    });
    if (!this.emptyCheck(newList, text)) return false;
    return newList;
  };
  //  空值验证
  emptyCheck = (data, text) => {
    if (data === undefined || data.length === 0) {
      createTip(`没有要${text}的运单`, ERROR).show();
      return false;
    }
    return true;
  };
  // 操作前验证设置
  checkIsSet = (item, tips, pickInfo) => {
    const pickOrderInfo = pickInfo || this.state.pickOrderInfo;
    const sysSet = pickOrderInfo.batch_info.sys_set;
    if (sysSet[item]) {
      createTip(tips || '批次已审核无法修改！', ERROR).show();
      return false;
    }
    return true;
  };
  // 获取选中ids
  getSelectids = value => {
    const tableInfo = value.tableInfo || {};
    const selectList = tableInfo.select || [];
    const dataList = tableInfo.list || [];
    const ids = [];
    const keys = [];
    // const preTableInfo = this.gcurrPageInfo.pageConfig.tableInfo || {}
    // const tab = preTableInfo.tab || ''
    const tab = this.props.realTab;
    // 下游的情况，需要取下游的id
    if (tab && tab.indexOf('_down') !== -1) {
      keys.push('od_tail_od_link_id');
    } else {
      keys.push('od_link_id');
    }
    selectList.forEach(index => {
      if (dataList[index].b_link_id) return;
      keys.forEach(key => {
        ids.push(dataList[index][key]);
      });
    });
    return ids;
  };
  // 获取选中company_id
  getSelectComids = value => {
    const tableInfo = value.tableInfo || {};
    const selectList = tableInfo.select || [];
    const dataList = tableInfo.list || [];
    const comids = [];
    const keys = [];
    // const preTableInfo = this.gcurrPageInfo.pageConfig.tableInfo || {}
    // 下游的情况，需要取下游的id
    // const tab = preTableInfo.tab || ''
    const tab = this.props.realTab;
    if (tab.indexOf('_down') !== -1) {
      keys.push('od_tail_com_id');
    } else {
      keys.push('com_id');
    }
    selectList.forEach(index => {
      keys.forEach(key => {
        dataList[index] && dataList[index][key] && comids.push(dataList[index][key]);
      });
    });
    return comids;
  };
  // 添加到挑单夹
  pickOrderBtnClick = (key, value) => {
    const tableInfo = value.tableInfo || {};
    const selectList = tableInfo.select || [];
    const dataList = tableInfo.list || [];
    const ids = [];
    selectList.forEach(index => {
      ids.push(dataList[index]);
    });
    const checkRes = this.emptyCheck(ids, '加入挑单夹');
    if (checkRes === false) return;
    const listPage = proxyListPage(dataList, { selected: selectList });
    emitListBtnOp({ listPage, key: 'pick_order' });
  };
  batchCheck = (key, value, str) => {
    const tableInfo = value.tableInfo || {};
    const selectList = tableInfo.select || [];
    let isChecked;
    if (str === 'batchCheck') {
      isChecked = tableInfo.form.lineCheck; // 批次审核是否选中
    } else {
      isChecked = tableInfo.form.lineCancelCheck; // 取消批次审核是否选中
    }
    if (!isChecked && selectList.length < 1) {
      createTip('至少选中一行', WARN).show();
      return;
    }
    const that = this.props.listPage;
    const bLinkId = tableInfo.list[0].b_tr_link_id;
    let obj;
    const odLinkIds = this.getSelectids(value);
    if (str === 'batchCheck') {
      if (isChecked === true) {
        isChecked = 1;
      } else {
        isChecked = 0;
      }
      obj = {
        odLinkIds,
        bLinkId,
        isChecked,
        setRightButtonLoadingStatus: this.context.getPick().setRightButtonLoadingStatus,
      };
      batchCheckLine(that, obj);
    } else {
      if (isChecked === true) {
        isChecked = 1;
      } else {
        isChecked = 0;
      }
      obj = {
        odLinkIds,
        bLinkId,
        isChecked,
        setRightButtonLoadingStatus: this.context.getPick().setRightButtonLoadingStatus,
      };
      cancelBatchCheckLine(that, obj);
    }
  };
  // 审核
  orderBatchCheckBtnClick = (key, value) => {
    this.batchCheck(key, value, 'batchCheck');
  };
  // 取消审核
  cancelOrderBatchCheckBtnClick = (key, value) => {
    this.batchCheck(key, value, 'cancelBatchCheck');
  };
  getFilterIds = (selectList, dataList) => {
    const keys = [];
    const ids = [];
    const packIds = [];
    // const preTableInfo = this.gcurrPageInfo.pageConfig.tableInfo || {}
    // const tab = preTableInfo.tab || ''
    const tab = this.props.realTab;
    // 下游的情况，需要取下游的id
    if (tab.indexOf('_down') !== -1) {
      keys.push('od_tail_od_link_id');
    } else {
      keys.push('od_link_id');
    }
    selectList.forEach(index => {
      keys.forEach(key => {
        if (dataList[index].b_pack_batch) {
          packIds.push(dataList[index].b_link_id);
        } else {
          dataList[index] && dataList[index][key] && ids.push(dataList[index][key]);
        }
      });
    });
    return { ids, selectList, packIds };
  };
  // 中转、签收校验
  checkOrderStatus = (value, tips) => {
    let route = getComVal(value.cardInfo.route);
    const tableInfo = value.tableInfo || {};
    const selectList = tableInfo.select || [];
    const dataList = tableInfo.list || [];
    let opTips = '卸车';
    let checkKey = 'b_tr_state';
    if (this.props.realTab === 'shuttle_down') {
      opTips = '接收';
      checkKey = 'b_shuttle_batch_state';
    }
    // 兼容下拉选择的路由
    if (route && route[0] && route[0].line_nodes) {
      route = route[0].line_nodes;
    }
    return new Promise(resolve => {
      if (this.props.realTab === 'shuttle_down' || (route && route.length > 1)) {
        const canNotList = [];
        const sucId = [];
        let hasPackNot = false;
        let hasOrderNot = false;
        selectList.forEach(index => {
          const rowData = dataList[index];
          // 未卸车的 不能操作
          if (+rowData[checkKey] !== 40) {
            if (rowData.b_pack_batch) {
              canNotList.push(rowData.b_pack_batch);
              hasPackNot = true;
            } else {
              hasOrderNot = true;
              canNotList.push(rowData.order_num);
            }
          } else {
            sucId.push(index);
          }
        });
        if (canNotList.length) {
          const content = (
            <div className="dialog_detail">
              <p className="notice">{`以下${hasOrderNot ? '运单' : ''}${hasOrderNot && hasPackNot ? '和' : ''}${
                hasPackNot ? '打包单' : ''
              }未${opTips}入库，无法${tips || '操作'}:`}</p>
              <ShowList list={canNotList} style={{ marginTop: '8px', marginLeft: '-18px' }} />
            </div>
          );
          const handle = {};
          sucId.length &&
            (handle.confirm = () => {
              resolve(this.getFilterIds(sucId, dataList));
            });
          showInfo(ERROR, content, true, handle, true, '操作提醒');
        } else {
          resolve(this.getFilterIds(sucId, dataList));
        }
      } else {
        resolve(this.getFilterIds(selectList, dataList));
      }
    });
  };
  // 转交
  transBtnClick = (key, value) => {
    // let newList = this.filterCheck('中转', value)
    // if (newList === false) return
    // let ids = filterListColumnByKey(newList || [], 'od_link_id')
    this.checkOrderStatus(value, '中转').then(({ ids }) => {
      const checkRes = this.emptyCheck(ids, '中转');
      if (checkRes === false) return;
      transFunc(ids, '外部中转', 1);
    });
  };
  // 网点中转
  transPointBtnClick = (key, value) => {
    this.checkOrderStatus(value, '中转').then(({ ids }) => {
      const checkRes = this.emptyCheck(ids, '网点中转');
      if (checkRes === false) return;
      transFunc(ids, '网点中转', 3);
    });
  };
  checkComId = (comId, tips) => {
    const _tips = tips || '一次只能操作一个网点的数据';
    if (comId.length > 1) {
      createTip(_tips, ERROR).show();
      return false;
    }
    return true;
  };
  packSettlePayArrivalBtnClick = (key, val) => {
    initEmt('settle', this.props.listPage, null, 'settle_batch_arrival_pack');
  };
  packSettleCreaseBtnClick = (key, val) => {
    initEmt('settle', this.props.listPage, null, 'settle_batch_crease_pack');
  };
  // 签收
  signBtnClick = (key, value) => {
    this.checkOrderStatus(value, '签收').then(({ ids, selectList, packIds }) => {
      const checkValue = { ...value };
      checkValue.tableInfo && (checkValue.tableInfo.select = selectList);
      const comids = this.getSelectComids(value);
      let checkRes = false;
      if (packIds.length === 0) {
        checkRes = this.emptyCheck(ids, '签收');
      } else {
        checkRes = true;
      }
      if (checkRes === false) return;
      const _comId = remDub(comids); // 去重
      if (!this.checkComId(_comId)) {
        return;
      }
      const ext = {
        company_id: _comId[0] || window.company_id,
      };
      const pick = this.context.getPick();
      const tableInfo = pick.props.batchTableInfo.tableFilter;
      signFunc(ids, 'sign_pick', '签收', ext, { tableInfo }, pick, packIds);
    });
  };
  shuttleAcceptBtnClick = (key, value) => {
    const { comId } = this.props;
    const bLinkIds = [this.props.batchId];
    const { listPage } = this.props;
    const { select, list } = value.tableInfo;
    let odLinkIds;
    if (select.length === 0) {
      return showInfo(ERROR, '请选择要接收的运单');
    }
    if (select.length > 0 && select.length < list.length) {
      odLinkIds = select.map(i => list[i].od_link_id);
    }
    commonOpCare({
      comId,
      bLinkIds,
      btnKey: 'shuttle_accept',
      that: {
        enableMenuButton: () => {
          this.context.getPick().setRightButtonLoadingStatus('shuttle_accept', false);
        },
        disableMenuButton: () => {
          this.context.getPick().setRightButtonLoadingStatus('shuttle_accept', true);
        },
        handleRefreshData: () => {
          const pickOrder = this.context.getPick();
          pickOrder.requestPickTable();
        },
      },
      od_link_ids: odLinkIds,
    });
  };
  shuttleCancelAcceptBtnClick = (key, value) => {
    const { comId } = this.props;
    const bLinkIds = [this.props.batchId];
    const { listPage } = this.props;
    const { select, list } = value.tableInfo;
    let odLinkIds;
    if (select.length === 0) {
      return showInfo(ERROR, '请选择要取消接收的运单');
    }
    if (select.length > 0 && select.length < list.length) {
      odLinkIds = select.map(i => list[i].od_link_id);
    }
    commonOpCare({
      comId,
      bLinkIds,
      btnKey: 'shuttle_cancel_accept',
      that: {
        enableMenuButton: () => {
          this.context.getPick().setRightButtonLoadingStatus('shuttle_cancel_accept', false);
        },
        disableMenuButton: () => {
          this.context.getPick().setRightButtonLoadingStatus('shuttle_cancel_accept', true);
        },
        handleRefreshData: () => {
          const pickOrder = this.context.getPick();
          pickOrder.requestPickTable();
        },
      },
      od_link_ids: odLinkIds,
    });
  };
  // 取消卸车
  cancelUnload = (key, value, url, text) => {
    const tableInfo = value.tableInfo || {};
    const packIds = [];
    const selectList = tableInfo.select || [];
    const dataList = tableInfo.list || [];
    const ids = [];
    selectList.forEach(index => {
      if (dataList[index].b_pack_batch) {
        packIds.push(dataList[index].b_link_id);
      } else {
        ids.push(dataList[index].od_link_id);
      }
    });
    const pack = {
      b_link_id: this.state.batchId,
      company_id: this.props.comId,
    };
    cancelAcceptLoad(
      { b_link_id: pack.b_link_id },
      `您确定取消${text}吗？`,
      url,
      { op_type: 'b_od_list', company_id: pack.company_id, od_link_ids: ids, b_pack_link_ids: packIds },
      () => {
        this.context.getPick().setLoadingStatus();
        throttle(this.context.getPick().requestPickTable, 1000)();
      },
    );
  };
  // 取消卸车
  cancelUnloadBtnClick = (key, value) => {
    this.cancelUnload(key, value, '/Truck/Truck/onCancelUnload', '卸车');
  };
  // 取消接收
  cancelShuttleAcceptBtnClick = (key, value) => {
    this.cancelUnload(key, value, '/Truck/Shuttle/onCancelUnload', '接收');
  };
  // 登记异常
  addAbnormalsBtnClick = (key, value) => {
    // let newList = this.filterCheck('登记异常', value)
    // if (newList === false) return
    // let ids = filterListColumnByKey(newList || [], 'od_link_id')
    const ids = this.getSelectids(value);
    const checkRes = this.emptyCheck(ids, '登记异常');
    if (checkRes === false) return;
    const odIds = [];
    const orderNums = {};
    const num_title = [];
    const od_basic_ids = [];
    const { tableInfo } = value;
    const dataList = tableInfo.select.map(index => ({ ...tableInfo.list[index], index }));
    dataList.forEach((v, i) => {
      odIds[i] = v.od_id;
      // orderNums[v.od_id] = {
      //   order_num: v.order_num, index: v.index, od_id: v.od_id, od_link_id: v.od_link_id, od_basic_id: v.od_basic_id
      // }
      num_title[i] = v.order_num;
      od_basic_ids[i] = v.od_basic_id;
    });
    const pickContent = this.context.getPick();
    new PopUp(Abnormal, {
      popName: 'AbnormalSlider',
      type: 'add',
      id: ids[0],
      title: '登记',
      orderNum: dataList[0].order_num,
      odLinkId: dataList[0].od_link_id,
      isOpenApprovalFlow: false,
      refreshTab: () => setTimeout(pickContent.requestPickTable(), 2000),
      hiddenOrderInfo: true,
      selectIds: ids,
      num_title,
      od_basic_ids,
      categoryNum: '1',
    }).show();
  };
  // 取消中转
  transCancelBtnClick = (key, value) => {
    const newList = this.filterCheck('取消中转', value);
    if (newList === false) return;
    const dict = {
      trans_inner_to_trans: 'b_inner_trans_batch_id',
      trans_to_trans: 'b_trans_batch_id',
      trans_inner_to_deal: 'b_inner_trans_in_batch_id',
      trans_to_deal: 'b_trans_in_batch_id',
      b_trans_out: 'b_trans_batch_id',
      b_inner_trans_out: 'b_inner_trans_batch_id',
      trans_inner_transed: 'b_inner_trans_batch_id',
    };
    const ids = filterListColumnByKeyList(newList || [], [dict[this.props.type], 'od_link_id']);
    // let ids = this.getSelectids(value, [dict[this.props.type]])
    // let checkRes = this.emptyCheck(ids, '取消中转')
    // if (checkRes === false) return
    const pack = { orders: ids };
    let url = '/Order/Trans/cancelInner';
    if (
      this.props.type === 'trans_to_deal' ||
      this.props.type === 'trans_to_trans' ||
      this.props.type === 'b_trans_out'
    ) {
      url = '/Order/Trans/cancel';
    }
    cancelTrans(url, pack, () => {
      // this.props.close()
      this.closeContent();
      // typeof this.props.closeCallback === 'function' && this.props.closeCallback()
    });
  };
  // 取消接收
  transCancelAcceptBtnClick = (key, value) => {
    const newList = this.filterCheck('取消接收', value);
    if (newList === false) return;
    const dict = {
      b_inner_trans_in: 'b_inner_trans_in_batch_id',
      trans_inner_accepted: 'b_inner_trans_in_batch_id',
    };
    const ids = filterListColumnByKeyList(newList || [], [dict[this.props.type], 'od_link_id']);
    const pack = { orders: ids };
    const url = '/Order/Trans/cancelAcceptInner';
    cancelTransAccept(url, pack, () => {
      this.closeContent();
      // this.props.close()
      // typeof this.props.closeCallback === 'function' && this.props.closeCallback()
    });
  };
  // 短驳
  odShuttleBtnClick = (key, value) => {
    const ids = this.getSelectids(value);
    const checkRes = this.emptyCheck(ids, '短驳');
    if (checkRes === false) return;
    const { select } = value.tableInfo;
    const selectList = [];
    select.map(listKey => {
      const ele = value.tableInfo.list[listKey];
      selectList.push(ele);
      return selectList;
    });
    this.checkOneVariable(selectList, 'next_com_id', '短驳', (resIds, resRemComId) =>
      directNewPageInnerFunc(resIds, resRemComId, {
        checkTips: '新增短驳',
        controller: 'Shuttle',
        checkUrl: '/Truck/Shuttle/onLoading',
        isBatch: false,
      }),
    );
  };
  // 送货
  odDeliveryBtnClick = (key, value) => {
    const ids = this.getSelectids(value);
    const { select } = value.tableInfo;
    let noPack = true;
    select &&
      select.forEach(i => {
        // 到车管理，批次详情，既有打包单，又有运单
        const val = value.tableInfo && value.tableInfo.list[i];
        if (val.b_link_id) {
          noPack = false;
        }
      });
    const checkRes = noPack && this.emptyCheck(ids, '送货');
    if (checkRes === false && noPack) return;

    const selectList = [];
    select.map(listKey => {
      const ele = value.tableInfo.list[listKey];
      selectList.push(ele);
      return selectList;
    });
    this.checkOneVariable(selectList, 'next_com_id', '送货', (resIds, resRemComId) =>
      directNewPageInnerFunc(resIds, resRemComId, {
        checkTips: '新增送货',
        controller: 'Delivery',
        checkUrl: '/Truck/Delivery/onLoading',
        isBatch: false,
      }),
    );
  };
  // 提货
  odPickupBtnClick = (key, value) => {
    const ids = this.getSelectids(value);
    const checkRes = this.emptyCheck(ids, '提货');
    if (checkRes === false) return;
    directNewPageInnerFunc(ids, this.props.comId, {
      checkTips: '新增提货',
      controller: 'Pickup',
      checkUrl: '/Truck/Pickup/onLoading',
      isBatch: false,
    });
  };
  arrivalBtnClick = () => {
    arrivalOperate(this.state.batchId);
  };
  acceptLoadBtnClick = () => {
    acceptLoad(this.state.batchId, '/Truck/Truck/onUnload', { op_type: 'b_list' });
  };
  changeStatus = () => {
    this.context.getPick().changeLoadingStatus(true);
  };
  // 下游卸车
  trDownUnloadBtnClick = (key, value) => {
    let newList = this.filterCheck('卸车', value);
    if (newList === false) return;
    const packIds = [];
    newList = newList.filter(val => {
      if (val.b_pack_batch) {
        packIds.push(val.b_link_id);
      }
      return !val.b_pack_batch;
    });
    console.log(newList, 'newList');
    const ids = filterListColumnByKey(newList || [], 'od_link_id');
    const pack = {
      is_check: 1,
      od_link_ids: ids,
      op_type: 'b_od_list',
      company_id: this.props.comId,
      b_link_id: this.props.batchId,
      b_pack_link_ids: packIds,
    };
    const { tab } = this.state.pickOrderInfo.batchTableInfo.tableFilter;
    if (tab === 'tr_up_loading_list') {
      pack.company_id = this.props.tr_up_op.comId;
      pack.b_link_id = this.props.tr_up_op.batchId;
      pack.tr_up_op = 1;
    }
    this.context.getPick().setRightButtonLoadingStatus('tr_down_unload', true);
    odUnloadFunc(
      pack,
      () => {
        this.context.getPick().setRightButtonLoadingStatus('tr_down_unload', false);
        this.context.getPick().setLoadingStatus();
        throttle(this.context.getPick().requestPickTable, 1000)();
      },
      {
        failedCallback: () => {
          this.context.getPick().setRightButtonLoadingStatus('tr_down_unload', false);
        },
        ...this.enableMenuButton('tr_down_unload'),
      },
    );
  };
  trModifyOrderBtnClick = (key, value) => {
    const { select, list } = value.tableInfo;
    if (select.length === 0) {
      return showInfo(INFO, '请选择要修改的数据！');
    }
    const ids = {};
    select.forEach(i => {
      if (list[i].od_link_id) {
        if (!ids.od_basic_ids) {
          ids.od_basic_ids = [];
          ids.od_link_ids = [];
        }
        ids.od_basic_ids.push(list[i].od_basic_id);
        ids.od_link_ids.push(list[i].od_link_id);
      } else if (list[i].b_link_id) {
        if (!ids.b_pack_link_ids) {
          ids.b_pack_link_ids = [];
        }
        ids.b_pack_link_ids.push(list[i].b_link_id);
      }
    });
    console.log(ids, 'ids');
    const pick = this.context.getPick();
    batchModifyOrder(ids, () => pick.refreshLeftTable('left', pick.state.tableList.right.data));
  };
  enableMenuButton = btnKey => {
    const parm = {
      that: {
        enableMenuButton: () => {
          this.context.getPick().setRightButtonLoadingStatus(btnKey, false);
        },
        handleRefreshData: () => {
          const pickOrder = this.context.getPick();
          pickOrder.requestPickTable();
        },
      },
      btnKey,
    };
    return parm;
  };
  // dealFeeInfo = (feeData, flag) => {
  //   const pickObj = this.context.getPick()
  //   if (flag) {
  //     pickObj.changeFeeStatus(flag)
  //   } else {
  //     pickObj.changeFeeStatus(flag)
  //   }
  // }
  // 下游保存配载信息
  // trDownSaveBtnClick = () => {
  //   const pick = this.context.getPick()
  //   const checkRes = pick.feeTable.getTableData(true, {}, false)
  //   if (!checkRes.isPass) {
  //     createTip(formateTableCheckTips(checkRes), ERROR).show()
  //     return false
  //   }
  //   const data = pick.biPickOrder.getTableData('right') || []
  //   const header = pick.getTableHeader('right') || {}

  //   // 添加表格验证
  //   const checkRes1 = tableDataCheck(data, header)
  //   if (!checkRes1.isPass) {
  //     createTip(formateTableCheckTips(checkRes1), ERROR).show()
  //     return false
  //   }
  //   const feeData = checkRes.data || []
  //   // let feeData = pick.feeTable.getStateCache()
  //   const index = this.getCompanyIndex(feeData)
  //   const companyId = this.props.comId || window[COMPANY_ID]
  //   // // let companyId = '885'
  //   // let index = getIndexOfList(feeData, 'company_id', companyId)
  //   const arrDisable = ['b_arr_f', 'b_rmt_unload_f', 'b_rmt_misc_f', 'b_rmt_landing_f', 'plan_arr_t']
  //   const checkBoxEle = ['b_rmt_misc_f_paid', 'b_rmt_unload_f_paid']
  //   const pack = { b_link_id: this.props.batchId, company_id: companyId }
  //   arrDisable.forEach((item) => {
  //     pack[item] = feeData[index][item]
  //   })
  //   checkBoxEle.forEach((item) => {
  //     if (feeData[index][item] === true) {
  //       pack[item] = 1
  //     } else if (feeData[index][item] === false) {
  //       pack[item] = 0
  //     } else {
  //       pack[item] = feeData[index][item]
  //     }
  //   })
  //   pack.od_link_detail = filterListColumnByKeyList(data, ['od_link_id', 'od_rmt_unload_f', 'od_rmt_msc_f'])

  //   modifyArrFee(pack, () => {
  //     const buttons = pick.biPickOrder.state.rightButtons || []
  //     buttons.tr_down_modify.display = true
  //     buttons.tr_down_save.display = false
  //     pick.biPickOrder.changeRightButtonList(buttons)
  //     const newPickOrderInfo = this.dealFeeInfoDisable({ pageTab: 'tr_down', pageType: 'view' }, this.state.pickOrderInfo)
  //     const dealFeedata = newPickOrderInfo.feeInfo.data // 处理好的数据
  //     dealFeedata.map((key, indexof) => {
  //       feeData[indexof].otherProps.disable = key.otherProps.disable
  //       return feeData
  //     })
  //     // this.dealFeeInfo(feeData, true)
  //     pick.setFeeData(feeData)
  //     // 恢复回 未修改状态
  //     const biPickOrder = this.context.getPick()
  //     biPickOrder.isEdit = false
  //     this.isEdit = false
  //   })
  // }
  setSliderBiggest = () => {
    if (this.slider) {
      // 侧拉最大化
      this.slider.setBiggest();
    }
    setTimeout(() => {
      this.setState({ type: 'edit' });
    }, 0);
  };
  // 处理车辆配载修改的时候 费用的状态
  loadFeeInfoDisable = () => {
    const pick = this.context.getPick();
    const feeData = pick.feeTable.getStateCache();
    const index = this.getCompanyIndex(feeData);
    if (index === -1) return feeData;
    const newFeeData = feeData;
    const currPidInfo = feeData[index];
    const disabledList = currPidInfo.otherProps.disable;
    const newDisable = [];
    const canEdit = [
      'b_billing_f',
      'b_billing_f_paid',
      'b_fuel_card_f',
      'b_fuel_card_f_paid',
      'b_info_f',
      'b_info_f_paid',
      'b_insur_f',
      'b_insur_f_paid',
      'b_loc_tr_f',
      'b_loc_tr_f_paid',
      'b_loc_misc_f',
      'b_loc_misc_f_paid',
    ].join(',');
    disabledList.forEach(item => {
      if (canEdit.indexOf(item) < 0) {
        newDisable.push(item);
      }
    });
    currPidInfo.otherProps.disable = newDisable;
    newFeeData[index] = currPidInfo;
    pick.setFeeData(newFeeData);
  };
  refreshFeeInfo = url => {
    const parm = {
      type: 'add',
      b_link_id: this.state.batchId,
      company_id: this.state.comId,
    };
    const param = {
      method: 'POST',
      body: { req: parm },
    };
    const pick = this.context.getPick();
    fetchApi(url, param)
      .then(res => {
        if (res.errno !== 0) {
          createTip(res.errmsg || '获取数据失败', WARN).show();
          return;
        }
        const { header: feeHeader, data: feeData } = res.res.feeInfo;
        const { cardFormHeader, cardInfo } = res.res.batchTitleInfo;
        this.pageType = 'edit';

        // 结算服务1.0.8 配载后自动认证，且认证成功后才可操作发车 start
        const { batch_info = {} } = res.res;
        const batchType = [1, 3, 5, 7].includes(+batch_info.type);
        const pmsn = window.permission.includes('payMgr');
        const departed = +batch_info.blk_flag >= 50 && +batch_info.batch_id === +batch_info.head_b_link_id;

        let opPageType = 'add'; // 新增配载 add |  修改批次 update  |  批次详情 view

        if (this.pageType === 'show') {
          opPageType = 'view';
        } else if (+batch_info.batch_id) {
          opPageType = 'update';
        }

        console.log(' === headTruckT ==== ');
        if (g7Done() && batchType && pmsn) {
          const isAutoImportChecked = getCookie(`isAutoImportChecked_${window.user_id}`) === 'true' || false;
          const isAutoImportDisabled = this.pageType === 'show' || +batch_info.b_tr_g7_import_st === 2;

          cardInfo.is_auto_import.display = true;
          cardInfo.is_auto_import_tip.display = true;
          opPageType === 'add' && (cardInfo.is_auto_import.otherProps.checked = isAutoImportChecked);
          cardInfo.is_auto_import.otherProps.disabled = isAutoImportDisabled;
        } else {
          delete cardInfo.is_auto_import_tip;
          delete cardInfo.is_auto_import;
        }
        // 结算服务1.0.8 配载后自动认证，且认证成功后才可操作发车 end

        // pick.setCardFormData(cardInfo, cardFormHeader)
        // pick.setFeeHeader(feeHeader)
        // pick.setFeeData(feeData)

        const pickOrderInfo = { ...this.state.pickOrderInfo };
        pickOrderInfo.batchTitleInfo = { ...pickOrderInfo.batchTitleInfo, cardInfo, cardFormHeader };
        pickOrderInfo.feeInfo = { ...pickOrderInfo.feeInfo, header: feeHeader, data: feeData };
        dealCardPriceSnapshot(pickOrderInfo); // 显示价格快照
        dealFeeTablePriceSnapshot(pickOrderInfo); // 显示价格快照
        pick.setCardFormData(pickOrderInfo.batchTitleInfo.cardInfo, pickOrderInfo.batchTitleInfo.cardFormHeader);
        pick.setFeeHeader(pickOrderInfo.feeInfo.header);
        pick.setFeeData(pickOrderInfo.feeInfo.data);
      })
      .catch(err => console.log(err));
  };
  // 打包单新增配载
  loadOrderBtnClick = v => {
    cargo(this.props.listPage, v);
  };
  //  修改配载信息
  modifyTrLoadBtnClick = () => {
    console.log(this.props, 'BatchDetailBatchDetailBatchDetail');
    const query = { tab: 'truck_pick' };
    let url = '/Truck/Truck/onLoading';
    // 预装车
    if (this.props.realTab === 'pre_tr_up') {
      query.tab = 'truck_pre_pick';
      url = '/Truck/TruckPre/onLoading';
    } else {
      url = '/Truck/Truck/onLoading';
    }
    // 预装车、已签到、已靠台
    if (+this.props.batchSt === 5 || +this.props.batchSt === 13 || +this.props.batchSt === 16) {
      url = '/Truck/TruckPre/onLoading';
    }
    if (!this.checkIsSet('batch_check')) return false;
    // 处理费用的信息
    this.refreshFeeInfo(url);
    this.setSliderBiggest();
    setTimeout(this.context.getPick().changeLoadingStatus(false, query), 0);
  };
  modifyTrPreLoadBtnClick = () => {
    this.modifyTrLoadBtnClick();
  };
  //  修改打包单信息
  editPackBtnClick = () => {
    // if (!this.checkIsSet('batch_check')) return false
    const query = { tab: 'pack' };
    this.setSliderBiggest();
    setTimeout(this.context.getPick().changeLoadingStatus(false, query), 0);
  };
  //  修改送货信息
  modifyDeliveryLoadBtnClick = () => {
    // if (!this.checkIsSet('batch_check')) return false
    const query = { tab: 'delivery_pick' };
    this.setSliderBiggest();
    setTimeout(this.context.getPick().changeLoadingStatus(false, query), 0);
  };
  //  修改提货信息
  modifyPickupLoadBtnClick = () => {
    const query = { tab: 'pickup_pick' };
    this.setSliderBiggest();
    setTimeout(this.context.getPick().changeLoadingStatus(false, query), 0);
  };
  //  修改短驳信息
  modifyShuttleLoadBtnClick = () => {
    const query = { tab: 'shuttle_pick' };
    this.setSliderBiggest();
    setTimeout(this.context.getPick().changeLoadingStatus(false, query), 0);
  };
  // 投保资料未审核
  improveInfoDialog = content => {
    new PopUp(ShowInfo, {
      type: WARN,
      content, // : '您的投保资料未提交或未审核，请维护，完成后即可投保',
      simpleMode: {
        confirm: thisDialog => {
          thisDialog.changeBtnLoadingStatus('confirm', true);
          thisDialog.handleHide();
          // this.props.close()
          this.closeContent();
          browserHistory.pushWithQuery({ pathname: '/Company/insuredInfo', query: { tabName: '投保资料' } });
        },
        cancel: thisDialog => {
          thisDialog.handleHide();
          // this.props.close()
          this.closeContent();
        },
      },
      simpleText: {
        confirm: '前去维护',
        cancel: '取消',
      },
    }).show();
  };
  refresh = (url, key) => {
    const parmInfo = this.props;
    const pick = this.context.getPick();
    pick.setRightButtonLoadingStatus(key);
    return fetchApi(url, {
      method: 'POST',
      body: {
        req: {
          type: parmInfo.type,
          b_link_id: parmInfo.batchId,
          company_id: parmInfo.comId,
        },
      },
    }).then(res => {
      pick.setRightButtonLoadingStatus(key, false);
      if (res.errno !== 0) {
        showInfo(ERROR, res.errmsg);
      } else {
        const props = { ...this.props };
        props.pickOrderInfo = res.res;
        this.setState(this.resetState(props), () => {
          const query = res.res.batchTableInfo.tableFilter;
          pick.refreshBiTable(query);
          pick.setFeeHeader(res.res.feeInfo.header);
          pick.setFeeData(res.res.feeInfo.data);
        });
      }
    });
  };
  // 这里是补装车-完成配载，修改车辆配载
  loadBtnClick = async (key, value, validateInfo) => {
    if (!(await validateFieldsOf(this.wrap))) return false;
    const { truckLoad } = this.context;
    const pickOrderInfo = this.props.pickOrderInfo.toJS() || {};
    const batchInfo = pickOrderInfo.batch_info || {};
    const throughDate = pickOrderInfo.throughDate || {};
    const picker = this.context.getPick();
    // picker.batchPriceCalcComplete().then 应该等待价格计算结束再处理参数，这个暂时不知道在哪处理好，先不做
    truckLoad({
      truckTeamSource: this.truckTeamSource,
      bBasicId: batchInfo.b_basic_id,
      value,
      throughDate,
      validateInfo,
      btnKey: key,
      pageType: 'truck',
      is_from_pay_mgr: this.is_from_pay_mgr,
      dr_id: this.cardExtData && this.cardExtData.dr_id,
      otherProps: {
        g7_info: pickOrderInfo.g7_info,
        is_reload: pickOrderInfo.is_reload,
        old_car_batch: batchInfo.car_batch,
        b_link_id: this.props.batchId,
        com_id: batchInfo.com_id || batchInfo.company_id,
        company_id: batchInfo.com_id || batchInfo.company_id,
        type: batchInfo.type, // 上游网点，费用项需要读系统设置进行必填校验
        require_fields: pickOrderInfo.require_fields || [], // 必填字段数组
        fee_header: (pickOrderInfo.feeInfo && pickOrderInfo.feeInfo.header) || {}, // 费用项的表头
      },
      callback: (resData, param) => {
        if (resData.errno === 900) {
          return this.refresh('Truck/Truck/onLoading', key);
        }
        // this.props.close()
        // this.props.listPage.handleRefreshData()
        this.closeContent('/Operate/carStowage');
        // 完成配载 完善投保资料
        const data = resData.res.insur_info;
        const reqData = resData.req || {};
        // pici id
        const recordId = resData.res.b_link_id;
        // 这个延时是为了和装车成功的tips区分开，不然间隔太短
        setTimeout(() => {
          if (data && data.errno === 0 && data.insured === '1') {
            const url = 'Insurance/Vehicle/edit';
            this.sendReq(url, { id: '', car_record_id: recordId }).then(res => {
              if (res.errno === 93) {
                createTip(res.errmsg, WARN).show();
              } else if (res.errno === 65) {
                createTip(res.errmsg, WARN).show();
              } else {
                browserHistory.pushWithQuery({
                  pathname: '/Company/carInsured',
                  query: {
                    car_record_id: recordId,
                  },
                });
              }
            });
          } else if (data && data.errno === 26 && +reqData.insured) {
            this.improveInfoDialog(data.msg);
          }
          if (param && param.type === 'retry_cancel') {
            const url = '/Truck/Truck/onLoading';
            const { req } = param.req;
            // const popParam = param.req.param
            // const pageKey = getPageKey().pageConfig.key
            this.sendReq(url, req)
              .then(res => {
                if (+res.data.errno) {
                  createTip(res.data.errmsg, ERROR).show();
                  return;
                }
                const pick = this.context.getPick();
                pick.setFeeHeader(res.res.feeInfo.header);
                pick.setFeeData(res.res.feeInfo.data);
              })
              .catch(err => console.log(err));
          }
        }, 600);
        picker.saveBatchPriceSnapshot(recordId, this.priceSnapshot, this.priceSnapshotDelete);
      },
      pickOrder: picker,
      isPreLoad: this.props.realTab === 'pre_tr_up',
      truck_id: this.truck_id || batchInfo.truck_id,
    });
  };
  shuttleBtnClick = (key, value, validateInfo) => {
    const { shuttleLoad } = this.context;
    const pickOrderInfo = this.props.pickOrderInfo.toJS() || {};
    const batchInfo = pickOrderInfo.batch_info || {};
    shuttleLoad({
      pageType: 'shuttle',
      btnKey: key,
      value,
      validateInfo,
      is_from_pay_mgr: this.is_from_pay_mgr,
      otherProps: {
        g7_info: pickOrderInfo.g7_info,
        old_car_batch: batchInfo.car_batch,
        b_link_id: this.props.batchId,
        com_id: this.props.comId,
        truck_id: this.truck_id || batchInfo.truck_id,
        company_id: this.props.comId,
      },
      callback: resData => {
        if (resData.errno === 900) {
          return this.refresh('Truck/Shuttle/onLoading', key);
        }
        const bLinkId = resData.res.b_link_id;
        const comId = window.company_id;
        const printOrder = value.tableInfo.form ? value.tableInfo.form.printOrder : '';
        this.printType.key && setPrintLocalStorage(this.printOrderKey, printOrder);
        this.handlePrint([bLinkId], comId, printOrder, () => this.closeContent('/Operate/shortDepart'));
      },
      pickOrder: this.context.getPick(),
    });
  };
  deliveryBtnClick = (key, value, validateInfo) => {
    const { deliveryLoad } = this.context;
    const pickOrderInfo = this.props.pickOrderInfo.toJS() || {};
    const batchInfo = pickOrderInfo.batch_info || {};
    const picker = this.context.getPick();
    // picker.batchPriceCalcComplete().then 应该等待价格计算结束再处理参数，这个暂时不知道在哪处理好，先不做
    deliveryLoad({
      value,
      btnKey: key,
      validateInfo,
      pageType: 'delivery',
      is_from_pay_mgr: this.is_from_pay_mgr,
      otherProps: {
        g7_info: pickOrderInfo.g7_info,
        old_car_batch: batchInfo.car_batch,
        batch_link_id: batchInfo.batch_id,
        b_link_id: this.props.batchId,
        com_id: this.props.comId,
        print_tpl: value.tableInfo.form ? value.tableInfo.form.printOrder : '',
      },
      callback: resData => {
        if (resData.errno === 900) {
          return this.refresh('Truck//onLoading', key);
        }
        picker.savePriceSnapshot();
        const bLinkId = resData.res.b_link_id;
        const comId = window.company_id;
        const printOrder = value.tableInfo.form ? value.tableInfo.form.printOrder : '';
        picker.saveBatchPriceSnapshot(bLinkId, this.priceSnapshot, this.priceSnapshotDelete);
        this.printType.key && setPrintLocalStorage(this.printOrderKey, printOrder);
        this.handlePrint([bLinkId], comId, printOrder, () => this.closeContent('/Operate/deliverBatch'));
      },
      pickOrder: picker,
    });
  };
  createPackBtnClick = (key, value, validateInfo) => {
    const { completePack } = this.context;
    const pickOrderInfo = this.props.pickOrderInfo.toJS() || {};
    const batchInfo = pickOrderInfo.batch_info || {};
    completePack({
      value,
      btnKey: key,
      validateInfo,
      pageType: 'pack',
      otherProps: {
        old_car_batch: batchInfo.car_batch,
        batch_link_id: batchInfo.batch_id,
        b_link_id: this.props.batchId,
        com_id: this.props.comId,
        print_tpl: value.tableInfo.form ? value.tableInfo.form.printOrder : '',
      },
      callback: resData => {
        if (resData.errno === 900) {
          return this.refresh('Truck//onLoading', key);
        }
        const bLinkId = resData.res.b_link_id;
        const comId = window.company_id;
        const printOrder = value.tableInfo.form ? value.tableInfo.form.printOrder : '';
        this.printType.key && setPrintLocalStorage(this.printOrderKey, printOrder);
        this.handlePrint([bLinkId], comId, printOrder, () => this.closeContent('/Operate/deliverBatch'));
      },
      pickOrder: this.context.getPick(),
    });
  };
  pickupBtnClick = (key, value, validateInfo) => {
    const { deliveryLoad } = this.context;
    const pickOrderInfo = this.props.pickOrderInfo.toJS() || {};
    const batchInfo = pickOrderInfo.batch_info || {};
    deliveryLoad({
      pageType: 'pickup',
      btnKey: key,
      value,
      validateInfo,
      is_from_pay_mgr: this.is_from_pay_mgr,
      otherProps: {
        g7_info: pickOrderInfo.g7_info,
        old_car_batch: batchInfo.car_batch,
        batch_link_id: batchInfo.batch_id,
        b_link_id: this.props.batchId,
        com_id: this.props.comId,
        print_tpl: value.tableInfo.form ? value.tableInfo.form.printOrder : '',
      },
      callback: resData => {
        if (resData.errno === 900) {
          return this.refresh('Truck/Delivery/onLoading', key);
        }
        const bLinkId = resData.res.b_link_id;
        const comId = window.company_id;
        const printOrder = value.tableInfo.form ? value.tableInfo.form.printOrder : '';
        this.context.getPick().savePriceSnapshot();
        this.context.getPick().saveBatchPriceSnapshot(bLinkId, this.priceSnapshot, this.priceSnapshotDelete);
        this.printType.key && setPrintLocalStorage(this.printOrderKey, printOrder);
        this.handlePrint([bLinkId], comId, printOrder, () => this.closeContent('/Operate/pickupBatch'));
      },
      pickOrder: this.context.getPick(),
    });
  };
  // 订单提货
  completePickupBtnClick = (key, value, validateInfo) => {
    // eslint-disable-line
    const { deliveryLoad } = this.context;
    const pickOrderInfo = this.props.pickOrderInfo.toJS() || {};
    const batchInfo = pickOrderInfo.batch_info || {};
    const newValue = value;
    if (value && value.tableInfo.list && value.tableInfo.list.length) {
      newValue.tableInfo.list.forEach((item, index) => (newValue.tableInfo.list[index].od_link_id = item.id));
    }
    deliveryLoad({
      value: newValue,
      validateInfo,
      btnKey: key,
      pageType: 'pickup',
      otherProps: {
        old_car_batch: batchInfo.car_batch,
        batch_link_id: batchInfo.batch_id,
        b_link_id: batchInfo.batch_id,
        com_id: this.props.comId,
        company_id: this.props.comId,
        truck_id: this.truck_id || batchInfo.truck_id,
        orderType: '订单',
        print_tpl: value.tableInfo.form ? value.tableInfo.form.printOrder : '',
      },
      callback: resData => {
        const bLinkId = resData.res.b_link_id;
        const comId = window.company_id;
        const printOrder = value.tableInfo.form ? value.tableInfo.form.printOrder : '';
        this.printType.key && setPrintLocalStorage(this.printOrderKey, printOrder);
        this.handlePrint([bLinkId], comId, printOrder, () => this.closeContent('/Operate/pickupReservationBatch'));
      },
      pickOrder: this.context.getPick(),
      url: 'Reservation/Pickup/onComplete',
    });
  };
  // 取消装车
  cancelLoadBtnClick = (key, val) => {
    const value = val;
    const packIds = [];
    value.tableInfo &&
      value.tableInfo.list &&
      Array.isArray(value.tableInfo.list) &&
      value.tableInfo.list.forEach((i, index) => {
        const isSelect = value.tableInfo.select && value.tableInfo.select.indexOf(index) > -1;
        if (i.b_link_id && isSelect) {
          packIds.push(i.b_link_id);
        }
      });
    console.log(key, value);
    // let tableInfo = value.tableInfo || {}
    // let selectList = tableInfo.select || [], dataList = tableInfo.list || [], ids = []
    // selectList.forEach((index) => {
    //   ids.push(dataList[index].od_link_id)
    // })
    // if (!this.emptyCheck(ids, '取消装车')) return false
    if (!this.checkIsSet('batch_check', '批次已审核无法取消!')) return false;
    const newList = this.filterCheck('取消装车', value);
    if (newList === false) return;
    const ids = this.getSelectids(value);
    const ext = {
      showInfoContentType: 'order', // 弹框提示文案的类型。默认是 您确定整车xxx吗
    };
    cancelLoad(
      { b_link_id: this.state.batchId },
      'calcelTruck',
      '/Truck/Truck/onCancel',
      { op_type: 'b_od_list', company_id: this.props.comId, od_link_ids: ids, b_pack_link_ids: packIds },
      res => {
        // 整车取消完成，关闭侧拉
        // if (res.cancel_all) {
        // console.log(res)
        // this.props.close()
        this.closeContent();
        // this.props.listPage.handleRefreshData()
        // }
      },
      ext,
    );
  };
  cancelPreLoadBtnClick = (key, value) => {
    const newList = this.filterCheck('取消预装车', value);
    if (newList === false) return;
    const ids = this.getSelectids(value);
    cancelLoad(
      { b_link_id: this.state.batchId },
      'calcelPreTruck',
      '/Truck/TruckPre/onCancel',
      { op_type: 'b_od_list', company_id: this.props.comId, od_link_ids: ids },
      res => {
        // 整车取消完成，关闭侧拉
        // if (res.cancel_all) {
        console.log(res);
        // this.props.close()
        this.closeContent();
        // this.props.listPage.handleRefreshData()
        // }
      },
    );
  };
  // 列表设置
  setHeaderBtnClick = () => {
    this.context.getPick().tableSetHeader('right');
  };
  // 列表排序
  setSortBtnClick = () => {
    this.context.getPick().tableSetSort('right');
  };
  // 导出
  exportAllBtnClick = () => {
    const ext = {};
    ext.company_id = this.state.company_id || this.state.comId;
    ext.b_link_id = this.state.batchId;
    this.context.getPick().tableExport('right', ext);
  };
  exportAllByReservationBtnClick = () => {
    const ext = {};
    ext.company_id = this.state.company_id || this.state.comId;
    ext.b_link_id = this.state.batchId;
    this.context.getPick().tableExport('right', ext, 'export_all_by_reservation');
  };
  exportAllByGoodsBtnClick = () => {
    const ext = {};
    ext.company_id = this.state.company_id || this.state.comId;
    ext.b_link_id = this.state.batchId;
    this.context.getPick().tableExport('right', ext, 'export_all_by_goods');
  };
  exportSelectedByReservationBtnClick = (val, newRightList) => {
    const ext = {};
    ext.company_id = this.state.company_id || this.state.comId;
    ext.b_link_id = this.state.batchId;
    this.context.getPick().tableExport('right', ext, 'export_select_by_reservation', newRightList.tableInfo);
  };
  exportSelectedByGoodsBtnClick = (val, newRightList) => {
    const ext = {};
    ext.company_id = this.state.company_id || this.state.comId;
    ext.b_link_id = this.state.batchId;
    this.context.getPick().tableExport('right', ext, 'export_selected_by_goods', newRightList.tableInfo);
  };
  exportSelectedBtnClick = () => {
    this.context.getPick().tableExportSelect('right', this.props.batchNo);
  };
  // 打印运单列表
  printOrderBtnClick = (key, value) => {
    const { select } = value.tableInfo;
    let odLinkIdsList = [];
    if (select.length) {
      // 打印选中
      select.forEach(index => odLinkIdsList.push(value.tableInfo.list[index].od_link_id));
    } else {
      // 打印全部
      odLinkIdsList = value.tableInfo.list.map(v => v.od_link_id);
    }
    const { selectSubKey } = value.tableInfo;
    const _comId = value.tableInfo.list.map(v => v.company_id || ' ');
    handlePrintTableList(selectSubKey, odLinkIdsList, _comId, 'order');
  };
  // 打印预览-外部中转
  transPrintPreviewBtnClick = (key, value) => {
    // let { tableInfo } = this.props
    const req = {
      category: 'Order',
      cid: window.globalPrintHostId,
      fetch_mode: 'all',
      name: 'OrderList',
      tab: this.state.pickOrderInfo.batchTableInfo.tableFilter.tab,
    };
    console.log(key, value);
    const { select } = value.tableInfo;
    let ids = [];
    if (select.length) {
      // 打印选中
      select.forEach(index => ids.push(value.tableInfo.list[index].id));
    } else {
      // 打印全部
      ids = value.tableInfo.list.map(v => v.id);
    }
    let title = '中转详情';
    if (req.tab === 'trans_batch_detail') {
      title = '外部中转批次详情';
    }
    if (req.tab.indexOf('point_trans') > -1) {
      title = '网点中转批次详情';
    } else if (req.tab.indexOf('inner_trans') > -1) {
      title = '员工中转批次详情';
    }
    const urlData = {
      controller: 'Print',
      action: 'printList',
      query: {
        tabName: `${title}-打印`,
      },
    };
    req.tab += '_right_print';
    req.query = { id: [...ids] };
    delete req.left;
    delete req.right;
    const _pack = {
      url: 'Table/Search/orderList',
      title,
      req,
    };
    actions.api.direct(urlData, 'data', {
      packData: _pack,
    });
  };
  packSettleBtnClick = (key, value, A, B, C) => {
    initEmt('settle', this.props.listPage);
  };
  // 打印预览
  trLoadingDefaultPrintBtnClick = (key, value) => {
    console.log(key, value);
    this.printPreviewBtnClick(key, value);
  };
  // 打印装车清单
  printListBtnClick = async (key, resData, validateInfo, res) => {
    if (!(await checkLODOP())) return false;
    const tableInfoHere = resData.tableInfo;
    const selectSubKey = tableInfoHere.selectSubKey || {};
    const tplType = selectSubKey.tpl_type;
    const tplIdHere = selectSubKey.tpl_key;
    const selectIndexs = tableInfoHere.select;

    if (
      res &&
      res[2] &&
      res[2].tpl_key &&
      typeIs(res[2].tpl_key, 'string') &&
      res[2].tpl_key.indexOf('tr_loading_list') > -1
    ) {
      this.printPreviewBtnClick(key, resData, res[2]);
      return false;
    }
    console.log(key, resData, validateInfo, res);

    this.selectIds = selectIndexs.reduce((prev, curr) => {
      prev.push(tableInfoHere.list[curr].od_link_id);
      return prev;
    }, []);
    const select = resData.tableInfo.select || [];
    const list = resData.tableInfo.list || [];
    const newList = [];
    const packList = [];
    const pack_ids = [];
    const allPackIds = [];
    const allOrderIds = [];
    select.forEach(item => {
      if (list[item].b_link_id) {
        pack_ids.push(list[item].b_link_id);
        packList.push(list[item]);
      } else {
        newList.push(list[item]);
      }
    });
    list.forEach(item => {
      if (item.b_link_id) {
        allPackIds.push(item.b_link_id);
      } else if (item.od_link_id) {
        allOrderIds.push(item.od_link_id);
      }
    });
    const pickIdType = tplType === 'pickup_reservation_loading_list' ? 'id' : 'od_link_id';
    const orderIds = filterListColumnByKey(newList || [], pickIdType);
    const order_num = filterListColumnByKey(newList || [], 'order_num');
    const pack_num = filterListColumnByKey(packList || [], 'b_pack_batch');
    // let orderIds = this.getSelectids(resData)
    const ext = { ...tableInfoHere.selectSubKey };
    const dataJson = { order_num, pack_num, allPackIds, allOrderIds };
    this.printAction(tplIdHere, orderIds, ext, null, pack_ids, dataJson);
  };
  // 清单类短信发送
  msgSendUserTplBtnClick = async (key, value) => {
    const { pageTab } = this.props;
    const { batchId } = this.state;
    const tableFilter = this.state.pickOrderInfo.batchTableInfo && this.state.pickOrderInfo.batchTableInfo.tableFilter;
    const { category } = tableFilter;
    const { tab } = tableFilter;
    const { tableInfo } = value;
    const index = tableInfo.select;
    if (index.length < 1) {
      new PopUp(PopTip, {
        classname: 'pop_tip',
        type: WARN,
        isShow: true,
        content: '请选择选择至少一条数据！',
        autoDestroy: true,
      }).show();
      return false;
    }
    const table = tableInfo.list;
    const params = [];
    const param = { tpl_ids: [tableInfo.selectSubKey.tpl_key] };
    const keyArr = Object.keys(table[0]);
    let msgTemplate;
    if (keyArr.includes('od_link_id') || keyArr.includes('next_od_link_id')) {
      param.order_ids = index.map(
        idx => (+table[idx].next_od_link_id && table[idx].next_od_link_id) || table[idx].od_link_id,
      );
      // 短信模板的参数
      const templateParam = {
        sign: 'Order',
        req: {
          category,
          tab,
          orderIds: param.order_ids,
          tplIds: param.tpl_ids,
          typeList: [],
        },
        url: 'Basic/ShortMessage/checkOrderMsgHistory',
      };
      msgTemplate = await checkMsgHistory(templateParam);
      if (msgTemplate.op === 'skip') param.order_ids = msgTemplate.list;
    } else if (keyArr.includes('telephone')) {
      // 没有od_link_id取telephone
      param.phone_list = index.map(idx => table[idx].telephone);
      const templateData = index.map(idx => ({ name: table[idx].name, recv_phone: table[idx].telephone }));
      // 短信模板的参数
      const templateParam = {
        sign: category,
        req: {
          tpl_ids: param.tpl_ids,
          data: templateData,
        },
      };
      msgTemplate = await checkMsgHistory(templateParam);
      if (msgTemplate.op === 'skip') param.order_ids = msgTemplate.list;
    }
    if (!msgTemplate) return;
    params.push(param);
    const canSend = await checkSend(params);
    if (!canSend) return;
    if (pageTab.includes('tr_down') || pageTab.includes('tr_up')) param.b_link_id = batchId;
    param.category = category;
    param.tab = tab;
    actions.msgTplServer.sendTplMsg(param).then(res => {
      let tipText = '';
      let tipType = null;
      if (res.data.errno === 0) {
        tipText = '发送成功';
        tipType = CHECK;
      } else {
        tipText = res.data.errmsg;
        tipType = WARN;
      }
      new PopUp(PopTip, {
        classname: 'pop_tip',
        type: tipType,
        isShow: true,
        content: tipText,
        autoDestroy: true,
      }).show();
    });
  };
  printListFn = () => {
    // 中转信息
    const { tableFilter } = this.state.pickOrderInfo.batchTableInfo; //  查询信息
    const rightQuy = tableFilter.right;
    console.log(tableFilter);
    const urlData = {
      controller: 'Print',
      action: 'printList',
      query: {
        tabName: this.state.printTitle,
      },
    };
    const _pack = {
      url: this.state.printUrl,
      title: this.state.printTitle,
      req: {
        category: tableFilter.category,
        tab: this.state.printTab,
        page_size: rightQuy.page_size || null,
        query: rightQuy.query,
        // filter,
        fetch_mode: 'all',
        // sort
      },
    };
    actions.api.direct(urlData, 'data', {
      packData: _pack,
    });
  };
  // 打印预览 button click
  printPreviewBtnClick = (key, value, printParm) => {
    let { printTab } = this.state;
    if (printTab === undefined || printTab === '') return false;
    if (batchPrintConf.printList.indexOf(printTab) > -1) {
      this.printListFn();
      return;
    }
    const { printType } = this.state;
    const { batchId } = this.state;
    const select = value.tableInfo.select || [];
    const list = value.tableInfo.list || [];
    const newList = [];
    const packList = [];
    select.forEach(item => {
      if (list[item].b_pack_batch) {
        packList.push(list[item]);
      } else {
        newList.push(list[item]);
      }
    });
    const orderIds = filterListColumnByKey(newList || [], 'od_link_id');
    const packIds = filterListColumnByKey(packList || [], 'b_link_id');
    // let orderIds = this.getSelectids(value)
    const urlData = {
      controller: 'Print',
      action: 'batchPrintList',
      query: {
        isRefresh: true,
      },
    };
    if (printParm && printParm.tpl_key) {
      printTab = this.trLoadingDefaultPrintArray[printParm.tpl_key];
    }
    actions.api.direct(urlData, 'printInfo', {
      printTab,
      printType,
      batchId,
      orderIds,
      company_id: this.state.company_id || this.state.comId,
      pageUrl: this.state.printUrl || this.state.pageUrl,
      printParm,
      packIds,
    });
  };
  printAction = (tplIdHere, orderIds, ext, testSlnSet, pack_ids, dataJson) => {
    // ext ===> selectedBtn
    const { type, pageTab /* trDownPrintType */ } = this.props;
    const { pickOrderInfo = {} } = this.state;
    const { batch_info = {} } = pickOrderInfo;
    const { order_num, pack_num, allPackIds, allOrderIds } = dataJson;
    const isTrDown = +batch_info.type === 2; //  || trDownPrintType
    const _type = isTrDown ? 'tr_down' : type;
    const isPack = ext && ext.tpl_type === 'pack';
    const isOrder = ext && ext.tpl_type === 'order';
    const isPackOrOrder = (isPack || isOrder) && (pageTab === 'tr_up' || pageTab === 'tr_down');
    const allNotCheck =
      // eslint-disable-next-line no-nested-ternary
      !orderIds.length && !pack_ids.length ? (isPack ? allPackIds : allOrderIds) : isPack ? pack_ids : orderIds;
    const ids = isPackOrOrder ? allNotCheck : [this.state.batchId];
    const sendReq = () => {
      actions.orderServer
        .getPrintInfo({
          cid: this.printerHostId,
          tpl_id: tplIdHere,
          fields_type: (ext && ext.tpl_type) || 'tr_loading_list',
          // ids: this.selectIds,
          /*
        type: (_type.indexOf('tr_down') > -1) ? 2 : undefined, // 下游发车 需要传 type:2
        */
          type: _type.indexOf('tr_down') > -1 ? 2 : undefined, // 下游发车 需要传 type:2
          ids,
          order_ids: isPackOrOrder ? [] : orderIds,
          pack_ids: isPackOrOrder ? [] : pack_ids,
          company_id: this.state.comId,
          isCheck: true,
        })
        .then(rest => {
          const restData = { ...rest.data };
          testSlnSet && (restData.res.tpl_setting = { ...restData.res.tpl_setting, ...testSlnSet });
          this.handleApiResPrint(restData, tplIdHere, orderIds, ext);
        });
    };
    const packNotPermiss = isPack && orderIds && orderIds.length;
    const orderNotPermiss = isOrder && pack_ids && pack_ids.length;
    const allPackNotPermiss = packNotPermiss && !pack_ids.length;
    const allOrderNotPermiss = orderNotPermiss && !orderIds.length;
    if (packNotPermiss || orderNotPermiss) {
      const allNotPermiss = allPackNotPermiss || allOrderNotPermiss;
      const failed_detail = [];
      const failData = isPack ? orderIds : pack_ids;
      failData.forEach(i => {
        failed_detail.push({ number: i });
      });
      const orderStr = isPack ? '运' : '打包';
      const packStr = isPack ? '打包单' : '运单';
      const tipsStr = allNotPermiss
        ? `您选择的是${orderStr}单，无法按${packStr}模板打印，请检查后重新选择！`
        : `以下单据是${orderStr}单，无法按${packStr}模板打印,是否继续打印其他${packStr}?`;
      NewOpTips({
        resData: {
          res: {
            failed_detail,
            success_id: isPack ? pack_ids : orderIds,
          },
          errno: 0,
        },
        sucCallback: () => {}, // 成功的回调
        continueCallback: (sucId, close) => {
          close();
          sendReq();
        }, // 继续 btn 对应的回调
        showSucTips: false,
        ext: {
          specialTips: tipsStr,
          noticeTitle: '运单号',
          reason: isPack ? order_num : pack_num,
          hideList: allNotPermiss,
          typeTitle: isPack ? '运单号:' : '打包号:',
        },
      });
    } else {
      sendReq();
    }
  };
  handleApiResPrint = async (resDt, tplIdHere, orderIds, ext) => {
    let messageContent = '请创建装车清单打印模板';
    let showTip = false;
    const printCopies = 1;
    if (resDt.errno < 0) {
      messageContent = resDt.errmsg;
      showTip = true;
    }
    const tplSet = resDt.res.tpl_setting;
    if (!tplSet || tplSet.length < 1 || showTip) {
      new PopUp(PopTip, {
        classname: 'pop_tip',
        type: ERROR,
        isShow: true,
        content: messageContent,
        autoDestroy: true,
      }).show();
      return null;
    }
    if (tplSet.has_sln !== 1) {
      // 是否有打印方案
      actions.orderServer
        .getPrintSln({
          cid: this.printerHostId,
          tpl_id: tplIdHere,
        })
        .then(rest => this.apiPrintTpl(rest.data, tplIdHere, orderIds, ext));
    } else {
      const resTplData = resDt.res.tpl_data;
      for (let i = 0; i < printCopies; i++) {
        for (let k = 0; k < resTplData.length; k++) {
          const currTplData = resTplData[k];
          if (resDt.req.fields_type === 'order') {
            const printProxy = {
              LODOP: this.LodopObj,
              data: currTplData,
              printSetting: tplSet,
              tplStr: tplSet.detail,
              needCheck: false,
              dataSum: Math.max(resTplData.length, printCopies),
            };
            await PrintManager.print(printProxy);
          } else {
            const isUTD = tplSet.category === 0;
            const tplSetDetail = isUTD ? groupIdxSum({ tplSet, idx: k, total: resTplData.length }) : tplSet.detail;

            const printProxy = {
              LODOP: this.LodopObj,
              data: currTplData,
              printSetting: tplSet,
              tplDetail: tplSetDetail,
              needCheck: false,
              dataSum: Math.max(resTplData.length, printCopies),
            };

            isUTD ? await PrintManager.untdTablePrint(printProxy) : await PrintManager.tablePrint(printProxy);
          }
        }
      }
      // console.log('调用printDialog', resDt)
    }
  };
  apiPrintTpl = (resDt, tplIdHere, orderIds, ext) => {
    console.log(orderIds, ext);
    const resResult = resDt.res;
    if (resResult) {
      resResult.data.printer.otherProps.data = LodopFunc.getAllPrinters(LodopFunc.getLodop());
    }
    new PopUp(printSlnDialog, {
      dialogKey: 'printSln',
      dialogInfo: resResult || {},
      cid: this.printerHostId,
      ref: r => (this.coPrintDigSlnRef = r),
      tplId: tplIdHere,
      tplType: ext && ext.tpl_type,
      // onClose: () => this.printAction(tplIdHere, orderIds, ext),
      close: thisDialog => thisDialog.handleHide(),
      closePrintSln: (where, testSlnSet) => {
        if (where === 'confirm') {
          this.printAction(tplIdHere, orderIds, ext, testSlnSet);
        } else {
          this.coPrintDigSlnRef = null;
        }
      },
    }).show();
  };

  cancelRecordCheckFee = () => {
    const picker = this.context.getPick();
    // const feeInfo = this.state.pickOrderInfo.feeInfo
    const feeInfo = picker.state.feeInfo || {};
    let feeData = feeInfo.data || [];
    this.feeDataBak && (feeData = this.feeDataBak);
    picker && picker.feeTable && picker.feeTable.resetData(feeData);
    picker && picker.requestPickTable();
    this.setState({ showSaveBtn: false });
  };

  // 到站对账费用录入
  saveRecordCheckFee = async () => {
    const picker = this.context.getPick();
    const data = picker.biPickOrder.getTableData('right');
    const checkKeyList =
      this.props.realTab === 'tr_down'
        ? ['check_delivery_f', 'check_inner_points_trans_f', 'check_trans_f', 'od_rmt_unload_f', 'od_rmt_msc_f']
        : ['budget_trans_f', 'budget_delivery_f', 'budget_handling_f', 'budget_upstairs_f', 'budget_misc_f'];
    const saveKeyList = ['inner_remark'];
    const list = [];
    for (let iter = 0; iter < Object.keys(this.checkFeeChange).length; iter++) {
      const index = Object.keys(this.checkFeeChange)[iter];
      const res = { od_link_id: data[index].od_link_id };
      const checkRes = checkKeyList.every(key => {
        const value = data[index][key];
        if (value && !`${value}`.match(FLOAT)) {
          createTip(validateTips[FLOAT], ERROR).show();
          return false;
        }
        res[key] = value;
        return true;
      });
      // 数据校验不通过
      if (!checkRes) return;
      saveKeyList.forEach(key => {
        res[key] = data[index][key];
      });
      list.push(res);
    }
    const cid = this.state.company_id || this.state.comId;
    const { basicId } = this.props;
    const url = this.props.realTab === 'tr_down' ? 'Truck/Truck/modifyArrFee' : 'Truck/Truck/recordBudgetFee';
    let req = { isCheck: 1, b_basic_id: basicId, company_id: cid, od_info: list, b_link_id: this.state.batchId };
    if (this.props.realTab === 'tr_down') {
      req = { isCheck: 1, b_basic_id: basicId, company_id: cid, od_fee: list, b_link_id: this.state.batchId };
      if (Object.keys(this.feeChange).length) {
        const checkRes = picker.feeTable.getTableData(true, {}, false);
        if (!checkRes.isPass) {
          createTip(formateTableCheckTips(checkRes), ERROR).show();
          return false;
        }
        const feeData = checkRes.data || [];
        const pack = {};
        const index = this.getCompanyIndex(feeData);
        const arrDisable = [
          'b_arr_f',
          'b_rmt_unload_f',
          'b_rmt_misc_f',
          'b_rmt_landing_f',
          'plan_arr_t',
          'b_oil_f',
          'b_bridge_f',
        ];
        const checkBoxEle = ['b_rmt_misc_f_paid', 'b_rmt_unload_f_paid'];
        arrDisable.forEach(item => (pack[item] = feeData[index][item]));
        checkBoxEle.forEach(
          // eslint-disable-next-line no-nested-ternary
          item => (pack[item] = feeData[index][item] ? 1 : feeData[index][item] ? 0 : feeData[index][item]),
        );
        req.batch_fee = pack;
      }
    }
    const conf = { method: 'POST', body: { req } };
    const result = await fetchApi(url, conf);
    const { res } = result;
    const reason = res.failed_detail;
    const failedDetail = reason && reason.length && (
      <p style={{ marginLeft: '66px' }}>
        {reason.map(item => `${item.number}${item.msg}`).join(',')}
        <br />
        是否继续将可编辑的操作保存？
      </p>
    );
    const failedDetailTip = reason && reason.length && '以下运单不能编辑费用';
    const faileContent = reason && reason.length && (
      <p style={{ marginLeft: '66px' }}>{reason.map(item => `${item.number}${item.msg}`).join(',')}</p>
    );
    if (res.success_ids && res.success_ids.length) {
      if (failedDetail && !(await confirm(WARN, '以下运单不能编辑费用', { confirm: '继续' }, '确认', failedDetail)))
        return false;
      if (failedDetail) {
        req.isCheck = 0;
        await fetchApi(url, conf);
      }
      showInfo(CHECK, '保存成功');
      this.checkFeeChange = {};
      this.feeChange = {};
      this.setState({ showSaveBtn: false });
      picker.feeTable && (this.feeDataBak = picker.feeTable.getStateCache());
      throttle(() => {
        picker.requestPickTable();
      }, 1000)();
    } else {
      alert(ERROR, failedDetailTip || res.errmsg || '保存失败', undefined, faileContent);
    }
  };

  handleFeeTableSelectDropSelect = (feeInfo, rowIndex) => {
    if (this.props.realTab === 'tr_down') {
      this.feeChange[rowIndex] = (feeInfo.data || [])[rowIndex];
      this.setState({ showSaveBtn: true });
    }
  };
  handleFeeTableChange = (feeInfo, res) => {
    setTimeout(() => {
      if (this.props.realTab === 'tr_down') {
        this.feeChange[res.rowIndex] = (feeInfo.data || [])[res.rowIndex];
        this.setState({ showSaveBtn: true });
      }
    }, 100);
    // 删除快照
    const { rowIndex, columnKey } = res;
    if (
      rowIndex === 0 &&
      (this.priceSnapshot[columnKey] || !this.priceSnapshotDelete[columnKey]) &&
      (columnKey === 'b_info_f' ||
        columnKey === 'b_tr_tail_f' ||
        columnKey === 'b_handling_f' ||
        columnKey === 'b_spot_fee' ||
        columnKey === 'b_trans_tax' ||
        columnKey === 'b_upstairs_f' ||
        columnKey === 'b_yf_trans_f')
    ) {
      delete this.priceSnapshot[columnKey];
      this.priceSnapshotDelete[columnKey] = 1;
      setTimeout(() => {
        const picker = this.context.getPick();
        const { feeTable } = picker;
        const feeData = feeTable && feeTable.getTableData(false).data;
        if (!feeData || !feeData.length) {
          return;
        }
        if (columnKey === 'b_info_f') {
          _.unset(feeData[0], ['otherProps', 'extRender', 'b_info_f']);
        }
        if (columnKey === 'b_tr_tail_f') {
          _.unset(feeData[0], ['otherProps', 'extRender', 'b_tr_tail_f']);
        }
        if (columnKey === 'b_handling_f') {
          _.unset(feeData[0], ['otherProps', 'extRender', 'b_handling_f']);
        }
        if (columnKey === 'b_spot_fee') {
          _.unset(feeData[0], ['otherProps', 'extRender', 'b_spot_fee']);
        }
        if (columnKey === 'b_trans_tax') {
          _.unset(feeData[0], ['otherProps', 'extRender', 'b_trans_tax']);
        }
        if (columnKey === 'b_upstairs_f') {
          _.unset(feeData[0], ['otherProps', 'extRender', 'b_upstairs_f']);
        }
        if (columnKey === 'b_yf_trans_f') {
          _.unset(feeData[0], ['otherProps', 'extRender', 'b_yf_trans_f']);
        }
        picker.setState({
          feeInfo: {
            ...feeInfo,
            data: feeData,
          },
        });
      }, 50);
    }
    if (columnKey === 'b_kilometers' && this.need_calc_price) {
      setTimeout(() => {
        const picker = this.context.getPick();
        const { feeTable } = picker;
        const feeData = feeTable && feeTable.getTableData(false).data;
        if (!feeData || !feeData.length) {
          return;
        }
        this.cardExtData.line_distance = _.sumBy(feeData, item => +item.b_kilometers || 0);
        this.calcBatchPrice();
      });
    }
  };
  handleBiTableChange = (rowIndex, col, value, resData, btnkey, tableHeader, cardRef) => {
    const saveKeys = [
      'check_trans_f',
      'check_inner_points_trans_f',
      'check_delivery_f',
      'od_rmt_unload_f',
      'od_rmt_msc_f',
      'budget_trans_f',
      'budget_delivery_f',
      'budget_handling_f',
      'budget_upstairs_f',
      'budget_misc_f',
      'inner_remark',
    ];
    if (saveKeys.includes(col)) {
      // 防止有前端筛选的情况
      const picker = this.context.getPick();
      const oriIndex = picker.biPickOrder.changeIndexToOrgIndex('right', [rowIndex])[0];
      this.checkFeeChange[oriIndex] = 1;
      this.setState({ showSaveBtn: true });
      return;
    }
    // 表格内单票提货费变更，更新单票提货费合计 start
    const cardData = cardRef.getStateData();
    if (col === 'pickup_f') {
      const pickupFTotal = this.getRightTotal();

      const cardPickupObj = cardData.pickup_f_total ? getCcombinderValue(cardData.pickup_f_total.otherProps.data) : {};
      const cardPickup = cardPickupObj ? cardPickupObj.pickup_f_total : '';

      const calcPickupFee = this.getCalcPickUp(cardRef);

      this.setNewCard(cardData, (+pickupFTotal && +cardPickup !== +pickupFTotal) || +calcPickupFee !== cardPickup);
    }
    // 表格内单票提货费变更，更新单票提货费合计 end
    // 批次提货费合计
    const itemList = getSettingItemList();
    if (col === 'b_pickup_f' && !this.disabledBPickupF) {
      const total = this.getRightTotal(col);
      const pick = this.context.getPick();
      if (cardData.b_pickup_f) {
        const newTotal = total ? total.toFixed(2) : total;
        if (itemList[0] && cardData[itemList[0]]) {
          let num2 = 0;
          itemList.forEach(item => {
            if (itemList[0] === item || !cardData[item]) return;
            num2 += +cardData[item].otherProps.defaultValue || 0;
          });
          cardData[itemList[0]].otherProps.defaultValue = +((+newTotal || 0) - num2).toFixed(2);
        }
        cardData.b_pickup_f.otherProps.inputProps.defaultValue = newTotal;
        if (this.priceSnapshot.b_pickup_f) {
          delete this.priceSnapshot.b_pickup_f;
          delete cardData.b_pickup_f.otherProps.spanContent;
        }
        pick.setCardFormData(cardData);
      }
      resData.list[rowIndex] = this.calcPickupPayMonthly([resData.list[rowIndex]])[0]; // eslint-disable-line
    }
    // 批次送货费合计
    if (col === 'b_delivery_f' && !this.disabledBDeliveryF) {
      const total = this.getRightTotal(col);
      const pick = this.context.getPick();
      if (cardData.b_delivery_f) {
        const newTotal = total ? total.toFixed(2) : total;
        if (itemList[0] && cardData[itemList[0]]) {
          let num2 = 0;
          itemList.forEach(item => {
            if (itemList[0] === item || !cardData[item]) return;
            num2 += +cardData[item].otherProps.defaultValue || 0;
          });
          cardData[itemList[0]].otherProps.defaultValue = (+newTotal || 0) - num2;
        }
        cardData.b_delivery_f.otherProps.inputProps.defaultValue = newTotal;
        if (this.priceSnapshot.b_delivery_f) {
          delete this.priceSnapshot.b_delivery_f;
          delete cardData.b_delivery_f.otherProps.spanContent;
        }
        pick.setCardFormData(cardData);
      }
      resData.list[rowIndex] = this.calcDeliveryPayMonthly([resData.list[rowIndex]])[0]; // eslint-disable-line
    }

    const key = col.includes('b_pickup_') ? '_pickup_' : '_delivery_';
    const newKey = col.split(key).join('_');
    if (cardData[newKey]) {
      // 计算列和
      let num = 0;
      resData.list.forEach(item => {
        num += +item[col] || 0;
      });
      cardData[newKey].otherProps.defaultValue = num;

      const pickupNewItemList = itemList.map(s => s.split('b_').join('b_pickup_'));
      const deliveryNewItemList = itemList.map(s => s.split('b_').join('b_delivery_'));
      if (pickupNewItemList.includes(col) || deliveryNewItemList.includes(col)) {
        const key1 = col.includes('b_pickup_') ? 'b_pickup_f' : 'b_delivery_f';
        let num2 = 0;
        itemList.forEach(item => {
          num2 += +cardData[item].otherProps.defaultValue || 0;
        });
        cardData[key1].otherProps.inputProps.defaultValue = num2;
      }
      this.context.getPick().setCardFormData(cardData);
    }
    // 配载的重量体积和件数发生变化， 重新计算配载率
    if (col === 'num' || col === 'weight' || col === 'volume') {
      const rightTable = resData.list || [];
      const res = this.getWeightVolume(rightTable || []);
      this.resetRateValue(res, 0, 1);
    }
    handleBiTableChange(rowIndex, col, value, resData, this.context);
  };
  drNameSelectChange = value => {
    const pick = this.context.getPick();
    const { cardForm } = pick;
    const val = (value && value[0]) || {};
    const polCardShow = _.get(cardForm, 'props.data.b_tr_payee_oil_card_no.display');
    const hasFuelCard = _.get(value, '[0].oil_card_no');
    if (polCardShow) {
      setTimeout(() => {
        cardForm.setComVal('b_tr_payee_oil_card_no', hasFuelCard, 'SelectDrop');
      }, 100);
    }
    this.cardExtData.dr_id = val.id;
    this.calcBatchPrice();
  };

  drNameInputChange = () => {
    delete this.cardExtData.dr_id;
    this.calcBatchPrice();
  };
  bTrTeamNameSelectChange = value => {
    const val = (value && value[0]) || {};
    this.cardExtData.carrier_name = val.carrier_name;
    this.cardExtData.carrier_id = val.id;
    this.calcBatchPrice();
  };
  drPhoneSelectChange = value => {
    const pick = this.context.getPick();
    const { cardForm } = pick;
    const val = (value && value[0]) || {};
    const polCardShow = _.get(cardForm, 'props.data.b_tr_payee_oil_card_no.display');
    const hasFuelCard = _.get(value, '[0].oil_card_no');
    if (polCardShow) {
      setTimeout(() => {
        cardForm.setComVal('b_tr_payee_oil_card_no', hasFuelCard, 'SelectDrop');
      }, 100);
    }
    this.cardExtData.dr_id = val.id;
    this.calcBatchPrice();
  };

  drPhoneInputChange = () => {
    delete this.cardExtData.dr_id;
    this.calcBatchPrice();
  };

  trNumInputChange = value => {
    delete this.cardExtData.tr_type;
    delete this.cardExtData.tr_length;
    this.cardExtData.tr_num = value;
    this.calcBatchPrice();
  };

  trNumExtInputChange = value => {
    this.cardExtData.tr_num_ext = value;
    this.calcBatchPrice();
  };

  planTruckTInputChange = () => {
    this.calcBatchPrice();
  };

  routeInputChange = value => {
    if (this.cardExtData.route_id_src !== 'sug') {
      delete this.cardExtData.route_id;
    }
    delete this.cardExtData.route_id_src;
    this.calcBatchPrice();
  };

  routeSelectChange = value => {
    const routeInfo = value[0] || {};
    this.cardExtData.route_id = routeInfo.id;
    this.cardExtData.route_id_src = 'sug';
    this.calcBatchPrice();
  };

  /*
    拼接线路变化后，请求出来的线路信息
  */
  routeInfoSelectChange = value => {
    const routeInfo = value || {};
    this.cardExtData.route_id = routeInfo.id;
    this.cardExtData.line_distance = routeInfo.line_distance;
    this.calcBatchPrice();
  };

  truckTotalInputChange = val => {
    const pickOrder = this.context.getPick();
    if (this.priceSnapshot.b_tr_f_total || !this.priceSnapshotDelete.b_tr_f_total) {
      delete this.priceSnapshot.b_tr_f_total;
      setTimeout(() => {
        const picker = this.context.getPick();
        const data = pickOrder.cardForm.getStateHeader();
        const newData = fromJS(data).setIn(['truck_total', 'otherProps', 'spanContent'], undefined);
        picker.cardForm.setHeaderData(newData.toJS());
      });
    }
    this.priceSnapshotDelete.b_tr_f_total = 1;
    this.truckTotalInputChangeRelateFeeData(val);
  };

  truckTotalInputChangeRelateFeeData = async (val, cb, col) => {
    const pickOrder = this.context.getPick();
    const feeInfo = pickOrder.state.feeInfo || {};
    const { feeTable } = pickOrder;
    const feeData = feeTable && feeTable.getTableData(false).data;
    if (!feeData || !feeData.length) {
      return;
    }
    const itemList = getSettingItemList();
    const key = itemList[0];
    let newItemList = [];
    if (itemList.length) {
      newItemList = itemList.filter(item => item !== key);
    }
    let totalData = 0;
    let totalData1 = 0;
    const total = +val || 0;
    feeData.forEach((item, index) => {
      totalData += (+item.b_billing_f || 0) + (+item.b_fuel_card_f || 0) + (+item.b_arr_f || 0);
      if (key) {
        newItemList.forEach(j => {
          totalData1 += +item[j] || 0;
        });
        if (index !== 0) {
          totalData1 += +item[key] || 0;
        }
      }
      if (index !== 0) {
        totalData += +item.b_receipt_f || 0;
      }
    });
    const bReceiptF = +(total - totalData).toFixed(2);
    if (col !== 'b_receipt_f') feeData[0].b_receipt_f = bReceiptF || '';
    feeData[0][key] = +(total - totalData1).toFixed(2);
    setTimeout(() => {
      pickOrder.setState(
        {
          feeInfo: {
            ...feeInfo,
            data: feeData,
          },
        },
        () => {
          cb && cb();
        },
      );
    }, 20);
  };

  truckTInputChange = (val, cardRef, pageData) => {
    this.calcBatchPrice();
    if (!g7Done()) {
      return false;
    }

    const pickCntRef = this.context.getPick();
    const { batchInfo = {} } = pickCntRef.props;
    const { props = {} } = cardRef;
    const { header = {} } = props;
    const { pageTab = '', realTab = '' } = this.props;

    const currTab = realTab || pageTab;

    let compareTimeVal = 0;
    let showTxt = '';
    if (currTab === 'tr_up') {
      showTxt = '发车时间晚于到达时间，请修改';
      compareTimeVal = batchInfo.batch_id === batchInfo.head_b_link_id ? batchInfo.end_arr_t || 0 : 0;
    } else if (currTab === 'pickup_batch') {
      showTxt = '提货时间晚于提货完成时间，请修改';
      compareTimeVal = batchInfo.pickup_arr_t || 0;
    } else if (currTab === 'delivery_batch') {
      showTxt = '送货时间晚于送货完成时间，请修改';
      compareTimeVal = batchInfo.delivery_arr_t || 0;
    } else if (currTab === 'shuttle_up') {
      showTxt = '短驳时间晚于短驳接收时间，请修改';
      compareTimeVal = batchInfo.shuttle_arr_t || 0;
    }

    const currTime = new Date(val).getTime();
    const compareTime = new Date(compareTimeVal).getTime();
    const doCheck = !(currTime < compareTime);

    if (!doCheck || !compareTime) {
      return false;
    }

    doCheck && showInfo(ERROR, showTxt);

    const headData = pageData.cardHeader;
    const oldTruckT = getComVal(header.truck_t) || '';
    const newTruckTVal = doCheck ? oldTruckT : val;

    const newHead = { ...headData };

    newHead.truck_t.otherProps.defaultValue = newTruckTVal;

    const cardCom = pickCntRef.cardForm;
    cardCom.setHeaderData(newHead);
  };

  bDeliveryFInputChange = (val, cardRef, pageData) => {
    console.log('bDeliveryFInputChange');
    const picker = this.context.getPick();
    if (this.priceSnapshot.b_delivery_f || !this.priceSnapshotDelete.b_delivery_f) {
      delete this.priceSnapshot.b_delivery_f;
      setTimeout(() => {
        const data = picker.cardForm.getStateData();
        const newData = fromJS(data).setIn(['b_delivery_f', 'otherProps', 'spanContent'], undefined);
        picker.cardForm.setData(newData.toJS());
      });
    }
    this.priceSnapshotDelete.b_delivery_f = 1;
    const { itemList = [] } = window.company_setting.b_f_total_formula.formulaDt[0];
    const cardData = pageData.cardInfo;
    let num = 0;
    if (itemList[0] && cardData[itemList[0]]) {
      itemList.forEach(item => {
        if (item === itemList[0]) return;
        num += +cardRef.getComVal(item) || 0;
      });
      cardData[itemList[0]].otherProps.defaultValue = +(+val - num).toFixed(2);
      cardData.b_delivery_f.otherProps.inputProps.defaultValue = val;
      picker.setCardFormData(cardData);
    }
  };

  /* 提货批次代码， start, 期间请入插入其他逻辑代码 与/pages/PickupController/load/index.js 代码重复 */
  // 提货费变化 计算单票提货费合计
  bPickupFInputChange = (val, cardRef, pageData) => {
    const picker = this.context.getPick();
    if (this.priceSnapshot.b_pickup_f || !this.priceSnapshotDelete.b_pickup_f) {
      delete this.priceSnapshot.b_pickup_f;
      setTimeout(() => {
        const data = picker.cardForm.getStateData();
        const newData = fromJS(data).setIn(['b_pickup_f', 'otherProps', 'spanContent'], undefined);
        picker.cardForm.setData(newData.toJS());
      });
    }
    const cardData = pageData.cardInfo;
    const { itemList = [] } = window.company_setting.b_f_total_formula.formulaDt[0];
    let num = 0;
    if (itemList[0] && cardData[itemList[0]]) {
      itemList.forEach(item => {
        if (item === itemList[0]) return;
        num += +cardRef.getComVal(item) || 0;
      });
      cardData[itemList[0]].otherProps.defaultValue = +(+val - num).toFixed(2);
      cardData.b_pickup_f.otherProps.inputProps.defaultValue = val;
      picker.setCardFormData(cardData);
    }
    this.priceSnapshotDelete.b_pickup_f = 1;
    const pickFeeRatio = cardRef.getComVal('pickup_f_ratio') || '';
    if (!+pickFeeRatio) {
      return;
    }
    const newTotal = +(+pickFeeRatio * +val).toFixed(2);
    const tableList = pageData.tableInfo.list || [];
    const rowPickup = this.getRightTotal();
    // const rowPickup = tableList.reduce((all, item) => +item.pickup_f ? +(all + +item.pickup_f).toFixed(2) : all, 0)

    cardData.b_pickup_f = setComVal(cardData.b_pickup_f, val).toJS();
    cardData.pickup_f_total = setComVal(cardData.pickup_f_total, { pickup_f_total: newTotal }).toJS();

    this.setNewCard(cardData, +rowPickup !== +newTotal && +rowPickup);
  };
  bYfTransFInputChange = (val, cardRef, pageData) => {
    this.inputChange('b_yf_trans_f', val, cardRef, pageData);
  };
  bSpotFeeInputChange = (val, cardRef, pageData) => {
    this.inputChange('b_spot_fee', val, cardRef, pageData);
  };
  bUpstairsFInputChange = (val, cardRef, pageData) => {
    this.inputChange('b_upstairs_f', val, cardRef, pageData);
  };
  bHandlingFInputChange = (val, cardRef, pageData) => {
    this.inputChange('b_handling_f', val, cardRef, pageData);
  };
  bTransTaxInputChange = (val, cardRef, pageData) => {
    this.inputChange('b_trans_tax', val, cardRef, pageData);
  };
  bInfoFInputChange = (val, cardRef, pageData) => {
    this.inputChange('b_info_f', val, cardRef, pageData);
  };
  inputChange = (key, val, cardRef, pageData) => {
    const { itemList = [] } = window.company_setting.b_f_total_formula.formulaDt[0];
    if (!itemList.includes(key)) return;
    let num = 0;
    itemList.forEach(item => {
      if (item === key) return;
      num += +cardRef.getComVal(item) || 0;
    });
    const cardData = pageData.cardInfo;
    const pick = this.context.getPick() || {};
    if (cardData.b_pickup_f) cardData.b_pickup_f.otherProps.inputProps.defaultValue = +(+num + +val).toFixed(2);
    if (cardData.b_delivery_f) cardData.b_delivery_f.otherProps.inputProps.defaultValue = +(+num + +val).toFixed(2);
    cardData[key].otherProps.defaultValue = val;
    pick.setCardFormData(cardData);
  };
  // 提货费合计变化 更新合计值和tips
  pickupFTotalInputBlur = (value, cardRef, pageData) => {
    const val = value.pickup_f_total;
    const cardData = pageData.cardInfo;
    const calcPickupFee = this.getCalcPickUp(cardRef);

    const tableList = pageData.tableInfo.list || [];
    const rowPickup = this.getRightTotal();
    // const rowPickup = tableList.reduce((all, item) => +item.pickup_f ? +(all + +item.pickup_f).toFixed(2) : all, 0)
    const rightEqual = +rowPickup ? +rowPickup === +val : true;

    cardData.pickup_f_total = setComVal(cardData.pickup_f_total, { pickup_f_total: val }).toJS();
    this.setNewCard(cardData, +calcPickupFee !== +val || !rightEqual);
  };

  setNewCard = (cardData, val, pickCnt) => {
    const oldTotalSum = { ...cardData.pickup_f_total };
    const oldOther = { ...oldTotalSum.otherProps };

    const newOtherCls = `pickup-f-total-sum-data ${val ? '' : 'no-warn-tip'}`;

    const newTotalSum = { ...oldTotalSum, otherProps: { ...oldOther, classname: newOtherCls } };
    const newCard = { ...cardData, pickup_f_total: { ...newTotalSum } };

    const pickCntRef = pickCnt || this.context.getPick();

    pickCntRef.setCardFormData(newCard);
  };

  oneWayOfMilesInputChange = value => {
    const pick = this.context.getPick();
    const { cardForm } = pick;
    const theoryOilShow = _.get(cardForm, 'props.data.theory_oil.display');
    const standardOil = cardForm.getStateDataForm().standard_oil;
    if (theoryOilShow) {
      setTimeout(() => {
        const theoryOil = +(((+standardOil || 0) * (+value || 0)) / 100).toFixed(2);
        cardForm.setComVal('theory_oil', theoryOil, 'PureInput');
      }, 100);
    }
  };

  standardOilInputChange = value => {
    const pick = this.context.getPick();
    const { cardForm } = pick;
    const theoryOilShow = _.get(cardForm, 'props.data.theory_oil.display');
    const oneWayOfMiles = cardForm.getStateDataForm().one_way_of_miles;
    if (theoryOilShow) {
      setTimeout(() => {
        const theoryOil = +(((+value || 0) * (+oneWayOfMiles || 0)) / 100).toFixed(2);
        cardForm.setComVal('theory_oil', theoryOil, 'PureInput');
      }, 100);
    }
  };

  calcBatchPrice = () => {
    const tab = this.props.realTab;
    const tabTypeMap = { tr_up: 'truck_pt', delivery_batch: 'delivery_pt', pickup_batch: 'pickup_pt' };
    const type = tabTypeMap[tab];
    if (!this.need_calc_price || !type || +window.group_id === 88888) return;
    this.context.getPick().calcBatchPrice(type, this.cardExtData, res => {
      const result = res.res && res.res.price[0];
      const priceSnapshot = {};
      if (result[type]) {
        const picker = this.context.getPick();
        const data = picker.cardForm.getStateData();
        let newData = fromJS(data);
        if (type === 'truck_pt') {
          newData = fromJS(picker.cardForm.getStateHeader());
          const b_info_f = +result.truck_pt.b_info_f;
          const b_tr_tail_f = +result.truck_pt.b_tr_tail_f;
          const b_handling_f = +result.truck_pt.b_handling_f;
          const b_spot_fee = +result.truck_pt.b_spot_fee;
          const b_trans_tax = +result.truck_pt.b_trans_tax;
          const b_upstairs_f = +result.truck_pt.b_upstairs_f;
          const b_yf_trans_f = +result.truck_pt.b_yf_trans_f;
          if (b_info_f) {
            priceSnapshot.b_info_f = res.res.process_snapshot[0].truck_pt.b_info_f;
          }
          if (b_tr_tail_f) {
            priceSnapshot.b_tr_tail_f = res.res.process_snapshot[0].truck_pt.b_tr_tail_f;
          }
          if (b_handling_f) {
            priceSnapshot.b_handling_f = res.res.process_snapshot[0].truck_pt.b_handling_f;
          }
          if (b_spot_fee) {
            priceSnapshot.b_spot_fee = res.res.process_snapshot[0].truck_pt.b_spot_fee;
          }
          if (b_trans_tax) {
            priceSnapshot.b_trans_tax = res.res.process_snapshot[0].truck_pt.b_trans_tax;
          }
          if (b_upstairs_f) {
            priceSnapshot.b_upstairs_f = res.res.process_snapshot[0].truck_pt.b_upstairs_f;
          }
          if (b_yf_trans_f) {
            priceSnapshot.b_yf_trans_f = res.res.process_snapshot[0].truck_pt.b_yf_trans_f;
          }
          // 因为truckTotalInputChangeRelateFeeData 延时100ms setState 这里要延时更多
          setTimeout(() => {
            const { feeTable } = picker;
            const feeInfo = picker.state.feeInfo || {};
            const feeData = feeTable && feeTable.getTableData(false).data;
            if (!feeData || !feeData.length) {
              return;
            }
            feeData[0].b_info_f = b_info_f || '';
            feeData[0].b_tr_tail_f = b_tr_tail_f || '';
            feeData[0].b_handling_f = b_handling_f || '';
            feeData[0].b_spot_fee = b_spot_fee || '';
            feeData[0].b_trans_tax = b_trans_tax || '';
            feeData[0].b_upstairs_f = b_upstairs_f || '';
            feeData[0].b_yf_trans_f = b_yf_trans_f || '';
            if (b_info_f) {
              _.set(feeData[0], ['otherProps', 'extRender', 'b_info_f'], ({ type: cellType }) => (
                <PriceSnapshotIcon className={`input-icon ${cellType}-priceSnapshot`} data={priceSnapshot.b_info_f} />
              ));
            } else {
              _.unset(feeData[0], ['otherProps', 'extRender', 'b_info_f']);
            }
            if (b_tr_tail_f) {
              _.set(feeData[0], ['otherProps', 'extRender', 'b_tr_tail_f'], ({ type: cellType }) => (
                <PriceSnapshotIcon
                  className={`input-icon ${cellType}-priceSnapshot`}
                  data={priceSnapshot.b_tr_tail_f}
                />
              ));
            } else {
              _.unset(feeData[0], ['otherProps', 'extRender', 'b_tr_tail_f']);
            }
            if (b_handling_f) {
              _.set(feeData[0], ['otherProps', 'extRender', 'b_handling_f'], ({ type: cellType }) => (
                <PriceSnapshotIcon
                  className={`input-icon ${cellType}-priceSnapshot`}
                  data={priceSnapshot.b_handling_f}
                />
              ));
            } else {
              _.unset(feeData[0], ['otherProps', 'extRender', 'b_handling_f']);
            }
            if (b_spot_fee) {
              _.set(feeData[0], ['otherProps', 'extRender', 'b_spot_fee'], ({ type: cellType }) => (
                <PriceSnapshotIcon className={`input-icon ${cellType}-priceSnapshot`} data={priceSnapshot.b_spot_fee} />
              ));
            } else {
              _.unset(feeData[0], ['otherProps', 'extRender', 'b_spot_fee']);
            }
            if (b_trans_tax) {
              _.set(feeData[0], ['otherProps', 'extRender', 'b_trans_tax'], ({ type: cellType }) => (
                <PriceSnapshotIcon
                  className={`input-icon ${cellType}-priceSnapshot`}
                  data={priceSnapshot.b_trans_tax}
                />
              ));
            } else {
              _.unset(feeData[0], ['otherProps', 'extRender', 'b_trans_tax']);
            }
            if (b_upstairs_f) {
              _.set(feeData[0], ['otherProps', 'extRender', 'b_upstairs_f'], ({ type: cellType }) => (
                <PriceSnapshotIcon
                  className={`input-icon ${cellType}-priceSnapshot`}
                  data={priceSnapshot.b_upstairs_f}
                />
              ));
            } else {
              _.unset(feeData[0], ['otherProps', 'extRender', 'b_upstairs_f']);
            }
            if (b_yf_trans_f) {
              _.set(feeData[0], ['otherProps', 'extRender', 'b_yf_trans_f'], ({ type: cellType }) => (
                <PriceSnapshotIcon
                  className={`input-icon ${cellType}-priceSnapshot`}
                  data={priceSnapshot.b_yf_trans_f}
                />
              ));
            } else {
              _.unset(feeData[0], ['otherProps', 'extRender', 'b_yf_trans_f']);
            }
            picker.setState({
              feeInfo: {
                ...feeInfo,
                data: feeData,
              },
            });
            setTimeout(() => {
              if (Object.keys(result.truck_pt).length) {
                this.handleFeeTableBlur(picker.state.feeInfo, 0, Object.keys(result.truck_pt)[0]);
              }
            }, 50);
          }, 120);
        } else if (type === 'pickup_pt') {
          const b_pickup_f = +result.pickup_pt.b_pickup_f;
          if (b_pickup_f) {
            priceSnapshot.b_pickup_f = res.res.process_snapshot[0].pickup_pt.b_pickup_f;
            newData = newData.setIn(['b_pickup_f', 'otherProps', 'inputProps', 'defaultValue'], b_pickup_f || '');
          }
          newData = newData.setIn(
            ['b_pickup_f', 'otherProps', 'spanContent'],
            b_pickup_f ? <PriceSnapshotIcon data={priceSnapshot.b_pickup_f} /> : undefined,
          );
          picker.cardForm.setData(newData.toJS());
        } else if (type === 'delivery_pt') {
          const b_delivery_f = +result.delivery_pt.b_delivery_f;
          if (b_delivery_f) {
            priceSnapshot.b_delivery_f = res.res.process_snapshot[0].delivery_pt.b_delivery_f;
            newData = newData.setIn(['b_delivery_f', 'otherProps', 'inputProps', 'defaultValue'], b_delivery_f || '');
          }
          newData = newData.setIn(
            ['b_delivery_f', 'otherProps', 'spanContent'],
            b_delivery_f ? <PriceSnapshotIcon data={priceSnapshot.b_delivery_f} /> : undefined,
          );
          picker.cardForm.setData(newData.toJS());
        }
        this.priceSnapshot = priceSnapshot;
      }
    });
  };

  getCalcPickUp = (cardRef, cardData) => {
    const pickfRL = cardData ? getComVal(cardData.b_pickup_f) : cardRef.getComVal('b_pickup_f');
    const pickfRatioRL = cardData ? getComVal(cardData.pickup_f_ratio) : cardRef.getComVal('pickup_f_ratio');
    const cardRes = +(+pickfRL * +pickfRatioRL).toFixed(2) || '';
    return cardRes;
  };

  getPickUpTotal = (list, cardRef) => {
    const listPickupFee = this.getRightTotal();
    // const listPickupFee = list.reduce((all, item) => +item.pickup_f ? +(all + +item.pickup_f).toFixed(2) : all, 0)
    const calcPickupFee = this.getCalcPickUp(cardRef);
    const pickupFee = listPickupFee || calcPickupFee || '';
    return pickupFee;
  };

  getRightTotal = (key = 'pickup_f') => {
    const pickCntRef = this.context.getPick() || {};
    const biPickTable = pickCntRef.biPickOrder || {};
    const totalRow = biPickTable.getTotalData('right') || {};
    return totalRow[key] || '';
  };

  tableSwitch = (newRightList, cardRef, isEdit = true) => {
    const cardData = cardRef.getStateData();
    const cardPickupObj = cardData.pickup_f_total ? getCcombinderValue(cardData.pickup_f_total.otherProps.data) : {};
    const cardPickup = cardPickupObj ? cardPickupObj.pickup_f_total : '';
    const listSum = this.getRightTotal();
    // const listSum = newRightList.reduce((all, item) => +item.pickup_f ? +(all + +item.pickup_f).toFixed(2) : all, 0)
    const calcPickupFee = this.getCalcPickUp(cardRef);

    this.setNewCard(cardData, (+listSum && +listSum !== +cardPickup) || +calcPickupFee !== +cardPickup);
  };

  handleLeftToRightCallback = (changedList, newLeftList, newRightList, header, cardRef, isEdit) => {
    if (this.state.type !== 'show') {
      // 左右数据变更，更新单票提货费合计 start
      if (['pickup_reservation_batch', 'pickup_batch'].includes(this.props.realTab)) {
        this.tableSwitch(newRightList, cardRef, this.state.type !== 'show' && isEdit);
      }
      // 左右数据变更，更新单票提货费合计 end

      if (this.state.type !== 'show' && this.getWeightVolume) {
        const res = this.getWeightVolume(changedList);
        this.resetRateValue && this.resetRateValue(res, 1, 1);
      }
      if (!this.hasCallHandleLeftToRight) {
        this.hasCallHandleLeftToRight = true;
      } else {
        this.calcBatchPrice();
      }
    }
  };

  handleRightToLeftCallback = (changeList, newLeftList, newRightList, header, cardRef) => {
    // 左右数据变更，更新单票提货费合计 start
    if (['pickup_reservation_batch', 'pickup_batch'].includes(this.props.realTab)) {
      this.tableSwitch(newRightList, cardRef);
    }
    // 左右数据变更，更新单票提货费合计 end
    if (this.getWeightVolume) {
      // const pickOrder = this.context.getPick()
      // const rightTable = (pickOrder.biPickOrder ? pickOrder.biPickOrder.getTableData('right') : []) || []
      const res = this.getWeightVolume(newRightList || []);
      this.resetRateValue(res, 0, 1);
    }
    this.calcBatchPrice();
  };
  tableDataFormate = resList => {
    // 只有批次详情进行格式化
    if (this.state.type !== 'show') return resList;
    if (_.get(window, 'company_setting.weight_unit.value') !== 'T') return resList;
    const newList = resList || {};
    const rightList = resList.right || {};
    const newRightListData = [];
    const keyList = ['b_tr_load_w', 'b_shuttle_load_w', 'b_delivery_load_w', 'b_pickup_load_w'];
    // 提货批次详情不转换单位
    if (
      _.get(window, 'company_setting.weight_unit.value') === 'T' &&
      this.props.realTab !== 'pickup_reservation_batch'
    ) {
      rightList.data.forEach(item => {
        const newVal = {};
        keyList.forEach(key => item[key] && (newVal[key] = parseFloat(parseFloat(item[key] / 1000).toFixed(6))));
        newRightListData.push({ ...item, ...newVal });
      });
      // 处理第一遍total中的信息
      keyList.forEach(
        key =>
          newList.right.total[key] &&
          (newList.right.total[key] = parseFloat(parseFloat(newList.right.total[key] / 1000).toFixed(6))),
      );
      // 处理header中信息
      keyList.forEach(key => _.set(newList.right.header, `${key}.format.division`, 1));
      newList.right.data = newRightListData;
    }
    return newList;
  };

  /* 提货批次代码， end, 期间请入插入其他逻辑代码 与/pages/PickupController/load/index.js 代码重复 */

  /*
   * 批次详情
   */
  renderBiPickOrder = () => {
    const { renderBiPickOrder } = this.context;
    return (
      <div className={`${prefixCls}__page ${prefixCls}_${this.props.pageTab}`} ref={ref => (this.wrap = ref)}>
        <div style={{ height: this.state.showSaveBtn ? 'calc(100% - 26px)' : '100%' }}>
          {renderBiPickOrder(this, this.slider)}
        </div>
        {this.state.showSaveBtn && (
          <div className="slide__drager__footer__content fee-rec-btn">
            <Button type="primary" onClick={this.saveRecordCheckFee}>
              保存
            </Button>
            <Button onClick={this.cancelRecordCheckFee}>取消</Button>
          </div>
        )}
      </div>
    );
  };
  /*
   * 运输合同
   */
  renderContract = () => {
    const basicId = this.props.basicId || '1';
    const goodsInfo = this.props.goodsInfo || {};
    const { batchId } = this.state;
    return <Contract basicId={basicId} batchId={batchId} goodsInfo={goodsInfo} close={this.closeContent} />;
  };
  /*
   * 日志跟踪
   */
  renderLog = () => {
    const basicId = this.props.basicId || '1';
    const { batchId } = this.state;
    const pageConfig =
      this.props.listPage && this.props.listPage.getTableInfo && this.props.listPage.getTableInfo().pageConfig;
    const tableInfo = (pageConfig && pageConfig.tableInfo) || {};
    console.log('log table info', tableInfo, this.props);
    if (isEmptyObj(tableInfo)) {
      // 中转批次
      tableInfo.category = 'Batch';
      tableInfo.tab = this.props.type;
    }
    if (tableInfo.category === 'Settle' && tableInfo.tab === 'detail') {
      // 资金流水明细
      tableInfo.category = 'Batch';
      tableInfo.tab = this.props.batchLogType;
    }
    return <BatchLog basicId={basicId} batchId={batchId} tableInfo={tableInfo} />;
  };
  renderFinanceLog = () => {
    const basicId = this.props.basicId || '1';
    return <FinanceLog basicId={basicId} />;
  };
  renderReceiptLog = () => {
    console.log('renderReceiptLog', this.props);
    const { comId } = this.props;
    const basicId = this.props.basicId || '1';
    const bLinkId = this.props.batchId;
    const { transtaskFlag } = this.props;
    const { receiptAuditSt } = this.props;
    return (
      <ReceiptLog
        basicId={basicId}
        comId={comId}
        bLinkId={bLinkId}
        transtaskFlag={transtaskFlag}
        receiptAuditSt={receiptAuditSt}
        from="batch"
      />
    );
  };
  renderAbnormalLog = () => {
    const basicId = this.props.basicId || '1';
    return <AbnormalLog odBasicId={basicId} isAutoContentHeight typeAbn="batch" />;
  };
  /**
   * 平台车辆跟踪
   */
  renderCarTrack = () => {
    const basicId = this.props.basicId || '1';
    return <CarTrack basicId={basicId} />;
  };
  /**
   * 平台车辆日志
   */
  renderCarLog = () => {
    const basicId = this.props.basicId || '1';
    return <CarLog basicId={basicId} />;
  };
  tabChangeFunc = activeKey => {
    this.setState({
      selectTab: activeKey,
    });
    setTimeout(() => {
      if (+activeKey === 1) {
        this.context.getPick().handleResize();
      }
    }, 0);
  };
  inArray(array, string) {
    return string && array.join(' ').indexOf(string) > -1;
  }
  isHasLog = () => {
    const batchType = this.props.type;
    const notHasArray = [
      'b_point_trans_out', // 内部中转转出 网点中转批次
      'b_inner_trans_out', // 内部中转转出 员工中转批次

      'b_point_trans_in', // 内部中转转入  网点中转批次
      'b_inner_trans_in', // 内部中转转入  员工中转批次

      'b_trans_out', // 外部中转转出 中转批次
      'b_trans_in', // 外部中转转入  中转批次
    ];
    return this.inArray(notHasArray, batchType);
  };
  renderTabs = () => {
    const { batchId } = this.state;
    const { TabPane } = Tabs;
    let hasContract = false; // 此处判断的是(合同tab)
    let title = '批次详情';
    const pickOrderInfo = this.props.pickOrderInfo.toJS() || {};
    const batchInfo = pickOrderInfo.batch_info || {};
    const viewSettleRecord = batchInfo.view_settle_record || '';
    const packTab = this.props.realTab === 'pack_default';
    const batchOrdevBtn = this.props.buttonKey === 'shuttle_batch' || this.props.buttonKey === 'delivery_batch';
    if (
      this.props.realTab === 'tr_up' ||
      this.props.realTab === 'tr_down' ||
      this.props.realTab === 'pre_tr_up' ||
      this.props.realTab === 'pre_tr_down' ||
      (packTab && batchOrdevBtn)
    ) {
      title = '装车清单';
      hasContract = true;
    }
    if (packTab && !batchOrdevBtn) {
      title = '打包单';
    }
    const hasFlatCar = +batchInfo.truck_team_source > 0;
    return (
      <Tabs defaultActiveKey="1" onChange={this.tabChangeFunc}>
        <TabPane tab={title} key="1">
          {batchId !== undefined && this.renderBiPickOrder()}
        </TabPane>
        {!this.isHasLog() && (
          <TabPane tab="日志跟踪" key="2">
            {batchId !== undefined && this.renderLog()}
          </TabPane>
        )}
        {(!packTab || (packTab && batchOrdevBtn)) && (
          <TabPane tab="车辆轨迹" key="track_log">
            <TrackLog
              truckInfo={this.state.pickOrderInfo.truck_info}
              opFrom="batch"
              number={this.props.batchNo}
              batchID={this.props.basicId}
            />
          </TabPane>
        )}
        {viewSettleRecord === 'true' && (
          <TabPane tab="财务记录" key="4">
            {batchId !== undefined && this.renderFinanceLog()}
          </TabPane>
        )}
        {hasContract && (
          <TabPane tab="运输合同" key="3">
            {batchId !== undefined && this.renderContract()}
          </TabPane>
        )}
        {hasFlatCar && (
          <TabPane tab="平台车辆跟踪" key="5">
            {batchId !== undefined && this.renderCarTrack()}
          </TabPane>
        )}
        {hasFlatCar && (
          <TabPane tab="平台车辆日志" key="6">
            {batchId !== undefined && this.renderCarLog()}
          </TabPane>
        )}
        <TabPane tab="回单跟踪" key="8">
          {batchId !== undefined && this.renderReceiptLog()}
        </TabPane>
        <TabPane tab="异常记录" key="7">
          {batchId !== undefined && this.renderAbnormalLog()}
        </TabPane>
      </Tabs>
    );
  };
  renderSliderContent = () => {
    const hasTab = this.props.hasTab || false;
    return (
      <div
        className={classnames({
          [`${prefixCls}__content`]: true,
          [`${prefixCls}__content_hasTab`]: hasTab,
        })}
      >
        {hasTab && this.renderTabs()}
        {!hasTab && this.renderBiPickOrder()}
      </div>
    );
  };
  togglePopWidth = state => {
    this.isBiggest = true;
    this.props.togglePopWidth(
      state,
      setTimeout(() => {
        this.context.getPick().handleResize();
      }, 200),
    );
  };

  render() {
    const slideWid = 1000;
    return (
      <div ref={r => (this.sliderDiv = r)}>
        <SlideDrager
          isShow
          isHasBiggest
          isHasDrager
          isBiggest={this.state.type !== 'show' || this.isBiggest}
          slideWidth={slideWid}
          autoDestroy
          HeaderTitle={`${this.props.batchNo}`}
          contentDiv={this.renderSliderContent()}
          close={this.props.close}
          dragerContainer={this.props.popContainer}
          togglePopWidth={this.togglePopWidth}
          ref={r => (this.slider = r)}
        />
      </div>
    );
  }
}

function getBatchDetail() {
  const { key } = getPageKey().pageConfig;
  return BiFormateData(key, BindAction(key, payee(BatchDetail)));
}
export default getBatchDetail;
export { BatchDetail };
