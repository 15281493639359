import { Table, Column, Cell } from 'fixed-data-table-2';
import { Icon, Label } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

const headerStyle = { textAlign: 'center', lineHeight: '30px' };
const bodyCellStyle = { padding: '0 4px', lineHeight: '28px' };
const indexCellStyle = { textAlign: 'center', lineHeight: '28px' };
const iconStyle = { marginRight: 4 };
class SimpleTable extends React.Component {
  static propTypes = {
    data: PropTypes.array,
    header: PropTypes.object,
    className: PropTypes.string,
    width: PropTypes.number,
    enumerations: PropTypes.object,
    bodyCellStyle: PropTypes.object,
    indexCellStyle: PropTypes.object,
  };
  renderCell = (colProps, rowIndex, key) => {
    const { rowHeight = 28, statusSpecify } = this.props;
    const rowData = this.props.data[rowIndex];
    const propIndexStyle = this.props.indexCellStyle || {};
    const iCellStyle = { ...indexCellStyle, ...propIndexStyle };
    // 序号列
    if (key === 'index') {
      return (
        <Cell key={`${key}-${rowIndex}`} {...colProps} style={iCellStyle}>
          {rowIndex + 1}
        </Cell>
      );
    }
    const { header, enumerations = {} } = this.props;
    // const style = bodyCellStyle

    const propBodyStyle = this.props.bodyCellStyle || {};
    const style = { ...bodyCellStyle, ...propBodyStyle };
    // 状态列
    if (key === 'status') {
      const cellTip = _.get(rowData.otherProps, 'tooltips.status');
      const ellipsisCls = cellTip && !statusSpecify ? 'ellipsis-wrap' : '';
      const ellipsisCss = cellTip && !statusSpecify ? { maxHeight: rowHeight } : {};
      const iconTip = statusSpecify ? '' : cellTip;
      return (
        <Cell key={`${key}-${rowIndex}`} {...colProps} style={style}>
          <Icon classname="batch-sts-icon" iconType={rowData.status_icon} style={iconStyle} tips={iconTip} />
          <Label
            tips={cellTip}
            classname={`${_.get(rowData.otherProps, 'status_text.otherProps.classname')} sts-label-wrap`}
          >
            <span className={ellipsisCls} style={ellipsisCss}>
              {statusSpecify ? rowData.status_text : cellTip || rowData.status_text}
              {/* cellTip && (<Icon classname="batch-sts-tip-icon" iconType="icon-help" />) */}
            </span>
          </Label>
        </Cell>
      );
    }
    // 其他列
    let showText = rowData[key];
    const enums = enumerations[header[key].refEnum || key];
    if (showText !== undefined && enums && enums.find) {
      const { uniqueKey = 'key', showKey = 'name' } = header[key];
      const theEnum = enums.find(item => item[uniqueKey] == showText); // eslint-disable-line
      showText = (theEnum && theEnum[showKey]) || showText;
    }
    return (
      <Cell key={`${key}-${rowIndex}`} {...colProps} style={style} title={showText}>
        {showText}
      </Cell>
    );
  };
  render() {
    const { data, header, className, width: tableWidth = 520, rowHeight = 28 } = this.props;
    return (
      <Table
        rowHeight={rowHeight}
        rowsCount={data.length}
        width={tableWidth}
        maxHeight={316}
        headerHeight={30}
        className={className}
        showScrollbarX={false}
        showScrollbarY={data.length > 10}
      >
        {Object.entries(header).map(([key, { title, width = 45, flexGrow }]) => (
          <Column
            header={<Cell style={headerStyle}>{title}</Cell>}
            key={key}
            cell={({ rowIndex, ...props }) => this.renderCell(props, rowIndex, key)}
            width={width}
            flexGrow={Number.isNaN(flexGrow) ? 0 : flexGrow}
          />
        ))}
      </Table>
    );
  }
}
export default SimpleTable;
