exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".image-form-wrapper .gui-upload-list-item-picture-mask,.image-form-wrapper .gui-upload-list-type-picture-card .gui-upload-list-item{transition:none}.image-form-wrapper .image-form-item{margin-bottom:4px}.image-form-wrapper .image-form-item .gui-form-label-item>label{color:#000}.image-form-wrapper .image-form-item.required .gui-form-label-item>label{color:red}.image-form-wrapper .gui-upload-list-item-picture{width:200px;height:127px;background-color:#8e8b84}.image-form-wrapper .gui-upload-list-item-picture>img{height:100%;width:auto}.image-form-wrapper .gui-upload-list-item-picture-mask{line-height:127px}.image-form-wrapper .gui-upload-disabled .trigger{cursor:not-allowed}.image-form-wrapper .gui-upload-drag{width:auto}.image-form-wrapper .more-pic-font{color:red}.image-form-wrapper .more-pic-link{color:#53cea8;border:1px solid #53cea8;background:#e2fff2;font-weight:700;padding:2px 4px;border-radius:4px;cursor:pointer;display:inline-block;margin-left:10px}.gui-modal-wrapper{z-index:10030}", ""]);

// exports
exports.locals = {
	"prefixCls": "image-form-wrapper",
	"image-form-wrapper": "image-form-wrapper",
	"gui-upload-list-type-picture-card": "gui-upload-list-type-picture-card",
	"gui-upload-list-item": "gui-upload-list-item",
	"gui-upload-list-item-picture-mask": "gui-upload-list-item-picture-mask",
	"image-form-item": "image-form-item",
	"gui-form-label-item": "gui-form-label-item",
	"required": "required",
	"gui-upload-list-item-picture": "gui-upload-list-item-picture",
	"gui-upload-disabled": "gui-upload-disabled",
	"trigger": "trigger",
	"gui-upload-drag": "gui-upload-drag",
	"more-pic-font": "more-pic-font",
	"more-pic-link": "more-pic-link",
	"gui-modal-wrapper": "gui-modal-wrapper"
};