exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".abnormal-payinfo .total-price{color:#f37100;font-weight:700}.abnormal-payinfo table{font-size:12px;color:#333}.abnormal-payinfo table input{text-align:center}.abnormal-payinfo table .operate{width:40px}.abnormal-payinfo table .operate span{color:#0097cf;cursor:pointer}.abnormal-payinfo table .order{width:39px}.abnormal-payinfo table th{background:#ecf0f5}.abnormal-payinfo table th .fn-icon-help{color:#3c8bdc;font-weight:400;margin-top:-1px;margin-left:5px}.abnormal-payinfo table th.operate,.abnormal-payinfo table th.order{width:39px}.abnormal-payinfo table td,.abnormal-payinfo table th{height:28px;text-align:center;border:1px solid #b9ccd7;padding:1px;width:94px}.abnormal-payinfo table td .fn-input-pure-wrap,.abnormal-payinfo table th .fn-input-pure-wrap{height:26px;width:100%;border:1px solid #d1d8e1;border-radius:2px}.abnormal-payinfo table td .fn-input-pure-wrap input,.abnormal-payinfo table th .fn-input-pure-wrap input{border:none!important;height:22px}.abnormal-payinfo table td.required,.abnormal-payinfo table th.required{color:red}", ""]);

// exports
exports.locals = {
	"prefixCls": "abnormal-payinfo",
	"abnormal-payinfo": "abnormal-payinfo",
	"total-price": "total-price",
	"operate": "operate",
	"order": "order",
	"fn-icon-help": "fn-icon-help",
	"fn-input-pure-wrap": "fn-input-pure-wrap",
	"required": "required"
};