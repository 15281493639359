import React from 'react';

import { Button, PureInput as Input } from 'components';

import { prefixCls } from './index.scss';
import PropTypes from 'prop-types';
import { showInfo, postJAVA } from 'utils';
import { ERROR } from 'constants';

class EnterpriseCertification extends React.Component {
  static propTypes = {
    certName: PropTypes.string,
    certNo: PropTypes.string,
  };
  static defaultProps = {};
  constructor(props) {
    super(props);
    this.state = {
      certification: 0, // 0未认证 1已认证 2去认证
      companyName: '', // 公司名称
      creditCode: '', // 信用代码
      certifiedUrl: '', // E签宝认证地址
    };
  }
  componentDidMount() {
    this.setState({
      companyName: this.props.certName,
      creditCode: this.props.certNo,
    });
  }
  companyNameChange = e => {
    this.setState({
      companyName: e.target.value,
    });
  };
  creditCodeChange = e => {
    this.setState({
      creditCode: e.target.value,
    });
  };
  close = () => {
    this.props.close();
  };
  confirmCertification = async () => {
    const cert_name = this.state.companyName.trim();
    const cert_no = this.state.creditCode.trim();
    if (cert_name && cert_no) {
      const req = { cert_name, cert_no };
      const url = '/cmm-batch/electronic-contract/save-license';
      try {
        const res = await postJAVA(url, req);
        if (res.errno === 0) {
          if (res.res?.authenticated) {
            this.setState({
              certification: 1,
            });
          } else {
            this.setState({
              certification: 2,
              certifiedUrl: res.res?.authentic_url,
            });
          }
        } else {
          showInfo(ERROR, res.errmsg);
        }
      } catch (error) {
        showInfo(ERROR, '网络错误');
      }
    }
  };
  toCertification = () => {
    window.open(this.state.certifiedUrl, '_blank');
  };
  renderHeader() {
    return (
      <div className="certification-header">
        <div className="header-title">企业认证</div>
        <div className="header-desc">
          企业需要在E签宝完成企业认证,与司机签署的运输合同将自动企业盖章,合同才具有法律效益。
        </div>
      </div>
    );
  }
  renderContent() {
    return (
      <div className="certification-content">
        {this.state.certification === 0 && (
          <div>
            <div className="certification-content-title">以下认证流程请企业的财务人员或法人进行操作。</div>
            <Input
              placeholder="请输入营业执照上的企业名称"
              value={this.state.companyName}
              onChange={this.companyNameChange}
              className="certification-content-input"
              required
            />
            <Input
              placeholder="请输入企业的社会信用代码"
              value={this.state.creditCode}
              onChange={this.creditCodeChange}
              className="certification-content-input"
              required
            />
            <p className="certification-content-desc">*请仔细核对企业名称和信用代码</p>
          </div>
        )}
        {this.state.certification === 1 && (
          <div className="certification-content-title">检测到已完成企业认证,可以正常使用线上电子合同。</div>
        )}
        {this.state.certification === 2 && (
          <div className="certification-content-title">
            以下认证流程请企业的财务人员或法人进行操作。
            <br />
            请点击【去认证】完成企业认证。
          </div>
        )}
      </div>
    );
  }
  renderFooter() {
    return (
      <div className="certification-footer">
        {/* 未认证 */}
        {this.state.certification === 0 && (
          <div>
            <Button onClick={this.close} type="default">
              取消
            </Button>
            <Button onClick={this.confirmCertification} type="primary">
              确认
            </Button>
          </div>
        )}
        {/* 已认证 */}
        {this.state.certification === 1 && (
          <div>
            <Button onClick={this.close} type="primary">
              关闭
            </Button>
          </div>
        )}
        {/* 去认证 */}
        {this.state.certification === 2 && (
          <div>
            <Button onClick={this.close} type="default">
              取消
            </Button>
            <Button onClick={this.toCertification} type="primary">
              去认证
            </Button>
          </div>
        )}
      </div>
    );
  }

  render() {
    return (
      <div className={`${prefixCls}-content`}>
        {this.renderHeader()}
        {this.renderContent()}
        {this.renderFooter()}
      </div>
    );
  }
}

export default EnterpriseCertification;
