exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".request-payment{display:flex;height:100%;overflow:hidden;flex-direction:column;padding:0 10px 10px}.request-payment__list{flex:1 1 100%;margin-top:6px;overflow-y:scroll}.request-payment__list .list-tip{font-size:14px;margin-bottom:10px}.request-payment__list .list-tip .blue{color:#136af1;padding:3px}.request-payment__list .list-tip .orange{color:#f86e21;padding:3px}.request-payment__list .bold{font-weight:600;padding:3px}.request-payment__list .table-wrap{height:58px;margin:0 0 10px 20px}.request-payment .right_in,.request-payment .right_in .in-bk .item__1{height:100%;display:flex;justify-content:center;align-items:center}.request-payment .right_in .in-bk .item__1{margin-top:1px}", ""]);

// exports
exports.locals = {
	"prefixCls": "request-payment",
	"request-payment": "request-payment",
	"request-payment__list": "request-payment__list",
	"list-tip": "list-tip",
	"blue": "blue",
	"orange": "orange",
	"bold": "bold",
	"table-wrap": "table-wrap",
	"right_in": "right_in",
	"in-bk": "in-bk",
	"item__1": "item__1"
};