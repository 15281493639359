exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".audit-one-by-one__remark{margin-top:15px}.audit-one-by-one__remark .fn-label{margin-bottom:5px;display:inline-block}.audit-one-by-one__remark .remark{width:100%;height:60px}", ""]);

// exports
exports.locals = {
	"prefixCls": "audit-one-by-one",
	"audit-one-by-one__remark": "audit-one-by-one__remark",
	"fn-label": "fn-label",
	"remark": "remark"
};