import { Amap, PopUp } from 'components';
import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { handleAPIResponse, postJAVA, bem, showInfo, confirm } from 'utils';
import { ERROR, WARN } from 'constants';
import { Spin, Button, Tooltip, Tabs } from '@gui/web-react';
import moment from 'moment';
import IconStdAdd from 'images/icon-std-add.svg';
import { prefixCls } from './index.scss';
import StdLine from '../StdLine';
import DefaultStdLineSwitch from './components/DefaultStdLineSwitch';

const { TabPane } = Tabs;
const EDIT = 'edit';
const VIEW = 'view';
const CREATE = 'create';

function formateLineTime(seconds) {
  if (!seconds || typeof seconds !== 'number') {
    return '';
  }
  const duration = moment.duration(seconds, 'seconds');

  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();

  let result = '';

  if (days > 0) {
    result += `${days}天`;
  }

  if (hours > 0) {
    result += `${hours}小时`;
  }

  if (minutes > 0) {
    result += `${minutes}分钟`;
  }

  // 处理边界情况：如果总时长小于1分钟
  if (result === '') {
    result = '少于1分钟';
  }

  return result;
}
function RenderButton({ loadError, fetchStdLine }) {
  const cls = bem(prefixCls);
  return !loadError ? (
    <div className={cls('add')} />
  ) : (
    <div className={cls('add')}>
      <Button
        type="primary"
        onClick={() => {
          fetchStdLine();
        }}
      >
        <i className="fn-icon fn-icon-file-add" />
        请求标准线路失败，请重试
      </Button>
    </div>
  );
}

function RenderMapButton({ curStdRoute, openStdLineDialog }) {
  const cls = bem(prefixCls);
  if (!curStdRoute) {
    return null;
  }
  if (curStdRoute.id) {
    // 存在标准线路
    return null;
  }
  // 不存在标准线路
  return (
    <Button
      className={cls('map__btn')}
      onClick={() => {
        openStdLineDialog({ opType: CREATE, orderNumber: curStdRoute.orderNumber });
      }}
      icon={<IconStdAdd />}
      type="primary"
    >
      创建标准线路
    </Button>
  );
}

function RenderOperateButton({ activeStdRoute, stdLineData, openStdLineDialog, deleStdLine }) {
  // 如果是占位，不展示任何按钮
  if (!activeStdRoute?.id) {
    return null;
  }
  const cls = bem(prefixCls);
  return (
    <>
      <Button
        className={cls('btn__right__btn')}
        type="primary"
        onClick={() => {
          openStdLineDialog({ opType: EDIT, stdRouteId: activeStdRoute.id });
        }}
      >
        修改线路
      </Button>
      <Button
        className={cls('btn__right__btn')}
        type="outline"
        onClick={() => {
          openStdLineDialog({ opType: VIEW, stdRouteId: activeStdRoute.id, orderNumber: activeStdRoute.orderNumber });
        }}
      >
        查看线路
      </Button>
      {stdLineData.filter(item => item.id).length > 1 ? (
        <Button className={cls('btn__right__btn')} type="outline" onClick={deleStdLine}>
          删除线路
        </Button>
      ) : null}
    </>
  );
}

const RouteCard = forwardRef(({ lineId, opType, lineNodes, onClose }, ref) => {
  const cls = bem(prefixCls);
  const [isLoading, setLoading] = useState(true);
  const [stdLineData, setStdLineData] = useState(null);
  const [loadDataError, setLoadDataError] = useState(false);
  const [mapInstance, setMapInstance] = useState(null);
  const [activeTabID, setActiveTabID] = useState(null);
  const [activeStdRoute, setActiveStdRoute] = useState(null);

  const markers = useRef([]);
  const polylines = useRef([]);

  useImperativeHandle(ref, () => ({
    refresh: orderNumber => {
      fetchStdLine(orderNumber);
    },
  }));
  const fetchStdLine = focusOrderNumber => {
    setLoading(true);
    handleAPIResponse(postJAVA('/cmm-basic/line/std_route/query_by_line_id', { line_id: lineId }))
      .then(res => {
        setLoadDataError(false);
        let stdRoutes = res?.std_routes || [];
        // 如果std_routes的长度小于3，补全到3，且要根据order_number的值升序补充，比如接口返回1和3，那么要补充2
        // 当前选中tab是否存在标准线路，通过是否存在id来判断
        // 为了方便后面tab默认选中默认标准线路，增加tabID作为tab的key
        // arco的tabPane的key会默认变成字符串，所以这里用字符串拼接
        stdRoutes = [1, 2, 3].map(orderNumber => {
          const orderNumberStd = stdRoutes.find(item => item.order_number === orderNumber);
          if (orderNumberStd) {
            return {
              ...orderNumberStd,
              tabTitle: `线路${orderNumber}`,
              tabID: `${orderNumberStd.id}`,
              orderNumber,
            };
          } else {
            return {
              tabTitle: `线路${orderNumber}`,
              tabID: `empty-${orderNumber}`,
              orderNumber,
            };
          }
        });
        stdRoutes.sort((a, b) => a.order_num - b.order_num);
        // 默认选中第一个tab
        setActiveTabID(stdRoutes[0].tabID);
        setActiveStdRoute(stdRoutes[0]);
        // 如果存在默认标准线路, title增加默认标识
        if (res?.default_std_route_id) {
          const defaultIndex = stdRoutes.findIndex(item => item.id === res.default_std_route_id);
          if (defaultIndex > -1) {
            stdRoutes[defaultIndex].tabTitle += '（默认）';
            stdRoutes[defaultIndex].isDefault = true;
          }
          // 如果是保存返回这种场景，需要默认显示上一次的tab，否则，默认显示默认tab
          if (focusOrderNumber) {
            setActiveTabID(stdRoutes[focusOrderNumber - 1].tabID);
            setActiveStdRoute(stdRoutes[focusOrderNumber - 1]);
          } else {
            setActiveTabID(stdRoutes[defaultIndex].tabID);
            setActiveStdRoute(stdRoutes[defaultIndex]);
          }
        }
        setStdLineData(stdRoutes);
      })
      .catch(() => {
        setLoadDataError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchStdLine();
    return () => {
      // 组件销毁的时候需要销毁地图
      if (mapInstance) {
        mapInstance?.clearMap();
        mapInstance?.destroy();
        setMapInstance(null);
      }
    };
  }, []);

  useEffect(() => {
    // 画地图
    if (mapInstance && stdLineData?.length) {
      drawRouteLine();
    }
  }, [mapInstance, stdLineData]);

  const onDefaultStdLinechange = () => {
    fetchStdLine();
  };
  /**
   * 绘制单个marker
   * @param {*} pointData { lng, lat}
   * @returns marker实例
   */
  const drawMarker = (pointData = {}, config) => {
    if (!pointData) {
      return;
    }
    const {
      iconUrl = 'https://static.g7cdn.com/fe-cdn/bill-crose.png',
      size = 32,
      // 层级高德官方默认12
      zIndex = 12,
    } = config || {};
    const point = new window.AMap.LngLat(pointData.lng, pointData.lat);
    const marker = new window.AMap.Marker({
      position: point,
      icon: new window.AMap.Icon({
        image: iconUrl,
        size: [size, size],
        imageSize: [size, size],
      }),
      // angle: pointData.direction,
      offset: new window.AMap.Pixel(-(size / 2), -(size / 2)),
      zIndex,
    });
    mapInstance.add(marker);
    return marker;
  };

  // 在地图上绘制车线，起点，途经点
  const drawRouteLine = () => {
    // 先清除
    mapInstance?.clearMap();
    // 画线
    stdLineData.forEach(item => {
      const { points } = item || {};
      if (!points) {
        return;
      }
      const pointsArr = points.map(({ lng, lat }) => new window.AMap.LngLat(lng, lat));
      const defaultPolylinOptions = {
        strokeColor: '#86909C',
        strokeWeight: 5,
        showDir: false,
      };
      if (activeStdRoute?.id === item.id) {
        defaultPolylinOptions.strokeColor = '#23CF3B';
        defaultPolylinOptions.showDir = true;
        defaultPolylinOptions.zIndex = 56;
      }
      const crossLineOverlay = new window.AMap.Polyline({
        path: pointsArr,
        ...defaultPolylinOptions,
      });
      item.polyline = crossLineOverlay;
      polylines.current.push(crossLineOverlay);
      mapInstance.add(crossLineOverlay);
    });

    // 因为只画车线节点，所以取一个就可以了
    const map_nodes = stdLineData?.[0]?.map_nodes || [];
    // 起始点和途经点
    if (Array.isArray(map_nodes)) {
      for (let i = 0; i < map_nodes.length; i++) {
        // 只画车线节点
        if (map_nodes[i].is_line_node) {
          let iconUrl = 'https://static.g7cdn.com/fe-cdn/waybill/img/bill-crose.png';
          if (i === 0) {
            iconUrl = 'https://static.g7cdn.com/fe-cdn/waybill/img/bill-put.png';
          } else if (i === map_nodes.length - 1) {
            iconUrl = 'https://static.g7cdn.com/fe-cdn/waybill/img/bill-lay-down.png';
          }
          const marker = drawMarker(map_nodes[i], {
            iconUrl,
            // 轨迹上的层级放低，要不然会高于marker
            zIndex: 1,
          });
          markers.current.push(marker);
        }
      }
    }
    // 全部画完后视野缩放
    mapInstance.setFitView();
  };

  const toggleMapOverlay = stdRoute => {
    if (!stdRoute.id) {
      // 没有标准线路，隐藏所有marker和line，默认视图定位到北京
      markers.current.forEach(marker => marker.hide());
      polylines.current.forEach(line => line.hide());
      mapInstance.setZoomAndCenter(10, [116.397, 39.908]);
    } else {
      markers.current.forEach(marker => marker.show());
      polylines.current.forEach(line => {
        line.setOptions({ strokeColor: '#86909C', showDir: false, zIndex: 50 });
        line.show();
      });
      // 高亮当前标准线路的polyline
      stdRoute.polyline?.setOptions({ strokeColor: '#23CF3B', showDir: true, zIndex: 56 });
      mapInstance.setFitView();
    }
  };

  const onMapLoaded = map => {
    // 需求要求禁止缩放地图
    map.setStatus({ scrollWheel: false });
    setMapInstance(map);
  };

  const openStdLineDialog = ({ opType: innerOpType, stdRouteId: innerStdRouteId, orderNumber }) => {
    if (lineNodes?.filter(item => +item.node_type !== 7).length > 0) {
      showInfo(ERROR, '节点类型请选择高德地址');
      return;
    }
    if (lineNodes?.length > 16) {
      showInfo(ERROR, '车线节点超过16个无法创建标准线路');
      return;
    }
    let dialog = null;
    const onCloseStdLineDialog = ({ type }) => {
      dialog?.close();
      onClose({ type, props: { orderNumber: activeStdRoute.orderNumber } });
    };
    const props = {
      lineId,
      onClose: onCloseStdLineDialog,
      opType: innerOpType,
    };
    if (innerOpType === CREATE || innerOpType === VIEW) {
      props.orderNumber = orderNumber;
    }
    if (innerStdRouteId) {
      props.stdRouteId = innerStdRouteId;
    }
    dialog = new PopUp(<StdLine {...props} />, {});
    dialog.show();
  };

  const deleStdLine = () => {
    confirm(
      WARN,
      '删除后不可恢复，选择此标准线路的运单不可进行线路导航。',
      { confirm: '确定', cancel: '取消' },
      '操作确认',
    ).then(isConfirm => {
      if (isConfirm) {
        setLoading(true);
        handleAPIResponse(
          postJAVA('/cmm-basic/line/std_route/delete', { line_id: lineId, std_route_id: activeStdRoute.id }),
        )
          .then(res => {
            if (res) {
              fetchStdLine();
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  };

  const infoList = [
    {
      label: '标准线路名称：',
      key: 'name',
    },
    {
      label: '线路里程：',
      key: 'total_distance',
      extKey: ['highway_distance', 'navi_estimate_time'],
      render(mileage, highwayDistance, naviEstimateTime) {
        const parts = [];
        const timeTxt = formateLineTime(naviEstimateTime);
        if (timeTxt) {
          parts.push(`约${timeTxt}`);
        }
        if (mileage) {
          let distanceText = `${(mileage / 1000).toFixed(2)}公里`;
          if (highwayDistance) {
            distanceText += `（高速${(highwayDistance / 1000).toFixed(2)}公里）`;
          }
          parts.push(distanceText);
        }
        return parts.join(' | ');
      },
    },
    {
      label: '途径路段：',
      key: 'path',
      extKey: ['full_path'],
      render(path, fullPath) {
        const completePath = fullPath || path;
        return (
          <Tooltip position="top" content={completePath}>
            <span className={cls('path')}>{path}</span>
          </Tooltip>
        );
      },
    },
    {
      label: '途径风险点：',
      key: 'risk_points_statistics',
      render(riskPointsStatistics) {
        if (!riskPointsStatistics || !riskPointsStatistics.length) {
          return '';
        }
        // 根据riskPointsStatistics每一项的source_type来统计风险点数量，1为大数据风险点，2为自定义风险点
        const riskPointsCount = riskPointsStatistics.reduce(
          (acc, cur) => {
            if (cur.source_type === 1) {
              acc.bigDataRiskPoints += cur.count;
            } else if (cur.source_type === 2) {
              acc.customRiskPoints += cur.count;
            }
            return acc;
          },
          {
            bigDataRiskPoints: 0,
            customRiskPoints: 0,
          },
        );
        return `高风险${riskPointsCount.bigDataRiskPoints || 0}个，自定义${riskPointsCount.customRiskPoints || 0}个`;
      },
    },
    {
      label: '电子路书：',
      key: 'name',
      render() {
        // 暂时全部置空
        return '';
      },
    },
  ];

  const setActiveTab = tabID => {
    // 通过tabID查找对应的标准线路，并设置activeStdRoute
    const stdRoute = stdLineData.find(item => item.tabID === tabID);
    setActiveStdRoute(stdRoute);
    toggleMapOverlay(stdRoute);
    // 修改当前active的标准线路id
    setActiveTabID(tabID);
  };
  return (
    <Spin loading={isLoading} block>
      <div className={cls('wrap')}>
        {!stdLineData ? (
          <RenderButton loadError={loadDataError} fetchStdLine={fetchStdLine} />
        ) : (
          <div className={cls('view')}>
            <div className={cls('info')}>
              <Tabs activeTab={activeTabID} type="card" onChange={setActiveTab}>
                {stdLineData.map(item => (
                  <TabPane title={item.tabTitle} key={item.tabID}>
                    <ul className={cls('list')}>
                      {infoList.map(infoItem =>
                        infoItem.render ? (
                          <li className={cls('item')}>
                            <div className={cls('label')}>{infoItem.label}</div>
                            <div className={cls('value')}>
                              {infoItem.render(
                                item[infoItem.key],
                                ...(infoItem.extKey ? infoItem.extKey.map(info => item[info]) : []),
                              )}
                            </div>
                          </li>
                        ) : (
                          <li className={cls('item')}>
                            <div className={cls('label')}>{infoItem.label}</div>
                            <div className={cls('value')}>{item[infoItem.key]}</div>
                          </li>
                        ),
                      )}
                    </ul>
                  </TabPane>
                ))}
              </Tabs>
              <div className={cls('btn')}>
                <div className={cls('btn__left')}>
                  {
                    // 如果是占位，不展示设为默认的switch
                    activeStdRoute?.id ? (
                      <DefaultStdLineSwitch
                        lineId={lineId}
                        stdRouteId={activeStdRoute.id}
                        onDefaultStdLinechange={onDefaultStdLinechange}
                        initialSwitchState={activeStdRoute.isDefault}
                        disabled={activeStdRoute.isDefault}
                      />
                    ) : null
                  }
                </div>
                <div className={cls('btn__right')}>
                  <RenderOperateButton
                    deleStdLine={deleStdLine}
                    activeStdRoute={activeStdRoute}
                    stdLineData={stdLineData}
                    openStdLineDialog={openStdLineDialog}
                  />
                </div>
              </div>
            </div>
            <div className={cls('map')}>
              <Amap.MapShow width="100%" height="100%" onMapLoaded={onMapLoaded} />
              <RenderMapButton curStdRoute={activeStdRoute} openStdLineDialog={openStdLineDialog} />
            </div>
          </div>
        )}
      </div>
    </Spin>
  );
});

export default RouteCard;
