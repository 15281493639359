exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".retry-content{max-width:440px;padding:17px 5px 0 12px}.retry-content .notice{position:absolute;top:-10px;left:61px;font-size:16px;line-height:26px;color:#000}.retry-content .notice .little-notice{font-size:12px;line-height:15px;margin-top:3px}.retry-content .checkinfo-detail{margin-top:20px}.retry-content .detail-content{position:relative;margin:0 0 4px 35px}.retry-content .detail-content .fn-icon{color:#0097cf;margin-left:10px;margin-bottom:2px}.retry-content .detail-content .fn-icon:hover{color:#378cbe}.retry-content .detail-content em{position:absolute;left:-10px;top:0}.retry-content .checkinfo-list{display:inline-block;overflow:hidden;color:#888;max-width:320px}.retry-content .notice_title{color:#888}.retry-content .notice_list{color:#888;line-height:22px}.retry-content .fn-icon-arrow-db{position:absolute;top:26px;right:0}.retry-content .height_0{height:18px}.retry-content .height_1{display:inline}", ""]);

// exports
exports.locals = {
	"retry-content": "retry-content",
	"notice": "notice",
	"little-notice": "little-notice",
	"checkinfo-detail": "checkinfo-detail",
	"detail-content": "detail-content",
	"fn-icon": "fn-icon",
	"checkinfo-list": "checkinfo-list",
	"notice_title": "notice_title",
	"notice_list": "notice_list",
	"fn-icon-arrow-db": "fn-icon-arrow-db",
	"height_0": "height_0",
	"height_1": "height_1"
};