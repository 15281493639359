import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ModalDialog, Table, Select, Label, Radio, Button, PopUp, Icon } from 'components';
import {
  animateClass,
  arraySum,
  createTip,
  fetchApi,
  filterArrayPropByKey,
  getErrorDetail,
  alert,
  mathHelper,
} from 'utils';
import { ERROR, WARN } from 'constants';
import { fadeOut } from 'constants/animation';
import { defaultI18n } from '@/utils/i18n/context';
import { prefixCls } from './index.scss';
import splitConfig from './config';

const { accAdd, accSub, accMul, accDiv } = mathHelper;
export default class SplitOrderDialog extends PureComponent {
  constructor(prop) {
    super(prop);
    // 当前拆单信息位于splitData中的index值
    this.splitDataIndex = 0;
    const splitInfo = prop.splitInfo || {};
    const oldSplitInfo = JSON.parse(JSON.stringify(splitInfo.curr_info));
    // 是否设置为吨
    this.isUnitT =
      window.company_setting.weight_unit &&
      window.company_setting.weight_unit.value === 'T' &&
      prop.category !== 'Reservation';
    // 此项为新增时候已经点过拆单，再次点击进来
    const { currSplitInfo } = prop;
    const stockGoods = JSON.parse(JSON.stringify(splitInfo.stock_goods));
    const splitTypePara = this.getSplitTypePara(currSplitInfo, prop.splitInfo);
    // 是否在已指定主子单
    this.hasZZD = splitInfo.has_zzd;
    this.setDefaultZZD = +splitInfo.default_zzd;
    const splitData = this.dealSplitInfo(oldSplitInfo, currSplitInfo, stockGoods);
    // 是否忽略主子单必填（因其他拆单方式已有主子单，但是还没结束）
    this.otherHasZZD = +splitInfo.other_has_zzd && !oldSplitInfo.some(x => +x.is_zzd === 1);
    // 不计算percent
    this.unCalcPercent =
      (window.company_setting.split_order_fee &&
        window.company_setting.split_order_fee.splitValue &&
        window.company_setting.split_order_fee.splitValue === 'spec') ||
      false;
    const orderDict = prop.i18nDict.get('order', '运单');
    const batchDict = prop.i18nDict.get('batch', '批次');
    this.state = {
      // 是否为loading状态
      isLoading: false,
      // 所有的拆单信息
      splitData,
      // 本次拆单信息
      // eslint-disable-next-line react/no-unused-state
      currSplitInfo,
      // 曾经的拆单信息
      // eslint-disable-next-line react/no-unused-state
      oldSplitInfo,
      // 库存数量
      stockGoods,
      // 货物信息
      goods: (prop.splitInfo || {}).goods,
      // 分摊方式是否可改
      ...splitTypePara,
    };
    this.disableActualPrice = prop.disableActualPrice || false;
    // 是否发生修改
    this.isEdit = false;
    // 弹框最小宽
    this.minWidth = '590px';
    // 是否为点击确认状态
    this.isLoading = false;
    // 是否为多货(判断goods的g_name数组的len)
    this.isMultyGoods = ((this.state.goods || {}).g_name || []).length > 1;

    this.splitHeader = this.initHeaderType(splitConfig(prop).splitHeader || {}, (prop.splitInfo || {}).goods);
    // 单票货物的情况下， 二值相等， 多票aveTypeListDialog包含单条货物值不为0的
    this.aveTypeList = [];
    this.aveTypeListDialog = [];
    this.splitSetting = prop.setting || {};
    //
    this.splitTypeList = this.disableSplitTypeList(splitConfig(prop).splitType);
    // 不显示分摊合计运费
    if (this.disableActualPrice) {
      delete splitConfig(prop).orderInfo.g_actual_price;
    }
    this.orderTips = prop.orderTips === '订单号' ? '订单' : orderDict;
    this.iconTips = `分摊方式指以下费用项在拆单${orderDict}上的分摊逻辑：<br />
            运费、件数、重量、体积、套数、中转费用项、开单费用项、<br />
            ${batchDict}送货费、${batchDict}提货费、${batchDict}短驳费及发车${batchDict}费用项等`;
    if (prop.orderTips === '订单号') {
      this.iconTips = `分摊方式指以下费用项在拆单订单上的分摊逻辑：<br />
            运费、提货费、送货费、手续费、保价费`;
    }
  }
  static defaultProps = {
    // 是否为修改
    isModify: false,
    isFromSign: false,
    orderTips: '运单号',
    i18nDict: defaultI18n,
  };
  static propTypes = {
    // 当前tab_key
    tab: PropTypes.string,
    // 当前运单号
    orderNum: PropTypes.string,
    // 订单号或者运单号
    orderTips: PropTypes.string,
    // 当前运单或者订单id
    orderId: PropTypes.string,
    // 当前运单信息
    orderInfo: PropTypes.object,
    // 当前的拆单信息
    splitInfo: PropTypes.object,
    // 是否为修改
    isModify: PropTypes.bool,
    // 确认拆单的回调
    confirmSplitCallBack: PropTypes.func,
    // 关闭的回调
    close: PropTypes.func,
    // 是否为签收分摊
    isFromSign: PropTypes.bool,
    // 提示文案(可提货 可签收 数量)
    tipText: PropTypes.string,
    // 拆单的批次信息
    batchId: PropTypes.string,
    // 拆单设置
    setting: PropTypes.object,
    i18nDict: PropTypes.object,
  };
  UNSAFE_componentWillMount = () => {};
  UNSAFE_componentWillReceiveProps = () => {};
  componentDidMount = () => {};
  componentDidUpdate = () => {};
  getKey = x => `g_${x}`;
  getSplitKey = x => {
    const { i18nDict } = this.props;
    if (+x === 4) return ['actual_price'];
    return splitConfig(this.props).mapCol[x];
  };
  initHeaderType = (header, goods) => {
    let newHeader = JSON.parse(JSON.stringify(header));
    const { splitType } = this.state;
    const { i18nDict } = this.props;
    // 重量验证6位小数
    if (this.isUnitT) {
      newHeader.weight.validate = ['positiveFloatSix'];
    }
    if (!this.props.isFromSign) {
      newHeader = { ...newHeader, ...splitConfig(this.props).splitHeaderUnSign };
    }
    if (this.disableActualPrice) {
      delete newHeader.actual_price;
    }
    // 不计算费用分摊比例
    if (this.unCalcPercent) {
      newHeader = { ...newHeader, ...splitConfig(this.props).splitHeaderPercent };
    }
    const columnType = this.isMultyGoods ? 'Link' : 'Input';
    let col = splitConfig(this.props).mapCol[+splitType] || [];

    if (this.disableActualPrice) {
      col = col.filter(x => x !== 'actual_price');
    }
    // 干掉分摊合计运费
    col = col.filter(x => !goods[this.getKey(x)].some(gItem => +gItem === 0));
    col.forEach(key => {
      key === 'actual_price' && (newHeader[key].type = 'Input');
      key !== 'actual_price' && (newHeader[key].type = columnType);
    });
    return newHeader;
  };
  // 运单的对应的总量、体积、件数、套数为0，不能选择此项
  disableSplitTypeList = splitTypeList => {
    const { i18nDict } = this.props;
    let aveType = splitConfig(this.props).aveType || [];
    this.disableActualPrice && (aveType = aveType.filter(x => +x.key !== 4));
    const { goods } = this.state;
    const newSplitList = [];
    splitTypeList.forEach(item => {
      // 多货的情况下，只要有一个为0，此项即不可为拆单方式
      if (+item.value !== 4 && goods[item.key].some(gItem => +gItem === 0)) {
        newSplitList.push({
          ...item,
          disabled: true,
          tips: `${splitConfig(this.props).orderInfo[item.key]}为0不能作为拆单方式`,
        });
        if (arraySum(goods[item.key]) !== 0) {
          const mapType = aveType.filter(x => +x.key === +item.value);
          mapType.length && this.aveTypeListDialog.push(mapType[0]);
        }
      } else {
        newSplitList.push(item);
        const mapType = aveType.filter(x => +x.key === +item.value);
        if (mapType.length) {
          // 过滤掉分摊合计运费为0的情况下，拆单方式中按分摊合计运费分摊选项
          const { mapKey } = mapType[0];
          if (mapKey !== 'actual_price' || !goods[this.getKey(mapKey)].some(gItem => +gItem === 0)) {
            this.aveTypeList.push(mapType[0]) && this.aveTypeListDialog.push(mapType[0]);
          }
        }
      }
    });
    return newSplitList;
  };
  // 获取默认的分摊方式, 优先获取splitInfo中的分摊方式,
  getSplitTypePara = (currSplitInfo = {}, splitInfo) => {
    const { i18nDict } = this.props;
    let canSpTypeEdit = false;
    let canSubSpTypeEdit = true;
    // 存在原来的拆单数据的， 不可以修改
    if (+splitInfo.sub_sp_type || +splitInfo.sp_type) {
      canSpTypeEdit = true;
      canSubSpTypeEdit = true;
    }
    // 分摊方式
    const subSplitValue = +currSplitInfo.sub_sp_type || +splitInfo.sub_sp_type || splitConfig(this.props).aveDefault;
    // 拆单方式
    const splitType = +currSplitInfo.sp_type || +splitInfo.sp_type || splitConfig(this.props).splitDefault;
    // 拆单方式为自定义时， 分摊方式可编辑
    if (currSplitInfo.sp_type && +currSplitInfo.sp_type === 4) {
      canSubSpTypeEdit = false;
    }
    return {
      canSpTypeEdit,
      canSubSpTypeEdit,
      subSplitValue,
      splitType,
    };
  };
  /**
   * @DateTime 2017-11-10
   * @desc:    {[默认未拆单的数量给第二条数据]}
   * @return   {[type]}        [description]
   */
  dealSplitInfo = (oldSplitInfo, currSplitInfo, stock) => {
    const { i18nDict } = this.props;
    let splitData = oldSplitInfo;
    const stockGoods = JSON.parse(JSON.stringify(stock));
    let hasCurSplitInfo = false;
    const newStock = stock;
    const zzds = [];
    splitData = splitData.map((item, index) => {
      const x = item;
      if (+x.percent === 1) {
        x.percentType = true;
      }
      // 非当前可批次的拆单信息可以编辑
      if (+x.b_basic_id !== +this.props.batchId) {
        Object.assign(x, { otherProps: Object.assign(x.otherProps || {}, { disable: Object.keys(x) }) });
        x.otherProps.disable.push('is_zzd');
      } else {
        // Object.assign(x, x.g_info)
        // 有当前条数据，不可修改的情况
        if (+x.state === 40) {
          Object.assign(x, { otherProps: Object.assign(x.otherProps || {}, { disable: Object.keys(x) }) });
          x.otherProps.disable.push('is_zzd');
        } else {
          // 可以编辑的情况下， 需要将当前条的的拆单信息更新stock信息
          const mapKeyList = splitConfig(this.props).mapCol['4'];
          mapKeyList.forEach(key => {
            newStock[this.getKey(key)] = stock[this.getKey(key)].map((val, ind) =>
              accAdd(+val, +x[this.getKey(key)]?.[ind] || 0),
            );
          });
          // 可以修改，并且已经点开过一次
          currSplitInfo && Object.assign(x, currSplitInfo);
        }
        hasCurSplitInfo = true;
        this.splitDataIndex = index;
      }
      x.is_zzd = +x.is_zzd === 1 ? 1 : 0;
      if (this.hasZZD) {
        x.otherProps = x.otherProps || {};
        x.otherProps.disable = x.otherProps.disable || [];
        x.otherProps.disable.push('is_zzd');
      }
      if (x.is_zzd) {
        zzds.push(index);
      }
      return x;
    });
    if (zzds.length > 1) {
      const zzdDisables = zzds.filter(
        i =>
          splitData[i].otherProps &&
          splitData[i].otherProps.disable &&
          splitData[i].otherProps.disable.includes('is_zzd'),
      );
      if (zzdDisables.length + 1 === zzds.length) {
        zzdDisables.forEach(i => {
          splitData[i].is_zzd = 0;
        });
      }
    }
    // 新增,第二次点开拆单，显示上一次的拆单信息
    if (!hasCurSplitInfo && currSplitInfo) {
      this.splitDataIndex = splitData.length;
      return [...splitData, currSplitInfo];
    }
    // 当是第一此拆单且未在其他操作中指定过主子单时设置第一条为默认主子单
    if (!this.hasZZD && this.setDefaultZZD) {
      stockGoods.is_zzd = 1;
      if (this.unCalcPercent) {
        stockGoods.percent = 1;
      }
    } else {
      stockGoods.is_zzd = 0;
    }
    if (this.hasZZD) {
      stockGoods.otherProps = stockGoods.otherProps || {};
      stockGoods.otherProps.disable = stockGoods.otherProps.disable || [];
      stockGoods.otherProps.disable.push('is_zzd');
    }
    // 没有本次拆单信息的，用剩余库存 最后一条数据为本次拆单数据，不能删除
    if (!hasCurSplitInfo) {
      const splitHeader = splitConfig(this.props).splitHeader || {};
      Object.keys(splitHeader).forEach(
        x =>
          stockGoods[this.getKey(x)] &&
          x !== 'name' &&
          (stockGoods[x] = this.formate(arraySum(stockGoods[this.getKey(x)]), x)),
      );
      stockGoods.otherProps = stockGoods.otherProps || {};
      stockGoods.otherProps.disable = stockGoods.otherProps.disable || [];
      stockGoods.otherProps.disable.push('substract');
      this.splitDataIndex = splitData.length;
      return [...splitData, stockGoods];
    }
    return splitData;
  };
  isEdited = () => this.isEdit || false;
  /**
   * @DateTime 2017-10-17
   * @desc:    {[弹框关闭操作]}
   * @return   {[type]}        [description]
   */
  handleDialogClose = () => {
    if (typeof this.props.close === 'function') {
      animateClass(this.dialogWrap, fadeOut, () => this.props.close());
    } else {
      animateClass(this.dialogWrap, fadeOut);
    }
  };
  /**
   * @DateTime 2017-10-17
   * @desc:    {[拆单确认按钮]}
   * @return   {[type]}        [description]
   */
  confirmSplit = () => {
    const { i18nDict } = this.props;
    let splitData = {};
    let checkSplitType = false;
    let tableData = [];
    let hasZu = false;
    if (this.splitTable) {
      const checkRes = this.splitTable.getTableData(true);
      if (checkRes.isPass === false) {
        createTip(`${checkRes.columnName || ''}${checkRes.msg || ''}`, ERROR).show();
        return;
      }
      tableData = checkRes.data;
      this.unCalcPercent &&
        tableData.forEach(item => {
          checkSplitType = checkSplitType || item.percentType;
        });
      splitData = tableData[this.splitDataIndex];
      hasZu = this.hasZZD || tableData.some(item => +item.is_zzd);
    }
    if (+splitData.num === 0 && +splitData.weight === 0 && +splitData.volume === 0 && +splitData?.suit === 0) {
      createTip('件数/重量/体积/套数需至少有一个不为0！', ERROR).show();
      return;
    }
    const checkInfo = this.checkSplit(splitData);
    if (!checkInfo.isPass) {
      createTip('当前的拆单数量会导致库存件数/重量/体积/套数/运费小于0！', ERROR).show();
      return;
    }
    // 如果是自定义拆单， 分摊方式项拆单全部拆完，其他项也需要拆完
    if (+this.state.splitType === 4) {
      const seleKey = this.getSplitKey(this.state.subSplitValue, i18nDict)[0];
      const key = this.getKey(seleKey);
      if (+arraySum(splitData[key]) === +arraySum(this.state.stockGoods[key])) {
        let pass = false;
        let leftTips = '';
        splitConfig(this.props).mapCol[4].forEach(item => {
          const a = +this.formate(arraySum(splitData[this.getKey(item)]), item);
          const b = +this.formate(arraySum(this.state.stockGoods[this.getKey(item)]), item);
          pass = pass || a !== b;
          item !== seleKey[0] &&
            (leftTips =
              leftTips === ''
                ? splitConfig(this.props).orderInfo[this.getKey(item)]
                : `${leftTips}、${splitConfig(this.props).orderInfo[this.getKey(item)]}`);
        });
        if (pass) {
          createTip(
            `您本次处理了全部剩余${splitConfig(this.props).orderInfo[key]}，${leftTips}也需填写全部剩余数量！`,
            ERROR,
          ).show();
          return;
        }
      }
    }
    // 全部分摊完，并且是费用分摊到一个运单上的设置并且没有单子勾选费用分摊， 提示， 并返回
    if (this.unCalcPercent && checkInfo.isCompelte && !checkSplitType && !hasZu && !this.otherHasZZD) {
      // createTip(`请您选择将${this.orderTips}费用全部分摊到哪个子单上！`, ERROR).show()
      createTip('请选择主子单！', ERROR).show();
      return;
    }
    if (checkInfo.isCompelte && !hasZu && !this.otherHasZZD) {
      createTip('请选择主子单！', ERROR).show();
      return;
    }
    // if (!checkInfo.isCompelte && tableData && tableData.length === splitConfig(this.props).maxSplitTime) {
    //   createTip(
    //     `每次业务操作最多可以拆单${splitConfig(this.props).maxSplitTimeCh}次，第${
    //       splitConfig(this.props).maxSplitTimeCh
    //     }次请您操作全部数量！`,
    //     ERROR,
    //   ).show();
    //   return;
    // }
    if (!checkInfo.isCompelte && (this.splitSetting || {}).has_settle) {
      const detail = getErrorDetail(
        `当前${this.orderTips}已经全部拆单完成并且进行了账户扣款，无法修改为部分拆单！请先进行相应的逆向操作取消账户扣款后再修改拆单信息。`,
      );
      alert(ERROR, `当前${this.orderTips}无法修改拆单信息！`, undefined, detail);
      return;
    }
    const { stockGoods } = this.state;
    const actualPrice = +arraySum(stockGoods.g_actual_price);
    // 分摊方式为分摊合计运费分摊
    if (+this.state.subSplitValue === 4) {
      if (!+splitData.actual_price) {
        createTip('您选择了按分摊合计运费分摊，子单分摊合计运费不能为0或空！', ERROR).show();
        return;
      }
      if (actualPrice > 0 && +splitData.actual_price < 0) {
        createTip(`${this.orderTips}分摊合计运费为正数，子单分摊合计运费需填写最多两位小数的正数！`, ERROR).show();
        return;
      }
      if (actualPrice < 0 && +splitData.actual_price > 0) {
        createTip(`${this.orderTips}分摊合计运费为负数，子单分摊合计运费需填写最多两位小数的负数！`, ERROR).show();
        return;
      }
    } else {
      const seleKey = this.getSplitKey(this.state.subSplitValue)[0];
      const key = this.getKey(seleKey);
      if (+splitData[seleKey] === 0) {
        createTip(
          `您选择了按${splitConfig(this.props).orderInfo[key]}分摊，子单${
            splitConfig(this.props).orderInfo[key]
          }不能为0或空！`,
          ERROR,
        ).show();
        return;
      }
      if (actualPrice < 0 && +splitData.actual_price > 0) {
        createTip(
          `${this.orderTips}分摊合计运费为负数，子单分摊合计运费需填写最多两位小数的负数、0或空！`,
          ERROR,
        ).show();
        return;
      }
      if (actualPrice > 0 && +splitData.actual_price < 0) {
        createTip(
          `${this.orderTips}分摊合计运费为正数，子单分摊合计运费需填写最多两位小数的正数、0或空！`,
          ERROR,
        ).show();
        return;
      }
    }
    // percent默认值为0
    this.unCalcPercent && (splitData.percent = splitData.percent || 0);
    if (!this.unCalcPercent && splitData.percent === undefined) {
      splitData.percent = this.resetPercent(this.state.subSplitValue, false, false);
    }
    // 确认拆单的回调
    this.setState({ isLoading: true });
    this.props.confirmSplitCallBack &&
      this.props.confirmSplitCallBack({
        currSplitInfo: splitData,
        aveType: this.state.subSplitValue,
        splitType: this.state.splitType,
      });
    this.handleDialogClose();
  };
  formate = (value, x) => {
    // 重量为吨的情况下， 重量分摊，需要保留6位小数
    if (x === 'weight' && this.isUnitT) {
      return parseFloat((parseFloat(value) || 0).toFixed(6));
    }
    // 件数只能为整
    if (x === 'num') return Math.floor(value);
    if (x === 'volume') return parseFloat((parseFloat(value) || 0).toFixed(6));
    if (x === 'suit') return parseFloat((parseFloat(value) || 0).toFixed(3));
    if (x === 'actual_price') return parseFloat((parseFloat(value) || 0).toFixed(2));
    return parseFloat((parseFloat(value) || 0).toFixed(3));
  };
  // 重量体积件数的分摊
  calcGoodsPercent = (col, colList, item) => {
    const newItem = item;
    const colKey = this.getKey(col);
    const { stockGoods } = this.state;
    const stockGoodsCol = stockGoods[colKey];
    const { goods } = this.state;
    const goodsCol = goods[colKey];
    const { i18nDict } = this.props;
    const leftKey = splitConfig(this.props).mapCol['4'].filter(x => x !== col && x !== 'actual_price');
    let splitAll = 0;
    colList.forEach((value, index) => {
      // 后台返回的值的精度不正确
      if (+this.formate(stockGoodsCol[index], col) === +value) {
        // 此处为了保证完全拆完
        leftKey.forEach(x => (newItem[this.getKey(x)][index] = stockGoods[this.getKey(x)][index]));
        splitAll++;
      } else {
        // 按照剩余的数据计算
        const percent = value / stockGoodsCol[index];
        leftKey.forEach(x => {
          const splitValue = stockGoods[this.getKey(x)][index] * percent;
          newItem[this.getKey(x)][index] = this.formate(splitValue, x);
        });
      }
    });
    // 多条货物均拆完, 保证拆完
    // if (splitAll === colList.length) {
    //   newItem.g_actual_price = stockGoods.g_actual_price
    // } else {
    //   // 不按单条货物计算，按照合计计算
    //   newItem.g_actual_price = [this.formate((+arraySum(colList) / +arraySum(goods[this.getKey(col)])) * arraySum(stockGoods.g_actual_price), 'actual_price')]
    //   newItem.actual_price = newItem.g_actual_price[0]
    // }
    leftKey.forEach(x => {
      newItem[x] = this.formate(arraySum(newItem[this.getKey(x)]), x);
    });
    return newItem;
  };
  /**
   * @DateTime 2017-11-29
   * @desc:    {[拆单校验， 拆单数量要小于stockGoods数量]}
   * @return   {[type]}        [description]
   */
  checkSplit = currSplit => {
    const { i18nDict } = this.props;
    const { stockGoods } = this.state;
    let isPass = true;
    let isCompelte = true;
    let allZero = true;
    const checkKeyList = splitConfig(this.props).mapCol[4] || [];
    let tips = '';
    for (let iter = 0; iter < checkKeyList.length; iter++) {
      const key = this.getKey(checkKeyList[iter]);
      let checkRes = true;
      if (this.unCalcPercent && key === 'g_actual_price') {
        continue;
      }
      const splitValue = arraySum(currSplit[key]);
      const stockValue = this.formate(arraySum(stockGoods[key]), checkKeyList[iter]);
      // 分摊合计运费不每一项进行校验
      key !== 'g_actual_price' &&
        currSplit[key].forEach(
          (x, index) => (checkRes = checkRes && +x <= +this.formate(stockGoods[key][index], checkKeyList[iter])),
        );
      isCompelte = isCompelte && +splitValue === +stockValue;
      allZero = allZero && +splitValue === 0;
      if (Math.abs(+splitValue) > Math.abs(+stockValue) || !checkRes) {
        isPass = false;
        tips = splitConfig(this.props).orderInfo[key];
        break;
      }
    }
    return { isPass, tips, isCompelte, allZero };
  };
  /**
   * @DateTime 2017-11-14
   * @desc:    {[货物拆单弹框回调]}
   * @return   {[type]}        [description]
   */
  confirmGoodsSplit = (column, rowIndex) => {
    const { i18nDict } = this.props;
    let data = [];
    const splitData = JSON.parse(JSON.stringify(this.state.splitData)) || [];
    const { goods } = this.state;
    if (!this.goodsTable) return;
    if (this.goodsTable) {
      const checkRes = this.goodsTable.getTableData(true);
      if (checkRes.isPass === false) {
        createTip(`${checkRes.columnName || ''}${checkRes.msg || ''}`, ERROR).show();
        return;
      }
      data = checkRes.data;
    }
    const { splitType } = this.state;
    const showCol = splitConfig(this.props).mapCol[+splitType];
    // 非自定义拆单的情况下， 需要根据当前的分摊方式，重新计算剩余的字段的值
    if (+splitType !== 4) {
      const col = showCol[0];
      const getItemCol = filterArrayPropByKey(data, this.getKey(col));
      splitData[rowIndex][col] = arraySum(getItemCol);
      splitData[rowIndex][this.getKey(col)] = getItemCol;
      // 多货的情况下， 重量体积的分摊和percent不一致
      if (!this.unCalcPercent) {
        const percent = splitData[rowIndex][col] / arraySum(goods[this.getKey(col)]);
        splitData[rowIndex].percent = percent;
        splitData[rowIndex] = this.getActualPrice(col, splitData[rowIndex][col], percent, splitData[rowIndex]);
      }
      splitData[rowIndex] = this.calcGoodsPercent(col, getItemCol, splitData[rowIndex]);
      // 自定义拆单方式下， 货物弹框中填写的数量即为拆单弹框中的件数, 此时不更新分摊合计运费
    } else {
      showCol.forEach(item => {
        if (item === 'actual_price') return;
        const getItemCol = filterArrayPropByKey(data, this.getKey(item));
        splitData[rowIndex][item] = arraySum(getItemCol);
        splitData[rowIndex][this.getKey(item)] = getItemCol;
      });
      // 多货弹框情况下更新percent
      if (!this.unCalcPercent) {
        const { subSplitValue } = this.state;
        const col = splitConfig(this.props).mapCol[subSplitValue][0];
        const percent = splitData[rowIndex][col] / arraySum(goods[this.getKey(col)]);
        splitData[rowIndex].percent = percent;
        splitData[rowIndex] = this.getActualPrice(col, splitData[rowIndex][col], percent, splitData[rowIndex]);
      }
    }
    const checkInfo = this.checkSplit(splitData[rowIndex]);
    if (!checkInfo.isPass) {
      createTip('当前的拆单数量会导致库存件数/重量/体积/套数小于0！', ERROR).show();
      return;
    }
    if (checkInfo.allZero) {
      let allZeroStr = splitConfig(this.props).orderInfo[this.getKey(showCol[0])];
      showCol.length !== 0 &&
        showCol.forEach((x, index) => {
          index !== 0 && (allZeroStr = `${allZeroStr}/${splitConfig(this.props).orderInfo[this.getKey(x)]}`);
        });
      createTip(`请您填写拆单的${allZeroStr}！`, ERROR).show();
      return;
    }
    this.setState({ splitData });
    this.splitTable && this.splitTable.resetData(splitData);
    this.goodsDialogRef && this.goodsDialogRef.handleHide();
  };
  /**
   * @DateTime 2023-11-07
   * @desc:    {[10757 拆单配载优化 ]}
   * @return   {[type]}        [description]
   */
  // 10757-清空当前行数据
  handleGoodsLinkClickOperate = (rowIndex, column, data) => {
    // 预留扩展
    if (column === 'g_operation') {
      const newData = { ...data };
      const excludeKeys = ['g_operation', 'g_name', 'reference'];
      Object.keys(newData).forEach(key => {
        if (key.startsWith('g_') && !excludeKeys.includes(key)) {
          newData[key] = '0';
        }
      });
      this.goodsTable?.updateCacheRowWrap(rowIndex, newData); // 清空操作更新数据
      this.goodsTable.forceUpdate(); // 由于弹窗多次嵌套且弹窗内table不是以组件形式维护，故需要使用原始forceUpdate方法更新渲染
    }
  };
  // 10757-处理goods数据，货物模拟列表数据重组
  handleGoodsDataFormat = () => {
    const { goods } = this.props.splitInfo || {};
    const { stockGoods } = this.state;
    const { i18nDict } = this.props;
    // 10757 拆单配载优化 货物模拟列表数据重组
    const stockPrice = stockGoods?.g_actual_price[0]; // goods总量
    const shareTotalPrice = goods?.g_actual_price[0]; // goods库存量
    const labels = {};
    Object.entries(splitConfig(this.props).orderInfo).forEach(([key, value]) => {
      labels[key] = value;
    });
    // 处理goods数据
    const keys = Object.keys(goods);
    const itemData = [];
    const numEntries = goods?.g_name?.length;
    for (let i = 0; i < numEntries; i++) {
      const children = [];
      const objGoodsSketch = {
        name: labels.g_name,
        value: goods.g_name[i],
      };
      keys.forEach(key => {
        if (['g_name', 'g_actual_price'].includes(key)) return;
        const newObj = {
          value: goods[key][i],
          label: labels[key],
          key_value: key,
          valueResidue: stockGoods[key][i],
          labelResidue: labels[key] && `${this.props.tipText}${labels[key]}`,
        };
        children.push(newObj);
      });
      objGoodsSketch.children = children;
      itemData.push(objGoodsSketch);
    }
    return { itemData, stockPrice, shareTotalPrice };
  };

  handleGoodsDialogClose = () => this.goodsDialogRef && this.goodsDialogRef.handleHide();
  /**
   * @DateTime 2017-10-20
   * @desc:    {[出货物拆单弹框]}
   * @return   {[type]}        [description]
   */
  handleLinkClick = (rowIndex, column, data) => {
    new PopUp(ModalDialog, {
      isShow: true,
      opType: 'add',
      title: '货物拆单',
      content: this.renderSplitGoodsDialog(data),
      bottom: this.renderSplitGoodsBottom(column, rowIndex),
      dragerContainer: this,
      contentStyle: { width: '700px', height: '400px' },
      ref: r => (this.goodsDialogRef = r),
    }).show();
  };
  /**
   * @DateTime 2017-10-17
   * @desc:    {[表格减号，batch_id不为0的取消(装车)]}
   * @return   {[type]}        [description]
   */
  handleCancelLoad = (rowIndex, data) => {
    const batchId = data.batch_id;
    if (!batchId) {
      createTip('', ERROR).show();
      return;
    }
    const url = '';
    const { splitData } = this.state;
    const req = {};
    // 此处调用取消装车接口
    const param = { method: 'POST', body: { req } };
    fetchApi(url, param)
      .then(res => {
        if (res.errno !== 0) {
          createTip(res.errmsg || '取消装车失败', WARN).show();
          return;
        }
        // 删除当前条数据
        this.setState({ splitData: splitData.filter(x => x !== data) });
      })
      .catch(err => console.log(err));
  };

  /**
   * @DateTime 2017-10-31
   * @desc:    {[分摊方式发生变化]}
   * @return   {[type]}        [description]
   */
  subSplitChange = value => {
    this.setState({ subSplitValue: value });
    if (!this.unCalcPercent) {
      this.resetPercent(value, true, false, +value !== 4);
    }
  };

  // 更新percent值
  resetPercent = (value, resetState = true, resetLeftValue = false, resetActualPrice = false) => {
    const col = this.getSplitKey(value)[0];
    const { goods } = this.state;
    const { splitData } = this.state;
    const tableData = this.splitTable.getStateCache();
    const rowIndex = this.splitDataIndex;
    const colValue = tableData[rowIndex][col];
    const percent = colValue / arraySum(goods[this.getKey(col)]);
    tableData[rowIndex].percent = percent;
    resetLeftValue &&
      (tableData[rowIndex] = this.calcGoodsPercent(col, splitData[rowIndex][this.getKey(col)], splitData[rowIndex]));
    // 按照分摊合计运费分摊的情况下不重新计算分摊合计运费且不更新剩余数量,其余情况需要更新分摊合计运费值
    resetActualPrice &&
      !tableData[rowIndex].actual_price_edit &&
      (tableData[rowIndex] = this.getActualPrice(col, colValue, percent, tableData[rowIndex]));
    resetState && this.setState({ splitData: tableData });
    return percent;
  };
  // 分摊到此单的radio选中状态
  handleSplitOrderChange = (rowIndex, col, value) => {
    if (col !== 'is_zzd') return;
    const tableData = this.splitTable.getStateCache();
    const { goods } = this.state;
    if (this.unCalcPercent) {
      tableData.forEach((item, index) => {
        tableData[index].percent = 0;
        tableData[index].actual_price = 0;
        value && (tableData[index].percentType = false);
        value && (tableData[index].is_zzd = 0);
      });
      tableData[rowIndex].is_zzd = value ? 1 : 0;
      tableData[rowIndex].percent = value ? 1 : 0;
      tableData[rowIndex].percentType = value;
      // 所有的费用分摊到此单
      tableData[rowIndex].actual_price = value ? +arraySum(goods.g_actual_price) : 0;
    } else {
      tableData.forEach((item, index) => {
        value && (tableData[index].is_zzd = 0);
      });
      tableData[rowIndex].is_zzd = value ? 1 : 0;
    }

    this.splitTable && this.splitTable.resetData(tableData);
    this.setState({ splitData: tableData });
  };
  // 每次计算分摊合计运费值
  getActualPrice = (col, value, percent, rowData) => {
    const newRowData = rowData;
    // if (rowData.actual_price_edit) return newRowData

    const { stockGoods } = this.state;
    const { goods } = this.state;
    const isComplete = +arraySum(stockGoods[this.getKey(col)]) === +value;

    if (isComplete) {
      newRowData.actual_price = +arraySum(stockGoods.g_actual_price) || 0;
    } else {
      newRowData.actual_price = parseFloat((arraySum(goods.g_actual_price) || 0) * percent).toFixed(2);
    }
    newRowData.g_actual_price = [newRowData.actual_price];
    return newRowData;
  };
  /**
   * @DateTime 2017-11-09
   * @desc:    {[拆单表格的离开事件, 更新货物中的值]}
   * @return   {[type]}        [description]
   */
  handleSplitTableBlur = (rowIndex, col, value) => {
    const goods = this.state.goods || {};
    const stockGoods = this.state.stockGoods || {};
    const { splitType } = this.state;
    const { subSplitValue } = this.state;
    const splitData = this.state.splitData || [];
    splitData[rowIndex][this.getKey(col)] = [value];
    splitData[rowIndex][col] = value;
    if (col === 'actual_price') {
      splitData[rowIndex].actual_price_edit = true;
    }
    // 按照当前的分摊方式，重新计算剩余的两个值
    if (+splitType !== 4) {
      const percent = +value / +arraySum(goods[this.getKey(col)]);
      if (!this.unCalcPercent) {
        splitData[rowIndex].percent = percent;
        splitData[rowIndex] = this.getActualPrice(col, value, percent, splitData[rowIndex]);
      } else {
        splitData[rowIndex].g_actual_price = splitData[rowIndex].percentType ? stockGoods.g_actual_price : [0];
        splitData[rowIndex].actual_price = arraySum(splitData[rowIndex].g_actual_price);
      }
      splitData[rowIndex] = this.calcGoodsPercent(col, splitData[rowIndex][this.getKey(col)], splitData[rowIndex]);
    } else {
      // 自定义拆单方式的情况下，只有更新的这列的值和分摊方式一致， 才更新percent
      const splitKey = this.getSplitKey(subSplitValue)[0];
      if (splitKey === col && !this.unCalcPercent) {
        const percent = +value / +arraySum(goods[this.getKey(col)]);
        splitData[rowIndex].percent = percent;
        if (col !== 'actual_price' && !splitData[rowIndex].actual_price_edit) {
          splitData[rowIndex] = this.getActualPrice(col, value, percent, splitData[rowIndex]);
        }
      }
    }
    this.setState({ splitData });
    this.splitTable && this.splitTable.resetData(splitData);
  };
  /**
   * @DateTime 2017-10-20
   * @desc:    {[拆单方式发生变化, 对应的输入框更新为LInk或者Input类型,
   * 拆单方式为自定义，分摊方式可以修改]}
   * @return   {[type]}        [description]
   */
  splitTypeChange = value => {
    const { i18nDict } = this.props;
    let col = splitConfig(this.props).mapCol[value];
    const { stockGoods } = this.state;
    const subSplitValue = +value !== 4 ? value : this.state.subSplitValue;
    const newState = { splitType: value, subSplitValue };
    const oldSplitType = this.state.splitType;
    if (+value === 4) {
      newState.canSubSpTypeEdit = false;
      // 自定义情况下，需要过滤掉
      const includeKey = filterArrayPropByKey(this.aveTypeListDialog, 'mapKey');
      col = col.filter(x => includeKey.includes(x));
    } else {
      newState.canSubSpTypeEdit = true;
    }
    this.setState(newState);
    const columnType = this.isMultyGoods ? 'Link' : 'Input';
    this.splitTable && this.splitTable.changeHeaderProps('type', 'Text', splitConfig(this.props).mapCol[4]);
    this.splitTable && this.splitTable.changeHeaderProps('type', columnType, col);
    // 有分摊合计运费且为自定义的情况下，分摊合计运费可以填写
    this.splitTable &&
      arraySum(stockGoods.g_actual_price) &&
      +value === 4 &&
      this.splitTable.changeHeaderProps('type', 'Input', ['actual_price']);
    // 更新分摊方式对应的percent值
    if (!this.unCalcPercent) {
      // 拆单方式由自定义切换为其他类型时，需要更新分摊的value值
      this.resetPercent(subSplitValue, true, +oldSplitType === 4);
    }
  };
  renderSplitGoodsDialog = (splitInfo = {}) => {
    const { splitType } = this.state;
    const { i18nDict } = this.props;
    let showCol = splitConfig(this.props).mapCol[+splitType];
    const header = {
      g_operation: { title: '操作', type: 'Link', display: 'show', flexGrow: 1 },
      g_name: { title: splitConfig(this.props).orderInfo.g_name, type: 'Text', display: 'show', flexGrow: 1 },
    };
    // showCol需要过滤掉为0的
    const includeKey = filterArrayPropByKey(this.aveTypeListDialog, 'mapKey');
    showCol = showCol.filter(x => includeKey.includes(x) && x !== 'actual_price');
    showCol.forEach(item => {
      let validate = ['positiveFloatThree'];
      if (item === 'num') {
        validate = ['Number'];
      } else if (item === 'volume') {
        validate = ['positiveFloatSix'];
      }
      header[this.getKey(item)] = {
        title: splitConfig(this.props).orderInfo[this.getKey(item)],
        type: 'Input',
        display: 'show',
        flexGrow: 1,
        validate,
      };
    });

    const tableData = [];
    const gName = splitInfo.g_name || this.state.stockGoods.g_name || [];
    gName.forEach((x, index) => {
      let tmp = { g_operation: '清空', g_name: x };
      showCol.forEach(item => {
        tmp = { ...tmp, [this.getKey(item)]: splitInfo[this.getKey(item)][index] };
      });
      tableData.push(tmp);
    });
    return (
      <div className={`${prefixCls}__goods_wrap`} ref={d => (this.goodsDialogWrap = d)}>
        <Table
          isAutoSize
          isOrderNumberColumn
          showScrollbarX={false}
          isShowSort={false}
          isShowFilterRow={false}
          isShowTotalRow={false}
          data={tableData}
          header={header}
          handleLinkClick={this.handleGoodsLinkClickOperate}
          refGetter={table => (this.goodsTable = table)}
        />
      </div>
    );
  };
  renderSplitGoodsBottom = (column, rowIndex) => (
    <div className={`${prefixCls}__goods_footer`}>
      <Button
        type="primary"
        loading={this.state.isLoading}
        onClick={(...args) => this.confirmGoodsSplit(column, rowIndex, ...args)}
      >
        确定
      </Button>
      <Button onClick={this.handleGoodsDialogClose} disabled={this.state.isLoading}>
        取消
      </Button>
    </div>
  );
  renderSplitTable = () => {
    const splitHeader = this.splitHeader || {};
    const { splitData } = this.state;
    let showScrollbarY = true;
    splitData.length < 5 && (showScrollbarY = false);
    const { i18nDict } = this.props;
    return (
      <div className={`${prefixCls}__fee_table`} ref={r => (this.splitTableWrap = r)}>
        <Table
          isAutoSize
          showScrollbarX={false}
          showScrollbarY={showScrollbarY}
          isShowSort={false}
          data={splitData}
          header={splitHeader}
          handleBlur={this.handleSplitTableBlur}
          handleTodoSubtract={this.handleCancelLoad}
          handleLinkClick={this.handleLinkClick}
          handleChange={this.handleSplitOrderChange}
          // handleRowClick={this.handleRowClick}
          refGetter={table => (this.splitTable = table)}
          {...splitConfig(this.props).splitTableAttr}
        />
      </div>
    );
  };
  renderAveType = () => {
    const splitType = this.splitTypeList;
    return (
      <div className={`${prefixCls}__content_checkbox`}>
        <div className={`${prefixCls}__split_type`}>
          <Label>拆单方式：</Label>
          <Radio
            data={splitType}
            value={this.state.splitType}
            onChange={this.splitTypeChange}
            disabled={this.state.canSpTypeEdit}
          />
        </div>
        <div className={`${prefixCls}__sub_split`}>
          <Label>分摊方式：</Label>
          <Select
            data={this.aveTypeList}
            value={this.state.subSplitValue}
            onChange={this.subSplitChange}
            filter={false}
            disabled={this.state.canSubSpTypeEdit}
          />
        </div>
        <Icon isHtmlTips iconType="icon-help" tipsProps={{ tipsStyle: { width: '340px' } }} tips={this.iconTips} />
      </div>
    );
  };
  renderDialogContent = () => {
    // stockPrice:goods总量;shareTotalPrice:goods库存量;itemData:货物全量
    const { stockPrice, shareTotalPrice, itemData } = this.handleGoodsDataFormat();

    return (
      <div className={`${prefixCls}__content_goods`} ref={r => (this.splitContent = r)}>
        <div className={`${prefixCls}__content_goods_item`}>
          <div className="goods_item_roll">
            {itemData.map((item, index) => {
              return (
                <div className="item">
                  <div className="item_name">
                    <span className="item_name_index">{index + 1}</span>
                    <span>{item.name}: </span>
                    <b className="item_name_value">{item.value}</b>
                  </div>
                  <div className="item_content">
                    <p>
                      {item.children.map((items, indexAll) => {
                        return (
                          <div className="item_label" key={indexAll}>
                            {items.label && (
                              <>
                                <span className="label_key">{items.label}：</span>
                                <b className="label_value">{items.value}</b>
                              </>
                            )}
                          </div>
                        );
                      })}
                    </p>
                    <p>
                      {item.children.map((items, indexResidue) => {
                        return (
                          <div className="item_label" key={indexResidue}>
                            {items.labelResidue && (
                              <>
                                <span className="label_key">{items.labelResidue}：</span>
                                <b className="value_red label_value">{items.valueResidue}</b>
                              </>
                            )}
                          </div>
                        );
                      })}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="item_footer">
            <div className="item_label">
              <span className="label_key">分摊合计运费:</span>
              <b className="label_value">{shareTotalPrice}</b>
            </div>
            <div className="item_label">
              <span className="label_key">剩余运费:</span>
              <b className="value_red label_value">{stockPrice}</b>
            </div>
          </div>
        </div>

        {this.renderAveType()}
        {this.renderSplitTable()}
      </div>
    );
  };
  renderBottom = () => (
    <div className={`${prefixCls}__footer`}>
      <Button onClick={this.confirmSplit} type="primary" loading={this.state.isLoading}>
        确定
      </Button>
      <Button onClick={this.handleDialogClose} disabled={this.state.isLoading}>
        取消
      </Button>
    </div>
  );
  render() {
    const { orderNum } = this.props;
    return (
      <div className={`${prefixCls}__wrap`} ref={d => (this.dialogWrap = d)}>
        <ModalDialog
          ref={d => (this._thisDialog = d)}
          isShow
          closable
          title={`拆单（${this.props.orderTips}：${orderNum}）`}
          contentStyle={{ minWidth: this.minWidth }}
          content={this.renderDialogContent()}
          bottom={this.renderBottom()}
          handleHideCallback={this.handleDialogClose}
        />
      </div>
    );
  }
}
