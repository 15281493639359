/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { Table, Load, Ccombiner, CardForm, Icon, CardBox, PopUp, PopTip, ButtonIcon } from 'components';
import BiPickOrder from 'components/commoncomponents/pickorder/BiPickOrder';
import URI from 'urijs';
import { format2ApiQueryFilter } from 'utils/tableUtils';
import {
  PICK_TABLE_ROW_HEIGHT,
  PICK_TABLE_HEADER_HEIGHT,
  ERROR,
  WARN,
  PICKORDER_MAX_RIGHT_NUM,
  ERR_DUPLICATE_REQUEST,
  EXPORT_ALL,
  EXPORT_SELECTED,
  EXPORT_ALL_BY_RESERVATION,
  EXPORT_SELECTED_BY_RESERVATION,
  EXPORT_ALL_BY_GOODS,
  EXPORT_SELECTED_BY_GOODS,
} from 'constants';
import {
  setHeaderFunc,
  setSortFunc,
  getHeader,
  switchSetTpl,
} from 'components/commoncomponents/listOperation/commonOp.js';
import {
  setComDisable,
  selectResetCardFormData,
  getComVal,
  deepEquals,
  filterListNotEquKey,
  fetchJAVA,
  filterListColumnByKey,
  typeIs,
  setComVal,
  fetchApi,
  createTip,
  formateTableCheckTips,
  throttle,
  setCookie,
  getCookie,
  fromJS,
  regularCorrect,
  showInputCheckDialog,
  downLoadFile,
  isset,
  g7Done,
  sequentiallyPostJAVA,
  postJAVA,
  confirmWithReasonSync,
  toNegatives,
} from 'utils';
// import getJAVAPriceSettingParams from 'utils/business/price/getJAVAPriceSettingParams';
import { setDisabledAccountBook } from 'pages/FinanceController/utils/pickOrder.js';
import { isAccountCheckPayMode } from 'pages/FinanceController/utils/accountBook';
import {
  fnBatchExportSelect,
  fnBatchExportAll,
  fnExportAll,
  queryAllByLocalExport,
} from 'components/commoncomponents/listOperation/exportOpFn';
import PriceSnapshotIcon from 'components/commoncomponents/PriceSnapshotIcon';
import { Affix } from '@gui/web-react';
import { IconRefresh } from '@gui/web-react/icon';
import BiSummary from '../BiSummary';
import BiAmountDetail from '../BiAmountDetail';

import {
  dealTableEnum,
  formateTableData,
  buildPricePram,
  handleCardFormDataForSelectRender,
  shouldWvFunc,
  dealFeeDataForShow,
  handleAccountHolderForSelect,
  addAdjustFee,
  crossCompany,
} from './biUtils';
import { invoiceData } from './invoiceInfo';
import { tabType, OrderTabType, tableHeaderCategoryTab } from './tabConfig';
import { prefixCls } from './index.scss';
import { formulaConf } from '../../../../pages/SettingController/set/rosSet/constant';
import TaskLoad from 'pages/ScheduleController/task/taskLoad';
import { billAdjustTable, summary_header, component_props } from './tableConfig';
import { analyzeFeeList, getFeelListByFeeRelation } from '../../../../pages/FinanceController/utils/feeDetailUtil';
import Big from 'big.js';
import { FLOAT_I_9_M_9_P_2, FLOAT_I_9_N_9_P_2 } from '../../../../constants/validate';

import renderReceiptPayment from './renderReceiptPayment';
import PaymentCollectionContainer from 'components/commoncomponents/pickorder/PaymentCollection/paymentCollectionContainer.js';
import { COLLECTION_PAGE_CONFIG } from '../PaymentCollection/config';
import {
  getCollectionUniqData,
  setCollectionData,
  arrayToMap,
} from 'components/commoncomponents/pickorder/PaymentCollection/utils.js';

// 公共方法影响到 车辆配载、运单提送货、运单结算、中转，带有左右挑单

const config = require('../config');

const batchSugApi = {
  url: '/Table/Suggest/batchSug',
  para: {
    category: 'Batch',
    tab: 'tr_pack_left',
    page_num: 1,
    page_size: 10,
    query: { car_batch: '' },
    com_id: window.company_id,
    is_scan: 0,
  },
  enableTrim: true,
  key: 'car_batch',
};
const lenMap = {
  6: { fontSize: '12px !important', lineHeight: '28px' },
  7: { paddingLeft: 4, fontSize: '12px !important', lineHeight: '28px' },
  8: { paddingLeft: 4, fontSize: '12px !important', lineHeight: '28px' },
  9: { paddingLeft: 4, fontSize: '12px !important', lineHeight: '28px' },
  10: { paddingLeft: 4, fontSize: '12px !important', lineHeight: '28px' },
  11: { fontSize: '12px !important', lineHeight: '28px' },
  12: { fontSize: '12px !important', lineHeight: '28px' },
};
const priceConfig = {
  trans_freight_f: 'ext_trans_pt',
  trans_delivery_f: 'ext_trans_pt',
  trans_pickup_f: 'ext_trans_pt',
  trans_handling_f: 'ext_trans_pt',
  od_delivery_f: 'delivery_pt',
  pickup_f: 'pickup_pt',
  b_pickup_f: 'pickup_pt',
};

const getSettingItemList = () => {
  const { itemList = [] } = _.get(window, 'company_setting.b_f_total_formula.formulaDt[0]', {});
  if (!itemList.length) return [];
  const value = [...itemList];
  const b_f_total_formula = Object.keys(formulaConf.b_f_total_formula);
  const reIndex = key => {
    let index = 0;
    b_f_total_formula.forEach((item, i) => {
      if (key === item) {
        index = i;
      }
    });
    return index;
  };
  value.sort((a, b) => reIndex(a) - reIndex(b));
  return value;
};

const invoiceHeader = {
  cardFormToggler: {
    eventNames: ['onClick'],
    otherProps: { iconType: 'icon-carry-out' },
    type: 'Icon',
  },
  titleName: {
    type: 'Label',
    otherProps: { hasClass: false, children: '开票信息' },
  },
};

export default class BiPickOrderContent extends PureComponent {
  constructor(prop) {
    super(prop);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      sendRequest: false,
      query: {},
      feeInfo: {},
      // eslint-disable-next-line react/no-unused-state
      feeWidth: (window.innerWidth - 210 > 1100 ? window.innerWidth - 210 : 1100) || 1100,
      feeHeight: 0,
      batchTableInfo: this.props.batchTableInfo,
      cardFormData: this.props.batchTitleInfo ? this.props.batchTitleInfo.cardInfo : [],
      cardFormTitle: this.props.batchTitleInfo ? this.props.batchTitleInfo.cardFormHeader : {},
      type: this.props.type,
      wRateValue: this.props.wRateValue,
      vRateValue: this.props.vRateValue,
      isLoading: true, // 初始状态为loading
      // 路由变化是否改变左侧筛选
      routeChangeLeft: this.props.batchInfo ? this.props.batchInfo.leftMatchRoute : false,
      // cardForm中的项是否可以修改
      cardFormCanChange: this.props.batchInfo ? this.props.batchInfo.cardFormCanChange : false,
      isDepart: this.props.batchInfo ? this.props.batchInfo.isDepart : false,
      defaultDataFormate: this.props.defaultDataFormate,
      resetDefaultDataFlag: this.props.resetDefaultDataFlag,
      orderNum: 0,
      packNum: 0,
      isPack: false,
      packList: [],
      orderArrList: [],
      oldBatchInfo: _.cloneDeep(this.props.batchTableInfo),
      // eslint-disable-next-line react/no-unused-state
      currentPageInfo: {},
      noSwitchPack: true,
      showPack: false,
      // eslint-disable-next-line react/no-unused-state
      showInvoiceInfo: false, // 是否展示发票信息
      innerInvoiceData: _.cloneDeep(invoiceData), // 发票信息
      feeCatigory: [], // 费用明细
      // 账单调整 表格
      billAdustInfo: {
        ...billAdjustTable,
      },
    };
    this.isEdit = false; // 是否发生修改
    this.feeColumnWidth = 90; // 费用表格默认的宽度
    this.feeDateColumnWidth = 140; // 费用表格日期类型的宽度
    this.substractWidth = 36; // substract 的宽度
    // 顶部cardForm的callback信息
    this.titleCallback = {
      onClick: (...args) => this.commonTitleHandle('handleOnClick', ...args),
      handleIconClick: (...args) => this.commonTitleHandle('handleIconClick', ...args),
      iconClick: (...args) => this.commonTitleHandle('iconClick', ...args),
      onFocus: (...args) => this.commonTitleHandle('onFocus', ...args),
    };
    this.needCalcTruckPrice =
      window.company_setting.truck_calc_price && window.company_setting.truck_calc_price.checked;
    this.useJAVAPrice = window.company_setting.use_java_price && window.company_setting.use_java_price.checked;
    this.batchPriceFetcher = sequentiallyPostJAVA();
    // 因为要根据是否具有账单调整费用，来判断是否显示账单调整，所以始终都要先请求接口（仅限于上下游对账）
    // 特殊的，在isOverload(超过1000条)时，费用明细都取自overload。
    this.props.isFiddle && // 上下游对账
      !this.props.isOverload && // 不是isOverload(超过1000条)对账
      this.getFeeRelation();
    // 存在账单调整，获取 账单调整 费用 下拉
    this.props.isBillAdjust && this.getAdjustFeeTypeList();
    // 收支合计
    this._summary = prop.layout?.feeInfo?.summary ?? [];

    // 对账总金额初始值，仅用于记录判断值是否有变更
    this.beforeCheckTotalAmount = 0;
  }

  static defaultProps = {
    type: 'add',
    parentContainer: window,
    formateLeft: false,
    // 此项配置用来判断首屏在右边的数据是否进行格式化, 比如批次详情，不进行
    defaultDataFormate: true,
    resetDefaultDataFlag: true,
    howToShow: 'both',
    wRateValue: 0,
    vRateValue: 0,
    showRate: false,
    isTask: false,
    showBiTable: true,
    isNewOverload: false,
    isReceiptPayment: false, // 是否是报销单
    isEditOrShow: false, // 编辑或者详情模式 因为type在编辑和新增时是add、在详情时是add
  };
  static propTypes = {
    infoReqParam: PropTypes.object, // 挑单页顶部各种信息的接口请求参数
    openPageBtnKey: PropTypes.string, // 打开挑单页的按钮的key
    originReqFromSource: PropTypes.object, // 打开的原始页包含tab和category
    type: PropTypes.string,
    pageKey: PropTypes.any,
    openPageTab: PropTypes.string, // 打开挑单页的来源页的接口请求tab，用于区分订单对账单、运单收款单页面的编辑按钮
    isCardFormHeader: PropTypes.string,
    batchTableInfo: PropTypes.object,
    batchTitleInfo: PropTypes.object,
    // 费用表格信息
    feeInfo: PropTypes.object,
    extraForm: PropTypes.func,
    // 挑单页是否展示账本
    showAcountBook: PropTypes.bool,
    // button点击回调
    btnClickCallback: PropTypes.func,
    // 顶部cardForm中的icon操作
    handleIconClick: PropTypes.func,
    iconClick: PropTypes.func,
    onFocus: PropTypes.func,
    handleInputChange: PropTypes.func,
    handleInputBlur: PropTypes.func,
    handleSelectChange: PropTypes.func,
    handleCheckBoxChange: PropTypes.func,
    // 左右屏幕挑单完成操作
    handleLeftToRightCallback: PropTypes.func,
    handleRightToLeftCallback: PropTypes.func,
    // 配载率
    wRateValue: PropTypes.any,
    vRateValue: PropTypes.any,
    parentContainer: PropTypes.any,
    // 费用表格下拉操作回调
    handleFeeTableSelectDropSelect: PropTypes.func,
    handleFeeTableChange: PropTypes.func,
    handleFeeTableBlur: PropTypes.func,
    // 费用表格加减操作
    handleTodoSubtract: PropTypes.func,
    handleTodoAdd: PropTypes.func,
    handleRightTodoSubtract: PropTypes.func,
    handleRightTodoAdd: PropTypes.func,
    handleRightOperateClick: PropTypes.func,
    // 左右屏表格change操作
    handleBiTableChange: PropTypes.func,
    // 批次信息
    batchInfo: PropTypes.object,
    // 是否对table的枚举值进行过滤, 会和table的枚举值merge, (此处会有问题)
    enumFilter: PropTypes.object,
    // table中的otherForm操作
    handleTableFormClick: PropTypes.func,
    handleOnClick: PropTypes.func,
    // 左屏数据格式化
    leftDataFormate: PropTypes.func,
    rightDataFormate: PropTypes.func,
    resetDefaultDataFlag: PropTypes.bool,
    dataMap: PropTypes.object,
    resetDefaultData: PropTypes.func,
    formateLeft: PropTypes.bool,
    defaultDataFormate: PropTypes.bool,
    // 请求Table数据之前，处理query
    queryFormateFunc: PropTypes.func,
    // cardForm的body隐藏时，需要显示在header中的字段(批次详情中使用)
    headerHideKey: PropTypes.array,
    // 可修改时显示的字段
    headerEditKey: PropTypes.array,
    // cardForm中可修改时显示在body中，非可修改时，显示在header中的字段
    bodyEditKey: PropTypes.array,
    howToShow: PropTypes.oneOf(['both', 'right']), // 默认左右屏都显示 left 只显示左屏
    opTips: PropTypes.string,
    checkRightTableNum: PropTypes.func,
    renderCustomContent: PropTypes.func,
    tableDataFormate: PropTypes.func,
    refreshBiTableCallback: PropTypes.func, // 挑单左右表格有更新时的回调，一般用于：列表设置、排序等引起的更新
    onRefreshPickerOrder: PropTypes.func, // 完全刷新左右表格的回调
    oilcardAddAuth: PropTypes.any,
    enter13Path: PropTypes.any,
    // 对账比例
    onRateChange: PropTypes.func,
    reconciliationRate: PropTypes.string,
    totalAmount: PropTypes.number,
    acPayAmount: PropTypes.number,
    amerceAmount: PropTypes.string,
    interestAmount: PropTypes.string,
    showRate: PropTypes.bool,
    prohibitTableMove: PropTypes.func,
    fetchTableCallback: PropTypes.func,
    contractList: PropTypes.array,
    viewContract: PropTypes.func,
    isModify: PropTypes.bool,
    // 左右比例
    adjustWidth: PropTypes.object,
    isTask: PropTypes.bool,
  };
  setLoadingStatus = (loading = true) => {
    this.setState({ isLoading: loading });
  };
  company_id = window.company_id;
  setFeeInfoByProps = props => {
    const { feeInfo } = props;
    if (feeInfo && feeInfo.data && feeInfo.data.length) {
      // 处理历史数据，只有现金账本，没有油卡账本数据
      feeInfo.data = setDisabledAccountBook(feeInfo.data);
      // Object.keys(feeInfo.header).forEach(i => {
      //   if (i === 'oil_card') {
      //     const oilHeader = feeInfo.header.oil_card;
      //     props.oilcardAddAuth && (oilHeader.addIcon = 'fn-icon-add-rad');
      //   }
      // });
      const feeHeight = PICK_TABLE_ROW_HEIGHT * feeInfo.data.length + PICK_TABLE_HEADER_HEIGHT;
      this.setState({
        feeInfo: dealFeeDataForShow(feeInfo, {
          dealSettle: props.type === 'show',
          dealOilCard: props.type === 'add',
          that: this,
        }),
        feeHeight,
      });
    }
  };
  UNSAFE_componentWillMount() {
    const cardInfo = this.props.batchTitleInfo.cardInfo || [];
    // 更新筛选条件的回调
    if (this.props.queryFormateFunc && typeof this.props.queryFormateFunc === 'function') {
      let query = this.getTableQuery();
      query = this.props.queryFormateFunc(cardInfo, query);
      this.setState({ query });
    }
    // (装车用)路由有值的， 更新到筛选条件中
    const routeInfo = getComVal(cardInfo.route) || [];
    const { batchInfo } = this.props;
    const routeChangeLeft = batchInfo ? batchInfo.leftMatchRoute : false;
    if (typeIs(routeInfo, 'array') && routeInfo.length > 1 && routeChangeLeft) {
      this.setRouteFilter(routeInfo);
    }
    const checkRightOrderNum = 'checkRightOrderNum' in batchInfo ? batchInfo.checkRightOrderNum : true;
    if (this.props.showBiTable) this.requestPickTable({}, checkRightOrderNum); // 1000条 isOverload 时，无需展示表格

    this.setFeeInfoByProps(this.props);
  }

  handleRefreshBiPickerOrder = () => {
    confirmWithReasonSync({
      tips: `确认刷新数据吗？确认后会获取对账数据最新的可对账金额进行对账，且页面中相关的内容会进行更新。`, // 顶部提示语
      noticeTitle: '',
      btnText: { confirm: '确认' },
    })?.then?.(ok => {
      if (!ok) return;
      const { batchInfo } = this.props;
      const checkRightOrderNum = 'checkRightOrderNum' in batchInfo ? batchInfo.checkRightOrderNum : true;
      const query = {
        button_event_name: 'pick_bill_refresh',
      };

      this.requestPickTable(query, checkRightOrderNum, {
        callback: this.props.onRefreshPickerOrder, // 完全刷新左右表格的回调
      });
    });
  };

  async componentDidMount() {
    const { openPageBtnKey, infoReqParam, originReqFromSource } = this.props;

    const categoryAndTab = `${originReqFromSource?.category} ${originReqFromSource?.tab}`;
    const btnKey = openPageBtnKey;
    const keyArr = [
      'Order receipt_payment_v1', // 上游对账-客户订单对账
      'Batch client_batch_v1_detail', // 上游对账-客户运单对账-客户明细
      'Batch project_v1_batch_detail', // 上游对账-项目运单对账-项目明细
      'Batch client_batch_detail', // 任务对账-客户对账-客户明细
      'Batch project_batch_detail', // 任务对账-项目对账-项目明细
      'Order receipt_pay_billing', // 收款付款-运单收付款-现付
      'Order receipt_pay_arrival', // 收款付款-运单收付款-到付
      'Order receipt_pay_monthly', // 收款付款-运单收付款-月结
      'Order receipt_pay_receipt', // 收款付款-运单收付款-回付
    ];

    const keyArr2 = [
      'Order receipt_payment', // 收款付款-运单收付款-全部
      'Order receipt_crease', // 收款付款-运单收付款-异动
    ];

    let showMark =
      keyArr.indexOf(categoryAndTab) > -1 || (keyArr2.indexOf(categoryAndTab) > -1 && btnKey === 'receiptApply');

    const customerTypeArr = ['receipt', 'customer_batch_client', 'customer_batch_project']; // 单据类型 收款单 客户单 项目单

    // 修改模式需要满足特定的单据类型才能展示发票信息
    if (infoReqParam?.customerType) {
      showMark = customerTypeArr.indexOf(infoReqParam.customerType) > -1;
    }
    this.setState({
      showInvoiceInfo: showMark,
    });
    if (infoReqParam?.bill_id && showMark) {
      await this.getInvoiceInfo(infoReqParam.bill_id, true);
    }

    // 设置收支方式合计
    this.biFeeSummaryRef?.setSummary?.(this._summary);
  }
  /**
   * 外层 props.feeInfo 可能会改变，但是本组件的 state.feeInfo 没有接收新的值。原因：
   * 在BindAction renderBiPickOrder 中有个逻辑判断，当（被BindAction包装的那个组件，如: pickOrder） state.pickOrderInfo 不存在时，使用props.pickOrderInfo。
   * 实际运行时，pickOrder 在mounted之后，state.pickOrderInfo 会重新被赋有值，导致 props.feeInfo 已经改变。
   */
  UNSAFE_componentWillReceiveProps = nextProps => {
    // 由外层决定，是否接受新的Props
    if (nextProps.shouldUpdateWhenReceiveProps) {
      this.props.feeInfo !== nextProps.feeInfo && this.setFeeInfoByProps(nextProps);
    }
  };
  componentDidUpdate(preProps, preState) {
    const { enter13Path } = this.props;
    enter13Path && enter13Path();
  }

  /**
   * @DateTime 2017-12-18
   * @desc:    {[description]}
   * @return   {[type]}        [description]
   */
  resetPickInfoHeader = data => {
    this.setState({
      cardFormData: data.batchTitleInfo ? data.batchTitleInfo.cardInfo : [],
      cardFormTitle: data.batchTitleInfo ? data.batchTitleInfo.cardFormHeader : {},
      feeInfo: data.feeInfo,
    });
    if (data.feeInfo) {
      this.setFeeInfo(data.feeInfo);
    }
    // header 更新不进去的问题
    this.cardForm &&
      this.cardForm.setHeaderData(data.batchTitleInfo ? data.batchTitleInfo.cardFormHeader : this.state.cardFormTitle);
    this.setRightTableData([]);
  };
  /**
   * @DateTime 2017-10-16
   * @desc:    {[description]}
   * @return   {[type]}        [description]
   */
  dealCardFormData = tableData => {
    const data = this.state.cardFormData || {};
    let unAcceptNum = 0;
    let acceptNum = 0;
    if (!data.accept_num || !data.no_deal_num) return data;
    for (let i = 0; i < tableData.right.data.length; i++) {
      if (tableData.right.data[i].point_trans_st === '10') {
        unAcceptNum++;
      } else {
        acceptNum++;
      }
    }
    data.accept_num = setComVal(data.accept_num, acceptNum.toString()).toJS();
    data.no_deal_num = setComVal(data.no_deal_num, unAcceptNum.toString()).toJS();
    return data;
  };
  // unique key
  getuniqueKey = () => {
    const batchTableInfo = this.props.batchTableInfo || {};
    const uniqueInKey = batchTableInfo.uniqueInKey || '';
    return uniqueInKey || batchTableInfo.uniqueKey || 'od_link_id';
  };
  /**
   * @DateTime 2017-10-13
   * @desc:    {[获得底部表格table的category以及tab信息]}
   * @param    {[String]}        type [right|left]
   * @return   {[object]}     [{category, tab}]
   */
  getCategoryInfo = type => {
    const query = this.getTableQuery();
    let tab = query.tab || 'pick_order';
    const tabVal =
      this.state.batchTableInfo.showType === 'billDetail' && this.state.batchTableInfo.tableFilter.is_detail === 1
        ? this.state.query.tab
        : undefined;
    if (type) {
      tab = `${query.tab}_${type}`;
    }
    return {
      category: query.category || 'Order',
      tab: tabVal || tab,
    };
  };
  /**
   * @desc:    {[设置当前表格的筛选条件]}
   */
  setTableQuery = (filter, cb) => {
    const query = this.state.query || {};
    this.setState(
      {
        query: {
          ...query,
          ...filter,
        },
      },
      () => cb?.(),
    );
  };
  /**
   * @DateTime 2017-10-13
   * @desc:    {[获取当前表格的筛选条件]}
   * @return   {[object]}        []
   */
  getTableQuery = () => {
    let query = this.state.query || {};
    if (Object.keys(query).length === 0) {
      const tableInfo = this.props.batchTableInfo || {};
      query = tableInfo.tableFilter || {};
    }
    return query;
  };
  /**
   * @DateTime 2017-12-12
   * @desc:    {[表格的enumeration]}
   * @param    {[type]}        type [description]
   * @return   {[type]}             [description]
   */
  getTableHeader = type => {
    const { tableList } = this.state;
    if (!tableList) return undefined;
    return (tableList[type] || {}).header || {};
  };
  /**
   * @DateTime 2017-12-12
   * @desc:    {[description]}
   * @param    {[type]}        type [description]
   * @return   {[type]}             [description]
   */
  getTableEnum = type => {
    const { tableList } = this.state;
    return (tableList[type] || {}).enumerations || {};
  };
  // 订单提货 特殊处理
  filterQuery = (query, type = 'left') => {
    if (query.category === 'Reservation' && query.tab === 'pickup_pick') {
      // 订单提货批次修改 特殊处理
      query.right &&
        query.right.query &&
        query.right.query.od_link_id &&
        Reflect.deleteProperty(query.right.query, 'od_link_id');
      query.left &&
        query.left.query &&
        Object.keys(query.left.query).forEach(key => {
          query.left.query[key].od_link_id && Reflect.deleteProperty(query.left.query[key], 'od_link_id');
        });
    }

    // 超过 1000 条，在详情页，暂时右侧分页表格。无需 uuid 查询条件
    if (this.props.showBiTable && this.props.isOverload && type === 'right') {
      if (query?.right?.query?.uuid) {
        // query.right.query._uuid = query.right.query.uuid; // 缓存原始uuid，此处我做了妥协，在其他接口场景下可能需要uuid
        query.right.query.uuid = -1;
      }
    }
  };
  /**
   * @DateTime 2017-10-13
   * checkRightOrderNum: 是否校验右屏数据 刷新底部表格数据
   */
  refreshBiTable = async (query, checkRightOrderNum = false, { callback } = {}) => {
    if (!this.props.showBiTable) return; // 无需展示表格
    this.filterQuery(query);
    // 财务总是在请求数据接口时，category:null, tab:null, com_id:null
    // 所以需要跟踪一下
    if (query && query.category === null && query.tab === null) {
      // recordLog({ message: `刷新左右挑单底部表格数据参数出错：${Date()}`, fetchApi: this.props.batchTableInfo })
      console.log('刷新左右挑单底部表格数据参数：', query);
    }
    const tableFilter = {
      ...query,
      need_left: 1,
      need_right: 1,
      key: this.props.pageKey,
      cid: window.globalPrintHostId,
      fetch_mode: 'all',
    };
    const tabMap = {
      1: 'transport_task_tr',
      7: 'transport_task_pick_up',
      5: 'transport_task_deliver',
      3: 'transport_task_short',
    };
    if (tabMap[query.batch_type]) {
      window.LTAB = tabMap[query.batch_type];
      tableFilter.tab = tabMap[query.batch_type];
    } else {
      window.LTAB = undefined;
    }
    const tableInfo = this.props.batchTableInfo || {};
    const url = tableInfo.url || '/Table/Search/pickTable';
    // 右侧最多请求2000条数据
    if (!this.props.isOverload) tableFilter.right.page_size = PICKORDER_MAX_RIGHT_NUM;
    !tableFilter.need_left && this.state.type === 'show' && (tableFilter.need_left = 0);
    // 任务详情,装车清单不需要左屏 司机报销单新版新增、编辑、查看不需要左屏 3561
    // payment_batch_onway_enterpris 新版信息和编辑、 payment_batch_onway_bill_fee_detail 新版详情
    if (
      tableFilter.tab === 'transport_task_tr' ||
      tableFilter.tab === 'transport_task_pick_up' ||
      tableFilter.tab === 'transport_task_deliver' ||
      tableFilter.tab === 'transport_task_short' ||
      tableFilter.tab === 'payment_batch_onway_enterpris' ||
      (tableFilter.tab === 'payment_batch_onway_bill_fee_detail' && this.props.isReceiptPayment)
    )
      tableFilter.need_left = 0;
    const packNum =
      (this.state.packList && this.state.packList.length && this.state.packList.length) ||
      (tableFilter.b_pack_link_ids && tableFilter.b_pack_link_ids.length) ||
      0;
    const newQuery = Object.assign(tableFilter, { packList: this.state.packList || [] });
    // eslint-disable-next-line react/no-unused-state
    this.setState({ isLoading: true, query: newQuery, tableFilter, sendRequest: true, packNum });
    const param = { method: 'POST', body: { req: tableFilter } };

    fetchApi(url, param)
      .then(res => {
        if (res.errno !== 0) {
          if (+res.errno === ERR_DUPLICATE_REQUEST) return;
          createTip(res.errmsg || '获取数据失败', WARN).show();
          return;
        }
        /**
         * 2019-03-01
         * 折单后的单票送货费、单票提货费在结算或者收付款时
         */
        // checkRightOrderNum && this.props.checkRightTableNum && this.props.checkRightTableNum(res.res || {}, query.right, this.getuniqueKey())
        // 因为操作折单后的运单 单票送货费、单票提货费 结算/收付款，query.right的格式不太一样，所以增加了用tableInfo.selectIdList来判断
        const selectList = this.state.showPack ? tableInfo.selectPackIdList : tableInfo.selectIdList;
        checkRightOrderNum &&
          this.props.checkRightTableNum &&
          this.props.checkRightTableNum(
            res.res || {},
            query.right,
            this.getuniqueKey(),
            selectList,
            tableInfo.selectPackBatchList,
            this.state.isPack,
          );
        let resTableList = res.res || {};
        this.props.tableDataFormate && (resTableList = this.props.tableDataFormate(resTableList));

        const tableList = dealTableEnum(
          this.props.enumFilter,
          resTableList,
          this.state.tableList,
          'left,right',
          this.state.type === 'show',
        );

        if (tableList.right.data.length) {
          tableList.right.data = tableList.right.data.map(this.formatPriceTips);
        }
        if (typeof this.props.refreshBiTableCallback === 'function') this.props.refreshBiTableCallback(res.res);
        callback?.(); // 刷新数据完毕进行回调
        this.setState(
          {
            cardFormData: Object.assign({}, this.dealCardFormData(resTableList)),
            // tableList: dealTableEnum(this.props.enumFilter, resTableList, this.state.tableList),
            tableList,
            isLoading: false,
          },
          () => {
            this.doTaskLoad();
          },
        );
        this.props.fetchTableCallback?.(resTableList);
        this.biPickOrder?.handleChangeAmount?.(); // 调用底层BiPickerOrder，触发底部合计计算
      })
      .catch(err => console.log(err));
  };
  // 切换打包单运单
  SwitchPackOrOrder = (type, isPack, allRersh = true) => {
    const { category, tab, name } = this.state.oldBatchInfo.tableFilter;

    const newQuery = isPack
      ? { category: 'Batch', tab: tabType[tab] || tab, name: 'BatchList' }
      : { category: 'Order', tab: OrderTabType[tab] || tab, name: 'OrderList' };
    this.setState({
      query: Object.assign(this.state.query, newQuery),
      noSwitchPack: false,
    });
    const queryCopy = this.state.query;
    const batchInfo = this.props.batchTableInfo || {};
    const url = batchInfo.url || '/Table/Search/pickTable';
    queryCopy.query = queryCopy.query || {};
    let req = _.cloneDeep(queryCopy);
    // 特殊处理，签收页面运单维度会有can_sign参数，其打包单维度不能有
    if (req.tab && req.tab.indexOf('sign') > -1) {
      !isPack
        ? (req.right.query.op_filter = 'can_sign')
        : req.right.query.op_filter && delete req.right.query.op_filter;
    }
    const replaceJson = {
      link_id: ['od_link_id', 'b_link_id'],
      com_id: ['com_id', 'company_id'],
    };
    // 切换打包单和运单接口传值id
    for (const item in replaceJson) {
      req = JSON.parse(
        JSON.stringify(req).replace(
          new RegExp(replaceJson[item][Number(!isPack)], 'g'),
          replaceJson[item][Number(isPack)],
        ),
      );
    }
    this.setState({ query: req, isLoading: true });
    const rQuery = req.right && req.right.query;
    const lQuery = req.left && req.left.query && req.left.query['0'];
    if (isPack) {
      const oldPackList = this.state.packList; // 保存挑单操作后右侧的数据
      const oldPackLinkIds = req.b_pack_link_ids; // 新增打包单时b_pack_link_ids保存着右侧的数据
      if (oldPackList.length) {
        //  以前有挑选到右侧的打包单, 再次切换到打包单时保留以前的状态
        rQuery.b_link_id = [];
        oldPackList.forEach(i => {
          i.b_link_id && rQuery.b_link_id.push(Number(i.b_link_id));
        });
        lQuery.b_link_id = rQuery.b_link_id;
      } else if (oldPackLinkIds && oldPackLinkIds.length) {
        // 第一次进入修改打包单的界面，没有进行挑单操作， this.state.rightClear为true时为手动将右侧清空
        const rightClear = this.state.rightClear && !oldPackList.length;
        rQuery.b_link_id = rightClear ? [-1] : req.b_pack_link_ids;
        lQuery.b_link_id = rightClear ? [-1] : req.b_pack_link_ids;
      }
    } else if (this.state.orderArrList.length) {
      // 以前有挑选到右侧的订单,再次切换到运单时保留以前的状态
      const oldOrder = [];
      this.state.orderArrList.forEach(i => {
        oldOrder.push(i.od_link_id);
      });
      rQuery.od_link_id = oldOrder;
      lQuery.od_link_id = oldOrder;
    }
    rQuery && rQuery.b_tr_link_id && delete rQuery.b_tr_link_id;
    rQuery && rQuery.b_delivery_batch_id && delete rQuery.b_delivery_batch_id;
    allRersh &&
      Object.assign(req, {
        fetch_mode: 'all',
        need_left: 1,
        need_right: allRersh ? 1 : 0,
      });
    const param = { path: url, method: 'POST', body: { req } };
    if (this.promise) this.promise.abort();
    this.promise = fetchApi(param.path, param);
    this.promise.then(
      res => {
        if (res.errno !== 0) {
          if (+res.errno === ERR_DUPLICATE_REQUEST) return;
          createTip(res.errmsg || '获取数据失败', WARN).show();
          return;
        }
        let resTableList = res.res || {};
        const tableInfo = res.res || {};
        const { total } = tableInfo.left || {};
        this.biPickOrder && this.biPickOrder.resetTotalData?.(type, total, true);
        this.setState({ isLoading: false });
        if (req.need_left === 1) {
          this.props.tableDataFormate && (resTableList = this.props.tableDataFormate(resTableList));
          const newList = dealTableEnum(
            this.props.enumFilter,
            res.res,
            this.state.tableList,
            'left,right',
            this.state.type === 'show',
          );
          this.setState({
            cardFormData: Object.assign({}, this.dealCardFormData(resTableList)),
            tableList: newList,
          });
        }
      },
      err => {
        console.log(err);
      },
    );
  };
  /**
   * @DateTime 2017-10-13
   * @param    {[String]}   type    [left or right]
   * @param    {[object]}   query   [当前筛选条件]
   * @param    {Boolean}  exForceFresh [强制刷新，不比较上次的筛选条件和这次的是否相同]
   * @return   {[null]}                []
   */
  refreshTableData = (type, typeQuery, exForceFresh = true) => {
    if (!this.props.showBiTable) return; // 无需展示表格
    const queryCopy = typeQuery;
    const tableFilter = this.state.query;
    const batchInfo = this.props.batchTableInfo || {};
    const url = batchInfo.url || '/Table/Search/pickTable';
    queryCopy.query = queryCopy.query || {};
    // 最后一次请求的参数未变化，不重复发请求
    if (exForceFresh && this[`${type}lastFetchQuery`] && deepEquals(this[`${type}lastFetchQuery`].toJS(), queryCopy)) {
      return false;
    }
    this.setState({ isLoading: true });
    this[`${type}lastFetchQuery`] = fromJS(queryCopy);
    const req = Object.assign(tableFilter, {
      fetch_mode: 'body',
      need_left: type === 'left' ? 1 : 0,
      need_right: type === 'right' ? 1 : 0,
      [type]: queryCopy,
      key: this.props.pageKey,
    });
    this.filterQuery(req, type);
    const param = { path: url, method: 'POST', body: { req } };
    if (this.promise) this.promise.abort();
    this.promise = fetchApi(param.path, param);
    this.promise.then(
      res => {
        if (res.errno !== 0) {
          if (+res.errno === ERR_DUPLICATE_REQUEST) return;
          createTip(res.errmsg || '获取数据失败', WARN).show();
          return;
        }
        const tableInfo = res.res || {};
        const resData = (tableInfo[type] || {}).data || [];
        // const _tableList = dealTableEnum(this.props.enumFilter, tableInfo, undefined, 'left')
        // 这个地方有点怪，刷新了表格之后，难道不应该始终使用最新的数据吗？为啥要 undefined
        const _tableList = dealTableEnum(
          this.props.enumFilter,
          tableInfo,
          this.props.isOverload ? null : undefined,
          this.props.isOverload ? 'left,right' : 'left',
          this.state.type === 'show',
        );
        const { total } = tableInfo[type] || {};
        total.pageSize = queryCopy.page_size;
        total.pageNum = queryCopy.page_num;
        if (type === 'left' && this.biPickOrder) {
          const rightData = this.biPickOrder.getTableData?.('right');
          const rightEnum = this.biPickOrder.mergeSugEnum?.(_tableList.left.enumerations, 'right');
          this.biPickOrder && this.biPickOrder.resetTableData?.('right', rightData, rightEnum, false);
        }
        this.biPickOrder && this.biPickOrder.resetTotalData?.(type, total, true);
        this.biPickOrder &&
          this.biPickOrder.resetTableData?.(type, resData, _tableList.left.enumerations, type === 'left');
        this.setState({ isLoading: false });
      },
      err => {
        console.log(err);
      },
    );
  };
  /**
   * @DateTime 2017-10-13
   * @desc:    {[刷新底部表格接口]}
   * @param    {object}        otherQuery [其他筛选条件]
   * @return   {[null]}                   [description]
   */
  requestPickTable = (otherQuery = {}, checkRightOrderNum = false, ...rest) => {
    let query = this.getTableQuery();
    query = Object.assign(query, otherQuery);
    const searchUrl = URI.parseQuery(window.location.search);
    const showPack = searchUrl.showPack === 'true';
    this.setState({ showPack });

    const tabJson = {
      truck_pick: 'tr_pack',
      sign_pick: 'sign_pack',
      delivery_pick: 'delivery_pack',
      settle_pay_arrival: 'settle_batch_arrival_pack',
      settle_crease: 'settle_batch_crease_pack',
      settle_batch_arrival_pack: 'settle_batch_arrival_pack',
      settle_batch_crease_pack: 'settle_batch_crease_pack',
    };
    const is_view_pack =
      this.state.batchTableInfo && this.state.batchTableInfo.tableFilter && this.state.batchTableInfo.tableFilter.tab;
    if (showPack && is_view_pack !== 'pack_list') {
      this.setState({ isPack: true });
      const rightQuery =
        query.b_pack_link_ids && query.b_pack_link_ids.length > 0
          ? Object.assign(query.right, { query: { b_link_id: query.b_pack_link_ids } })
          : query.right;
      const lQuery_0 = query.left && query.left.query && query.left.query['0'];
      // lQuery_0 && (query.left.query['0'].company_id = query.com_id)
      const lQuery = lQuery_0 || query.left.query;
      if (lQuery && query.b_pack_link_ids && query.b_pack_link_ids.length > 0) {
        lQuery.b_link_id = query.b_pack_link_ids;
        lQuery._logic = 'NOT';
      }
      lQuery && delete lQuery.od_link_id;
      lQuery && delete lQuery.com_id;
      Object.assign(lQuery, { company_id: lQuery.com_id });
      query = Object.assign(query, {
        category: 'Batch',
        tab: tabJson[query.tab],
        name: 'BatchList',
        right: rightQuery,
        left: { query: { 0: lQuery } },
      });
    }
    if (Object.keys(query) === undefined) return;
    this.refreshBiTable(query, checkRightOrderNum, ...rest);
  };
  // 行筛选发生变化
  filterChangeQuery = (type, newFilter) => {
    const query = this.getTableQuery();
    const filter = query[type].query || [];
    const header = this.getTableHeader(type);
    // 行筛选中的条件
    const rowFilter = format2ApiQueryFilter({
      searchFilter: newFilter,
      header,
      initLogicIndex: 500,
      orderNumScan: this.biPickOrder?.leftTable ? this.biPickOrder?.leftTable?.orderNumScan : false,
    });
    const rowFilterQuery = rowFilter.query || {};
    const keyList = Object.keys(rowFilterQuery).concat(Object.keys(filter)) || [];
    keyList.forEach(key => {
      // 左屏幕中的网点筛选不应该生效, 不能筛选出其他网点的运单, 路由节点不从此处清空筛选条件
      if (key === '0' || key === '1' || key === 'od_link_id' || key === 'com_id' || key === 'route_arr') return;
      // 输入的空值， 筛选的空值， 过滤掉
      if (
        rowFilterQuery[key] === '' ||
        rowFilterQuery[key] === undefined ||
        (typeIs(rowFilterQuery[key], 'array') && rowFilterQuery[key].length === 0)
      )
        delete filter[key];
      else filter[key] = rowFilterQuery[key];
    });
    query[type].query = filter;
    if (rowFilter.filter) {
      query[type].filter = rowFilter.filter;
    }
    if (rowFilter.addition) {
      query[type] = { ...query[type], ...rowFilter.addition };
    }
    return query;
  };
  // 左右屏幕表格中的筛选条件发生变化后的回调], 只刷新单侧数据
  changeFilterCallback = (type = 'left', newFilter) => {
    // 右屏幕回车操作不进行任何操作
    if (type === 'right') return;
    const query = this.filterChangeQuery(type, newFilter);
    // 筛选条件改变之后重置page_num
    query[type].page_num = 1;
    this.refreshTableData(type, query[type]);
    this.setState({ query });
  };
  // 点击排序只刷新一侧表格的数据
  handleHeaderSortable = (col, sort, filter) => {
    const query = this.filterChangeQuery('left', filter);
    // const tmpQuery = Object.assign({}, (query || {}).left || {}, { sort })
    query.left = { ...isset(query, 'left', {}), sort };
    this.setState({ query });
    this.refreshTableData('left', query.left);
  };
  /**
   * @DateTime 2017-10-13
   * @desc:    {[窗口发生变化，重置费用表格的宽度]}
   * @return   {[type]}        [description]
   */
  handleResize = () => {
    if (this.pickOrderWrap === undefined || this.pickOrderWrap === null) return false;
    const width = this.pickOrderWrap.offsetWidth;
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      feeWidth: width < 900 ? 900 : width,
    });
  };
  /**
   * @DateTime 2017-10-13
   * @desc:    {[默认值设置]}
   * @param    {[type]}        item [description]
   * @return   {[type]}             [description]
   */
  resetDefaultData = (item, index, refreshDataMap, isInit) => {
    let newItem = item;
    if (this.props.resetDefaultData && typeof this.props.resetDefaultData === 'function') {
      const rightList = this.state.tableList ? this.state.tableList.right : {};
      const header = rightList.header || {};
      const enumerations = rightList.enumerations || {};
      // 调用页面中默认值的赋值
      newItem = this.props.resetDefaultData(newItem, header, enumerations, index, refreshDataMap, isInit);
    }
    return this.formatPriceTips(newItem);
  };
  renderPrice = ({ rowData, columnKey, priceType, type }) => {
    const data = _.get(rowData.__priceInfo, ['process_snapshot', priceType, columnKey]);
    const val = +_.get(rowData.__priceInfo, ['price', priceType, columnKey]);
    const isDelete = _.get(rowData.__priceInfo, ['delete_fees', columnKey]);
    const odLinkId = rowData.od_link_id;
    if (!this.useJAVAPrice || (val && +val !== +rowData[columnKey]) || !+rowData[columnKey] || isDelete) {
      return null;
    }
    return (
      <PriceSnapshotIcon
        className={`input-icon ${type}-priceSnapshot`}
        data={data}
        fee={columnKey.replace('chain_', '')}
        type="yd"
        bizID={odLinkId}
      />
    );
  };
  formatPriceTips = item => {
    const { useJAVAPrice } = this;
    const iitem = item;
    useJAVAPrice &&
      Object.keys(priceConfig).forEach(key => {
        const priceType = priceConfig[key];
        _.set(iitem, ['otherProps', 'extRender', key], ({ rowData, columnKey, rowIndex, type }) =>
          this.renderPrice({ rowData, columnKey, rowIndex, priceType, type }),
        );
      });
    return iitem;
  };
  savePriceSnapshot = () => {
    const { useJAVAPrice } = this;
    const rightData = this.biPickOrder?.getTableData?.('right');
    if (useJAVAPrice && rightData && rightData.length) {
      const req = {
        price: [],
        process_snapshot: [],
      };
      rightData.forEach(item => {
        const priceInfo = item.__priceInfo;
        if (priceInfo) {
          const { process_snapshot, price, delete_fees = {} } = priceInfo;
          if (process_snapshot) {
            const deletes = Object.keys(delete_fees);
            process_snapshot.delete_fees = deletes.length ? deletes : undefined;
          }
          req.price.push(price || {});
          req.process_snapshot.push(process_snapshot);
        }
      });
      if (req.process_snapshot.length) {
        fetchJAVA('/cmm-pricesys/Basic/PriceSystemV2/saveProcessSnapshot', {
          method: 'POST',
          body: {
            req,
          },
        });
      }
    }
  };
  // 左屏数据的预处理以及数据的默认值
  leftDataFormate = (data, refreshDataMap = true, isInit = false) => {
    if (!data) return [];
    if (!this.props.formateLeft) return data;
    const newData = [];
    data.every((item, index) => {
      let newItem = item;
      if (this.state.resetDefaultDataFlag || refreshDataMap) {
        newItem = formateTableData(this.props.dataMap, newItem);
      }
      newItem = this.resetDefaultData(newItem, index, refreshDataMap, isInit);
      if (newItem === false) return false;
      newData.push(newItem);
      return true;
    });
    return newData;
  };
  rightDataFormate = data => {
    if (!this.props.rightDataFormate || typeof this.props.rightDataFormate !== 'function') return data;
    const leftList = this.state.tableList ? this.state.tableList.left : {};
    const header = leftList.header || {};
    const enumerations = leftList.enumerations || {};
    const newData = [];
    data.forEach(item => {
      newData.push(this.props.rightDataFormate(item, header, enumerations));
    });
    return newData;
  };
  // 左侧更新页码数
  /**
   * @DateTime 2017-10-13
   * @desc:    {[description]}
   * @param    {[type]}        newPage [description]
   * @return   {[type]}                [description]
   */
  changeLeftPageNum = newPage => {
    const { query } = this.state;
    query.left.page_size = newPage || null;
    this.setState({ query });
    this.refreshTableData('left', query.left);
  };
  handlePaging = (type, pageSize, pageNum) => {
    const { query } = this.state;
    query[type] = query?.[type] ?? {};
    query[type].page_num = pageNum || null;
    query[type].page_size = pageSize;
    this.setState({ query });
    this.refreshTableData(type, query[type]);
  };
  toArray = value => {
    if (typeIs(value, 'number')) {
      return [value];
    }
    if (typeIs(value, 'string')) {
      return value.split('，').map(v => +v);
    }
    if (typeIs(value, 'array')) {
      return value.map(v => +v);
    }
    return [];
  };
  /* ************************************* 价格计算(start) *************************************** */
  /**
   * @DateTime 2017-10-13
   * @desc:    {[价格计算参数中，添加分摊方式]}
   * @param    {[object]}      req [description]
   * @return   {[type]}            [description]
   */
  dealPriceReq = req => {
    const cardInfo = this.cardForm ? this.cardForm.state.data.toJS() : {};
    if (cardInfo.fee_ave_type) {
      let feeAveType = getComVal(cardInfo.fee_ave_type) || '';
      if (typeIs(feeAveType, 'array') && feeAveType.length) {
        feeAveType = feeAveType[0].key;
      }
      return req.type[0] === 'point_trans_pt'
        ? Object.assign({}, req, { point_trans_fee_ave_type: feeAveType })
        : Object.assign({}, req, { fee_ave_type: feeAveType });
    }
    return req;
  };
  pricePramFilter = (key, value) => {
    switch (key) {
      // 多票重量体积
      case 'weight':
      case 'volume': // eslint-disable-line
        return this.toArray(value).reduce((a, b) => +a + +b, 0);
      default:
        return value;
    }
  };
  /**
   * @DateTime 2017-10-13
   * @desc:    {[description]}
   * @param    {[list]}        priceKeyList [待计算的价格类型列表]
   * @param    {[list]}        dataList     [待计算的数据列表]
   * @param    {[String]}        type         [计算类型]
   * @param    {Boolean}       filter       [是否进行有值不再进行计算的过滤(中转和提货不过滤,因为涉及到批次费用)]
   * @param    {[Function]}        paramFormate [参数处理回调]
   * @param    {Function}      callback     [完成后的回调]
   * @return   {[type]}                     [description]
   */
  getPriceFromRightTable = (priceKeyList, dataList, type, filter = true, paramFormate, callback) => {
    const rightTableData = this.biPickOrder ? this.biPickOrder.getTableData?.('right') : [];
    const tableData = dataList || rightTableData;
    const { priceUrl } = config;
    const resList = [];
    tableData.forEach(item => {
      const isEmpty = value => !item[value] || item[value] === 0 || item[value] === '0';
      // 待计算价格中有一项不为0， 即计算此运单的价格
      if (priceKeyList.some(isEmpty) || !filter) {
        resList.push(buildPricePram(Object.assign({}, item), paramFormate, type));
      }
    });
    // 带计算价格的列表为空, 不调用用接口
    if (resList.length === 0) {
      if (callback && typeof callback === 'function') {
        callback(rightTableData, {});
      }
      return;
    }
    if (resList.length === 0) return;
    const types = typeIs(type, 'array') ? type : [type];
    const param = {
      method: 'POST',
      body: {
        req: this.dealPriceReq({
          type: types,
          orders: resList,
        }),
      },
    };
    const useJAVAPrice =
      types[0] !== 'point_trans_pt' &&
      window.company_setting.use_java_price &&
      window.company_setting.use_java_price.checked;
    const url = `${useJAVAPrice ? '/cmm-pricesys/' : ''}Basic/PriceSystemV2/getOrderOuterPrice?no_explain_detail=0`;
    // if (useJAVAPrice) param.body.req.settings = getJAVAPriceSettingParams(types);
    const fetch = useJAVAPrice ? fetchJAVA : fetchApi;
    const req = fetch(url, param);

    req
      .then(res => {
        if (res.errno !== 0) {
          createTip(res.errmsg, WARN).show();
          return;
        }
        this.priceSnapshot = res.res || {};
        if (useJAVAPrice) {
          const { process_snapshot = [], biz_ids = [] } = this.priceSnapshot || {};
          this.priceSnapshot.process_snapshot = process_snapshot.map((item, index) => {
            const _iitem = item;
            _iitem.type = 'yd';
            _iitem.biz_id = biz_ids[index];
            this.priceSnapshot.price[index].biz_id = biz_ids[index];
            return _iitem;
          });
        }
        this.setPriceForRightTable(type, this.priceSnapshot, callback, !!useJAVAPrice);
      })
      .catch(err => console.log(err));
  };

  // 计算批次价格
  getBatchPrice = (type, extData, cb) => {
    const data = this.cardForm.getStateDataForm();
    const batchInfo = this.props.batchInfo || {};
    const headerData = this.cardForm.getStateHeader();
    const orderList = this.biPickOrder?.getTableData?.('right');
    const wvnKeyMap = {
      truck_pt: { w: 'b_tr_load_w', v: 'b_tr_load_v', n: 'b_tr_load_n' },
      pickup_pt: { w: 'b_pickup_load_w', v: 'b_pickup_load_v', n: 'b_pickup_load_n' },
      delivery_pt: { w: 'b_delivery_load_w', v: 'b_delivery_load_v', n: 'b_delivery_load_n' },
    };
    const weight = _.sumBy(orderList, item => +item[wvnKeyMap[type].w] || 0); // 装车重量范围
    const volume = _.sumBy(orderList, item => +item[wvnKeyMap[type].v] || 0); // 装车体积范围
    const num = _.sumBy(orderList, item => +item[wvnKeyMap[type].n] || 0); // 装车件数范围
    const actualWeight = _.sumBy(orderList, item => +item.actual_weight || 0); // 装车重量范围
    const actualVolume = _.sumBy(orderList, item => +item.actual_volume || 0); // 装车体积范围
    const actualNum = _.sumBy(orderList, item => +item.actual_num || 0); // 装车件数范围
    const priceWeight = _.sumBy(orderList, item => +item.settle_weight || 0); // 装车结算重量范围
    const suit = _.sum(orderList.map(item => _.sumBy(item.g_suit || [], n => +n || 0))); // 装车套数范围
    let routeNodes = data.route || [];
    routeNodes.length === 1 && routeNodes[0].line_nodes && (routeNodes = routeNodes[0].line_nodes);
    const goods = {
      truck_type: extData.tr_type, // 车型
      truck_length: extData.tr_length, // 车长
      tr_num: extData.tr_num, // 车牌号
      tr_num_ext: extData.tr_num_ext, // 挂车牌号
      dr_id: extData.dr_id, // 司机
      fee_attr_carrier: extData.carrier_name,
      carrier_id: extData.carrier_id,
      weight,
      volume,
      price_weight: priceWeight,
      num,
      suit,
      mile: +extData.line_distance || 0,
      order_n: orderList.length,
      actual_weight: actualWeight,
      actual_volume: actualVolume,
      actual_num: actualNum,
      route_id: extData.route_id,
      car_node_num: routeNodes.length,
      insured: extData.insured,
    };
    const order = {
      b_company_id: batchInfo.company_id,
      truck_type: extData.tr_type, // 车型
      truck_length: extData.tr_length, // 车长
      tr_num: extData.tr_num, // 车牌号
      tr_num_ext: extData.tr_num_ext, // 挂车牌号
      dr_id: extData.dr_id, // 司机
      fee_attr_carrier: extData.carrier_name,
      carrier_id: extData.carrier_id,
      route_line: routeNodes,
      weight,
      volume,
      price_weight: priceWeight,
      num,
      suit,
      actual_weight: actualWeight,
      actual_volume: actualVolume,
      actual_num: actualNum,
      route_id: extData.route_id,
      goods: [goods],
      insured: extData.insured,
      goods_name: extData.name_1,
    };
    const req = { type: [type], orders: [order] };
    if (type === 'truck_pt') {
      order.plan_truck_t = getComVal(headerData.plan_truck_t); // 预计发车时间
    }

    if (type === 'pickup_pt') {
      order.pickup_truck_t = getComVal(headerData.truck_t); // 提货时间
    }
    if (type === 'delivery_pt') {
      order.delivery_truck_t = getComVal(headerData.truck_t); // 送货时间
    }
    const url = '/cmm-pricesys/Basic/PriceSystemV2/getOrderOuterPrice?no_explain_detail=0';
    this.batchPriceCalcInQueue = false;
    this.lastBatchPriceParams = req;
    this.batchPriceCalcInProcess = true;
    const fetchPrice = this.batchPriceFetcher(url, req);
    fetchPrice
      .then(res => {
        cb(res);
        const result = res.res && res.res.price[0];
        if (result) {
          this.priceSnapshotOrigin = { price: [result], process_snapshot: res.res.process_snapshot };
        }
        this.batchPriceCalcInProcess = false;
        this.onCalcBatchPriceComplete && this.onCalcBatchPriceComplete();
      })
      .catch(err => {
        this.batchPriceCalcInProcess = false;
        this.onCalcBatchPriceComplete && this.onCalcBatchPriceComplete();
        console.error(err);
      });
    return fetchPrice;
  };

  // 节流后的批次价格计算
  throttledCalcBatchPrice = throttle(this.getBatchPrice, 500);

  // 触发价格计算调用这个
  calcBatchPrice = (type, extData, cb) => {
    this.batchPriceCalcInQueue = true;
    this.throttledCalcBatchPrice(type, extData, cb);
  };

  // 等待批次价格计算结束
  batchPriceCalcComplete = () => {
    if (this.batchPriceCalcInQueue) {
      this.lastBatchPriceParams = {};
      this.getBatchPrice();
    }
    return new Promise(resolve => {
      if (this.batchPriceCalcInProcess) {
        this.onthrottledCalcBatchPriceComplete = () => {
          this.onCalcBatchPriceComplete = null;
          resolve();
        };
        return;
      }
      resolve();
    });
  };

  // 保存价格快照
  saveBatchPriceSnapshot = (id, priceSnapshot = {}, priceSnapshotDelete = {}) => {
    const { priceSnapshotOrigin } = this;
    const deleteFees = Object.keys(priceSnapshotDelete).filter(key => !priceSnapshot[key]);
    if (Object.keys(priceSnapshot).length || deleteFees.length) {
      const url = '/cmm-pricesys/Basic/PriceSystemV2/saveProcessSnapshot';
      const price = {};
      const snapshot = { type: 'pc', biz_id: id, delete_fees: deleteFees };
      // price 与 process_snapshot结构一致 删除多余的
      if (priceSnapshotOrigin && priceSnapshotOrigin.process_snapshot && priceSnapshotOrigin.process_snapshot[0]) {
        Object.entries(priceSnapshotOrigin.process_snapshot[0]).forEach(([pt, data]) =>
          Object.keys(data).forEach(key => {
            if (priceSnapshot[key]) {
              price[pt] = price[pt] || {};
              price[pt][key] = priceSnapshotOrigin.price[0][pt][key];
              snapshot[pt] = snapshot[pt] || { calc_type: priceSnapshotOrigin.process_snapshot[0][pt].calc_type };
              snapshot[pt][key] = priceSnapshotOrigin.process_snapshot[0][pt][key];
            }
          }),
        );
      }
      postJAVA(url, { price: [price], process_snapshot: [snapshot] });
    }
  };
  /**
   * @DateTime 2017-10-13
   * @desc:    {[更新右侧表格中的价格信息]}
   * @param    {[type]}        type     [description]
   * @param    {[type]}        priceRes [description]
   * @param    {Function}      callback [价格计算后的回调]
   * @return   {[type]}                 [description]
   */
  setPriceForRightTable = (type, priceRes, callback, isJAVA) => {
    if (!this.biPickOrder) return;
    const priceList = priceRes.price || [];
    const priceEx = priceRes.data_ex || {};
    const processSnapshot = priceRes.process_snapshot || [];
    const rightTablData = this.biPickOrder.getTableData('right');
    let rightTotalData = this.biPickOrder.getTotalData('right');
    // countTotal： 暂存价格返回信息的合计值
    const newDataList = [];
    const countTotal = {};
    rightTablData.forEach(item => {
      const odLinkId = item.od_link_id;
      if (!odLinkId) return;
      const priceIndex = priceList.findIndex(
        priceItem => (isJAVA ? priceItem.biz_id : priceItem.od_link_id) === `${odLinkId}`,
      );
      if (priceIndex < 0) {
        newDataList.push(item);
        return;
      }
      let priceValue = priceList[priceIndex];
      priceValue = { ...(priceValue[type] || {}) };
      // 不计算运单维度的批次费用
      delete priceValue.b_pickup_f;
      delete priceValue.b_delivery_f;

      const priceValueCopy = { ...priceValue };

      Object.keys(priceValue).forEach(k => {
        let key = k;
        if (key.startsWith('point_trans_act')) {
          key = key.replace('_act_', '_');
          priceValue[key] = priceValue[k];
          delete priceValue[k];
        }
        countTotal[key] = (parseFloat(countTotal[key]) || 0) + priceValue[key];
        // 如果此行数据中有手动修改或者Unit计算的标记，不用价格计算的结果进行覆盖
        if (item[`${key}_handy_status`] !== undefined && +item[`${key}_handy_status`] !== 1) {
          delete priceValue[key];
        } else {
          priceValue[`${key}_handy_status`] = 1; // 1 为价格计算结果
        }
      });
      // 手动计算标准网点中转费合计
      if (type === 'point_trans_pt') {
        const pointTransStdF = [
          'point_trans_std_freight_f',
          'point_trans_std_delivery_f',
          'point_trans_std_pickup_f',
          'point_trans_std_handling_f',
        ];
        priceValue.chain_point_trans_std_f = pointTransStdF.reduce((a, b) => (+priceValue[b] || 0) + a, 0).toFixed(2);
      }
      // callback 处理的联动的逻辑, 此处有bug， 合计运费列的合计值没有更新
      const newItem = Object.assign({ [type]: priceValueCopy }, item, priceValue);
      // if (callback && typeof callback === 'function') newItem = callback(newItem, tmpKey, priceValue[tmpKey], priceEx)
      newItem.__priceInfo = {
        price: priceList[priceIndex],
        process_snapshot: processSnapshot[priceIndex],
        delete_fees: {},
        type,
      };
      newDataList.push(newItem);
    });
    // 费用处理完之后执行回调
    if (callback && typeof callback === 'function') {
      const res = callback(newDataList, priceEx);
      this.biPickOrder && this.biPickOrder.resetTableData?.('right', res);
    } else {
      // 更新右侧表格数据
      this.biPickOrder && this.biPickOrder.resetTableData?.('right', newDataList);
    }
    rightTotalData = Object.assign(rightTotalData, countTotal);
    // 更新右侧表格total信息
    this.biPickOrder && this.biPickOrder.resetTotalData?.('right', rightTotalData);
  };
  /* ************************************* 价格计算(end) *************************************** */
  /*
   * 左侧更新筛选条件
   */
  setRightButtonLoadingStatus = (btnKey, flag = true) => {
    window.btnLoadingOn = !flag ? undefined : true;
    const buttons = this.biPickOrder ? this.biPickOrder.getRightButtonList?.() : [];
    if (buttons.length || !btnKey || buttons[btnKey].loading === flag) return;
    buttons[btnKey].loading = flag;
    this.biPickOrder && this.biPickOrder.changeRightButtonList?.(buttons);
  };
  setLeftButtonLoadingStatus = (btnKey, flag) => {
    const buttons = this.biPickOrder ? this.biPickOrder.getLeftButtonList?.() : [];
    if (buttons.length || !btnKey) return;
    buttons[btnKey].loading = flag;
    this.biPickOrder && this.biPickOrder.changeLeftButtonList?.(buttons);
  };
  changeButtonStatus = () => {
    const { batchTableInfo } = this.state;
    const rightButtonList = batchTableInfo.right.button;
    const newButtonList = [];
    rightButtonList.forEach(item => {
      const newItem = item;
      if (newItem.otherProps.display) newItem.otherProps.display = false;
      else newItem.otherProps.display = true;
      newButtonList.push(item);
    });
    batchTableInfo.right.button = newButtonList;
    this.setState({ batchTableInfo });
  };
  /*
   修改配载的可编辑状态
   */
  changeLoadingStatus = (canEdit, query = {}) => {
    const newData = {};
    const newHeader = {};
    // 点击了修改也不能修改的情况， 比如上游已经发车不能再修改等
    const status = canEdit;
    const type = status ? 'show' : 'edit';
    const { header, data } = this.getHeaderData(type, status);
    let dataKeys = Object.keys(data) || [];
    const bodyEditKey = this.props.bodyEditKey || [];
    // 此处为了保持route以及短驳的到站在第一个位置
    bodyEditKey.forEach(item => {
      if (dataKeys.indexOf(item)) {
        dataKeys = dataKeys.filter(x => x !== item);
        dataKeys.splice(0, 0, item);
      }
    });

    // 结算服务1.0.8 配载后自动认证，且认证成功后才可操作发车 start
    const { batchInfo = {} } = this.props;
    const batchType = [1, 3, 5, 7].includes(+batchInfo.type);
    const pmsn = window.permission.includes('payMgr');
    const departed = +batchInfo.blk_flag >= 50 && +batchInfo.batch_id === +batchInfo.head_b_link_id;

    let opPageType = 'add'; // 新增配载 add |  修改批次 update  |  批次详情 view

    if (type === 'show') {
      opPageType = 'view';
    } else if (+batchInfo.batch_id) {
      opPageType = 'update';
    }

    if (!g7Done() || !batchType || !pmsn) {
      dataKeys = dataKeys.filter(x => !['is_auto_import', 'is_auto_import_tip'].includes(x));
    }
    // 结算服务1.0.8 配载后自动认证，且认证成功后才可操作发车 end

    dataKeys.forEach(key => {
      const item = data[key];
      let sta = status || this.state.cardFormCanChange;
      if (
        (key === 'route' && this.state.isDepart) ||
        key === 'source' ||
        key === 'tr_payee_name' ||
        key === 'delivery_pay_monthly_ratio' ||
        key === 'pickup_pay_monthly_ratio'
      ) {
        sta = true;
      }
      if (key === 'is_auto_import') {
        const isAutoImportDisabled = type === 'show' || +batchInfo.b_tr_g7_import_st === 2;
        sta = isAutoImportDisabled;
      }
      newData[key] = setComDisable(item, sta).toJS();
      if (!sta && (key === 'b_delivery_f' || key === 'b_pickup_f')) {
        delete newData[key].otherProps.disabled;
      }
    });
    Object.keys(header).forEach(key => {
      if (key === 'truck_total') {
        newHeader[key] = header[key];
        return;
      }
      const item = header[key];
      newHeader[key] = setComDisable(item, status || this.state.cardFormCanChange).toJS();
    });
    // this.cardForm && this.cardForm.showBody(!status)
    this.cardForm &&
      this.cardForm.changeState({ showBody: !status, headerItem: fromJS(newHeader) }, () => {
        this.changeFeeStatus(status);
        const shouldRefreshTable = this.props?.beforeRefreshBiTableByEdit?.() ?? true;
        shouldRefreshTable && this.requestPickTable({ ...query, need_left: 1 });
        this.biPickOrder && this.biPickOrder.setTab?.(query.tab);

        const newInvoiceData = this.state.innerInvoiceData;
        Object.keys(newInvoiceData).forEach(item => {
          // 编辑模式
          if (type === 'edit') {
            newInvoiceData[item].otherProps.disabled = false;
          }
        });
        this.setState({
          type,
          cardFormData: newData,
          query: Object.assign(this.state.query, query),
          // eslint-disable-next-line react/no-unused-state
          sendRequest: false,
          cardFormTitle: newHeader,
          // eslint-disable-next-line react/no-unused-state
          changeStatus: true,
          isLoading: shouldRefreshTable,
          tableList: {}, // 数据清空
          defaultDataFormate: true,
          innerInvoiceData: { ...newInvoiceData },
        });
      });
  };
  /* 重置table数据 */
  resetTableList = res => {
    const data = res.data || [];
    const total = res.total || {};
    const rateW = total.rate_w || 0;
    const rateV = total.rate_v || 0;
    const rightBak = this.biPickOrder?.getTableData?.('right');
    let leftList = this.biPickOrder?.getTableData?.('left');
    // 将右侧表格原有的运单移到左侧
    rightBak.forEach(item => {
      leftList.push(item);
    });
    // 左侧表格中删除新挑入的运单
    const idList = filterListColumnByKey(data, 'od_link_id');
    leftList = filterListNotEquKey(leftList, 'od_link_id', idList);
    // 返回的信息放到右侧表格
    this.biPickOrder?.resetTableData?.('right', data);
    this.biPickOrder?.resetTotalData?.('right', total);
    // 更新左侧数据以及合计信息
    this.biPickOrder?.resetTableData?.('left', leftList, true);
    this.biPickOrder?.setRateVal?.({
      wRateValue: parseFloat(rateW * 100).toFixed(2),
      vRateValue: parseFloat(rateV * 100).toFixed(2),
    });
  };
  //  获取导出的头部信息
  exportBatchInfo = () => {
    const cardFormInfo = this.cardForm.getStateData();
    const feeInfoTitle = this.feeTable && this.feeTable.headerTitle;
    const feeInfoData = (this.feeTable && this.feeTable.getTableData()) || {};
    const batchTableInfo = this.props.batchTitleInfo || {};
    return {
      cardForm: cardFormInfo,
      feeInfoTitle,
      feeInfoData: feeInfoData.data,
      cardFormHeader: batchTableInfo.cardFormHeader,
      tab: this.props.batchTableInfo.tableFilter.tab,
    };
  };
  // 前端导出方法
  localExport = ({ listData, total, header, title }) => {
    const cardFormInfo = this.cardForm.getStateData();
    const feeInfoData = (this.feeTable && this.feeTable.getTableData()) || {};
    const feeInfoTitle = this.feeTable && this.feeTable.headerTitle;
    const batchTableInfo = this.props.batchTitleInfo || {};
    const pageTitle = title;
    let batchInfo = {
      cardForm: cardFormInfo,
      feeInfoTitle,
      feeInfoData: feeInfoData.data,
      cardFormHeader: batchTableInfo.cardFormHeader,
      tab: this.props.batchTableInfo.tableFilter.tab, // 标识 是那个页面
    };
    if (this.state.query.tab === 'employee' || this.state.query.tab === 'point_bill_detail') {
      // pageTitle = this.props.parentContainer.props.HeaderTitle
      batchInfo = undefined;
    }
    fnBatchExportSelect({ total, listData, header, title: pageTitle || '详情数据', batchInfo });
  };
  toQueryAllByLocalExport = async ({ req, exportUrl, pageTitle, header, totalAcount, pageSize = 500 }) => {
    const createParams = page_num => {
      return {
        ...req,
        right: {
          query: {
            [this.props.batchTableInfo.uniqueKey]: '-1',
          },
          page_size: pageSize,
          page_num,
        },
        left: {
          query: {},
        },
        need_left: 0,
        format: '',
      };
    };
    queryAllByLocalExport({ totalAcount, exportUrl, pageTitle, header, createParams, extra: { that: this } });
  };
  // 导出全部
  tableExport = (type, ext, byType, allListData) => {
    // 因右屏数据不分页，可走前端导出
    const header = this.biPickOrder?.[`${type}Table`].getShowingHeader?.();
    let pageTitle =
      (this.props.parentContainer.props && this.props.parentContainer.props.HeaderTitle) || ext.pageTitle || '';
    this.state.query && this.state.query.tab === 'pack_list' && (pageTitle = '打包管理');
    // 超1000条对账，全部导出不能直接导，因为表格是分页的
    if (type === 'right' && !byType && !this.props.isOverload) {
      const total = this.biPickOrder?.[`${type}Table`].getTotalRow?.();
      const listData = this.biPickOrder?.[`${type}Table`].getAllShowData?.();
      return this.localExport({ listData, total, title: pageTitle, header });
    }
    // 此处判断有问题, 后面需要改成配置
    const isBatch = this.state.query.category === 'Batch' ? 1 : 0;
    let exportUrl = isBatch ? '/Table/Search/batchList' : '/Table/Search/orderList';
    // 使用 cloneDeep，防止下面赋值影响了原有值
    const query = _.cloneDeep(this.state.query || {});
    const tabVal =
      this.state.batchTableInfo.showType === 'billDetail' ? this.state.query.tab : `${this.state.query.tab}_${type}`;
    config.exportUrlConf && config.exportUrlConf[tabVal] && (exportUrl = config.exportUrlConf[tabVal]);
    let queryObj = query.right;
    let batchInfo = this.exportBatchInfo(type);
    // 财务清单导出格式不一样
    if (config.exportUrlConf && config.exportUrlConf[this.state.query.tab]) {
      queryObj = query;
      queryObj.format = 'xls';
      batchInfo = undefined;
    }
    const data = Object.assign(
      {
        ...this.getCategoryInfo(type),
        format: 'xls',
        company_id: ext.company_id,
      },
      queryObj || {},
      { ...ext },
    );
    let total = this.biPickOrder?.[`${type}Table`]?.getTotalData?.().count;
    let export_ways = null;
    const ids = [];
    if (byType === 'export_all_by_reservation') {
      export_ways = 'all';
    } else if (byType === 'export_all_by_goods') {
      export_ways = 'all_goods';
    } else if (byType === 'export_selected_by_goods' || byType === 'export_select_by_reservation') {
      export_ways = byType === 'export_selected_by_goods' ? 'select_goods' : 'select';
      const allData = (allListData && allListData.list) || [];
      const allDataSelect = (allListData && allListData.select) || [];
      allData.forEach((item, index) => {
        if (item.checkbox || allDataSelect.includes(index)) {
          ids.push(item.id);
        }
      });
      total = ids.length;
      if (total < 1) {
        createTip('请选择要导出的数据！', ERROR).show();
        return false;
      }
    }
    if (this.props.isOverload && type === 'right') {
      return this.toQueryAllByLocalExport({
        req: data,
        totalAcount: this.biPickOrder?.[`${type}Table`]?.state?.total?.count,
        pageTitle,
        header,
        exportUrl,
      });
    }
    fnBatchExportAll({ req: data, exportUrl, count: total, batchInfo, pageTitle, export_ways, ids });
  };

  // 导出选中,低啊用前端筛选
  tableExportSelect = (type, title) => {
    let pageTitle = title;
    if (this.biPickOrder && this.biPickOrder[`${type}Table`]) {
      const _selects = this.biPickOrder[`${type}Table`]?.getStateSelectes?.();
      if (_selects.length < 1) {
        createTip('请选择要导出的数据！', ERROR).show();
        return false;
      }
      const header = this.biPickOrder[`${type}Table`].getShowingHeader?.();

      const total = this.biPickOrder[`${type}Table`].getSelectedTotalRow?.();
      const listData = this.biPickOrder[`${type}Table`].getShowingData?.(_selects);
      this.state.query && this.state.query.tab === 'pack_list' && (pageTitle = '打包管理');
      this.localExport({
        listData,
        total,
        header,
        title: pageTitle,
      });
    }
  };
  // 判断是设置表头还是排序,还是切换模板
  tableSet = (obj, type) => {
    if (obj && obj.key === 'set_header') {
      this.tableSetHeader(type);
    } else if (obj && obj.key === 'set_sort') {
      this.tableSetSort(type);
    }
    if (obj.isSublistClick && isset(obj, 'menuBtnItemParent.key') === 'switch_tpl') {
      this.tableChangeTpl(obj, type);
    }
  };
  // 切换模板
  tableChangeTpl = (obj, type) => {
    const categoryInfo = this.getCategoryInfo(type) || {};
    switchSetTpl(obj, categoryInfo.category, categoryInfo.tab, () => {
      // 刷新表格数据
      const { query } = this.state;
      this.refreshBiTable(query);
    });
  };
  // 列表设置
  tableSetHeader = type => {
    const oricCategoryTab = this.getCategoryInfo(type) || {};
    const category_tab = `${oricCategoryTab.category}_${oricCategoryTab.tab}`;
    const categoryTab = tableHeaderCategoryTab[category_tab] ?? oricCategoryTab; // 特定场景需要映射成固定的category和tab
    getHeader(categoryTab).then(header => {
      setHeaderFunc(header, this, categoryTab, {
        callback: () => {
          // 刷新表格数据
          const { query } = this.state;
          this.refreshBiTable(query);
        },
        ...component_props[category_tab]?.setHeaderProps,
      });
    });
  };
  // 列表排序
  tableSetSort = type => {
    const sort = type === 'left' ? this.state.tableList.left.sort : this.state.tableList.right.sort;
    const oricCategoryTab = this.getCategoryInfo(type) || {};
    const category_tab = `${oricCategoryTab.category}_${oricCategoryTab.tab}`;
    const categoryTab = tableHeaderCategoryTab[category_tab] ?? oricCategoryTab; // 特定场景需要映射成固定的category和tab
    getHeader(categoryTab).then(header => {
      setSortFunc(header, sort, this, categoryTab, {
        callback: () => {
          // 刷新表格数据
          const { query } = this.state;
          this.refreshBiTable(query);
        },
        ...component_props[category_tab]?.setSortProps,
      });
    });
  };
  refreshRightTable = (key, rightList) => {
    const { query } = this.state;
    this.refreshTableData('right', query.right, false);
  };
  refreshLeftTable = (key, rightList) => {
    const { query } = this.state;
    if (rightList.length === 0) query.left.query[0][this.props.batchTableInfo.uniqueKey] = [];
    this.refreshTableData('left', query.left, false);
  };
  refreshPackOrOrder = (key, isPack) => {
    const batchTableInfo = isPack
      ? Object.assign(this.state.batchTableInfo, { fetchApi: batchSugApi, filterTips: '打包号' })
      : Object.assign({ filterTips: '运单号' }, this.state.oldBatchInfo);
    this.setState({ isPack, batchTableInfo });
    this.SwitchPackOrOrder(key, isPack);
  };
  leftTableExportAll = key => {
    const tableInfo = this.props.batchTableInfo || {};
    const { query } = this.state;
    const url = tableInfo.url || '/Table/Search/pickTable';
    const tabParam = URI.parseQuery(window.location.search);
    let tabName = '左屏';
    tabName = `${tabParam.tabName || ''}-左屏`;
    if (!this.biPickOrder || !this.biPickOrder.leftTable) return;
    query.need_right = 0;
    const tableFilter = this.biPickOrder.leftTable.getSearchFilter?.();
    const tableQuery = format2ApiQueryFilter({
      searchFilter: tableFilter,
      header: (this.state.tableList.left || {}).header,
    });
    let count = ((this.biPickOrder.leftTable.state || {}).total || {}).count || 1000;
    // reset后 表格中的total已经左右屏中的total值都不正确
    if (this.biPickOrder.leftTableTotal) {
      count = this.biPickOrder.leftTableTotal.count || 1000;
    }
    query.left.query = { ...query.left.query, ...tableQuery.query };
    query.left.filter = { ...query.left.filter, ...tableQuery.filter };
    delete query.left.page_size;
    delete query.right.page_size;
    const req = { ...query, format: 'xls' };
    let export_ways = '';
    const ids = [];
    if (key === EXPORT_ALL_BY_RESERVATION) {
      export_ways = 'all';
    } else if (key === EXPORT_ALL_BY_GOODS) {
      export_ways = 'all_goods';
    } else if (key === EXPORT_SELECTED_BY_GOODS || key === EXPORT_SELECTED_BY_RESERVATION) {
      export_ways = key === EXPORT_SELECTED_BY_GOODS ? 'select_goods' : 'select';
      const selectArr = this.biPickOrder.leftTable.getStateSelecteRows?.();
      if (selectArr && selectArr.length > 0) {
        selectArr.forEach(i => {
          ids.push(i.id);
        });
        count = selectArr.length;
      } else {
        createTip('请选择要导出的数据！', ERROR).show();
        return;
      }
    }

    fnExportAll({
      req,
      url,
      pageTitle: tabName,
      count,
      export_ways,
      ids,
      exportAllCallback: () => {
        downLoadFile({ req: JSON.stringify({ ...req, format: 'csv' }) }, url);
      },
    });
  };
  leftTableExportSelect = () => {
    const tabParam = URI.parseQuery(window.location.search);
    let tabName = '左屏';
    if (tabParam.tabName) {
      tabName = `${tabParam.tabName}-左屏`;
    }
    this.tableExportSelect('left', tabName);
  };
  // 右侧button点击操作
  btnClickCallback = async (key, resData, selected, rightList, type, companyId, ...res) => {
    key === 'pick_refresh' && type === 'left' && this.refreshLeftTable(key, rightList);
    key === 'pick_refresh' && type === 'right' && this.refreshRightTable(key, rightList);
    key === 'show_pack' && type === 'left' && this.refreshPackOrOrder(key, true);
    key === 'show_order' && type === 'left' && this.refreshPackOrOrder(key, false);
    if (
      (key === EXPORT_ALL ||
        key === EXPORT_SELECTED_BY_RESERVATION ||
        key === EXPORT_ALL_BY_RESERVATION ||
        key === EXPORT_ALL_BY_GOODS ||
        key === EXPORT_SELECTED_BY_GOODS) &&
      type === 'left'
    ) {
      this.leftTableExportAll(key);
      return;
    }
    if (key === EXPORT_SELECTED && type === 'left') {
      this.leftTableExportSelect();
      return;
    }
    // 批次详情中的切换模板操作
    if (selected && selected.isSublistClick && isset(selected, 'menuBtnItemParent.key') === 'switch_tpl') {
      this.tableChangeTpl(selected, 'right');
      return;
    }
    const allData = {};
    // 费用信息
    if (this.feeTable) {
      const checkRes = this.feeTable.getTableData(true, {}, false);

      if (!checkRes.isPass) {
        createTip(formateTableCheckTips(checkRes), ERROR).show();
        return false;
      }
      allData.feeInfo = checkRes.data || [];
    } else if (this.state.feeInfo) {
      allData.feeInfo = (this.state.feeInfo || {}).data;
    }
    this.cardForm && (allData.cardInfo = this.cardForm.state.data.toJS());
    // 财务账单修改的时候，cardForm 没有 header里的项
    this.props.batchTitleInfo.isCardFormHeader !== 'hidden' &&
      this.cardForm &&
      this.cardForm.state.headerItem &&
      (allData.cardInfo = Object.assign(allData.cardInfo, this.cardForm.state.headerItem.toJS()));
    allData.tableInfo = resData;
    if (this.state.isPack || (this.state.packList && this.state.packList.length)) {
      // 如果是打包单，要将保存的打包单传过去
      allData.packList = this.state.packList;
    }
    const b_pack_link_ids_len = this.state.query.b_pack_link_ids && this.state.query.b_pack_link_ids.length;
    const right_data_len = this.state.tableList && this.state.tableList.right && this.state.tableList.right.data.length;
    // 右侧可能有不符合条件的
    const isSettle = this.state.tableFilter && this.state.tableFilter.type !== 'settle'; // 结算有可能点击保存按钮多次
    if (this.state.noSwitchPack && b_pack_link_ids_len > 0 && b_pack_link_ids_len === right_data_len && isSettle) {
      const packIds = [];
      this.state.query.b_pack_link_ids.forEach(i => {
        packIds.push({ b_link_id: i });
      });
      allData.packList = packIds;
    }
    allData.isPack = this.state.isPack;
    // 多网点支持时选择的网点
    if (companyId) {
      allData.checkedCompanyId = companyId;
    }
    // 当超过1000条时，需要从服务端判断是否跨网点，当 company_info.length > 1 时，即：跨网点了，并且需要弹出网点选择框
    if (this.props.isOverload && this.props.overloadInfo?.company_info?.length > 1 && key === 'save') {
      const checkedCompanyId = await crossCompany(this.props.overloadInfo?.company_info);
      if (!checkedCompanyId) {
        return createTip('请选择本次对账所属组织', ERROR);
      }
      allData.checkedCompanyId = checkedCompanyId;
    }
    const { orderArrList } = this.state;
    // 客户订单对账 收款按钮带到挑单页的数据里面只有com_id
    orderArrList?.forEach?.(item => {
      item.company_id = item.company_id || item.com_id;
    });
    allData.orderArrList = orderArrList;
    const keyArr = ['delivery', 'load', 'sign_complete', 'save'];
    if (keyArr.indexOf(key) > -1 && resData.list && resData.list.length > 0 && !resData.list[0].b_link_id) {
      // 右侧运单数据可能被编辑修改
      this.setState({ orderArrList: resData.list });
      allData.orderArrList = resData.list;
    }

    !allData.isPack &&
      allData.tableInfo?.list?.forEach?.((item, index) => {
        if (item?.od_link_split_info && allData?.orderArrList?.[index]) {
          allData.orderArrList[index].od_link_split_info = item.od_link_split_info;
        }
      });

    let validateInfo;
    if (this.taskLoad) {
      const validKey = ['pickup_v1', 'load_v1', 'delivery_v1'];
      if (validKey.includes(key)) {
        // 校验，未请求，所以btnkey 可以不传
        const valid = await this.taskLoad.save(null, null, null, { isValidating: true });
        if (!valid) return; // 校验未通过
      }
      // 传递ref 到下层，保存时，调用taskLoad的保存方法，把相关参数带给save，走创建任务的保存方法
      allData.taskLoadRef = this.taskLoad;
      validateInfo = { isPass: true };
    } else {
      validateInfo = this.cardForm?.validatePass?.();
    }
    if (this.state.showInvoiceInfo && key === 'save') {
      // 发票信息
      allData.invoicing_info = {};
      const data = this.state.innerInvoiceData;

      Object.keys(data).forEach(item => {
        allData.invoicing_info[item] =
          this.invoiceForm.form[item] === undefined ? data[item].otherProps.defaultValue : this.invoiceForm.form[item];
      });
    }
    if (typeof this.props.btnClickCallback === 'function') {
      this.props.btnClickCallback(key, allData, validateInfo, [...res, selected, this.state.tableList]);
    }
  };
  // 获取 费用明细 树与数据(这个接口有点怪：费用类型树与费用明细数据均包含在这个接口中)
  getFeeRelation = async ({ succussCallback } = {}) => {
    // const res = await postJAVA('/cmm-finance/fee/getFeeRelationTree', {});
    const res = await this.props.getFeeRelation?.();
    this._feeRelationAndFee = res;
    const feeDetailFlaten = getFeelListByFeeRelation(res);
    const oldBillAdustInfo = this.state.billAdustInfo;
    this.setState(
      {
        oriFeeCatigoryTree: res,
        // eslint-disable-next-line react/no-unused-state
        feeCatigory: _.cloneDeep(feeDetailFlaten), // 类型树
        billAdustInfo: {
          ...oldBillAdustInfo,
          data: feeDetailFlaten
            ?.map?.(item => ({
              amount: item.amount,
              fee_id: item.id,
              fee_type: item.type,
              expense: item.db_key,
              fee_direct: item.fee_direct,
              share_type: item.share_type,
              share_type_name: item.share_type_name,
            }))
            .filter?.(item => +item.fee_type == 17) // 只取 账单调整 费用项。
            .filter?.(item => item.amount || item.amount == 0), // 只取 有值的费用项
        },
      },
      () => succussCallback?.(),
    );
  };
  // 格式化费用明细，用于展示
  formatAmountDetailList = amountDetail => {
    const { checked = {}, totalList = {} } = amountDetail || (this.props.amountDetail ?? {});
    const { feeCatigory = [], oriFeeCatigoryTree = [] } = this.state;
    const pickExpense = this.props.expense;
    const list = Object.entries(totalList).map(([key, value]) => {
      const expense = feeCatigory.find(item => item.db_key === key);
      return {
        key,
        expense: pickExpense?.[key]?.text ?? expense?.fee_name ?? '', // 优先取pickOrderInfo.expense中的费用名称（可能含有别名）,否则取 费用类型树 中的名称
        type: expense?.category_name,
        value: !expense ? 0 : value, // 不在 费用类型树 中的数据不显示。
        showCheckbox: this.props.type !== 'show',
        checked: checked[key],
        disabled: ['账单调整', '账单调整合计'].includes(expense?.category_name) || this.props.type === 'show',
      };
    });

    // 费用类型排序。根据 费用类型树 获取字段及顺序，实际没在 费用类型树 中的费用也不会展示。
    const sort = oriFeeCatigoryTree?.map?.(item => item.category_name) ?? [];

    return list
      .map(item => {
        try {
          item.value = Big(item.value).toFixed(2);
        } catch {
          console.log('请输入数字');
        }
        return item;
      })
      .filter(item => item.value && item.value != 0) // 金额为 0 的费用，不显示
      .sort((a, b) => sort.findIndex(item => item === a.type) - sort.findIndex(item => item === b.type));
  };
  // 格式化费用信息合计，用于底部展示
  formateAmountDetailTotal = amountDetail => {
    const { checked = {}, totalList = {} } = amountDetail || (this.props.amountDetail ?? {});
    const { feeCatigory = [], oriFeeCatigoryTree = [] } = this.state;

    // 费用类型排序。根据 费用类型树 获取字段及顺序，实际没在 费用类型树 中的费用也不会展示。
    const expenseList = oriFeeCatigoryTree?.map?.(item => item.category_name) ?? [];

    const totalName = this.props.isReceiptPayment ? '报销总金额' : '对账总金额';

    expenseList.push(totalName);

    // 本地的 合计项 设置
    const summary_map = summary_header ?? {};

    const total_map = expenseList.reduce(
      (obj, key) => ({
        ...obj,
        [key]: {
          title: key.indexOf('合计') > 1 ? key : `${key || ''}合计`,
          color: '#222',
          value: 0,
          ...summary_map[key], // 使用 本地的合计设置，如果没有使用统一的通用设置
        },
      }),
      {},
    );

    Object.entries(totalList).forEach(([key, value]) => {
      const expense = feeCatigory.find(item => item.db_key === key);
      if (checked[key]) {
        // 总金额
        total_map[totalName] = {
          ...total_map[totalName],
          value: Big(total_map?.[totalName]?.value ?? 0)
            .plus(totalList[key] || 0) // plus 参数不能为空字符串
            .toFixed(2),
        };
        // 按费用类型计算合计
        if (expense) {
          const feeCategory = expense.category_name;
          total_map[feeCategory] = {
            color: '#222',
            title: feeCategory?.indexOf('合计') > 1 ? feeCategory : `${feeCategory || ''}合计`,
            ...total_map[feeCategory],
            value: Big(total_map?.[feeCategory]?.value ?? 0)
              .plus(totalList[key] || 0)
              .toFixed(2),
          };
        }
      }
    });

    if (!this.props.isBillAdjust) {
      delete summary_map['账单调整']; // 如果没有账单调整权限，则隐藏不显示
      delete total_map['账单调整'];
    }

    const summary = expenseList?.map?.(name => total_map[name]) ?? []; // 转换成数组形式

    return summary.filter(Boolean);
  };
  _updateAmountDetail = amountDetail => {
    const amountDetailForShow = _.cloneDeep(amountDetail ?? {});

    // 账单调整的费用项要单独处理，它不属于 运单 的费用项。
    const { totalList, checked } = analyzeFeeList(this.state.billAdustInfo?.data ?? []);
    amountDetailForShow.totalList = {
      ...amountDetailForShow?.totalList,
      ...totalList,
    };
    amountDetailForShow.checked = {
      ...amountDetailForShow?.checked,
      ...checked,
    };
    this.updateAmountDetail(amountDetailForShow);
  };
  // 挑单页 有更新时，重新计算合计与费用明细
  // 一般是由 BiPickerOrder 触发。但是，当 isOverload 场景时，不存在 BiPickerOrder ，因此需要主动触发。
  handleChangeAmount = tableInfo => {
    if (!this.props.isFiddle) return;
    const amountDetail = this.props.handleChangeAmount?.(this, { tableInfo }); // 让父组件重新调用 费用项合计 计算流程，产生新的费用项明细与合计值
    this._updateAmountDetail(amountDetail);
  };
  // 费用明细复选框事件
  handleCheckAmountDetailExpense = ({ col, record, index, checked, e, data }) => {
    const amountDetail = this.props.handleCheckAmountDetailExpense?.({ col, record, index, checked, e, data });
    // 费用明细勾选要联动合计值
    this._updateAmountDetail(amountDetail);
  };
  // 费用明细全选事件
  handleAmountDetailChangeAll = (key, checked) => {
    const amountDetail = this.props.handleAmountDetailChangeAll?.({ key, checked });
    // 费用明细勾选要联动合计值
    this._updateAmountDetail(amountDetail);
  };
  // 更新费用明细组件的值（需要延迟调用，保证拿到ref；同时也防止频繁调用）
  updateAmountDetail = _.debounce(amountDetail => {
    // 主动设置data，避免循环调用
    const list = this.formatAmountDetailList(amountDetail);
    const summary = this.formateAmountDetailTotal(amountDetail);
    this.amountDetailListRef?.setData?.(list);
    this.amountDetailSummaryRef?.setSummary?.(summary);
    this.updateSummary({ total: summary?.find?.(item => item.key === 'dzzje' || item.key === 'bxzje')?.value });

    const checkTotalInfo = summary.find(item => item.key === 'dzzje');
    const checkTotalAmount = checkTotalInfo?.value;
    // 对账总金额值是否发生变更
    if (Number(checkTotalAmount) !== Number(this.beforeCheckTotalAmount)) {
      this.beforeCheckTotalAmount = Number(checkTotalAmount);
      this.props.onUpdateCheckTotakAmount?.(checkTotalAmount);
    }
  });
  // 获取 自定义显示 按钮。
  getCustomButtons = () => {
    // 只取 right 中的固定按钮。isOverload（超1000）条时，前端固定写死
    const buttons = this.props.isOverload
      ? {
          save: {
            type: 'ButtonMenu',
            eventNames: ['onClick'],
            children: '保存',
            iconType: '',
            dynamic: 'false',
            showKey: 'title',
            uniqueKey: 'key',
            display: this.props.type !== 'show',
            postion: 'custom',
            btnType: 'primary',
            sublist: [],
          },
        }
      : this.state.tableList?.right?.buttons ?? {};
    const btns = {
      ...buttons,
    };
    const btnKeys = Object.keys(buttons);
    // 只保留 自定义按钮
    btnKeys.forEach(key => {
      if (btns[key]?.postion !== 'custom') delete btns[key];
    });
    return btns;
  };
  // 自定义按钮 事件
  handleCustomBtnClick = (type, selected, key, subList = [], ...res) => {
    if (this.props.isOverload) {
      this.btnClickCallback(key, {}, selected, null, type, '', ...res); // 自定义按钮事件
    } else {
      this.biPickOrder?.onBtnClick?.(type, selected, key, subList, ...res); // 模拟触发 biPickOrder 底层的按钮事件。
    }
  };
  /*
   设置右侧表格数据
  */
  setRightTableData = data => {
    this.biPickOrder && this.biPickOrder.resetTableData?.('right', data, true);
  };
  /*
   * 右侧表格header中的input和Text的类型切换
   */
  setRightTableHeader = (paymentList, type) => {
    const table = this.biPickOrder.rightTable;
    table.changeHeaderProps('type', type, paymentList);
  };
  /* ************************************* 费用表格操作(start) *************************************** */
  // 费用表格的可编辑状态切换
  changeFeeStatus = status => {
    const type = status ? 'Text' : 'Input';
    this.feeTable &&
      this.feeTable.changeHeaderProps('type', type, ['b_receipt_f', 'b_arr_f', 'b_rmt_landing_f', 'plan_arr_t']);
    if (!status) {
      // 预计到达时间为 日期类型
      this.feeTable && this.feeTable.changeHeaderProps('type', 'DateTime', ['plan_arr_t']);
    }
  };
  // 设置费用信息
  setFeeInfo = (feeInfo, flushHeight = true) => {
    const data = feeInfo.data || [];
    const feeHeight = PICK_TABLE_ROW_HEIGHT * data.length + PICK_TABLE_HEADER_HEIGHT;
    this.setState({ feeHeight, feeInfo: Object.assign({}, feeInfo) });
    // throttle(this.handleResize, 10)()
    // 更新table的高度信息
    if (flushHeight) {
      throttle(() => {
        this.biPickOrder && this.biPickOrder.changeWidthCallback?.();
      }, 50)();
    }
  };
  // 修改费用修改信息
  setFeeData = feeData => {
    const { feeInfo } = this.state;
    feeInfo.data = feeData;
    this.setFeeInfo(feeInfo);
  };
  // 修改费用表头
  setFeeHeader = header => {
    const { feeInfo } = this.state;
    feeInfo.header = header;
    this.setState({ feeInfo: Object.assign({}, feeInfo) });
  };
  handleTodoSubtract = (...res) => {
    this.updateSummary();

    if (typeof this.props.handleTodoSubtract === 'function') {
      this.props.handleTodoSubtract(res[0], Object.assign({}, this.state.feeInfo));
    }
  };
  handleTodoAdd = () => {
    this.updateSummary();

    if (typeof this.props.handleTodoAdd === 'function') {
      this.props.handleTodoAdd(Object.assign({}, this.state.feeInfo));
    }
  };
  // checkbox 改变时触发
  handleCheckBoxChange = (key, value, form, data) => {
    typeof this.props.handleCheckBoxChange === 'function' && this.props.handleCheckBoxChange(key, value, form, data);
  };
  /* ************************************* 费用表格操作(end) *************************************** */
  /* ************************************* 对筛选条件的处理(start) *************************************** */
  // 设置路由筛选, 是否更新数据
  setRouteFilter = (routeItem, refreshData = false) => {
    if (!typeIs(routeItem, 'array')) return false;
    let route = filterListColumnByKey(routeItem, 'company_id');
    // 车辆线路节点可能没有company_id(比如地址节点)需要过滤掉
    route = route.filter(i => i);
    const routeStr = route.join(' ') || '';
    const curComIndex = route.findIndex(item => +item === +window.company_id);
    route = route.splice(curComIndex, 2);
    const query = this.getTableQuery();
    const leftQuery = query.left.query || {};
    // 只剩下一个节点的情况，不拼此查询条件
    if (route.length > 1) {
      leftQuery[1] = {
        _logic: 'OR',
        0: {
          route_arr: [''],
          arr_point: routeStr.split(' '),
        },
        1: {
          0: {
            _logic: 'not',
            route_arr: [''],
          },
          route_arr: [route.join(' ')],
        },
      };
    } else {
      delete leftQuery[1];
    }
    query.left.query = leftQuery;
    this.setState({ query });
    // 更新左侧数据
    if (refreshData) {
      this.refreshTableData('left', query.left);
    }
  };
  /* ************************************* 对筛选条件的处理(end) ******************************************** */
  /* ************************************* 顶部cardForm的处理(start) *************************************** */
  getLineInfo = comIds =>
    new Promise((reslove, reject) => {
      const nodestream = [...comIds];
      const url = '/Basic/Line/lineSug';
      const param = {
        method: 'POST',
        body: {
          req: {
            company_id: comIds[0],
            query: '',
            tail_company_id: comIds.pop(),
            line_nodes_num: nodestream.length,
            nodestream,
          },
        },
      };
      fetchApi(url, param)
        .then(res => {
          if (res.errno !== 0) {
            if (+res.errno === ERR_DUPLICATE_REQUEST) return;
            createTip(res.errmsg || '获取数据失败', WARN).show();
            reject(res);
          }
          reslove(res);
        })
        .catch(err => reject(err));
    });
  isNode = (item, index) => {
    if (!((item.node_name && item.node_type) || (item.company_id && item.company_name))) {
      return false;
    }

    if (item.node_address && item.node_address.poi) {
      return { poi: item.node_address.poi, index };
    }
    if (item.nodeAddress && item.nodeAddress.poi) {
      return { poi: item.nodeAddress.poi, index };
    }
    return false;
  };
  getDistance = curPath => window.calDistance(curPath).then(({ distance }) => Math.round(distance / 1000));

  calcDistance = async (nodes, force) => {
    const _self = this;
    const path = nodes.map((item, index) => _self.isNode(item, index)).filter(item => item && item.poi);
    const miles = [];
    let _line = 0;
    if (path.length > 1) {
      for (let i = 0; i < path.length; i++) {
        const nextItem = path[i + 1];
        if (nextItem) {
          const curPath = [path[i].poi, nextItem.poi];
          const mile = await this.getDistance(curPath);
          miles[path[i].index] = mile;
          _line += mile;
        }
      }
    }
    const feeInfo = { ...this.state.feeInfo };
    feeInfo.data = feeInfo.data.map((item, i) => ({ ...item, b_kilometers: miles[i] }));
    const data = _.cloneDeep(this.state.cardFormData);
    data.line_mile.otherProps.value = _line;
    this.setState({ cardFormData: data, feeInfo });
    return _line;
  };
  // 添加路由回调(写在此处方便更新状态)
  handleRouteChange = async (routeItem, form, val, type) => {
    const data = val;
    const route = form.route && form.route[0];
    if (!typeIs(routeItem, 'array')) return;
    if (!routeItem[0]) return;
    const feeInfo = { ...this.state.feeInfo };
    const feeList = [];
    const feeTmpDict = {};
    const feeData = feeInfo.data || [];
    feeData.forEach(item => {
      const id = item.company_id;
      if (id) {
        feeTmpDict[id] = { ...item };
        if (item.otherProps) {
          const disableAry = [...(item.otherProps.disable || [])].filter(
            key => !['b_kilometers', 'section_time', 'section_hour', 'actual_mile'].includes(key),
          );
          if (type !== 'custom') {
            disableAry.push('b_kilometers', 'section_time');
          }
          const newDisableArr = [...new Set(disableAry)];
          feeTmpDict[id].disable = newDisableArr;
          feeTmpDict[id].otherProps.disable = newDisableArr;
        }
      }
    });
    const expenseSetting = this.props.batchInfo.expense_setting || {}; // 费用信息的 系统设置

    let firstNodeIsAddress = false;
    routeItem.forEach((item, index) => {
      const isAddress = +item.node_type === 7 || +item.node_type === 8;
      const disabled = [];
      if (type !== 'custom') {
        disabled.push('b_kilometers', 'section_time');
      }
      if (index === 0 && isAddress) {
        firstNodeIsAddress = true;
        // 如果线路第一个是地址，那么费用table第一个就是之前的第一行数据
        feeList.push(_.get(this, 'props.feeInfo.data[0]', {}));
      }

      const comId = item && item.company_id;
      const comName = item && (item.company_name || item.name || item.node_name);
      const nodeDistance = item && item.node_distance;
      const nodeHour = item && item.node_hour;
      const nodeRemark =
        type === 'custom'
          ? (feeData[index] && feeData[index].node_remark) || (item && item.node_remark)
          : item && item.node_remark; // 节点备注
      if (+comId === -1) return;
      if (comId && feeTmpDict[comId]) {
        feeList.push({
          ...feeTmpDict[comId],
          b_kilometers: nodeDistance,
          section_time: nodeHour,
          node_remark: nodeRemark,
        });
      } else {
        const bRmtUnloadFPaid = expenseSetting.b_rmt_unload_f && expenseSetting.b_rmt_unload_f.default;
        const bRmtMiscFPaid = expenseSetting.b_rmt_misc_f && expenseSetting.b_rmt_misc_f.default;
        const node = {
          company_name: comName,
          b_kilometers: nodeDistance,
          section_time: nodeHour,
          node_remark: nodeRemark,
          otherProps: fromJS(config.feeInfo.otherProps).toJS(),
        };
        node.otherProps.disable.push(...disabled);
        if (isAddress) {
          node.unique_id = item.node_address && item.node_address.poi;
          node.otherProps.disable.push('b_arr_f');
        } else {
          node.unique_id = comId;
          node.company_id = comId;
          node.b_rmt_unload_f_paid = bRmtUnloadFPaid;
          node.b_rmt_misc_f_paid = bRmtMiscFPaid;
        }
        feeList.push(node);
      }
    });
    if (feeInfo.header && feeInfo.header.b_kilometers && feeList.length) {
      const lastRowOtherProps = feeList[feeList.length - 1].otherProps || {};
      const lastDisable = lastRowOtherProps.disable || [];
      const finalDisable = [
        ...new Set([...lastDisable, 'b_kilometers', 'actual_mile', 'section_hour', 'section_time']),
      ];
      feeList[feeList.length - 1].disable = [...finalDisable];
      if (feeList[feeList.length - 1].otherProps) feeList[feeList.length - 1].otherProps.disable = [...finalDisable];
    }
    // 当节点个数大于5个时，第一个节点备注不可编辑
    if (feeList.length && feeList.length > 5) {
      const feeList0Disable = _.get(feeList[0], 'otherProps.disable', []);
      _.set(feeList, '0.otherProps.disable', [...feeList0Disable, 'node_remark']);
    } else {
      let feeList0Disable = _.get(feeList[0], 'otherProps.disable', []);
      feeList0Disable = feeList0Disable.filter(item => item !== 'node_remark');
      _.set(feeList, '0.otherProps.disable', feeList0Disable);
    }
    let feeHeight;
    if (feeInfo) {
      feeInfo.data = feeList;
      feeHeight = PICK_TABLE_ROW_HEIGHT * feeInfo.data.length + PICK_TABLE_HEADER_HEIGHT;
    }

    const showKilometers =
      feeInfo && feeInfo.header && feeInfo.header.b_kilometers && feeInfo.header.b_kilometers.display === 'show';

    if (type === 'custom' && (this.needCalcTruckPrice || showKilometers)) {
      const comArr = [];
      routeItem.forEach(x => {
        if (x.company_id) comArr.push(x.company_id);
      });
      if (comArr.length > 2) {
        const lineRes = await this.getLineInfo(comArr);
        if (lineRes.errno === 0 && lineRes.res && lineRes.res.length > 0) {
          const curLine = lineRes.res && lineRes.res.length > 0 ? lineRes.res.pop() : {};
          const lineNodes = curLine.line_nodes ? curLine.line_nodes : {};
          if (showKilometers) {
            lineNodes.forEach((item, index) => {
              feeList[index].b_kilometers = item.node_distance;
            });
          }
          this.props.handleSelectChange('routeInfo', curLine, form, data);
        }
      }
    }

    if (type !== 'custom' && route) {
      if (data.line_mile) {
        data.line_mile.otherProps.value = route.line_distance || '';
      }
      if (data.line_time) {
        data.line_time.otherProps.value = route.line_hour || '';
      }
    }
    if (data.line_mile && type === 'custom' && routeItem.length > 1) {
      const routeNodes = [...routeItem];
      if (firstNodeIsAddress) {
        routeNodes.unshift({ node_address: JSON.parse(window.company_info.address) });
      }
      this.calcDistance(routeNodes);
    }

    this.setState({
      feeInfo,
      feeHeight,
      cardFormData: data,
      cardFormTitle: this.cardForm ? this.cardForm.state.headerItem : this.state.cardFormTitle,
    });
    // 更新table的高度信息
    throttle(() => {
      this.biPickOrder && this.biPickOrder.changeWidthCallback?.();
    }, 10)();
    // 路由改变筛选
    if (this.state.routeChangeLeft) {
      this.setRouteFilter(routeItem, true);
    }
  };
  handleInputChange = (key, value, form, data) => {
    this.isEdit = true;
    const newValue = value;
    if (key === 'route') {
      this.handleRouteChange(newValue, form, data, 'custom');
    }
    if (typeof this.props.handleInputChange === 'function') {
      const type = 'right';
      const allData = {};
      this.cardForm && (allData.cardInfo = this.cardForm.state.data.toJS());
      // this.props.batchTitleInfo.isCardFormHeader !== 'hidden' && this.cardForm && this.cardForm.state.headerItem && (allData.cardInfo = Object.assign(allData.cardInfo, this.cardForm.state.headerItem.toJS()))
      if (this.props.batchTitleInfo.isCardFormHeader !== 'hidden' && this.cardForm && this.cardForm.state.headerItem) {
        allData.cardHeader = this.cardForm.state.headerItem.toJS();
      }
      const list = this.biPickOrder?.[`${type}Table`]?.getStateCache?.(true);
      const { header } = this.biPickOrder?.props?.[`${type}List`] ?? {};
      allData.tableInfo = { list, header };
      this.props.handleInputChange(key, newValue, this.cardForm, allData);
    }
  };
  handleInputBlur = (key, value, form, data) => {
    this.isEdit = true;
    const newValue = value;
    // 如果是单号类型的进行blur 验证
    if (data[key].blurvalidcheck) {
      let label = (data[key].labelProps || {}).children;
      label !== undefined && (label = label.replace(':', ''));
      const res = this.cardForm.validateCheckItem(key, value, 'headerItem', label);
      if (!res.isPass) {
        showInputCheckDialog({ ...res, msg: `${label}${res.msg}` });
        Object.assign(data, { [key]: setComVal(data[key], regularCorrect(value)) });
        this.cardForm.setHeaderData(data);
        return;
      }
    }
    if (typeof this.props.handleInputBlur === 'function') {
      const type = 'right';
      const allData = {};
      this.cardForm && (allData.cardInfo = this.cardForm.state.data.toJS());
      // this.props.batchTitleInfo.isCardFormHeader !== 'hidden' && this.cardForm && this.cardForm.state.headerItem && (allData.cardInfo = Object.assign(allData.cardInfo, this.cardForm.state.headerItem.toJS()))
      if (this.props.batchTitleInfo.isCardFormHeader !== 'hidden' && this.cardForm && this.cardForm.state.headerItem) {
        allData.cardHeader = this.cardForm.state.headerItem.toJS();
      }
      let list = [];
      if (this.biPickOrder?.[`${type}Table`]) {
        list = this.biPickOrder[`${type}Table`].getStateCache?.(true);
      }
      let header = [];
      if (this?.biPickOrder?.props?.[`${type}List`]) {
        header = this.biPickOrder.props[`${type}List`].header;
      }
      allData.tableInfo = { list, header };
      this.props.handleInputBlur(key, newValue, this.cardForm, allData);
    }
  };
  commonTitleHandle = (callBackName, key, value, form, data) => {
    if (typeof this.props[callBackName] === 'function') {
      this.props[callBackName](key, form, data, value);
    }
  };
  // 此处注意： onFocus 和 chebox的change需要改回调参数
  handleSelectChange = async (key, _value, form, data) => {
    this.isEdit = true;
    let newData = data; // , valueObj = value || []
    const originData = data[key];
    const { type } = originData;
    const { attrInfo } = originData.otherProps;
    let value = [..._value];
    const sugPayee = value[0] && value[0].payee;
    const sugPayeeSet = !sugPayee || sugPayee.length === 1;
    if (Array.isArray(sugPayee) && !sugPayeeSet && sugPayee) {
      delete value[0].payee;
    }
    if (type === 'SelectDrop' && attrInfo) {
      value = shouldWvFunc(value, data);
      newData = selectResetCardFormData(data, attrInfo, value);

      // 创建付款单，收款单位sug
      if (key === 'opposite_name') {
        const arr = [
          'bank_name',
          'account_bank',
          'bank_num',
          'account_holder',
          'wechat_no',
          'alipay_no',
          'check_no',
          'money_card_no',
          'oil_card_no',
          'other',
        ];
        for (const item of arr) {
          if (newData[item] && newData[item].toJS) newData[item] = newData[item].toJS();
          if (newData[item]) newData[item].labelProps.isRequired = false;
        }
        // const relatedArr = value[0].pay_method_sug || []
        const relatedArr = _.get(value, '[0].pay_method_sug', []);
        for (const item of relatedArr) {
          if (item.related) {
            for (const rel of item.related) {
              newData[rel].labelProps.isRequired = true;
            }
          }
        }
        if (value?.[0]?.bill_id) {
          await this.getInvoiceInfo(value[0].bill_id, true);
        }
      }

      // 配载，送货，提货，短驳批次承运商
      if (key === 'b_tr_team_name' && value.length === 0) {
        newData.b_tr_team_type.otherProps.defaultSelected = '';
        newData.b_tr_team_id.otherProps.defaultSelected = '';
      }

      const header = this.cardForm.getStateHeader();
      this.setState({
        cardFormData: newData,
        cardFormTitle: header,
      });
    }
    if (
      key === 'carrier_id' &&
      this.state.query.tab === 'trans_point_pick' &&
      this.state.feeInfo &&
      this.state.feeInfo.data &&
      value &&
      value[0]
    ) {
      const comData = value[0];
      const comList = [];
      comList.push(this.state.feeInfo.data[0]);
      comData.company_id = comData.id;
      comList.push(comData);
      this.handleRouteChange(comList, form, data, 'custom');
    }
    if (key === 'route') {
      if (typeIs(value, 'array') && value[0] && value[0].id) {
        this.handleRouteChange(value[0].line_nodes, form, data, 'line');
      }
    }
    if (key === 'pay_method' && typeIs(value, 'array')) {
      const arr = [
        'bank_name',
        'account_bank',
        'bank_num',
        'account_holder',
        'wechat_no',
        'alipay_no',
        'check_no',
        'money_card_no',
        'oil_card_no',
        'other',
      ];
      for (const item of arr) {
        newData[item].labelProps.isRequired = false;
      }
      for (const item of value) {
        for (const rel of item.related) {
          newData[rel].labelProps.isRequired = true;
        }
      }
      this.setState({
        cardFormData: newData,
      });
    }
    if (typeof this.props.handleSelectChange === 'function') {
      let right = [];
      this.biPickOrder && (right = this.biPickOrder.getTableData?.('right'));
      this.props.handleSelectChange(key, value, form, data, right);
    }
  };
  setCardFormData = (newData, newHeader) => {
    const header = newHeader || this.cardForm.getStateHeader();
    if (newHeader && this.cardForm) {
      this.cardForm.setHeaderData(newHeader);
    }
    this.setState({ cardFormTitle: header, cardFormData: newData });
  };
  getCardFormData = (key = 'data') => this.cardForm && this.cardForm.state[key].toJS();
  setCardFormItemValue = (key, value) => {
    const header = this.cardForm.getStateHeader();
    header[key] = setComVal(header[key], value).toJS();
    this.cardForm &&
      this.cardForm.changeState({ headerItem: fromJS(header) }, () => {
        this.setState({ cardFormTitle: header });
      });
  };
  // 获取cookie中记录的状态
  getShowValue = (pageState = 'show') => {
    let showBody = true; // 默认不显示body内容
    if (getCookie(this.getCookieName()) === 'false') showBody = false;
    // 编辑状态全部为显示body
    if (pageState !== 'show') showBody = true;
    return showBody;
  };
  handleFeeTableBlur = (rowIndex, col, value) => {
    this.updateSummary();
    if (typeof this.props.handleFeeTableBlur === 'function') {
      const feeData = this.feeTable ? this.feeTable.getTableData() : this.state.feeInfo;
      this.props.handleFeeTableBlur(feeData, rowIndex, col, value, this.state.feeInfo);
    }
  };
  handleFeeTableChange = (rowIndex, columnKey, value) => {
    this.isEdit = true;
    this.updateSummary();

    if (typeof this.props.handleFeeTableChange === 'function') {
      this.props.handleFeeTableChange(this.state.feeInfo, { rowIndex, columnKey, value });
    }
  };
  handleFeeTableSelectDropSelect = (rowIndex, col, val) => {
    this.isEdit = true;
    this.updateSummary();

    if (typeof this.props.handleFeeTableSelectDropSelect === 'function') {
      this.props.handleFeeTableSelectDropSelect(
        this.state.feeInfo,
        rowIndex,
        col,
        val,
        this.feeTable.getStateCache(true),
      );
    }
  };
  // 左右屏的运单列表发生变化
  resetNewQueryList = newRightList => {
    const query = this.state.query || {};
    const batchTableInfo = this.props.batchTableInfo || {};
    let uniqueKey = batchTableInfo.uniqueKey || 'od_link_id';
    this.state.isPack && (uniqueKey = 'b_link_id');
    let uniqueExtraKey = batchTableInfo.uniqueExtraKey || '';
    // 员工交账使用uniqueInKey
    const uniqueInKey = batchTableInfo.uniqueInKey || '';
    let addedKey = batchTableInfo.addedKey || [];

    /**
     * bugID = 40861
     * 先判断tab是否是：payment_pickup_f、payment_od_delivery_f、settle_pickup_f、settle_od_delivery_f
     * 若不是则使用原逻辑，若是，则在修改挑单分页时构造参数如下：
     * 遍历挑单右屏数据，
     * 1、若split_index>0，若tab为payment_pickup_f、settle_pickup_f 时读取 b_pickup_batch_id
     * 若tab为 payment_od_delivery_f、settle_od_delivery_f 时读取 b_delivery_batch_id 字段，且该字段不为0时，构造该行参数：
     * [
     *   'od_link_id':123,
     *   'b_pickup_batch_id':111
     * ],
     *
     * 2、若split_index=0，或 b_pickup_batch_id=0 则该行参数放到 od_link_id:[124] 最后参数：
     * [
     *   [
     *     'od_link_id':123,
     *     'b_pickup_batch_id':111
     *   ],
     *   [
     *     'od_link_id':123,
     *     'b_pickup_batch_id':112
     *   ],
     *   [
     *     'od_link_id':[124,125],
     *   ],
     * ]
     */
    if (query.tab === 'payment_pickup_f' || query.tab === 'settle_pickup_f') {
      uniqueExtraKey = 'b_pickup_batch_id';
      addedKey = ['split_index'];
    } else if (query.tab === 'payment_od_delivery_f' || query.tab === 'settle_od_delivery_f') {
      uniqueExtraKey = 'b_delivery_batch_id';
      addedKey = ['split_index'];
    }

    let odLinkId = filterListColumnByKey(newRightList, uniqueKey, uniqueExtraKey, addedKey);

    const leftQuery = (query.left || {}).query || [];
    const rightQuery = (query.right || {}).query || [];
    // 右侧有空时， 左筛选的条件置为空
    leftQuery['0'] = leftQuery['0'] || {};
    const genKey = uniqueInKey || uniqueKey;
    if (
      query.tab === 'payment_pickup_f' ||
      query.tab === 'settle_pickup_f' ||
      query.tab === 'payment_od_delivery_f' ||
      query.tab === 'settle_od_delivery_f'
    ) {
      const linkIds = [];
      const linkIdObj = [];
      odLinkId.forEach(x => {
        if (x.od_link_id === null || x.od_link_id === undefined) {
          return;
        }
        if (!+x.split_index || !x.b_pickup_batch_id) {
          linkIds.push(x.od_link_id);
        } else {
          linkIdObj.push({ od_link_id: x.od_link_id, b_pickup_batch_id: x.b_pickup_batch_id });
        }
      });
      odLinkId = [{ od_link_id: linkIds }, ...linkIdObj];
      leftQuery['0'] = { _logic: 'NOT', ...odLinkId };
      odLinkId.length && (rightQuery[0] = odLinkId);
    } else {
      odLinkId = odLinkId.filter(x => x !== null && x !== undefined);
      leftQuery['0'] = Object.assign(leftQuery['0'], { _logic: 'NOT', [genKey]: odLinkId });
      odLinkId.length && (rightQuery[genKey] = odLinkId);
    }
    !odLinkId.length && genKey !== 'link_info' && (rightQuery[genKey] = -1);
    query.right.query = rightQuery;
    query.left.query = leftQuery;
    return query;
  };
  handleLeftToRightCallback = (changeList, newLeftList, newRightList) => {
    // 初始化时的第一次挑单不算修改
    if (changeList !== newRightList) {
      this.isEdit = true;
    }
    if (newLeftList === undefined || newRightList === undefined) return;
    const b_pack_ids = this.state.query && this.state.query.b_pack_link_ids;
    if (!this.state.isPack && b_pack_ids && b_pack_ids.length && !this.isEdit) {
      const packIds = [];
      b_pack_ids.forEach(i => {
        packIds.push({ b_link_id: i });
      });
      this.setState({ packList: packIds });
    }
    const setType = this.state.isPack ? 'packNum' : 'orderNum';
    const listArrType = this.state.isPack ? 'packList' : 'orderArrList';
    this.setState({
      query: this.resetNewQueryList(newRightList),
      [setType]: newRightList.length,
      [listArrType]: newRightList,
    });

    if (typeof this.props.handleLeftToRightCallback === 'function') {
      const type = 'right';
      let header = {};
      if (this?.biPickOrder?.props?.[`${type}List`]) {
        header = this.biPickOrder.props[`${type}List`].header;
      }
      Promise.resolve().then(() =>
        this.props.handleLeftToRightCallback(
          changeList,
          newLeftList,
          newRightList,
          header,
          this.cardForm,
          this.isEdit,
          this.taskLoad,
          {
            taskLoadWrap: this.taskLoadWrap,
          },
        ),
      );
    }
  };
  handleRightToLeftCallback = (changeList, newLeftList, newRightList) => {
    this.isEdit = true;
    if (newLeftList === undefined || newRightList === undefined) return;
    this.setState({
      query: this.resetNewQueryList(newRightList),
    });
    const setType = this.state.isPack ? 'packNum' : 'orderNum';
    const listArrType = this.state.isPack ? 'packList' : 'orderArrList';
    this.setState({
      query: this.resetNewQueryList(newRightList),
      [setType]: newRightList.length,
      [listArrType]: newRightList,
    });
    if (this.state.isPack) {
      this.setState({ rightClear: !newRightList.length });
    }
    if (typeof this.props.handleRightToLeftCallback === 'function') {
      const type = 'right';
      let header = {};
      if (this?.biPickOrder?.props?.[`${type}List`]) {
        header = this.biPickOrder.props[`${type}List`].header;
      }
      this.props.handleRightToLeftCallback(
        changeList,
        newLeftList,
        newRightList,
        header,
        this.cardForm,
        this.isEdit,
        this.taskLoad,
        {
          taskLoadWrap: this.taskLoadWrap,
        },
      );
    }
  };
  handleBiTableChange = (rowIndex, col, value, resData, key) => {
    this.isEdit = true;
    if (typeof this.props.handleBiTableChange === 'function') {
      const type = 'right';
      let header = {};
      if (this?.biPickOrder?.props?.[`${type}List`]) {
        header = this.biPickOrder.props[`${type}List`].header;
      }
      const listData = Array.isArray(resData) ? resData : resData.list;
      if (priceConfig[col] && listData[rowIndex].od_link_id && this.useJAVAPrice) {
        if (!listData[rowIndex].__priceInfo) {
          listData[rowIndex].__priceInfo = {
            delete_fees: {},
            process_snapshot: { type: 'yd', biz_id: listData[rowIndex].od_link_id },
          };
        }
        if (+value !== +_.get(listData[rowIndex].__priceInfo, ['price', listData[rowIndex].__priceInfo.type, col])) {
          listData[rowIndex].__priceInfo.delete_fees[col] = 1;
        }
      }
      const item = this.props.handleBiTableChange(
        rowIndex,
        col,
        value,
        resData,
        key,
        header,
        this.cardForm,
        this.isEdit,
        this.taskLoad,
        {
          taskLoadWrap: this.taskLoadWrap,
        },
      );
      return item;
    }
  };
  // 根据cookie中值，显示或者折叠批次详情
  getCookieName = () => {
    const categoryInfo = this.getCategoryInfo();
    return `${categoryInfo.category}_${categoryInfo.tab}_${window.user_id}`;
  };
  setCookie = state => {
    const timeout = new Date();
    timeout.setDate(timeout.getDate() + 365);
    setCookie(this.getCookieName(), state, timeout, '/');
  };
  // cardFrom 折叠时调用的方法
  cardFormBodyHideCallBack = (height, state) => {
    if (this.feeTable) {
      if (height >= 0) {
        this.feeTableWrap.style.height = '0px';
        this.feeTableWrap.style.maxWidth = '0px';
        this.feeTableWrap.style.padding = '0px';
        // this.invoiceFormWrap.style.height = '0px';
      }
      if (height <= 0) {
        this.feeTableWrap.style.height = '';
        this.feeTableWrap.style.maxWidth = '';
        this.feeTableWrap.style.padding = '';
        // this.invoiceFormWrap.style.height = '';
      }
    }

    this.setCookie(state);
    const { header } = this.getHeaderData(this.state.type, state);
    this.cardForm && this.cardForm.setHeaderData(header);
    // 更新挑单table的高度
    throttle(() => {
      this.biPickOrder && this.biPickOrder.changeWidthCallback?.();
    }, 10)();
  };
  // cardForm 获取header数据
  getHeaderData = (pageState, bodyShowState) => {
    const type = pageState || this.state.type;
    let header = this.state.cardFormTitle;
    const data = this.state.cardFormData;
    const headerHideKey = this.props.headerHideKey || [];
    const headerEditKey = this.props.headerEditKey || [];
    const bodyEditKey = this.props.bodyEditKey || [];
    const showBody = bodyShowState || this.getShowValue(type);
    if (this.cardForm) {
      header = this.cardForm.getStateHeader() || header;
    }
    // eslint-disable-next-line guard-for-in
    for (const item in data) {
      if (data[item].type && data[item].type === 'Ccombiner') {
        const ccData = data[item].otherProps.data;
        for (const subCurr in ccData) {
          if (ccData[subCurr].type === 'Button') {
            const subCurrOtherP = data[item].otherProps.data[subCurr].otherProps;
            data[item].otherProps.data[subCurr].otherProps = { ...subCurrOtherP, display: type !== 'show' };
          }
        }
      }
      if (data[item].type && data[item].type === 'Button') {
        data[item].otherProps.display = type !== 'show';
      }

      const canAddAccountHolder =
        ['customer_batch_team', 'customer_batch_driver', 'customer_batch_car'].includes(this.props.reqInfo?.type) ||
        ['payment_batch_onway_enterpris'].includes(this.props.reqInfo?.tab); // CTRRD-3561 在途费用创建报销单
      /**
       * 客户、司机、承运商 对账单，客户开户人 支持 + 号添加收款人
       */
      if (item === 'account_holder' && canAddAccountHolder && this.props.type === 'add') {
        data[item] = handleAccountHolderForSelect(this, data);
      }
      if (['dr_name', 'dr_phone', 'tr_num', 'truck_num'].includes(item)) {
        // 新增配载、短驳、提/送货  司机车辆关联收款人
        data[item] = handleCardFormDataForSelectRender({ key: item, value: data[item] });
      }
      const labelStr = data[item].labelProps && data[item].labelProps.children;
      if (labelStr && labelStr.length >= 6) {
        data[item].labelProps.classname = `${data[item].labelProps.classname} len-${labelStr.length}`;
      }
    }
    // 此处用于批次详情页点开修改时的操作
    bodyEditKey.forEach(item => {
      if (type === 'show' && !header[item] && data[item]) {
        header[item] = data[item];
        header[item].labelProps && (header[item].labelProps.showColon = true);
        delete data[item];
      }
      if (type !== 'show' && header[item]) {
        if (!data.route && header[item]) {
          data[item] = header[item];
          data[item].labelProps && (data[item].labelProps.showColon = false);
        }
        delete header[item];
      }
    });
    // 头部中只有编辑的状态下显示的字段
    headerEditKey.forEach(item => {
      header[item] && (header[item].display = type !== 'show');
    });
    // 处理折叠的情况下，在header中显示的字段
    headerHideKey.forEach(key => {
      if (!header[key] && !data[key]) return;
      if (showBody === false && type === 'show') {
        header[key] = data[key];
      } else {
        delete header[key];
      }
    });
    return { header, data };
  };
  /* ************************************* 顶部cardForm的处理(end) *************************************** */
  cellContentGetter = prop => {
    const { rowIndex, data, columnKey, tableKey, dataPathIndex, headerData, mode } = prop;
    // 使用header中的信息或者数据中的信息
    const dataItem = data.getObjectAt(rowIndex);
    // 此处，table的行信息中不维护Ccombiner的类型信息, 在header中进行维护
    const otherProps = dataItem.otherProps || {};
    if (headerData) {
      Object.keys(headerData).forEach(key => {
        if (otherProps[key] && otherProps[key].type) {
          headerData[key].type = otherProps[key].type;
        }
        headerData[key] = setComVal(headerData[key], dataItem[key]).toJS();
        const disabled = false;
        headerData[key] = setComDisable(headerData[key], disabled).toJS();
      });
    }
    const combinerProps = {
      mode,
      data: Object.assign({}, headerData),
      dataPathIndex,
      dataPath: `${tableKey}_${columnKey}_${rowIndex}`,
      handleEvents: prop.handleEvents,
    };
    return <Ccombiner {...combinerProps} />;
  };
  // 获取 账单调整 费用名称 下拉
  getAdjustFeeTypeList = async () => {
    const { infoReqParam, type } = this.props;

    // 如果传递了bill_id，则取单据上的快照数据。
    const isView = type === 'show'; // 是否是查看详情
    const res = await postJAVA('/cmm-finance/fee/getFeeByTypeOrOpType', {
      types: [17],
      bill_id: isView ? infoReqParam?.bill_id : '',
    });
    const { billAdustInfo = {} } = this.state;
    const adjustFeeTypeList = res?.res ?? [];
    this.setAdjustFeeTypeList(adjustFeeTypeList);
    return adjustFeeTypeList;
  };
  // 设置 账单调整费用项
  setAdjustFeeTypeList = (list, cb) => {
    const { billAdustInfo = {} } = this.state;
    this.setState(
      {
        adjustFeeTypeList: list,
        billAdustInfo: {
          ...billAdustInfo,
          enumerations: {
            ...billAdustInfo?.enumerations,
            expense:
              // 构建 Sug
              list?.map?.(item => ({
                fee_id: item.id,
                fee_type: item.type,
                expense: item.db_key,
                key: item.db_key,
                name: item.fee_name,
                display: item.fee_name,
                ...item,
              })) ?? [],
          },
        },
      },
      cb,
    );
  };
  // 设置 费用类型树
  setFeeCatigory = list => {
    this.setState({
      feeCatigory: list,
    });
  };
  // 账单调整
  renderBillAdjust = () => {
    const billAdustInfo = this.state.billAdustInfo ?? {};
    // 默认显示一条空数据
    if (!billAdustInfo?.data?.length) {
      billAdustInfo.data = [
        {
          expense: '',
          amount: '',
        },
      ];
    }
    const { header = {} } = billAdustInfo;

    // 处理表格样式，超过一定高度显示滚动条
    let style = {}; // , showScrollbarY = false
    let height =
      (this.state.billAdjustHeight ||
        PICK_TABLE_ROW_HEIGHT * (billAdustInfo?.data?.length ?? 0) + PICK_TABLE_HEADER_HEIGHT) +
      2 +
      PICK_TABLE_HEADER_HEIGHT;
    const showKeys = Object.keys(header);
    let width = 0;
    showKeys.forEach(key => {
      const item = header[key];
      width += item.minWidth;
    });
    const wrapDom = this.cardFormHeader || this.pickOrderWrap || window.document.body;
    const totalWidth = wrapDom.offsetWidth - 16;
    const overWidth = width > totalWidth;
    height = overWidth ? height + 16 : height;
    const list = this.setBillAdjustRow(billAdustInfo.data, billAdustInfo.enumerations ?? {});
    // 处理表格样式，超过一定高度显示滚动条
    const total = {
      amount:
        list
          ?.reduce?.((n, row) => {
            try {
              const t = Big(n).plus(row.amount);
              return t;
            } catch {
              return n;
            }
          }, 0)
          ?.toFixed(2) ?? '',
    };

    // body不显示的情况下，默认不显示body
    if (!this.getShowValue(this.state.type)) style = { height: '0px', padding: '0px', maxWidth: '0px' };
    return (
      <CardBox title="账单调整" className={classnames(`${prefixCls}__card-box`)}>
        <div
          className={`${prefixCls}__bill_adjust`}
          style={{ ...style, width: '100%' }}
          ref={r => (this.billAdjustTableWrap = r)}
        >
          <div style={{ width: '100%', overflowX: 'auto' }}>
            <div style={{ maxHeight: 200, height: height > 200 ? 200 : height }}>
              <Table
                key="billAdjustTable"
                tipsTrigger="click"
                classname="unedit-table-style"
                headerHeight={PICK_TABLE_HEADER_HEIGHT}
                rowHeight={PICK_TABLE_ROW_HEIGHT}
                footerHeight={26}
                data={list}
                header={header}
                enumerations={billAdustInfo.enumerations ?? {}}
                {...billAdustInfo._table}
                handleTodoAdd={this.handleBillAdjustAdd}
                handleTodoSubtract={this.handleBillAdjustSubtract}
                handleChange={this.handleBillAdjustChange}
                handleBlur={this.handleBillAdjustBlur}
                handleSelectDropSelect={this.handleBillAdjustSelect}
                total={total}
              />
            </div>
          </div>
        </div>
      </CardBox>
    );
  };
  setBillAdjustData = newData => {
    const { billAdustInfo = {} } = this.state;
    this.setState({
      billAdustInfo: {
        ...billAdustInfo,
        data: newData,
      },
    });
  };
  getBillAdjustData = () => {
    return this.state?.billAdustInfo?.data;
  };
  // 账单调整 添加费用项
  handleBillAdjustAdd = () => {
    if (this.props.type === 'show') return;
    const { billAdustInfo = {}, adjustFeeTypeList = [] } = this.state;
    const { data = [] } = billAdustInfo ?? {};
    if (data?.length >= adjustFeeTypeList?.length) return;
    const newData = [
      ...data,
      {
        expense: '',
        amount: '',
      },
    ];
    this.setState(
      {
        billAdustInfo: {
          ...billAdustInfo,
          data: newData,
        },
      },
      () => {
        this.props.isOverload && this.handleChangeAmount(); // isOverload 场景需要主动更新 明细。
      },
    );
  };
  // 账单调整 删除费用项
  handleBillAdjustSubtract = index => {
    const { billAdustInfo = {} } = this.state;
    const { data = [] } = billAdustInfo ?? {};
    const newData = [...data];
    newData.splice(index, 1);
    // 至少保留一个费用项，可以为空
    if (!newData.length) {
      newData.push({
        expense: '',
        amount: '',
      });
    }
    this.setState(
      {
        billAdustInfo: {
          ...billAdustInfo,
          data: newData,
        },
      },
      () => {
        this.props.isOverload && this.handleChangeAmount(); // isOverload 场景需要主动更新 明细。
      },
    );
  };
  // 账单调整 下拉选择
  handleBillAdjustSelect = (index, colKey, colVal) => {
    const { billAdustInfo = {} } = this.state;
    const { data = [], enumerations } = billAdustInfo ?? {};
    const newData = [...data];
    let dataRow = newData[index];
    if (colKey === 'expense') {
      const enums = enumerations?.expense ?? [];
      const feeInfo = enums?.find?.(item => item.expense === colVal);
      dataRow = {
        ...dataRow,
        fee_id: feeInfo?.id,
        fee_type: feeInfo?.type,
        expense: feeInfo?.db_key,
        fee_direct: feeInfo?.fee_direct,
        share_type_name: feeInfo?.share_type_name,
      };
    } else {
      dataRow[colKey] = colVal;
    }

    newData[index] = dataRow;
    this.setRowData(billAdustInfo, newData);
  };
  // 失去焦点处理
  handleBillAdjustBlur = (index, colKey) => {
    const { billAdustInfo = {} } = this.state;
    const { data = [] } = billAdustInfo ?? {};
    const newData = [...data];
    const dataRow = newData[index];
    const colVal = dataRow[colKey];
    dataRow[colKey] = colVal === '-' ? '' : colVal;
    newData[index] = dataRow;
    this.setRowData(billAdustInfo, newData);
  };
  // 账单调整 输入数据
  handleBillAdjustChange = (index, colKey, colVal) => {
    const { billAdustInfo = {} } = this.state;
    const { data = [] } = billAdustInfo ?? {};
    const newData = [...data];
    const dataRow = newData[index];
    dataRow[colKey] = colVal;
    const { fee_direct } = dataRow;
    // 费用方向为支出时，在输入数据前添加负号
    if (fee_direct === 'out') {
      dataRow[colKey] = toNegatives(colVal);
    }
    newData[index] = dataRow;
    this.setRowData(billAdustInfo, newData);
  };
  setRowData = (billAdustInfo, data) => {
    this.setState(
      {
        billAdustInfo: {
          ...billAdustInfo,
          data,
        },
      },
      () => {
        this.props.isOverload && this.handleChangeAmount(); // isOverload 场景需要主动更新 明细。
      },
    );
  };
  // 账单调整 费用项每条数据单独设置
  setBillAdjustRow = (data = [], orignEnum) => {
    const cache = {};
    if (!data?.length) return;
    // 缓存所有已选择项
    data.forEach((item, index) => {
      cache[data[index].expense] = item;
    });

    const hasFeeAddPerm = window.permission.includes('feeAdd') || window.permission.includes('feeAddV1');

    return data.map((row, index) => {
      const disable = ['fee_direct', 'share_type_name'];
      if (this.props.type === 'show') {
        disable.push('amount', 'expense', 'substract');
      }

      row.otherProps = {
        expense:
          this.props.type !== 'show' && hasFeeAddPerm
            ? {
                inputWrapStyle: {
                  width: 'calc(100% - 0px)',
                },
                style: {
                  width: '100%',
                },
                after: () => (
                  <Icon
                    iconType="icon-add-rad"
                    style={{ marginLeft: 4, marginRight: 6 }}
                    onClick={e => {
                      e?.stopPropagation?.();
                      addAdjustFee(this, res => {
                        if (!res || (_.isArray(res) && !res.length)) return;
                        const { adjustFeeTypeList = [], feeCatigory = [] } = this.state ?? {};
                        // 将 新增的账单调整费用 添加到费用类型树
                        this.setFeeCatigory(feeCatigory.concat({ ...res, category_name: '账单调整' }));
                        // 回填新增的账单调整费用到下拉Sug
                        this.setAdjustFeeTypeList(adjustFeeTypeList.concat(res), () => {
                          // 默认选中刚刚新增的 账单调整费用
                          this.handleBillAdjustSelect(
                            index,
                            'expense',
                            res?.db_key ?? res?.expense ?? res?.[0]?.db_key ?? res?.[0]?.expense,
                          );
                        });
                      });
                    }}
                  />
                ),
              }
            : {},
        disable,
      };

      if (row.fee_direct) {
        const isOut = row.fee_direct === 'out';
        row.otherProps.amount = {
          pattern: isOut ? FLOAT_I_9_M_9_P_2 : FLOAT_I_9_N_9_P_2,
          // 以下两个属性是配合css使用，模拟实现自动输入负号
          shownegativesplaceholder: isOut ? '1' : '0',
          placeholder: isOut ? '-' : '',
        };
      }
      if (row.amount && +row.amount != 0) {
        row.otherProps.expense = {
          ...row.otherProps.expense,
          required: true,
        };
      }

      // 为每条数据单独设置枚举值，过滤掉已被选择的值
      const rowEnum = _.cloneDeep(orignEnum);
      const { expense } = row;
      const billAdjustEnum = rowEnum?.expense;
      rowEnum.expense = billAdjustEnum?.filter?.(item => item.expense === expense || !cache[item.expense]);
      row.otherProps.enumerations = rowEnum;

      return { ...row };
    });
  };
  // 账单调整格式校验
  validateBillAdjust = async () => {
    const { billAdustInfo = {} } = this.state;
    const { data = [] } = billAdustInfo ?? {};

    const validRes = [];

    for (let i = 0, len = data.length; i < len; i++) {
      let validated = true;
      const tips = [];
      const row = data[i];
      if (row.amount && !row.expense) {
        validated = false;
        tips.push({ text: `账单调整项 ${i + 1} 费用名称不能为空`, block: true });
      }
      if (row.fee_direct === 'inCome' && +row.amount < 0) {
        validated = false;
        tips.push({ text: `账单调整项 ${i + 1} 收入费用只能为正数`, block: true });
      }
      if (row.fee_direct === 'out' && +row.amount > 0) {
        validated = false;
        tips.push({ text: `账单调整项 ${i + 1} 支出费用只能为负数`, block: true });
      }
      if (row.expense && row.amount == '0') {
        tips.push({ text: `账单调整项 ${i + 1} 费用为0，请注意检查！`, block: false });
      }
      if (!row.amount && row.expense) {
        tips.push({ text: `账单调整项 ${i + 1} 费用为空，请注意检查！`, block: false });
      }
      // 有阻断式错误，直接提示
      if (!validated) {
        new PopUp(PopTip, {
          type: ERROR,
          content: tips?.[0]?.text,
        }).show();
        return validated;
      }
      validRes.push(...tips);
    }

    const continueValid = validRes.filter(item => !item.block);
    if (continueValid.length) {
      return await confirmWithReasonSync({
        tips: `存在账单调整费用项金额为0或空，请注意检查！`, // 顶部提示语
        noticeTitle: '',
        btnText: { confirm: '继续' },
      });
    }
    return true;
  };
  showFeeInfoTable = () => {
    let tableInfo = this.state.feeInfo;
    tableInfo = dealFeeDataForShow(tableInfo, {
      dealSettle: false,
      dealOilCard: this.props.type === 'add',
      that: this,
    });

    if (tableInfo.data === undefined || tableInfo.data === null || !tableInfo.header) return false;
    return true;
  };
  // 费用table信息
  renderFeeInfo = () => {
    const { enter13Path } = this.props;
    const enableDataPath = true; // !enter13Path bug43441
    let tableInfo = this.state.feeInfo;
    tableInfo = dealFeeDataForShow(tableInfo, {
      dealSettle: false,
      dealOilCard: this.props.type === 'add',
      that: this,
    });

    let style = {}; // , showScrollbarY = false
    if (tableInfo.data === undefined || tableInfo.data === null || !tableInfo.header) return false;
    // 实际行高小2px, 第一行不需要减2
    let height = (this.state.feeHeight || PICK_TABLE_ROW_HEIGHT * tableInfo.data.length + PICK_TABLE_HEADER_HEIGHT) + 2;
    const showKeys = Object.keys(tableInfo.header).filter(key => tableInfo.header[key].display === 'show');
    let width = 0;
    showKeys.forEach(key => {
      const newItem = tableInfo.header[key];
      newItem.flexGrow = 1;
      newItem.tipProps = { isDom: true, type: 'info' };
      newItem.isResizable = false;
      newItem.minWidth = this.feeColumnWidth;
      if (tableInfo.header[key].type === 'DateTime' || key === 'plan_arr_t') {
        newItem.minWidth = this.feeDateColumnWidth;
        newItem.width = this.feeDateColumnWidth;
      }
      if (key === 'b_kilometers' || key === 'actual_mile') {
        newItem.width = 120;
      }
      width += newItem.minWidth;
      key.toLowerCase() === 'substract' &&
        (newItem.width = this.substractWidth) &&
        (newItem.minWidth = this.substractWidth);
      tableInfo.header[key] = newItem;
    });
    if (this.props.batchTableInfo.showType === 'billDetail') {
      tableInfo.data.forEach((item, index) => {
        tableInfo.data[index].otherProps = tableInfo.data[index].otherProps || {};
        tableInfo.data[index].otherProps.disable =
          this.props.type === 'show' || isAccountCheckPayMode(item.pay_mode) ? Object.keys(tableInfo.header) : [];
      });
    }
    const itemList = getSettingItemList();
    const header = {};
    Object.entries(tableInfo.header).forEach(([key, value]) => {
      if (itemList.includes(key)) {
        header[key] = {
          ...value,
          style: { color: '#457091' },
        };
      } else {
        header[key] = value;
      }
    });
    const wrapDom = this.cardFormHeader || this.pickOrderWrap || window.document.body;
    const totalWidth = wrapDom.offsetWidth - 16;
    const overWidth = width > totalWidth;
    height = overWidth ? height + 16 : height;
    // body不显示的情况下，默认不显示body
    if (!this.getShowValue(this.state.type)) style = { height: '0px', padding: '0px', maxWidth: '0px' };
    // 统一配置的参数在tableInfo._table 之后
    return (
      <div className={`${prefixCls}__fee_table`} style={{ ...style, width: '100%' }} ref={r => (this.feeTableWrap = r)}>
        <div style={{ width: '100%', overflowX: 'auto' }}>
          <div style={{ maxHeight: 200, height: height > 200 ? 200 : height }}>
            <Table
              key="feeTable"
              tipsTrigger="click"
              classname="unedit-table-style"
              headerHeight={PICK_TABLE_HEADER_HEIGHT}
              rowHeight={PICK_TABLE_ROW_HEIGHT}
              data={tableInfo.data}
              header={header}
              enumerations={tableInfo.enumerations}
              handleSelectDropSelect={this.handleFeeTableSelectDropSelect}
              handleChange={this.handleFeeTableChange}
              handleBlur={this.handleFeeTableBlur}
              handleTodoAdd={this.handleTodoAdd}
              handleTodoSubtract={(...res) => this.handleTodoSubtract(...res)}
              handleCustomizeEvent={this.handleFeeTableSelectDropSelect}
              cellContentGetter={this.cellContentGetter}
              refGetter={r => (this.feeTable = r)}
              {...tableInfo._table}
              isAutoSize
              showScrollbarX
              showScrollbarY
              canSaveTableStyle={false}
              isShowSort={false}
              enableDataPath={enableDataPath}
            />
          </div>
        </div>
      </div>
    );
  };
  getInvoiceInfo = async (bill_id, changeShowBody = false) => {
    try {
      const res = await fetchApi('/Finance/Finance/getInvoicingInfoByBillId', {
        method: 'POST',
        body: { req: { bill_id } },
      });
      if (res.errno === 0) {
        const data = this.state.innerInvoiceData;
        if (res.res) {
          let hasContent = false;
          Object.keys(data).forEach(item => {
            if (res.res[item]) {
              hasContent = true;
            }
            data[item].otherProps.defaultValue = res.res[item];
            // 展示模式时不可编辑
            if (this.state.type === 'show') {
              data[item].otherProps.disabled = true;
              data[item].tips = res.res[item];
            }
          });
          changeShowBody && this.invoiceForm && this.invoiceForm.showBody(hasContent);
        } else {
          Object.keys(data).forEach(item => {
            data[item].otherProps.defaultValue = '';
            // 展示模式时不可编辑
            if (this.state.type === 'show') {
              data[item].otherProps.disabled = true;
            }
          });
          changeShowBody && this.invoiceForm && this.invoiceForm.showBody(false);
        }
        this.setState({
          innerInvoiceData: { ...data },
        });
      }
    } catch (e) {
      //
    }
  };
  //  挑单顶部信息
  renderTitle = () => {
    let handleEvents = {};
    const handleHeaderEvents = {};
    handleHeaderEvents.onChange = this.handleInputChange;
    handleHeaderEvents.onClick = this.props.handleOnClick;
    handleHeaderEvents.onBlur = this.handleInputBlur;
    handleEvents.onChange = this.handleInputChange;
    handleEvents.onBlur = this.handleInputBlur;
    handleEvents.handleSelected = this.handleSelectChange;
    handleEvents = { ...handleEvents, ...this.titleCallback };
    const showBody = this.getShowValue(this.state.type);
    const { header, data } = this.getHeaderData(this.state.type, showBody);

    // 给结算方式item 添加后缀，并将输入框宽度减少，TODO:临时代码，后端优化后由后端(@zhouqi)配置
    if (data.settle_way) {
      const { otherProps = {} } = data.settle_way;
      const { inputWrapStyle = {} } = otherProps;
      data.settle_way.otherProps = {
        ...otherProps,
        width: 120,
        inputWrapStyle: {
          ...inputWrapStyle,
          width: '118px',
        },
      };
      data.settle_way.suffixType = 'icon-help';
      data.settle_way.suffixTips =
        '下游对账单结算方式有选择“网货支付”时，会校验对账的单据及费用是否满足网货支付的要求，不满足不可保存成功。';
    }
    // 开票信息
    const { innerInvoiceData, showInvoiceInfo } = this.state;

    let isHeaderVal = true;
    const changeTypeClass = data.change_type; // 判断是否含有路由类型切换
    const { isTask } = this.props;
    if (Object.keys(header).length <= 0 || this.props.batchTitleInfo.isCardFormHeader === 'hidden') isHeaderVal = false;
    delete header.titleName;

    return (
      <div
        className={classnames({
          [`${prefixCls}__card_form`]: true,
          [`${prefixCls}__card_form_unedit`]: this.state.type === 'show',
          'change-type__card_form': changeTypeClass,
          hide: isTask,
        })}
        ref={r => (this.cardFormWrap = r)}
      >
        <CardForm
          showBody={showBody}
          headerClass="normal_title"
          handleEvents={handleEvents}
          handleHeaderEvents={handleHeaderEvents}
          header={header}
          data={data}
          isHeader={isHeaderVal}
          showHeaderTips
          bodyHideCallBack={(...res) => this.cardFormBodyHideCallBack(...res)}
          ref={r => (this.cardForm = r)}
        />
        {showInvoiceInfo ? (
          <div ref={r => (this.invoiceFormWrap = r)}>
            <CardForm
              showBody={false}
              headerClass="invoice_title"
              classname="invoice_info"
              handleEvents={handleEvents}
              handleHeaderEvents={handleHeaderEvents}
              header={invoiceHeader}
              data={innerInvoiceData}
              isHeader
              ref={r => (this.invoiceForm = r)}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    );
  };
  renderTaskLoad() {
    return (
      <div className={classnames({ [`${prefixCls}__card_task`]: true })}>
        <TaskLoad
          defaultStatus={0 /** STATUS.NO */}
          taskRef={r => {
            this.taskLoad = r;
          }}
          ref={r => (this.taskLoadWrap = r)}
          footerShow={false}
        />
      </div>
    );
  }
  // 合同
  renderContract = () => {
    const { contractList = [] } = this.props;
    const contractItem = contractList.map(item => {
      const id = item.key || item.id;
      return (
        <div
          className="contract-item"
          onClick={() => {
            this.props.viewContract(id);
          }}
        >
          <Icon iconType="icon-waper-bu" />
          <span>{item.name}</span>
        </div>
      );
    });
    return <div className="contract-wrapper">{contractItem}</div>;
  };
  // 金额
  renderRateInfo = () => {
    const { acPayAmount, totalAmount, reconciliationRate, amerceAmount, interestAmount, isModify, type } = this.props;
    const showBody = this.getShowValue(this.state.type);
    const { data = {} } = this.getHeaderData(this.state.type, showBody);
    // eslint-disable-next-line no-prototype-builtins
    const hasAmerce = data.hasOwnProperty('amerce') && +amerceAmount !== 0 && !isNaN(amerceAmount);
    // eslint-disable-next-line no-prototype-builtins
    const hasInterest = data.hasOwnProperty('interest') && +interestAmount !== 0 && !isNaN(interestAmount);
    return (
      <div className="rate-info-content">
        <div>
          <span className="text-warning">实付金额</span>
          <span className="text-danger">{acPayAmount}</span>
          <span className="text-warning">
            元（应付{totalAmount} * {reconciliationRate}%
          </span>
          {hasAmerce && <span className="text-warning">-罚款{amerceAmount}</span>}
          {hasInterest && <span className="text-warning">-利息{interestAmount}</span>}
          <span className="text-warning">）</span>
        </div>
        {(type !== 'show' || (type === 'show' && isModify)) && (
          <div className="text-warning">右屏数据为当前账单对账数据，对账比例非100%时系统将对剩余部分自动创建对账单</div>
        )}
      </div>
    );
  };
  getShowProps = (props = this.props) => {
    const { howToShow } = props;
    const show = { rightShow: true };
    switch (howToShow) {
      case 'right':
        show.leftShow = false;
        break;
      default:
        show.leftShow = true;
    }
    return show;
  };
  getTruckTime = () => {
    if (this.props.batchTitleInfo.cardFormHeader && this.props.batchTitleInfo.cardFormHeader.truck_t) {
      return this.props.batchTitleInfo.cardFormHeader.truck_t.otherProps.defaultValue;
    }
  };

  // 更新收支方式合计
  updateSummary = ({ total } = {}) => {
    const { _summary } = this;

    const billTotalIndex = _summary?.findIndex?.(item => item.key === 'billTotal');
    if (billTotalIndex < 0) return;

    const cTotal = _summary?.[billTotalIndex]?.value ?? 0;
    try {
      _.set(_summary, [billTotalIndex, 'value'], Big(total ?? cTotal)?.toFixed?.(2) ?? cTotal);
    } catch (e) {
      console.log(e);
    }

    Promise.resolve().then(() => this.biFeeSummaryRef?.setSummary?.(this.getSummaryForShow()));
  };

  // 从收支方式列表获取 收支合计
  getSummaryForShow = () => {
    const { _summary } = this;
    const feeData = this.feeTable?.getTableData?.() ?? {};

    // 计算各部分合计值
    let bookTotal = Big(0);
    let otherTotal = Big(0);
    let total = Big(0);
    const billTotal = Big(_summary?.[0]?.value || 0);
    feeData?.data?.forEach?.(item => {
      try {
        if (isAccountCheckPayMode(item.pay_mode)) {
          bookTotal = bookTotal?.plus?.(item.amount || 0);
        } else {
          otherTotal = otherTotal?.plus?.(item.amount || 0);
        }
        total = total?.plus?.(item.amount || 0);
      } catch (e) {
        console.log(e);
      }
    });
    const remainTotal = billTotal?.minus?.(total);
    Object.entries({ billTotal, bookTotal, otherTotal, remainTotal }).forEach(([key, value]) => {
      const index = _summary?.findIndex?.(item => item.key === key);
      if (index > -1) _.set(_summary, [index, 'value'], value?.toFixed?.(2)); // 单据金额合计
    });
    return _summary;
  };

  renderBiPickOrder = () => (
    <BiPickOrder
      type={URI.parseQuery(window.location.search).isOrderPicker ? 'add' : this.state.type}
      openPageBtnKey={this.props.openPageBtnKey}
      openPageTab={this.props.openPageTab}
      batchInfo={this.props.batchInfo}
      wRateValue={this.state.wRateValue}
      vRateValue={this.state.vRateValue}
      batchTableInfo={this.state.batchTableInfo}
      leftList={this.state.tableList.left}
      rightList={this.state.tableList.right}
      changeLeftPageNumCallback={this.changeLeftPageNum}
      handlePaging={this.handlePaging}
      handleFilterChange={this.changeFilterCallback}
      handleBtnClick={this.btnClickCallback}
      handleLeftToRightCallback={this.handleLeftToRightCallback}
      handleRightToLeftCallback={this.handleRightToLeftCallback}
      handleTableChange={this.handleBiTableChange}
      handleRightTodoAdd={this.props.handleRightTodoAdd}
      handleRightTodoSubtract={this.props.handleRightTodoSubtract}
      handleRightOperateClick={this.props.handleRightOperateClick}
      handleChangeAmount={this.handleChangeAmount}
      tableSet={this.tableSet}
      handleTableFormClick={this.props.handleTableFormClick}
      handleHeaderSortable={this.handleHeaderSortable}
      defaultDataFormate={this.state.defaultDataFormate}
      leftDataFormate={this.leftDataFormate}
      rightDataFormate={this.rightDataFormate}
      refreshData={this.requestPickTable}
      getTruckTime={this.getTruckTime}
      packNum={this.state.packNum}
      orderNum={this.state.orderNum}
      packList={this.state.packList}
      orderArrList={this.state.orderArrList}
      isPack={this.state.isPack}
      ref={r => (this.biPickOrder = r)}
      onRateChange={this.props.onRateChange}
      reconciliationRate={this.props.reconciliationRate}
      showRate={this.props.showRate}
      prohibitTableMove={this.props.prohibitTableMove}
      adjustWidth={this.props.adjustWidth}
      isOverload={this.props.isOverload}
      isNewOverload={this.props.isNewOverload}
      {...this.getShowProps()}
    />
  );

  renderNewOverloadCardBox = () => {
    const { isNewOverload, overloadInfo, reqInfo, billNo } = this.props;
    if (!isNewOverload) return null;
    const { type: billType, is_modify } = reqInfo || {};
    const dataType = is_modify ? 'edit' : 'add';
    const {
      pickOrderConfig: { pickOrderCollectionLabel },
      saveUniqueKey,
    } = COLLECTION_PAGE_CONFIG[billType];

    const showBody = this.getShowValue(this.state.type);
    const { header } = this.getHeaderData(this.state.type, showBody);
    const title = `${header?.bill_no?.otherProps?.defaultValue ?? billNo ?? ''}对账数据`;
    const uniqData = getCollectionUniqData(overloadInfo?.operate_data);
    // 对账单编辑/查看时，需要将od_link_id 存一下，打开挑单页用
    const setDetailCollectionData = async () => {
      const collectData = uniqData.map(item => ({ [saveUniqueKey]: item.id }));
      const mapData = arrayToMap(collectData, saveUniqueKey);
      await setCollectionData({
        val: mapData,
        billType,
        dataType,
      });
    };
    // 打开弹窗
    const handleOpenDialog = () => {
      this.paymentCollectionContainerRef?.showCollectionPage?.();
    };

    // 查看修改对账数据
    const toOpenDialog = async () => {
      await setDetailCollectionData();
      handleOpenDialog();
    };

    const onSave = () => {
      this.props.updateOverloadInfo?.();
    };

    const paymentCollectionNode = (
      <div className={classnames(`${prefixCls}__new-overload`)}>
        <PaymentCollectionContainer
          parentPage={this}
          label={pickOrderCollectionLabel}
          countVal={uniqData.length}
          dataType={dataType}
          billType={billType}
          headerTitle={title}
          beforeOpenDialog={setDetailCollectionData}
          onSave={onSave}
          ref={r => (this.paymentCollectionContainerRef = r)}
        >
          <ButtonIcon onClick={toOpenDialog} classname={classnames(`${prefixCls}__view-collection`)}>
            查看修改/对账数据
          </ButtonIcon>
        </PaymentCollectionContainer>
      </div>
    );
    const node = this.props.layout?.pickerTable ? (
      <CardBox title={this.props.layout?.pickerTable?.title} className={classnames(`${prefixCls}__card-box`)}>
        {paymentCollectionNode}
      </CardBox>
    ) : (
      { paymentCollectionNode }
    );
    return node;
  };

  renderReceiptPayment = () => renderReceiptPayment(this);
  // 给配载单处理一些回调
  doTaskLoad = () => {
    const { tableList } = this.state;
    // 挑单夹左右都不存在值，此时配载页直接完成了
    if (!tableList?.leftList || !tableList?.rightList) {
      this.taskLoadWrap?.setStatus?.(1 /* STATUS.YES */);
    }
  };
  // 生成左右挑单正文内容
  render() {
    console.log(URI.parseQuery(window.location.search));
    const tabArr = ['tr_pack', 'sign_pack', 'delivery_pack'];
    const tabArr2 = ['settle_batch_arrival_pack', 'settle_batch_crease_pack'];
    if (
      tabArr2.indexOf(this.state.query.tab) > -1 ||
      (this.state.showPack && tabArr.indexOf(this.state.query.tab) > -1)
    ) {
      const batchTableInfo = Object.assign(this.state.batchTableInfo, { fetchApi: batchSugApi, filterTips: '打包号' });
      this.setState({
        isPack: true,
        batchTableInfo,
      });
    }
    const { batchTitleInfo, contractList = [], isTask, showBiTable, isReceiptPayment } = this.props;
    const { feeInfo } = this.state;
    let BiPickOrderObj;
    if (this.state.tableList) {
      BiPickOrderObj = this.renderBiPickOrder();
    }

    // 渲染报销单
    if (isReceiptPayment) {
      return this.renderReceiptPayment();
    }
    // {this.getTruckTeamSource()}
    const { isFiddle, isBillAdjust, showAcountBook } = this.props;
    const useFlowClass = isFiddle || isBillAdjust || this.state.showInvoiceInfo || showAcountBook;
    return (
      <div
        className={classnames({
          [`${prefixCls}__wrap`]: true,
          [`is-flow`]: useFlowClass,
          [`is-detail`]: this.props.type !== 'add',
        })}
        ref={r => (this.pickOrderWrap = r)}
      >
        <Load spinning={this.state.isLoading} contentRef={r => (this.contentRef = r)} autoStop={false}>
          <div
            className={classnames({
              [`${prefixCls}__header`]: true,
              [`task_load`]: isTask,
            })}
            ref={r => (this.cardFormHeader = r)}
          >
            {batchTitleInfo && Object.keys(batchTitleInfo).length !== 0 && this.renderTitle()}
            {isTask && this.renderTaskLoad()}
            {this.props.extraForm && !this.props.layout?.feeInfo && this.props.extraForm()}
            {contractList.length > 0 && !this.props.layout?.feeInfo && (
              <div className={`${prefixCls}__head`}>
                <div className="title">收支方式</div>
                {this.renderContract()}
              </div>
            )}
            {feeInfo && !this.props.layout?.feeInfo && this.renderFeeInfo()}
          </div>
          {
            // 上下游对账，收支方式用 CardBox 进行显示。
          }
          {this.props.layout?.feeInfo && this.showFeeInfoTable() ? (
            <CardBox
              title={this.props.layout?.feeInfo?.title}
              className={classnames(`${prefixCls}__card-box`)}
              header={
                <div className={`${prefixCls}__card-box--head`}>
                  <Icon
                    iconType="icon-help"
                    tipsCls="icon-help"
                    placement="top"
                    tips={this.props.layout?.feeInfo?.helpTips}
                  />
                </div>
              }
            >
              {this.props.extraForm && this.props.extraForm()}
              {contractList.length > 0 && (
                <div className={`${prefixCls}__head`}>
                  <div className="title">收支方式</div>
                  {this.renderContract()}
                </div>
              )}
              {this.props.isFiddle ? (
                <BiSummary
                  ref={r => (this.biFeeSummaryRef = r)}
                  simple
                  style={{ marginRight: 'auto', width: '100%' }}
                  summary={this.getSummaryForShow()}
                />
              ) : null}
              {this.renderFeeInfo()}
            </CardBox>
          ) : null}
          {this.props.isBillAdjust ? this.renderBillAdjust() : null}
          {this.props.showRate && (
            <div className={`${prefixCls}__head ${prefixCls}__rate-info`}>
              <div className="title">对账明细</div>
              {this.renderRateInfo()}
            </div>
          )}
          {this.props.renderCustomContent && this.props.renderCustomContent()}
          {showBiTable ? (
            this.props.layout?.pickerTable ? (
              <CardBox
                title={this.props.layout?.pickerTable?.title}
                className={classnames(`${prefixCls}__card-box`)}
                header={
                  <div className={`${prefixCls}__card-box--head`}>
                    {(this.props.type === 'add' || this.props.isModify) && this.props.layout?.pickerTable?.refresh && (
                      <IconRefresh onClick={this.handleRefreshBiPickerOrder} />
                    )}
                  </div>
                }
              >
                <div
                  className={classnames({
                    [`${prefixCls}__table_wrap`]: true,
                    [`${prefixCls}__table_wrap_unedit`]: this.state.type === 'show',
                  })}
                >
                  {BiPickOrderObj}
                </div>
              </CardBox>
            ) : (
              <div
                className={classnames({
                  [`${prefixCls}__table_wrap`]: true,
                  [`${prefixCls}__table_wrap_unedit`]: this.state.type === 'show',
                })}
              >
                {BiPickOrderObj}
              </div>
            )
          ) : (
            this.renderNewOverloadCardBox()
          )}
          {this.props.isFiddle ? (
            <>
              <CardBox title="费用项明细" className={classnames(`${prefixCls}__card-box`)}>
                <BiAmountDetail
                  ref={r => (this.amountDetailListRef = r)}
                  handleCheckAmountDetailExpense={this.handleCheckAmountDetailExpense}
                  action={this.state.type}
                  onChangeAll={this.handleAmountDetailChangeAll}
                />
              </CardBox>
              <Affix offsetBottom={0} target={() => this.contentRef} targetContainer={() => this.pickOrderWrap}>
                {/* 复用BiPickOrder中的按钮点击逻辑 */}
                <BiSummary
                  ref={r => (this.amountDetailSummaryRef = r)}
                  rightButtons={this.getCustomButtons()}
                  tab={() => this.biPickOrder?.getTab?.()}
                  category={() => this.biPickOrder?.getCategory?.()}
                  btnClickCallback={this.handleCustomBtnClick}
                />
              </Affix>
            </>
          ) : null}
        </Load>
      </div>
    );
  }
}
