import { ERROR, AMOUNT_I_9_P_2_C_0 } from 'constants';
import { showInfo, fetchApi, postOption } from 'utils';
import _ from 'lodash';
import carBatch from 'utils/sug/carBatch';

// 司机、车辆、员工余额的key
const balanceKeys = ['car_remain_amount', 'driver_remain_amount', 'employee_remain_amount'];
// 司机、车辆、员工总余额的key
const totalBalanceKeys = ['car_total_balance', 'driver_total_balance', 'employee_total_balance'];

export const initListData = (header = []) => {
  const obj = {};
  if (Object.keys(header).length === 0) return [{ amount: 0 }];
  Object.keys(header).forEach(k => {
    obj[k] = k === 'amount' ? 0 : '';
  });
  return [obj];
};

const getBalanceFlag = data => {
  const _amount = +data.amount || 0;
  const amountMin = data.expense ? +data.expense.amount_min : 0;
  const amountMax = data.expense ? +data.expense.amount_max : 0;
  if (!_amount || (!amountMin && !amountMax)) return '';
  // eslint-disable-next-line no-nested-ternary
  return _amount > amountMax ? '高' : _amount <= amountMin ? '低' : '';
};

export const getFeeInfo = async (data, header) => {
  if (!header.balance_flag || !data.length) return data;
  const feeId = {};
  data.forEach((x, i) => {
    if (x.expense_id) feeId[x.expense_id] = i;
  });
  if (Object.keys(feeId).length === 0) return data;
  const req = { id: Object.keys(feeId) };
  const url = '/Finance/ReceiptExpense/getFeeInfoById';
  const res = await fetchApi(url, { method: 'POST', body: { req } });
  if (res.errno !== 0) {
    showInfo(ERROR, res.errmsg);
    return data;
  }
  if (res.res.length === 0) return data;
  const nextData = [...data];
  res.res.forEach(x => {
    const index = feeId[x.id];
    nextData[index].amount_min = x.amount_min;
    nextData[index].amount_max = x.amount_max;
  });
  return nextData;
};

export const formatData = (list = [], header = {}) =>
  list.map(x => {
    const obj = {};
    const _row = { ...x };
    obj.uuid_mark = _row.uuid_mark;

    Object.keys(header).forEach(k => {
      obj.amount = _row.amount;
      switch (k) {
        case 'project_id': // 项目客户
          obj[k] = { id: _row.project_id || '', name: _row.project_name || '' };
          // delete _row.project_name
          break;
        case 'customer_id': // 客户名称
          if (_row.customer_id) obj[k] = { id: _row.customer_id || '', proj_name: _row.customer_name || '' };
          break;
        case 'line_id': // 车线名称
          obj[k] = { id: _row.line_id || '', line_name: _row.line_name || '' };
          break;
        case 'receipt_bill_no': // 借款单号
          obj[k] = { bill_id: _row.loan_id, bill_no: _row.loan_no, expense_id: _row.loan_uuid_mark };
          // delete _row.loan_id
          // delete _row.loan_name
          // delete _row.uuid_mark
          break;
        case 'batch_num': // 批次号
          obj[k] = {
            uuid: _row.id || '',
            car_batch: _row.batch_num || '',
            b_basic_id: _row.b_basic_id || '',
            transtask_flag: _row.transtask_flag || '',
            b_link_id: _row.b_link_id || '',
          };
          // delete _row.id
          break;
        case 'order_num': // 运单号
          obj[k] = { od_id: _row.od_id || '', order_num: _row.order_num || '' };
          // delete _row.od_id
          break;
        case 'truck_num':
          obj[k] = { id: _row.truck_id || '', tr_num: _row.truck_num || '' };
          // delete _row.truck_id
          break;
        case 'driver_name':
          obj[k] = { id: _row.driver_id || '', name: _row.driver_name || '' };
          // delete _row.driver_id
          break;
        case 'employee_id': // 员工姓名
          obj[k] = { id: _row.employee_id || '', user_name: _row.employee_name || '' };
          // delete _row.employee_name
          break;
        case 'receipt_person':
          if (_row.receipt_person_id)
            obj[k] = { id: _row.receipt_person_id || '', tr_payee_name: _row.receipt_person || '' };
          // delete _row.receipt_person_id
          break;
        case 'receipt_person_bank':
          if (_row.receipt_person_bank) obj[k] = { tr_payee_open_bank: _row.receipt_person_bank || '' };
          break;
        case 'receipt_person_banknum':
          if (_row.receipt_person_banknum) obj[k] = { tr_payee_bank_card_num: _row.receipt_person_banknum || '' };
          break;
        case 'expense':
          obj[k] = {
            id: _row.expense_id,
            fee_name: _row.expense,
            assist: _row.assist,
            subject_name: _row.subject_name,
            subject_code: _row.subject_code,
            subject_id: _row.subject_id,
            debit_sub_id: _row.debit_sub_id,
            debit_sub_code: _row.debit_sub_code,
            debit_sub_name: _row.debit_sub_name,
            credit_sub_id: _row.credit_sub_id,
            credit_sub_code: _row.credit_sub_code,
            credit_sub_name: _row.credit_sub_name,
            amount_min: _row.amount_min,
            amount_max: _row.amount_max,
          };
          break;
        case 'belong_new':
          obj[k] = _row.belong_str;
          obj.belong = _row.belong;
          break;
        case 'oil_card':
          if (_row.oil_card) {
            obj[k] = {
              oil_card: _row.oil_card,
              oil_card_id: _row.oil_card_id,
              fuel_card_remain_amount: _row.fuel_card_remain_amount,
              show_name: _row.fuel_card_remain_amount
                ? `${_row.oil_card}/${_row.fuel_card_remain_amount}元`
                : _row.oil_card,
            };
          } else {
            obj[k] = _row[k];
          }

          break;
        default:
          obj[k] = _row[k];
      }
    });
    return obj;
  });

export const getTotal = (data = []) =>
  data.length ? data.map(x => +x.amount || 0).reduce((prev, curr) => prev + curr) : 0;

export const formulaCalculation = (data = [], index, key, val, page) => {
  const { usedFor, billType } = page.props;
  const list = [...data];
  const pageKey = `${billType}_${usedFor}`;
  const formulaList = _.get(window, 'company_setting.field_association_tb.tableData', []);
  if (!formulaList.length) return data;
  const _list = formulaList.filter(x => x.effect_page.length && x.effect_page[0].key === pageKey);
  if (!_list.length) return data;
  for (let i = 0, l = _list.length; i < l; i++) {
    const v = _list[i];
    if (!v.effect_fee[0]) continue;
    const feeKey = v.effect_fee[0].keyWord;
    if (page.inputMemory[`${index}_${feeKey}`]) continue;

    let total = 0;
    const { formula } = v;
    formula.forEach(x => {
      const { ratio } = x;
      const itemKey = x.itemList;
      const _cur = key === itemKey ? val : list[index][itemKey];
      const _sum = _cur ? +_cur * ratio : 0;
      if (x.operator === 'add') {
        total += _sum;
      } else {
        total -= _sum;
      }
    });
    list[index][feeKey] = total;
  }
  return list;
};

export const buildData = page => {
  const { type } = page.props;
  const { header, data } = page.state;
  let reTotal = 0;
  const errList = [];
  const reInfo = [];
  if (data.length === 0) errList.push('金额必填');
  for (let i = 0, l = data.length; i < l; i++) {
    const r = data[i];
    if (r.amount) reTotal += +r.amount;

    const _row = {};
    _row.uuid_mark = r.uuid_mark;
    // loan: 借款  repay: 还款  income: 付款
    if (type) {
      if (type !== 'income') _row.expense = type;
      switch (type) {
        case 'loan':
          _row.direction = 1;
          break;
        case 'repay':
          _row.direction = 0;
          break;
        default:
          _row.direction = 1;
      }
    }

    const rKey = Object.keys(header);
    for (let n = 0, m = rKey.length; n < m; n++) {
      const x = rKey[n];
      const _h = header[x];
      if (_h.required && !r[x]) {
        errList.push(`${_h.label}必填`);
        break;
      }
      if (['loan', 'repay'].includes(type) && x === 'amount' && (!r[x] || !Number(r[x]))) {
        errList.push(`${_h.label}不能为0`);
        break;
      }

      if (_h && !r[x] && ![...totalBalanceKeys, balanceKeys].includes(x)) continue;

      switch (x) {
        case 'project_id': // 项目客户
          _row.project_id = r[x].id;
          _row.project_name = r[x].name;
          if (_h.required && !r[x].id) errList.push(`${_h.label}必填`);
          break;
        case 'customer_id': // 客户名称
          _row.customer_id = r[x].id;
          _row.customer_name = r[x].proj_name;
          if (_h.required && !r[x].id) errList.push(`${_h.label}必填`);
          break;
        case 'line_id': // 车线名称
          _row.line_id = r[x].id;
          _row.line_name = r[x].line_name;
          if (_h.required && !r[x].id) errList.push(`${_h.label}必填`);
          break;
        case 'batch_num': // 批次号
          _row.id = r[x].uuid;
          _row.batch_num = r[x].car_batch;
          _row.b_basic_id = r[x].b_basic_id;
          _row.transtask_flag = r[x].transtask_flag;
          if (_h.required && !r[x].uuid) errList.push(`${_h.label}必填`);
          break;
        case 'order_num': // 运单号
          _row.od_id = r[x].od_id;
          _row.order_num = r[x].order_num;
          if (_h.required && !r[x].od_id) errList.push(`${_h.label}必填`);
          break;
        case 'receipt_bill_no': // 借款单号
          _row.loan_id = r[x].bill_id;
          _row.loan_no = r[x].bill_no;
          _row.loan_uuid_mark = r[x].expense_id;
          if (_h.required && !r[x].bill_id) errList.push(`${_h.label}必填`);
          break;
        case 'truck_num': // 车牌号
          _row.truck_id = r[x].id;
          _row.truck_num = r[x].tr_num;
          if (_h.required && !r[x].id) errList.push(`${_h.label}必填`);
          break;
        case 'driver_name': // 司机姓名
          _row.driver_id = r[x].id;
          _row.driver_name = r[x].name;
          if (_h.required && !r[x].id) errList.push(`${_h.label}必填`);
          break;
        case 'employee_id': // 员工姓名
          _row.employee_id = r[x].id;
          _row.employee_name = r[x].user_name;
          if (_h.required && !r[x].id) errList.push(`${_h.label}必填`);
          break;
        case 'receipt_person': // 收款人
          _row.receipt_person_id = r[x].id;
          _row.receipt_person = r[x].tr_payee_name;
          if (_h.required && !r[x].id) errList.push(`${_h.label}必填`);
          break;
        case 'receipt_person_bank': // 收款人开户行
          _row.receipt_person_bank = r[x].tr_payee_open_bank;
          break;
        case 'receipt_person_banknum': // 收款人银行卡号
          _row.receipt_person_banknum = r[x].tr_payee_bank_card_num;
          break;
        case 'expense':
          _row.assist = r[x].assist;
          _row.expense = r[x].fee_name;
          _row.expense_id = r[x].id;
          _row.subject_name = r[x].subject_name;
          _row.subject_code = r[x].subject_code;
          _row.subject_id = r[x].subject_id;
          _row.debit_sub_id = r[x].debit_sub_id;
          _row.debit_sub_code = r[x].debit_sub_code;
          _row.debit_sub_name = r[x].debit_sub_name;
          _row.credit_sub_id = r[x].credit_sub_id;
          _row.credit_sub_code = r[x].credit_sub_code;
          _row.credit_sub_name = r[x].credit_sub_name;
          break;
        case 'belong_new':
          _row.belong_str = r.belong_new;
          _row.belong = r.belong;
          break;
        case 'oil_card':
          _row.oil_card = r.oil_card.oil_card;
          _row.oil_card_id = r.oil_card.oil_card_id;
          _row.fuel_card_remain_amount = r.oil_card.fuel_card_remain_amount;
          break;
        case 'car_remain_amount':
        case 'driver_remain_amount':
        case 'employee_remain_amount':
        case 'car_total_balance':
        case 'driver_total_balance':
        case 'employee_total_balance':
          if (r[x] || r[x] === 0) {
            _row[x] = r[x];
          }
          break;
        default:
          if (r[x]) _row[x] = r[x];
      }
    }
    reInfo.push(_row);
  }
  return {
    err: errList,
    data: reInfo,
    total: reTotal,
  };
};

export const getDriverSug = async (query, page) => {
  const { preParam = {} } = page?.state || {};
  // 发生网点id
  const _comId = preParam.com_id ? preParam.com_id.id : +window.company_id;
  const url = 'Basic/Driver/driverSug';
  const res = await fetchApi(
    url,
    postOption({
      company_id: _comId,
      query: '',
      is_oil_card: true,
      need_remainder: true,
      ...query,
    }),
  );
  return res;
};

// 更新司机余额
export const updateDriverRemainAmount = async ({ index, page, query }) => {
  const res = await getDriverSug(query, page);
  if (+res.errno === 0 && res.res) {
    const { data = [] } = page.state;
    data[index].driver_remain_amount = res.res[0]?.balance;
    data[index].driver_total_balance = res.res[0]?.total_balance;
    page.setState({ data: [...data] });
  }
};

const getDriver = async ({ header, index, page, query }) => {
  if (header.driver_name) {
    const res = await getDriverSug({ ...query }, page);
    if (+res.errno === 0) {
      if (res.res) {
        // 更新
        page.setState(old => {
          const { data = [] } = old;
          data[index].driver_name = res.res[0];
          if (header.receipt_person) {
            const dataItem = res.res[0];
            doPayee(dataItem, index, data);
          }
          // 更新司机余额
          if (header.driver_remain_amount) {
            data[index].driver_remain_amount = res.res[0]?.balance;
          }
          // 更新司机总余额
          if (header.driver_total_balance) {
            data[index].driver_total_balance = res.res[0]?.total_balance;
          }
          return {
            ...old,
            data: [...data],
          };
        });
      }
    }
  }
};

export const getCarBatchSug = ({ car_batch, company_id } = {}) =>
  carBatch({ keyword: car_batch, cid: company_id, tab: 'loan_batch_suggest' }, true);

export const updatePayTotalAmount = async ({ index, page, query }) => {
  const res = await getCarBatchSug(query);
  if (res?.length) {
    page.setState(old => {
      const val = res[0];
      const { data = [] } = old;
      data[index].pay_total_amount = val.b_tr_f_total;
      return {
        ...old,
        data: [...data],
      };
    });
  }
};

export const getCarSug = async query => {
  const url = 'Basic/Truck/truckSug';
  const res = await fetchApi(
    url,
    postOption({
      company_id: window.company_id,
      query: '',
      is_tr_header: 1,
      need_remainder: true,
      state: [0, 1],
      ...query,
    }),
  );
  return res;
};

export const getCar = async query => {
  const res = await getCarSug(query);
  if (+res.errno === 0 && res.res) {
    return { ...res.res };
  }
  return null;
};

// 更新车辆余额
export const updateCarRemainAmount = async ({ index, page, query }) => {
  const res = await getCarSug(query, page);
  if (+res.errno === 0 && res.res) {
    page.setState(old => {
      const { data = [] } = old;
      data[index].car_remain_amount = res.res[0]?.balance;
      data[index].car_total_balance = res.res[0]?.total_balance;
      return {
        ...old,
        data: [...data],
      };
    });
  }
};

const doPayee = (val, index, data) => {
  if (val?.payee?.length === 1) {
    data[index].receipt_person = val.payee[0];
    data[index].receipt_person_bank = val.payee[0];
    data[index].receipt_person_banknum = val.payee[0];
  }
};

export const formSet = async (index, key, val, page) => {
  if (['real_pay_amount', 'pre_pay_amount', 'tip_amount', 'pre_pay_fuel', 'handle_pay_amount'].includes(key)) {
    const reg = /^[0-9]+\.{0,1}[0-9]{0,2}$/;
    if (val && !reg.test(val)) return;
  }

  const { billType } = page.props;
  const { data = [], header } = page.state;
  if (!data[index]) data[index] = {};
  const { loan_type } = data[index];
  if (key === 'belong_new') {
    data[index].belong_new = val.str;
    data[index].belong = val.val;
  } else if (['receipt_person', 'receipt_person_bank', 'receipt_person_banknum'].includes(key)) {
    data[index].receipt_person = val;
    data[index].receipt_person_bank = val;
    data[index].receipt_person_banknum = val;
  } else if (['receipt_bill_no'].includes(key)) {
    const _val = val || {};
    const rowHeader = {};
    Object.keys(header).forEach(k => {
      if (k !== 'receipt_bill_no' && k !== 'amount') rowHeader[k] = header[k];
    });
    const _row = formatData([_val], rowHeader)[0];
    const _disable = val ? Object.keys(rowHeader) : [];
    _row.otherProps = { disable: _disable };
    _row.amount = data[index].amount;
    // 借支类型，默认现金
    if (rowHeader.loan_type) {
      _row.loan_type = _row.loan_type || 'cash';
    }
    _row[key] = val;

    // 车辆还款单：repay_car
    if (billType === 'repay_car' && rowHeader.car_remain_amount) _row.car_remain_amount = val.balance;
    if (billType === 'repay_car' && rowHeader.car_total_balance) _row.car_total_balance = val.total_balance;
    // 司机还款单：repay_driver
    if (billType === 'repay_driver' && rowHeader.driver_remain_amount) _row.driver_remain_amount = val.balance;
    if (billType === 'repay_driver' && rowHeader.driver_total_balance) _row.driver_total_balance = val.total_balance;
    // 员工还款单：repay_employee
    if (billType === 'repay_employee' && rowHeader.employee_remain_amount) _row.employee_remain_amount = val.balance;
    if (billType === 'repay_employee' && rowHeader.employee_total_balance)
      _row.employee_total_balance = val.total_balance;
    data[index] = _row;
  } else if (key === 'loan_type') {
    // 借支类型处理
    data[index][key] = val;
    // 更新车辆余额
    const hasCarAmount = ['car_remain_amount', 'car_total_balance'].some(k => header[k]);
    if (hasCarAmount && header.truck_num && data[index]?.truck_num?.id && val) {
      const { preParam = {} } = page?.state || {};
      // 发生网点id
      const _comId = preParam.com_id ? preParam.com_id.id : +window.company_id;
      updateCarRemainAmount({
        page,
        index,
        query: { tr_id: data[index]?.truck_num?.id, loan_type: val, company_id: _comId },
      });
    }
    // 更新司机余额
    const hasDriverAmount = ['driver_remain_amount', 'driver_total_balance'].some(k => header[k]);
    if (hasDriverAmount && header.driver_name && data[index]?.driver_name?.id && val) {
      updateDriverRemainAmount({ page, index, query: { dr_id: data[index].driver_name.id, loan_type: val } });
    }

    // 借支类型为现金，需情况油卡数据
    if (val === 'cash') {
      data[index].oil_card = null;
      data[index].oil_card_id = null;
      data[index].fuel_card_remain_amount = null;
    }
    // 借支类型发生变更
    page.loadTypeChange(data);
  } else {
    data[index][key] = val;
  }

  // 允许输入：0~999999999.99
  if (key === 'amount' && val && !val.match(AMOUNT_I_9_P_2_C_0)) {
    let finalVal = val;
    const valArr = finalVal.split('.');
    // 正数位最多9位数
    if (valArr[0]?.length > 9) valArr[0] = valArr[0].slice(0, 9);
    // 小数位最大2位小数
    if (valArr[1]?.length > 2) valArr[1] = valArr[1].slice(0, 2);
    finalVal = valArr.join('.');
    data[index][key] = finalVal;
    return showInfo(ERROR, '金额输入有误！');
  }
  if (key === 'amount' && +val < 0) return showInfo(ERROR, '金额需是正数！');

  // 金额标识
  if (key === 'amount' || key === 'expense') data[index].balance_flag = getBalanceFlag(data[index]);

  console.log('form set =', key, val);
  if (key === 'truck_num') {
    if (header.car_remain_amount) data[index].car_remain_amount = val.balance;
    if (header.car_total_balance) data[index].car_total_balance = val.total_balance;
    if (val.payee && val.payee.length === 1) {
      data[index].receipt_person = val.payee[0];
      data[index].receipt_person_bank = val.payee[0];
      data[index].receipt_person_banknum = val.payee[0];
    }
    if (val && +val.dr_com_id) {
      getDriver({
        header,
        index,
        page,
        query: {
          dr_id: val.dr_com_id,
          loan_type,
        },
      });
    }
  }
  if (key === 'driver_name') {
    if (header.driver_remain_amount) data[index].driver_remain_amount = val.balance;
    if (header.driver_total_balance) data[index].driver_total_balance = val.total_balance;
    doPayee(val, index, data);
  }

  if (key === 'employee_id') {
    if (header.employee_remain_amount) data[index].employee_remain_amount = val.balance;
    if (header.employee_total_balance) data[index].employee_total_balance = val.total_balance;
  }

  if (key === 'batch_num') {
    // 应付运输费合计
    if (header.pay_total_amount) data[index].pay_total_amount = val.b_tr_f_total;
    // 联动项目名称
    const hasProjectId =
      typeof data[index].project_id === 'object' ? data[index].project_id.id : data[index].project_id;
    if (val.project_id && !hasProjectId) data[index].project_id = { id: val.project_id, name: val.project_name };
    // 联动客户名称
    if (val.customer_id && !data[index].customer_id)
      data[index].customer_id = { id: val.customer_id, proj_name: val.customer_name };
    if (val.b_tr_id && header.truck_num) {
      const { preParam = {} } = page?.state || {};
      // 发生网点id
      const _comId = preParam.com_id ? preParam.com_id.id : +window.company_id;
      const res = await getCar({ tr_id: val.b_tr_id, company_id: _comId });
      if (res) {
        data[index].truck_num = res[0];
        page.setState({ data: [...data] });
      }
    }
    if (val.b_dr_id) {
      getDriver({
        header,
        index,
        page,
        query: {
          dr_id: val.b_dr_id,
          loan_type,
        },
      });
    }
  }

  // 项目名称联动客户名称
  if (key === 'project_id' && !data[index].customer_id)
    data[index].customer_id = { id: val.customer_proj_id, proj_name: val.customer_proj_name };

  let _row = data;
  // 公式计算
  if (
    [
      'real_pay_amount',
      'pre_pay_amount',
      'tip_amount',
      'pre_pay_fuel',
      'amount',
      'handle_pay_amount',
      'payment_standard',
    ].includes(key)
  ) {
    val && page.setInputMemory(`${index}_${key}`, val);
    _row = formulaCalculation(data, index, key, val, page);
  }
  page.setState({ data: [..._row] });
};
