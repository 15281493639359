import PropTypes from 'prop-types';
import React, { Component } from 'react';
import md5 from 'blueimp-md5';
import { Button, SlideDrager, CardForm, Load } from 'components';
import { DataStripping, post, showInfo } from 'utils';
import { WARN, INFO, ERROR } from 'constants';
import './index.scss';

export default class TransferAccounts extends Component {
  static propTypes = {
    close: PropTypes.func,
    account: PropTypes.object,
    userType: PropTypes.any,
    accountType: PropTypes.any,
    popContainer: PropTypes.object,
    togglePopWidth: PropTypes.func,
    handleUpdate: PropTypes.func,
  };

  state = {
    isSettleCenterMode:
      window.company_setting.is_settle_center_mode && window.company_setting.is_settle_center_mode.checked,
  };

  UNSAFE_componentWillMount() {
    const url = '/Trade/Transfer/virTransferInfo';
    const req = { user_type: this.props.userType, account_type: this.props.accountType };
    this.setState({ loading: true });
    post(url, req).then(res => {
      if (res.errno === 0) {
        const data = res.res;
        this.setState({ data, loading: false });
      } else {
        showInfo(ERROR, res.errmsg);
        this._SlideDrager.handleClose();
      }
    });
  }

  CardInfoContent = () => {
    const { data, isSettleCenterMode } = this.state;
    if (!data) return null;
    const isShow = +data.is_dist === 1 && +data.open_line_settle === 1;
    const isRequire = isShow ? 'require' : '';
    const cardFromData = {
      pay_account_name: {
        type: 'Label',
        labelProps: { children: '付款账户 :' },
        otherProps: { children: this.props.account.account_name },
      },
      line_id_out: {
        type: 'SelectDrop',
        labelProps: { isRequired: true, children: '付款线路' },
        display: isShow,
        eventNames: ['handleSelected'],
        validate: [isRequire], // 显示的时候才校验必填
        validateKey: 'line_id',
        otherProps: {
          data: data.line_list || [],
          blurCheck: true,
          showKey: 'line_name',
          placeholder: '请选择付款线路',
          uniqueKey: 'line_id',
          isMultiple: false,
          showHeader: false,
          tableHeader: {
            line_name: '线路',
            balance: '余额',
          },
          defaultSelected: [],
        },
      },
      in: {
        type: 'SelectDrop',
        display: !!isSettleCenterMode,
        labelProps: {
          isRequired: true,
          children: '收款方',
        },
        eventNames: ['handleSelected'],
        validate: [isSettleCenterMode ? 'require' : ''],
        validateKey: 'id',
        otherProps: {
          fetchApi: {
            url: 'Trade/Transfer/virTransferInfoSug',
            para: {
              slice_num: 50,
            },
            key: 'input',
            data_key: 'res,account_list',
          },
          blurCheck: true,
          uniqueKey: 'key',
          showHeader: true,
          tableHeader: { org_name: '所属组织', name: '收款方', user_type_disp: '收款方类型' },
          shouldFilter: true,
          isMultiple: false,
          defaultSelected: [],
        },
      },
      account_name: {
        type: 'SelectDrop',
        labelProps: {
          isRequired: true,
          children: '收款账户',
        },
        eventNames: ['handleSelected'],
        validate: ['require'],
        validateKey: 'id',
        otherProps: {
          blurCheck: true,
          uniqueKey: 'id',
          showKey: 'account_name',
          showHeader: true,
          tableHeader: isSettleCenterMode
            ? { company_short_name: '所属组织', account_name: '账号名称', account_no: '账户号' }
            : { account_name: '账号名称', account_no: '账户', org_name: '组织名称' },
          shouldFilter: true,
          isMultiple: false,
          defaultSelected: [],
          fetchApi: isSettleCenterMode
            ? undefined
            : {
                url: '/Trade/Transfer/virTransferInfoSug',
                para: {
                  slice_num: 50,
                },
                key: 'input',
                data_key: 'res,account_list',
              },
        },
      },
      account_no: {
        type: 'Input',
        eventNames: ['onChange'],
        labelProps: { children: '收款账号' },
        otherProps: { disabled: true, type: 'text', defaultValue: '' },
      },
      line_id_in: {
        type: 'SelectDrop',
        labelProps: { isRequired: true, children: '收款线路' },
        display: false,
        eventNames: ['handleSelected'],
        validate: [], // 显示的时候才校验必填
        validateKey: 'line_id',
        otherProps: {
          data: [],
          blurCheck: true,
          showKey: 'line_name',
          placeholder: '请选择收款线路',
          uniqueKey: 'line_id',
          isMultiple: false,
          showHeader: false,
          tableHeader: {
            line_name: '线路',
          },
          defaultSelected: [],
        },
      },
      amount: {
        type: 'Input',
        eventNames: ['onChange'],
        validate: ['require'],
        validateKey: 'defaultValue',
        labelProps: { isRequired: true, children: '转账金额' },
        otherProps: {
          disabled: false,
          type: 'text',
          placeholder: `可转账金额${data.balance || 0}元`,
          defaultValue: '',
        },
      },
      pay_pwd: {
        type: 'Input',
        eventNames: ['onChange'],
        validate: ['require'],
        validateKey: 'defaultValue',
        labelProps: { isRequired: true, children: '交易密码' },
        otherProps: { disabled: false, type: 'password', defaultValue: '' },
      },
      remark: {
        type: 'TextArea',
        eventNames: ['onChange'],
        labelProps: { children: '备注' },
        otherProps: { disabled: false, type: 'textarea', defaultValue: '' },
      },
    };
    return (
      <CardForm
        ref={r => (this._cardForm = r)}
        data={cardFromData}
        isHeader={false}
        handleEvents={{ handleSelected: this.cardFormEvents, onChange: this.cardFormChange }}
      />
    );
  };

  setLineIn = (isDist, comID) => {
    if (isDist) {
      const _url = '/Trade/Account/virLineAcc';
      post(_url, { company_id: comID }).then(res => {
        // setComPropBatch 二级属性 setComProp 一级属性
        if (res.errno === 0) {
          this._cardForm.setComPropBatch([
            { key: 'line_id_in', propKey: 'data', value: res.res.line_list, type: 'SelectDrop' },
          ]);
          this._cardForm.setComProp([
            { key: 'line_id_in', propKey: 'validate', value: ['require'], type: 'SelectDrop' },
            { key: 'line_id_in', propKey: 'display', value: true, type: 'SelectDrop' },
          ]);
        } else {
          this._cardForm.setComPropBatch([{ key: 'line_id_in', propKey: 'data', value: [], type: 'SelectDrop' }]);
          this._cardForm.setComProp([
            { key: 'line_id_in', propKey: 'validate', value: [''], type: 'SelectDrop' },
            { key: 'line_id_in', propKey: 'display', value: false, type: 'SelectDrop' },
          ]);
        }
        this._cardForm.setComPropBatch([
          { key: 'line_id_in', propKey: 'defaultSelected', value: [], type: 'SelectDrop' }, // 异步请求回来要设置
        ]);
      });
    } else {
      setTimeout(() => {
        // 没有延时会被setComVal覆盖
        this._cardForm.setComProp([
          { key: 'line_id_in', propKey: 'validate', value: [''], type: 'SelectDrop' },
          { key: 'line_id_in', propKey: 'display', value: false, type: 'SelectDrop' },
        ]);
      }, 0);
    }
  };

  setAccountIn = val => {
    const isDist = val[0].is_dist; // 是分拨中心 展示收款线路
    this.setLineIn(isDist, val[0].id);
    this._cardForm.setComVal('account_no', val[0].account_no, 'Input');
  };

  cardFormEvents = (key, event) => {
    if (!event.length) {
      return;
    }
    if (key === 'in') {
      const val = event[0];
      const { affiliated_sc } = this.state.data;
      const req = {
        affiliated_sc,
        user_id: val.user_id,
        user_type: val.user_type,
        name: val.name,
        org_name: val.org_name,
      };
      post('Trade/Transfer/getAccountList', req).then(res => {
        this._cardForm.setComPropBatch([
          { key: 'account_name', propKey: 'data', value: res.res.account_list, type: 'SelectDrop' },
          { key: 'account_name', propKey: 'defaultSelected', value: [res.res.account_list[0]], type: 'SelectDrop' },
        ]);
        setTimeout(() => this.setAccountIn([res.res.account_list[0]]), 0);
        if (!res.res.account_list.length) {
          showInfo(ERROR, '当前收款方无可转账的账户，请更换收款方！');
        }
      });
    }
    if (key === 'account_name') {
      setTimeout(() => this.setAccountIn(event), 0);
    }
  };
  renderContent = () => (
    <Load spinning={this.state.loading} className="transfer-accounts">
      {this.CardInfoContent()}
    </Load>
  );
  onCancel = () => {
    this._SlideDrager.handleClose();
  };
  isRightAmount = amount => {
    const exp = /^([1-9][\d]{0,7}|0)(\.[\d]{1,2})?$/;
    if (exp.test(amount) && parseFloat(amount) > 0) {
      return false;
    }
    return true;
  };
  onConfirm = () => {
    const isPass = this._cardForm && this._cardForm.validatePass();
    if (!isPass.isPass) {
      showInfo(WARN, isPass.errorInfo);
      return;
    }
    const data = this._cardForm.state.data.toJS();
    const _data = DataStripping(data);
    if (this.isRightAmount(_data.amount)) {
      showInfo(WARN, '请输入正确的转账金额');
      return false;
    }
    _data.user_type_out = this.props.userType;
    _data.account_type_out = this.state.isSettleCenterMode ? this.props.accountType : 0;
    _data.user_id_in = data.account_name.otherProps.defaultSelected[0].user_id;
    _data.user_type_in = data.account_name.otherProps.defaultSelected[0].user_type;
    _data.account_type_in = this.state.isSettleCenterMode
      ? data.account_name.otherProps.defaultSelected[0].account_type
      : 0;
    _data.pay_pwd = md5(_data.pay_pwd);
    delete _data.account_name;
    delete _data.pay_account_name;
    delete _data.account_no;
    delete _data.in;

    const _url = '/Trade/Transfer/virTransfer';
    this._button.loadingOn();
    post(_url, _data)
      .then(res => {
        this._button.loadingOff();
        if (res.errno === 0) {
          showInfo(INFO, '转账成功');
          this.onCancel();
          this.props.handleUpdate();
        } else {
          showInfo(WARN, res.errmsg);
        }
      })
      .catch(e => {
        console.log(e);
        this._button.loadingOff();
      });
  };
  renderFooters = () => {
    const renderFooter = () => (
      <div className={SlideDrager.footerClass}>
        <Button onClick={this.onConfirm} type="primary" ref={r => (this._button = r)}>
          转账
        </Button>
        <Button onClick={this.onCancel}>取消</Button>
      </div>
    );
    return renderFooter();
  };
  render() {
    const { close, popContainer, togglePopWidth } = this.props;
    const slideDragerProps = {
      slideWidth: 430,
      HeaderTitle: '转账信息',
      isShow: true,
      close,
      autoDestroy: true,
      contentDiv: this.renderContent(),
      footerDiv: this.renderFooters(),
    };
    return (
      <SlideDrager
        ref={r => (this._SlideDrager = r)}
        dragerContainer={popContainer}
        togglePopWidth={togglePopWidth}
        {...slideDragerProps}
      />
    );
  }
}
