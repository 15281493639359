exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".message-modal-form.gui-modal{width:812px}.message-modal-form .gui-modal-header .gui-modal-title{text-align:left}.message-modal-form .gui-form-item>.gui-form-label-item{width:110px;padding-right:8px;text-align:right}.message-modal-form .gui-input{width:252px}.message-modal-form .gui-btn-secondary,.message-modal-form .gui-btn-secondary:not(.gui-btn-disabled){background-color:#fff;color:#1d2129;border:1px solid #c9cdd4}.message-modal-form .gui-btn-primary,.message-modal-form .gui-btn-primary:not(.gui-btn-disabled){background-color:#532fe6}.message-modal-form .gui-modal-content{padding:0 0 24px}.message-modal-form-form{padding-top:20px}", ""]);

// exports
exports.locals = {
	"prefixCls": "message-modal-form",
	"message-modal-form": "message-modal-form",
	"gui-modal": "gui-modal",
	"gui-modal-header": "gui-modal-header",
	"gui-modal-title": "gui-modal-title",
	"gui-form-item": "gui-form-item",
	"gui-form-label-item": "gui-form-label-item",
	"gui-input": "gui-input",
	"gui-btn-secondary": "gui-btn-secondary",
	"gui-btn-disabled": "gui-btn-disabled",
	"gui-btn-primary": "gui-btn-primary",
	"gui-modal-content": "gui-modal-content",
	"message-modal-form-form": "message-modal-form-form"
};