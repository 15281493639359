import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
// import moment from 'moment'
import { postJAVA, showInfo, opTips, filterArrayPropByKey } from 'utils';
import { ERROR, CHECK } from 'constants';
import { Button, ModalDialog, Select, DateTimePicker, PopUp } from 'components';
import BatchOp from 'components/commoncomponents/BatchOp';
import truckSug, { truckExtHeader, getTruckStateHeader } from 'utils/sug/truck';
import driverSug, { driverHeader as driverHeaderOrigin } from 'utils/sug/driver';
import { withPopUpI18n, defaultI18n } from '@/utils/i18n/context';
import { prefixCls } from './index.scss';
import config from './config';

const required = bool => (bool ? ' r' : '');

// 司机的header添加考勤字段，因为utils/sug/driver中driverHeader很多地方都在用，不敢直接修改原始值
const driverHeader = [...driverHeaderOrigin, { title: '考勤状态', key: 'attend_flag_desc' }];

class TaskLoad extends PureComponent {
  static propTypes = {
    close: PropTypes.func,
    infos: PropTypes.array,
    formData: PropTypes.object,
    minTruckT: PropTypes.string,
    onSaveSuccess: PropTypes.func,
    i18n: PropTypes.object,
  };

  static defaultProps = {
    i18n: defaultI18n,
  };

  constructor(props) {
    super(props);
    this.state = {
      infos: props.infos || [],
      formData: props.formData,
      startImg: [],
    };
  }

  confirm = async () => {
    const { onSaveSuccess, bLinkIds: selectList, listPage } = this.props;
    const { infos, formData } = this.state;
    const taskDict = this.props.i18n.get('batch', '任务');
    let errTips = '';
    if (infos.length === 1 && !formData.dr_phone) errTips = '司机电话必填！';
    if (infos.length === 1 && !formData.dr_name) errTips = '司机姓名必填！';
    if (infos.length === 1 && !formData.tr_num) errTips = '车牌号必填！';
    if (infos.length === 1 && !formData.truck_t) errTips = '未填写装车时间，不允许装车!';
    if (errTips) {
      return showInfo(ERROR, errTips);
    }

    const req = { ...formData };
    req.infos = infos;
    const { idKey } = config.task_loading;
    if (selectList.length > 1) {
      this.modal.handleHide();
      this.showBatchDialog(JSON.parse(JSON.stringify(selectList)), {}, 'task_loading', listPage, 1, true, idKey);
      return;
    }
    const fetchRes = await postJAVA('/cmm-batch/batch/load', { ...req, is_check: 1 });
    this.modal.handleHide();
    opTips({
      resData: fetchRes,
      orderType: taskDict,
      opType: '装车',
      sucCallback: onSaveSuccess,
      continueCallback: (sucId, cb) => {
        postJAVA('/cmm-batch/batch/load', req).then(res => {
          cb();
          if (res.res.failed_detail.length === 0) {
            showInfo(CHECK, '装车成功');
            setTimeout(this.props?.onSaveSuccess, 2000);
          }
        });
      },
    });
  };

  // 调用批量操作的弹框
  showBatchDialog = (data, pack, btnKey, that, batchCheck, isNotSupportBatch) => {
    const tips = config[btnKey].opType;
    const url = config[btnKey].apiUrl;
    const { header } = config[btnKey];
    const enumerations = {};
    const dataEnums = that.getTableEnum('company_id');
    const newData = data;
    data.forEach((i, idx) => {
      dataEnums.forEach(j => {
        if (+i.company_id === j.id) {
          newData[idx].com_name = j.display;
        }
      });
    });
    console.log(newData, 'sssss');
    new PopUp(BatchOp, {
      title: `批量${tips}`,
      tips,
      header,
      data: newData,
      enumerations,
      requestUrl: url,
      prefixalTips: '',
      isJAVA: true,
      getPara: dataList => {
        const ids = filterArrayPropByKey(dataList, 'b_basic_id');
        const params = {
          infos: this.state.infos,
        };
        if (params.infos) {
          params.infos = params.infos.filter(item => +item.b_basic_id === +ids);
        }
        params.truck_t = this.state.formData.truck_t;
        return params;
      },
      getUrl: () => url,
      finishCallback: that.handleRefreshData,
      escBind: false,
    }).show();
  };

  selectChange = (type, val) => {
    const formData = { ...this.state.formData };
    if (type === 'truck_t') {
      formData.truck_t = val;
    } else if (type === 'tr_num') {
      formData.truck_id = val.id;
      formData.tr_num = val.tr_num;
      if (val.dr_com_id) {
        formData.dr_id = val.dr_com_id;
        formData.dr_name = val.dr_name;
        formData.dr_phone = val.dr_tel;
      }
    } else if (type === 'tr_num_ext') {
      formData.tr_ext_id = val.id;
      formData.tr_num_ext = val.tr_num;
    } else {
      formData.dr_id = val.id;
      formData.dr_name = val.name;
      formData.dr_phone = val.tel;
      formData.black_set = val.black_set;
    }
    this.setState({ formData });
  };
  truckSug = keyword => truckSug({ keyword, is_tr_header: 1 });
  truckExtSug = keyword => truckSug({ keyword, is_tr_header: 0 });
  driverSug = keyword =>
    driverSug({
      keyword,
      need_attendance: 1,
      // 只查询在职的司机（数据会直接把不在职的司机过滤）
      job_status: [1],
    });
  validateBlackDriver = val => {
    if (val && val.black_set === '1') {
      // showInfo(WARN, '当前司机已被拉黑')
      return { type: 'warn', msg: '当前司机已被拉黑' };
    } else if (val && val.black_set === '2') {
      return '当前司机已被拉黑';
    }
    return '';
  };
  customValidator = {
    dr_phone: () => {
      const { state } = this;
      return this.validateBlackDriver(state.formData);
    },
    dr_name: () => {
      const { state } = this;
      return this.validateBlackDriver(state.formData);
    },
  };
  changeImg = e => {
    this.state.startImg.push(e);
  };
  render() {
    const { infos, formData } = this.state;
    const { i18n } = this.props;
    const trNumEnum = [{ tr_num: formData.tr_num }];
    const trNumExtEnum = [{ tr_num: formData.tr_num_ext }];
    const drNameEnum = [{ name: formData.dr_name }];
    const drPhoneEnum = [{ tel: formData.dr_phone }];
    const filedRequired = true;
    const batchDict = i18n.get('batch', '批次');
    const truckStateHeader = getTruckStateHeader(i18n);

    const tipEl =
      infos.length > 1 ? (
        <div className="tip">
          <i className="fn-icon-il fn-icon-warn-o" />
          <p>
            您确定要操作批量装车吗？
            <br />
            如为批量操作，所有{batchDict}都将以此发车时间为准
          </p>
        </div>
      ) : (
        <div className="tip">
          <p>请您确认装车信息</p>
        </div>
      );
    const content = (
      <div ref={r => (this.wrap = r)} className={prefixCls}>
        {tipEl}
        <div className="fields">
          <div>
            <label className={`fn-label${required(filedRequired)}`}>装车时间：</label>
            <DateTimePicker
              defaultValue={formData.truck_t}
              showTime
              onChange={val => this.selectChange('truck_t', val)}
            />
          </div>
          {infos.length === 1 && (
            <div>
              <label className={`fn-label${required(filedRequired)}`}>车牌号：</label>
              <Select
                value={formData.tr_num}
                data={trNumEnum}
                map={false}
                format="tr_num"
                compare="tr_num"
                header={truckStateHeader}
                required={filedRequired}
                onChange={val => this.selectChange('tr_num', val)}
                filter={this.truckSug}
              />
            </div>
          )}
          {infos.length === 1 && (
            <div>
              <label>挂车牌号：</label>
              <Select
                value={formData.tr_num_ext}
                data={trNumExtEnum}
                map={false}
                format="tr_num"
                compare="tr_num"
                header={truckExtHeader}
                onChange={val => this.selectChange('tr_num_ext', val)}
                filter={this.truckExtSug}
              />
            </div>
          )}
          {infos.length === 1 && (
            <div>
              <label className={`fn-label${required(filedRequired)}`}>主驾司机姓名：</label>
              <Select
                value={formData.dr_name}
                data={drNameEnum}
                map={false}
                format="name"
                compare="name"
                header={driverHeader}
                required={filedRequired}
                onChange={val => this.selectChange('dr_name', val)}
                filter={this.driverSug}
                customValidity={this.customValidator.dr_name}
              />
            </div>
          )}
          {infos.length === 1 && (
            <div>
              <label className={`fn-label${required(filedRequired)}`}>主驾电话：</label>
              <Select
                value={formData.dr_phone}
                data={drPhoneEnum}
                map={false}
                format="tel"
                compare="tel"
                header={driverHeader}
                required={filedRequired}
                onChange={val => this.selectChange('dr_phone', val)}
                filter={this.driverSug}
                customValidity={this.customValidator.dr_phone}
              />
            </div>
          )}
        </div>
      </div>
    );

    const bottom = (
      <Fragment>
        <Button type="primary" onClick={this.confirm}>
          确认装车
        </Button>
        <Button onClick={() => this.modal.handleHide()}>取消</Button>
      </Fragment>
    );

    return (
      <ModalDialog
        ref={r => (this.modal = r)}
        content={content}
        bottom={bottom}
        isShow
        isModal
        close={this.props.close}
        title="装车"
      />
    );
  }
}
export default withPopUpI18n(TaskLoad);
