exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".insurance-serve-info-main{font-size:14px}.insurance-serve-info-title{font-weight:700;color:red}.insurance-serve-info-item{display:flex;align-items:center;padding-top:10px}.insurance-serve-info-label{flex:none;width:71px;text-align:right;color:#555}.insurance-serve-info-show-pdf{position:relative}.insurance-serve-info-pdf{position:relative;overflow-y:scroll}.insurance-serve-info-chapter{position:absolute;right:10px;top:350px;display:flex;flex-direction:column}.insurance-serve-info-chapter-img{width:80px}", ""]);

// exports
exports.locals = {
	"prefixCls": "insurance-serve-info",
	"insurance-serve-info-main": "insurance-serve-info-main",
	"insurance-serve-info-title": "insurance-serve-info-title",
	"insurance-serve-info-item": "insurance-serve-info-item",
	"insurance-serve-info-label": "insurance-serve-info-label",
	"insurance-serve-info-show-pdf": "insurance-serve-info-show-pdf",
	"insurance-serve-info-pdf": "insurance-serve-info-pdf",
	"insurance-serve-info-chapter": "insurance-serve-info-chapter",
	"insurance-serve-info-chapter-img": "insurance-serve-info-chapter-img"
};