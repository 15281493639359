import React from 'react';
import { Icon } from 'components';
import { redirectToHelpCenter } from '../../tools';
import { HELP_CENTER_MENU_ID } from '../../constants';
import './index.scss';

export default function DaoHangTip() {
  const driverRoutePkgFlag = (window.permission || []).includes('driver_navigation_v1');
  return (
    <>
      {driverRoutePkgFlag ? (
        <span className="normal-tip">
          <span>当车辆具备G7易流定位能力时,司机可在司小宝APP端使用设置的标准线路进行线路导航。</span>
          <Icon iconType="icon-help" classname="normal-tip" />
          <span className="normal-btn" onClick={() => redirectToHelpCenter('Schedule', HELP_CENTER_MENU_ID)}>
            查看帮助
          </span>
        </span>
      ) : (
        ''
      )}
    </>
  );
}
