exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".truck-download-dialog-content{display:flex;align-items:flex-start}.truck-download-dialog-content-item{flex:none;width:200px}.truck-download-dialog-content-title .gui-checkbox{font-size:16px;font-weight:600}.truck-download-dialog-content-group{margin:10px 0 0 20px}.truck-download-dialog-tip{font-size:14px;color:#ff7d00;margin:10px 0 0 10px}", ""]);

// exports
exports.locals = {
	"prefixCls": "truck-download-dialog",
	"truck-download-dialog-content": "truck-download-dialog-content",
	"truck-download-dialog-content-item": "truck-download-dialog-content-item",
	"truck-download-dialog-content-title": "truck-download-dialog-content-title",
	"gui-checkbox": "gui-checkbox",
	"truck-download-dialog-content-group": "truck-download-dialog-content-group",
	"truck-download-dialog-tip": "truck-download-dialog-tip"
};