import { formatSetting, formatVal, getAttr, getHeader, getExpense, getFeeInfo } from './tool';
import { allField, extraAttr } from './constant';

const adaptor = (info = {}) => {
  console.log('adaptor =', info);
  const { usedFor } = info;
  const { billType } = info;
  const expenseType = getExpense(billType);
  const fieldSet = formatSetting(usedFor, info.settings);
  const { enums } = info;

  const valHub = {};
  const loadInfo = info.loadInfo || {};
  const oriBillNo = loadInfo.bill_no;
  const extraField = extraAttr
    .filter(k => fieldSet[k] && fieldSet[k].show)
    .map(x => {
      valHub[x] = formatVal(x, loadInfo);
      return { key: x, required: fieldSet[x].required, disables: fieldSet[x].disables || false };
    });
  const attrField = getAttr(billType)
    .filter(k => fieldSet[k] && fieldSet[k].show)
    .map(x => {
      valHub[x] = formatVal(x, loadInfo);
      return { key: x, required: fieldSet[x].required, disables: fieldSet[x].disables || false };
    });
  const imgField = {
    show: fieldSet.imgs.show || false,
    disables: fieldSet.imgs.disables || false,
    required: fieldSet.imgs.required || false,
  };
  const fileField = {
    show: fieldSet.files.show || false,
    disables: fieldSet.files.disables || false,
    required: fieldSet.files.required || false,
  };
  const listHeader = {};
  getHeader(billType)
    .filter(k => fieldSet[k] && fieldSet[k].show)
    .forEach(k => {
      listHeader[k] = {
        label: fieldSet[k].label,
        required: fieldSet[k].required,
        disables: fieldSet[k].disables || false,
      };
    });
  const reInfo = loadInfo.re_info || [];
  const listData = reInfo.length ? reInfo : [];
  const moneyState = listData.map(x => !x);
  const payModeEditable = usedFor === 'view' ? true : info.payModeEditable;
  const feeInfo = getFeeInfo({ usedFor, payModeEditable, loadInfo, payModeInfo: enums.pay_mode_info });

  return {
    valHub,
    allField,
    attrField,
    extraField,

    listHeader,
    listData,
    moneyState,

    feeInfo,

    enums,
    fieldSet,
    imgField,
    fileField,
    billType,
    oriBillNo,
    expenseType,
    billId: loadInfo.bill_id,
    filesList: loadInfo.files,
    imgList: loadInfo.imgs ? Object.values(loadInfo.imgs) : [],
    usedFor,
  };
};

export default adaptor;
