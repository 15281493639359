export const configBtnProp = {
  children: '',
  style: {
    height: '24px',
    lineHeight: '20px',
    width: '24px',
    minWidth: '24px',
    textAlign: 'center',
    padding: '0',
    paddingLeft: '4px',
  },
};
export const PRINTER_CONFIG = {
  children: '打印',
  display: true,
  eventNames: ['onClick'],
  showKey: 'title',
  sublist: [],
  type: 'ButtonMenu',
  uniqueKey: 'key',
};
// 静态按钮，始终显示，不会被收到更多里面
// value 将作为div 的style.order属性。排序使用
export const staticBtnKeys = {
  clear_pick_clip: 10, // 清空
  import: 1, // 导入
  self_header: 2, // 多级表头切换到常规表头
  custom_import: 1, // 可视化导入
  import_all: 1, // 带菜单的导入按钮
  import_all_tr: 1, // 带菜单的导入按钮
  import_all_delivery: 1, // 带菜单的导入按钮
  import_all_pickup: 1, // 带菜单的导入按钮
  import_all_shuttle: 1, // 带菜单的导入按钮
  import_update: 1, // 导入更新
  import_cor: 1,
  import_cee: 1,
  export: 2, // 导出
  export_json: 2, // 导出json
  printer: 3, // 打印
  print: 4, // 运单详情页的打印
  pack_order: 5,
  pack_print: 6,
  export_abnormal: 2, // 异常管理的导出按钮
  export_user: 2, // 人事管理-导出员工
  export_cor: 2, // 客户管理
  export_cee: 2,
  export_carriers: 2,
  export_driver: 2,
  export_truck: 2,
  export_fee: 2,
  export_in_exp_mode: 2,
  // 运单跟踪-轨迹共享按钮，因为要放在右边，所以配置为staticBtn
  batch_waybill_trace_share: 2,
  batch_waybill_trace_v1_share: 2,
  batch_waybill_alarm_setting: 1,
  // 运单列表导出全部（实际是导出当前页面展示的全部）
  export_bill_all: 1,
  // 运单列表导出选择
  export_bill_selected: 1,
  jump_to_list: 1,
  in_transit_alert_config: 1,
  // 在途跟踪-自动订阅设置，放右边
  auto_subscribe_setting: 1,
  // 在途跟踪-自动订阅设置-v1版本，放右边
  auto_subscribe_setting_v1: 1,
};
// 不需要flat的按钮们
export const notFlatBtnKeys = {
  ...staticBtnKeys,
  msg_send: true, // 发送短信
};
// 子菜单是打印模板之类的按钮
export const printTplKeys = {
  order_printer: true, // 打印运单
  outer_trans_out_loading_print: true, // 外部中转批次详情打印清单
  staff_trans_out_loading_print: true,
  pickup_loading_print: true, // 订单提货批次打印
  shuttle_loading_print: true, // 短驳批次打印
  delivery_loading_print: true, // 损货批次打印
  tr_loading_print: true, // 装车批次打印
  bill_detail_print: true,
  pack_print: true,
};
// 打印列表之类的按钮
export const printPreviewKeys = {
  trans_print_preview: '列表',
  print_preview: '列表',
  document_print: '打印单据',
};
// 需要合到打印按钮里的keys
export const printKeys = [...Object.keys(printTplKeys), ...Object.keys(printPreviewKeys)];
