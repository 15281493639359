import React, { useEffect } from 'react';
import { Layout, ResizeBox } from '@gui/web-react';
import { IconUniTipsCloseOutline } from '@gui/icon-react';
import { showInfo } from 'utils';
import { ERROR, CHECK } from 'constants';
import MessageInput from '../MessageInput';
import MessageList from '../MessageList';
import ModalForm from '../ModalForm';
import { useMessages, useMessagesDispatch } from '../messagesContext';
import { prefixCls } from './index.scss';

const Header = Layout.Header;
const Footer = Layout.Footer;
const Content = Layout.Content;

export default function MessageDrawer({ closeDrawer, visible }) {
  const { editInfo, reconnectTip } = useMessages();
  const dispatch = useMessagesDispatch();

  useEffect(() => {
    // 重新进入聊天前滚动最底部
    if (!visible) {
      dispatch({
        type: 'SCROLL_TO',
        isToBottom: true,
        immediate: true,
      });
    }

    const handleOffline = () => {
      if (visible) {
        showInfo(ERROR, '当前网络连接异常，请检查您的网络连接，或尝试断开后重新连接。我们将尽快恢复服务。');
      }
    };

    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('offline', handleOffline);
    };
  }, [visible]);

  useEffect(() => {
    if (reconnectTip && visible) {
      showInfo(CHECK, reconnectTip);
      dispatch({
        type: 'RECONNECT_TIP',
        reconnectTip: '',
      });
    }
  }, [reconnectTip]);

  return (
    <ResizeBox directions={['left', 'bottom']} className={`${prefixCls} ${visible ? '' : 'hidden'}`}>
      <Layout className={`${prefixCls}-box`}>
        <Header className={`${prefixCls}-title`}>
          <span>智能录单助手</span>
          <IconUniTipsCloseOutline onClick={() => closeDrawer()} className="close" />
        </Header>
        <Content className={`${prefixCls}-content`}>
          <MessageList />
        </Content>
        <Footer className={`${prefixCls}-footer`}>
          <MessageInput />
        </Footer>
      </Layout>
      {editInfo.show && <ModalForm />}
    </ResizeBox>
  );
}
