/**
 * Created by luoyinxian on 8/31/17.
 */
import React from 'react';
import { ShowInfo, PopUp, PopTip } from 'components';
import { ERROR, INFO } from 'constants';
import { prefixCls } from './faultDetection.scss';

export function detection(needReportSuccess = false) {
  if (navigator.platform.indexOf('Win') > -1) {
    const currNormalPort = window.localStorage.normalPrintPort || 8000;
    const sock = new WebSocket(`ws://127.0.0.1:${currNormalPort}`);
    const _html = (
      <div>
        <h3>系统检测到打印故障，请尝试以下方式解决，若还有问题，请联系G7易流客服400-611-5656</h3>
        <p className="main-p">
          <span>1、</span>重启打印插件C-Lodop服务，确保C-Lodop主服务进程没有被人为或其他程序强制关闭的其他情况。
        </p>
        <p className="sub-p">
          找到并打开C-Lodop启动程序，当弹窗提示C-Lodop安装成功且已启动，说明服务启动正常，检查打印端口是否与系统配置的一致，如果一致，刷新页面再尝试一次打印，如果显示服务启动失败，需要联系客服重装打印插件。
        </p>
        <img src={require('images/print/clodop.jpg')} width="300px" alt="" />
        <p className="main-p">
          <span>2、</span>确保没有其他程序占用C-Lodop端口。
        </p>
        <p className="sub-p">2.1 开始--运行--cmd，或者是window+R组合键，调出命令窗口</p>
        <img src={require('images/print/cmd.jpg')} width="600px" alt="" />
        <p className="sub-p">
          2.2 查看被占用端口对应的PID，输入命令：netstat -aon|findstr 8000，记下最后一位数字，即pid号，例如是7532
        </p>
        <img src={require('images/print/netstat.jpg')} width="600px" alt="" />
        <p className="sub-p">2.3 继续输入tasklist|findstr 7532，回车</p>
        <img src={require('images/print/tasklist.jpg')} width="600px" alt="" />
        <p className="sub-p">
          如果应用程序不是CLodopPrint32.exe说明被其他应用程序占用了端口，需要关闭占用打印端口的应用程序
        </p>
      </div>
    );
    sock.onerror = () => {
      const _currClassname = `${prefixCls}`;
      new PopUp(ShowInfo, {
        classname: _currClassname,
        isShow: true,
        type: '',
        content: _html,
        autoDestroy: true,
        title: '打印故障',
        closable: true,
        buttons: [
          {
            name: '确定',
            btnType: 'primary',
            cb: thisDialog => thisDialog.handleHide(),
          },
        ],
        maskClosable: true,
      }).show();
    };

    sock.onopen = () => {
      if (needReportSuccess) {
        new PopUp(PopTip, {
          classname: '',
          type: INFO,
          content: '系统未检测到打印异常',
          autoDestroy: true,
        }).show();
      }
    };
  } else {
    new PopUp(ShowInfo, {
      classname: '',
      isShow: true,
      type: ERROR,
      content: '您的操作系统不是Windows系统，暂不支持打印',
      autoDestroy: true,
      title: '打印故障',
      closable: true,
      buttons: [
        {
          name: '确定',
          btnType: 'primary',
          cb: thisDialog => thisDialog.handleHide(),
        },
      ],
      maskClosable: true,
    }).show();
  }
}
