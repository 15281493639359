import { Notification } from '@gui/web-react';
import React from 'react';
import { PRINT_STATUS_TEXT, COLOR_MAP } from './printConst';

const NOTIFICATION_MAX_COUNT = 3;

Notification.config({
  maxCount: NOTIFICATION_MAX_COUNT,
  duration: 0,
  prefixCls: 'gui',
});

class PrintTaskQueue {
  constructor() {
    this.tasks = []; // 存储异步任务的队列
    this.isRunning = false; // 判断队列是否正在执行中
    this.taskMap = new Map(); // 记录需要再次轮询的任务
    this.notifications = []; // 记录当前Notification实例，为了解决超过3个之后，id相同，对应Notification没更新，而是新增
    this.isTaskCompleted = false;
  }

  addTask(task) {
    this.tasks.push(task);

    if (!this.isRunning) {
      this.run();
    }
  }

  taskStart() {
    if (this.tasks.length > 0) {
      this.run();
    } else {
      this.notifications = [];
    }
  }

  showNotification(id, printStatusText, printInfo) {
    const { printTitle: title } = printInfo;

    const content = (
      <div>
        打印状态:
        <span
          style={{
            fontSize: '16px',
            marginLeft: '6px',
            color: COLOR_MAP[printStatusText] ?? '#C2C20B',
          }}
        >
          {printStatusText}
        </span>
      </div>
    );
    // arco Notification 组件有个问题，如果ID一致，则直接更新，当设置maxCount，如果弹框数量超出maxCount之后，相同ID得弹框，会新增而不是更新，此时需要找到对应ID得弹框手动更新
    const item = this.notifications?.[0];
    if (item) {
      let isUpdate = false;
      const ids = item.state.notices.map(ele => ele?.id) ?? [];

      if (ids.includes(id)) {
        isUpdate = true;
        item.update({
          id,
          title,
          style: { width: 400 },
          content,
          showIcon: true,
          duration: 0,
          position: 'bottomRight',
          closable: true,
          onClose: () => {
            Notification.remove(id);
            this.taskMap.has(id) && this.taskMap.delete(id);
            this.notifications.length && this.notifications.shift();
          },
        });
        return;
      }
    }
    // 如果目前已经弹框数量是设置得maxCount值，如果出现超过该数量，手动移除最早的，不然会直接把最新得弹窗干掉
    if (this.notifications.length === NOTIFICATION_MAX_COUNT) {
      item.state.notices.shift();
    }

    const notificationInstance = Notification.info({
      id,
      title,
      style: { width: 400 },
      content,
      showIcon: true,
      position: 'bottomRight',
      closable: true,
      customKey: id,
      onClose: () => {
        Notification.remove(id);
        this.taskMap.has(id) && this.taskMap.delete(id);
        this.notifications.length && this.notifications.shift();
      },
    });
    this.notifications.push(notificationInstance);
  }

  run() {
    const task = this.tasks.shift();
    this.isTaskCompleted = false; // 内层循环查询状态是否完成

    const { id, getStatusValueFn } = task;

    this.log('开始执行任务：', id);
    this.runTask(getStatusValueFn(id));
  }
  log() {
    console.log('PRINT:', ...arguments);
  }

  runTask(getStatusFn) {
    this.isRunning = true;
    const startTime = Date.now();
    const taskTimer = setInterval(() => {
      this.printStatusStart(startTime, getStatusFn, taskTimer);
      // 考虑打印机异常场景 走不到回调里 销毁timer
      if (Date.now() - startTime > 60 * 1000) {
        clearInterval(taskTimer);
      }
    }, 5000);
    this.printStatusStart(startTime, getStatusFn, taskTimer);
  }

  printStatusStart(startTime, getStatusFn, taskTimer) {
    getStatusFn().then(result => {
      // 轮询超时逻辑处理
      if (Date.now() - startTime > 60 * 1000) {
        clearInterval(taskTimer);
        this.isRunning = false;
        if (result) {
          const { P_ID } = result;
          this.taskMap.set(P_ID, {
            ...result,
            PRINT_STATUS_ID_TEXT: {
              value: -1,
              text: PRINT_STATUS_TEXT.PRINT_TIMEOUT,
            },
            PRINT_STATUS_ID_VALUE: '-1',
            PRINT_STATUS_TEXT_CONTENT: PRINT_STATUS_TEXT.PRINT_TIMEOUT,
          });
          this.showNotification(P_ID, PRINT_STATUS_TEXT.PRINT_TIMEOUT, result);
          setTimeout(() => {
            Notification.remove(P_ID);
            this.notifications.length && this.notifications.shift();
          }, 10000);
          this.taskStart();
        }
      } else {
        // 保存所有的弹框内容，方便后续根据P_ID手动更新消息内容
        this.taskMap.set(result.P_ID, result);
        const currentPrintList = Array.from(this.taskMap.values());
        // 遍历目前未移除得所有弹框 为了把目前已经弹出来的框批量手动更新消息内容
        currentPrintList.forEach(async item => {
          const { PRINT_STATUS_ID_TEXT, PRINT_STATUS_ID_VALUE, P_ID, PRINT_STATUS_TEXT_CONTENT, FINISH_PRINT } =
            item ?? {};
          // 成功
          const remainder = +PRINT_STATUS_ID_VALUE % 2048;
          const id = `${P_ID}`;
          this.showNotification(id, PRINT_STATUS_TEXT_CONTENT, item);
          if (+PRINT_STATUS_ID_TEXT.value === 128 || +remainder === 20 || FINISH_PRINT) {
            clearInterval(taskTimer);
            this.isTaskCompleted = true;
            this.taskMap.has(id) && this.taskMap.delete(id);
            // 10s之后把成功状态得弹窗移除
            setTimeout(() => {
              Notification.remove(id);
              this.notifications.length && this.notifications.shift();
            }, 10000);
            this.isRunning = false;
            this.taskStart();
          } else if (+PRINT_STATUS_ID_TEXT.value === 2) {
            // 失败
            clearInterval(taskTimer);
            this.isTaskCompleted = true;
            this.taskMap.has(id) && this.taskMap.delete(id);
            setTimeout(() => {
              Notification.remove(id);
              this.notifications.length && this.notifications.shift();
            }, 10000);
            this.isRunning = false;
            this.taskStart();
          }
        });
      }
    });
  }
}

export default new PrintTaskQueue();
