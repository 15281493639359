import PropTypes from 'prop-types';
import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import { SlideDrager, PopUp } from 'components';
import PaymentCollection from './index';
import CollectionButtonArea from './CollectionButtonArea';

const PaymentCollectionContainer = forwardRef(
  (
    {
      parentPage,
      label,
      countVal,
      dataType = 'view',
      children,
      beforeOpenDialog,
      billType,
      headerTitle = '客户订单待对账数据',
      onSave,
      onCancel,
      onClose,
    },
    ref,
  ) => {
    const slideDragerRef = useRef(null);

    const renderSliderContent = modalDialog => (
      <PaymentCollection
        dataType={dataType}
        parentPage={parentPage}
        billType={billType}
        modalDialog={modalDialog}
        onSave={onSave}
        onCancel={onCancel}
      />
    );

    const toOpenDialog = async () => {
      await beforeOpenDialog?.();
      showCollectionPage();
    };

    const showCollectionPage = async () => {
      const modalDialog = new PopUp(SlideDrager, {
        isShow: true,
        isHasBiggest: true,
        isHasDrager: true,
        isBiggest: true,
        ref: slideDragerRef,
        slideWidth: 900,
        autoDestroy: true,
        close: () => {
          modalDialog.close();
          onClose?.();
        },
        HeaderTitle: headerTitle,
        contentDiv: renderSliderContent(slideDragerRef),
      });
      // 为了实现拖动
      const dragContainer = modalDialog.getContainer();
      modalDialog.props.dragerContainer = dragContainer;

      modalDialog.show();
    };

    useImperativeHandle(ref, () => ({
      showCollectionPage,
    }));

    return (
      <>
        {children || null}
        <CollectionButtonArea
          onBtnClick={toOpenDialog}
          billType={billType}
          dataType={dataType}
          countVal={countVal}
          label={label}
        />
      </>
    );
  },
);

PaymentCollectionContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  parentPage: PropTypes.object,
  label: PropTypes.string,
  countVal: PropTypes.number,
  dataType: PropTypes.string,
  children: PropTypes.node,
  billType: PropTypes.string,
  headerTitle: PropTypes.string,
  beforeOpenDialog: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
};

export default PaymentCollectionContainer;
