var escape = require("../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".comment-dialog .commet-wrap{max-height:600px;overflow-y:auto}.comment-dialog .commet-wrap .queryer-content p{font-size:12px;color:#333;font-weight:700}.comment-dialog .commet-wrap .queryer-content .list-start ul{border-bottom:1px solid #ecf0f5;padding-bottom:8px}.comment-dialog .commet-wrap label{font-size:14px;color:#333;border-radius:2px 0 0 2px;display:inline-block;position:relative;top:-75px}.comment-dialog .commet-wrap .fn-textarea{margin-left:20px}.comment-dialog .commet-wrap .imgs-area{margin-left:50px}.comment-dialog .commet-wrap .div-wrap p em{width:60px;text-align:left}.comment-dialog .commet-wrap .div-wrap p span{font-size:12px;color:#f80}.comment-dialog .list-start{display:flex;margin-top:13px;margin-bottom:17px}.comment-dialog .list-start ul{display:flex;width:100%}.comment-dialog .list-start ul li{width:20px;height:20px;margin:0 7px}.comment-dialog .list-start span:first-child{font-size:12px;color:#333;width:60px;border-radius:2px 0 0 2px}.comment-dialog .list-start span:nth-child(3){font-size:12px;color:#537287;margin-left:7px}.comment-dialog .list-start .commentText{font-size:12px;color:#537287;letter-spacing:0;line-height:20px;margin-left:14px}.comment-dialog .defalut{background:url(" + escape(require("../../../images/默认.png")) + ") 0 0 no-repeat;background-size:20px}.comment-dialog .hover{background:url(" + escape(require("../../../images/start.png")) + ") 0 0 no-repeat;background-size:20px}.comment-dialog .bad{background:url(" + escape(require("../../../images/差评.png")) + ") 0 0 no-repeat;background-size:20px}", ""]);

// exports
exports.locals = {
	"prefixCl": "comment-dialog",
	"comment-dialog": "comment-dialog",
	"commet-wrap": "commet-wrap",
	"queryer-content": "queryer-content",
	"list-start": "list-start",
	"fn-textarea": "fn-textarea",
	"imgs-area": "imgs-area",
	"div-wrap": "div-wrap",
	"commentText": "commentText",
	"defalut": "defalut",
	"hover": "hover",
	"bad": "bad"
};