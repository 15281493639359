import _ from 'lodash';
import { GROUP_ACCOUNT_TYPE, COMPANY_ACCOUNT_TYPE, accountTypeSugHeader } from './constant';
import { validateFieldsOf } from 'utils';

import fetchApi from 'utils/fetchApi';

// 获取账本配置
export const getAccountTypeFromSetting = async () => {
  const res = await fetchApi('/Basic/Setting/getSetting', {
    method: 'POST',
    body: { req: { tab: 'fn_set' } },
  }).catch(e => null);
  const setting_path = {
    车辆账本: ['car_loan', 'use_group_car_account'],
    司机账本: ['driver_loan', 'use_group_driver_account'],
    员工账本: ['employee_loan', 'use_group_employee_account'],
    车辆油款账本: ['car_loan', 'use_group_car_account'],
    司机油款账本: ['driver_loan', 'use_group_driver_account'],
  };

  const settting = {};

  Object.keys(setting_path).forEach(k => {
    const checked = _.get(res, ['res', 'setting', 'fn_set', ...setting_path[k], '__meta', 'checked']);
    settting[k] = checked ? GROUP_ACCOUNT_TYPE : COMPANY_ACCOUNT_TYPE;
  });

  return settting;
};

// 切换集团账本表头样式。
export const getAccountTypeSugHeader = type => {
  const header = _.cloneDeep(accountTypeSugHeader);
  if (type === GROUP_ACCOUNT_TYPE) {
    ['account_book_uuid', 'account_book_company_name'].forEach(key => {
      const index = header.findIndex(item => item.key === key);
      if (index > -1) header.splice(index, 1);
    });
  }
  return header;
};

// 为每条收支方式单独设置集团账本表头样式。
export const doAccountTypeForData = (header, data, setting) => {
  if (!_.isArray(data)) return [];
  return data.forEach(item => {
    const pay_mode = _.isArray(item.pay_mode) ? _.get(item.pay_mode, '0.key') : item.pay_mode;
    _.set(item, ['otherProps', 'account_book_card'], {
      //   required: setting[pay_mode] === GROUP_ACCOUNT_TYPE,
      required: _.has(setting, pay_mode), // 收支方式是账本类型，则账本账号是必填的
      tableHeader: getAccountTypeSugHeader(setting?.[pay_mode]),
    });
  });
};

// 重置账本账户字段，并且标红必填
// 有两个场景需要重置：1. 创建时是集团账本，修改时是网点账本 2. 刚进入页面是集团账本，提交时配置改成了网点账本，
export const resetAccountBookField = async (page, { names = [], dataKey = 'data', payModeWrap } = {}) => {
  // 重新获取配置
  await page.getAccountTypeSetting();

  // 处理对应的账本
  const data = page.state[dataKey];
  const newData = data.map(item => {
    const newItem = { ...item };
    const payMode = _.isArray(newItem.pay_mode) ? _.get(newItem, `pay_mode[0].key`) : _.get(newItem, `pay_mode`);
    if (names?.includes(payMode)) {
      _.set(newItem, ['account_book_card'], []);
    }
    return newItem;
  });
  page.setState(
    {
      [dataKey]: newData,
    },
    () => validateFieldsOf(payModeWrap),
  );
};
