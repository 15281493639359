import { showInfo, confirmWithReason, hasPermission } from 'utils';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ERROR, WARN } from 'constants';
import { PopUp, SlideDrager } from 'components';
import GpsSet from 'pages/CompanyController/gpsSet';

class index extends React.Component {
  static propTypes = {
    truckInfo: PropTypes.array,
    gId: PropTypes.string,
    num: PropTypes.string,
    sys: PropTypes.string, // 1:tms, 2: oms
    opFrom: PropTypes.string, // 操作类型，订单轨迹：reservation;运单轨迹：order;批次轨迹：batch
    number: PropTypes.string, // 订单取reservation_num,运单取order_num，批次取car_batch
  };
  constructor(props) {
    super(props);
    this.param = props.truckInfo
      ? {
          truck_info: props.truckInfo,
          op_from: props.opFrom,
          number: props.number,
          gid: props.gId,
          query_source: 'tms',
        }
      : {
          gid: props.gId,
          reservation_num: props.num,
          sys_version: props.sys,
          op_from: props.opFrom,
          number: props.number,
          query_source: 'tms',
        };
  }
  componentDidMount() {
    this.track_log.contentWindow.handleErr = this.handleErr;
    this.track_log.contentWindow.handleWarn = this.handleWarn;
    this.track_log.contentWindow.bd_gps_wallet_open = window.sas_env_group
      ? window.sas_env_group.bd_gps_wallet_open
      : true;
  }
  handleErr = (err, detail) => {
    if (!detail) {
      showInfo(ERROR, err);
    } else {
      confirmWithReason({
        title: ' 操作提醒',
        iconType: WARN, // icon
        tips: err, // 顶部提示语
        noticeTitle: '原因说明：',
        reason: detail, // 具体原因
        btns: { confirm: 1 },
      });
    }
  };
  handleWarn = () => {
    const per = hasPermission('dStateFollow');
    const content = (
      <Fragment>
        未查询到车辆轨迹信息！
        <br />
        请确认车辆是否安装了定位设备，并完成了GPS设置！
      </Fragment>
    );
    if (per) {
      showInfo(
        undefined,
        content,
        true,
        {
          confirm: () => {
            new PopUp(SlideDrager, {
              dragerContainer: this.props.popContainer,
              slideWidth: 344,
              HeaderTitle: 'GPS设置',
              isShow: true,
              contentDiv: <GpsSet isSlide />,
            }).show();
          },
        },
        true,
        '操作提醒',
        undefined,
        {
          confirm: '查看GPS设置',
          cancel: '确定',
        },
      );
    } else {
      showInfo(undefined, content, true, {}, false, '操作提醒', undefined, {
        confirm: '确定',
      });
    }
  };
  render() {
    return (
      <iframe
        ref={ref => (this.track_log = ref)}
        width="100%"
        height="100%"
        src={`/api/Basic/Loc/getWaybillRelatedTruckInfo?req=${JSON.stringify(this.param)}`}
        title="轨迹跟踪"
      />
    );
  }
}
export default index;
