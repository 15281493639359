/**
 * 表格筛选行 查询按钮后面的 报表说明
 * Author: liubingyuan
 * Ex: 报表中心 - 线路报表 - 线路货量统计表
 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Icon, PopUp, ShowInfo } from 'components';

import { contentConf } from './constant';
import { prefixCls } from './index.scss';

export default class DeScription extends PureComponent {
  static defaultProps = {
    children: '报表说明',
    title: '报表说明',
  };
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    category: PropTypes.string,
    tab: PropTypes.string,
    children: PropTypes.any,
  };
  constructor(prop) {
    super(prop);
    this.state = {};
  }

  handleDialog = () => {
    const { title, category, tab } = this.props;
    const key = [category, tab].join('_');
    const content = contentConf[key];
    const buttons = [{ key: 'cancel', name: '关闭', btnType: 'primary', cb: thisDialog => thisDialog.handleHide() }];
    new PopUp(ShowInfo, {
      showIcon: false,
      title,
      content,
      buttons,
    }).show();
  };
  render() {
    return (
      <div className={prefixCls} onClick={() => this.handleDialog()}>
        <Icon iconType="icon-info-o" />
        <span>{this.props.children}</span>
      </div>
    );
  }
}
