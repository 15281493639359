import React, { useState, useRef, useEffect, useContext, useCallback } from 'react';
import { Table, InputNumber, Select, Form, Link, Checkbox, Popconfirm, Grid } from '@gui/web-react';
import { IconOperationEditOutline } from '@gui/icon-react';
import { ModalDialog, PopUp, Button, Icon } from 'components';
import { showInfo, axiosIns } from 'utils';
import { getGoodsType } from 'utils/insurance';
import { WARN, CHECK } from 'constants';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { prefixCls } from './index.scss';
import moment from 'moment';

const FormItem = Form.Item;

const { Option } = Select;

const EditableContext = React.createContext({});

const quickOperateList = [
  { label: '20万', value: 200000 },
  { label: '50万', value: 500000 },
  { label: '100万', value: 1000000 },
];

// 获取货物类型最大值
const getAmountMax = (code, goodsTypeList) => {
  if (code) {
    const amountMax = goodsTypeList.find(e => e.code === code)?.amountMax;
    return amountMax;
  } else {
    return Infinity;
  }
};

// 可编辑行
function EditableRow(props) {
  const { children, record, className, ...rest } = props;
  const refForm = useRef(null);
  const getForm = () => refForm.current;

  return (
    <EditableContext.Provider value={{ getForm }}>
      <Form
        style={{ display: 'table-row' }}
        ref={refForm}
        wrapper="tr"
        wrapperProps={rest}
        className={`${className} editable-row`}
      >
        {children}
      </Form>
    </EditableContext.Provider>
  );
}

// 可编辑列
function EditableCell(props) {
  const { children, className, rowData, column, onHandleSave, goodsTypeList } = props;
  const ref = useRef(null);
  const refInput = useRef(null);
  const { getForm } = useContext(EditableContext);
  const [editing, setEditing] = useState(false);
  const handleClick = useCallback(
    e => {
      if (
        editing &&
        column.editable &&
        ref.current &&
        !ref.current.contains(e.target) &&
        !e.target.classList.contains('js-demo-select-option')
      ) {
        handleGoodsTypeChange(rowData[column.dataIndex]);
      }
    },
    [editing, rowData, column],
  );

  useEffect(() => {
    editing && refInput.current && refInput.current.focus();
  }, [editing]);

  useEffect(() => {
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [handleClick]);

  // 货物类型更改
  const handleGoodsTypeChange = code => {
    const form = getForm();
    const label = goodsTypeList.find(e => e.code === code)?.label;
    if (column.dataIndex === 'goodsType') {
      const values = { goodsType: code, guaranteeAmount: '', guaranteeCost: '' };
      label && onHandleSave && onHandleSave({ ...rowData, ...values });
      form && form.setFieldsValue({ guaranteeAmount: '' });
      setTimeout(() => setEditing(!editing), 300);
    }
  };
  const handleGoodsTypeBlur = () => {
    setEditing(!editing);
  };

  // 投保额度更改
  const handleGuaranteeAmountChange = () => {
    const form = getForm();
    form.validate([column.dataIndex], (errors, values) => {
      if (!errors || !errors[column.dataIndex]) {
        onHandleSave && onHandleSave({ ...rowData, guaranteeCost: '', ...values });
      }
    });
  };

  const handleGuaranteeAmountBlur = () => {
    setEditing(!editing);
  };

  if (editing) {
    const form = getForm();
    form.setFieldsValue({ guaranteeAmount: rowData.guaranteeAmount });
    return (
      <div ref={ref}>
        {column.dataIndex === 'goodsType' && (
          <Select
            value={rowData.goodsType}
            defaultPopupVisible
            placeholder="请选择货物类型"
            onChange={handleGoodsTypeChange}
            onBlur={handleGoodsTypeBlur}
            style={{ width: '120px' }}
            triggerProps={{
              autoAlignPopupWidth: false,
              autoAlignPopupMinWidth: true,
              position: 'bl',
            }}
          >
            {goodsTypeList.map(option => (
              <Option key={option.code} value={option.code}>
                {option.label}
              </Option>
            ))}
          </Select>
        )}
        {column.dataIndex === 'guaranteeAmount' && (
          <FormItem
            style={{ marginBottom: 0 }}
            labelCol={{
              span: 0,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValue={rowData[column.dataIndex]}
            field={column.dataIndex}
            onChange={handleGuaranteeAmountChange}
            onBlur={handleGuaranteeAmountBlur}
          >
            <InputNumber
              placeholder="请输入保额"
              hideControl
              autoFocus
              min={0}
              max={getAmountMax(rowData?.goodsType, goodsTypeList)}
              style={{ width: '90px' }}
            />
          </FormItem>
        )}
      </div>
    );
  }

  return (
    <div
      className={column.editable ? `editable-cell ${className}` : className}
      onClick={() => column.editable && setEditing(!editing)}
    >
      {children}
    </div>
  );
}

// 批量购买弹窗
function FreightGuaranteeBatchModal(props) {
  let modalDialogRef = useRef(null);
  let formRef = useRef(null);
  const [readonly, setReadonly] = useState(false);
  const [computeLoading, setComputeLoading] = useState(false);
  const [goodsTypeList, setGoodsTypeList] = useState([]);
  const { title = '货物安全管理保障服务', autoDestroy, batchWaybillInfo, isShow = true, ...rest } = props;
  const bottom = renderBottom(handleSubmit, handleCancel);
  const [batchData, setBatchData] = useState(
    batchWaybillInfo.map(e => {
      return {
        ...e,
        goodsType: '1',
      };
    }),
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [readed, setReaded] = useState(false);

  const defaultColumns = [
    {
      title: '运单号',
      dataIndex: 'carBatch',
      width: 160,
    },
    {
      title: '货物名称',
      dataIndex: 'goodsName',
      width: 100,
    },
    {
      title: '货物类型',
      dataIndex: 'goodsType',
      editable: true,
      width: 190,
      render: (col, record, index) => (
        <span>
          {goodsTypeList.find(e => record?.goodsType === e?.code)?.label}
          {!readonly && <IconOperationEditOutline style={{ width: '12px', marginLeft: '4px' }} />}
        </span>
      ),
    },
    {
      title: '投保额度(元)',
      dataIndex: 'guaranteeAmount',
      editable: true,
      width: 130,
      render: (col, record, index) => {
        return (
          <span>
            {record.guaranteeAmount}
            {!readonly && <IconOperationEditOutline style={{ width: '12px', marginLeft: '4px' }} />}
          </span>
        );
      },
    },
    {
      title: '积分',
      dataIndex: 'guaranteeCost',
      width: 80,
    },
  ];
  const columns = readonly
    ? [
        ...defaultColumns,
        {
          title: '购买状态',
          dataIndex: 'status',
          width: 160,
          render: (col, record, index) =>
            record.status && <span style={{ color: record.status === '成功' ? 'green' : 'red' }}>{record.status}</span>,
        },
      ]
    : [
        ...defaultColumns,
        {
          title: '操作',
          dataIndex: 'op',
          render: (col, record, index) => (
            <Button onClick={() => handleCompute(record, index)} type="text">
              计算积分
            </Button>
          ),
        },
      ];

  useEffect(() => {
    // 获取货物类型
    const params = {
      customerId: window.group_id,
      platformCode: 'cyt',
      customerBranchId: batchData?.[0]?.createPoint?.id,
    };
    getGoodsType(params).then(val => {
      setGoodsTypeList(val);
    });
  }, []);

  // 提交
  function handleSubmit() {
    if (!readed) {
      showInfo(WARN, '请勾选已阅读!');
      return;
    } else if (batchData.some(e => !e?.goodsType)) {
      showInfo(WARN, '运单中存在未选择货物类型的运单!');
      return;
    } else if (batchData.some(e => !e?.guaranteeAmount)) {
      showInfo(WARN, '运单中存在投保额度为空的运单!');
      return;
    } else if (batchData.some(e => !e?.guaranteeCost)) {
      showInfo(WARN, '运单中存在积分未计算的运单!');
      return;
    }
    // 校验已阅读
    props.onSaveSuccess &&
      props.onSaveSuccess(batchData, goodsTypeList, msgs => {
        const newData = [...batchData].map((e, i) => {
          return {
            ...e,
            status: msgs[i],
          };
        });
        setBatchData(newData);
        setReadonly(true);
      });
  }

  // 取消
  function handleCancel() {
    modalDialogRef && modalDialogRef.handleHide();
    props.handleCancel && props.handleCancel();
  }

  // 底部按钮
  function renderBottom(submit, cancel) {
    return (
      <div className="bottom-div">
        {!readonly && (
          <Button type="primary" onClick={submit}>
            确定
          </Button>
        )}
        <Button onClick={cancel}>{!readonly ? '取消' : '关闭'}</Button>
      </div>
    );
  }

  // table row 更改
  function handleSave(row) {
    const newData = [...batchData];
    const index = newData.findIndex(item => row.key === item.key);
    newData.splice(index, 1, {
      ...newData[index],
      ...row,
    });
    setBatchData(newData);
  }

  // 保费计算
  const handleCompute = (record, index) => {
    const newData = [...batchData];
    const goodsType = record?.goodsType;
    const guaranteeAmount = record?.guaranteeAmount;
    if (!guaranteeAmount) {
      showInfo(WARN, '请输入投保额度!');
      return;
    }
    setComputeLoading(true);
    const { goodsName, plateNumber, createPoint } = record || { goodsName: '', truckId: '', createPoint: {} };
    const params = {
      customerId: window.group_id,
      goodsName,
      goodsType,
      guaranteeAmount,
      plateNumber,
      platformCode: 'cyt',
      customerBranchId: createPoint?.id,
    };
    axiosIns
      .postRequest({
        url: '/insurance/freight/assurance/services/premium',
        data: params,
      })
      .then(res => {
        const { success, data } = res;
        if (success) {
          newData[index].guaranteeCost = data?.totalActualScore;
          setBatchData(newData);
        }
      })
      .finally(() => {
        setComputeLoading(false);
      });
  };

  // 批量计算
  const handleBatchCompute = () => {
    const newData = batchData.filter(e => {
      return selectedRowKeys.includes(e.key);
    });
    if (newData.some(e => !e.guaranteeAmount)) {
      showInfo(WARN, '已选运单中存在投保额度为空的运单！');
      return;
    }
    batchData.forEach((e, i) => {
      if (selectedRowKeys.includes(e.key)) {
        handleCompute(e, i);
      }
    });
  };

  // 获取pdf
  const getPdf = (goodsType, pdfUrl, pdfPage) => {
    const file = goodsTypeList.find(e => e.code === goodsType)?.[pdfUrl];
    const page = goodsTypeList.find(e => e.code === goodsType)?.[pdfPage];
    return { file, page };
  };

  // 已阅读
  const handleRead = (goodsType, pdfUrl, pdfPage) => {
    const { file, page } = getPdf(goodsType, pdfUrl, pdfPage);
    const pdfModalDialog = new PopUp(ModalDialog, {
      closable: false,
      content: (
        <div style={{ height: '600px', overflowY: 'auto' }}>
          <Document file={file}>
            {new Array(page).fill().map((_, index) => (
              <Page key={`page_${index + 1}`} scale={1.5} pageNumber={index + 1} height={600} />
            ))}
          </Document>
        </div>
      ),
      bottom: [
        <Button
          type="primary"
          key={1}
          onClick={() => {
            pdfModalDialog.close();
          }}
        >
          我已阅读并同意
        </Button>,
      ],
      bottomStyle: { textAlign: 'center' },
      isShow: true,
      style: { height: 600 },
    });
    pdfModalDialog.show();
  };

  // 保额快捷操作
  const handleSetAmount = val => {
    formRef.setFieldsValue({ guaranteeAmount: val });
  };

  const popconfirmContent = (
    <Form ref={r => (formRef = r)} labelCol={{ span: 6 }} wrapperCol={{ span: 12 }}>
      <FormItem label="货物类型" field="goodsType">
        <Select placeholder="请选择货物类型">
          {goodsTypeList.map(option => (
            <Option key={option.code} value={option.code}>
              {option.label}
            </Option>
          ))}
        </Select>
      </FormItem>
      <FormItem label="保额" dependencies={['goodsType']}>
        <Form.Item field="guaranteeAmount">
          {() => {
            const goodsTypeValue = formRef && formRef.getFieldValue && formRef.getFieldValue('goodsType');
            return (
              <Grid.Row gutter={8}>
                <Grid.Col span={12}>
                  <Form.Item field="guaranteeAmount">
                    <InputNumber
                      placeholder="请输入保额"
                      max={getAmountMax(goodsTypeValue, goodsTypeList)}
                      hideControl
                      min={0}
                    />
                  </Form.Item>
                </Grid.Col>
                <Grid.Col span={12} style={{ fontSize: '14px', marginTop: '6px' }}>
                  元
                </Grid.Col>
              </Grid.Row>
            );
          }}
        </Form.Item>
        {quickOperateList.map(e => {
          return (
            <Button type="text" onClick={() => handleSetAmount(e.value)}>
              {e.label}
            </Button>
          );
        })}
      </FormItem>
    </Form>
  );

  // 总计
  const summary = currentData => {
    return (
      <Table.Summary.Row fixed="bottom">
        <Table.Summary.Cell>总计</Table.Summary.Cell>
        <Table.Summary.Cell colSpan={4} />
        <Table.Summary.Cell type="error" bold>
          {currentData.reduce((prev, next) => Number(prev) + Number(next.guaranteeCost), 0)}
        </Table.Summary.Cell>
        <Table.Summary.Cell />
      </Table.Summary.Row>
    );
  };
  // cell
  const renderEditableCell = useCallback(
    cellProps => {
      return <EditableCell {...cellProps} goodsTypeList={goodsTypeList} />;
    },
    [goodsTypeList],
  );

  const batchTable = (
    <div>
      <div style={{ display: 'flex', marginBottom: 10 }}>
        {!readonly ? (
          <div>
            <Popconfirm
              title="批量设置"
              disabled={!selectedRowKeys.length}
              style={{ width: '420px', maxWidth: '420px' }}
              icon={''}
              content={popconfirmContent}
              onOk={() => {
                formRef.validate().then(val => {
                  const goodsType = val?.goodsType || null;
                  const newData = [...batchData].map(e => {
                    if (selectedRowKeys.includes(e.key)) {
                      const amountMax = getAmountMax(goodsType || e?.goodsType, goodsTypeList);
                      return {
                        ...e,
                        goodsType: goodsType || e?.goodsType,
                        // eslint-disable-next-line no-nested-ternary
                        guaranteeAmount: val?.guaranteeAmount
                          ? val?.guaranteeAmount > amountMax
                            ? amountMax
                            : val?.guaranteeAmount
                          : e?.guaranteeAmount,
                        guaranteeCost: goodsType || val?.guaranteeAmount ? '' : e?.guaranteeCost,
                      };
                    } else {
                      return { ...e };
                    }
                  });
                  setBatchData(newData);
                });
              }}
            >
              <Button type="primary" disabled={!selectedRowKeys.length}>
                批量设置
              </Button>
            </Popconfirm>
            <Button
              type="primary"
              disabled={!selectedRowKeys.length}
              style={{ marginLeft: 10 }}
              onClick={handleBatchCompute}
            >
              批量计算
            </Button>
          </div>
        ) : (
          <div className={`${prefixCls}__tip`}>
            <div>
              <Icon iconType={CHECK} />
            </div>
            <div className="batch-tip-num-txt">
              <span>批量操作已完成，</span>
              <span className="spe-font" style={{ color: '#F12222' }}>
                {batchData.filter(item => item.status !== '成功').length}
              </span>
              <span>个操作失败，</span>
              <span className="spe-font" style={{ color: '#1DBB73' }}>
                {batchData.filter(item => item.status === '成功').length}
              </span>
              <span>个操作成功！</span>
            </div>
          </div>
        )}
      </div>
      <Table
        className={`${prefixCls}-batch-table`}
        loading={computeLoading}
        data={batchData}
        components={{
          body: {
            row: EditableRow,
            cell: renderEditableCell,
          },
        }}
        scroll={{ y: 300 }}
        pagination={false}
        rowSelection={{
          type: 'checkbox',
          columnWidth: 70,
          checkboxProps: () => {
            return {
              disabled: readonly,
            };
          },
          onChange: keys => {
            setSelectedRowKeys(keys);
          },
        }}
        columns={columns.map(column =>
          column.editable
            ? {
                ...column,
                onCell: () => ({
                  onHandleSave: handleSave,
                }),
              }
            : column,
        )}
        border={{
          wrapper: true,
          cell: true,
        }}
        summary={summary}
      />
      {!readonly && (
        <div style={{ marginTop: '10px' }}>
          <Checkbox value={readed} onChange={e => setReaded(e)} style={{ marginRight: '10px' }} />
          我已阅读
          {goodsTypeList.map(e => {
            const type = [
              { code: 'advicePdf', page: 'advicePage', label: '投保告知书' },
              { code: 'guaranteePdf', page: 'guaranteePage', label: '保障范围' },
            ];
            return type.map(i => {
              return (
                <Link
                  onClick={() => {
                    handleRead(e.code, i?.code, i?.page);
                  }}
                >
                  《{e?.label}
                  {i?.label}》
                </Link>
              );
            });
          })}
          并同意购买
        </div>
      )}
    </div>
  );
  return (
    <ModalDialog
      isShow={isShow}
      title={title}
      autoDestroy={autoDestroy}
      content={batchTable}
      ref={r => (modalDialogRef = r)}
      bottom={bottom}
      style={{ width: 900 }}
      {...rest}
    />
  );
}

export default FreightGuaranteeBatchModal;

// 批量购买货运险校验
export const batchFreightGuaranteeValidate = state => {
  const batchWaybillInfoValidate = state.map(e => {
    const { carBatch, goodsName, startPlace, toPlace, plateNumber, planTruckT, truckT, planArrT, completeTime } = e;
    // 计算 truckT 之后的 24 小时后的时间
    const truckTPlus24Hours = moment(truckT || planTruckT)
      .clone()
      .add(24, 'hours');
    // 计算 a 和 b 之间的时间差（以秒为单位）
    const differenceInSeconds = moment(completeTime || planArrT).diff(moment(truckT || planTruckT), 'seconds');
    // 定义一周的秒数
    const oneWeekInSeconds = 7 * 24 * 60 * 60;
    if (!goodsName || !startPlace || !toPlace || !plateNumber || (!truckT && !planTruckT)) {
      return {
        key: 'required',
        success: false,
        carBatch,
        msg: '货物名称、出发地、目的地、车牌号、实际发车时间或计划发车时间不能为空！',
      };
    } else if (moment().isAfter(truckTPlus24Hours)) {
      return {
        key: 'depart24Hours',
        success: false,
        carBatch,
        msg: '该运单已发车超过24小时，不可购买货物安全管理保障服务！',
      };
    } else if ((completeTime || planArrT) && moment(completeTime || planArrT).isBefore(truckT || planTruckT)) {
      return {
        key: 'early',
        success: false,
        carBatch,
        msg: '到达时间不能早于发车时间！',
      };
    } else if (differenceInSeconds > oneWeekInSeconds) {
      return {
        key: 'sevenDays',
        success: false,
        carBatch,
        msg: '货物安全管理保障服务最长保障7天！',
      };
    } else {
      return {
        success: true,
      };
    }
  });
  return batchWaybillInfoValidate;
};

// 整理批量购买货运险校验结果
export const rangeArray = arr => {
  const resultMap = {};

  arr.forEach(item => {
    if (!resultMap[item.key]) {
      resultMap[item.key] = { key: item.key, number: [], msg: item.msg };
    }
    resultMap[item.key].number.push(item.carBatch);
  });
  return Object.values(resultMap);
};
