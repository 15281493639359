exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".approver-selection{display:flex}.approver-selection-waring{margin-left:12px}.approver-selection-content{margin-right:22px;margin-left:12px;flex:1}.approver-selection .content-title{font-size:16px;font-weight:600;margin-bottom:22px}.approver-selection-line-item{display:flex;align-items:flex-start}.approver-selection-item-title{padding:8px 24px;background:hsla(0,0%,60%,.6);border-radius:4px}.approver-selection-item-value{margin-left:24px}.approver-selection .select-value-label{margin-right:8px;color:red}.approver-selection .select-value{display:flex}.approver-selection .content-list{max-height:400px;overflow-y:scroll;padding-left:11px}.approver-selection .gui-timeline-item-dot{width:22px;height:22px;left:-6px;background-color:#999}.approver-selection .transform-triangle{width:0;height:0;border-width:8px;margin-top:11px;border-style:solid;border-color:transparent hsla(0,0%,60%,.6) transparent transparent}.approver-selection .fn-input-pure-wrap{width:220px}", ""]);

// exports
exports.locals = {
	"prefixCls": "approver-selection",
	"approver-selection": "approver-selection",
	"approver-selection-waring": "approver-selection-waring",
	"approver-selection-content": "approver-selection-content",
	"content-title": "content-title",
	"approver-selection-line-item": "approver-selection-line-item",
	"approver-selection-item-title": "approver-selection-item-title",
	"approver-selection-item-value": "approver-selection-item-value",
	"select-value-label": "select-value-label",
	"select-value": "select-value",
	"content-list": "content-list",
	"gui-timeline-item-dot": "gui-timeline-item-dot",
	"transform-triangle": "transform-triangle",
	"fn-input-pure-wrap": "fn-input-pure-wrap"
};