import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { prefixCls } from './index.scss';
import { Table, Checkbox } from '@gui/web-react';
import { cloneDeep } from 'lodash';
import { Icon } from 'components';
export default class BiAmountDetail extends PureComponent {
  constructor(prop) {
    super(prop);
    this.state = {
      data: cloneDeep(prop.data),
      table_span: this.getTableSpan(prop.data),
      headCheck: {
        expense: {
          checked: true,
          indeterminate: false,
          disabled: false,
        },
      },
    };
  }
  getTableSpan = data => {
    const list = data?.sort?.((a, b) => a.type - b.type); // 默认进行排序
    const table_span = [];
    let preKey = '';
    let startIndex = 0;
    list?.forEach?.((item, index) => {
      const curKey = item.type;
      if (preKey === curKey) {
        table_span[index] = 0;
        // eslint-disable-next-line operator-assignment
        table_span[startIndex] = table_span[startIndex] + 1;
      } else {
        preKey = curKey;
        startIndex = index;
        table_span[index] = 1;
      }
    });
    return table_span;
  };
  handleHeaderCheck = () => {
    let checked = false;
    let checkedLen = 0;
    let indeterminate = false;
    let disabled = true;
    this.state.data?.forEach?.(item => {
      checked = checked || item.checked;
      if (item.checked) checkedLen += 1;
      disabled = disabled && item.disabled;
    });
    if (checkedLen && checkedLen !== (this.state?.data?.length ?? 0)) {
      indeterminate = true;
    }
    this.setState({
      headCheck: {
        ...this.state.headCheck,
        expense: {
          checked,
          indeterminate,
          disabled,
        },
      },
    });
  };
  setData = data => {
    this.setState(
      {
        data: cloneDeep(data),
        table_span: this.getTableSpan(data),
      },
      () => this.handleHeaderCheck(),
    );
  };
  handleCheckExpense = ({ col, record, index, checked, e }) => {
    const list = this.state.data;
    list[index].checked = checked;
    this.setState({
      data: [...list],
    });
    this.props.handleCheckAmountDetailExpense?.({ col, record, index, checked, e, data: list });
  };
  onChangeAll = (key, checked) => {
    if (checked) {
      this.setState(
        {
          headCheck: {
            ...this.state.headCheck,
            [key]: {
              checked: true,
              indeterminate: false,
            },
          },
        },
        () => this.props.onChangeAll?.(key, checked),
      );
    } else {
      this.setState(
        {
          headCheck: {
            ...this.state.headCheck,
            [key]: {
              checked: false,
              indeterminate: false,
            },
          },
        },
        () => this.props.onChangeAll?.(key, checked),
      );
    }
  };

  render() {
    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        width: 70,
        align: 'center',
        render: (col, record, index) => <span>{++index}</span>,
      },
      {
        title: '费用类型',
        dataIndex: 'type',
        align: 'center',
        render: (col, record, index) => {
          const obj = {
            children: col,
            props: {},
          };
          obj.props.rowSpan = this.state.table_span[index];
          return obj;
        },
      },
      {
        title:
          this.props.action !== 'show' ? (
            <Checkbox
              checked={this.state.headCheck?.expense?.checked}
              indeterminate={this.state.headCheck?.expense?.indeterminate}
              disabled={this.state.headCheck?.expense?.disabled}
              onChange={(...param) => this.onChangeAll('expense', ...param)}
            >
              费用项
            </Checkbox>
          ) : (
            `费用项`
          ),
        dataIndex: 'expense',
        align: 'center',
        className: 'expense-column',
        render: (col, record, index) => (
          <span>
            {record.showCheckbox ? (
              <Checkbox
                checked={record.checked}
                onChange={(checked, e) => {
                  this.handleCheckExpense?.({ col, record, index, checked, e });
                }}
                style={{ marginRight: 8 }}
                disabled={record.disabled}
              />
            ) : null}
            {record.expense}
          </span>
        ),
      },
      {
        title: (
          <span>
            金额(元)
            <Icon iconType="icon-help" tipsCls="icon-help" placement="top" tips="负数代表支出、正数代表收入" />
          </span>
        ),
        dataIndex: 'value',
        align: 'center',
      },
    ];

    return (
      <Table
        style={{ width: '100%' }}
        borderCell
        columns={columns}
        data={this.state.data}
        pagination={false}
        noDataElement={'暂无费用信息'}
        className={classnames({ [`${prefixCls}`]: true })}
      />
    );
  }
}
