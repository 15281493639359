export const tabType = {
  truck_pick: 'tr_pack',
  tr_up_loading_list: 'tr_pack',
  sign_pick: 'sign_pack',
  pickup_sign_pick: 'pickup_sign_pack',
  delivery_sign_pick: 'delivery_sign_pack',
  trans_sign_pick: 'trans_sign_pack',
  delivery_pick: 'delivery_pack',
  settle: 'settle_pack',
  settle_pay_arrival: 'settle_batch_arrival_pack',
  settle_crease: 'settle_batch_crease_pack',
  delivery_list: 'delivery_pack',
  settle_batch_arrival_pack: 'settle_batch_arrival_pack',
};
export const OrderTabType = {
  settle_batch_arrival_pack: 'settle_pay_arrival',
  settle_batch_crease_pack: 'settle_crease',
  tr_up_loading_list: 'truck_pick',
  delivery_list: 'delivery_pick',
};

// 将 category和tab 映射成固定的值
export const tableHeaderCategoryTab = {
  Batch_payment_batch_onway_enterpris_right: {
    category: 'BatchFeeApproval',
    tab: 'b_fee_list_for_onway_detail',
  },
  Batch_payment_batch_onway_bill_fee_detail: {
    category: 'BatchFeeApproval',
    tab: 'b_fee_list_for_onway_detail',
  },
};
