import React from 'react';
import { PopUp, ModalDialog, Label, TextArea, Button } from 'components';
import './index.scss';

export default function ({ title, className, labelText, maxLength, sureCallback, confirmBtnText = '确定' }) {
  let dialogRef;
  let textareaVal = '';
  const sure = () => {
    sureCallback && sureCallback(textareaVal.trim(), dialogRef);
  };
  const onChange = (event, value) => {
    event.stopPropagation();
    textareaVal = value;
  };
  const cancel = () => {
    dialogRef.handleHide();
  };
  const popUp = new PopUp(ModalDialog, {
    content: (
      <div className="remark_dialog">
        <div className="remark_text">
          <Label>{labelText}</Label>
        </div>
        <div>
          <TextArea maxLength={maxLength} onChange={onChange} />
        </div>
      </div>
    ),
    bottom: (
      <div className="remark_footer">
        <Button type="primary" onClick={sure}>
          {confirmBtnText}
        </Button>
        <Button onClick={cancel}>取消</Button>
      </div>
    ),
    classname: className,
    isShow: true,
    ref: r => (dialogRef = r),
    title,
  });
  popUp.show();
}
