exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-fault_detection{width:810px}.fn-fault_detection .fn-show_info__icon{display:none}.fn-fault_detection h3{color:#000;font-weight:700;font-size:13px;margin-bottom:20px;text-align:center}.fn-fault_detection img{display:block;max-width:700px;margin:14px auto 20px}.fn-fault_detection .main-p{font-weight:700;font-size:12px;position:relative;line-height:28px}.fn-fault_detection .main-p span{position:absolute;width:14px;height:22px;line-height:22px;top:3px;left:-20px}.fn-fault_detection .sub-p{margin-left:10px;font-weight:400;font-size:12px;text-indent:2em;line-height:22px}.fn-fault_detection .fn-dialog__center{max-height:480px;overflow-y:scroll;padding-right:0}.fn-fault_detection .fn-show_info__text{padding:0 20px}.fn-fault_detection .detection-download-clodop{max-width:320px}.fn-fault_detection .detection-download-clodop .ddc-half{align-items:center;display:inline-flex}.fn-fault_detection .detection-download-clodop .ddc-half .ddc-title{font-size:14px}.fn-fault_detection .detection-download-clodop .ddc-half .ddc-p{word-break:break-all}.fn-fault_detection .detection-download-clodop .fn-icon-info-o{width:32px;height:32px;font-size:32px;line-height:34px;margin-right:12px}.fn-fault_detection .detection-download-clodop .ddc-down{margin-top:20px}.fn-fault_detection .detection-download-clodop .ddc-color-tip{color:red}.fn-fault_detection-err{width:400px}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn-fault_detection",
	"fn-fault_detection": "fn-fault_detection",
	"fn-show_info__icon": "fn-show_info__icon",
	"main-p": "main-p",
	"sub-p": "sub-p",
	"fn-dialog__center": "fn-dialog__center",
	"fn-show_info__text": "fn-show_info__text",
	"detection-download-clodop": "detection-download-clodop",
	"ddc-half": "ddc-half",
	"ddc-title": "ddc-title",
	"ddc-p": "ddc-p",
	"fn-icon-info-o": "fn-icon-info-o",
	"ddc-down": "ddc-down",
	"ddc-color-tip": "ddc-color-tip",
	"fn-fault_detection-err": "fn-fault_detection-err"
};