import React from 'react';
import { IconCheckCircleFill, IconCloseCircleFill } from '@gui/web-react/icon';

export const renderTips = identified_terms_data => {
  try {
    const identified_data = JSON.parse(identified_terms_data || '{}');
    if (Object.keys(identified_data).length) {
      return (
        <div className="code-translation-tips">
          <p>
            <IconCheckCircleFill />
            识别成功
          </p>
          <p>财财理解到的内容：</p>
          {Object.keys(identified_data).map(key => (
            <p>
              {key}: {identified_data[key]}
            </p>
          ))}
        </div>
      );
    } else {
      return (
        <div className="code-translation-tips">
          <p>
            <IconCloseCircleFill />
            财财没理解您输入的内容，请您参照该列最上面表头?中的示例内容，重新描述该行含义注解。
          </p>
        </div>
      );
    }
  } catch (e) {
    console.log(e);
  }
};
