import PropTypes from 'prop-types';
import React from 'react';
import { ModalDialog, Flex, Select, Button, PureInput, AddrSug, Icon } from 'components';
import { fetchApi, showInfo } from 'utils';
import { ERROR, CHECK } from 'constants';
import { prefixCls } from './index.scss';

const PHONE_REGULAR = /^((\(\d{2,3}\))|(\d{3}\-))?1[3,4,5,6,7,8,9]\d{9}$/; // eslint-disable-line
const usages = [
  { key: 55, name: '企业货主' },
  { key: 56, name: '个人货主' },
];
export default class FillInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      validate: {},
    };
    const globaSearch = document.getElementsByClassName('all-search');
    globaSearch[0].classList.add('zIndex0');
  }
  static propTypes = {
    saleIds: PropTypes.array,
  };
  componentDidMount() {
    const globaSearch = document.getElementsByClassName('all-search');
    globaSearch[0].classList.add('zIndex0');
  }
  componentWillUnmount() {
    const globaSearch = document.getElementsByClassName('all-search');
    globaSearch[0].classList.remove('zIndex0');
  }
  onChange = key => e => {
    let val = e && e.target ? e.target.value : e;
    if (key === 'address' && val && !val.poi) {
      val = '';
    }
    const state = { ...this.state };
    state[key] = val;
    if (+state.usage === 56) {
      state.company_name = state.name;
    }
    if ((key === 'telephone' || key === 'emergency_call') && val && state.telephone === state.emergency_call) {
      state.validate[key] = '“负责人手机号”和“紧急联系电话”不能重复！';
    }
    this.setState(state);
  };
  onSubmit = () => {
    const check = this.onBlur('telephone')();
    if (check) {
      return;
    }
    const { loading, validate, ...others } = this.state;
    this.setState({
      loading: true,
    });
    fetchApi('Basic/Org/completeTrialInfo', {
      method: 'POST',
      body: {
        req: {
          ...others,
        },
      },
    }).then(res => {
      if (res.errno === 0) {
        window.indexInfo &&
          window.indexInfo(() => {
            this.setState({
              loading: false,
            });
            window.softReloading = false;
            window.NEED_TO_UPDATE_USER_INFO = true;
            showInfo(CHECK, '保存成功！');
            this.handleHide();
          });
      } else {
        this.setState({
          loading: false,
        });
        showInfo(ERROR, res.errmsg || '保存失败！');
      }
    });
  };
  enforcementIdFilter = keyword => {
    const { saleIds = [] } = this.props;
    if (keyword.length < 2) {
      return [];
    }
    return saleIds.filter(({ name }) => name.includes(keyword));
  };
  addressFilter = search =>
    fetchApi('/Basic/Amap/xzqh', {
      method: 'POST',
      body: {
        req: {
          search,
          slice_num: '20',
          ext: {
            lelvel: 3,
          },
        },
      },
    });
  onBlur = key => () => {
    const val = this.state[key];
    const validate = {
      ...this.state.validate,
    };
    if (val && !PHONE_REGULAR.test(val)) {
      validate[key] = '请填写正确的手机号！';
    } else if (val && this.state.emergency_call === this.state.telephone) {
      validate[key] = '“负责人手机号”和“紧急联系电话”不能重复！';
    } else {
      validate[key] = '';
    }
    this.setState({
      validate,
    });
    return validate[key];
  };
  // tms 完善公司信息
  renderContent = () => {
    if (window.is_gms) {
      return this.renderGmsContent();
    }
    const {
      company_name: cn,
      point_name: pn,
      address,
      address_remark: remark,
      emergency_call: ec,
      name,
      telephone,
      enforcement_id: ei,
      loading,
      validate,
    } = this.state;
    const canClick = cn && pn && address && ec && telephone && name && !validate.telephone && !validate.emergency_call;
    return (
      <Flex className={prefixCls} dir="column" align="stretch">
        <Flex className="title" justify="center">
          完善公司信息
        </Flex>
        <Flex className="info-item" align="center">
          <Icon iconType="icon-company" />
          <PureInput value={cn} placeholder="请填写公司名称" onChange={this.onChange('company_name')} />
        </Flex>
        <Flex className="info-item" align="center">
          <Icon iconType="icon-building" />
          <PureInput value={pn} placeholder="请填写开单网点名称" onChange={this.onChange('point_name')} />
        </Flex>
        <Flex className="info-item" align="center">
          <Icon iconType="icon-avtor" />
          <PureInput placeholder="请填写公司负责人姓名" value={name} onChange={this.onChange('name')} />
        </Flex>
        <Flex className="info-item" align="center">
          <Icon iconType="icon-iphone" />
          <PureInput
            placeholder="请填写公司负责人手机号"
            value={telephone}
            onChange={this.onChange('telephone')}
            onBlur={this.onBlur('telephone')}
          />
        </Flex>
        {validate.telephone && <span className="err">{validate.telephone}</span>}
        <Flex className="info-item" align="center">
          <Icon iconType="icon-phone-line" />
          <PureInput
            placeholder="请填写紧急联系人手机号（不能与负责人手机号重复）"
            value={ec}
            onChange={this.onChange('emergency_call')}
            onBlur={this.onBlur('emergency_call')}
          />
        </Flex>
        {validate.emergency_call && <span className="err">{validate.emergency_call}</span>}
        <Flex className="info-item address" align="center">
          <Icon iconType="icon-yingyewangdian" />
          <AddrSug
            defaultValue={address}
            handleSelected={this.onChange('address')}
            blurCheck
            mapIcon={false}
            fullName
            placeholder="请填写公司地址"
          />
          <PureInput value={remark} placeholder="请填写地址备注" onChange={this.onChange('address_remark')} />
        </Flex>
        <Flex className="info-item" align="center">
          <Icon iconType="icon-user-add" />
          <Select
            menuClassName="enforcement_id-menu"
            value={ei}
            silent
            filter={this.enforcementIdFilter}
            placeholder="请选择您的市场推广人姓名（选填项）"
            onChange={this.onChange('enforcement_id')}
          />
        </Flex>
        <Button disabled={!canClick} classname="btn" loading={loading} onClick={this.onSubmit} type="positive">
          确定
        </Button>
      </Flex>
    );
  };
  // gms 完善信息
  renderGmsContent = () => {
    // const { status } = window.bms_info || {}
    const { company_name: cn, address, address_remark: remark, name, telephone, loading, validate, usage } = this.state;
    const canClick = cn && address && telephone && name && !validate.telephone && usage;
    return (
      <Flex className={prefixCls} dir="column" align="stretch">
        <Flex className="title" justify="center">
          完善信息
        </Flex>
        <Flex className="info-item" align="center">
          <Icon iconType="icon-manager1" />
          <PureInput placeholder="负责人姓名" value={name} onChange={this.onChange('name')} />
        </Flex>
        <Flex className="info-item" align="center">
          <Icon iconType="icon-menu" />
          <Select data={usages} placeholder="用户类型" value={usage} onChange={this.onChange('usage')} />
        </Flex>
        {+usage === 55 && (
          <Flex className="info-item" align="center">
            <Icon iconType="icon-building" />
            <PureInput value={cn} placeholder="公司名称" onChange={this.onChange('company_name')} />
          </Flex>
        )}
        <Flex className="info-item" align="center">
          <Icon iconType="icon-phone-line" />
          <PureInput
            placeholder="负责人手机号"
            value={telephone}
            onChange={this.onChange('telephone')}
            onBlur={this.onBlur('telephone')}
          />
        </Flex>
        {validate.telephone && <span className="err">{validate.telephone}</span>}
        <Flex className="info-item address" align="center">
          <Icon iconType="icon-yingyewangdian" />
          <AddrSug
            defaultValue={address}
            handleSelected={this.onChange('address')}
            blurCheck
            mapIcon={false}
            fullName
            placeholder="请填写公司地址"
          />
          <PureInput value={remark} placeholder="请填写地址备注" onChange={this.onChange('address_remark')} />
        </Flex>
        <Button disabled={!canClick} classname="btn" loading={loading} onClick={this.onSubmit} type="positive">
          确定
        </Button>
      </Flex>
    );
  };
  handleHide = () => {
    this.modal && this.modal.handleHide();
  };
  render() {
    return (
      <ModalDialog
        content={this.renderContent()}
        ref={ref => (this.modal = ref)}
        closable
        maskClosable={false}
        style={{ width: 458 }}
        isShow
        {...this.props}
      />
    );
  }
}
