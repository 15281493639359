import React, { Fragment } from 'react';
import { SelectDrop, PureInput, Select } from 'components';
import truckSug from 'utils/sug/truck';
import driverSug, { mainDriverHeader } from 'utils/sug/driver';
import Card from '../card';
import InfoItem from '../infoItem';

const bscjson = { 1: '干线', 3: '短驳', 5: '送货', 7: '提货' };
const formateFunc = res => ({
  reqData: res.res
    ? res.res.data.map(item => {
        const receipt_num =
          res.req.tab === 'abn_task_sug' ? `${bscjson[item.bsc_type]}` : `${bscjson[item.bsc_type]}批次`;
        return {
          ...item,
          receipt_num: receipt_num || '',
        };
      })
    : [],
});
const orderNumSug = {
  url: 'Table/Suggest/commonSug',
  para: {
    category: 'Order',
    tab: 'od_all',
    search: '',
    search_key: 'order_num',
    query: {},
    page_num: 0,
    page_size: 10,
    fields: [
      'id',
      'od_link_id',
      'od_id',
      'od_basic_id',
      'order_num',
      'receipt_num',
      'billing_date',
      'g_name',
      'g_pkg',
      'num',
      'cor_name',
      'cee_name',
      'cor_id',
      'cee_id',
      'chain_trans_dn_carrier_id',
    ],
    filter: { order_status: 1, is_deserted: 0 },
    sort: { order_create_t: 'desc' },
  },
  data_key: 'res,data',
  key: 'search',
};
// const transportSug = {
//   url: 'Table/Suggest/commonSug',
//   para: {
//     category: 'Batch', tab: 'abn_task_sug', search: '', search_key: 'car_batch', query: {}, page_num: 0, page_size: 10, fields: ['id', 'b_link_id', 'car_batch', 'bsc_type', 'b_tr_num', 'b_dr_name', 'b_dr_phone', 'create_time'],
//     filter: { blk_status: 2, transtask_flag: [1, 2] },
//     sort: { create_time: 'desc' },
//     type: 3
//   },
//   data_key: 'res,data', key: 'search',
//   formateFunc
// }
const batchtSug = {
  url: 'Table/Suggest/commonSug',
  para: {
    category: 'Batch',
    tab: 'abn_batch_sug',
    search: '',
    search_key: 'car_batch',
    query: {
      0: {
        0: {
          0: { blk_status: 1, transtask_flag: 0 },
          1: {
            0: { _logic: 'not', transtask_flag: 0 },
            // 1: {
            //   0: {
            //     transtask_flag: 2,
            //     b_source: 7,
            //   },
            //   _logic: 'not',
            // },
            2: {
              0: { blk_status: [1, 2], bsc_status: 1 },
              1: { blk_status: 0, dispatch_carrier_st: [400, 700] },
              _logic: 'or',
            },
          },
          _logic: 'or',
        },
        _logic: 'and',
      },
    },
    page_num: 0,
    page_size: 10,
    fields: [
      'id',
      'b_link_id',
      'car_batch',
      'bsc_type',
      'b_tr_num',
      'b_dr_name',
      'b_dr_phone',
      'create_time',
      'company_id',
    ],
    filter: {},
    sort: { create_time: 'desc' },
    type: 3,
  },
  data_key: 'res,data',
  key: 'search',
  formateFunc,
};
const receiptNumSug = {
  url: 'Table/Suggest/commonSug',
  para: {
    category: 'Order',
    tab: 'od_all',
    search: '',
    search_key: 'receipt_num',
    query: {},
    page_num: 0,
    page_size: 10,
    fields: [
      'id',
      'od_link_id',
      'od_id',
      'od_basic_id',
      'order_num',
      'receipt_num',
      'billing_date',
      'g_name',
      'g_pkg',
      'num',
      'cor_name',
      'cee_name',
      'cor_id',
      'cee_id',
      'chain_trans_dn_carrier_id',
    ],
    filter: { order_status: 1, is_deserted: 0 },
    sort: { order_create_t: 'desc' },
  },
  data_key: 'res,data',
  key: 'search',
};

export const truckHeader = [
  { key: 'tr_num', title: '车牌号' },
  { key: 'source_display', title: '合作关系' },
  { key: 'dr_name', title: '主驾司机' },
  { key: 'dr_tel', title: '主驾电话' },
];

function orderInfo({ set, data, disabled, setAbnormalTypeFn, abnormType, enums, addTypeList, i18nDict }) {
  const typeArr = {
    1: i18nDict.get('order', '运单'),
    3: `${i18nDict.get('batch', '')}批次`,
    4: i18nDict.get('batch', '任务'),
  };
  const defaultKey = num => [{ type: `${typeArr[num]}号` }];

  const onChange = key => e => {
    const val = e && e.target ? e.target.value : e;
    set('orderInfo', key, val);
  };
  const onChangeBatchInfo = key => val => {
    const newData = { ...data };
    if (key === 'b_tr_num') {
      newData.b_tr_num = val ? val.tr_num : '';
      if (val && val.dr_com_id) {
        newData.b_dr_name = val.dr_name;
        newData.b_dr_phone = val.dr_tel;
      }
    }
    if (key === 'b_dr_name' || key === 'b_dr_phone') {
      newData.b_dr_name = val ? val.name : '';
      newData.b_dr_phone = val ? val.tel : '';
    }
    set('orderInfo', key, newData);
  };
  let bscType = bscjson[data.bsc_type] || '';
  if (data.car_batch && abnormType === '3') {
    bscType += i18nDict.get('batch', '批次');
  } else if (data.car_batch && abnormType === '4') {
    // bscType;
  }
  return (
    <Card title={`${typeArr[abnormType]}信息`}>
      <InfoItem label="" required className="selectTaggle">
        <SelectDrop
          defaultSelected={defaultKey(abnormType)}
          data={addTypeList}
          handleSelected={setAbnormalTypeFn}
          uniqueKey="type"
          showKey="type"
          selectOnly
          inputIconType="icon-arrow-down"
          required
          disabled={disabled}
          className="SelectDropTaggle"
        />
        {abnormType === '1' && (
          <SelectDrop
            defaultSelected={data.order_num}
            fetchApi={orderNumSug}
            handleSelected={onChange('order_num')}
            filterKey="order_num"
            uniqueKey="order_num"
            inputIconType="icon-arrow-down"
            placeholder={`请输入${i18nDict.get('order', '运单')}号`}
            required
            selectOnly
            disabled={disabled}
            minFetchLen="3"
            showKey="order_num"
            tableHeader={{ order_num: `${i18nDict.get('order', '运单')}号`, receipt_num: '回单号' }}
          />
        )}
        {abnormType === '3' && (
          <SelectDrop
            defaultSelected={data.car_batch}
            fetchApi={batchtSug}
            handleSelected={onChange('car_batch')}
            filterKey="car_batch"
            uniqueKey="car_batch"
            inputIconType="icon-arrow-down"
            placeholder={`请输入${i18nDict.get('batch')}批次号`}
            required
            selectOnly
            disabled={disabled}
            minFetchLen="3"
            showKey="car_batch"
            fetchFormateFunc={formateFunc}
            tableHeader={{
              car_batch: `${i18nDict.get('batch', '')}批次号`,
              receipt_num: `${i18nDict.get('batch', '')}批次类型`,
            }}
          />
        )}
        {abnormType === '4' && (
          <SelectDrop
            defaultSelected={data.car_batch}
            fetchApi={batchtSug}
            handleSelected={onChange('car_batch')}
            filterKey="car_batch"
            uniqueKey="car_batch"
            inputIconType="icon-arrow-down"
            placeholder="请输入任务号"
            fetchFormateFunc={formateFunc}
            required
            selectOnly
            disabled={disabled}
            minFetchLen="3"
            showKey="car_batch"
            tableHeader={{ car_batch: '任务号', receipt_num: '任务类型' }}
          />
        )}
      </InfoItem>
      {abnormType === '1' ? (
        <Fragment>
          <InfoItem label="回单号">
            <SelectDrop
              defaultSelected={data.receipt_num}
              fetchApi={receiptNumSug}
              handleSelected={onChange('receipt_num')}
              filterKey="receipt_num"
              uniqueKey="receipt_num"
              inputIconType="icon-arrow-down"
              placeholder="请输入回单号"
              minFetchLen="3"
              showKey="receipt_num"
              disabled={disabled}
              tableHeader={{ receipt_num: '回单号', order_num: `i18nDict.get('order', '运单')号` }}
            />
          </InfoItem>
          <InfoItem label="开单时间">
            <PureInput value={data.billing_date} disabled />
          </InfoItem>
          <InfoItem label="货物名称">
            <PureInput value={data.g_name} disabled />
          </InfoItem>
          <InfoItem label="包装">
            <PureInput value={data.g_pkg} disabled />
          </InfoItem>
          <InfoItem label="件数">
            <PureInput value={data.num} disabled />
          </InfoItem>
        </Fragment>
      ) : (
        <Fragment>
          <InfoItem label={`${typeArr[abnormType]}类型`}>
            <PureInput value={bscType} disabled />
          </InfoItem>
          <InfoItem label="创建时间">
            <PureInput value={data.create_time} disabled />
          </InfoItem>
          <InfoItem label="车牌号">
            <Select
              value={data.b_tr_num}
              filter={(keyword, select) =>
                truckSug({ cid: data.company_id, route: undefined, fetch: select.fetch, keyword })
              }
              header={truckHeader}
              disabled={disabled || !data.car_batch}
              map={false}
              compare="tr_num"
              format={value => value.map(item => item.tr_num || item).join(',')}
              onChange={onChangeBatchInfo('b_tr_num')}
            />
          </InfoItem>
          <InfoItem label="主驾司机">
            <Select
              value={data.b_dr_name}
              disabled={disabled || !data.car_batch}
              filter={(keyword, select) => driverSug({ cid: data.company_id, fetch: select.fetch, keyword })}
              format={value => value.map(item => item.name || item).join(',')}
              header={mainDriverHeader}
              map={false}
              compare="name"
              onChange={onChangeBatchInfo('b_dr_name')}
            />
          </InfoItem>
          <InfoItem label="主驾电话">
            <Select
              value={data.b_dr_phone}
              disabled={disabled || !data.car_batch}
              filter={(keyword, select) => driverSug({ cid: data.company_id, fetch: select.fetch, keyword })}
              format={value => value.map(item => item.tel || item).join(',')}
              header={mainDriverHeader}
              map={false}
              compare="tel"
              onChange={onChangeBatchInfo('b_dr_phone')}
            />
          </InfoItem>
        </Fragment>
      )}
    </Card>
  );
}

export default orderInfo;
