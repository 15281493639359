import PropTypes from 'prop-types';
import React, { PureComponent, forwardRef, useImperativeHandle, useRef } from 'react';
import BindAction from 'components/commoncomponents/pickorder/BindAction';
import { prefixCls } from './index.scss';
import classnames from 'classnames';

class Transfer extends PureComponent {
  constructor(prop) {
    super(prop);

    this.state = {
      // eslint-disable-next-line react/no-unused-state
      type: prop.type,
    };
  }

  static defaultProps = {
    type: 'add',
    pickOrderInfo: {},
  };
  static contextTypes = {
    renderBiPickOrder: PropTypes.func,
    getPick: PropTypes.func,
  };
  static propTypes = {
    type: PropTypes.string,
    pickOrderInfo: PropTypes.object,
  };

  componentDidMount = () => {};
  // TODO: 通过 decorator 注入
  // 薪资模式 -- 算薪人员-确认
  salaryFilePickConfirmBtnClick = async (key, value, validate, ...tables) => {
    this.props.onSelect?.(value?.tableInfo?.list);
  };
  // 工资条 -- 算薪人员-生成工资条
  salaryPayrollPickSubmitBtnClick = async (key, value, validate, ...tables) => {
    this.props.onSubmit?.(value?.tableInfo?.list);
  };
  // 挑单左侧向右侧移动回调
  handleLeftToRightCallback = (changeList, newLeftList, newRightList, tableHeader, cardform) => {
    this.props.handleLeftToRightCallback?.(changeList, newLeftList, newRightList, tableHeader, cardform);
  };
  // 挑单右侧向左侧移动回调
  handleRightToLeftCallback = (changeList, newLeftList, newRightList, tableHeader, cardform) => {
    this.props.handleRightToLeftCallback?.(changeList, newLeftList, newRightList, tableHeader, cardform);
  };

  render() {
    const { renderBiPickOrder } = this.context;
    return (
      <div className={classnames(`${prefixCls}__page`, this.props.className)} ref={ref => (this.wrap = ref)}>
        {renderBiPickOrder(this)}
      </div>
    );
  }
}

const TransferCom = BindAction('Transfer' /** 这个参数随便写的，也不知道有啥用 */, Transfer);

export default forwardRef((props, ref) => {
  const transfComRef = useRef();
  useImperativeHandle(ref, () => ({
    execPickFunc: (key, ...params) => {
      transfComRef.current?.pickContent?.[key]?.(...params);
    },
    getPick: () => transfComRef.current?.pickContent,
  }));
  return <TransferCom {...props} ref={transfComRef} />;
});
