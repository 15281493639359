exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".collection-page{height:100%}.collection-page .filter-wrap{display:flex}.collection-page .filter-wrap .query-order-num{height:60px;width:200px;margin:0 10px}.collection-page .button-area{position:absolute;left:0;top:-3px;z-index:99}.collection-page .button-area .fn-btn-icon__left{margin-right:10px}.collection-page .table-wrap{position:relative;height:calc(100% - 68px)}", ""]);

// exports
exports.locals = {
	"prefixCls": "collection-page",
	"collection-page": "collection-page",
	"filter-wrap": "filter-wrap",
	"query-order-num": "query-order-num",
	"button-area": "button-area",
	"fn-btn-icon__left": "fn-btn-icon__left",
	"table-wrap": "table-wrap"
};