import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Dialog, PopUp, PopTip } from 'components';
import { fadeOut } from 'constants/animation';
import { animateClass, postData, setComVal } from 'utils';
import { ERROR, CHECK } from 'constants';
import { prefixCls } from './index.scss';
import actions from 'actions';

function showPopTip(type, content, callback) {
  new PopUp(PopTip, {
    type,
    content,
    onClose: () => {
      if (typeof callback === 'function') {
        callback();
      }
    },
  }).show();
}

export default class printSlnDialog extends PureComponent {
  constructor(prop) {
    super(prop);
    this.state = {};
  }

  static refCid = null;
  static defaultProps = {
    autoDestroy: true,
  };
  static propTypes = {
    dialogKey: PropTypes.string,
    dialogInfo: PropTypes.object,
    slnInfo: PropTypes.object,
    autoDestroy: PropTypes.bool,
    close: PropTypes.func,
    cid: PropTypes.string,
    tplId: PropTypes.string,
    tplType: PropTypes.string,
    callback: PropTypes.func,
    closePrintSln: PropTypes.func,
    companyId: PropTypes.string,
    groupId: PropTypes.string,
  };

  UNSAFE_componentWillMount() {
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      dialogShow: true,
    });
    const dialogInfo = this.props.dialogInfo || {};
    if (dialogInfo.data !== undefined) {
      const urlParams = window.location.search;
      if (urlParams.indexOf('showRefSln') === -1) {
        delete dialogInfo.data.refer_sln;
      }
      this.setState({
        data: dialogInfo.data,
      });
    }
    actions.printActions.getHostId().then(printerHostId => {
      this.refCid = printerHostId;
      window.globalPrintHostId = printerHostId;
    });
  }

  componentWillUnmount = () => {};
  closeDialog = (where, slnRes) => {
    if (this.props.autoDestroy && typeof this.props.close === 'function') {
      animateClass(this.dialog, fadeOut, () => this.props.close());
    } else {
      // eslint-disable-next-line react/no-unused-state
      animateClass(this.dialog, fadeOut, () => this.setState({ dialogShow: false }));
    }
    this.props.closePrintSln && this.props.closePrintSln(where, slnRes);
  };
  testClick = (btnKey, cardFormData) => {
    if (!cardFormData.printer || !cardFormData.printer.length) {
      const nameElem = this.dialog.querySelector('[data-path="print_sln_name"]');
      nameElem.focus();
      nameElem.style.backgroundColor = '#fff5f5';
      return false;
    }
    const testSlnSet = {
      printer: cardFormData.printer,
      margin_left: cardFormData.margin_left,
      margin_top: cardFormData.margin_top,
      has_sln: 1,
    };
    this.props.closePrintSln && this.props.closePrintSln('confirm', testSlnSet);
  };
  save = (key, value, validate, cb) => {
    const url = '/Basic/Print/setPrintSln';
    const pack = value;
    if (!validate.isPass) {
      const label = validate.error.labelProps.children;
      showPopTip(ERROR, `请检查${label}`, cb);
      return false;
    }
    console.log(this.refCid);
    pack.group_id = this.props.groupId;
    pack.company_id = this.props.companyId;
    pack.cid = this.refCid || this.props.cid;
    pack.tpl_id = this.props.tplId;
    pack.tpl_type = this.props.tplType;
    const hideTplName = `${this.props.cid}_${this.props.tplId}`;
    pack.sln_name = pack.print_tpl_name || hideTplName;
    delete pack.curr_version;
    if (pack.state === 'switch_set_close') {
      pack.state = 0;
    } else {
      pack.state = 1;
    }
    postData(url, pack, res => {
      cb();
      const resData = res.data || {};
      if (resData.errno === undefined || resData.errno < 0) {
        showPopTip(ERROR, resData.errmsg || '失败');
        return false;
      }
      showPopTip(CHECK, '保存成功', this.props.callback);
      this.closeDialog('confirm', value);
      return false;
    });
  };
  printSln = dialogInfo => {
    const actionList = {};
    actionList.cancel = () => this.closeDialog('cancel');
    actionList.save = this.save;
    actionList.test = this.testClick;
    const button = dialogInfo.button.map(item => {
      if (item.key === 'cancel') return { ...item, type: 'default' };
      return { ...item, type: 'primary' };
    });
    const param = {
      actionList,
      button,
    };
    return Object.assign(dialogInfo, param);
  };
  handleSelectChange = (key, value, data) => {
    const newData = data;
    if (key === 'printer') {
      const nameElem = this.dialog.querySelector('[data-path="print_sln_name"]');
      nameElem.style = '';
      newData.printer = setComVal(data.printer, value || []);
      this.setState({
        data: newData,
      });
    }
    if (key === 'refer_sln') {
      const currValue = value[0] || {};
      newData.printer = setComVal(data.printer, currValue.printer || []);
      // newData.print_tpl_name = setComVal(data.print_tpl_name, currValue.sln_name || [])
      newData.margin_left = setComVal(data.margin_left, currValue.margin_left || []);
      newData.margin_top = setComVal(data.margin_top, currValue.margin_top || []);
      this.setState({
        data: newData,
      });
      this.refCid = currValue.cid || null;
    }
  };

  // getDialogInfo = (dialogKey) => this[dialogKey]
  render() {
    const { dialogKey } = this.props;
    const dialogInfo = this[dialogKey](this.props.dialogInfo);
    return (
      <div className={`${prefixCls} ${prefixCls}-set`} ref={r => (this.dialog = r)}>
        <Dialog
          dialogInfo={dialogInfo}
          loadBtnList={['save']}
          data={this.state.data}
          close={this.props.close}
          handleSelectChange={this.handleSelectChange}
          isShow
        />
      </div>
    );
  }
}
