module.exports = {
  specialFieldConf: {
    batch: ['b_remark', 'remark'],
    approval: ['approval_opinion'],
    // order: ['b_remark', 'remark', 'cor_name', 'cee_name'],
  },
  maxWidthConf: {
    // nowrap: ['order_num', 'cor_mobile', 'cee_mobile'],
    four: ['cor_name', 'cee_name', 'start', 'arr', 'g_name', 'delivery_mode'],
    eight: ['remark', 'cor_addr', 'cee_addr', 'b_delivery_remark'],
    full: [
      'cor_name',
      'cee_name',
      'start',
      'arr',
      'g_name',
      'delivery_mode',
      'remark',
      'cor_addr',
      'cee_addr',
      'b_delivery_remark',
    ],
    // full(全部) = four + eight
  },
  stretchConf: {
    remark: ['remark', 'b_delivery_remark'],
    address: ['cee_addr'],
  },
  numLetterConf: ['order_num', 'cor_mobile', 'cee_mobile', 'billing_day'],
};
