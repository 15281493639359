import React from 'react';
import ListPageConf from 'pages/ListPage';

const List = ({ category, tab, url = 'Table/Search/getDataList', query }) => {
  const config = {
    pageConfig: {
      listPage: true,
      tableInfo: {
        category,
        tab,
        body: {
          fetch_mode: 'all',
        },
      },
      url,
    },
  };
  const ListPage = ListPageConf(config);
  return <ListPage designateFilter={query} />;
};
export default List;
