exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".task-in-transit-fee-audit{display:flex;height:100%;overflow:hidden;flex-direction:column}.task-in-transit-fee-audit__setting label{font-size:14px;color:#333}.task-in-transit-fee-audit__setting .fn-switch-pure{margin-right:5px;vertical-align:-2px}.task-in-transit-fee-audit__setting .fn-btn{float:right;padding:5px 6px}.task-in-transit-fee-audit__list{flex:1 1 100%;margin-top:6px}.task-in-transit-fee-audit__opinion{flex:0 0 58px;margin-top:11px;margin-bottom:8px}.task-in-transit-fee-audit__opinion .fn-input-pure-wrap{width:100%;height:100%}", ""]);

// exports
exports.locals = {
	"prefixCls": "task-in-transit-fee-audit",
	"task-in-transit-fee-audit": "task-in-transit-fee-audit",
	"task-in-transit-fee-audit__setting": "task-in-transit-fee-audit__setting",
	"fn-switch-pure": "fn-switch-pure",
	"fn-btn": "fn-btn",
	"task-in-transit-fee-audit__list": "task-in-transit-fee-audit__list",
	"task-in-transit-fee-audit__opinion": "task-in-transit-fee-audit__opinion",
	"fn-input-pure-wrap": "fn-input-pure-wrap"
};