import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ModalDialog, CardForm, Button, SwitchSet } from 'components';
import { DataStripping, deleteProps /* getCardFormData */ } from 'utils';
import { prefixCls } from './index.scss';

export default class Dialog extends PureComponent {
  static defaultProps = {
    classname: '',
    isShow: false,
    getOriData: false,
    loadBtnList: [],
  };
  /*
   * dialogInfo: 弹框信息
   */
  static propTypes = {
    classname: PropTypes.string,
    dialogInfo: PropTypes.object,
    // actionList: PropTypes.object,
    data: PropTypes.object,
    handleSelectChange: PropTypes.func,
    isShow: PropTypes.bool,
    maskClosable: PropTypes.bool,
    close: PropTypes.func,
    getOriData: PropTypes.bool, // 是否获取处理后的数据, 否则不进行处理
    handleHideCallback: PropTypes.func,
    loadBtnList: PropTypes.array,
    onChange: PropTypes.func,
  };

  constructor(prop) {
    super(prop);
    this.state = {
      data: prop.data,
      isLoading: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log(nextProps, this.state.data);
    if (nextProps.data !== this.state.data) {
      this.setState({
        data: nextProps.data,
      });
    }
  }

  handleHide = () => {
    this._thisDialog.handleHide();
  };
  handleSelectChange = (key, value, form, data) => {
    if (typeof this.props.handleSelectChange === 'function') {
      this.props.handleSelectChange(key, value, data);
    }
  };
  renderDialogContent = (header, itemList, contentBotm) => {
    console.log(itemList, '234341312');
    const thisHeader = <div>{header}</div>;
    const handleEvents = {};
    handleEvents.handleSelected = this.handleSelectChange;
    handleEvents.onChange = this.props.onChange;
    return (
      <div>
        {header && thisHeader}
        <div className={`${prefixCls}__card_form`} ref={r => (this.cardFormWrap = r)}>
          <CardForm data={itemList} handleEvents={handleEvents} isHeader={false} ref={r => (this._cardForm = r)} />
        </div>
        {contentBotm}
      </div>
    );
  };
  ajaxFinish = () => {
    this.setState({ isLoading: false });
  };
  btnClickCallback = (key, callback) => {
    if (typeof callback === 'function') {
      const data = this._cardForm.getStateData();
      let formData = DataStripping(data);
      if (this.props.getOriData) {
        formData = data;
      }
      // const formData = getCardFormData(data || {})
      this.setState({ data });
      const validate = this._cardForm.validatePass();
      // 如果有switch set，switch set的值放入formData里面
      if (this.switchSet && this.switchSet.props.isOn) {
        formData.state = this.switchSet.state.switch_state;
      }
      if (this.props.loadBtnList.includes(key)) {
        this.setState({ isLoading: true });
      }
      callback(key, formData, validate, this.ajaxFinish);
    }
  };
  renderButtonList = (buttonList, callbackList, switchSet) => {
    console.log(this.props.loadBtnList.includes('save'));
    return (
      <div>
        {Object.entries(switchSet).map(([key, value]) => (
          <SwitchSet ref={r => (this.switchSet = r)} key={key} {...value} />
        ))}
        {Object.entries(buttonList).map(([key, value]) => (
          <Button
            key={key}
            loading={this.props.loadBtnList.includes(value.key) ? this.state.isLoading : undefined}
            disabled={!this.props.loadBtnList.includes(value.key) ? this.state.isLoading : undefined}
            onClick={(...res) => this.btnClickCallback(value.key, callbackList[value.key], ...res)}
            {...value}
          />
        ))}
      </div>
    );
  };
  handleHideCallback = () => {
    this.props.handleHideCallback && this.props.handleHideCallback();
  };

  render() {
    /**
     * dialogInfo = {
     *  title: '',
     *  contentHeader: {},
     *  button: {},
     *  actionList: {}
     * }
     * 非dialog props :
     classname: PropTypes.string,
     dialogInfo: PropTypes.object,
     // actionList: PropTypes.object,
     data: PropTypes.object,
     handleSelectChange: PropTypes.func,
     */
    const { dialogInfo: info = {}, classname } = this.props;
    const button = info.button || [];
    const switchSet = info.switchSet || [];
    const actionList = info.actionList || {};
    const dialogProps = deleteProps(this.props, 'classname', 'dialogInfo', 'data', 'handleSelectChange');
    console.log(this.state.data, '2312312321');
    // console.log('----------', info)
    return (
      <div className={`${prefixCls}__wrap ${classname}`}>
        <ModalDialog
          {...dialogProps}
          ref={d => (this._thisDialog = d)}
          title={info.title || ''}
          close={this.props.close}
          contentStyle={{ minWidth: '360px' }}
          content={this.renderDialogContent(info.contentHeader || '', this.state.data, info.contentBotm || '')}
          bottom={this.renderButtonList(button, actionList, switchSet)}
          handleHideCallback={this.handleHideCallback}
        />
      </div>
    );
  }
}
