import React from 'react';
import { FLOAT } from 'constants';
import { accountTypeKeyMap, allPayModeAccountType } from 'pages/FinanceController/constants/accountBook';

export const payModeHeader = {
  substract: {
    display: 'show',
    width: 40,
    title: 'icon-add-rad',
    type: 'Subtract',
  },
  pay_mode: {
    title: '收支方式',
    display: 'show',
    flexGrow: 1,
    type: 'SelectDrop',
  },
  /*
  truck_num: {
    title: '车牌号',
    display: 'hide',
    flexGrow: 1,
    type: 'SelectDrop',
    showKey: 'tr_num',
    tableHeader: {
      tr_num: '车牌号',
    },
    fetchApi: {
      url: 'Basic/Truck/truckSug',
      para: { page_size: 10, need_remainder: true, company_id: window.company_id },
      queryKey: 'query',
      key: 'tr_num'
    }
  },
  truck_remain_amount: {
    title: '车辆账本余额',
    display: 'hide',
    flexGrow: 1,
    type: 'Input'
  },
  driver_name: {
    title: '司机姓名',
    display: 'hide',
    flexGrow: 1,
    type: 'SelectDrop',
    showKey: 'name',
    tableHeader: {
      name: '姓名',
    },
    fetchApi: {
      url: 'Basic/Driver/driverSug',
      para: { page_size: 10, need_remainder: true, company_id: window.company_id, is_oil_card: true },
      queryKey: 'query',
      key: 'name'
    }
  },
  driver_remain_amount: {
    title: '司机账本余额',
    display: 'hide',
    flexGrow: 1,
    type: 'Input'
  },
  employee_name: {
    title: '员工姓名',
    display: 'hide',
    flexGrow: 1,
    type: 'SelectDrop',
    showKey: 'name',
    tableHeader: {
      tr_num: '姓名',
    },
    fetchApi: {
      url: 'Basic/Org/userSug',
      para: { slice_num: 10, need_remainder: true, company_id: window.company_id },
      queryKey: 'query',
      key: 'name'
    }
  },
  employee_remain_amount: {
    title: '员工账本余额',
    display: 'hide',
    flexGrow: 1,
    type: 'Input'
  },
  */
  amount: {
    title: '金额',
    display: 'show',
    flexGrow: 1,
    type: 'Input',
    pattern: FLOAT,
    trigger: 'blur',
  },
  bank_name: {
    title: '银行名称',
    display: 'show',
    flexGrow: 1,
    type: 'Input',
  },
  bank_num: {
    title: '银行卡号',
    display: 'show',
    flexGrow: 1,
    type: 'Input',
  },
  account_holder: {
    title: '开户人',
    display: 'show',
    flexGrow: 1,
    type: 'Input',
  },
  cheque_no: {
    title: '支票号码',
    display: 'show',
    flexGrow: 1,
    type: 'Input',
  },
  draft_no: {
    title: '汇票号码',
    display: 'show',
    flexGrow: 1,
    type: 'Input',
  },
  wechat_no: {
    title: '微信号',
    display: 'show',
    flexGrow: 1,
    type: 'Input',
  },
  alipay_no: {
    title: '支付宝号',
    display: 'show',
    flexGrow: 1,
    type: 'Input',
  },
  oil_card: {
    title: '油卡号码',
    display: 'show',
    type: 'SelectDrop',
    showKey: 'card_num',
    showHeader: true,
    tableHeader: {
      main_card_num: '主卡卡号',
      card_num: '卡号',
      balance: '余额',
      short_name: '网点',
      driver_name: '分配司机',
      truck_num: '分配车辆',
    },
    fetchApi: {
      url: 'Basic/FuelCard/fuelCardSug',
      para: { page_size: 10 },
      queryKey: 'query',
      key: 'card_num',
    },
  },
};

// 账本账户sug字段
export const accountTypeSugKeyArr = [
  'account_book_balance',
  'account_book_company_book_name',
  'account_book_company_name',
  'account_book_id',
  'account_book_is_suggest',
  'account_book_name',
  'account_book_type',
  'account_book_uuid',
  'account_book_company_id',
  'account_book_user_id',
  'account_book_tel',
];

export const accountTypeSugHeader = [
  {
    key: 'account_book_name',
    title: '推荐',
    render: item => {
      return <div style={{ textAlign: 'center' }}>{item.account_book_is_suggest ? '是' : ''}</div>;
    },
  },
  {
    key: 'account_book_name',
    title: '账本名称',
  },
  {
    key: 'account_book_uuid',
    title: '账本账号',
  },
  {
    key: 'account_book_balance',
    title: '账本余额',
  },
  {
    key: 'account_book_company_name',
    title: '网点',
  },
  {
    key: 'account_book_tel',
    title: '手机号',
  },
];

export const GROUP_ACCOUNT_TYPE = 1;
export const COMPANY_ACCOUNT_TYPE = 0;

export const accountTypeFontArr = allPayModeAccountType;

export const accountTypeFetchParams = accountTypeKeyMap;
