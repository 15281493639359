import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ShowInfo } from 'components';
import { WARN, ERROR, INFO, CHECK, NETERR } from 'constants';
import { directToWalletOnlineCharge } from 'utils/business/account/wallet/direct';
import { _config } from './config';
import { dialogClass } from './index.scss';

export default class BalanceEmpty extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    iconType: PropTypes.oneOf([WARN, ERROR, INFO, CHECK, NETERR]),
    btnKey: PropTypes.string,
    notice: PropTypes.any,
    close: PropTypes.func,
    callback: PropTypes.func,
  };

  constructor(prop) {
    super(prop);
    this.state = {};
  }

  handleContent = () => {
    const buttonKey = this.props.btnKey;
    return (
      <div className="show-info-content">
        <label className="text">{_config.content_text[buttonKey]}</label>
      </div>
    );
  };
  handleContentNotice = () => {
    const { notice } = this.props;
    return <div className="show-info-notice">{notice}</div>;
  };

  confirm = () => {
    this.cancel();
    setTimeout(directToWalletOnlineCharge, 0);
  };
  cancel = () => {
    this.showInfoDialog.handleHide();
    this.props.callback && this.props.callback();
  };

  handleSimpleMode = () => ({
    confirm: this.confirm,
    cancel: this.cancel,
  });

  render() {
    const iconType = this.props.iconType || INFO;
    const simpleText = { confirm: '前往充值', cancel: '我知道了' };
    return (
      <ShowInfo
        isShow
        ref={r => (this.showInfoDialog = r)}
        classname={dialogClass}
        type={iconType}
        close={this.props.close}
        title={this.props.title}
        content={this.handleContent()}
        contentNotice={this.handleContentNotice()}
        simpleMode={this.handleSimpleMode()}
        simpleText={simpleText}
      />
    );
  }
}
