import ApproverSelectionModal from './ApproverSelectionModal';
import { PopUp } from 'components';
import { getAppointManSelectData } from './api';

export const OPERATTION_MAP = {
  fee_report_apply: 'fee_report_apply', // 费用上报审批
  onway_payment_apply: 'onway_payment_apply', // 在途费用付款申请
  carrier_ca_ba_apply: 'carrier_ca_ba_apply', // 承运商运单对账单
  driver_ca_ba_apply: 'driver_ca_ba_apply', // 司机运单对账单
  car_ca_ba_apply: 'car_ca_ba_apply', // 车辆运单对账单
  create_expense: 'create_expense', // 创建报销单
  create_receipt: 'create_receipt', // 创建收据
  client_ca_ba_apply: 'client_ca_ba_apply', // 客户对账单
  loan_driver_apply: 'loan_driver_apply', // 司机借款申请
  repayment_driver_apply: 'repayment_driver_apply', // 司机还款申请
  loan_user_apply: 'loan_user_apply', // 员工借款申请
  repayment_user_apply: 'repayment_user_apply', // 员工还款申请
  loan_car_apply: 'loan_car_apply', // 车辆借款申请
  repayment_car_apply: 'repayment_car_apply', // 车辆还款申请
};

export function assignedApprover(params) {
  return new Promise((resolve, reject) => {
    const { title = '审批流程' } = params;
    const fetchApprover = async () => {
      try {
        return await getAppointManSelectData(params);
      } catch (error) {
        reject(error);
      }
    };

    fetchApprover().then(result => {
      if (!result || (Array.isArray(result) && !result.length)) {
        return resolve({});
      }
      // 需要手动添加一条结束的审批
      result.user_list.push([]);
      result.title.push('结束');
      result.audit_or.push(null);

      new PopUp(ApproverSelectionModal, {
        popName: `_${title}`,
        popClass: 'select-modal',
        opType: 'Add',
        modalTitle: { title },
        data: { ...result },
        onCancel: () => {
          return reject(undefined);
        },
        onSuccess: respose => {
          return resolve(respose);
        },
      }).show();
    });
  });
}
