import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select, PureInput } from 'components';
import { NUMBER } from 'constants';
import { post } from '@/utils/fetchApi';
import { EXEC_UNITS_ENUM, CONDITIONS_ENUM } from '../../constant';
import './index.scss';

// 渲染条件区域
const RenderRuleConditions = props => {
  const { data, handleRuleItemChange } = props;
  const { type, condition, value } = data;
  if (type === 'sug') {
    return (
      <Select
        value={condition}
        required
        map={false}
        compare="type"
        data={CONDITIONS_ENUM}
        onChange={v => handleRuleItemChange('condition', v?.type)}
      />
    );
  }
  if (type === 'date') {
    return (
      <PureInput
        value={value}
        required
        pattern={NUMBER}
        onChange={e => handleRuleItemChange('value', e?.target?.value)}
      />
    );
  }
  return null;
};

// 渲染详细数据区域
const RenderRuleValue = props => {
  const { data, enum_api, handleRuleItemChange } = props;
  const { type, array_value = [], unit, item: { ref_enum: refEnumKey } = {}, condition } = data;
  const [sugEnums, setSugEnums] = useState({});
  const { fetchApi = {}, uniqueKey = 'key', showKey = 'name' } = enum_api?.[refEnumKey] || {};
  const handleSugFilter = async keyword => {
    const { url, para, key } = fetchApi;
    if (!url || !key) return;
    try {
      const { errno, res = [] } = await post(url, { ...para, [key]: keyword });
      if (errno === 0) {
        setSugEnums({ ...sugEnums, [refEnumKey]: res });
      }
    } catch (e) {
      console.error(e);
    }
  };

  if (type === 'sug' && !['empty', 'not_empty'].includes(condition)) {
    return (
      <Select
        value={array_value}
        required
        filterOnInit
        map={false}
        multiple
        compare={uniqueKey}
        header={[showKey]}
        format={showKey}
        data={sugEnums[refEnumKey] || []}
        onChange={v => {
          const ids = v?.map(item => item[uniqueKey]);
          handleRuleItemChange('array_value', ids);
        }}
        filter={handleSugFilter}
      />
    );
  }
  if (type === 'date') {
    return (
      <Select
        value={unit}
        required
        map={false}
        compare="key"
        data={EXEC_UNITS_ENUM}
        onChange={v => handleRuleItemChange('unit', v?.key)}
      />
    );
  }
  return null;
};

const DataFilter = props => {
  const { setRuleFilterData, initialData = {}, enums = {}, enum_api = {} } = props;
  const rule = typeof initialData.rule === 'string' ? JSON.parse(initialData.rule) : initialData.rule;
  const defaultRule = {
    key: 'billing_date',
    condition: '',
    value: '',
    array_value: [],
    unit: '',
    type: 'date',
    item: {},
  };

  const [rules, setRules] = useState(rule || [defaultRule]);

  useEffect(() => {
    setRuleFilterData({ rules });
  }, [rules]);

  const handleRuleItemChange = (i, key, val) => {
    const currItem = rules[i];
    let compareItem = {
      ...currItem,
      [key]: val,
      type: val?.type ?? currItem.type,
    };
    // 如果type不同则清空其他输入框数据
    if (val?.type && val?.type !== currItem.type) {
      compareItem = {
        ...compareItem,
        condition: '',
        value: '',
        unit: '',
      };
    }
    rules[i] = compareItem;
    setRules([...rules]);
  };

  const handlerAddRule = () => {
    setRules([...rules, { ...defaultRule, key: '' }]);
  };

  const handleDeleteRule = i => {
    rules.splice(i, 1);
    setRules([...rules]);
  };

  const handerFilterNameChange = (i, v) => {
    handleRuleItemChange(i, 'item', v);
    handleRuleItemChange(i, 'key', v?.key);
  };

  return (
    <div className="fn-card__form rule_filter_data_filter">
      {rules.map((item, i) => {
        return (
          <div key={i} className="fn-card__form__card-item">
            <Select
              value={item.key}
              required
              map={false}
              compare="key"
              data={enums?.filter_key || []}
              onChange={v => handerFilterNameChange(i, v)}
            />
            <RenderRuleConditions data={item} handleRuleItemChange={(k, v) => handleRuleItemChange(i, k, v)} />
            <RenderRuleValue
              data={item}
              enum_api={enum_api}
              handleRuleItemChange={(k, v) => handleRuleItemChange(i, k, v)}
            />
            <i
              className="fn-icon fn-icon-close input-icon pointer"
              style={{ marginLeft: 5 }}
              onClick={() => handleDeleteRule(i)}
            />
          </div>
        );
      })}
      <div className="fn-card__form__card-item">
        <span onClick={handlerAddRule} className="pointer">
          <i className="fn-icon fn-icon-add input-icon" style={{ marginRight: 15 }} />
          添加条件
        </span>
      </div>
    </div>
  );
};

DataFilter.propTypes = {
  setRuleFilterData: PropTypes.func,
  initialData: PropTypes.object,
  enums: PropTypes.object,
};

export default DataFilter;
