exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".account-success-modal .title{font-size:20px;color:#131e27;text-align:center;line-height:24px;font-weight:500;margin-bottom:40px}.account-success-modal .title .fn-icon{font-size:56px;color:#53df9c;margin-right:44px;margin-top:36px}.account-success-modal .msg{width:100%;text-align:center;align-self:flex-start;font-size:14px;color:#131e27;line-height:26px}.account-success-modal .btn{margin-top:30px;width:378px;height:48px}", ""]);

// exports
exports.locals = {
	"prefixCls": "account-success-modal",
	"account-success-modal": "account-success-modal",
	"title": "title",
	"fn-icon": "fn-icon",
	"msg": "msg",
	"btn": "btn"
};