exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".receipt-log-wrap{display:flex;flex-direction:column;position:relative;height:100%}.receipt-log-wrap__list-wrap{flex-grow:1;width:100%}.receipt-log-wrap__footer{padding-top:6px;text-align:center}.receipt-log-wrap__operate-trace-info{display:flex}.receipt-log-wrap__operate-txt{word-break:keep-all;white-space:nowrap}.receipt-log-wrap__pic-list-item{margin-left:5px}", ""]);

// exports
exports.locals = {
	"prefixCls": "receipt-log-wrap",
	"receipt-log-wrap": "receipt-log-wrap",
	"receipt-log-wrap__list-wrap": "receipt-log-wrap__list-wrap",
	"receipt-log-wrap__footer": "receipt-log-wrap__footer",
	"receipt-log-wrap__operate-trace-info": "receipt-log-wrap__operate-trace-info",
	"receipt-log-wrap__operate-txt": "receipt-log-wrap__operate-txt",
	"receipt-log-wrap__pic-list-item": "receipt-log-wrap__pic-list-item"
};