import { bool, string, func, object } from 'prop-types';
import React, { PureComponent } from 'react';
import _ from 'lodash';
import { DateTimePicker } from 'components';
import classnames from 'classnames';
import { prefixCls } from './index.scss';
import { validateTips } from 'constants';
import { hideTip, showTip } from 'utils';

export default class DateTimeValidityPicker extends PureComponent {
  static propTypes = {
    required: bool,
    showTime: bool,
    defaultValue: string,
    classname: string,
    disabled: bool,
    type: string,
    style: object,
    onChange: func,
    onOpenChange: func,
    format: string,
    forceFormat: bool,
    placeholder: string,
    allowClear: bool,
    multiLine: bool,
    pattern: string,
    customValidity: func,
  };
  static defaultProps = {
    required: false,
  };
  state = {
    tip: '',
  };
  isDateTimeValidityPicker = true;

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { tip } = this.state;
    if (tip && this.needCheckValidity(nextProps)) {
      setTimeout(this.checkValidity.bind(this), 50);
    }
  }
  needCheckValidity = nextProps =>
    ['defaultValue', 'required', 'pattern'].some(key => this.props[key] !== nextProps[key]);
  // 提供焦点方法校验
  async focus() {
    const tip = await this.checkValidity();
    setTimeout(() => {
      if (tip && this.tipIcon) this.showTip({ target: this.tipIcon });
    }, 100);
  }
  // 提供统一校验（可供validateFieldsOf使用）
  async checkValidity() {
    let tip = '';
    const { defaultValue: value, pattern, required, customValidity } = this.props;
    if (required && !_.trim(value)) {
      tip = '必填';
    } else if (value && pattern && !new RegExp(pattern).test(value)) {
      tip = validateTips[pattern];
    } else if (customValidity) {
      tip = await customValidity(value);
    }
    this.setState({ tip });
    return tip;
  }
  getTipType(tip) {
    if (tip && tip.type) {
      return tip.type;
    }
    return 'error';
  }
  getTipMsg(tip) {
    if (tip && tip.msg) {
      return tip.msg;
    }
    return tip;
  }

  // 展示tip (支持string或object两种格式)
  showTip(e) {
    const { tip } = this.state;
    const type = this.getTipType(tip);
    const msg = this.getTipMsg(tip);
    showTip(e.target, { content: <span>{msg}</span>, type });
  }

  refTipIcon = r => (this.tipIcon = r);

  render() {
    const { tip } = this.state;
    const classes = classnames({ [prefixCls]: true, invalid: tip && !tip.type, field: true });
    const { defaultValue, ...other } = this.props;
    return (
      <div className={classes}>
        <DateTimePicker defaultValue={tip ? '' : defaultValue} {...other} />
        {tip && <span className={`${prefixCls}-extra`}>{defaultValue}</span>}
        {tip && (
          <i
            className="input-tip input-tip--invalid fn-icon fn-icon-warn-o"
            ref={this.refTipIcon}
            onMouseEnter={this.showTip.bind(this)}
            onMouseLeave={hideTip}
          />
        )}
      </div>
    );
  }
}
