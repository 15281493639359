exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".print-fault-tip h3{color:#000;font-weight:700;font-size:13px;margin-bottom:20px;text-align:center}.print-fault-tip img{display:block;max-width:700px;margin:14px auto 20px}.print-fault-tip .main-p{font-weight:700;font-size:12px;position:relative;line-height:28px}.print-fault-tip .main-p span{position:absolute;width:14px;height:22px;line-height:22px;top:3px;left:-20px}.print-fault-tip .sub-p{margin-left:10px;font-weight:400;font-size:12px;text-indent:2em;line-height:22px}.print-fault-tip .fn-dialog__center{max-height:480px;overflow-y:scroll;padding-right:0}.print-fault-tip .fn-show_info__text{padding:0 20px}.print-fault-tip .error-tip{color:#f12222}.print-fault-tip-fail .fn-show_info__icon{position:absolute;left:0;top:0}.print-fault-tip-fail .checkinfo-detail{padding-top:22px}.print-fault-tip-fail .checkinfo-detail .tips.error-tip{text-indent:35px}.print-fault-tip-fail .checkinfo-detail .detail-content{margin-top:10px}.print-fault-tip-fail .dialog_detail .notice_list{color:#888;position:relative}.print-fault-tip-fail .fn-dialog__bottom .fn-btn--default{background:#0097cf;color:#fff}.print-fault-tip-fail .fn-dialog__bottom .fn-btn--default:hover{border-color:#0097cf}#printGIFPopDg{background:rgba(0,0,0,.5)}#printGIFPopDg .print-GIF-pop-dg{height:100%;text-align:center;vertical-align:middle;justify-content:center;display:flex;align-items:center}", ""]);

// exports
exports.locals = {
	"prefixCls": "fn",
	"print-fault-tip": "print-fault-tip",
	"main-p": "main-p",
	"sub-p": "sub-p",
	"fn-dialog__center": "fn-dialog__center",
	"fn-show_info__text": "fn-show_info__text",
	"error-tip": "error-tip",
	"print-fault-tip-fail": "print-fault-tip-fail",
	"fn-show_info__icon": "fn-show_info__icon",
	"checkinfo-detail": "checkinfo-detail",
	"tips": "tips",
	"detail-content": "detail-content",
	"dialog_detail": "dialog_detail",
	"notice_list": "notice_list",
	"fn-dialog__bottom": "fn-dialog__bottom",
	"fn-btn--default": "fn-btn--default",
	"printGIFPopDg": "printGIFPopDg",
	"print-GIF-pop-dg": "print-GIF-pop-dg"
};