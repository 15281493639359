import React, { useCallback, useRef, useEffect, useState, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import ListPageConf from 'pages/ListPage';
import { COLLECTION_PAGE_CONFIG } from './config';
import { getUuid, showInfo, emitListBtnOp, fetchApi, formatCamelCase } from 'utils';
import {
  getCollectionData,
  removeOrderFromCollection,
  checkQueryAndAddNum,
  addOrderToCollection,
  clearCollectionData,
} from './utils';
import { TextArea, ButtonIcon, Label } from 'components';
import OrderButtons from 'components/commoncomponents/OrderButtons';
import { getSplit } from 'utils/tableUtils.js';
import emitter from 'utils/emitter';
import { prefixCls } from './index.scss';
import { WARN, ERROR } from 'constants';

// FilterComponent
const FilterComponent = memo(({ orderNum, onChange, loading, queryAndAdd, searchLabel }) => (
  <div className="filter-wrap">
    <Label>{searchLabel}</Label>
    <TextArea
      defaultValue={orderNum}
      placeholder="支持批量搜索，多个数据用 逗号/加号/回车/空格 分隔，最多500个"
      onChange={onChange}
      classname="query-order-num"
    />
    <ButtonIcon onClick={queryAndAdd} iconType="icon-search" loading={loading}>
      查询并新增
    </ButtonIcon>
  </div>
));

FilterComponent.propTypes = {
  orderNum: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  queryAndAdd: PropTypes.func.isRequired,
  searchLabel: PropTypes.string.isRequired,
};

// ButtonComponent
const ButtonComponent = memo(({ dataType, billType, handleClickButtons }) => {
  const { buttons, buttonsHideKey } = COLLECTION_PAGE_CONFIG[billType];
  // 处理按钮显示逻辑
  const hideKeys = buttonsHideKey[dataType] ?? [];
  Object.keys(buttons).forEach(key => {
    buttons[key].display = !hideKeys.includes(key);
  });

  return (
    <div className="button-area">
      <OrderButtons data={{ ...buttons }} handleClick={handleClickButtons} />
    </div>
  );
});

ButtonComponent.propTypes = {
  dataType: PropTypes.string.isRequired,
  handleClickButtons: PropTypes.func.isRequired,
  billType: PropTypes.string,
};

// PaymentCollection Component
const PaymentCollection = ({ id, parentPage, billType, otherProps, dataType, modalDialog, onSave, onCancel }) => {
  const {
    category,
    tab,
    url,
    maxPickOrder,
    queryIdKey,
    searchConfig: {
      searchType,
      searchTab,
      searchName,
      searchMaxNum,
      searchtUrl,
      searchLabel,
      searchCategory,
      checkTab,
      checkNumUniqueKey,
      checkButtonKey,
    },
  } = COLLECTION_PAGE_CONFIG[billType];

  const curPageKey = useRef(getUuid());
  const parentPageKey = parentPage?.getPageKey?.();
  const [showListPage, setShowListPage] = useState(false);
  const [orderNum, setOrderNum] = useState('');
  const [loading, setLoading] = useState(false);
  const [listPageIsLoaded, setListPageIsLoaded] = useState(false);
  const [designateFilter, setDesignateFilter] = useState({ [queryIdKey]: [] });

  const pageConfig = useMemo(
    () => ({
      key: curPageKey.current,
      parentPageKey,
      pageConfig: {
        key: curPageKey.current,
        listPage: true,
        tableInfo: { category, tab },
        url,
      },
    }),
    [category, tab, url, parentPageKey],
  );
  const pageConfigRef = useRef(pageConfig);

  useEffect(() => {
    curPageKey.current = getUuid();
  }, [id]);

  // 获取挑单夹数据，再更新表格引擎查询条件，触发表格引擎查询数据
  const getData = useCallback(async () => {
    const collectionData = await getCollectionData({
      billType,
      dataType,
    });
    const ids = [...collectionData.keys()];
    setDesignateFilter({ [queryIdKey]: ids.length ? [...ids] : [0] });
    setShowListPage(true);
  }, [dataType, billType, queryIdKey]);

  useEffect(() => {
    getData();
    const Emitter = emitter.getSpance('picker_order');
    Emitter.on('setCollection', async () => {
      getData();
    });
  }, [getData]);

  // 输入框事件
  const orderNumChange = useCallback(e => setOrderNum(e.target.value), []);

  // 查询订单方法
  const searchFinancePickTable = useCallback(
    async queryOrderNum => {
      const params = {
        com_id: window.company_id,
        type: searchType,
        category: searchCategory,
        tab: searchTab,
        name: searchName,
        call_source: 'multiple_order_search',
        fetch_mode: 'body',
        is_detail: 1,
        left: {},
        right: {
          query: { 'order_num._exact_': [...queryOrderNum] },
          page_size: searchMaxNum,
        },
        need_left: 0,
        need_right: 1,
      };

      const res = await fetchApi(searchtUrl, {
        method: 'POST',
        body: { req: { ...params } },
      });
      return res;
    },
    [searchType, searchTab, searchName, searchMaxNum, searchtUrl, searchCategory],
  );

  // 查询并新增
  const queryAndAdd = useCallback(async () => {
    if (!orderNum) return showInfo(WARN, `请输入${searchLabel}`);

    const spliter = getSplit(orderNum);
    const queryOrderNum = orderNum.split(spliter).filter(item => !!item);
    if (queryOrderNum.length > searchMaxNum) return showInfo(WARN, `支持批量查询查询上限为${searchMaxNum}`);

    setLoading(true);
    const queryRes = await searchFinancePickTable(queryOrderNum);
    setOrderNum('');
    setLoading(false);

    if (queryRes.errno !== 0) return showInfo(ERROR, queryRes.errmsg);
    const { data: successData = [] } = queryRes?.res?.right ?? {};

    const goOn = async () => {
      if (!successData.length) return showInfo(ERROR, '没有可添加的数据');
      // 添加订单到挑单夹
      await addOrderToCollection({
        billType,
        dataType,
        newData: [...successData],
        prefixTips: `查询到${successData.length}条`,
      });
      getData();
    };

    // 弹窗校验
    if (successData.length !== queryOrderNum.length) {
      checkQueryAndAddNum({
        tableList: queryRes.res,
        uniqueKey: checkNumUniqueKey,
        selectList: [],
        selectPackBatchList: [],
        isPack: false,
        buttonKey: checkButtonKey,
        orderNum: queryOrderNum,
        category,
        tab: checkTab,
        showInfoHandle: {
          onClose: () => {
            goOn();
          },
          closableCallback: () => {
            goOn();
          },
        },
        showInfoOtherProps: {
          maskClosable: false,
        },
      });
      return;
    }
    goOn();
  }, [
    orderNum,
    searchMaxNum,
    searchLabel,
    category,
    checkTab,
    checkNumUniqueKey,
    dataType,
    billType,
    checkButtonKey,
    searchFinancePickTable,
    getData,
  ]);

  // 保存
  const save = () => {
    onSave?.();
    close();
  };
  // 取消
  const cancel = () => {
    onCancel?.();
    close();
  };
  // 关闭弹窗
  const close = () => modalDialog?.current?.props?.close?.();
  // 移除勾选数据
  const removeOrder = () => {
    removeOrderFromCollection({
      that: pageConfigRef.current,
      dataType,
      billType,
    });
  };
  // 全部移除
  const removeAllOrder = () => {
    clearCollectionData({ billType, dataTypeList: [dataType] });
  };
  // 固定按钮事件
  const buttonFun = {
    saveBtn: save,
    cancelBtn: cancel,
    removeOrder,
    removeAllOrder,
  };

  // 按钮回调
  const handleClickButtons = (selected = {}, name, sublist = [], buttonData, col, rowIndex) => {
    const { useBtnOpButtonKeys = [], buttons } = COLLECTION_PAGE_CONFIG[billType];
    if (useBtnOpButtonKeys.includes(name)) {
      const { useParentPage } = buttons[name];
      const context = useParentPage ? parentPage : pageConfigRef.current;
      // 使用自定义事件的按钮，listPage 可为当前列表，也可为打开挑单页前的列表页，使用parentPage主要是为了复用创建对账单流程
      emitListBtnOp({
        listPage: context,
        space: context?.getPageKey?.(),
        emitter: context?.emitter,
        name,
        key: name,
      });
      close();
      return;
    }
    const buttonName = formatCamelCase(name);
    buttonFun[buttonName]?.();
  };

  const loadedCallback = () => setListPageIsLoaded(true);

  const getPageInfo = useCallback(() => pageConfig, [pageConfig]);

  const ListPage = useMemo(() => ListPageConf(pageConfig), [pageConfig]);

  const renderTable = useMemo(
    () =>
      showListPage && (
        <ListPage
          ref={pageConfigRef}
          {...otherProps}
          isRedirect
          designateFilter={designateFilter}
          getPageInfo={getPageInfo}
          srefreshData={false}
          isShowFilter={false}
          filterMaxCount={maxPickOrder}
          loadedCallback={loadedCallback}
        />
      ),
    [showListPage, designateFilter, getPageInfo, otherProps, maxPickOrder],
  );

  return (
    // 为了保存按钮样式统一，添加 g7ui 类
    <div className={`${prefixCls} g7ui`}>
      {listPageIsLoaded && (
        <FilterComponent
          orderNum={orderNum}
          onChange={orderNumChange}
          loading={loading}
          searchLabel={searchLabel}
          queryAndAdd={queryAndAdd}
        />
      )}
      <div className="table-wrap">
        {listPageIsLoaded && (
          <ButtonComponent dataType={dataType} billType={billType} handleClickButtons={handleClickButtons} />
        )}
        {renderTable}
      </div>
    </div>
  );
};

PaymentCollection.propTypes = {
  id: PropTypes.string.isRequired,
  parentPage: PropTypes.object.isRequired,
  billType: PropTypes.string,
  otherProps: PropTypes.object,
  dataType: PropTypes.string,
  modalDialog: PropTypes.object,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

PaymentCollection.defaultProps = {
  billType: 'receiptApply',
  dataType: 'view',
  otherProps: {},
  modalDialog: null,
};

export default PaymentCollection;
