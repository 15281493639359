import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
// import { BiPickOrderContent } from 'components'
import BiPickOrderContent from 'components/commoncomponents/pickorder/BiPickOrderContent';

import { formatCamelCase } from 'utils/utils';

const bindAction = (pageKey, ComposedComponent) =>
  class extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {};
    }
    static defaultProps = {};

    static childContextTypes = {
      renderBiPickOrder: PropTypes.func,
      shouldUpdate: PropTypes.func,
      getPick: PropTypes.func,
    };

    getChildContext = () => ({
      renderBiPickOrder: this.renderBiPickOrder,
      shouldUpdate: this.shouldUpdate,
      getPick: () => this.pickContent,
    });

    shouldUpdate = (thisProps = {}, thisState = {}, nextProps = {}, nextState = {}) => {
      if (
        Object.keys(thisProps).length !== Object.keys(nextProps).length ||
        Object.keys(thisState).length !== Object.keys(nextState).length
      ) {
        return true;
      }
      Object.keys(nextProps).forEach(key => {
        if (thisProps[key] !== nextProps[key] || !Object.is(thisProps[key], nextProps[key])) {
          return true;
        }
      });
      Object.keys(nextState).forEach(key => {
        if (thisState[key] !== nextState[key] || !Object.is(thisState[key], nextState[key])) {
          return true;
        }
      });
      return false;
    };
    handleInputChange = (reactElement, key, value, ...res) => {
      const funcName = formatCamelCase(`${key}_input_change`);
      if (typeof reactElement[funcName] === 'function') {
        reactElement[funcName](value, ...res);
      }
    };
    handleInputBlur = (reactElement, key, value, ...res) => {
      const funcName = formatCamelCase(`${key}_input_blur`);
      if (typeof reactElement[funcName] === 'function') {
        reactElement[funcName](value, ...res);
      }
    };
    handleOnClick = (reactElement, key, form, data, value) => {
      if (typeof reactElement.handleOnClick === 'function') {
        reactElement.handleOnClick(key, form, data, value);
      }
    };
    handleIconClick = (reactElement, key, form, data) => {
      const funcName = formatCamelCase(`${key}_icon_click`);
      if (typeof reactElement[funcName] === 'function') {
        reactElement[funcName](key, form, data);
      }
    };
    iconClick = (reactElement, key, form, data) => {
      const funcName = formatCamelCase(`${key}_click_icon`);
      if (typeof reactElement[funcName] === 'function') {
        reactElement[funcName](key, form, data);
      }
    };
    onFocus = (reactElement, key, value, form, data) => {
      const funcName = formatCamelCase(`${key}_on_focus`);
      if (typeof reactElement[funcName] === 'function') {
        reactElement[funcName](key, value, form, data);
      }
    };

    handleSelectChange = (reactElement, key, value, form, data, rightTable) => {
      const funcName = formatCamelCase(`${key}_select_change`);
      if (typeof reactElement[funcName] === 'function') {
        reactElement[funcName](value, key, data, rightTable);
      }
    };
    btnClickCallback = (reactElement, key, value, validateInfo, ...res) => {
      const funcName = formatCamelCase(`${key}_btn_click`);
      if (typeof reactElement[funcName] === 'function') {
        reactElement[funcName](key, value, validateInfo, ...res);
      }
    };
    prohibitTableMove = reactElement => {
      if (typeof reactElement.prohibitTableMove === 'function') {
        return reactElement.prohibitTableMove();
      }
      return false;
    };
    renderBiPickOrder = (reactElement, parentContainer) => {
      const { props } = reactElement;
      const { state } = reactElement;
      // const info = state.pickOrderInfo || props.pickOrderInfo || props.pickOrderInfo.toJS() || {}
      let info = {};
      if (state.pickOrderInfo) {
        info = state.pickOrderInfo;
      } else if (props.pickOrderInfo.toJS) {
        info = props.pickOrderInfo.toJS();
        if (state.buttonKey === 'carrier_ca_ba_apply' && info.feeInfo.header.amount) {
          info.feeInfo.header.amount.tips = '负数表示支出项，正数表示收入项';
        }
      } else if (props.pickOrderInfo) {
        info = props.pickOrderInfo;
      }
      const howToshow = props.howToShow || 'both';
      const { renderType } = props;
      const { canSwitchRenderType } = props;
      const { isEditOrShow } = props;
      if (
        props === undefined ||
        state === undefined ||
        info.batchTableInfo === undefined ||
        !info.batchTableInfo.left ||
        !info.batchTableInfo.right
      )
        return false;
      // infoReqParam 打开的挑单页的头部信息的接口请求参数 openPageBtnKey 打开挑单页的由来按钮
      // 是否显示账本组件
      const showAcountBook = reactElement.extraForm && state.ledgerInfo && Object.keys(state.ledgerInfo).length > 0;
      return (
        <BiPickOrderContent
          pageKey={pageKey}
          key={pageKey}
          type={state.type}
          reqInfo={info.reqInfo}
          openPageBtnKey={state.buttonKey || props.buttonKey}
          openPageTab={props.pageInfo?.currFetchParamCache?.tab || ''}
          originReqFromSource={state.originReqFromSource}
          infoReqParam={props.reqParam}
          headerHideKey={reactElement.headerHideKey}
          headerEditKey={reactElement.headerEditKey}
          bodyEditKey={reactElement.bodyEditKey}
          wRateValue={props.wRateValue}
          vRateValue={props.vRateValue}
          enumFilter={state.enumFilter}
          resetDefaultData={reactElement.resetDefaultData}
          rightDataFormate={reactElement.rightDataFormate}
          batchTableInfo={info.batchTableInfo || {}}
          batchTitleInfo={info.batchTitleInfo || {}}
          batchInfo={info.batch_info || {}}
          shouldUpdateWhenReceiveProps={state.updateProps4FeeInfo}
          feeInfo={info.feeInfo}
          initFeeInfo={info.initFeeInfo}
          oilcardAddAuth={info.oilcard_add_auth || false}
          btnClickCallback={(...res) => this.btnClickCallback(reactElement, ...res)}
          handleSelectChange={(...res) => this.handleSelectChange(reactElement, ...res)}
          handleOnClick={(...res) => this.handleOnClick(reactElement, ...res)}
          handleInputChange={(...res) => this.handleInputChange(reactElement, ...res)}
          handleInputBlur={(...res) => this.handleInputBlur(reactElement, ...res)}
          handleIconClick={(...res) => this.handleIconClick(reactElement, ...res)}
          iconClick={(...res) => this.iconClick(reactElement, ...res)}
          onFocus={(...res) => this.onFocus(reactElement, ...res)}
          extraForm={reactElement.extraForm}
          handleTodoAdd={reactElement.handleTodoAdd}
          handleTodoSubtract={reactElement.handleTodoSubtract}
          handleRightTodoAdd={reactElement.handleRightTodoAdd}
          handleRightTodoSubtract={reactElement.handleRightTodoSubtract}
          handleRightOperateClick={reactElement.handleRightOperateClick}
          handleFeeTableChange={reactElement.handleFeeTableChange}
          handleFeeTableSelectDropSelect={reactElement.handleFeeTableSelectDropSelect}
          handleBiTableChange={reactElement.handleBiTableChange}
          handleFeeTableBlur={reactElement.handleFeeTableBlur}
          handleLeftToRightCallback={reactElement.handleLeftToRightCallback}
          handleRightToLeftCallback={reactElement.handleRightToLeftCallback}
          handleChangeAmount={reactElement.handleChangeAmount}
          onUpdateCheckTotakAmount={reactElement.onUpdateCheckTotakAmount}
          amountDetail={reactElement.amountDetail}
          handleCheckAmountDetailExpense={reactElement.handleCheckAmountDetailExpense}
          handleAmountDetailChangeAll={reactElement.handleAmountDetailChangeAll}
          expense={info.expense}
          isFiddle={reactElement.state.isFiddle}
          isBillAdjust={reactElement.state.isBillAdjust}
          getFeeRelation={reactElement.getFeeRelation}
          formateLeft={reactElement.formateLeft}
          dataMap={reactElement.dataMap}
          defaultDataFormate={reactElement.defaultDataFormate}
          resetDefaultDataFlag={reactElement.resetDefaultDataFlag}
          parentContainer={parentContainer}
          handleTableFormClick={reactElement.handleTableFormClick}
          checkRightTableNum={reactElement.checkRightTableNum}
          renderCustomContent={reactElement.renderCustomContent}
          tableDataFormate={reactElement.tableDataFormate}
          ref={r => (this.pickContent = r)}
          howToShow={howToshow}
          isEditOrShow={isEditOrShow}
          renderType={renderType}
          canSwitchRenderType={canSwitchRenderType}
          enter13Path={reactElement.enter13Path}
          beforeRefreshBiTableByEdit={reactElement.beforeRefreshBiTableByEdit}
          refreshBiTableCallback={reactElement.refreshBiTableCallback}
          onRefreshPickerOrder={reactElement.onRefreshPickerOrder}
          onRateChange={reactElement.onRateChange}
          reconciliationRate={state.reconciliationRate}
          totalAmount={state.totalAmount}
          acPayAmount={state.acPayAmount}
          amerceAmount={state.amerceAmount}
          interestAmount={state.interestAmount}
          showRate={state.showRate}
          fetchTableCallback={reactElement.fetchTableCallback}
          prohibitTableMove={() => this.prohibitTableMove(reactElement)}
          contractList={state.contractList}
          viewContract={reactElement.viewContract}
          isModify={state.isModify}
          isTask={state.isTask}
          isReceiptPayment={state.isReceiptPayment}
          adjustWidth={state.adjustWidth}
          isOverload={state.isOverload}
          showBiTable={state.showBiTable}
          isNewOverload={state.isNewOverload}
          billNo={props.billNo}
          overloadInfo={state.overloadInfo}
          layout={state.layout}
          showAcountBook={showAcountBook}
          extraPara={state.extraPara}
          handleFn={reactElement.handleFn}
          updateOverloadInfo={reactElement.updateOverloadInfo}
        />
      );
    };
    render() {
      return <ComposedComponent {...this.props} {...this.state} />;
    }
  };
export default bindAction;
