import React from 'react';
import { ModalDialog } from 'components';
import { prefixCls } from './index.scss';

export default class helloOms extends React.Component {
  // tms 完善公司信息
  renderContent = () => <div className="hello-oms" />;

  render() {
    return (
      <div className={prefixCls}>
        <ModalDialog
          content={this.renderContent()}
          ref={ref => (this.modal = ref)}
          closable
          maskClosable={false}
          style={{ width: 700 }}
          isShow
          {...this.props}
        />
      </div>
    );
  }
}
