import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { opTips, fetchJAVA, postJAVA, showInfo, g7Done, validateFieldsOf } from 'utils';
import { ERROR, NORMAL_DT_FORMAT, FLOAT_P_3, FLOAT_P_6 } from 'constants';
import {
  Button,
  ModalDialog,
  Select,
  PureCheckbox as Checkbox,
  DateTimePicker,
  PopUp,
  PureInput as Input,
  UploadFile,
} from 'components';
import BatchOp from 'components/commoncomponents/BatchOp';
import { withPopUpI18n, defaultI18n } from '@/utils/i18n/context';
import { prefixCls } from './index.scss';
import _ from 'lodash';

const required = bool => (bool ? ' r' : '');

class TaskArrivalModal extends PureComponent {
  static propTypes = {
    data: PropTypes.array,
    close: PropTypes.func,
    onSaveSuccess: PropTypes.func,
    i18n: PropTypes.object,
    checked: false,
    isLimit: false, // 区分财运通1.0和2.0做展示限制
  };

  static defaultProps = {
    i18n: defaultI18n,
  };

  constructor(props) {
    super(props);
    const weightUnit = _.get(window, 'company_setting.weight_unit.value') === 'KG' ? 'kg' : '吨';
    const isBulkUser = _.get(window, 'company_setting.home_flow_style.value') === 'bulk_industry';
    const unloadNetWeightField = _.get(
      window,
      'company_setting.pc_arrives_at_operation_field.fields.unload_net_weight',
      {},
    );
    let arriveTime = moment().format(NORMAL_DT_FORMAT);
    // 这里判断下业务传入的到达时间，有就优先使用
    if (props.data?.[0]?.arriveTime) {
      arriveTime = props.data?.[0]?.arriveTime;
    }
    this.state = {
      arrT: arriveTime,
      infos: props.data.map(item => ({ cur_company_id: item.company_id, b_basic_id: item.b_basic_id })),
      isAllDispatch: false,
      unloadNetWeight: '',
      arriveImg: [],
      unloadNetWeightField,
      weightUnit,
      weightUnitIsT: weightUnit === '吨',
      confirmUnload: props.checked,
      isLast: false,
      isBulkUser,
      confirmLoading: false,
    };
    this.getPointData();
  }

  getPointData() {
    if (this.props.data.length > 1) {
      // 批量到达不请求网点
      return;
    }

    const req = this.props.data.map(item => ({ cur_company_id: item.company_id, b_basic_id: item.b_basic_id }));
    fetchJAVA('/cmm-batch/batch/arrivePoint', {
      method: 'POST',
      body: {
        req,
      },
    }).then(res => {
      if (res.errno) {
        this.modal.handleHide();
        showInfo(ERROR, res.errmsg);
        return;
      }
      const resData = res.res || {};
      this.setState({
        comIDEnum: resData.points || [],
        comID: resData.points && resData.points[0] ? resData.points[0].id : '',
        isAllDispatch: resData.all_owned,
        minArriveT: resData.min_arrive_t,
        isLast: resData.last,
      });
    });
  }
  changeImg = files => {
    this.setState({ arriveImg: files });
  };
  success = res => {
    this.modal && this.modal.handleHide();
    this.props?.onSaveSuccess?.(res);
  };

  confirm = async () => {
    const {
      arrT,
      confirmUnload,
      comID,
      infos,
      isLast,
      minArriveT,
      arriveImg,
      unloadNetWeight,
      weightUnitIsT,
      unloadNetWeightField,
      confirmLoading,
    } = this.state;
    if (confirmLoading) return;

    const taskDict = this.props.i18n.get('batch', '任务');
    if (!(await validateFieldsOf(this.wrap))) return false;
    if (!arrT) {
      return showInfo(ERROR, '请选择到达时间');
    }
    if (arrT < minArriveT) {
      showInfo(ERROR, '到达时间不能早于上一节点发车时间');
      this.wrap.querySelector('.ant-input').click();
      return;
    }
    const url = '/cmm-batch/batch/arrive';
    const { data } = this.props;
    const req = {
      infos,
      arr_t: arrT,
      arr_company_id: comID,
      confirm_unload: isLast ? confirmUnload : false,
      imgs: arriveImg,
      is_check: 1,
    };

    if (this.props.data.length > 1) {
      // 批量到达增加参数
      req.batch_arr = 1;
    }

    if (unloadNetWeightField.show && unloadNetWeight) {
      req.unload_net_weight = weightUnitIsT ? unloadNetWeight * 1000 : unloadNetWeight;
    }
    this.setState({ confirmLoading: true });
    postJAVA(url, req).then(res => {
      this.setState({ confirmLoading: false });
      const batchArrival = () => {
        const header = {
          car_batch: { title: '发车批次', display: 'show' },
          route_text: { title: '车辆线路', display: 'show' },
          b_tr_num: { title: '车牌号', display: 'show' },
        };
        const successData = data.filter(item => res.res.success_id.includes(+item.b_basic_id));
        new PopUp(BatchOp, {
          isJAVA: true,
          requestUrl: url,
          title: '批量到达',
          tips: '到达',
          header,
          data: successData,
          prefixalTips: '',
          getPara: row => ({
            ...req,
            infos: [{ cur_company_id: row[0].company_id, b_basic_id: row[0].b_basic_id }],
            b_basic_ids: row.map(item => item.b_basic_id),
          }),
          finishCallback: this.success,
          escBind: false,
        }).show();
      };
      opTips({
        orderType: taskDict,
        opType: '到达',
        resData: res,
        sucCallback: infos.length > 1 ? batchArrival : this.success,
        continueCallback: (sids, cb) => {
          cb && cb();
          batchArrival();
        },
        showSucTips: infos.length === 1,
      });
    });
  };

  render() {
    const {
      arrT,
      confirmUnload,
      comID,
      comIDEnum,
      isAllDispatch,
      isLast,
      unloadNetWeight,
      unloadNetWeightField,
      weightUnit,
      weightUnitIsT,
      isBulkUser,
      arriveImg,
      confirmLoading,
    } = this.state;
    const { isLimit, data } = this.props;
    const taskDict = this.props.i18n.get('batch', '任务');
    const orderDict = this.props.i18n.get('order', '运单');
    const checkContent = (
      <div>
        <Checkbox checked={confirmUnload} onChange={e => this.setState({ confirmUnload: e.target.checked })} />
        <label>
          同时确认自建{taskDict}的{orderDict}已全部入库
        </label>
      </div>
    );
    const enableDirectArrive = _.get(window, 'company_setting.enable_direct_arrive.checked');
    const content = (
      <div ref={r => (this.wrap = r)} className={prefixCls}>
        <div className="tip">
          <i className="fn-icon-il fn-icon-warn-o" />
          {enableDirectArrive ? (
            <p>
              您确定要操作直接到达吗？
              <br />
              如为批量操作，所有{taskDict}都将以此终点到达时间为准
            </p>
          ) : (
            <p>
              您确定要操作到达吗？
              <br />
              如为批量操作，所有{taskDict}都将以此到达时间为准
            </p>
          )}
        </div>

        <div className="fields">
          <div>
            <label>到达网点：</label>
            <Select
              value={comID}
              map="id"
              compare="id"
              required={data.length === 1}
              disabled={data.length > 1}
              data={comIDEnum}
              onChange={val => this.setState({ comID: val })}
            />
          </div>

          <div>
            <label>到达时间：</label>
            <DateTimePicker defaultValue={arrT} showTime onChange={val => this.setState({ arrT: val })} />
          </div>

          {unloadNetWeightField.show && data.length === 1 && (
            <div>
              <label className={`fn-label${required(unloadNetWeightField.required)}`}>卸车净重：</label>
              <Input
                value={unloadNetWeight}
                required={unloadNetWeightField.required}
                onChange={e => this.setState({ unloadNetWeight: e.target.value })}
                placeholder="请输入"
                pattern={weightUnitIsT ? FLOAT_P_6 : FLOAT_P_3}
              >
                <span className="input-icon unit">{weightUnit}</span>
              </Input>
            </div>
          )}
          {g7Done() && (
            <div className="uploadImg">
              <div className="upload-wrap-tilte">{isBulkUser ? '卸货磅单' : '卸货回单'}</div>
              <UploadFile
                type="receipt"
                fileList={arriveImg}
                onChange={this.changeImg}
                acceptFileTypes="image/*,.pdf,.doc,.docx,.xls,.xlsx"
                accept="file"
                maxNum={10}
                sizeLimit={8}
              />
            </div>
          )}
          {!isLimit && !isAllDispatch && checkContent}
          {isLimit && isLast && checkContent}
        </div>
      </div>
    );

    const bottom = (
      <Fragment>
        <Button type="primary" onClick={this.confirm} loading={confirmLoading}>
          确定
        </Button>
        <Button onClick={() => this.modal.handleHide()}>取消</Button>
      </Fragment>
    );

    return (
      <ModalDialog
        ref={r => (this.modal = r)}
        content={content}
        bottom={bottom}
        isShow
        isModal
        close={this.props.close}
        title="操作提醒"
      />
    );
  }
}
export default withPopUpI18n(TaskArrivalModal);
