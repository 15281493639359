exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".message-input{padding:5px 16px 16px;background-color:#f7f8fa;width:100%;height:100%;display:flex;flex-direction:column;align-items:start;position:relative}.message-input .gui-btn-primary:not(.gui-btn-disabled){background-color:#532fe6}.message-input .gui-btn-primary.gui-btn-disabled{background-color:#9675f0}.message-input-textarea.gui-textarea{margin-top:5px;width:100%;height:226px;padding:5px;background-color:#fff;border:1px solid #c9cdd4;resize:none;border-radius:4px}.message-input-help{display:flex}.message-input-help__btn.gui-btn-secondary:not(.gui-btn-disabled){background-color:#f0e8ff;margin-right:10px;color:#532fe6;height:28px}.message-input-help__btn.gui-btn-secondary:not(.gui-btn-disabled).gui-btn-secondary:not(.gui-btn-disabled):not(.gui-btn-loading):hover{background-color:#f0e8ff;color:#532fe6}.message-input-btn{position:absolute;right:24px;bottom:24px;display:flex}.message-input-btn__send{margin-left:16px;background-color:#532fe6}", ""]);

// exports
exports.locals = {
	"prefixCls": "message-input",
	"message-input": "message-input",
	"gui-btn-primary": "gui-btn-primary",
	"gui-btn-disabled": "gui-btn-disabled",
	"message-input-textarea": "message-input-textarea",
	"gui-textarea": "gui-textarea",
	"message-input-help": "message-input-help",
	"message-input-help__btn": "message-input-help__btn",
	"gui-btn-secondary": "gui-btn-secondary",
	"gui-btn-loading": "gui-btn-loading",
	"message-input-btn": "message-input-btn",
	"message-input-btn__send": "message-input-btn__send"
};