import React from 'react';
import { handleAPIResponse, postJAVA } from 'utils';
import { Button, ModalDialog, PopUp } from 'components';

export default async () => {
  let _dialog = null;
  const close = () => {
    _dialog.handleHide();
  };
  const { con_company_name } = await handleAPIResponse(postJAVA('/cmm-intransit/contract_tms/get_con', {}));
  const content = renderContent({ con_company_name });

  new PopUp(ModalDialog, {
    ref: r => (_dialog = r),
    title: '协议明细',
    content,
    max: true,
    bottom: (
      <Button type="primary" onClick={close}>
        确定
      </Button>
    ),
    isModal: false,
    popName: 'cutomerServiceDialog',
    isShow: true,
    style: { width: '500px' },
  }).show();
};

const renderContent = ({ con_company_name }) => (
  <div className="agreement">
    <p>
      <strong className="agreement__title">《运单车辆跟踪定位服务协议》</strong>
    </p>

    <p>
      <strong>运单车辆跟踪定位管理服务协议</strong>
    </p>
    <p>
      版本发布日期：
      <span className="underline"> 2024 </span>年<span className="underline"> 10 </span>月
      <span className="underline"> 22 </span> 日
    </p>
    <p>欢迎您使用G7提供的财运通系统产品和运单在途监控服务（下称“服务”）</p>

    <p>
      本协议自发布之日起即刻生效。本协议各条款前“【】”所列索引关键词仅为帮助您理解该条款表达的主旨之用，不影响或限制本协议条款的含义或解释。为维护您自身权益，建议您仔细阅读各条款具体表述。
    </p>

    <p>
      <strong>
        【审慎阅读】
        <span className="underline">
          如果您完成服务费用的支付或开始使用服务，即表示您接受或同意本协议。因此，请您务必审慎阅读本协议各条款，特别是免除或限制责任条款、法律适用条款和管辖条款。免除或限制责任条款、法律适用条款和管辖条款将以下划线粗体标识，请您重点阅读。如果您不接受本协议或本协议任一条款，您可以拒绝使用服务。
        </span>
      </strong>
    </p>

    <p>您可通过下列任一孰先发生方式接受或同意本协议：</p>
    <p>（一）在与服务相关用户界面上G7向您提供的选择处点击以接受或同意本协议；或</p>
    <p>
      （二）登录财运通系统或实际使用服务。在此情况下，您理解并同意，G7视您自登录财运通系统或使用服务时起接受或同意本协议。
    </p>

    <p>
      <strong>一、 相关定义</strong>
    </p>
    <p>1.1 【G7】：指广州吉七信息科技有限公司</p>
    <p>
      1.2 【财运通系统】：指由G7授权/销售给<span className="underline"> {con_company_name} </span>
      的公司（简称“使用权人”）的系统。
    </p>
    <p>
      1.3
      【运单在途监控服务】：指在您取得相应车辆所有权人及车辆实际控制人（统称“车辆权利人”）的明确授权且按照G7要求提供相应资料/信息后，您通过财运通系统进行相应运单的车辆位置、轨迹查询，以及运单发运前和在途的异常事件识别与推送。
    </p>

    <p>
      <strong>二、 协议范围及用户资格</strong>
    </p>
    <p>2.1本协议由您与G7共同缔结，本协议对您与G7均具有合同效力。</p>
    <p>
      2.2
      【补充协议】您理解并同意，您与G7签署的本协议列明的条款并不能完整罗列并覆盖您与G7所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，
      <strong className="underline">
        G7有时还会适用一些协议或附加条款或服务要求，该协议或附加条款或服务要求将会与相关服务一同提供，并成为本协议的补充协议，与本协议不可分割且具有同等法律效力。如果您使用G7服务，视为您同意上述补充协议。如果补充协议与本协议有冲突，以补充协议为准。
      </strong>
    </p>
    <p>
      <strong className="underline">
        2.3
        当您登录财运通系统完成服务费用充值或您开始使用服务时，即表示您已充分阅读、理解、接受并同意本协议的全部内容，并与G7达成一致，成为G7用户。
      </strong>
    </p>
    <p>
      <strong className="underline">
        2.4
        您确认，在您登录财运通系统或使用G7服务前，您已经得到了财运通系统使用权人的充分授权，您在财运通系统内的任何操作行为即视为财运通系统使用权人的行为，因此导致的一切后果由您与财运通系统使用权人共同承担。
      </strong>
    </p>

    <p>
      <strong>三、 服务内容</strong>
    </p>
    <p>
      3.1
      财运通系统使用权人系依法成立的独立法人，其基于公司业务管理和发展需要，需对其公司业务的运单时效合理性进行管理，因此委托G7供本协议约定相应的运单车辆定位技术服务。
    </p>
    <p>
      3.2
      根据《中华人民共和国数据安全法》、《中华人民共和国个人信息保护法》等法律法规（以下简称“适用法律”），双方开展合作项目。由您和财运通系统使用权人作为数据处理者决定处理数据的目的和方法，G7作为受托数据处理者向您提供服务。
    </p>
    <p>
      <strong className="underline">
        3.3
        您保证并承诺根据适用法律要求已获得车辆所有权人或基于所有权人授权而合法使用货车的相关权利人（“权利人”）的必要的授权、同意或单独同意（在适用的情况），以便自己授权G7处理所有信息（包含个人信息）。
      </strong>
      特别地，您需要履行的义务包括共享个人信息的目的、方式、个人信息的种类、接收方的名称或者姓名、联系方式以及自身的安全能力告知个人信息主体，并取得个人的单独同意，形式上可以参考附件二。附件二仅供您参考，G7不对此作任何承诺。
    </p>
    <p>
      <strong className="underline">
        3.4
        您在接受服务前，应确认已从车辆权利人处取得以下授权：（a）实时调取并使用车辆轨迹、位置信息；（b）为调度车辆轨迹、位置信息而向G7及G7必要合作单位提供相应证件；（c）为调度车辆轨迹、位置信息而向G7及G7必要合作单位提供姓名、联系方式等相关必要个人信息；（d）法律法规所要求的其他必要授权。如您未取得上述任一授权的，应立即停止使用G7提供的服务。
      </strong>
    </p>
    <p>
      <strong className="underline">
        3.5
        您确认并同意，平台有权基于您提供的信息不定期向车辆权利人确认您是否已取得授权，如车辆权利人未明确确认的，平台将暂停向您提供本项服务直至该车辆权利人进行确认。
      </strong>
    </p>
    <p>3.6 具体《服务费用明细及费用支付、退还规则》详见本协议附件一。</p>
    <p>
      3.7
      G7服务查询到的信息仅供您参考使用，由于技术限制及数据迟延等原因，G7不保证服务信息绝对精准。您应结合自身经营需要对查询到的信息进行使用并独立作出决策。在任何情况下，您在使用G7服务过程中独立做出的行为（包括但不限于信息查询、利用信息查询结果做出决定或行动引入第三方合作者、利用G7服务开展业务等）与G7无关，其所引发的任何争议、纠纷、诉讼等均应由您承担全部责任。
    </p>
    <p>
      <strong className="underline">
        3.8
        您承诺对从G7服务查询到的信息负有保密义务，不得以任何方式进行委托处理、共享、转让、公开披露或向其他任何人透露、传播、散布等。您应采取安全措施、审计制度保护上述信息的安全，防止该等信息泄露或被滥用。如果因您安全保护措施不到位，致使发生信息泄露、篡改或其他安全事故，由您承担全部责任。
      </strong>
      G7有权视情节轻重追究您的相关责任，包括但不限于要求停止相关信息查询、立即停止公开披露行为、删除信息、赔偿损失、终止平台服务等。G7服务终止后，您亦应严格遵守前述义务，因您持有前述信息导致的问题由您负责解决纠纷及承担责任。
    </p>
    <p>
      <strong className="underline">
        3.9
        您承诺要求G7作为受托数据处理者为您提供的相关数据处理服务，均不违反法律及相关规定、不侵害第三方权益。您承诺严格遵守适用法律所指引的最小够用和合法、正当、必要的原则。
      </strong>
    </p>
    <p>
      <strong className="underline">
        3.10
        您承诺遵守法律、法规、规章、政府机关的命令和决议等关于信息保护、使用的规定、标准和要求，对从G7服务查询到的信息及其处理结果只能用于车辆监控之合法用途，不得用作任何非法用途（包括但不限于∶复制、存储信息用于非法建立数据库或数据接口；用于暴力或软暴力催收、恶意报复、诈骗、敲诈勒索、非法营销等违法行为；未经许可形成用户画像并将用户画像提供给第三方；为第三方实施违法行为提供便利等）。
      </strong>
    </p>

    <p>
      <strong>四、协议终止</strong>
    </p>
    <p>
      4.1出现以下情况时，<strong className="underline">G7可以通知您终止本协议</strong>：
    </p>
    <p>1）您在使用服务过程中违反有关法律法规规定的，或违反本协议（包括本协议附件及补充协议）约定的；</p>
    <p>
      2）滥用G7提供的服务，未经G7授权将从G7获得的信息、技术服务进行二次销售（包括但不限于通过赠与、转售等提供给第三方，直接或将信息重新整理、加工后进行二次销售）。
    </p>

    <p>
      <strong>五、争议解决</strong>
    </p>
    <p>
      5.1
      本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如果法律无相关规定的，参照商业惯例和/或行业惯例。
    </p>
    <p>
      5.2
      您因使用服务所产生和/或与服务有关的争议，由G7与您协商解决；协商不成时，任何一方均可向G7所在地人民法院提起诉讼。
    </p>

    <p>
      <strong>六、免责条款</strong>
    </p>
    <p>
      6.1协议履行过程中，如因不可抗力致使协议无法履行或给对方造成损失的，遭遇不可抗力的一方可免予承担因此造成的违约责任。遭遇不可抗力的一方应采取积极措施，尽量减少损失，否则应就扩大损失部分向对方承担赔偿责任。任何一方遭受不可抗力事件，应在不可抗力事件发生后
      7个工作日内向另一方提供证明文件。遭遇不可抗力一方提供证明文件后，双方可以按照不可抗力对协议履行的影响程度，协商是否解除协议、免除履行协议的责任或者延期履行协议。若一方因迟延履行协议约定义务而遭受不可抗力，则不受前述条款的限制。
    </p>
    <p>
      6.2本协议所称不可抗力是指不能预见、不能避免且不能克服的客观情况，如果因国家法律、法规、规章、政府行为、相关国家机关要求等，导致本协议不能继续履行的，视为不可抗力，本协议终止，双方互不承担违约责任。
    </p>
    <p>
      <strong>
        6.3.鉴于计算机、互联网的特殊性，因下列原因导致G7无法正常提供服务，G7免于承担责任，该状况包括但不限于：系统维护升级、电信部门技术调整、线路故障、通信和电力故障、设备故障、非因G7导致的系统故障、病毒、木马、恶意程序攻击等。但发生前述状况时，G7将采取合理行动积极促使本协议下的服务恢复正常。
      </strong>
    </p>

    <p>
      <strong>附件一、《服务费用明细、积分计算、费用退还规则》</strong>
    </p>
    <p>
      <strong>1.服务费充值：</strong>
    </p>
    <p>
      1）充值收费<strong>：</strong>
    </p>
    <p>财运通系统中查询运单位置或行程轨迹会扣除对应的积分，积分需要充值购买，每充值1元对应转换10个积分。</p>
    <p>2）充值方式：</p>
    <p>提供微信充值与网银转账两种方式，具体形式参考系统功能的引导流程。</p>

    <p>
      <strong>2.积分计算及费用退还规则</strong>
    </p>
    <p>1）在途监控服务积分规则：</p>
    <ul className="list">
      <li>
        <strong>查询运单：</strong>
        位置或行程轨迹不超过3天（自然日）的，每次查询将消耗10积分/每个运单；位置或行程轨迹超过3天的，将以3天为分段标准，每增加3天多消耗10个积分，以此类推。{' '}
      </li>
      <li>
        <strong>查询车辆位置：</strong>单次查询将消耗5个积分，每辆车查询上限为100积分/月。
      </li>
      <li>
        <strong>查询单车轨迹：</strong>
        将按次数消耗积分，3天（自然日）以内的轨迹信息，单次查询将消耗10个积分；轨迹信息超过3天的将额外消耗积分，以3天为分段标准，每增加3天多消耗10个积分，单次最大允许查询范围8*24小时。
      </li>
    </ul>
    <p>2）退款规则：</p>
    <p>若确认双方不再合作，根据协议规则，双方协商解决退款问题。遗留未消费的可申请退款金额，依照以下公式来计算：</p>
    <p>可退款金额=充值总金额-（充值总积分-剩余积分）/10</p>

    <p>
      <strong>附件二 您征得主体授权同意模板</strong>
    </p>
    <p>
      <strong>个人信息收集告知声明</strong>
    </p>
    <p>
      【 】（以下简称“我们”）为了使用【G7】的运单定位技术服务，我们会收集您的个人信息。
      您应当在仔细阅读、充分理解本声明及其所包含的所有内容后选择是否授权我们收集、处理您的个人信息。您签署本声明即代表您已充分理解并同意本声明所有内容的相应授权。为了使用G7服务的目的，我们会收集您以下的个人信息：
    </p>
    <p>1. 您的个人基本信息：姓名、身份证、驾驶证、手机号码。</p>
    <p>2. 您的紧急联系人的联络信息：紧急联系人姓名、电话。</p>
    <p>3. 您在行驶过程中的录像、图像信息；</p>
    <p>4. 您在行驶过程中的定位信息以及轨迹信息。</p>
    <p>5. 【 】</p>
    <p>
      请您知悉，为了实现上述目的，您的上述个人信息会委托以下列明的数据处理受托方、第三方供应商及其授权合作伙伴处理，我们会与其签署严格的保密协定，明确数据处理事项、处理期限、处理目的以及双方责任等。
    </p>
    <p>数据处理受托方：向【 】提供本告知声明中收集您的个人信息，以用于【使用G7信息技术服务】</p>
    <p>
      在适当的情况下，我们仅在相关法律允许和必要共享、转让、披露的目的下，或者事先征得您同意的情况下，才会将您的某些个人信息共享、转让、披露或者将来通过单独告知的方式获取您对其他第三方服务商的同意。
    </p>
    <p>请您知悉，为了实现下述目的，我们会将您的下述个人信息提供给以下列明的第三方机构。</p>
    <p>（a）向【 】提供【 】信息，以用于【 】；</p>
    <p>（b）向【 】提供【 】信息，以用于【 】；</p>
    <p>
      您的个人信息将会被存储在中国境内，在本声明下所处理的个人信息不存在出境的情况。同时，您可以通过以下所述的联系方式，联系我们并行使您的权利，查询、更正、删除您的个人信息，撤回您针对处理您个人信息的同意，或对本声明表达任何疑问、意见或建议：发送邮件至【
      】。
    </p>
    <p>
      您必须将本声明的内容通知给提供我们个人信息的您的家属及其他相关人员，并向他们提供本声明的副本和任何相关政策以供查阅和理解。您对本声明的确认，将视为个人信息主体亦同意向我们提供其个人信息并且您已经获得了授权。您必须将您以及可以被采集信息的家属或者相关人员个人信息情况的任何变化及时通知我们，并确保所提供的个人信息始终真实、准确、完整。您会对所填写信息的真实性、准确性和完整性所引起的任何问题负责。
    </p>
    <p>您的签字，代表您充分知悉并同意我们基于上述目的及范围处理您的个人信息。</p>
    <p>
      <strong>
        我【 】【身份证号： 】承认收到了上述《个人信息收集告知声明》，并且我已知晓并理解该声明，同意【
        】依照声明处理我的个人信息。
      </strong>
    </p>
    <p>
      <strong>同意人确认签字：</strong>
    </p>
    <p>
      <strong>日期： 年 月 日</strong>
    </p>
  </div>
);
