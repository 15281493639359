exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".contract-show-modal-content{width:750px;height:620px;background-color:#fff}.contract-show-modal-header{display:flex;align-items:center;margin-bottom:20px}.contract-show-modal-header .header-title{font-size:18px;font-weight:500}.contract-show-modal-header .header-status{margin-left:10px}.contract-show-modal-header .default-color,.contract-show-modal-header .success-color{font-size:14px;font-weight:500}.contract-show-modal-header .success-color{color:#0a854d}.contract-show-modal-header .default-color{color:#0a91d5}.contract-show-modal-contract{height:480px;overflow:scroll}.contract-show-modal-desc{border-top:1px solid #ccc;padding:15px 20px}.contract-show-modal-footer{display:flex;justify-content:flex-end;padding-right:30px}.contract-show-modal-footer .fn-btn{margin-left:15px}.contract-show-modal-qrCode{text-align:center}.contract-show-modal-qrCode .qrCode_container{width:260px;height:260px}.contract-show-modal-dialog,.contract-show-modal-qrCode .qrCode-desc{font-size:14px}", ""]);

// exports
exports.locals = {
	"prefixCls": "contract-show-modal",
	"contract-show-modal-content": "contract-show-modal-content",
	"contract-show-modal-header": "contract-show-modal-header",
	"header-title": "header-title",
	"header-status": "header-status",
	"success-color": "success-color",
	"default-color": "default-color",
	"contract-show-modal-contract": "contract-show-modal-contract",
	"contract-show-modal-desc": "contract-show-modal-desc",
	"contract-show-modal-footer": "contract-show-modal-footer",
	"fn-btn": "fn-btn",
	"contract-show-modal-qrCode": "contract-show-modal-qrCode",
	"qrCode_container": "qrCode_container",
	"qrCode-desc": "qrCode-desc",
	"contract-show-modal-dialog": "contract-show-modal-dialog"
};