import React from 'react';
import { PureInput as Input, TextArea } from 'components';
import { bem } from 'utils';

import { prefixCls } from './index.scss';

const cls = bem(prefixCls);

const extraSet = (key, val, page) => {
  const { valHub = {} } = page.state;
  valHub[key] = val;
  // const fee = page.capitalFlowPaymode.getData()
  // page.setState({ valHub: { ...valHub }, feeInfo: fee.data })
  page.setState({ valHub: { ...valHub } });
};

const renderEl = page => {
  const { allField = {}, extraField = [], valHub = {} } = page.state;
  return extraField.map((k, t) => {
    let _el = null;
    const _key = k.key;
    switch (_key) {
      case 'create_by':
        _el = (
          <div className={cls('form-item')} key={t}>
            <label className={cls('fn-label', k.required ? 'required' : '')}>{allField[_key]}</label>
            <Input
              value={valHub[_key] || ''}
              required={k.required || false}
              disabled={k.disables || false}
              onChange={e => extraSet(_key, e.target.value, page)}
            />
          </div>
        );
        break;
      case 'remark':
        _el = (
          <div className={cls('form-remark')} key={t}>
            <label className={cls('fn-label', k.required ? 'required' : '')}>{allField[_key]}</label>
            <TextArea
              name="remark"
              maxLength="512"
              classname="remark"
              placeholder="请填写备注信息，最多支持输入512个字符"
              defaultValue={valHub[_key] || ''}
              required={k.required || false}
              disabled={k.disables || false}
              onChange={e => extraSet(_key, e.target.value, page)}
            />
          </div>
        );
        break;
      default:
        _el = null;
    }
    return _el;
  });
};

const renderExtra = page => <div className={cls()}>{renderEl(page)}</div>;

export default renderExtra;
