exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".bi-bill-adjust__adjust-container{margin:8px 0}.bi-bill-adjust__adjust-container .fn-card-box__content{padding:8px;justify-content:center}.bi-bill-adjust__bill_adjust .fn-table__footer{text-align:center!important}.bi-bill-adjust .unedit-table-style .fixedDataTableRowLayout_rowWrapper .public_fixedDataTableCell_main{border-bottom:1px solid #b9ccd7}.bi-bill-adjust .unedit-table-style .fixedDataTableRowLayout_rowWrapper:last-child .public_fixedDataTableCell_main{border-bottom:none!important}.bi-bill-adjust .public_fixedDataTable_bottomShadow{background:0 0 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAECAYAAABP2FU6AAAAHElEQVQI12MwNjZmZdAT1+Nm0JDWEGZQk1GTBgAWkwIeAEp52AAAAABJRU5ErkJggg==) repeat-x;margin-top:-4px}.bi-bill-adjust .pick-order-summary{margin-bottom:8px}.bi-bill-adjust__card-box--head{height:100%;display:flex;align-items:center;padding-left:8px}.bi-bill-adjust__card-box--head svg{color:#0097cf;margin-top:1px;width:16px;height:16px}.bi-bill-adjust__card-box--head .fn-icon-help{font-size:16px;width:16px;height:16px;color:#0097cf}.bi-bill-adjust__card-box--head .fn-icon-help:before{position:relative;top:2px}", ""]);

// exports
exports.locals = {
	"prefixCls": "bi-bill-adjust",
	"bi-bill-adjust__adjust-container": "bi-bill-adjust__adjust-container",
	"fn-card-box__content": "fn-card-box__content",
	"bi-bill-adjust__bill_adjust": "bi-bill-adjust__bill_adjust",
	"fn-table__footer": "fn-table__footer",
	"bi-bill-adjust": "bi-bill-adjust",
	"unedit-table-style": "unedit-table-style",
	"fixedDataTableRowLayout_rowWrapper": "fixedDataTableRowLayout_rowWrapper",
	"public_fixedDataTableCell_main": "public_fixedDataTableCell_main",
	"public_fixedDataTable_bottomShadow": "public_fixedDataTable_bottomShadow",
	"pick-order-summary": "pick-order-summary",
	"bi-bill-adjust__card-box--head": "bi-bill-adjust__card-box--head",
	"fn-icon-help": "fn-icon-help"
};