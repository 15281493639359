import React, { PureComponent } from 'react';
import { SlideDrager, PureInput as Input, DateTimePicker } from '@/components';
import ListPageConf from 'pages/ListPage';
import './index.scss';

class WageDetail extends PureComponent {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  renderCnt = () => {
    const config = {
      pageConfig: {
        listPage: true,
        tableInfo: {
          body: {
            fetch_mode: 'all',
          },
          category: 'DriverWageDetail',
          tab: 'driver_wage_detail',
        },
        url: 'Table/Search/getDataList',
      },
    };
    const ListPage = ListPageConf(config);
    const { wage_id, wage_month, title, wage_no } = this.props;
    return (
      <div className="wage_p">
        <div className="wage_title">
          <div>
            <label> 工资月份 </label>
            <DateTimePicker format="YYYY-MM" defaultValue={wage_month} disabled />
          </div>
          <div>
            <label>工资单名称</label>
            <Input value={title} disabled />
          </div>
          <div>
            <label>工资单编号</label>
            <span>{wage_no}</span>
          </div>
        </div>
        <div className="wage_wrap">
          <ListPage designateFilter={{ wage_id }} />
        </div>
      </div>
    );
  };
  render() {
    return (
      <SlideDrager
        isShow
        HeaderTitle="工资单详情"
        slideWidth={1200}
        ref={this.sliderWrap}
        contentDiv={this.renderCnt()}
        close={this.props.close}
      />
    );
  }
}
export default WageDetail;
