exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".capital-flow-audit{margin-top:10px;padding:0 10px}.capital-flow-audit__fn-label{font-size:14px}.capital-flow-audit .fn-textarea_wrap{margin-top:6px}.capital-flow-audit .fn-textarea{width:100%;height:60px}", ""]);

// exports
exports.locals = {
	"prefixCls": "capital-flow-audit",
	"capital-flow-audit": "capital-flow-audit",
	"capital-flow-audit__fn-label": "capital-flow-audit__fn-label",
	"fn-textarea_wrap": "fn-textarea_wrap",
	"fn-textarea": "fn-textarea"
};