import React from 'react';
import { attrHub, headerHub } from './constant';
import { CheckBox } from 'components';
import { confirm } from 'utils';
import { WARN } from 'constants';

export const showOfSetting = setting => key => setting[key] && setting[key].show;
export const requiredOfSetting = setting => key => setting[key] && setting[key].required;

export const getAttr = billType => attrHub[billType];
export const getHeader = billType => headerHub[billType];

export const getExpense = billType => {
  let expense;
  if (['loan_car', 'loan_driver', 'loan_employee'].includes(billType)) expense = 'loan';
  if (['repay_car', 'repay_driver', 'repay_employee'].includes(billType)) expense = 'repay';
  if (['income_pay'].includes(billType)) expense = 'income';
  return expense;
};

export const formatSetting = (usedFor, _set = {}) => {
  const obj = {};
  obj.amount = { label: '金额', show: true, required: true, disables: usedFor === 'view' };
  /*
  obj.bill_no = _set.bill_no
  obj.build_time = _set.build_time
  obj.create_by = _set.create_by
  obj.files = _set.files
  obj.imgs = _set.imgs
  */

  const setList = _set.table_fields_setting.value || [];
  setList.forEach(x => {
    obj[x.key] = {
      show: x.show,
      label: x.label,
      required: x.required,
      disables: usedFor === 'view' ? true : x.edit,
    };
  });

  const attr = { ..._set };
  delete attr.table_fields_setting;
  Object.keys(attr).forEach(x => {
    const v = attr[x];
    obj[x] = {
      show: v.show,
      required: v.required,
      disables: usedFor === 'view' ? true : !v.edit,
    };
  });

  // 应付运输费合计
  if (obj.pay_total_amount) {
    // 不验证必填
    obj.pay_total_amount.required = false;
    // 不可修改，根据所选任务自动带入应付运输费合计，当用户通过任务页面挑入时，自动聚合应付运输费合计
    obj.pay_total_amount.disables = true;
    // 只在创建的时候显示
    // if (usedFor !== 'add') obj.pay_total_amount.show = false;
  }

  if (usedFor === 'audit') {
    obj.audit_remark = { show: true, disables: true, required: false };
  }

  // 车辆，司机，员工余额都不可编辑
  if (obj.car_remain_amount) obj.car_remain_amount.disables = true;
  if (obj.driver_remain_amount) obj.driver_remain_amount.disables = true;
  if (obj.employee_remain_amount) obj.employee_remain_amount.disables = true;

  // 车辆，司机，员工总余额都不可编辑
  ['car_total_balance', 'driver_total_balance', 'employee_total_balance'].forEach(k => {
    if (obj[k]) obj[k].disables = true;
  });

  // if (obj.department) obj.department.disables = true

  return obj;
};

export const formatVal = (k, info = {}) => {
  let _cur;
  switch (k) {
    case 'route': // 线路
      _cur = { id: info.route, segment_name: info.route_name };
      break;
    case 'com_id':
      _cur = { id: info.com_id, short_name: info.company_name };
      break;
    case 'create_by':
      _cur = info.create_by_name;
      break;
    case 'operator':
      _cur = {
        id: info.operator,
        name: info.operator_name,
        department_id: info.department_id,
        department_name: info.department_name,
      };
      break;
    case 'department':
      _cur = { department_id: info.department, department_name: info.department_name };
      break;
    default:
      _cur = info[k];
  }
  return _cur;
};

export const formulaCalculation = (data = [], index, key, val, page) => {
  const { usedFor, billType } = page.state;
  const list = [...data];
  const pageKey = `${billType}_${usedFor}`;
  const formulaList = window.company_setting.field_association_tb
    ? window.company_setting.field_association_tb.tableData
    : [];
  if (!formulaList.length) return data;
  const _list = formulaList.filter(x => x.effect_page.length && x.effect_page[0].key === pageKey);
  if (!_list.length) return data;
  for (let i = 0, l = _list.length; i < l; i++) {
    const v = _list[i];
    const feeKey = v.effect_fee[0].keyWord;
    if (page.inputMemory[`${index}_${feeKey}`]) continue;

    let total = 0;
    const { formula } = v;
    formula.forEach(x => {
      const { ratio } = x;
      const itemKey = x.itemList;
      const _cur = key === itemKey ? val : list[index][itemKey];
      const _sum = _cur ? +_cur * ratio : 0;
      if (x.operator === 'add') {
        total += _sum;
      } else {
        total -= _sum;
      }
    });
    list[index][feeKey] = total;
  }
  return list;
};

export const getFeeInfo = ({ usedFor, payModeEditable, loadInfo, payModeInfo }) => {
  if (!payModeEditable) return [];
  const defaultPayMode = Object.values(payModeInfo).find(x => x.default);
  // eslint-disable-next-line no-nested-ternary
  return loadInfo.pay_mode && loadInfo.pay_mode.length
    ? loadInfo.pay_mode
    : defaultPayMode && usedFor === 'add'
    ? [defaultPayMode]
    : [];
};

export const rebutConfirm = ({ rebut_direct = false, onChangeRebutDirect } = {}) => {
  const content = (
    <div className="customer-dialog-content">
      <p className="big" style={{ fontSize: '16px', fontWeight: '650' }}>
        您确定要驳回单据审核吗？
      </p>
      {rebut_direct ? (
        <CheckBox direction="right" label="申请人修改后直送本节点" onClick={onChangeRebutDirect} />
      ) : null}
      <p className="big" style={{ fontSize: '12px', color: '#F59A23' }}>
        驳回后单据需要申请人修改才能自动重新发起审批。
      </p>
      {rebut_direct ? (
        <p className="big" style={{ fontSize: '12px', color: '#F59A23' }}>
          如果勾选直送本节点,在申请人修改后如果审批流程未发生变化则会直送到本节点审批
        </p>
      ) : null}
    </div>
  );
  return confirm(WARN, content, { confirm: '确定', cancel: '取消' }, '操作确认', undefined, undefined, undefined, {
    isModal: true,
  });
};
