exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".table-describe{width:90px;color:#0097cf;cursor:pointer}.table-describe .fn-icon{font-size:16px;background:#fff;width:18px;height:25px;line-height:25px;margin-right:2px}.statement-description{color:#333;font-size:14px;margin-top:12px;line-height:22px}.statement-description-detail{color:#333;font-size:4px;margin-top:4px;line-height:22px;margin-left:24px}", ""]);

// exports
exports.locals = {
	"prefixCls": "table-describe",
	"table-describe": "table-describe",
	"fn-icon": "fn-icon",
	"statement-description": "statement-description",
	"statement-description-detail": "statement-description-detail"
};