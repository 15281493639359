export const PRICE_TYPE_ENUM = [
  { name: '订单', type: 'order' },
  { name: '运单', type: 'waybill' },
];

export const EXEC_UNITS_ENUM = [
  { key: 'day', name: '天' },
  { key: 'week', name: '周' },
  { key: 'month', name: '月' },
];

export const MATCH_TYPE_ENUM = [
  { type: '1', name: '所有' },
  { type: '2', name: '单条' },
];

export const CONDITIONS_ENUM = [
  { type: 'contains', name: '包含' },
  { type: 'not_contains', name: '不包含' },
  { type: 'empty', name: '为空' },
  { type: 'not_empty', name: '非空' },
];
