import React, { useState } from 'react';
import { Modal, Button, Form, Input, Alert } from '@gui/web-react';
import moment from 'moment';
import vega from 'utils/vega';
import { dataAnalyticTrack } from '@/utils/dataAnalytic';
import { useMessages, useMessagesDispatch } from '../messagesContext';
import { prefixCls } from './index.scss';

const FormItem = Form.Item;

export default function ModalForm() {
  const { messages, send, tenantId, editInfo } = useMessages();
  const { chatId, cardIndex, show } = editInfo;
  const dispatch = useMessagesDispatch();
  const groupData = messages.find(message => message.chatId === chatId) ?? {};
  const { cardList } = groupData ?? {};
  const current = cardList?.find((card, i) => i === cardIndex) ?? {};
  const { capacity_info = [], waybill_count, group_field_data = [], field_data = [] } = current;
  const [values, setValues] = useState(current);

  const handelSave = () => {
    const newCardList = cardList.map((card, i) => {
      if (i === cardIndex) {
        return {
          ...card,
          ...values,
        };
      }
      return card;
    });
    const { group_id, company_id, user_id } = window;

    try {
      // 调用保存失败，不影响流程
      vega.orderCenter.modifyWaybill({
        tenant_id: tenantId,
        platform_group_id: group_id,
        platform_company_id: company_id,
        platform_user_id: user_id,
        parse_content: {
          domain_code: 'waybill',
          channel: 4,
          llm_scene: 1,
          identified_chat_record_id: chatId,
          identified_data: newCardList,
        },
      });
    } catch (error) {
      console.error('error: ', error);
    }
    // 修改聊天记录卡片
    dispatch({
      type: 'AI_CARD_LIST_UPDATE',
      cardList: newCardList,
      chatId,
    });

    closeModal();
    dataAnalyticTrack(['大模型智能助手', '智能录单助手', '运单组卡片-编辑保存']);
  };

  const handleCreate = () => {
    handelSave();

    // 建单消息发送
    send({
      dataType: 'llm_waybill_create',
      scene: '1',
      waybill_origin_chat_record_id: chatId,
      waybill_group_info: [values],
    });

    dataAnalyticTrack(['大模型智能助手', '智能录单助手', '确定建单']);
  };

  const closeModal = () => {
    dispatch({
      type: 'AI_CARD_EDIT_CLOSE',
    });

    dataAnalyticTrack(['大模型智能助手', '智能录单助手', '运单组卡片-编辑取消']);
  };

  return (
    <Modal
      className={prefixCls}
      onCancel={() => closeModal()}
      title={`智能助手-运单组${cardIndex + 1}_${moment().format('YYYY-MM-DD HH:mm:ss')}【编辑】`}
      visible={show}
      footer={
        <>
          <Button className={`${prefixCls}-btnSave`} onClick={() => handelSave()}>
            保存并返回助手
          </Button>
          <Button className={`${prefixCls}-btnCreate`} onClick={() => handleCreate()} type="primary">
            确定建单(x{waybill_count})
          </Button>
        </>
      }
    >
      {editInfo.errMsg && <Alert type="error" content={editInfo.errMsg} />}
      <Form
        initialValues={{ ...current }}
        onValuesChange={(_, changedValues) => {
          setValues(changedValues);
        }}
        className={`${prefixCls}-form`}
        layout="inline"
        autoComplete="off"
      >
        {capacity_info?.length > 0 &&
          capacity_info.map((el, i) => {
            return (
              <FormItem field={`capacity_info[${i}].value`} key={i} label={el.label}>
                <Input className={`${prefixCls}-form__input`} value={el.value} placeholder="请输入" />
              </FormItem>
            );
          })}
        {group_field_data?.length > 0 &&
          group_field_data.map((el, j) => {
            return (
              <FormItem field={`group_field_data[${j}].value`} key={j} label={el.label}>
                <Input className={`${prefixCls}-form__input`} placeholder="请输入" />
              </FormItem>
            );
          })}
        {field_data?.length > 0 &&
          field_data.map((el, m) => {
            return (
              <FormItem field={`field_data[${m}].value`} key={m} label={el.label}>
                <Input className={`${prefixCls}-form__input`} placeholder="请输入" />
              </FormItem>
            );
          })}
      </Form>
    </Modal>
  );
}
