exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".settle-propaganda-form .fn-dialog__center{padding:40px 35px}.settle-propaganda-form .form-title{color:#131e27;font-size:16px;margin-bottom:28px}.settle-propaganda-form .fn-input-pure-wrap{width:358px;height:48px;display:block;position:relative;margin-bottom:20px}.settle-propaganda-form .fn-input-pure-wrap .fn-icon-avtor,.settle-propaganda-form .fn-input-pure-wrap .fn-icon-iphone{top:20px;left:18px;font-size:18px;position:absolute}.settle-propaganda-form .form-confirm{color:#fff;width:358px;height:48px;border:none;font-size:16px;border-radius:8px;background-image:none;background-color:#ff9d2a}.settle-propaganda-form .form-confirm:hover{border-color:initial}.settle-propaganda-form .fn-input-pure{padding-left:50px}", ""]);

// exports
exports.locals = {
	"prefixCls": "settle-propaganda-form",
	"settle-propaganda-form": "settle-propaganda-form",
	"fn-dialog__center": "fn-dialog__center",
	"form-title": "form-title",
	"fn-input-pure-wrap": "fn-input-pure-wrap",
	"fn-icon-avtor": "fn-icon-avtor",
	"fn-icon-iphone": "fn-icon-iphone",
	"form-confirm": "form-confirm",
	"fn-input-pure": "fn-input-pure"
};