import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Tips } from 'components';
import config from './config';
import { withPopUpI18n, defaultI18n } from '@/utils/i18n/context';
import './index.scss';

const smallTextStyle = {
  fontSize: '12px',
  fontFamily: 'MicrosoftYaHei',
};
const smallSvgStyle = { width: '20px', height: '20px', marginRight: '4px' };
const normalTextStyle = {
  fontSize: '17px',
  fontFamily: 'FZLanTingHei-B-GBK',
  fontWeight: 'bold',
};
const normalSvgStyle = { width: '26px', height: '28px' };
class Sign extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    // tips显示的信息
    tips: PropTypes.string,
    // alt: 对标签的描述。区分下面类型的情况:扫码装车,扫码卸车,扫码入库;他们的text都是扫
    alt: PropTypes.string,
    // 标签的尺寸，目前只有两种，分别是: normal 和 small
    size: PropTypes.string,
    text: PropTypes.string,
    color: PropTypes.string,
    bgColor: PropTypes.string,
    i18n: PropTypes.object,
  };

  static defaultProps = {
    alt: '',
    size: 'normal',
    i18n: defaultI18n,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  renderSmall = (className, text, color, bgColor) => (
    <svg
      className={className}
      style={smallSvgStyle}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <polygon points="1 1, 19 1, 19 14, 10 19, 1 14" stroke={color} fill={bgColor} strokeWidth="1.2" />
      <text x="4px" y="13px" style={smallTextStyle} fill={color}>
        {text}
      </text>
    </svg>
  );

  renderNormal = (className, text, color, bgColor) => (
    <svg
      className={className}
      style={normalSvgStyle}
      viewBox="0 0 26 28"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <polygon points="0 0, 26 0, 26 20.5, 13 28, 0 20.5" stroke={color} fill="#FFFFFF" strokeWidth="0.7" />
      <polygon points="1 1, 25 1, 25 20, 13 26.8, 1 20" stroke={color} fill={bgColor} strokeWidth="1" />
      <text x="5px" y="18px" style={normalTextStyle} fill={color}>
        {text}
      </text>
    </svg>
  );

  // 渲染一些不可进行设计的图标, 如: 导入，安卓，ios和平板
  renderOthers = (text, size) => <span className={`${text}-${size}`} />;

  render() {
    const { className, size, alt, i18n } = this.props;
    const iconConfig = { ...this.props, ...(config(i18n)[alt] || config(i18n)[this.props.text]) };
    const { color, bgColor, text, tips, hide } = iconConfig;
    if (!text) {
      return null;
    }
    let _html;
    if (!color && !bgColor) {
      _html = !hide ? this.renderOthers(text, size) : null;
    } else if (size === 'small') {
      _html = !hide ? this.renderSmall(className, text, color, bgColor) : null;
    } else {
      _html = !hide ? this.renderNormal(className, text, color, bgColor) : null;
    }
    return (
      <Tips className="sign-tips" title={tips || alt}>
        {_html}
      </Tips>
    );
  }
}
export default withPopUpI18n(Sign);
