import React from 'react';
import copy from 'copy-to-clipboard';

import { Button, PopUp, ShowInfo, ModalDialog } from 'components';

import { prefixCls } from './index.scss';
import { showInfo, postJAVA, showDialog } from 'utils';
import { ERROR, INFO, CHECK } from 'constants';
import RechargeDialog from 'components/commoncomponents/AccountRechargeDialog';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import PropTypes from 'prop-types';

class ContractShowModal extends React.Component {
  static propTypes = {
    contractInfo: PropTypes.object,
    isSignAgain: PropTypes.bool,
  };
  static defaultProps = {
    isSignAgain: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      contractInfo: {}, // 合同信息
      driver: {}, // 司机信息
      signStatus: {
        0: '未签署',
        1: '签署中',
        2: '已签署',
      }, // 签署状态
      numPages: 1,
    };
  }
  componentDidMount() {
    // 合同信息在上个页面传过来
    this.setState({
      contractInfo: this.props.contractInfo || {},
    });
    // 未签署
    if (this.contractInfo?.sign_status !== 2) {
      this.getContractConfirm();
    }
  }
  // 签署前获取积分和司机信息
  getContractConfirm = async () => {
    const req = { b_basic_id: this.props?.b_basic_id };
    const url = '/cmm-batch/electronic-contract/confirm';
    try {
      const res = await postJAVA(url, req);
      if (res.errno === 0) {
        this.setState({
          driver: res.res,
        });
      } else {
        showInfo(ERROR, res.errmsg);
      }
    } catch (error) {
      showInfo(ERROR, '网络错误');
    }
  };
  // 发起签署
  getApplySign = async () => {
    this.props.close();
    // 积分不够 弹窗为账户充值页面的弹窗
    if (!this.state.driver?.integral_adequate) {
      new PopUp(RechargeDialog, {
        title: '充值',
      }).show();
      return;
    }
    const content = (
      <div className="contract-dialog_detail">
        确定发起合同签署?点击【发送】后将扣除积分。
        <br />
        同时
        <div style={{ color: '#0a91d5', display: 'inline-block' }}>
          司机({this.state.driver?.dr_name}
          {this.state.driver?.dr_phone})
        </div>
        将收到签约短信,司机可点击链接打开司小宝完成合同签署。
        <br />
        扣除: <div style={{ color: '#f00', display: 'inline-block' }}>{this.state.driver?.integral_num}积分</div>
      </div>
    );

    new PopUp(ShowInfo, {
      type: INFO,
      content,
      title: '操作确认',
      buttons: [
        {
          name: '发送',
          btnType: 'primary',
          cb: async thisDialog => {
            thisDialog.handleHide();
            const req = { b_basic_id: this.props?.b_basic_id, sign_again: this.props.isSignAgain };
            const url = '/cmm-batch/electronic-contract/apply-sign';
            try {
              const res = await postJAVA(url, req);
              if (res.errno === 0) {
                // 能签署
                showInfo(CHECK, '发送成功');
              } else {
                showInfo(ERROR, res.errmsg);
              }
            } catch (error) {
              showInfo(ERROR, '网络错误');
            }
          },
        },
        { name: '取消', btnType: 'default', cb: thisDialog => thisDialog.handleHide() },
      ],
      escBind: false,
    }).show();
  };
  sendMsg = async () => {
    this.props.close();
    const content = (
      <div className="contract-show-modal-dialog">
        确定给
        <div style={{ color: '#0a91d5', display: 'inline-block' }}>
          司机({this.state.driver?.dr_name}
          {this.state.driver?.dr_phone})
        </div>
        再次发送一条合同签约短信,司机可点击链接直接签署合同。
      </div>
    );
    new PopUp(ShowInfo, {
      type: INFO,
      content,
      autoDestroy: true,
      title: '操作确认',
      popName: 'customer-key',
      buttons: [
        {
          name: '发送',
          btnType: 'primary',
          cb: async thisDialog => {
            thisDialog.handleHide();
            const req = { b_basic_id: this.props?.b_basic_id };
            const url = '/cmm-batch/electronic-contract/apply-sign';
            try {
              const res = await postJAVA(url, req);
              if (res.errno === 0) {
                showInfo(CHECK, '发送成功');
              } else {
                showInfo(ERROR, res.errmsg);
              }
            } catch (error) {
              showInfo(ERROR, '网络错误');
            }
          },
        },
        { name: '取消', btnType: 'default', cb: thisDialog => thisDialog.handleHide() },
      ],
    }).show();
  };
  sendWechat = async () => {
    this.props.close();
    const req = { b_basic_id: this.props?.b_basic_id };
    const url = '/cmm-batch/electronic-contract/wx-content';
    try {
      const res = await postJAVA(url, req);
      if (res.errno === 0) {
        copy(res.res);
        showDialog(
          {
            type: INFO,
            title: '操作提示',
            content: `已复制合同链接,请去微信发送给司机(${this.state.driver?.dr_name}${this.state.driver?.dr_phone})`,
          },
          true,
          false,
          { confirm: '我知道了' },
        ).show();
      } else {
        showInfo(ERROR, res.errmsg);
      }
    } catch (error) {
      showInfo(ERROR, '网络错误');
    }
  };
  qrcodeSign = async () => {
    this.props.close();
    const req = { b_basic_id: this.props?.b_basic_id };
    const url = '/cmm-batch/electronic-contract/wx-qr';

    try {
      const res = await postJAVA(url, req);
      try {
        if (res.errno !== 0) {
          showInfo(ERROR, res.errmsg);
        }
      } catch (e) {
        showInfo(ERROR, '网络错误');
      }
      // 二维码
      const qrCode = `/api/Trade/Base/qrCode?url=${res?.res}`;
      const content = (
        <div className="contract-show-modal-qrCode">
          <img src={qrCode} className="qrCode_container" />
          <div className="qrCode-desc">
            请司机({this.state.driver?.dr_name} {this.state.driver?.dr_phone})使用微信扫一扫签署合同
          </div>
        </div>
      );
      if (res.errno === 0) {
        new PopUp(ModalDialog, {
          content,
          isModal: true,
          isShow: true,
          closable: true,
          maskClosable: true,
          escBind: false,
        }).show();
      } else {
        showInfo(ERROR, res.errmsg);
      }
    } catch (error) {
      showInfo(ERROR, '网络错误');
    }
  };
  download = async () => {
    this.props.close();
  };
  renderHeader() {
    return (
      <div className="contract-show-modal-header">
        <div className="header-title">电子合同</div>
        <div className="header-status">
          <div className={`${this.state.contractInfo?.sign_status === 2 ? 'success-color' : 'default-color'}`}>
            {this.state.signStatus[this.state.contractInfo?.sign_status]}
          </div>
        </div>
      </div>
    );
  }
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };
  renderContractPdf() {
    const { numPages } = this.state;
    return (
      <Document
        file={this.state.contractInfo?.download_pdf_url}
        onLoadSuccess={this.onDocumentLoadSuccess}
        onSourceError={this.onSourceError}
      >
        {new Array(numPages).fill().map((item, index) => {
          return (
            <Page
              key={index}
              pageNumber={index + 1}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              width="740"
            />
          );
        })}
      </Document>
    );
  }
  // 电子合同 未签署展示html  签署展示pdf
  renderContract() {
    return (
      <div className="contract-show-modal-contract">
        {this.state.contractInfo?.sign_status === 2 ? (
          this.renderContractPdf()
        ) : (
          <div dangerouslySetInnerHTML={{ __html: this.state.contractInfo?.preview_content }} />
        )}
      </div>
    );
  }
  renderDesc() {
    return (
      <div className="contract-show-modal-desc">
        <div>免责声明：平台与相关方合作为您提供电子合同签署能力,不对合同具体内容和履行承担责任。</div>
        <div>
          特别说明：平台仅与相关方合作为您提供电子合同签署能力,具体电子合同为您与司机自行签署，
          可用于替代您与司机线下签署的纸质运输合同,更加方便、快捷、有法律效力
        </div>
      </div>
    );
  }
  renderFooter() {
    const { loading } = this.state;
    return (
      <div className="contract-show-modal-footer">
        {/* 未发起 */}
        {this.state.contractInfo?.sign_status === 0 && (
          <Button
            disabled={!this.state.contractInfo?.authenticated}
            onClick={this.getApplySign}
            loading={loading}
            type="primary"
          >
            发起合同签署
          </Button>
        )}
        {/* 待签署 */}
        {this.state.contractInfo?.sign_status === 1 && (
          <div>
            <Button onClick={this.sendMsg} loading={loading} type="primary">
              再次发送短信
            </Button>
            <Button onClick={this.sendWechat} loading={loading} type="primary">
              发送司机微信
            </Button>
            <Button onClick={this.qrcodeSign} loading={loading} type="primary">
              二维码签署
            </Button>
          </div>
        )}
        {/* 已签署 */}
        {this.state.contractInfo?.sign_status === 2 && (
          <a href={this.state.contractInfo?.download_pdf_url} download target="_blank" rel="noreferrer">
            <Button onClick={this.download} loading={loading} type="default">
              下载
            </Button>
          </a>
        )}
      </div>
    );
  }

  render() {
    return (
      <div className={`${prefixCls}-content`}>
        {this.renderHeader()}
        {this.renderContract()}
        {this.renderDesc()}
        {this.renderFooter()}
      </div>
    );
  }
}

export default ContractShowModal;
