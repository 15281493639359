import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { SlideDrager, Button, PureTextarea, PureCheckbox, Icon, Table, PopUp } from 'components';
import {
  post,
  showInfo,
  proxyListPage,
  emitListBtnOp,
  emitListLinkOp,
  validateFieldsOf,
  sleep,
  hasPermission,
} from 'utils';
import { batchPaymentApply } from 'operation/listOperation/taskOp';
import { CHECK, ERROR, WARN } from 'constants';
import BatchOp from 'components/commoncomponents/BatchOp';
import { prefixCls } from './index.scss';

const operation = 'fee_report_apply';
const tab = 'b_fee_list_popup';
class TaskInTransitFeeAudit extends React.Component {
  static propTypes = {
    close: PropTypes.func,
    isTask: PropTypes.bool,
    ids: PropTypes.string,
    checkSource: PropTypes.number, // 在途费用审核埋点
    isCancelAudit: PropTypes.bool,
    onSaveSuccess: PropTypes.func,
    pageInfo: PropTypes.object,
  };
  state = {
    data: [],
    header: {},
    enumerations: {},
    onlyCanAudit: true,
    title: this.props.isCancelAudit ? '取消审核' : '审核',
    status: this.props.isCancelAudit ? [20, 30, 40, 15] : [10, 20],
    buttonStatus: false,
    isLastAuditor: false,
  };
  componentDidMount() {
    this.initData();
    this.isLastAuditor();
  }
  initData() {
    this.setState({ loading: true });
    const filter = {
      [this.props.isTask ? 'b_basic_id' : 'id']: this.props.ids,
      audit_state: this.state.onlyCanAudit ? this.state.status : undefined,
    };
    const req = { filter, category: 'BatchFeeApproval', tab, fetch_mode: 'all' };
    post('Table/Search/batchFeeApprovalList', req).then(res => {
      this.setState({
        loading: false,
        data: res.res.data,
        header: res.res.header,
        enumerations: res.res.enumerations,
      });
    });
  }
  isLastAuditor = () => {
    const { ids, isCancelAudit } = this.props;
    if (ids.length === 1 && !isCancelAudit) {
      const req = {
        data: [
          {
            apply_id: ids[0],
            operation: 'fee_report_apply',
          },
        ],
      };
      post('/Basic/Approval/isLastAuditor', req).then(res => {
        if (res?.res?.success?.length) {
          this.setState({
            isLastAuditor: true,
          });
        }
      });
    }
  };
  setting = () => {
    const refresh = () => this.initData();
    const listPage = proxyListPage([{}], {
      category: 'BatchFeeApproval',
      tab,
      fromPage: 'taskInTransitFeeAudit',
      refresh,
    });
    emitListBtnOp({ space: 'taskInTransitFeeAudit', listPage, key: 'set_header', buttonData: {} });
  };
  onChange = e => {
    this.setState({
      opinion: e.target.value,
    });
  };

  handleLinkClick = (index, key, row, obj) => {
    const emitKey = `BatchFeeApproval_${key}`;
    const refresh = () => this.initData();
    const listPage = proxyListPage(this.state.data, {
      category: 'BatchFeeApproval',
      tab,
      fromPage: 'taskInTransitFeeAudit',
      refresh,
    });
    emitListLinkOp({ space: 'taskInTransitFeeAudit', listPage, key: emitKey, index, col: key, row, obj });
  };
  submit = async (audit, isCreatePayment = false) => {
    const { checkSource } = this.props;
    const { opinion, title, enumerations, header } = this.state;
    const selectedData = this.table.getStateSelecteRows();
    const tableData = selectedData.map(item => ({
      ...item,
      fee_name: enumerations?.[header?.fee_id?.refEnum]?.find(fee => +fee.key === +item.fee_id)?.name || '',
    }));

    // 1：通过 2：确认 3:拒绝 4:驳回
    const isRefuse = !![3, 4].includes(audit);
    this.setState({
      buttonStatus: isRefuse && audit !== 4,
      loading: true,
    });

    await sleep(100);

    if (!tableData.length) {
      this.setState({
        loading: false,
      });
      return showInfo(WARN, '请选择一条数据');
    }

    if (!opinion && isRefuse && !(await validateFieldsOf(this.form)) && audit !== 4) {
      this.setState({
        loading: false,
      });
      showInfo(ERROR, `拒绝时，审核备注必填`);
      return;
    }

    const url = this.props.isCancelAudit ? 'Basic/Approval/cancelAudit' : 'Basic/Approval/audit';

    if (tableData.length >= 2) {
      // 是否是最后一个审批节点
      let isLastAuditorIds = [];
      let sucIds = [];
      if (!isRefuse && !this.props.isCancelAudit) {
        const req = {
          data: tableData.map(i => ({
            apply_id: i.id,
            operation: 'fee_report_apply',
          })),
        };
        post('/Basic/Approval/isLastAuditor', req).then(res => {
          if (res?.res?.success?.length) {
            isLastAuditorIds = res?.res?.success?.map(i => i.apply_id);
          }
        });
      }
      const batchOpHeader = {
        car_batch: { title: '批次号', display: 'show' },
        fee_name: { title: '费用项', display: 'show' },
        amount: { title: '金额', display: 'show' },
        status: { title: '操作状态', display: 'show' },
      };
      new PopUp(BatchOp, {
        title: '批量操作',
        header: batchOpHeader,
        data: tableData,
        tips: title,
        orderType: '费用项',
        prefixalTips: '以下费用项',
        requestUrl: url,
        getPara: dataList => {
          return dataList.map(item => ({
            audit,
            opinion,
            data: [
              {
                number: `${item.car_batch},${item.fee_name}`,
                apply_id: item.id,
                operation,
              },
            ],
            check_source: checkSource,
          }))[0];
        },
        paceCallback: async resData => {
          sucIds.push(resData?.res?.success?.[0]);
        },
        footerRender: emit => {
          const { isFinish, ready } = emit.state;
          const btnText = isFinish ? '关闭' : '取消';
          const cancelType = ready ? 'primary' : 'default';
          sucIds = sucIds.filter(i => isLastAuditorIds?.indexOf(i) > -1);
          const showPaymentBtn =
            isFinish &&
            !this.props.isCancelAudit &&
            !isRefuse &&
            sucIds.length > 0 &&
            hasPermission('reviewPaymentOnwayV1');
          return (
            <div>
              {showPaymentBtn && (
                <Button
                  type="primary"
                  key="confirm"
                  onClick={() => {
                    this.createPayment(sucIds);
                    setTimeout(() => {
                      emit.handleButtonClick();
                    }, 500);
                  }}
                >
                  “审核通过”数据创建司机报销单
                </Button>
              )}
              <Button
                type={cancelType}
                key="cancel"
                onClick={() => {
                  emit.handleButtonClick();
                }}
              >
                {btnText}
              </Button>
            </div>
          );
        },
        finishCallback: () => {
          this.props?.onSaveSuccess?.();
        },
        escBind: false,
      }).show();
      this.setState({
        loading: false,
      });
      this.slide && this.slide.handleHide();
    } else {
      this.setState({
        loading: true,
      });
      post(url, {
        audit,
        opinion,
        data: tableData.map(item => ({
          number: `${item.car_batch}，${item.fee_name}`,
          apply_id: item.id,
          operation,
        })),
        check_source: checkSource,
      })
        .then(res => {
          if (res.errno === 0) {
            if (isCreatePayment) {
              return this.createPayment(tableData.map(i => i.id));
            }
            showInfo(CHECK, '操作成功');
            this.slide.handleHide();
            this.props?.onSaveSuccess?.();
            return;
          }
          // CYTRD-16500 防止重复提交
          if (+res?.errno === 941) {
            // do nothing.
            return;
          }
          const errmsg = res.errmsg || _.get(res.res, 'failed_detail.0.msg');
          showInfo(ERROR, errmsg);
        })
        .finally(() => {
          this.setState({
            loading: false,
          });
        });
    }
  };
  // 创建报销单
  createPayment(ids) {
    const { pageInfo } = this.props;
    batchPaymentApply(pageInfo, 'batch_payment_apply_v1', {
      ids,
      isTask: false,
    });
    setTimeout(() => {
      this?.slide?.handleHide();
      this.props?.onSaveSuccess?.();
    }, 500);
  }
  renderContent = () => {
    const { data, header, onlyCanAudit, opinion, enumerations, buttonStatus } = this.state;
    const defaultSelectes = Array.from(Array(data.length).keys());
    return (
      <div className={prefixCls}>
        <div className={`${prefixCls}__setting`}>
          <label>
            <PureCheckbox
              isSwitch
              checked={onlyCanAudit}
              onChange={e => this.setState({ onlyCanAudit: e.target.checked }, this.initData)}
            />
            只显示可{this.props.isCancelAudit ? '取消' : ''}审核费用
          </label>
          <Button onClick={this.setting}>
            <Icon iconType="icon-setting" />
          </Button>
        </div>
        <div className={`${prefixCls}__list`}>
          <Table
            refGetter={r => {
              this.table = r;
            }}
            data={data}
            header={header}
            isSelectColumn
            isOrderNumberColumn
            isShowFilterRowIcon
            handleLinkClick={this.handleLinkClick}
            enumerations={enumerations}
            frontFilter
            defaultSelectes={defaultSelectes}
          />
        </div>
        <div
          className={`${prefixCls}__opinion`}
          ref={ref => {
            this.form = ref;
          }}
        >
          <PureTextarea
            placeholder={this.props.isCancelAudit ? '请输入取消原因！' : '请输入审核意见！'}
            value={opinion}
            maxLength="100"
            // 拒绝需要填原因
            required={buttonStatus}
            showCount
            onChange={this.onChange}
          />
        </div>
      </div>
    );
  };
  renderFooter = () => {
    const operate = this.props.operations || '';
    const approvalButtons = _.get(window, `systemConfig.approval_config.${operate}.button`) || [];
    const { isCancelAudit } = this.props;
    const { loading, data, isLastAuditor } = this.state;
    const showPaymentBtn = data.length === 1 && hasPermission('reviewPaymentOnwayV1') && isLastAuditor;

    // 默认值
    let buttons = [
      <Button onClick={() => this.submit(1)} type="primary" loading={loading}>
        通过
      </Button>,
      <Button onClick={() => this.submit(3)} loading={loading}>
        拒绝
      </Button>,
    ];

    if (showPaymentBtn) {
      buttons.push(
        <Button onClick={() => this.submit(1, true)} type="primary" loading={loading}>
          通过并创建司机报销单
        </Button>,
      );
    }

    // 取消审核
    if (isCancelAudit) {
      buttons = [
        <Button onClick={() => this.submit(1)} type="primary" loading={loading}>
          确定
        </Button>,
        <Button onClick={this.props.close} loading={loading}>
          取消
        </Button>,
      ];
      // 审核存在配置
    } else if (approvalButtons.length) {
      buttons = approvalButtons.map(item => (
        <Button loading={loading} onClick={() => this.submit(item.audit)} type={item.type}>
          {item.title}
        </Button>
      ));
      if (showPaymentBtn) {
        buttons.push(
          <Button onClick={() => this.submit(1, true)} type="primary" loading={loading}>
            通过并创建司机报销单
          </Button>,
        );
      }
    }
    return <div className={SlideDrager.footerClass}>{buttons}</div>;
  };
  render() {
    return (
      <SlideDrager
        isShow
        slideWidth={1000}
        popName="task-in-transit-fee-audit"
        ref={ref => {
          this.slide = ref;
        }}
        HeaderTitle={this.state.title}
        contentDiv={this.renderContent()}
        footerDiv={this.renderFooter()}
        close={this.props.close}
      />
    );
  }
}
export default TaskInTransitFeeAudit;
