import React from 'react';
import { Carousel } from '@gui/web-react';
import { prefixCls } from './index.scss';

class CarouselModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  hideCarouselModal = () => {
    this.props.close();
  };
  render() {
    const { imgList } = this.props;
    return (
      <Carousel className={`${prefixCls}-content`}>
        {imgList.map((src, index) => (
          <div key={index}>
            <img src={src} style={{ width: '100%' }} onClick={this.hideCarouselModal} />
          </div>
        ))}
      </Carousel>
    );
  }
}

export default CarouselModal;
