import React from 'react';
import { func, bool, object, string } from 'prop-types';
import { dataAnalyticTrack } from '@/utils/dataAnalytic';
import _ from 'lodash';
import { SlideDrager, Button, Table, CheckBox, PopUp } from 'components';
import { ERROR } from 'constants';
import BatchOp from 'components/commoncomponents/BatchOp';
import { showInfo, validateFieldsOf } from 'utils';
import { prefixCls } from './index.scss';
import { paymentRequestHeader, batchOpHeader, payBillMap } from './constant';
import classnames from 'classnames';
export class PaymentRequest extends React.Component {
  static propTypes = {
    close: func,
    isTask: bool,
    res: object,
    onSaveSuccess: func,
    showBySlideDrager: bool,
    opType: string,
    isReceiptPayment: bool,
  };
  static defaultProps = {
    showBySlideDrager: true,
    isReceiptPayment: false, // 是否来源是司机报销单
    opType: '发起付款申请',
  };

  state = {
    tableList: _.cloneDeep(this.props.res.list) || [],
  };
  UNSAFE_componentWillMount() {
    let { tableList } = this.state;
    tableList = tableList.map(item => {
      return {
        ...item,
        amount_icon: 'icon-help',
        otherProps: {
          amount_icon: {
            otherProps: {
              tips: item.fees && item.fees.reduce((prev, cur) => prev + `${cur.fee_name}:${cur.amount} `, ''),
            },
          },
        },
        checked: true,
      };
    });
    this.setState({
      tableList,
    });
  }
  // 处理付款申请的勾选
  handleCheckedChange(index) {
    const { tableList } = this.state;
    tableList[index].checked = !tableList[index].checked;
    this.setState({
      tableList,
    });
  }
  // 处理table中input输入 参数index表示第几个表格
  handleTableChange(index, rowIndex, col, value) {
    const { tableList } = this.state;
    tableList[index][col] = value;
    this.setState({
      tableList,
    });
  }
  /**
   * 处理下拉选中
   * index 第几个表格，
   * tableIndex 表格中的第几行内容
   */
  handleSelectDropSelect(index, tableIndex, colKey, value, rowData) {
    const changeData = _.pick(value[0], ['tr_payee_name', 'tr_payee_open_bank', 'tr_payee_bank_card_num']);
    const { tableList } = this.state;
    tableList[index] = {
      ...tableList[index],
      ...changeData,
    };
    this.setState({
      tableList,
    });
  }
  // 发起付款申请提交
  submit = async () => {
    // 发起申请提交前校验必填项
    const { tableList = [] } = this.state;
    this.validate = true;
    this.wrapList = this.wrapList.filter(item => item);
    for (let i = 0; i < this.wrapList.length; i++) {
      if (!(await validateFieldsOf(this.wrapList[i]))) {
        this.validate = false;
      }
    }
    if (!this.validate) return;
    // 司机报销单入口需要打点 3561
    if (this.props.isReceiptPayment) {
      dataAnalyticTrack(['司机报销单', '老版', '发起报销申请']);
    }

    const url = '/cmm-finance/transitFee/payment/apply';
    const requestData = tableList.filter(item => item.checked);
    if (requestData.length <= 0) {
      showInfo(ERROR, '您需要至少选择一个付款申请，如都不需要付款请操作关闭');
      return false;
    }
    new PopUp(BatchOp, {
      title: '批量操作',
      header: batchOpHeader,
      data: requestData,
      isJAVA: true,
      tips: this.props.opType ?? '发起付款申请',
      prefixalTips: '',
      requestUrl: url,
      getPara: dataList =>
        dataList.map(item => ({
          operate_data: item.pay_params,
          bill_info: {
            account_holder: item.tr_payee_name,
            account_bank: item.tr_payee_open_bank,
            bank_num: item.tr_payee_bank_card_num,
            remark: item.remark,
          },
        }))[0],
      finishCallback: () => {
        this.props.onSaveSuccess?.();
      },
      escBind: false,
    }).show();
    this.slide?.handleHide?.();
  };
  renderContent = () => {
    this.wrapList = [];
    const { tableList = [] } = this.state;
    const { title } = this.props.res;
    return (
      <div className={classnames(prefixCls)} style={this.props.contentStyle}>
        <div className={`${prefixCls}__list`}>
          {this.props.customeHead}
          <div className={`${prefixCls}__list--content`}>
            <div className="list-tip" dangerouslySetInnerHTML={{ __html: title }} />
            {tableList.map((item, index) => {
              return (
                <div className="list-item" ref={ref => this.wrapList.push(ref)}>
                  <div>
                    <CheckBox direction="left" checked={item.checked} onClick={() => this.handleCheckedChange(index)} />
                    <span dangerouslySetInnerHTML={{ __html: item.title }} />
                  </div>
                  <div className="table-wrap">
                    <Table
                      sortIner
                      filterIner
                      rowHeight={26}
                      rowClickable={false}
                      isShowTotalRow={false}
                      isShowFilterRow={false}
                      ifShowFilterRowIcon={false}
                      isShowOperation={false}
                      handleChange={(rowIndex, col, value) => this.handleTableChange(index, rowIndex, col, value)}
                      handleSelectDropSelect={(tableIndex, colKey, value, rowData) =>
                        this.handleSelectDropSelect(index, tableIndex, colKey, value, rowData)
                      }
                      refGetter={r => (this.table = r)}
                      data={[item]}
                      header={item.checked ? this.getHeader(paymentRequestHeader) : paymentRequestHeader}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };
  renderFooter = () => {
    const { loading } = this.state;
    return (
      <div className={SlideDrager.footerClass}>
        <Button onClick={() => this.submit()} type="primary" loading={loading}>
          {this.props.opType ?? '发起付款申请'}
        </Button>
      </div>
    );
  };
  // 获取表格header
  getHeader = header => {
    header = _.cloneDeep(header);
    _.get(window, 'company_setting.pay_bill_field_setting.value', []).forEach(item => {
      if (payBillMap[item.key] && item.required) {
        header[payBillMap[item.key]].required = true;
      }
    });
    return header;
  };
  render() {
    if (!this.props.showBySlideDrager)
      return (
        <div className={classnames(this.props.wrapClassname)} style={this.props.style}>
          {this.renderContent()}
          {this.renderFooter()}
        </div>
      );
    return this.renderContent();
  }
}

export class PaymentRequestSide extends PaymentRequest {
  render() {
    return (
      <SlideDrager
        isShow
        slideWidth={1000}
        popName="request-payment"
        ref={ref => (this.slide = ref)}
        HeaderTitle="发起付款申请"
        contentDiv={this.renderContent()}
        footerDiv={this.renderFooter()}
        close={this.props.close}
      />
    );
  }
}
export default PaymentRequestSide;
