exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".message-drawer{min-width:30%;max-width:50%;min-height:50%;max-height:calc(100% - 44px);height:calc(100% - 44px);z-index:3;background-color:#fff}.message-drawer.gui-resizebox{position:absolute;right:0;top:44px;width:35%}.message-drawer .arco-icon{display:inline-block;color:inherit;font-style:normal;width:1em;height:1em;vertical-align:-2px;stroke:currentColor}.message-drawer.hidden{visibility:hidden}.message-drawer-box{text-align:center;display:flex;flex-direction:column;height:100%}.message-drawer-title{background-color:#532fe6;padding:0 8px;height:35px;line-height:35px;display:flex;align-items:center;justify-content:space-between}.message-drawer-title span{color:#fff;font-size:14px;font-weight:500}.message-drawer-title .close{color:#fff;cursor:pointer;font-size:16px}.message-drawer-content{overflow:hidden;background-color:#f2f3f5}.message-drawer-footer.gui-layout-footer{height:30%}.message-drawer .gui-list{border:none}.message-drawer .gui-list-wrapper,.message-drawer .gui-spin,.message-drawer .gui-spin-children{height:100%}.message-drawer .gui-spin-dot,.message-drawer .gui-spin-dot:nth-child(2),.message-drawer .gui-spin-dot:nth-child(3),.message-drawer .gui-spin-dot:nth-child(4),.message-drawer .gui-spin-dot:nth-child(5){background-color:#532fe6}", ""]);

// exports
exports.locals = {
	"prefixCls": "message-drawer",
	"message-drawer": "message-drawer",
	"gui-resizebox": "gui-resizebox",
	"arco-icon": "arco-icon",
	"hidden": "hidden",
	"message-drawer-box": "message-drawer-box",
	"message-drawer-title": "message-drawer-title",
	"close": "close",
	"message-drawer-content": "message-drawer-content",
	"message-drawer-footer": "message-drawer-footer",
	"gui-layout-footer": "gui-layout-footer",
	"gui-list": "gui-list",
	"gui-spin": "gui-spin",
	"gui-spin-children": "gui-spin-children",
	"gui-list-wrapper": "gui-list-wrapper",
	"gui-spin-dot": "gui-spin-dot"
};