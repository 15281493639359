import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Dialog, PopUp, PopTip } from 'components';
import { fadeOut } from 'constants/animation';
import { animateClass, postData, setComVal } from 'utils';
import { ERROR, CHECK } from 'constants';
import printCheckImg from 'images/print/print_check.png';
import { sleep } from 'utils/business/print/checkLODOP';
import { prefixCls } from './index.scss';

function showPopTip(type, content, callback) {
  new PopUp(PopTip, {
    type,
    content,
    onClose: () => {
      if (typeof callback === 'function') {
        callback();
      }
    },
  }).show();
}

export default class printSlnDialogError extends PureComponent {
  constructor(prop) {
    super(prop);
    this.state = {};
  }
  static defaultProps = {
    autoDestroy: true,
  };
  static propTypes = {
    dialogKey: PropTypes.string,
    dialogInfo: PropTypes.object,
    slnInfo: PropTypes.object,
    autoDestroy: PropTypes.bool,
    close: PropTypes.func,
    cid: PropTypes.string,
    tplId: PropTypes.string,
    tplType: PropTypes.string,
    callback: PropTypes.func,
    closePrintSln: PropTypes.func,
    dialogType: PropTypes.string, // 区分是否系统错误
  };
  UNSAFE_componentWillMount() {
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      dialogShow: true,
    });
    const dialogInfo = this.props.dialogInfo || {};
    const data = dialogInfo.data || {};
    window.globalLodopObj && (window.globalLodopObj.oriPrintSln = data);

    const header = (
      <div className="print-sln-tip print-sln-err-tip">
        <div className="error-sln-icon">
          <img alt="打印检测" src={printCheckImg} className="fn-icon-info-o" />
          <div className="error-sln-icon-txt">检测到您的打印功能有异常，请执行如下步骤进行修复！</div>
        </div>
        <div className="fn-show_info__detail">
          <div className="sln-error-tit"> 第一步：请校验打印机选择是否正确 ?</div>
        </div>
      </div>
    );
    const botmCnt = (
      <div className="detection-sln-btom btm-cnt-2">
        <div className="print-check-err">请检查打印机</div>
        <div className="sln-error-tit">
          第二步：请确认打印环境是否已经安装 ?<span className="else-next">(若已安装，请点击下一步)</span>
        </div>
        <div className="sln-error-bom-color">
          <li className="install-item margin-cls">
            修复工具安装：请点击
            <a
              href="http://download.chemanman.com/ucmm/desktop/打印修复工具.exe"
              rel="nofollow me noopener noreferrer"
              target="_blank"
            >
              下载
            </a>
            后， 运行“打印修复工具.exe”
          </li>
          <li className="install-item">
            CLodop安装：请访问
            <a href="http://www.c-lodop.com/download.html" rel="nofollow me noopener noreferrer" target="_blank">
              官网
            </a>
            ， 下载
            <a href="http://www.c-lodop.com/download.html" rel="nofollow me noopener noreferrer" target="_blank">
              Lodop综合版
            </a>
            后安装 install_lodop32.exe， 并下载安装
            <a href="http://www.c-lodop.com/download.html" rel="nofollow me noopener noreferrer" target="_blank">
              C-Lodop扩展版
            </a>
          </li>
        </div>
      </div>
    );
    dialogInfo.button[0] && (dialogInfo.button[0].children = '下一步');
    dialogInfo.button = [...[], dialogInfo.button[0]];
    dialogInfo.contentHeader = header;
    dialogInfo.contentBotm = botmCnt;

    if (dialogInfo.data !== undefined) {
      this.setState({
        data,
      });
    }
  }

  componentWillUnmount = () => {};

  downloadFix = () => window.globalLodopObj && (window.globalLodopObj.fixDownload = true);

  closeDialog = where => {
    if (this.props.autoDestroy && typeof this.props.close === 'function') {
      animateClass(this.dialog, fadeOut, () => this.props.close());
    } else {
      // eslint-disable-next-line react/no-unused-state
      animateClass(this.dialog, fadeOut, () => this.setState({ dialogShow: false }));
    }
    this.props.closePrintSln && this.props.closePrintSln(where);
  };

  save = async (key, value, validate, cb) => {
    const url = '/Basic/Print/setPrintSln';
    const pack = value;
    if (!validate.isPass) {
      const label = validate.error.labelProps.children;
      showPopTip(ERROR, `请检查${label}`, cb);
      return false;
    }
    pack.cid = this.props.cid;
    pack.tpl_id = this.props.tplId;
    pack.tpl_type = this.props.tplType;
    const hideTplName = `${this.props.cid}_${this.props.tplId}`;
    pack.sln_name = pack.print_tpl_name || hideTplName;
    pack.check = 1;
    if (pack.state === 'switch_set_close') {
      pack.state = 0;
    } else {
      pack.state = 1;
    }
    this.props.callback && this.props.callback(pack);
    this.closeDialog();
    window.globalLodopObj && (window.globalLodopObj.printSln = pack);

    if (window.PRINTERRORSTEP > 2) {
      await sleep(6000);
    } else {
      await sleep(2000);
    }

    postData(url, pack, res => {
      cb();
      console.log(CHECK, res);
      this.props.closePrintSln('confirm', pack);
      return false;
    });
  };

  printSln = dialogInfo => {
    const actionList = {};
    actionList.cancel = () => this.closeDialog('cancel');
    actionList.save = this.save;
    return Object.assign(dialogInfo, { actionList });
  };

  handleSelectChange = (key, value, data) => {
    const newData = data;
    if (key === 'printer') {
      newData.printer = setComVal(data.printer, value || []);
      this.setState({
        data: newData,
      });
    }
    if (key === 'refer_sln') {
      const currValue = value[0] || {};
      newData.printer = setComVal(data.printer, currValue.printer || []);
      newData.print_tpl_name = setComVal(data.print_tpl_name, currValue.sln_name || []);
      newData.margin_left = setComVal(data.margin_left, currValue.margin_left || []);
      newData.margin_top = setComVal(data.margin_top, currValue.margin_top || []);
      this.setState({
        data: newData,
      });
    }
  };

  checkLodop = () => window.open('clodop://setup');

  // getDialogInfo = (dialogKey) => this[dialogKey]

  render() {
    const { dialogKey } = this.props;
    const dialogInfo = this[dialogKey](this.props.dialogInfo);
    const { data } = this.state;
    dialogInfo.title = '打印机故障智能检修程序';
    delete data.refer_sln;
    return (
      <div className={`${prefixCls} ${prefixCls}-error`} ref={r => (this.dialog = r)}>
        <Dialog
          dialogInfo={dialogInfo}
          loadBtnList={['save']}
          data={data}
          close={this.props.close}
          handleSelectChange={this.handleSelectChange}
          isShow
        />
      </div>
    );
  }
}
