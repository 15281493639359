var escape = require("../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".hello-oms-modal .fn-dialog{z-index:1000}.hello-oms-modal .fn-dialog__center{padding:0}.hello-oms-modal .hello-oms{width:700px;height:464px;background:url(" + escape(require("images/hello_oms.png")) + ");background-size:contain;background-repeat:no-repeat}", ""]);

// exports
exports.locals = {
	"prefixCls": "hello-oms-modal",
	"hello-oms-modal": "hello-oms-modal",
	"fn-dialog": "fn-dialog",
	"fn-dialog__center": "fn-dialog__center",
	"hello-oms": "hello-oms"
};