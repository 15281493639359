exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".custom-confirm-reason .dialog_detail{padding:0 0 0 25px}.track-log-loading_loader{background-color:hsla(0,0%,100%,.7)!important}", ""]);

// exports
exports.locals = {
	"confirmClass": "custom-confirm-reason",
	"mapLoading": "track-log-loading",
	"custom-confirm-reason": "custom-confirm-reason",
	"dialog_detail": "dialog_detail",
	"track-log-loading_loader": "track-log-loading_loader"
};