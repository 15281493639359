exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".pick-order-summary{height:60px;display:flex;background:#fff;align-items:center;justify-content:space-between;box-sizing:border-box;border:1px solid #b9ccd7}.pick-order-summary.is-simple{height:100%;border:0}.pick-order-summary.is-simple .pick-order-summary__summary{justify-content:flex-start}.pick-order-summary.is-simple .pick-order-summary__summary-item{font-size:12px;padding:8px 0}.pick-order-summary.is-simple .pick-order-summary__summary-item+.pick-order-summary__summary-item{margin-left:20px}.pick-order-summary.is-stack{align-content:center;flex-wrap:wrap;height:100%}.pick-order-summary.is-stack .pick-order-summary__right-btn,.pick-order-summary.is-stack .pick-order-summary__summary{width:100%}.pick-order-summary.is-stack .pick-order-summary__summary{height:40px}.pick-order-summary.is-stack .pick-order-summary__right-btn{text-align:center}.pick-order-summary.is-stack .fn-waybill-buttons{margin-top:0;padding-top:0}.pick-order-summary .fn-waybill-buttons{margin-top:0}.pick-order-summary__summary{padding:0 4px;display:flex;flex:1;justify-content:space-between}.pick-order-summary__summary-item{display:flex;align-items:center;font-weight:500;font-size:12px;color:#222}.pick-order-summary__summary-data{padding:4px 8px;background:#f4f5f6;margin-left:4px}", ""]);

// exports
exports.locals = {
	"prefixCls": "pick-order-summary",
	"pick-order-summary": "pick-order-summary",
	"is-simple": "is-simple",
	"pick-order-summary__summary": "pick-order-summary__summary",
	"pick-order-summary__summary-item": "pick-order-summary__summary-item",
	"is-stack": "is-stack",
	"pick-order-summary__right-btn": "pick-order-summary__right-btn",
	"fn-waybill-buttons": "fn-waybill-buttons",
	"pick-order-summary__summary-data": "pick-order-summary__summary-data"
};