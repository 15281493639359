import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { List } from 'immutable';
import { fromJS, fetchApi as _callApi } from 'utils';

const fetchData = ComposedComponent =>
  class extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        inputValue: undefined,
        reqData: this.props.reqData,
      };
      // this.constructor.childContextTypes = {
      //   getData: PropTypes.func.isRequired
      // }
      this.handleRef = ref => {
        this.ref = ref && ref.ref ? ref.ref : ref;
      };
      this.promise = null;
    }

    static defaultProps = {};
    static propTypes = {
      fetchApi: PropTypes.object,
      reqData: PropTypes.array,
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (nextProps.reqData !== this.state.reqData) {
        this.setState({ reqData: nextProps.reqData });
      }
    }
    static childContextTypes = {
      getData: PropTypes.func,
    };
    getChildContext = () => {
      const fetchApi = this.props.fetchApi || {};
      const key = fetchApi.key || '';
      if (key !== '') {
        return { getData: this.getInputSug };
      }
      return { getData: this.getData };
    };

    fileterValue = value => {
      const { fetchApi } = this.props;
      const filterLen = fetchApi.filterLen || 0;
      if (value === undefined || value.length < filterLen) return '';
      return value;
    };

    getInputSug = newValue => {
      // let inputValue = this.fileterValue(newValue)
      // if (inputValue === this.state.inputValue) return false
      const inputValue = newValue;
      this.setState({
        inputValue,
      });
      this.getData(inputValue);
    };

    getPara = (newValue, pfetchApi) => {
      const fetchApi = this.props.fetchApi || pfetchApi;
      const url = fetchApi.url || '';
      const method = fetchApi.method || 'POST';
      const saveAs = fetchApi.saveAs || 'data';
      const dataFormat = fetchApi.dataFormat || 'raw';
      let para = fetchApi.para || {};
      const paraQuery = para.query || {};
      const key = fetchApi.key || '';
      const queryKey = fetchApi.queryKey || '';
      if (newValue !== undefined && key !== '') para[key] = newValue;
      if (newValue !== undefined && key !== '' && queryKey !== '') para[queryKey][key] = newValue;
      Object.keys(paraQuery).forEach(item => {
        !paraQuery[item] && delete paraQuery[item];
      });
      if (typeof fetchApi.paraCallback === 'function') {
        para = fetchApi.paraCallback(para, key, newValue);
      }
      const callApi = {
        path: url,
        dataFormat,
        saveAs,
        method,
        body: {
          req: para,
        },
      };
      return callApi;
    };
    clearData = () => {
      console.log('clear fetch data');
      this.setState({ reqData: [] });
    };
    getData = (newValue, pfetchApi) => {
      const fetchApi = this.props.fetchApi || pfetchApi;
      const { formateFunc } = fetchApi;
      if (fetchApi.url === '') return false;
      const callApi = this.getPara(newValue, pfetchApi); // ,
      const callback = res => {
        let resData = res || {};
        if (typeof formateFunc === 'function') {
          resData = formateFunc(resData);
        } else {
          const key = fetchApi.data_key ? fetchApi.data_key.split(',') || ['res'] : ['res'];
          const tmpData = fromJS(resData).getIn(key) || List();
          resData.reqData = tmpData.toJS() || [];
        }
        if (resData) {
          const newState = {};
          newState.reqData = resData.reqData === null || resData.reqData === undefined ? [] : resData.reqData;
          if (resData.tableHeader) newState.tableHeader = resData.tableHeader;
          this.setState(newState);
        }
      };
      if (this.promise) this.promise.abort();
      this.promise = _callApi(callApi.path, callApi);
      this.promise.then(
        res => {
          callback(res);
        },
        err => {
          console.log(err, newValue);
        },
      );
    };
    getWrappedInstance() {
      return this.wrappedInstance;
    }
    render() {
      // 此处 state 中的 data 会覆盖props中的data
      // 增加 UNSAFE_componentWillReceiveProps 处理
      return (
        <ComposedComponent
          {...this.props}
          {...this.state}
          fetchClear={this.cleraData}
          ref={r => {
            this.handleRef(r);
            this.getWrappedInstance = r;
          }}
        />
      );
    }
  };
export default fetchData;
