import { PopUp, PopTip } from 'components';
import { ERROR } from 'constants';
import actions from 'actions';
import printSlnDialog from 'components/commoncomponents/printSlnDialog';

function getPrintSln(hostId, tplId) {
  actions.settingServer.getPrintSln(hostId, tplId).then(res => {
    if (res.type === 'API_SUCCESS' && res.data.errno === 0) {
      const dialogInfoData = res.data.res;
      new PopUp(printSlnDialog, {
        dialogKey: 'printSln',
        dialogInfo: dialogInfoData || {},
      }).show();
    } else {
      new PopUp(PopTip, {
        classname: 'pop_tip',
        type: ERROR,
        isShow: true,
        content: res.data.errmsg,
      }).show();
    }
  });
}

export default {
  getPrintSln,
};
