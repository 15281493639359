import { fetchApi } from 'utils';

let loan_type_list = null;

// 获取借支类型
export const getLoanType = async () => {
  if (loan_type_list) {
    return loan_type_list;
  }
  const res = await fetchApi('/Finance/Finance/loanTypeSug');
  if (+res.errno === 0) {
    const loanType = Object.keys(res.res).map(key => ({
      key,
      name: res.res[key],
    }));
    loan_type_list = loanType || [];
    return loanType;
  }
  return [];
};
