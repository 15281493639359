exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".insure-order-content>div{margin-bottom:10px}.insure-order-content .insurance-type{width:450px}.insure-order-content .insurance-amount,.insure-order-content .insurance-fee,.insure-order-content .insurance-ratio{display:inline-block;margin-right:15px}.insure-order-content .left_space{margin-left:20px;width:183px;max-width:183px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.insure-order-content .insurance-balance{position:absolute;bottom:-5px}.insure-order-content .insurance-balance label,.insure-order-content .insurance-balance span{font-size:16px;font-weight:700}.insure-order-content .insurance-range{margin:0 15px 0 -10px}.insure-order-content .fn-address .input-tip{right:22px}.insure-order-content .fn-icon-help-o{color:#0097cf;margin-left:5px}.code_42 .fn-icon-info-o,.code_43 .fn-icon-info-o,.code_52 .fn-icon-info-o,.code_65 .fn-icon-info-o{height:32px;line-height:32px;width:32px;font-size:32px;color:#69b1f1;margin-right:10px}.code_42 .icon-o,.code_43 .icon-o,.code_52 .icon-o,.code_65 .icon-o{line-height:60px;font-size:14px}", ""]);

// exports
exports.locals = {
	"prefixCls": "insure-order",
	"insure-order-content": "insure-order-content",
	"insurance-type": "insurance-type",
	"insurance-amount": "insurance-amount",
	"insurance-ratio": "insurance-ratio",
	"insurance-fee": "insurance-fee",
	"left_space": "left_space",
	"insurance-balance": "insurance-balance",
	"insurance-range": "insurance-range",
	"fn-address": "fn-address",
	"input-tip": "input-tip",
	"fn-icon-help-o": "fn-icon-help-o",
	"code_65": "code_65",
	"fn-icon-info-o": "fn-icon-info-o",
	"code_42": "code_42",
	"code_52": "code_52",
	"code_43": "code_43",
	"icon-o": "icon-o"
};