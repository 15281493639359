module.exports = {
  opTablePara: {
    isAutoSize: true,
    isOrderNumberColumn: true,
    isShowTotalRow: false,
    isRowSelect: false,
    isShowFilterRow: false,
    isShowFilterRowIcon: false,
    rowClickable: false,
    enableOperate: false,
    showScrollbarX: false,
    showScrollbarY: false,
  },
};
