exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".pay-mode-wrap{width:100%}.pay-mode-wrap .fixedDataTableLayout_hasBottomBorder{border-bottom:0}.pay-mode-wrap .fn-input[disabled]{border-color:#fff}.amount-area{color:red;font-size:14px;padding-bottom:10px}", ""]);

// exports
exports.locals = {
	"prefixCls": "pay-mode-wrap",
	"pay-mode-wrap": "pay-mode-wrap",
	"fixedDataTableLayout_hasBottomBorder": "fixedDataTableLayout_hasBottomBorder",
	"fn-input": "fn-input",
	"amount-area": "amount-area"
};