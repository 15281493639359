exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".task-depart-modal .tip{display:flex;align-items:center}.task-depart-modal .tip .fn-icon-il{flex:0 0;color:#fbba7f;font-size:32px;width:auto;height:auto}.task-depart-modal .tip p{flex:1 1;margin-left:10px;line-height:22px;font-size:14px}.task-depart-modal .fields{display:flex;flex-wrap:wrap;width:430px;margin-top:20px}.task-depart-modal .fields>div{display:flex;flex-basis:50%;align-items:center;margin-bottom:10px}.task-depart-modal .fields>div>label{flex:0 0 76px;text-align:right;padding-right:5px;color:#333}.task-depart-modal .fields>div>label.r{color:red}.task-depart-modal .fields>div .ant-calendar-picker,.task-depart-modal .fields>div .fn-input-pure-wrap{flex:0 0 140px;height:28px}", ""]);

// exports
exports.locals = {
	"prefixCls": "task-depart-modal",
	"task-depart-modal": "task-depart-modal",
	"tip": "tip",
	"fn-icon-il": "fn-icon-il",
	"fields": "fields",
	"r": "r",
	"fn-input-pure-wrap": "fn-input-pure-wrap",
	"ant-calendar-picker": "ant-calendar-picker"
};