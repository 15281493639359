exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".cardform-no-border .fn-card__form__content,.cardFrom-dialog__card_form div{border:none}", ""]);

// exports
exports.locals = {
	"prefixCls": "cardFrom-dialog",
	"cardFrom-dialog__card_form": "cardFrom-dialog__card_form",
	"cardform-no-border": "cardform-no-border",
	"fn-card__form__content": "fn-card__form__content"
};