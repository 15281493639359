import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import moment from 'moment';
import {
  CheckBox,
  Button,
  Icon,
  Select,
  PureInput as Input,
  DateTimePicker,
  ModalDialog,
  PopUp,
  Address,
  Tooltip,
} from 'components';
import browserHistory from 'routes/history';
import { createTip, showInfo, fetchApi as fetch, validateFieldsOf, confirm, alert, customDetail } from 'utils';
import { ERROR, WARN, CHECK } from 'constants';
import { directToWalletOnlineCharge } from 'utils/business/account/wallet/direct';
import { prefixCls } from './insureOrder.scss';

const successTip = (
  <div>
    支付成功！保险公司<span className="text-red">处理中</span>,
    1-5分钟后返回承保结果您可在我的保单页面查询运单的承保状态，承保结果请您以收到的短信通知为准。
  </div>
);

const sendReq = (url, req) => fetch(url, { method: 'POST', body: { req } });

export class InsureOrderModal extends PureComponent {
  static propTypes = {
    data: PropTypes.object,
    title: PropTypes.string,
    close: PropTypes.func,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    getIsEdited: PropTypes.func,
  };

  constructor(props) {
    super(props);
    props.getIsEdited(this);
  }

  state = {
    valid_start_date: moment().format('YYYY-MM-DD'),
    ...this.props.data,
    errCode: '',
    errMsg: '',
  };

  isEdited = () => this.componentEdited;

  set = (key, val) => {
    this.componentEdited = true;
    this.setState({ [key]: val });
  };

  showNotice = () => {
    const { insure } = this.state;
    if (insure) {
      browserHistory.pushWithQuery({
        pathname: '/Company/insuredNotice',
        query: {
          type: insure.insure_cate_code,
          insureCompanyName: insure.insure_company_name,
          name: insure.company_name,
          insureType: this.state.insurance_type,
        },
      });
    } else {
      createTip('请先选择险种！', WARN).show();
    }
  };

  showContract = () => {
    const { insure } = this.state;
    if (insure) {
      browserHistory.pushWithQuery({
        pathname: '/Company/contractContent',
        query: { type: insure.insure_cate_code, name: insure.company_name },
      });
    } else {
      createTip('请先选择险种！', WARN).show();
    }
  };

  // 阅读并同意check
  handleAgreeChecked = () => this.setState({ agree: !this.state.agree });

  jumpToMyInsurance = () => {
    setTimeout(() => {
      const urlData = {
        controller: 'Company',
        action: 'myPolicy',
        query: { tabName: '我的保单' },
        refreshData: true,
      };
      window.$app.direct(urlData);
    }, 100);
    this.props.onConfirm && this.props.onConfirm({ ...this.state.insureResult, jump: true });
    this.modelDialog.handleHide();
  };
  // 去改单 这个弹窗是没用到的
  jumpToChangeOrder = msg => {
    this.props.onConfirm && this.props.onConfirm(0);
    this.modelDialog.handleHide();
    browserHistory.pushWithQuery({
      pathname: '/Order/coInfo',
      query: {
        isWhere: 'modify',
        modifyOrderNum: encodeURIComponent(msg.order_num),
        tabName: '改单',
        modifyFrom: 'list',
      },
    });
  };
  // 网点余额不足 去充值
  jumpToRecharge = () => {
    this.props.onConfirm && this.props.onConfirm(0);
    this.modelDialog.handleHide();
    setTimeout(directToWalletOnlineCharge, 100);
  };

  confirm = async () => {
    if (!(await validateFieldsOf(this.wrap))) return false;
    const { state } = this;
    const url = 'Insurance/Single/confirm';
    const req = {
      od_link_id: state.od_link_id,
      insurance_id: state.insure.key,
      valid_start_date: state.valid_start_date,
      car_num: state.car_num,
      start_address: state.start_address,
      end_address: state.end_address,
      start_info: state.start_info,
      end_info: state.end_info,
    };
    this.setState({ loading: true });
    const res = await sendReq(url, req);
    if (res.errno === 0) {
      this.setState({ insureResult: res.res, loading: false, errCode: res.errno });
    } else if (res.errno === 52) {
      this.setState({ insureResult: res.res, loading: false, errCode: res.errno, errMsg: res.errmsg });
    } else if (res.errno === 65) {
      this.setState({ insureResult: res.res, loading: false, errCode: res.errno, errMsg: res.errmsg });
    } else if (res.errno === 42) {
      this.setState({ insureResult: res.res, loading: false, errCode: res.errno, errMsg: res.errmsg });
    } else if (res.errno === 43) {
      this.setState({ insureResult: res.res, loading: false, errCode: res.errno, errMsg: res.errmsg });
    } else if (res.errno === 404) {
      if (!(await alert(WARN, res.errmsg))) {
        this.setState({ loading: false });
      }
    } else {
      showInfo(ERROR, res.errmsg);
      this.setState({ loading: false });
    }
  };

  cancel = () => {
    this.state.insureResult && this.props.onConfirm && this.props.onConfirm(this.state.insureResult);
    this.modelDialog.handleHide();
  };

  render() {
    if (!this.state.insur_cates) return false;
    const { state } = this;
    const success = state.insureResult;
    let msg = '';
    if (state.errCode === 52) {
      msg = success[0] ? success[0] : state.errMsg;
    } else if (state.errCode === 65) {
      msg = success[0] ? success[0] : state.errMsg;
    }
    const carNumLength = val => (val.length > 20 ? '最多填写两个车牌号！' : '');
    const { declared_value: declaredValue, insureEnum, insure = {}, loading, agree } = this.state;
    const rate = +insure.premium_rate || 0;
    const insuranceRange = insure.amount_input_tip || '每1万一档';
    const amount = (+declaredValue || 0) * rate;
    const carNumRequired = +insure.require_car_num;
    const premium_min = +insure.premium_min || 5;
    const content = (
      <div className="insure-order-content" ref={r => (this.wrap = r)}>
        <div>
          <label className="text-red">保险险种: </label>
          <Select
            className="insurance-type"
            required
            value={insure}
            map={false}
            data={insureEnum}
            onChange={val => this.set('insure', val)}
          />
        </div>
        <div>
          <label>保险金额: </label>
          <span className="insurance-amount">
            <span className="red">{declaredValue}</span>万元
            <Tooltip classname="notice-tips" content="保险金额为您填写的声明价值，不可更改。">
              <Icon iconType="icon-help-o" />
            </Tooltip>
          </span>
          <span className="insurance-range">({insuranceRange})</span>
          <label>费率:</label>
          <span className="insurance-ratio text-red">{+(rate * 1000).toFixed(3)}‰</span>
          <label>保费: </label>
          <span className="insurance-fee">
            <span className="text-red">
              {(amount * 10000).toFixed(2) <= premium_min ? premium_min : (amount * 10000).toFixed(2)}
            </span>
            元
            <Tooltip
              classname="notice-tips"
              content={`保费=保险金额*费率后四舍五入，保留两位小数，最低为${premium_min}元。`}
            >
              <Icon iconType="icon-help-o" />
            </Tooltip>
          </span>
        </div>
        <div>
          <label className="text-red">生效日期: </label>
          <DateTimePicker
            disabledDate={current => current && current.valueOf() < moment().startOf('day')}
            defaultValue={state.valid_start_date}
            onChange={val => this.set('valid_start_date', val)}
          />
          {/* <label className={`left_space ${carNumRequired ? 'text-red' : ''}`}>车牌号: </label>
          <Input
            required={carNumRequired}
            value={state.car_num}
            customValidity={carNumLength}
            onChange={e => this.set('car_num', e.target.value)}
          />
          <Tooltip
            classname="notice-tips"
            content="如有挂车也必须填写，多个车牌号中间用逗号分隔。"
          >
            <Icon iconType="icon-help-o" />
          </Tooltip> */}
        </div>
        <div>
          <label className="text-red">起运地址: </label>
          <Address
            mode="district"
            required
            needSelect
            fullName
            value={state.start_info}
            onChange={val => this.set('start_info', val)}
            onSelect={val => this.set('start_info', val)}
          />
          <Input
            className="left_space"
            placeholder="详细地址"
            maxLength="50"
            value={state.start_address}
            title={state.start_address}
            onChange={e => this.set('start_address', e.target.value)}
          />
        </div>
        <div>
          <label className="text-red">目的地址: </label>
          <Address
            mode="district"
            required
            needSelect
            fullName
            value={state.end_info}
            onChange={val => this.set('end_info', val)}
            onSelect={val => this.set('end_info', val)}
          />
          <Input
            className="left_space"
            placeholder="详细地址"
            maxLength="50"
            value={state.end_address}
            title={state.end_address}
            onChange={e => this.set('end_address', e.target.value)}
          />
        </div>
        <div>
          <CheckBox direction="right" required checked={state.agree} onClick={this.handleAgreeChecked} />
          <span className="notice_msg">
            我已阅读并同意<a onClick={this.showNotice}>投保须知</a>和<a onClick={this.showContract}>合同内容</a>
          </span>
        </div>
        {window.sas_env_group && window.sas_env_group.insure_wallet_open && (
          <div className="insurance-balance">
            <label>保险余额：</label>
            <span>{state.balance}元</span>
          </div>
        )}
      </div>
    );
    const confirmBtn = (
      <Button key={1} type="primary" disabled={!agree} onClick={this.confirm} loading={loading}>
        确认并支付
      </Button>
    );
    const jumpBtn = (
      <Button key={1} type="primary" onClick={this.jumpToMyInsurance}>
        我的保单
      </Button>
    );
    const jumpRechargeBtn = (
      <Button key={1} type="primary" onClick={this.jumpToRecharge}>
        去充值
      </Button>
    );
    const jumpChangeOrderBtn = (
      <Button key={1} type="primary" onClick={() => this.jumpToChangeOrder(msg)}>
        去改单
      </Button>
    );
    // 不同状态下的按钮展示
    let leftBtn = '';
    let tips = '';
    if (state.errCode === 52) {
      leftBtn = jumpChangeOrderBtn;
      tips = (
        <span className="icon-o">
          <Icon iconType="icon-info-o" />
          {state.errMsg}!
        </span>
      );
    } else if (state.errCode === 65) {
      leftBtn = jumpChangeOrderBtn;
      tips = (
        <span className="icon-o">
          <Icon iconType="icon-info-o" />
          {state.errMsg}！
        </span>
      );
    } else if (state.errCode === 43) {
      leftBtn = jumpRechargeBtn;
      tips = (
        <span className="icon-o">
          <Icon iconType="icon-info-o" />
          {state.errMsg}！
        </span>
      );
    } else if (state.errCode === 42) {
      leftBtn = '';
      tips = (
        <span className="icon-o">
          <Icon iconType="icon-info-o" />
          {state.errMsg}！
        </span>
      );
    } else if (
      success &&
      state.errCode !== 52 &&
      state.errCode !== 65 &&
      state.errCode !== 42 &&
      state.errCode !== 43
    ) {
      leftBtn = jumpBtn;
      tips = successTip;
    } else {
      leftBtn = confirmBtn;
      tips = content;
    }
    const bottom = [
      leftBtn,
      <Button key={2} onClick={this.cancel}>
        {success ? '取消' : '关闭'}
      </Button>,
    ];
    const titleFlag =
      success && state.errCode !== 52 && state.errCode !== 65 && state.errCode !== 42 && state.errCode !== 43;

    return (
      <ModalDialog
        classname={`${prefixCls} code_${state.errCode}`}
        title={titleFlag ? '支付成功' : '单票投保确认并支付'}
        // content={success ? successTip : content}
        content={tips}
        bottom={bottom}
        isShow
        closable
        maskClosable={false}
        close={this.props.close}
        contentStyle={{ width: '550px' }}
        ref={r => (this.modelDialog = r)}
      />
    );
  }
}

export default function insureOrder(odLinkId) {
  let resolved = false;

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async resolve => {
    const longkunSetting = window.company_setting.insure_longkun_open;
    const isOpenLongkun = longkunSetting && longkunSetting.checked;
    let url = 'Insurance/Single/edit';
    let conf = { od_link_id: odLinkId };
    // 列表 - 右键投保读系统设置
    if (isOpenLongkun) {
      url = 'Insurance/LongKun/directInsure';
      conf = {
        od_link_id: odLinkId,
        goods_check: true,
      };
    }
    const res = await sendReq(url, conf);
    if (isOpenLongkun) {
      // 开启了龙琨保险
      if (res.errno === 0) {
        res.errmsg && showInfo(CHECK, res.errmsg);
        resolve(true);
      } else if (res.errno === 503) {
        const detail = customDetail({ caption: '操作提示', detail: [res.errmsg], hasDot: true });
        if (!(await confirm(WARN, '当前货物不允许线上投保，是否线下投保？', undefined, undefined, detail))) {
          return false;
        }
        conf.goods_check = false;
        const confirmRes = await sendReq(url, conf);
        if (+confirmRes.errno !== 0) {
          showInfo(ERROR, confirmRes.errmsg);
          return false;
        }
        confirmRes.errmsg && showInfo(CHECK, confirmRes.errmsg);
        resolve(true);
      }
    } else if (res.errno === 0) {
      // 未开启龙琨保险
      // const insureType = res.res.insurance_type
      const insureEnum = res.res.insur_cates.map(item => ({
        ...item,
        key: item.insurance_id,
        name: item.insurance_name,
      }));
      const data = { ...res.res, ...res.res.record_info, od_link_id: odLinkId, insureEnum };
      new PopUp(InsureOrderModal, {
        id: odLinkId,
        data,
        onClose: () => !resolved && resolve(false),
        onConfirm: result => {
          resolved = true;
          resolve(result);
        },
      }).show();
    } else if (res.errno === 26) {
      const tip = '您的投保资料未提交或未审核，请维护，完成后即可投保';
      const simpleText = { confirm: '前去维护', cancel: '取消' };
      const result = await confirm(WARN, tip, simpleText);
      if (result)
        setTimeout(
          () => browserHistory.pushWithQuery({ pathname: '/Company/insuredInfo', query: { tabName: '投保资料' } }),
          100,
        );
      resolve(+result);
    } else {
      showInfo(ERROR, res.errmsg);
      resolve(false);
    }
  });
}
